import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import
    * as s
    from "underscore.string";

import { IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, PCRControl, CollapsibleSection } from "$Imports/CommonComponents";
import Snackbar from "@material-ui/core/Snackbar";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";
import { RadioGroup, FormControlLabel, Radio, TextField } from "@material-ui/core";
import { ChangeEvent } from "react";

const styles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

export interface ILigationState {
    messageSnackbarOpen: boolean;
    message: string;
    canMove: boolean;
}

export class LigationScreen extends React.Component<IWorkflowScreenProps, ILigationState> {

    state: ILigationState = { messageSnackbarOpen: false, message: "", canMove: true };

    componentDidMount() {
        this.props.workflowRunService.fetchPCRForStep(true);
        this.props.workflowRunService.fetchMasterMixForStep(true);
        this.props.workflowRunService.fetchStepInstanceCustomFields();
        this.props.workflowRunService.fetchInstrumentOptions();
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;

        if (currentWorkflowRun && currentStep && pcrInfoState.data && masterMixInfoState.data && instrumentOptions && lotOptions) {
            let customFields = currentStep.toJS().CustomFields;
            let tempValue = customFields["RoomTemperature"];
            let useThermocycler = customFields["UsePcr"] === "true";
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return (
                <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={styles.mainDiv}>
                            <MasterMixControl
                                disabled={disabled}
                                index={0}
                                masterMix={masterMixInfoState.data[0]}
                                sampleCount={1}
                                updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                lotOptions={lotOptions}
                                setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                            />
                            <div>
                                <RadioGroup row value={useThermocycler} onChange={this.handleRadioChange} >
                                    <FormControlLabel disabled={disabled} value={true} control={<Radio />} label="Use Thermocycler" />
                                    <FormControlLabel disabled={disabled} value={false} control={<Radio />} label="Incubate at room temparature" />
                                </RadioGroup>
                                {useThermocycler ?
                                    <PCRControl disabled={disabled} controlInfo={pcrInfoState.data} updateInstrumentName={this.props.workflowRunService.updatePCRName} headerOverride={"Thermocycler Information"} instrumentOptions={instrumentOptions} /> :
                                    (<div>
                                        {"Temperature " + customFields["degrees"] + "C"} <TextField disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={tempValue || ""} onChange={this.updateTempField} inputProps={{ pattern: "[0-9.]*" }} />
                                    </div>)
                                }
                            </div>
                        </div>
                    </CollapsibleSection>
                    <div className={styles.footerDiv}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={this.state.messageSnackbarOpen}
                        message={this.state.message}
                        autoHideDuration={5000}
                        onClose={this.snackbarClose}
                    />
                </div>);
        }
        return <></>
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.workflowRunService.updateCustomField("UsePcr", (event.target as HTMLInputElement).value);
    }

    @bind
    updateTempField(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.props.workflowRunService.updateCustomField("RoomTemperature", newValue);
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue) {
                this.props.workflowRunService.updateCustomField("RoomTemperature", newValue);
            }
        }
    }

    @bind
    private async moveToNextStep() {
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        const currentStep = this.props.workflowRunService.currentStep;

        if (pcrInfoState.data && masterMixState.data && currentStep && currentStep.OutputAssets) {
            let missingMasterMixData = missingData(masterMixState.data[0]);

            let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName)
            if (outputAsset && outputAsset.PoolSummary) {
                let customFields = currentStep.toJS().CustomFields;
                let usePcr = customFields["UsePcr"] === "true";

                if (usePcr) {
                    if (pcrInfoState.data.InstrumentNameValue && !missingMasterMixData) {
                        //Continue to next step
                        if (this.state.canMove) {
                            await Promise.all([
                                this.props.workflowRunService.saveMasterMix(true),
                                this.props.workflowRunService.savePCRInfo(),
                                this.props.workflowRunService.updateCustomFields(),
                                this.props.workflowRunService.addInstrument(pcrInfoState.data.InstrumentNameValue),
                                this.props.workflowRunService.completeStep(),
                                this.props.workflowRunService.savePoolVolume(outputAsset.PoolSummary.Id, 75) //Volume is equal to all mastermix reagents added together. Currently this is 75. If this changes down the line we will need to rework the master mix data structure.
                            ]);
                        }
                    }
                    else {
                        this.setState({ messageSnackbarOpen: true, message: "Lot numbers and ThermoCycler name are required" });
                    }
                }
                else {
                    if (customFields["RoomTemperature"] && !isNaN(parseFloat(customFields["RoomTemperature"])) && !missingMasterMixData) {
                        //Continue to next step
                        if (this.state.canMove) {
                            await Promise.all([
                                this.props.workflowRunService.saveMasterMix(true),
                                this.props.workflowRunService.savePCRInfo(),
                                this.props.workflowRunService.updateCustomFields(),
                                this.props.workflowRunService.completeStep(),
                                this.props.workflowRunService.savePoolVolume(outputAsset.PoolSummary.Id, 75) //Volume is equal to all mastermix reagents added together. Currently this is 75. If this changes down the line we will need to rework the master mix data structure.
                            ]);
                        }
                    }
                    else {
                        this.setState({ messageSnackbarOpen: true, message: "Lot numbers and temperature are required" });
                    }
                }
            }
        }
    }
}