import { NgsReportBioinformaticsPipelineAnalysisDetailsVM } from "$Generated/api";
import {
    React,
    _,
    bind
} from "$Imports/Imports";
import { FileSharingAPI } from "$State/FileShareManagementService";

import { NavigationService } from "$State/NavigationFreezerService";
var urljoin = require('url-join');


const reportStyles: {
    analysisTableHeader: string;
    mainReport: string;
    pageLink: string;
} = require("../WetLabReport.scss");

const styles: {
    header: string;
} = require("../../NGSQCReportsLayoutPage.scss");



interface IBioInformaticsPipelineFilesProps {
    runNumber: string;
    runTag: string;
    bioinformaticsInformation: NgsReportBioinformaticsPipelineAnalysisDetailsVM | undefined;
    workflowName: string;
}

export class BioInformaticsPipelineFiles extends React.Component<IBioInformaticsPipelineFilesProps>{

    render(): React.ReactNode {
        const filePath = urljoin("/fileShareManagement", (this.props.runNumber + "-" + this.props.runTag));
        const multiqcPath = this.props.workflowName === "COV2" ?
            urljoin(this.props.runTag, "multiqc.html") :
            urljoin("run", (this.props.runNumber + "-" + this.props.runTag), "multiqc.html");

        return (
            <div>
                <p>The bioinformatics analysis will output the final analysis files to the<br />
                    LIMS Lite Local File Share for this <a onClick={e => this.clickLink(e, filePath)} className={reportStyles.pageLink}>workflow run</a>.</p>

                <p>MultiQC Report containing additional analysis for this workflow run<br />
                    can be found here: <a onClick={e => this.openReport(multiqcPath)} className={reportStyles.pageLink}>MultiQC Report Workflow {this.props.runNumber}.</a></p>
            </div>
        )

    }

    // Click event for loading local file share reports.
    clickLink = (event: React.MouseEvent<HTMLAnchorElement>, link: string) => {
        event.preventDefault();
        this.navigateToUrl(link);
    }

    private navigateToUrl(link: string): void {
        NavigationService.navigateTo(link);
    }

    @bind
    private async openReport(filePath: string) {
        var runNumber = this.props.runNumber;
        await fetch(FileSharingAPI + 'DownloadFile/' + filePath, { method: "GET" }).then(
            response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                var fileDownload = document.createElement("a");
                fileDownload.href = url;
                fileDownload.download = "MultiQC Report Workflow" + { runNumber } + ".html"
                fileDownload.target = "_blank"
                fileDownload.click();
                URL.revokeObjectURL(url);
            })
    }

}


