import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    DataLoadingDisplay,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import {
    AssetService,
    IAssetServiceInjectedProps
} from "$State/AssetFreezerService";
import { ControlMaterialVM } from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
} = require("./LabAssets.scss");

interface IControlMaterialsPageBaseProps {
}

interface IControlMaterialsPageState {
    editDialogOpen: boolean,
    newMaterial: boolean,
    materialToEdit?: ControlMaterialVM,
    addTypeOpen: boolean,
    typeInputName: string
}

type IControlMaterialsPageProps = IControlMaterialsPageBaseProps & IAssetServiceInjectedProps;

export class _ControlMaterialsPage extends React.Component<IControlMaterialsPageProps, IControlMaterialsPageState> {

    private readonly columns: Array<IDataTableColumn<ControlMaterialVM>> = [
        {
            columnName: "control-material-name",
            columnFieldData: "Name",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "control-material-type",
            columnFieldData: "ControlType",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Type",
        },
        {
            columnName: "control-material-description",
            columnFieldData: "Description",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Description",
        },
        {
            columnName: "control-material-active",
            columnFieldData: (d) => d.IsActive ? "Active" : "Inactive",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Active",
        },
        {
            columnName: "control-material-deactivated-on",
            columnFieldData: (d) => !d.IsActive ? moment(d.DeactivatedOn).format("L LT") : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Deactivated On",
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                            onClick={() => {
                                this.setState({ materialToEdit: d, editDialogOpen: true });
                            }}
                        >
                            Edit
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    state: IControlMaterialsPageState = {
        editDialogOpen: false,
        newMaterial: false,
        addTypeOpen: false,
        typeInputName: ""
    }

    async componentDidMount() {
        await this.props.assetService.fetchControlMaterials(true);
        await this.props.assetService.fetchControlTypes(true);
    }

    render() {

        let {
            controlMaterialsFetchResults,
            controlTypesFetchResults
        } = this.props.assetService.getState();
        if (controlMaterialsFetchResults.data && controlTypesFetchResults.data) {
            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div
                        className={styles.mainContainer}
                    >
                        <Card
                            className={styles.cardStyle}
                        >
                            <h2>Control Materials</h2>
                            <Button
                                style={{ float: "right", marginRight: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({
                                        editDialogOpen: true,
                                        newMaterial: true,
                                        materialToEdit: {
                                            ControlType: "",
                                            Description: "",
                                            Id: "",
                                            IsActive: true,
                                            Name: "",
                                            CreatedOn: new Date(Date.now()),
                                            DeactivatedOn: new Date(Date.now())
                                        }
                                    })
                                }}
                            >
                                Add
                            </Button>
                            {controlMaterialsFetchResults.data &&
                                <DataTable
                                    data={controlMaterialsFetchResults.data}
                                    columns={this.columns}
                                />
                            }
                            <Dialog
                                className={styles.dialog}
                                open={this.state.editDialogOpen}
                                onClose={this.handleClose}
                            >
                                <div className={styles.content}>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            InputLabelProps={{ shrink: true }}
                                            label={"Name"}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            value={this.state.materialToEdit?.Name}
                                            onDebouncedChange={(value) => { this.setName(value) }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: "10px", display: "flex", flexDirection: "row" }}>
                                        <TextField
                                            select
                                            InputLabelProps={{ shrink: true }}
                                            label="Type"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            id="control-type"
                                            value={this.state.materialToEdit?.ControlType}
                                            onChange={(event) => { this.setType(event.target.value as string) }}
                                        >
                                            {_.map(controlTypesFetchResults.data, t => {
                                                return (<MenuItem key={t} value={t}>{t}</MenuItem>)
                                            })}
                                        </TextField>
                                        <div style={{ marginTop: "auto", marginLeft: "10px" }} onClick={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ?
                                            async () => { } :
                                            async () => {
                                                this.setState({ addTypeOpen: true });
                                            }}
                                        >
                                            <Link>
                                                <u>Add New Type</u>
                                            </Link>
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            InputLabelProps={{ shrink: true }}
                                            label={"Description"}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            value={this.state.materialToEdit?.Description}
                                            onDebouncedChange={(value) => { this.setDescription(value) }}
                                        />
                                    </div>
                                    {
                                        !this.state.newMaterial &&
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ paddingTop: "10px" }}>
                                                    Active:
                                                </div>
                                                <Checkbox disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} checked={this.state.materialToEdit?.IsActive}
                                                    onChange={(event) => {
                                                        this.setActive(event.target.checked);
                                                    }} />
                                            </div>
                                            {!this.state.materialToEdit?.IsActive && this.state.materialToEdit?.DeactivatedOn &&
                                                <div>
                                                    Deactivated On {moment(this.state.materialToEdit?.DeactivatedOn).format("L LT")}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.state.newMaterial ? this.addControlMaterial() : this.updateControlMaterial() }}
                                        disabled={
                                            value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                            (this.state.newMaterial &&
                                                !(this.state.materialToEdit && this.state.materialToEdit.ControlType && this.state.materialToEdit.Description && this.state.materialToEdit.Name))
                                        }
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                className={styles.dialog}
                                open={this.state.addTypeOpen}
                                onClose={this.handleClose}
                            >
                                <Card>
                                    <h2>
                                        Enter type name
                                    </h2>
                                    {_.find(controlTypesFetchResults.data, t => t === this.state.typeInputName) !== undefined &&
                                        <div>
                                            Duplicate Type Exists
                                        </div>}
                                    <AdvanceTextField
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onDebouncedChange={this.onTypeInputChange} />
                                </Card>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.addNewType() }}
                                        disabled={
                                            value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                            _.find(controlTypesFetchResults.data, t => t === this.state.typeInputName) !== undefined
                                        }
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleAddTypeClose}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Card>
                    </div >
                )}
            </ApplicationSecurityContext.Consumer>;
        }

        return <DataLoadingDisplay />;
    }

    @bind
    private handleClose() {
        this.setState({ editDialogOpen: false, addTypeOpen: false, newMaterial: false, typeInputName: "" });
    }

    @bind
    private handleAddTypeClose() {
        this.setState({ addTypeOpen: false, typeInputName: "" });
    }

    @bind
    private onTypeInputChange(newValue: string) {
        this.setState({ typeInputName: newValue });
    }

    @bind
    private setName(value: string) {
        let oldVal = this.state.materialToEdit;
        if (oldVal) {
            this.setState({
                materialToEdit:
                {
                    ControlType: oldVal.ControlType,
                    Description: oldVal.Description,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: oldVal.DeactivatedOn,
                    IsActive: oldVal.IsActive,
                    Id: oldVal.Id,
                    Name: value
                }
            })
        }
    }

    @bind
    private setDescription(value: string) {
        let oldVal = this.state.materialToEdit;
        if (oldVal) {
            this.setState({
                materialToEdit:
                {
                    ControlType: oldVal.ControlType,
                    Description: value,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: oldVal.DeactivatedOn,
                    IsActive: oldVal.IsActive,
                    Id: oldVal.Id,
                    Name: oldVal.Name
                }
            })
        }
    }

    @bind
    private setActive(value: boolean) {
        let oldVal = this.state.materialToEdit;
        if (oldVal) {
            this.setState({
                materialToEdit:
                {
                    ControlType: oldVal.ControlType,
                    Description: oldVal.Description,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: value ? oldVal.DeactivatedOn : new Date(Date.now()),
                    IsActive: value,
                    Id: oldVal.Id,
                    Name: oldVal.Name
                }
            })
        }
    }

    @bind
    private setType(value: string) {
        let oldVal = this.state.materialToEdit;
        if (oldVal) {
            this.setState({
                materialToEdit:
                {
                    ControlType: value,
                    Description: oldVal.Description,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: oldVal.DeactivatedOn,
                    IsActive: oldVal.IsActive,
                    Id: oldVal.Id,
                    Name: oldVal.Name
                }
            })
        }
    }

    @bind
    private async addNewType() {
        await this.props.assetService.addControlType(this.state.typeInputName);
        await this.props.assetService.fetchControlTypes(true);
        this.setState({ addTypeOpen: false, typeInputName: "" })
    }

    @bind
    private async updateControlMaterial() {
        if (this.state.materialToEdit) {
            await this.props.assetService.updateControlMaterial(this.state.materialToEdit);
            await this.props.assetService.fetchControlMaterials(true);
            this.handleClose();
        }
    }

    @bind
    private async addControlMaterial() {
        if (this.state.materialToEdit) {
            await this.props.assetService.addControlMaterial(this.state.materialToEdit);
            await this.props.assetService.fetchControlMaterials(true);
            this.handleClose();
        }
    }
}

export const ControlMaterialsPage = AssetService.inject(_ControlMaterialsPage);