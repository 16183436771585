import {
    FreezerService,
    _,
    managedAjaxUtil,
    IAjaxState,
    bind
} from "$Imports/Imports";
import { PipelineRunApiFactory, PipelineRunVM, QCApiFactory, QCCheckResultVM, QCCheckVM, WetlabRunBasespaceAnalysesVM, WorkflowQCSummaryVM } from "../generated/api";
import { apiExceptionHandler } from "./ErrorFreezerService";

const InjectedPropName = "PipelineService";

interface IPipelineServiceState {
    pipelineRuns: IAjaxState<PipelineRunVM[]>;
    currentPipelineRun: IAjaxState<PipelineRunVM>;
    pipelineRunWetlabRunBasespaceAnalyses: IAjaxState<WetlabRunBasespaceAnalysesVM[]>;
    initiateBasespacePipelineRun: IAjaxState<boolean>;
}

class PipelineFreezerService extends FreezerService<IPipelineServiceState, typeof InjectedPropName> {
    constructor() {
        super({
            pipelineRuns: managedAjaxUtil.createInitialState(),
            currentPipelineRun: managedAjaxUtil.createInitialState(),
            pipelineRunWetlabRunBasespaceAnalyses: managedAjaxUtil.createInitialState(),
            initiateBasespacePipelineRun: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async getPipelineRuns() {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'pipelineRuns',
            onExecute: async (apiOptions, param, options) => {
                let factory = PipelineRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PipelineRunPipelineRunsGet()
            },
            onError: apiExceptionHandler
        });
    }

    public async getPipelineRun(runNumber: number) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'currentPipelineRun',
            onExecute: async (apiOptions, param, options) => {
                let factory = PipelineRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PipelineRunPipelineRunGet({ runNumber });
            },
            onError: apiExceptionHandler
        });
    }

    public async getPipelineRunWetlabRunBasespaceAnalyses() {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'pipelineRunWetlabRunBasespaceAnalyses',
            onExecute: async (apiOptions, param, options) => {
                let factory = PipelineRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PipelineRunWetlabRunBasespaceAnalysesGet();
            },
            onError: apiExceptionHandler
        });
    }

    public async initiateBasespacePipelineRun(id: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'initiateBasespacePipelineRun',
            onExecute: async (apiOptions, param, options) => {
                let factory = PipelineRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PipelineRunInitiateBasespacePipelineRunPost({analysisId: id});
            },
            onError: apiExceptionHandler
        });
    }

    @bind
    public resetData() {
        this.freezer.get().set({
            pipelineRuns: managedAjaxUtil.createInitialState(),
            currentPipelineRun: managedAjaxUtil.createInitialState()
        });
    }

}

export const PipelineService = new PipelineFreezerService();
export type IPipelineServiceInjectedProps = ReturnType<PipelineFreezerService["getPropsForInjection"]>;