import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _,
    bind
} from "$Imports/Imports";

import {
    WetlabRunVM,
    PlateVM,
    StepInstanceVM,
    StepCompleteResponse,
    PCRControlInfoVM,
    GetExtractionReagentsResponse,
    WellAddRequestVM,
    PoolVM,
    LookupValueVM,
    ThresholdCycleVM,
    RtPCRResultVM,
    GetSequencingStatusResponse,
    SequencingDataVM,
    RunUserVM,
    ReagentGroupVM,
    ReagentVM,
    WetlabRunBarcodeVM,
    ComponentSetVM,
    WorkflowReagentsVM,
    WetlabRunBarcodeReagentMapVM,
    IlluminaSampleSheetVM,
    StepInstanceControlsCountVM,
    RackVM,
    WetlabRunAssetsVM,
    ChunkedUploadVM,
    FileShareApiFactory,
    DirectoryListingVM,
    MinitMinderJobStatusVM
} from "$Generated/api";

import {
    ConcentrationVolumeVM
} from "$State/WorkflowRun/WorkflowRunInternalFreezerService"

export const InjectedPropName = 'workflowRunService';

export type PassEnum = "Pass" | "Fail" | "ContinueFail" | "NotSet";

interface IWorkflowRunInternalState {
    controlsPlate: PlateVM;
    selectedControls: WellAddRequestVM[];
    plateSamplesResponse: IAjaxState<any>;
    concentrationDictionary: { [index: string]: ConcentrationVolumeVM | undefined};
    thresholdCycleDictionary: { [index: string]: ThresholdCycleVM[] | undefined};
    passFailDictionary: {[index:string]: PassEnum};
    saveConcentrationsResponse: IAjaxState<any>;
    updateRtPCRStateResponse: IAjaxState<any>;
    saveControlsResponse: IAjaxState<any>;
    useBarcodeKit: boolean;
    barcodeReagentMap: WetlabRunBarcodeReagentMapVM[];
    barcodesInUse: string[];
    barcodeReagentGroupId: string;
    controlsRack: RackVM;
}

interface IWorkflowRunExternalState {
    workflowRun: IAjaxState<WetlabRunVM>;
    stepInstances: IAjaxState<Array<StepInstanceVM>>;
    workflowRunId?: string;
    addCommentState: IAjaxState<boolean>;
    updatePlateStatusState: IAjaxState<boolean>;
    changePoolStatusState: IAjaxState<boolean>;
    selectedStepInstanceId?: string;
    displayTestText: boolean;
    plates: IAjaxState<PlateVM[]>;
    pcrInfoState: IAjaxState<PCRControlInfoVM>;
    masterMixInfoState: IAjaxState<Array<ComponentSetVM>>;
    failRunState: IAjaxState<boolean>;
    getExtractionReagentsState: IAjaxState<GetExtractionReagentsResponse>;
    completeStep: IAjaxState<StepCompleteResponse>;
    saveMasterMixResponse: IAjaxState<any>;
    updateCustomFieldsResponse: IAjaxState<any>;
    savePCRResponse: IAjaxState<any>;
    savePlateStatus: IAjaxState<any>;
    cleaningDataState: IAjaxState<Array<ComponentSetVM>>;
    savePoolVolumeState: IAjaxState<boolean>;
    savePlateWellVolumeState: IAjaxState<boolean>;
    inputPools: IAjaxState<PoolVM[]>;
    saveCleaningDataReponse: IAjaxState<any>;
    populatePoolFromPlateResponse: IAjaxState<any>;
    getConcentrationControlsState: IAjaxState<LookupValueVM[]>;
    getRtPCRState: IAjaxState<RtPCRResultVM[]>;
    updateSequencingStatusResponse: IAjaxState<any>;
    quantificationAfterEndRepairQCResponse: IAjaxState<any>;
    fetchSequencingStatusState: IAjaxState<GetSequencingStatusResponse>;
    fetchSequencingDataState: IAjaxState<SequencingDataVM[]>;
    completeWorkflow: IAjaxState<any>;
    discardRetainedAssets: IAjaxState<any>;
    fetchStepCustomFields: IAjaxState<{ [key: string]: string; }>;
    fetchRunUsers: IAjaxState<RunUserVM[]>;
    setCurrentRunUser: IAjaxState<any>;
    isCompletingStep: boolean;
    fetchInstrumentOptions: IAjaxState<string[]>;
    addInstrumentResult: IAjaxState<any>;
    fetchBarcodeKits: IAjaxState<ReagentGroupVM[]>;
    fetchBarcodeReagents: IAjaxState<ReagentVM[]>;
    fetchWorkflowRunBarcodes: IAjaxState<WetlabRunBarcodeVM[]>;
    associateBarcodeKitToRun: IAjaxState<any>;
    associateBarcodeReagentsToRun: IAjaxState<any>;
    fetchWorkflowReagentsResults: IAjaxState<WorkflowReagentsVM>;
    sampleSheetResults: IAjaxState<IlluminaSampleSheetVM>;
    previousStepControlsCount: IAjaxState<StepInstanceControlsCountVM[]>;
    racks: IAjaxState<RackVM[]>;
    workflowAssets: IAjaxState<WetlabRunAssetsVM>;
    releaseRunResult: IAjaxState<any>;
    initializeUploadResult: IAjaxState<ChunkedUploadVM>;
    updateSequencingStartTimeResponse: IAjaxState<any>;
    generateReportResult: IAjaxState<any>;
    fileShareDirectories: IAjaxState<DirectoryListingVM[]>;
    exportBugSeqCsvResult: IAjaxState<any>;
    minderJobStatus: IAjaxState<MinitMinderJobStatusVM[]>;
    workflowRunMetadata: IAjaxState<string>;
}

export type IWorkflowRunState = IWorkflowRunInternalState & IWorkflowRunExternalState;

const initialExternalState: IWorkflowRunExternalState = {
    workflowRun: managedAjaxUtil.createInitialState(),
    addCommentState: managedAjaxUtil.createInitialState(),
    plates: managedAjaxUtil.createInitialState(),
    displayTestText: false,
    updatePlateStatusState: managedAjaxUtil.createInitialState(),
    changePoolStatusState: managedAjaxUtil.createInitialState(),
    failRunState: managedAjaxUtil.createInitialState(),
    stepInstances: managedAjaxUtil.createInitialState(),
    pcrInfoState: managedAjaxUtil.createInitialState(),
    getExtractionReagentsState: managedAjaxUtil.createInitialState(),
    masterMixInfoState: managedAjaxUtil.createInitialState(),
    completeStep: managedAjaxUtil.createInitialState(),
    saveMasterMixResponse: managedAjaxUtil.createInitialState(),
    updateCustomFieldsResponse: managedAjaxUtil.createInitialState(),
    savePCRResponse: managedAjaxUtil.createInitialState(),
    savePlateStatus: managedAjaxUtil.createInitialState(),
    cleaningDataState: managedAjaxUtil.createInitialState(),
    savePoolVolumeState: managedAjaxUtil.createInitialState(),
    savePlateWellVolumeState: managedAjaxUtil.createInitialState(),
    inputPools: managedAjaxUtil.createInitialState(),
    saveCleaningDataReponse: managedAjaxUtil.createInitialState(),
    populatePoolFromPlateResponse: managedAjaxUtil.createInitialState(),
    getConcentrationControlsState: managedAjaxUtil.createInitialState(),
    getRtPCRState: managedAjaxUtil.createInitialState(),
    updateSequencingStatusResponse: managedAjaxUtil.createInitialState(),
    quantificationAfterEndRepairQCResponse: managedAjaxUtil.createInitialState(),
    fetchSequencingStatusState: managedAjaxUtil.createInitialState(),
    fetchSequencingDataState: managedAjaxUtil.createInitialState(),
    completeWorkflow: managedAjaxUtil.createInitialState(),
    discardRetainedAssets: managedAjaxUtil.createInitialState(),
    fetchStepCustomFields: managedAjaxUtil.createInitialState(),
    fetchRunUsers: managedAjaxUtil.createInitialState(),
    setCurrentRunUser: managedAjaxUtil.createInitialState(),
    isCompletingStep: false,
    fetchInstrumentOptions: managedAjaxUtil.createInitialState(),
    addInstrumentResult: managedAjaxUtil.createInitialState(),
    fetchBarcodeKits: managedAjaxUtil.createInitialState(),
    fetchBarcodeReagents: managedAjaxUtil.createInitialState(),
    fetchWorkflowRunBarcodes: managedAjaxUtil.createInitialState(),
    associateBarcodeKitToRun: managedAjaxUtil.createInitialState(),
    associateBarcodeReagentsToRun: managedAjaxUtil.createInitialState(),
    fetchWorkflowReagentsResults: managedAjaxUtil.createInitialState(),
    sampleSheetResults: managedAjaxUtil.createInitialState(),
    previousStepControlsCount: managedAjaxUtil.createInitialState(),
    racks: managedAjaxUtil.createInitialState(),
    workflowAssets: managedAjaxUtil.createInitialState(),
    releaseRunResult: managedAjaxUtil.createInitialState(),
    initializeUploadResult: managedAjaxUtil.createInitialState(),
    updateSequencingStartTimeResponse: managedAjaxUtil.createInitialState(),
    generateReportResult: managedAjaxUtil.createInitialState(),
    fileShareDirectories: managedAjaxUtil.createInitialState(),
    exportBugSeqCsvResult: managedAjaxUtil.createInitialState(),
    minderJobStatus: managedAjaxUtil.createInitialState(),
    workflowRunMetadata: managedAjaxUtil.createInitialState()
}

const initialInternalState: IWorkflowRunInternalState = {
    controlsPlate: {
        Id: "controlsPlate",
        Name: "controlsPlate",
        Status: "ReadyForUse",
        WellMaterial: "DNA",
        Barcode: "",
        WellContents: [],
    },
    plateSamplesResponse: managedAjaxUtil.createInitialState(),
    selectedControls: [],
    concentrationDictionary: {},
    thresholdCycleDictionary: {},
    passFailDictionary: {},
    saveConcentrationsResponse: managedAjaxUtil.createInitialState(),
    updateRtPCRStateResponse: managedAjaxUtil.createInitialState(),
    saveControlsResponse: managedAjaxUtil.createInitialState(),
    useBarcodeKit: true,
    barcodeReagentGroupId: "",
    barcodeReagentMap: [],
    barcodesInUse: [],
    controlsRack: {
        Id: "controlsRack",
        Name: "controlsRack",
        Status: "ReadyForUse",
        WellMaterial: "DNA",
        Barcode: "",
        WellContents: [],
    }
}

export const initialState: IWorkflowRunState = {...initialInternalState, ...initialExternalState};

