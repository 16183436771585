import {
    FreezerService,
    _,
    managedAjaxUtil,
    IAjaxState,
    bind
} from "$Imports/Imports";

import { 
    WorkflowRunApiFactory,
    MinitMinderApiFactory,
    FileShareApiFactory,
    WetlabRunVM,
    GetWetlabRunsResponse,
    SequencingDataVM,
    SequencingStatus,
    GetSequencingStatusResponse,
    ModuleInfoVM,
    MinitMinderJobStatusVM,
    DirectoryListingVM
} from "../generated/api";

import { apiExceptionHandler } from "$State/ErrorFreezerService";
const InjectedPropName = "ResultsService";

export interface IResultsState {
    WorkflowRuns: IAjaxState<GetWetlabRunsResponse>[];
    currentPage: number;
    totalPageCount: number;
    totalCount: number;
    fetchSequencingDataState: IAjaxState<{[key:string]: SequencingDataVM[]}>;
    workflowRun: IAjaxState<WetlabRunVM>;
    workflowRunIds: string[];
    modulesResults: IAjaxState<ModuleInfoVM[]>;
    directoryResults: {[key:string]: IAjaxState<DirectoryListingVM[]>};
    runReportFiles: IAjaxState<DirectoryListingVM[]>;
}

const pageSize = 100;

class ResultsFreezerService extends FreezerService<IResultsState, typeof InjectedPropName> {
    constructor() {
        super({
            WorkflowRuns: [managedAjaxUtil.createInitialState()],
            currentPage: 0,
            totalPageCount: 0,
            totalCount: 0,
            fetchSequencingDataState: managedAjaxUtil.createInitialState(),
            workflowRun: managedAjaxUtil.createInitialState(),
            workflowRunIds: [],
            modulesResults: managedAjaxUtil.createInitialState(),
            directoryResults: {},
            runReportFiles: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public get totalCount(): number {
        return this.freezer.get().totalCount;
    }

    public get pageSize(): number {
        return pageSize;
    }

    public async fetchWorkflowRuns(forceUpdate: boolean = false) {
        
        if(forceUpdate === true)
        {
            //Reset pagination state.
            this.freezer.get().set({WorkflowRuns: [managedAjaxUtil.createInitialState()], currentPage: 0, totalPageCount: 0, totalCount: 0});
        }
        
        const { WorkflowRuns, currentPage, totalPageCount } = this.freezer.get();
        
        if (WorkflowRuns[currentPage].hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            getAjaxState: (options) => {
                return this.freezer.get().WorkflowRuns[this.freezer.get().currentPage].toJS();
            },
            setAjaxState: (options, newStatus) => {
                const workflowRunFetchResults = this.freezer.get().WorkflowRuns.toJS();
                workflowRunFetchResults[this.freezer.get().currentPage] = newStatus;
                this.freezer.get().set({ WorkflowRuns: workflowRunFetchResults });
            },
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunGetWorkflowRunsPageGet(param);
            },
            onOk: (data: GetWetlabRunsResponse) => {
                const workflowRunFetchResults = this.freezer.get().WorkflowRuns.toJS();
                workflowRunFetchResults[this.freezer.get().currentPage].data = data;
                this.freezer.get().set({ WorkflowRuns: workflowRunFetchResults });
            },
            onError: apiExceptionHandler,
            params:
            {
                page: currentPage,
                pageSize: pageSize
            }
        });
        let pageList = this.freezer.get().WorkflowRuns.toJS();
        let initialResults = pageList[0];

        if (initialResults.hasFetched && initialResults.data && initialResults.data.TotalPageCount && totalPageCount === 0) {
            for (var x = 1; x < initialResults.data.TotalPageCount; x++) {
                pageList.push(managedAjaxUtil.createInitialState());
            }
            this.freezer.get().set({ WorkflowRuns: pageList, totalPageCount: initialResults.data.TotalPageCount, totalCount: initialResults.data.TotalRunCount });
        }

    }

    public async fetchWorkflowRunSequencingData(forceUpdate?: boolean) {
        const { fetchSequencingDataState, workflowRunIds } = this.freezer.get();

        
        if (workflowRunIds && (!fetchSequencingDataState.hasFetched || forceUpdate)) {
            let resultsToGet = this.getState().WorkflowRuns[0].data?.Runs.filter((value) => {
                return value.SequencingStatus != "Complete";
            }).map((value) => {
                return value.Id;
            });
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchSequencingDataState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetSequencingDataGet();
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async fetchDirectoryContents(path: string = "", minderJobs: IAjaxState<MinitMinderJobStatusVM[]>, forceUpdate?: boolean) {
        const { directoryResults } = this.freezer.get();

        if (minderJobs && (!directoryResults.hasFetched || forceUpdate)) {
            let resultsToGet = minderJobs.data?.filter((value) => {
                return value.Status == "FINISHED";
            }).map((value) => {
                return path + value.RunId + "-" + value.Tag;
            });
            for (var id of resultsToGet!) {
                try {
                    await managedAjaxUtil.fetchResults({
                        freezer: this.freezer,
                        getAjaxState: (options) => {
                            var state = this.freezer.get().directoryResults.toJS();
                            if (!(id in state)) {
                                state[id] = managedAjaxUtil.createInitialState();
                                this.freezer.get().set({ directoryResults: state });
                            }
                            return this.freezer.get().directoryResults[id].toJS();
                        },
                        setAjaxState: (options, newStatus) => {
                            const directoryfetchResults = this.freezer.get().directoryResults.toJS();
                            directoryfetchResults[id] = newStatus;
                            this.freezer.get().set({ directoryResults: directoryfetchResults });
                        },
                        onExecute: (apiOptions, param, options) => {
                            const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                            return factory.apiV1FileShareGetFilesPathGet({path: id});
                        },
                        onOk: (data: DirectoryListingVM[]) => {
                            const directoryfetchResults = this.freezer.get().directoryResults.toJS();
                            directoryfetchResults[id].data = data;
                            this.freezer.get().set({ directoryResults: directoryfetchResults });
                        },
                        onError: apiExceptionHandler,
                    });
                } catch (err) {
                    continue;
                }
            }
        }
    }

    public async fetchModules()
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "modulesResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderModulesGet();
            },
        })
    }

    public async fetchRunReports()
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "runReportFiles",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareGetFilesPathGet({path: "run-reports"});
            },
        })
    }
}

export const ResultsService = new ResultsFreezerService();
export type IResultsServiceInjectedProps = ReturnType<ResultsFreezerService["getPropsForInjection"]>;
export const FileSharingAPI: string = "/api/v1/FileShare/";