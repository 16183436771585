import {
    React,
    bind,
    _
} from "$Imports/Imports";

import * as URI from 'urijs';

import {
    Card
} from "$Imports/MaterialUIComponents";

import {
    WorkflowRunService,
    IWorkflowRunServiceInjectedProps
} from "$State/WorkflowRun/WorkflowRunFreezerService";

import {
    WorkflowScreenManager
} from "$Components/WorkflowStep/WorkflowScreenManager"


import {
    AjaxActionIndicator, DataLoadingDisplay, SimpleStatusHeader, WorkflowOrderSidebar
} from "$Imports/CommonComponents";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { NavigationService } from "$State/NavigationFreezerService";


interface IWorkflowRunBaseProps {
    runId: string;
    stepInstanceId?: string;
}

interface IWorkflowRunState {
    viewMode?: boolean;
}

const styles: {
    mainContainer: string;
    componentSection: string;
} = require("./WorkflowRun.scss");

export type IWorkflowRunProps = IWorkflowRunBaseProps & IWorkflowRunServiceInjectedProps;

class _WorkflowRunPage extends React.Component<IWorkflowRunProps, IWorkflowRunState> {

    state: IWorkflowRunState = { viewMode: true };

    async componentDidMount() {

        if (this.props.runId) {
            this.props.workflowRunService.workflowId = this.props.runId;
            this.props.workflowRunService.selectedStepInstanceId = ""; //reset
            await this.props.workflowRunService.fetchWorkflowRun(true);
        }
        if (this.props.stepInstanceId && this.props.stepInstanceId !== "first") {
            this.props.workflowRunService.selectedStepInstanceId = this.props.stepInstanceId;
        } else {
            const { workflowRun } = this.props.workflowRunService.freezer.get();
            this.props.workflowRunService.selectedStepInstanceId = workflowRun.data!.Steps[0].StepInstanceId;
        }

        await this.canUserEditRun();
    }

    private async canUserEditRun()
    {
        let security = new ApplicationSecuritySettings();
        await this.props.workflowRunService.fetchRunUsers(true);
        const runUsers = this.props.workflowRunService.freezer.get().fetchRunUsers.data;
        const userContext = security.userContext;
        if(runUsers && userContext)
        {
            var currentUser = _.find(runUsers, ru => ru.IsCurrentUser);
            if(currentUser)
            {
                if(currentUser.UserId !== userContext.preferred_username)
                {
                    this.setState({viewMode: true})
                }
                else
                {
                    this.setState({viewMode: false})
                }
            }
            else
            {
                //Set self as current user
                await this.props.workflowRunService.setCurrentRunUser(userContext.preferred_username);
                this.setState({viewMode: false})
            }
        }
    }


    @bind
    goToStep(stepId: string) {
        let state = this.props.workflowRunService.getState();
        if (state.stepInstances.hasFetched && state.stepInstances.data) {
            let stepInstance = state.stepInstances.data.find((step) => {
                return step.StepId === stepId;
            });
            if (stepInstance) {
                this.props.workflowRunService.selectedStepInstanceId = stepInstance.StepInstanceId;
            }
        }
    }

    render() {
        const state = this.props.workflowRunService.getState();
        const {
            workflowRun,
            stepInstances,
        } = state;
        if (workflowRun.hasFetched) {
            const currentStep = this.props.workflowRunService.currentStep;
            let orderedStepData = _.orderBy(stepInstances.data, (step) => {
                return step.WorkflowOrder
            });
            return (
                <Card>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        {(currentStep && <WorkflowOrderSidebar />)}
                        <div className={styles.mainContainer}>
                            <AjaxActionIndicator
                                state={workflowRun}
                            />
                            <Card className={styles.componentSection}>
                                {
                                    workflowRun.hasFetched && currentStep &&
                                    <SimpleStatusHeader
                                        goToStep={this.goToStep}
                                        workflowRun={workflowRun}
                                        currentStep={currentStep}
                                        previousStep={currentStep.WorkflowOrder > 1 ? orderedStepData[currentStep.WorkflowOrder - 2] : undefined}
                                        nextStep={currentStep.WorkflowOrder < orderedStepData.length ? orderedStepData[currentStep.WorkflowOrder] : undefined}
                                        allSteps={orderedStepData}
                                    />
                                }
                                {
                                    workflowRun.hasFetched && workflowRun.data &&
                                    <WorkflowScreenManager 
                                        viewMode={this.state.viewMode}
                                    />
                                }
                            </Card>
                        </div>
                    </div>
                </Card>
            )
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private handleClick(actionType: number) {
        //Used if we need to reload Data after an action is performed
    }
}

export const WorkflowRunPage = WorkflowRunService.inject(_WorkflowRunPage);