import { PipelineRunVM } from "$Generated/api";
import { DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Button, Card } from "$Imports/MaterialUIComponents";

import { IPipelineServiceInjectedProps, PipelineService } from "$State/PipelineFreezerService";

import { NavigationService } from "$State/NavigationFreezerService";
import { getStatusText } from "$Components/common/PipelineRuns/PipelineAnalysis";
import { Send } from "$Imports/MaterialUIIcons";
var urljoin = require('url-join');

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    divPadding: string;
    tablePadding: string;
    continueButtonStyle: string;
    cardHeader: string;
} = require("./NewPipelineRun.scss");


interface INewPipelineRunBaseProps {
}

interface INewPipelineRunState {
}

type INewPipelineRunProps = INewPipelineRunBaseProps & IPipelineServiceInjectedProps;

export class _NewPipelineRunPage extends React.Component<INewPipelineRunProps, INewPipelineRunState> {


    private readonly pipelineColumns: Array<IDataTableColumn<{name: string, pipelineDescription: string}>> = [
        {
            columnName: "Name",
            columnFieldData: "name",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "Description",
            columnFieldData: "pipelineDescription",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Pipeline Description",
        },
        {
            columnName: "StartPipeline",
            columnFieldData: (d) => {
                return (
                    <Button
                        onClick={() => {
                            NavigationService.navigateTo("/pipelineSampleSelection");
                        }}
                        color="primary"
                        variant="contained"
                        endIcon={<Send />}
                    >
                        Begin
                    </Button>)
            },
            headerValue: ""
        }

    ];

    state: INewPipelineRunState = {
    }


    async componentDidMount() {
    }

    render() {
        return <div className={styles.mainContainer}>
            <Card
                className={styles.cardStyle}
            >
                <div className={styles.cardHeader}>
                    <h2>Pipelines</h2>
                    <div><i>Select a pipeline to start.</i></div>
                </div>
                <div>
                <DataTable
                            data={[{name: "Cecret", pipelineDescription: "Pipeline used for viral sequencing workflows; originally developed for SARS-CoV-2 assembly and annotation"}]}
                            columns={this.pipelineColumns}
                        />
                </div>
            </Card>
        </div>
    }
}

export const NewPipelineRunPage = PipelineService.inject(_NewPipelineRunPage);