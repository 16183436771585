import { MetadataTemplateVM } from "$Generated/api";
import { COV2SampleAndWetlabTemplates, COV2SampleWetlabAndDrylabTemplates } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind
} from "$Imports/Imports";
import { Dialog, Card, Button } from "$Imports/MaterialUIComponents";

const styles: {
    cardStyle: string;
    fileSelectionButton: string;
    uploadMetadataPaperProps: string;
    fileSelectionDiv: string;
    metadataUploadHeader: string;
    uploadButtonStyle: string;
    uploadButtonDiv: string;
} = require("./SampleManagement.scss");


interface IUploadMetadataControlBaseProps {
    open: boolean;
    template?: MetadataTemplateVM
    close: () => void;
    upload: (sampleMetadata: string, wetlabMetadata: string, drylabMetadata: string) => void;
}

interface IUploadMetadataControlState {
    sampleFileName: string,
    wetlabFileName: string,
    drylabFileName: string,
    sampleData: string,
    wetlabData: string,
    drylabData: string
}

type IUploadMetadataControlProps = IUploadMetadataControlBaseProps;

//Control for uploading samples/metadata to the system.
export class UploadMetadataControl extends React.Component<IUploadMetadataControlProps, IUploadMetadataControlState> {

    state: IUploadMetadataControlState = {
        sampleFileName: "",
        sampleData: "",
        wetlabFileName: "",
        wetlabData: "",
        drylabFileName: "",
        drylabData: ""
    }

    async componentDidUpdate(prevProps: IUploadMetadataControlProps) {
        if (this.props.open && !prevProps.open) {
        }
    }

    private sampleMetadataButton() {
        document.getElementById('sampleMetadataButton')?.click();
    }

    private wetlabMetadataButton() {
        document.getElementById('wetlabMetadataButton')?.click();
    }

    private drylabMetadataButton() {
        document.getElementById('drylabMetadataButton')?.click();
    }

    //Load Sample File into state
    @bind
    private saveSampleMetadataFile() {
        const file = document.getElementById('sampleMetadataButton') as HTMLInputElement;
        if (file.files != null) {
            this.setState({ sampleFileName: file.files[0].name });
            const reader = new FileReader();
            reader.onload = () => { this.setState({ sampleData: reader.result as string }); };
            reader.readAsText(file.files[0]);
        }
    }

    //Load Wetlab File into state
    @bind
    private saveWetlabMetadataFile() {
        const file = document.getElementById('wetlabMetadataButton') as HTMLInputElement;
        if (file.files != null) {
            this.setState({ wetlabFileName: file.files[0].name });
            const reader = new FileReader();
            reader.onload = () => { this.setState({ wetlabData: reader.result as string }); };
            reader.readAsText(file.files[0]);
        }
    }

    //Load Drylab File into state
    @bind
    private saveDrylabMetadataFile() {
        const file = document.getElementById('drylabMetadataButton') as HTMLInputElement;
        if (file.files != null) {
            this.setState({ drylabFileName: file.files[0].name });
            const reader = new FileReader();
            reader.onload = () => { this.setState({ drylabData: reader.result as string }); };
            reader.readAsText(file.files[0]);
        }
    }

    render() {
        return <Dialog
            open={this.props.open}
            onClose={this.props.close}
            PaperProps={{className: styles.uploadMetadataPaperProps}}
        >
            <Card className={styles.cardStyle}>
                <div className={styles.metadataUploadHeader}>
                    <h2>{this.props.template?.Name}</h2>
                    <h3>Choose the appropriate file(s) below to upload.</h3>
                </div>
                <div>
                    {/*Only show the selection buttons that are relevant for each template. Currently hardcoded for COV2, we will need to revisit this approach in the future*/}
                    <div className={styles.fileSelectionDiv}>
                        <input id="sampleMetadataButton" type="file" hidden onChange={this.saveSampleMetadataFile} />
                        <h3>Sample Metadata File
                            <Button className={styles.fileSelectionButton} variant="contained" color="primary" onClick={this.sampleMetadataButton}>Choose File</Button>
                            <i>{this.state.sampleFileName}</i></h3>
                    </div>
                    {(this.props.template && (this.props.template.Id === COV2SampleAndWetlabTemplates || this.props.template.Id === COV2SampleWetlabAndDrylabTemplates)) && <div className={styles.fileSelectionDiv}>
                        <input id="wetlabMetadataButton" type="file" hidden onChange={this.saveWetlabMetadataFile} />
                        <h3>Wet Lab Metadata File
                            <Button className={styles.fileSelectionButton} variant="contained" color="primary" onClick={this.wetlabMetadataButton}>Choose File</Button>
                            <i>{this.state.wetlabFileName}</i></h3>
                    </div>}

                    {(this.props.template && (this.props.template.Id === COV2SampleWetlabAndDrylabTemplates)) && <div className={styles.fileSelectionDiv}>
                        <input id="drylabMetadataButton" type="file" hidden onChange={this.saveDrylabMetadataFile} />
                        <h3>Dry Lab Metadata File
                            <Button className={styles.fileSelectionButton} variant="contained" color="primary" onClick={this.drylabMetadataButton}>Choose File</Button>
                            <i>{this.state.drylabFileName}</i></h3>
                    </div>}
                </div>
                <div className={styles.uploadButtonDiv}>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={async () => {
                            await this.props.upload(this.state.sampleData, this.state.wetlabData, this.state.drylabData);
                            this.setState({
                                sampleFileName: "",
                                sampleData: "",
                                wetlabFileName: "",
                                wetlabData: "",
                                drylabFileName: "",
                                drylabData: ""
                            });
                        }}
                        className={styles.uploadButtonStyle}
                    >
                        Upload
                    </Button>
                </div>
            </Card>
        </Dialog>;
    }
}