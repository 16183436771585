import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { GetWorkflowType, IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, StandardPlate, IDataTableColumn, SampleInfoTable, CollapsibleSection, DataLoadingDisplay } from "$Imports/CommonComponents";
import { WellContentVM } from "$Generated/api";
import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";
import { RadioGroup, FormControlLabel, Radio, Snackbar } from "@material-ui/core";
import { isNullOrUndefined } from "$Utilities/helpers";

const styles: {
    tableHeader: string;
    sampleInfoDiv: string;
    plateDiv: string;
    reagentRowDiv: string;
    reagentInput: string;
} = require("./RNAExtraction.scss");

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");

export interface IRegridState {
    regriddedPositionDictionary: { [index: string]: number }
    usePlate: boolean;
    messageSnackbarOpen: boolean;
    message: string;
    dataLoaded: boolean;
}

export class RegridScreen extends React.Component<IWorkflowScreenProps, IRegridState> {

    state: IRegridState = { regriddedPositionDictionary: {}, usePlate: true, message: "", messageSnackbarOpen: false, dataLoaded: false };


    private columns(): Array<IDataTableColumn<WellContentVM>> {
        const regriddedPositionDictionary = this.state.regriddedPositionDictionary;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample ID",
                    sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
                },
                {
                    columnName: "sample-well-position",
                    columnFieldData: (d) => ConvertPositionToString(d.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Well Position",
                    sortMethod: (d) => d.WellPosition ?? ""
                },
                {
                    columnName: "sample-move-to",
                    columnFieldData: (d) => {
                        if (d.Sample && !isNullOrUndefined(regriddedPositionDictionary[d.Sample.Id])) {
                            return ConvertPositionToString(regriddedPositionDictionary[d.Sample.Id], currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow);
                        }
                        else if (d.Control && !isNullOrUndefined(regriddedPositionDictionary[d.Control.Id])) {
                            return ConvertPositionToString(regriddedPositionDictionary[d.Control.Id], currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow);
                        }

                        return "Failed";
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Moving To",
                }
            ];
        }
        return [];
    }

    async componentDidMount() {
        if (GetWorkflowType() === "Anthrax"){
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }

        const plates = this.props.workflowRunService.getState().plates;
        const currentStep = this.props.workflowRunService.currentStep;
        if (plates.data && currentStep) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            let outputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.OutputName });
            if (inputPlate && outputPlate) {
                let regriddedPositionDictionary: { [index: string]: number } = {}

                _.forEach(inputPlate.WellContents, well => {
                    if (well.ContentType === "Sample" && well.Sample && outputPlate) {
                        if (well.RtpcrPass === "Pass") {
                            const wellMovedTo = _.find(outputPlate.WellContents, outWell => {
                                if (well.Sample && outWell.Sample && (outWell.Sample.Id === well.Sample.Id)) {
                                    return true;
                                }
                                return false;
                            });
                            if (wellMovedTo !== undefined) {
                                regriddedPositionDictionary[well.Sample.Id] = wellMovedTo.WellPosition;
                            }
                        }
                    }
                    else if (well.Control && outputPlate) {
                        if (well.RtpcrPass === "Pass") {
                            const wellMovedTo = _.find(outputPlate.WellContents, outWell => {
                                if (well.Control && outWell.Control && (outWell.Control.Id === well.Control.Id)) {
                                    return true;
                                }
                                return false;
                            });
                            if (wellMovedTo !== undefined) {
                                regriddedPositionDictionary[well.Control.Id] = wellMovedTo.WellPosition;
                            }
                        }
                    }
                });
                this.setState({ regriddedPositionDictionary: regriddedPositionDictionary });
            }
        }
        this.setState({dataLoaded: true});
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {

        const plates = this.props.workflowRunService.getState().plates;
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (plates.data && currentStep && currentWorkflowRun) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            let outputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.OutputName });

            if (currentWorkflowRun && currentStep && inputPlate && outputPlate) {
                return (<div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={commonStyles.collapsibleDiv}>
                            <RadioGroup row value={this.state.usePlate} onChange={this.handleRadioChange} >
                                <FormControlLabel value={true} control={<Radio />} label="View Plate" />
                                <FormControlLabel value={false} control={<Radio />} label="View Table" />
                            </RadioGroup>
                            {!this.state.usePlate && <div>
                                <h2>{inputPlate.Name} moves to {outputPlate.Name}</h2>
                                <SampleInfoTable
                                    data={inputPlate.WellContents}
                                    columns={this.columns()}
                                    header={""}
                                    defaultSortColumnName={"sample-well-position"}
                                />
                            </div>}
                            {this.state.usePlate &&
                                <div className={styles.sampleInfoDiv}>

                                    <div className={styles.plateDiv}>
                                        <h2 style={{ margin: "0px" }}>{inputPlate.Name}</h2>
                                        <StandardPlate
                                            wellContents={inputPlate.WellContents}
                                            wellCount={96}
                                            columnCount={12}
                                            positionByRow={false}
                                            additionalTooltipInformation={this.additionalTooltipInformation}
                                        />
                                    </div>
                                    <div className={styles.plateDiv}>
                                        <h2 style={{ margin: "0px" }}>{outputPlate.Name}</h2>
                                        <StandardPlate
                                            wellContents={outputPlate.WellContents}
                                            wellCount={96}
                                            columnCount={12}
                                            positionByRow={false}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </CollapsibleSection>
                    <div className={commonStyles.footerDiv}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} hideArchiveRetain={inputPlate.WellContents[0].RunCount >= currentWorkflowRun.WorkflowMaxSampleRun} />
                        <StepChangeControl disabled={currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress"} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={this.state.messageSnackbarOpen}
                        message={this.state.message}
                        autoHideDuration={5000}
                        onClose={this.snackbarClose}
                    />
                </div >)
            }
        }

        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ usePlate: (event.target as HTMLInputElement).value === "true" });
    }

    @bind
    private additionalTooltipInformation(well: WellContentVM) {
        const regriddedPositionDictionary = this.state.regriddedPositionDictionary;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            if (well.Sample && !isNullOrUndefined(regriddedPositionDictionary[well.Sample.Id])) {
                return " moves to " + ConvertPositionToString(regriddedPositionDictionary[well.Sample.Id], currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow);
            }
            else if (well.Control && !isNullOrUndefined(regriddedPositionDictionary[well.Control.Id])) {
                return " moves to " + ConvertPositionToString(regriddedPositionDictionary[well.Control.Id], currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow);
            }
        }
        return " Failed";
    }

    @bind
    private async moveToNextStep() {

        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentStep) {

            let inputAsset = _.find(currentStep.toJS().InputAssets, a => a.Name === currentStep.InputName)
            if (inputAsset && inputAsset.PlateSummary && inputAsset.PlateSummary.Status !== "Archived") {

                const plates = this.props.workflowRunService.getState().plates;
                const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));

                if (currentWorkflowRun && inputPlate && inputPlate.WellContents[0].RunCount < currentWorkflowRun.WorkflowMaxSampleRun) {
                    this.setState({ messageSnackbarOpen: true, message: inputAsset.Name + " must be archived before continuing" });
                    return;
                }
                else if (inputPlate) {
                    //Auto discard plate
                    await this.props.workflowRunService.updatePlateStatus([inputPlate.Id], "Discarded", false);
                }
            }
            await this.props.workflowRunService.completeStep();
        }
    }
}