import { MinitMinderApiFactory, MinitMinderJobStatusVM, ModuleInfoVM, SimplifiedMinderRunStatusVM, SimulatorStartVM, SimulatorStatusVM } from "$Generated/api";
import {
    FreezerService, IAjaxState, managedAjaxUtil,
} from "$Imports/Imports";

interface IMinitMinderFreezerState {
    jobStatusResults: IAjaxState<MinitMinderJobStatusVM[]>;
    simulatorStatusResults: IAjaxState<SimulatorStatusVM>;
    postResults: IAjaxState<boolean>;
    modulesResults: IAjaxState<ModuleInfoVM[]>;
    simplifiedRunStatusResults: IAjaxState<SimplifiedMinderRunStatusVM[]>;
    runStatusResults: IAjaxState<SimplifiedMinderRunStatusVM>;
    startNBSRunResults: IAjaxState<boolean>;
}

const InjectedPropName = "MinitMinderService";

class MinitMinderFreezerService extends FreezerService<IMinitMinderFreezerState, typeof InjectedPropName> {
    constructor() {
        super({
            jobStatusResults: managedAjaxUtil.createInitialState(),
            simulatorStatusResults: managedAjaxUtil.createInitialState(),
            postResults: managedAjaxUtil.createInitialState(),
            modulesResults: managedAjaxUtil.createInitialState(),
            runStatusResults: managedAjaxUtil.createInitialState(),
            simplifiedRunStatusResults: managedAjaxUtil.createInitialState(),
            startNBSRunResults: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }


    public async fetchJobStatuses(forceUpdate?: boolean) {
        const { jobStatusResults } = this.freezer.get();

        if (jobStatusResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "jobStatusResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderJobStatusGet();
            },
        });
    }

    public async startJob(runTag: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "postResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderStartJobPut({runTag: runTag});
            },
        });
    }

    public async finishJob(runTag: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "postResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderFinishJobPost({runTag: runTag});
            },
        });
    }

    public async stopJob(runTag: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "postResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderStopJobPost({runTag: runTag});
            },
        });
    }

    public async fetchSimulatorStatus(forceUpdate?: boolean) {
        const { simulatorStatusResults } = this.freezer.get();

        if (simulatorStatusResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "simulatorStatusResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderSimulatorStatusGet();
            },
        });
    }

    public async startSimulator(simulatorParams: SimulatorStartVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "postResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderStartSimulatorPut({body: simulatorParams});
            },
        });
    }

    public async stopSimulator()
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "postResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderStopSimulatorPost();
            },
        });
    }

    public async fetchModules()
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "modulesResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderModulesGet();
            },
        })
    }

    public async fetchSimplifiedRun(runNumber: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "runStatusResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderSimplifiedMinderRunGet({runNumber});
            },
        })
    }

    public async fetchSimplifiedRuns()
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "simplifiedRunStatusResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderSimplifiedMinderRunsGet();
            },
        })
    }

    public async startNBSRun(towerToken: string, s3Buckets: string, runNumber: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "startNBSRunResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderStartRunPost({towerToken, s3Buckets, runNumber});
            },
        })
    }
}

export const MinitMinderService = new MinitMinderFreezerService();
export type IMinitMinderServiceInjectedProps = ReturnType<MinitMinderFreezerService["getPropsForInjection"]>;
