import {
    React
} from "$Imports/Imports";

import {
    TableCell,
    Tooltip,
} from "$Imports/MaterialUIComponents";


import {
    WellContentVM
} from "$Generated/api"

const styles: {
    plateWell: string;
    wellWithSample: string;
    wellWithControl: string;
    wellWithPTC: string;
    wellWithNTC: string;
    wellWithFailedSample: string;
    disabledWell: string;
    tooltip: string;
} = require("./StandardPlateStyles.scss");

interface IStandardPlateWellProps {
    wellContent: WellContentVM | null;
    wellClick?: (position: number) => void;
    position: number;
    selected?: boolean;
    disabledAfterWell?: number;
    additionalTooltipInformation?: (well: WellContentVM) => string;
}

export class StandardPlateWell extends React.PureComponent<IStandardPlateWellProps> {

    render() {
        let wellStyle = "";

        let { wellContent } = this.props;
        const disabled = this.props.disabledAfterWell !== undefined && this.props.position >= this.props.disabledAfterWell;
        if (disabled) {
            wellStyle = styles.disabledWell;
        }
        else if (wellContent && (wellContent.RtpcrPass === "Fail" || wellContent.RtpcrPass === "ContinueWithFailed")) {
            wellStyle = styles.wellWithFailedSample;
        }
        else if (wellContent && wellContent.Control && !wellContent.Control.SampleId?.includes("BC")) {
            wellStyle = wellContent.Control.Name === "Known" ? styles.wellWithControl
                : (wellContent.Control.Name === "NTC" ? styles.wellWithNTC : styles.wellWithPTC);
        }
        else if (wellContent && wellContent.Sample) {
            wellStyle = styles.wellWithSample;
        }
        else if (wellContent && wellContent.Control && wellContent.Control.SampleId?.includes("BC") && wellContent.Control.Name !== "") {
            wellStyle = wellContent.Control.Name === "NTC" ? styles.wellWithNTC : styles.wellWithPTC;
        }

        //Display 
        let wellDisplay = "";
        if (wellContent) {
            if ((wellContent.RtpcrPass === "Fail" || wellContent.RtpcrPass === "ContinueWithFailed")) {
                wellDisplay = "F"
            }
            else {
                if (wellContent.Control && wellContent.Control.SampleId) {
                    //Normal Barcodes
                    if(wellContent.Control.SampleId?.includes("BC"))
                    {
                        wellDisplay = wellContent.Control.SampleId.slice(-4);
                    }
                    //I5/I7 Barcodes
                    else
                    {
                        wellDisplay = wellContent.Control.SampleId.slice(-7).substring(0,4);
                    }
                    
                }
                if (wellContent.Sample && wellContent.Sample.SampleId) {
                     //Normal Barcodes
                     if(wellContent.Sample.SampleId?.includes("BC"))
                     {
                         wellDisplay = wellContent.Sample.SampleId.slice(-4);
                     }
                     //I5/I7 Barcodes
                     else
                     {
                         wellDisplay = wellContent.Sample.SampleId.slice(-7).substring(0,4);
                     }
                }
            }
        }

        return (
            <TableCell
                style={this.props.selected ? { padding: "5px", borderStyle: "solid", borderWidth: "4px", borderColor: "#fffd70" } : { padding: "5px" }}
            >
                {
                    wellContent ?
                        <Tooltip
                            title={<div className={styles.tooltip}>{(wellContent.Sample ? wellContent.Sample.SampleId : (wellContent.Control ? wellContent.Control.Name : "")) + (this.props.additionalTooltipInformation ? this.props.additionalTooltipInformation(wellContent) : "")}</div>}
                            enterDelay={300}
                            placement={"top"}
                            arrow={true}
                        >
                            <div onClick={() => {
                                if (this.props.wellClick && !disabled) {
                                    this.props.wellClick(this.props.position);
                                }
                            }}
                                className={`${styles.plateWell} ${wellStyle}`}>
                                <div style={{ marginTop: "8px" }}>
                                    {wellDisplay}
                                </div>
                            </div>
                        </Tooltip>
                        :
                        <div onClick={() => {
                            if (this.props.wellClick && !disabled) {
                                this.props.wellClick(this.props.position);
                            }
                        }}
                            className={`${styles.plateWell} ${wellStyle}`} />
                }
            </TableCell>
        );
    }
}
