import {
    ISecurityContext
} from "./ISecurityContext";

import {
    IUserContext
} from "./IUserContext";

import {
    _
} from "$Imports/Imports";

import {
    getLogger
} from "@yahara/logging";

const ApplicationStorageKey: string = "APPLICATION_JWT";
const UserStorageKey: string = "USER_JWT";

// Represents the claims encoded in the token.
interface IRawClaims {

}

export class ApplicationSecuritySettings {

    private readonly _logger = getLogger("AuthenticationSecuritySettings");
    private readonly _localStorage = window.localStorage;

    public set applicationJWT(token: string | null) {
        this._localStorage.setItem(ApplicationStorageKey, token ?? "");
    }

    public get applicationJWT(): string | null {
        return this._localStorage.getItem(ApplicationStorageKey);
    }

    public static clearSessionInfo() {
        let settings = new ApplicationSecuritySettings();
        settings.applicationJWT = "";
        settings.userJWT = "";
    }

    public get userJWT(): string | null {
        return this._localStorage.getItem(UserStorageKey);
    }

    public set userJWT(token: string | null) {
        this._localStorage.setItem(UserStorageKey, token ?? "");
    }

    public get securityContext(): ISecurityContext | null {
        const rawClaims = this.getRawClaims<ISecurityContext>(this.applicationJWT ?? "");

        if (rawClaims) {
            // Parse the raw claims to a security context.
            return rawClaims;
        }

        return null;
    }

    public get userContext(): IUserContext | null {
        const rawClaims = this.getRawClaims<IUserContext>(this.userJWT ?? "");

        if (rawClaims) {
            // Parse the raw claims to a user context.
            return rawClaims;
        }

        return null;
    }

    public get isLoggedIn(): boolean {
        const context = this.securityContext;

        return context !== null;
    }

    private getRawClaims<T = IRawClaims>(token: string): T | null {
        let rawClaims: T | null = null;

        try {
            const payload: string = token.split(".")[1];
            const data: T = JSON.parse(atob(payload));

            if (token !== "" && _.isObject(data)) {
                rawClaims = data as T;
            }

        } catch (e) {
            return null;
        }

        return rawClaims;
    }

}