import {
    React,
    bind,
    moment,
    _,
    ReactGA
} from "$Imports/Imports";

import {
    Card,
    Button,
    TableRow
} from "$Imports/MaterialUIComponents";

import {
    AdvanceTextField, AjaxActionIndicator, DataTable, DataTablePager, IDataTableColumn
} from "$Imports/CommonComponents";
import { RunsService, IRunsServiceInjectedProps } from "$State/RunsFreezerService";
import { WetlabRunInstrumentInfoVM } from "$Generated/api";

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
} = require("./InstrumentationPage.scss");

interface IInstrumentPageBaseProp {

}

type IInstrumentPageProp = IRunsServiceInjectedProps & IInstrumentPageBaseProp;

export class _InstrumentationPage extends React.Component<IInstrumentPageProp> {

    private readonly columns: Array<IDataTableColumn<WetlabRunInstrumentInfoVM>> = [
        {
            columnName: "workflow-name",
            columnFieldData: "WorkflowName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Workflow Name",
            sortMethod: (d) => d.WorkflowName ?? ""
        },
        {
            columnName: "workflow-run-number",
            columnFieldData: "RunNumber",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Run Number",
            sortMethod: (d) => d.RunNumber ?? ""
        },
        {
            columnName: "thermocyclers",
            columnFieldData: (d) => {
                return _.map(d.Thermocyclers, (t, idx) => {
                    return (<div key={idx}>{t}</div>);
                });
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "ThermoCycler",
        },
        {
            columnName: "absorbance",
            columnFieldData: (d) => {
                return _.map(d.AbsorbanceInstruments, (a, idx) => {
                    return (<div key={idx}>{a}</div>);
                });
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "UV Absorbance",
        },
        {
            columnName: "workflow-created-date",
            columnFieldData: (d) => moment(d.CreatedDate).format("L LT"),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Started On",
            sortMethod: (d) => d.CreatedDate ?? ""
        },
    ];

    async componentDidMount() {
        await this.props.runsService.fetchWorkflowInstruments(true);
    }

    render() {
        const state = this.props.runsService.getState();

        const {
            instrumentPageListResults,
            currentPage
        } = state;

        const instrumentData = instrumentPageListResults[currentPage].data?.Runs ?? [];

        return (
            <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    <AjaxActionIndicator
                        state={instrumentPageListResults}
                    />
                    <DataTable
                        data={instrumentData}
                        columns={this.columns}
                        defaultSortColumnName={"workflow-created-date"}
                        defaultSortDirection={"desc"}
                        tableFooterComponent={(
                            <TableRow>
                                <DataTablePager
                                    count={this.props.runsService.totalCount}
                                    page={currentPage}
                                    rowsPerPage={this.props.runsService.pageSize}
                                    rowsPerPageOptions={[this.props.runsService.pageSize]}
                                    onPagerStateChange={this._onPagerStateChanged}
                                    onChangePage={() => {/*This is not actually required, but there is a typescript issue that thinks it's required */ }}
                                />
                            </TableRow>
                        )}
                    />
                </Card>
            </div>
        );
    }

    @bind
    private _onPagerStateChanged(page: number, rowsPerPage: number) {
        this.props.runsService.setPageNumber(page);
    }
}

export const InstrumentationPage = RunsService.inject(
    _InstrumentationPage
);