import {
    React,
    _
} from "$Imports/Imports";


import {
    Card
} from "$Imports/MaterialUIComponents";

import {
    ErrorService,
    IErrorServiceInjectedProps
} from "$State/ErrorFreezerService";

interface IWebErrorAPIPageBaseProps {
}

export type IWebErrorAPIPageProps = IWebErrorAPIPageBaseProps & IErrorServiceInjectedProps;

const styles: {
    mainContainer: string;
} = require("./ErrorPages.scss");

export class _WebAPIErrorPage extends React.Component<IWebErrorAPIPageProps> {

    componentDidMount() {
    }


    render() {
        const error = this.props.errorService.error;
        return (
            <div className={styles.mainContainer}>
                <Card>
                    <h2 style={{padding:"10px"}}>
                        Error with data access. Please contact system administrator.
                    </h2>
                    <h4 style={{padding:"10px"}}>
                        {error.ExceptionType}: {error.ErrorMessage}
                    </h4>
                </Card>
            </div>
        )
    }
}

export const WebAPIErrorPage = ErrorService.inject(_WebAPIErrorPage);