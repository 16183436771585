import { React, Switch, Route, Redirect, Router, _ } from "$Imports/Imports";

import {
    mainNavigation,
    INavigationItem
} from "$Utilities/navigation";

import {
    AppContainer
} from "./AppContainer";
import { ISecurityContext } from "$Utilities/Security/ISecurityContext";
import { WebAPIErrorPage} from "$Pages/Errors";

import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";

import {
    SilentAuthFrame
} from "$Utilities/Security/SilentAuthFrame";
import { WorkflowsPage } from "$Pages/Workflows";
import { HomePage, LimsSelectionPage, DemoWelcomePage } from "$Pages/Home";
import { IAuthenticationContext } from "$Providers/AuthenticationProvider";
import { IWorkflowRunServiceInjectedProps, WorkflowRunService } from "$State/WorkflowRun/WorkflowRunFreezerService";
import { NoAccessPage } from "$Pages/NoAccess";
import { JsxElement } from "typescript";

interface IRoutingBaseProps {
    authContext: IAuthenticationContext;
}

interface IRoutingState {
    navigationRoutes: JSX.Element[]
}

type IRoutingProps = IRoutingBaseProps & ISettingsServiceInjectedProps & IWorkflowRunServiceInjectedProps;

export class _Routing<T = ISecurityContext> extends React.Component<IRoutingProps, IRoutingState> {
    async componentDidMount() {
        await this.props.settingsService.fetchLimsSet();
        await this.props.settingsService.fetchDemoEnvironmentSet();
        await this.props.settingsService.fetchUserPreferences();
        await this.props.settingsService.getSystemSettings();

        const useWetlab = (this.props.settingsService.getState().systemSettings.data || {})["use_wetlab"] === "use_wetlab";
        const useDryLab = ((this.props.settingsService.getState().systemSettings.data || {})["use_wetlab"] === "headless") || ((this.props.settingsService.getState().systemSettings.data || {})["use_wetlab"] === "use_wetlab");

        const navigationRoutes: JSX.Element[][] = _.map(mainNavigation(), (n, mnIdx: number) => {
            return this._renderRouter(n, 0, mnIdx, this.props.authContext.securityContext, useWetlab, useDryLab);
        });
        this.setState({navigationRoutes: _.flatten(navigationRoutes)});
    }

    private _renderRouter(navigation: INavigationItem, levelIdx: number, idx: number, securityContext: ISecurityContext | null = null, useWetlab: boolean, useDryLab: boolean): JSX.Element[] {
        const results: JSX.Element[] = [];

        // Check if the menu item is enabled
        const isEnabled = (typeof navigation.enabled === "function" ? navigation.enabled(navigation, securityContext) : navigation.enabled);
          
        //Check if the menu item should be filtered based on system settings
        let filtered = false;
        switch(navigation.pageType)
        {
            case "Wetlab":
                filtered = !useWetlab;
                break;
            case "Drylab":
                filtered = !useDryLab;
                break;
            case "PCEOnly":
                filtered = (useWetlab || useDryLab);
                break;
            default:
            case "System":
                break;
        }

        // Check if the user has access.
        const hasAccess = navigation.hasAccess !== undefined ?
            typeof navigation.hasAccess === "function" ? navigation.hasAccess(navigation, securityContext) : navigation.hasAccess
            : true;

        if (!hasAccess) {
            navigation.component = NoAccessPage;
        }

        if (!navigation.externalLink && isEnabled && !filtered) {
            if (navigation.redirectUrls !== undefined && _.isArray(navigation.redirectUrls)) {
                _.forEach(navigation.redirectUrls, (s, sIdx) => {
                    results.push(
                        (
                            <Route key={`${levelIdx}-${idx}-${sIdx}`} exact={true} path={s}>
                                <Redirect
                                    to={navigation.url || ""}
                                />
                            </Route>
                        )
                    );
                });
            }

            const router: JSX.Element = (
                <Route key={`${levelIdx}-${idx}`} path={navigation.url} component={navigation.component} />
            );

            if (navigation.childNavigation && _.isArray(navigation.childNavigation)) {
                _.forEach(navigation.childNavigation, (n, nIdx) => {
                    const routes = this._renderRouter(n, levelIdx + 1, nIdx, securityContext, useWetlab, useDryLab);
                    results.push(...routes);
                });
            }

            results.push(router);
        }

        return results;
    }
    render() {
        const limsSet = this.props.settingsService.getState().isLimsSetResults.data;
        const demoSet = this.props.settingsService.getState().demoEnvironmentResults.data;

        return (
            <Router>
                <AppContainer>
                    <Switch>
                        <Route exact path={"/"}>
                            {this.props.workflowRunService.currentWorkflowRun ? 
                                <WorkflowsPage />
                                : !demoSet ?
                                    limsSet ?
                                    <HomePage /> :
                                    <LimsSelectionPage />
                                : <DemoWelcomePage />}
                        </Route>
                        {(this.state && this.state.navigationRoutes) ? this.state.navigationRoutes : <></>}
                        <Route path={"/Error"}>
                            <WebAPIErrorPage />
                        </Route>
                        <Route path={"/check-silent-auth"}>
                            <SilentAuthFrame />
                        </Route>
                    </Switch>
                </AppContainer>
            </Router >
        );
    }
}

export const Routing = WorkflowRunService.inject(SettingsService.inject(_Routing));