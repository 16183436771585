import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"

import {
    IWorkflowScreenProps,
    GetCurrentStepInputType
} from "../WorkflowStep";

import {
    SampleInfoTable,
    IDataTableColumn,
    StepActionsControl,
    StepChangeControl,
    QCUIComponent,
    QuantificationBeforePoolingConcentrationEntryMandatoryCheck,
    QuantificationBeforePoolingNTCConcentrationCheck,
    CollapsibleSection,
    DataLoadingDisplay,
    SpectrophotometerInstrumentEmptyCheck,
    Spectrophotometer280RatioEmptyCheck,
    Spectrophotometer230RatioEmptyCheck,
    Spectrophotometer280OutOfRangeCheck,
    Spectrophotometer230OutOfRangeCheck
} from "$Imports/CommonComponents";

import {
    QCCheckInstanceVM,
    QCCheckResultVM,
    WellContentVM
} from "$Generated/api";

import {
    ConcentrationVolumeVM
} from "$State/WorkflowRun/WorkflowRunInternalFreezerService"

import {
    Snackbar,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    TableCellProps,
    Tooltip,
    ErrorIcon
} from "$Imports/MaterialUIComponents";

import * as s from "underscore.string";
import { ChangeEvent } from "react";
import { ConvertPositionToString, instanceOfWellContentVM } from "$Components/common/StandardPlate/StandardPlate";
import { Autocomplete } from "@material-ui/lab";

const styles: {
    tableHeader: string;
    mainDiv: string;
    table: string;
    input: string;
    failRow: string;
    errorIcon: string;
    inputDiv: string;
} = require("./Quantification.scss");

const commonStyles: {
    footerDiv: string;
} = require("./CommonStepStyles.scss");

export interface ISpectrophotometerQCState {
    messageSnackbarOpen: boolean;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    dataLoaded: boolean;
    wellContents: WellContentVM[];
    wellVolume: number;
}

const AbsorbanceRatio280Max = 2.1;
const AbsorbanceRatio280Min = 1.8;

const AbsorbanceRatio230Max = 2.9;
const AbsorbanceRatio230Min = 1.8;

export class SpectrophotometerQCScreen extends React.Component<IWorkflowScreenProps, ISpectrophotometerQCState> {

    state: ISpectrophotometerQCState = {
        messageSnackbarOpen: false,
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        dataLoaded: false,
        wellContents: [],
        wellVolume: 0
    };

    @bind
    private cellProps(d: WellContentVM): TableCellProps {
        let { concentrationDictionary} = this.props.workflowRunService;
        let ratioEntry = concentrationDictionary[d.Id]

        // For Anthrax, "NTC" should not show error messages unless less than 0.
        let isNTC = false;
        if (d.Control?.Name === "NTC")
            isNTC = true;

        if (!isNTC && ratioEntry !== undefined && ratioEntry !== null && ratioEntry.Ratio280 && (ratioEntry.Ratio280 < AbsorbanceRatio280Min || ratioEntry?.Ratio280 > AbsorbanceRatio280Max)
        || (isNTC && ratioEntry !== undefined && ratioEntry !== null && ratioEntry.Ratio280 && ratioEntry.Ratio280 < 0))
        {
            return {
                className: styles.failRow
            };
        }

        if (!isNTC && ratioEntry !== undefined && ratioEntry !== null  && ratioEntry.Ratio230 && (ratioEntry.Ratio230 < AbsorbanceRatio230Min || ratioEntry?.Ratio230 > AbsorbanceRatio230Max)
        || (isNTC && ratioEntry !== undefined && ratioEntry !== null && ratioEntry.Ratio230 && ratioEntry.Ratio230 < 0)) {
            return {
                className: styles.failRow
            };
        }
        return {
        };
    }

    //Returns the columns for the well contents. Needs to be a function to handle the disabled flag as well as correctly updating within react.
    private wellContentColumns(): Array<IDataTableColumn<WellContentVM>> {
        let {
            concentrationDictionary,
            currentStep,
            currentWorkflowRun
        } = this.props.workflowRunService;

        let disabled = false;

        if (currentStep && currentWorkflowRun) {
            disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
        }
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Sample ID",
                    sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
                },
                {
                    columnName: "sample-well-position",
                    columnFieldData: (d) => ConvertPositionToString(d.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Well Position",
                    sortMethod: (d) => d.WellPosition ?? ""
                },
                {
                    columnName: "sample-date-harvested",
                    columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Date Harvested",
                    sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
                },
                {
                    columnName: "ratio-260-280",
                    columnFieldData: (d) => {
                        let ratioEntry = concentrationDictionary[d.Id];

                        return (<div>
                            <TextField 
                                disabled={disabled} 
                                style={{ paddingLeft: 10 }} 
                                type="number" 
                                value={ratioEntry !== undefined ? ratioEntry.Ratio280 : ""} 
                                onChange={(e) => { this.onRatio280Changed(d.Id, e.target.value); }} 
                                inputProps={{ min: 0 }} />
                            {(!(d.Control?.Name === "NTC") && ratioEntry !== undefined && ratioEntry !== null && ratioEntry.Ratio280 && (ratioEntry.Ratio280 < AbsorbanceRatio280Min || ratioEntry?.Ratio280 > AbsorbanceRatio280Max)) ?
                                <Tooltip
                                    title={"A valid 260/280 Ratio is between " + AbsorbanceRatio280Min + " and " + AbsorbanceRatio280Max}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>
                                : <></>
                                }
                            {(d.Control?.Name === "NTC" && ratioEntry !== undefined && ratioEntry !== null && ratioEntry.Ratio280 && (ratioEntry.Ratio280 < 0)) ?
                                <Tooltip
                                    title={"The 260/280 Ratio can not be negative."}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>
                                : <></>
                            }

                        </div>);
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "260/280 Ratio",
                },
                {
                    columnName: "ratio-260-230",
                    columnFieldData: (d) => {
                        let ratioEntry = concentrationDictionary[d.Id];

                        return (<div>
                            <TextField 
                                disabled={disabled} 
                                style={{ paddingLeft: 10 }} 
                                type="number" 
                                value={ratioEntry !== undefined ? ratioEntry.Ratio230 : ""} 
                                onChange={(e) => { this.onRatio230Changed(d.Id, e.target.value); }} 
                                inputProps={{ min: 0 }} />
                            {(!(d.Control?.Name === "NTC") && ratioEntry !== undefined && ratioEntry !== null && ratioEntry.Ratio230 && (ratioEntry.Ratio230 < AbsorbanceRatio230Min || ratioEntry?.Ratio230 > AbsorbanceRatio230Max)) ?
                                <Tooltip
                                    title={"A valid 260/230 Ratio is between " + AbsorbanceRatio230Min + " and " + AbsorbanceRatio230Max}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}>
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>
                                : <></>
                                }
                            {(d.Control?.Name === "NTC" && ratioEntry !== undefined && ratioEntry !== null && ratioEntry.Ratio230 && (ratioEntry.Ratio230 < 0)) ?
                            <Tooltip
                                title={"The 260/230 Ratio can not be negative."}
                                enterDelay={300}
                                placement={"top"}
                                arrow={true}
                            >
                                <ErrorIcon className={styles.errorIcon} />
                            </Tooltip>
                            : <></>
                            }

                        </div>);
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "260/230 Ratio",
                }
            ];
        }
        return [];
    }

    async componentDidMount() {
        this.props.workflowRunService.resetConcentrationDictionary();
        await this.props.workflowRunService.fetchAssetsForStep(true);
        await this.props.workflowRunService.getConcentrationControls(true);
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
        await this.props.workflowRunService.fetchInstrumentOptions();

        const currentStep = this.props.workflowRunService.currentStep;
        const { workflowAssets } = this.props.workflowRunService.getState();

        const inputAssetType = GetCurrentStepInputType();
        

        if (currentStep && workflowAssets.data) {
            let assetWellContents : WellContentVM[] = [];
            let wellVolume : number | undefined = 0;
            let concentrationDictionary: { [index: string]: ConcentrationVolumeVM | undefined } = {};
            
            switch(inputAssetType) {
                case "Plate": 
                    const inputPlate = _.find(workflowAssets.data.Plates, (p) => { return p.Name === currentStep.InputName && p.Status !== "Empty"});
                    if(inputPlate) {
                        assetWellContents = inputPlate.WellContents;
                        wellVolume = inputPlate.WellVolume;
                    }
                    break;
                case "Rack":
                    const inputRack = _.find(workflowAssets.data.Racks, (r) => { return r.Name === currentStep.InputName && r.Status !== "Empty"});
                    if(inputRack) {
                        assetWellContents = inputRack.WellContents;
                        wellVolume = inputRack.WellVolume;
                    }
                    break;
            }

            _.forEach(assetWellContents, w => {
                concentrationDictionary[w.Id] = { 
                    Concentration: w.Concentration,
                    SampleVolume: wellVolume,
                    Ratio280: w.AbsorbanceRatio260Over280,
                    Ratio230: w.AbsorbanceRatio260Over230
                };
                
            });
            this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
            this.setState({ dataLoaded: true, wellContents: assetWellContents });
        }
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        if (currentStep && currentWorkflowRun && instrumentOptions) {
            const customFields = currentStep.CustomFields;
            if (customFields && this.state.wellContents) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={styles.mainDiv}>
                                <div>
                                    <h2>Spectrophotometer Measurement</h2>
                                    UV Absorbance Instrument: <Autocomplete
                                        freeSolo
                                        disabled={disabled}
                                        id="instrument-name"
                                        options={instrumentOptions}
                                        onChange={(e, val) => this.onTextChanged("UVAbsorbanceInstrument", val || "")}
                                        value={(customFields["UVAbsorbanceInstrument"] ? customFields["UVAbsorbanceInstrument"] : "")}
                                        renderInput={(params) =>
                                        (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                                autoFocus={true}
                                                className={styles.inputDiv}
                                                disabled={disabled}
                                                label=""
                                                value={(customFields["UVAbsorbanceInstrument"] ? customFields["UVAbsorbanceInstrument"] : "")}
                                                onChange={(e) => { this.onTextChanged("UVAbsorbanceInstrument", e.target.value) }} />
                                        )}
                                    />
                                </div>
                                <SampleInfoTable
                                    data={this.state.wellContents}
                                    columns={this.wellContentColumns()}
                                    header={"Sample Information"}
                                    description={"Acceptable Ratio of Absorbancies: 260/280: 1.8 - 2.1 | 260/230: 1.8 - 2.9 "}
                                    defaultSortColumnName={"sample-well-position"} />
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={"All information is required, NTC controls must be < 1 ng/ul, and PTC controls must be > 0 ng/ul"}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div >)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private onTextChanged(key: string, newValue: string) {
        this.props.workflowRunService.updateCustomField(key, newValue);
    }

    @bind
    private onRatio230Changed(wellContentId: string, newValue: string) {
        let concentrationDictionary = _.cloneDeep(this.props.workflowRunService.concentrationDictionary);
        let num = parseFloat(newValue);
        let concentrationEntry = concentrationDictionary[wellContentId];

        if(concentrationEntry && concentrationEntry != undefined) {
            concentrationEntry.Ratio230 = num;
            concentrationDictionary[wellContentId] = concentrationEntry;
            this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
        }
    }

    @bind 
    private onRatio280Changed(wellContentId: string, newValue: string) {
        let concentrationDictionary = _.cloneDeep(this.props.workflowRunService.concentrationDictionary);
        let num = parseFloat(newValue);
        let concentrationEntry = concentrationDictionary[wellContentId];

        if(concentrationEntry && concentrationEntry != undefined) {
            concentrationEntry.Ratio280 = num;
            concentrationDictionary[wellContentId] = concentrationEntry;
            this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
        }
    }

    @bind
    private async moveToNextStepBegin() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        const currentStep = this.props.workflowRunService.currentStep;

        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];

        let concentrationDictionary = this.props.workflowRunService.concentrationDictionary;
        let samples = this.state.wellContents;                       
         //we don't want to run some QCs on NTCs
        let filterSamples = _.filter(samples, (sample) => (sample.Id !== 'NTC'));
        //run all QCs
        if (currentWorkflowRun && currentStep) {
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case SpectrophotometerInstrumentEmptyCheck:
                        let instrumentPass = true;
                        const customFields = currentStep.CustomFields
                        if (s.isBlank(customFields["UVAbsorbanceInstrument"])) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            instrumentPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled? instrumentPass ? undefined : 1 : 2,
                                MeasuredValue: s.isBlank(customFields["UVAbsorbanceInstrument"])
                                                ? "No Instrument Entered"
                                                : customFields["UVAbsorbanceInstrument"],
                                Date: new Date(Date.now()),
                                Pass: instrumentPass,
                                QCCheckInstance: qc,
                            }
                        );
                    break;
                    case Spectrophotometer280RatioEmptyCheck:
                            _.forEach(samples, sample => {
                                let ratio280Pass = true;
                                let concentration = concentrationDictionary[sample.Id];
                                let concentration280 = concentration && concentration.Ratio280 ? concentration.Ratio280 : 0;
                                if ((concentration280 === 0) || (concentration280 === undefined)) {
                                    failedQCs.push(qc);
                                    ratio280Pass = false;
                                }
                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: qc.Enabled? instrumentPass ? undefined : 1 : 2,
                                        MeasuredValue: ratio280Pass
                                                        ? concentration280.toString()
                                                        : "0",
                                        Date: new Date(Date.now()),
                                        Pass: ratio280Pass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            });
                    break;
                    case Spectrophotometer230RatioEmptyCheck:
                            _.forEach(samples, sample => {
                                let ratio230Pass = true;
                                let concentration = concentrationDictionary[sample.Id];
                                let concentration230 = concentration && concentration.Ratio230 ? concentration.Ratio230 : 0;
                                if ((concentration230 === 0) || (concentration230 === undefined)) {
                                    failedQCs.push(qc);
                                    ratio230Pass = false;
                                }
                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: qc.Enabled? instrumentPass ? undefined : 1 : 2,
                                        MeasuredValue: ratio230Pass
                                                        ? concentration230.toString()
                                                        : "0",
                                        Date: new Date(Date.now()),
                                        Pass: ratio230Pass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            });
                    break;
                    case Spectrophotometer280OutOfRangeCheck:
                        _.forEach(filterSamples, sample => {
                            let ratio280RangePass = true;
                            let concentration = concentrationDictionary[sample.Id];
                            let concentration280 = concentration && concentration.Ratio280 ? concentration.Ratio280 : 0;
                            if ((concentration280 > AbsorbanceRatio280Max) || (concentration280 < AbsorbanceRatio280Min)) {
                                failedQCs.push(qc);
                                ratio280RangePass = false;
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled? instrumentPass ? undefined : 1 : 2,
                                    MeasuredValue: ratio280RangePass
                                                    ? concentration280.toString()
                                                    : "0",
                                    Date: new Date(Date.now()),
                                    Pass: ratio280RangePass,
                                    QCCheckInstance: qc,
                                }
                            );
                        });
                    break;
                    case Spectrophotometer230OutOfRangeCheck:
                        _.forEach(filterSamples, sample => {
                            let ratio230RangePass = true;
                            let concentration = concentrationDictionary[sample.Id];
                            let concentration230 = concentration && concentration.Ratio230 ? concentration.Ratio230 : 0;
                            if ((concentration230 > AbsorbanceRatio280Max) || (concentration230 < AbsorbanceRatio230Min)) {
                                failedQCs.push(qc);
                                ratio230RangePass = false;
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled? instrumentPass ? undefined : 1 : 2,
                                    MeasuredValue: ratio230RangePass
                                                    ? concentration230.toString()
                                                    : "0",
                                    Date: new Date(Date.now()),
                                    Pass: ratio230RangePass,
                                    QCCheckInstance: qc,
                                }
                            );
                        });
                    break;
                }
            });
            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else {
                this.setState({ qcOpen: true, qcResults: qcResults });
                this.completeMoveToNextStep();
            }
        }


        
    }

    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentStep && currentWorkflowRun) {
            const customFields = currentStep.CustomFields;

            let fieldWithMissingData = _.find(customFields, c => s.isBlank(c));

            if (fieldWithMissingData === undefined) {
                await Promise.all([
                    this.props.workflowRunService.updateCustomFields(),
                    this.props.workflowRunService.addInstrument(customFields["UVAbsorbanceInstrument"]),
                    this.props.workflowRunService.saveConcentrations(),
                ]);
                await this.props.workflowRunService.completeStep();
            }
            else {
                this.setState({ messageSnackbarOpen: true });
            }
        }
    }
}