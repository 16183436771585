import {
    React,
    _,
    bind,
    C3
} from "$Imports/Imports";
import { ChartConfiguration, PrimitiveArray } from "c3";

require("./c3.scss");

const uuidv4 = require("uuid/v4");

interface IC3ChartProps {
    colors: string[];
    id: string;
    pie?: any;
    bar?: any;
    data: C3.Data;
    axis?: C3.AxesOptions;
    size?: { width?: number, height?: number };
    tooltip?: C3.TooltipOptions;
}

export class C3Chart extends React.Component<IC3ChartProps> {

    componentDidMount() {
        this._updateChart();
    }
    componentDidUpdate() {
        this._updateChart();
    }

    private _updateChart() {
        const chart = C3.generate({
            bindto: ("#" + this.props.id),
            data: this.props.data,
            color: {
                pattern: this.props.colors
            },
            point: {show: false},
            pie: this.props.pie,
            bar: this.props.bar,
            tooltip: this.props.tooltip,
            size: this.props.size,
            axis: this.props.axis,
            grid: {
                y: {
                    show: true,
                }
            }
        });
    }

    render() {
        return <div id={this.props.id}></div>;
    }
}