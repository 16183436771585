import { WetlabRunVM } from "$Generated/api";
import { DataTable, DataTablePager, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Button, Card, TableRow } from "$Imports/MaterialUIComponents";

import {
    IRunsServiceInjectedProps,
    RunsService
} from "$State/RunsFreezerService";

import {
    IWorkflowRunServiceInjectedProps,
    WorkflowRunService
} from "$State/WorkflowRun/WorkflowRunFreezerService";

interface IReleaseRunPageBaseProps {
}

interface IReleaseRunState {
}

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    divPadding: string;
    tablePadding: string;
    continueButtonStyle: string;
} = require("./ExistingPipelineRuns.scss");

type IReleaseRunPageProps = IReleaseRunPageBaseProps & IRunsServiceInjectedProps & IWorkflowRunServiceInjectedProps;

export class _ReleaseRunPage extends React.Component<IReleaseRunPageProps, IReleaseRunState> {

    state: IReleaseRunState = {
    }

    async componentDidMount() {
        await this.props.runsService.fetchWorkflowRunsWithMetadata();
    }

    private columns(): Array<IDataTableColumn<WetlabRunVM>> {
        return [
            {
                columnName: "name",
                columnFieldData: d => JSON.parse(d.RunMetadata || "")["workflow_run_id"],
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Workflow Run ID",
            },
            {
                columnName: "sequencing_date",
                columnFieldData: d => moment(JSON.parse(d.RunMetadata || "")["sequencing_date"]).format("MM-DD-YYYY"),
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Sequencing Date",
            },
            {
                columnName: "release_date",
                columnFieldData: d => JSON.parse(d.RunMetadata || "")["release_date"] ? moment(JSON.parse(d.RunMetadata || "")["release_date"]).format("MM-DD-YYYY") : "",
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Release Date",
            },
            {
                columnName: "release_button",
                columnFieldData: d => {
                    return <Button
                        disabled={!d.CanRelease || JSON.parse(d.RunMetadata || "")["release_flag"]}
                        className={styles.continueButtonStyle}
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await this.props.workflowRunService.releaseRun(d.Id, true)
                            await this.props.runsService.fetchWorkflowRunsWithMetadata(true);
                        }}
                    >
                        Release
                    </Button>;
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "",
            },
        ];
    }

    render() {

        const state = this.props.runsService.getState();
        const {
            runsWithMetadataPageListResults,
            currentPage
        } = state;

        let data = runsWithMetadataPageListResults[currentPage].data?.Runs ?? [];

        return <div className={styles.mainContainer}>
            <Card
                className={styles.cardStyle}
            >
                <div className={styles.divPadding}>
                    <h2>Release Run Data</h2>
                    <i>Select an uploaded workflow run to release. All data for that run will be released for sharing with any eligible partners.</i>
                </div>
                <div className={styles.tablePadding}>
                    <DataTable
                        data={data}
                        columns={this.columns()}
                        defaultSortColumnName="startedOn"
                        defaultSortDirection="desc"
                        tableFooterComponent={(
                            <TableRow>
                                <DataTablePager
                                    count={this.props.runsService.totalCount}
                                    page={currentPage}
                                    rowsPerPage={this.props.runsService.pageSize}
                                    rowsPerPageOptions={[this.props.runsService.pageSize]}
                                    onPagerStateChange={this._onPagerStateChanged}
                                    onChangePage={() => {/*This is not actually required, but there is a typescript issue that thinks it's required */ }}
                                />
                            </TableRow>)}
                    />
                </div>
            </Card>
        </div>;
    }

    @bind
    private _onPagerStateChanged(page: number, rowsPerPage: number) {
        this.props.runsService.setPageNumber(page);
    }
}

export const ReleaseRunPage = WorkflowRunService.inject(RunsService.inject(
    _ReleaseRunPage
));
