import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { GetWorkflowType, IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, PCRControl, IDataTableColumn, SampleInfoTable, AdvanceTextField, CollapsibleSection, DataLoadingDisplay, BioanalyzerInstrument, BioanalyzerQualificationAverageLibrarySizeMinCheck, BioanalyzerQualificationAverageLibrarySizeMaxCheck, QCUIComponent } from "$Imports/CommonComponents";
import { Snackbar } from "$Imports/MaterialUIComponents";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";
import { QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";


const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

interface IBioanalyzerQualificationState {
    dataLoaded: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    qcOpen: boolean;
}

export class BioanalyzerQualification extends React.Component<IWorkflowScreenProps, IBioanalyzerQualificationState> {

    constructor(props: IWorkflowScreenProps) {
        super(props);

        this.state = {
            dataLoaded: false,
            failedQcs: [],
            qcResults: [],
            qcOpen: false,
        };
    }

    async componentDidMount() {
        await this.props.workflowRunService.fetchSimplifiedReagentListForStep(true);
        await this.props.workflowRunService.fetchInstrumentOptions();
        this.setState({ dataLoaded: true });
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {


        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;

        if (currentWorkflowRun && currentStep && masterMixInfoState.data && lotOptions && instrumentOptions) {
            let customFields = currentStep.toJS().CustomFields;
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return (
                <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={commonStyles.mainDiv}>
                            <div>
                                <h2>Reagent Information</h2>
                                <MasterMixControl
                                    disabled={disabled}
                                    index={0}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={0}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { }}
                                    hideHeaders
                                />
                            </div>
                            <BioanalyzerInstrument
                                instrumentOptions={instrumentOptions}
                                disabled={disabled}
                                displayNotes={"Expected mean fragment size to be between 300-400 bp"}
                                instrumentName={customFields["Bioanalyzer"]}
                                librarySize={parseFloat(customFields["LibrarySize"]) || 0}
                                updateInstrumentName={(name: string) => { this.props.workflowRunService.updateCustomField("Bioanalyzer", name); }}
                                updateLibrarySize={(newSize: number) => { this.props.workflowRunService.updateCustomField("LibrarySize", newSize.toFixed()); }}
                            />
                        </div>
                    </CollapsibleSection>
                    {currentStep.QCCheckInstances &&
                        <QCUIComponent
                            open={this.state.qcOpen}
                            failedQCs={this.state.failedQcs}
                            close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                            results={this.state.qcResults}
                            step={currentStep}
                            workflowRunId={currentWorkflowRun.Id}
                            workflowName={currentWorkflowRun.WorkflowName}
                            completeStep={this.completeMoveToNextStep}
                        />
                    }
                    <div className={commonStyles.footerDiv}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                    </div>
                </div>)
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private async moveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];

        // Run all QCs.
        if (currentStep) {
            let customFields = currentStep.toJS().CustomFields;
            let nLibrarySize: number = Number.parseInt(customFields["LibrarySize"]);
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case BioanalyzerQualificationAverageLibrarySizeMinCheck:
                        let nLibrarySizeMin = _.find(qc.CheckConfig?.Parameters, p => p.Id === "AvgLibrarySize");
                        let bLibrarySizeMinPass = true;
                        if (nLibrarySizeMin && nLibrarySize < Number.parseInt(nLibrarySizeMin.Value)) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            bLibrarySizeMinPass = false;
                        }
                    qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? bLibrarySizeMinPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: nLibrarySize.toString(),
                                Date: new Date(Date.now()),
                                Pass: bLibrarySizeMinPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case BioanalyzerQualificationAverageLibrarySizeMaxCheck:
                        let nLibrarySizeMax = _.find(qc.CheckConfig?.Parameters, p => p.Id === "AvgLibrarySize");
                        let bLibrarySizeMaxPass = true;
                        if (nLibrarySizeMax && nLibrarySize > Number.parseInt(nLibrarySizeMax.Value)) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            bLibrarySizeMaxPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? bLibrarySizeMaxPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: nLibrarySize.toString(),
                                Date: new Date(Date.now()),
                                Pass: bLibrarySizeMaxPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    default:
                        break;
                }
            });

            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else if (failedQCs.length === 0) {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                this.completeMoveToNextStep();
            }
        }



    }

    @bind
    private async completeMoveToNextStep() {
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        const currentStep = this.props.workflowRunService.currentStep;

        if (currentStep && masterMixState.data) {
            const customFields = currentStep.CustomFields;

            if (customFields["Bioanalyzer"]) //Optional step, only add instrument if it exists
            {
                await this.props.workflowRunService.addInstrument(customFields["Bioanalyzer"]);
            }
            if (currentStep.OutputAssets) {
                await Promise.all([
                    this.props.workflowRunService.saveMasterMix(true),
                    this.props.workflowRunService.updateCustomFields(),
                ]);
                await this.props.workflowRunService.completeStep(); //Needs to be outside due to a race condition
            }
        }
    }


}