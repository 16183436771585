import {
    React,
    bind,
    _
} from "$Imports/Imports";

import {
    Card,
    Button,
    InputLabel,
    FormControl
} from "$Imports/MaterialUIComponents";

import {
    AdvanceTextField, DataTable, IDataTableColumn
} from "$Imports/CommonComponents";

import {
    SettingsService,
    ISettingsServiceInjectedProps
} from "$State/SettingsFreezerService"

const styles: {
    mainContainer: string;
    cardStyle: string;
    cardHeader: string;
    flexRow: string;
    formControl: string;
    content: string;
    savedText: string;
    tableHeader: string;
} = require("./SystemSettingsPage.scss");

interface ISoftwareInformationPageBaseProps {
}

interface ISoftwareInformationPageState {
    dataRows: { module: string, version: string, notes: string }[];
}

type ISoftwareInformationPageProps = ISoftwareInformationPageBaseProps & ISettingsServiceInjectedProps;

export class _SoftwareInformationPage extends React.PureComponent<ISoftwareInformationPageProps, ISoftwareInformationPageState> {

    private readonly columns: Array<IDataTableColumn<{ module: string, version: string, notes: string }>> = [
        {
            columnName: "module",
            columnFieldData: (d) => d.module,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Module",
        },
        {
            columnName: "version",
            columnFieldData: (d) => d.version,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Version",
        },
        {
            columnName: "notes",
            columnFieldData: (d) => d.notes,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Notes",
        },
    ];

    state: ISoftwareInformationPageState = {
        dataRows: []
    }


    async componentDidMount() {
        await this.props.settingsService.fetchPlatformVersion();
        await this.props.settingsService.getInstalledWorkflows();

        let rows: { module: string, version: string, notes: string }[] = [];

        var platformData = this.props.settingsService.getState().platformVersion.data;
        if (platformData) {
            rows.push({
                module: "Platform",
                notes: "",
                version: platformData.PlatformVersion || "",
            })
        }

        var moduleData = this.props.settingsService.getState().installedWorkflows.data;
        if (moduleData) {
            _.forEach(moduleData, m => {
                rows.push(
                    {
                        module: m.Name || "",
                        notes: "",
                        version: m.VersionInfo,
                    }
                )
            });
        }

        this.setState({ dataRows: rows });

    }

    render() {
        return (
            <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    <div style={{ margin: "auto", paddingLeft:"20px" }}>
                        <h2 className={styles.cardHeader}>LIMS Lite Module Versions</h2>
                    </div>
                    <div className={styles.formControl}>
                        <DataTable
                            data={this.state.dataRows}
                            columns={this.columns}
                            stickyHeader={true}
                        />
                    </div>
                </Card>
            </div>
        );
    }
}

export const SoftwareInformationPage = SettingsService.inject(
    _SoftwareInformationPage
);