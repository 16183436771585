import {
  React
} from "$Imports/Imports";
import {
  ArrowDropDownIcon,
  ArrowRightIcon
} from "$Imports/MaterialUIIcons";

import {
  Card,
  CardContent
} from "$Imports/MaterialUIComponents";

const styles = require("./CollapsibleSection.scss") as {
  iconLarge: string;
  headerDiv: string;
  iconSmall: string;
  iconMedium: string;
  cardHeader: string;
};

interface ICollapsibleSectionProps {
  sectionHeader: React.ReactNode;
  size?: string;
  expanded?: boolean;
  headerColor?: string;
  hideHeaderColor?: boolean;
}

interface ICollapsibleSectionState {
  expanded: boolean;
}

export class CollapsibleSection extends React.Component<ICollapsibleSectionProps, ICollapsibleSectionState> {

  public state: ICollapsibleSectionState = {
    expanded: this.props.expanded || false
  };

  public render() {
    const fontSize = this.props.size || '14px';
    const hideHeaderColor = this.props.hideHeaderColor === undefined ? false : this.props.hideHeaderColor;
    const headerColor = !hideHeaderColor ? this.props.headerColor ? this.props.headerColor : "rgba(0,0,0,.03)" : "";
    let iconSize = fontSize;
    switch (fontSize) {
    case "small":
    case "13px":
      iconSize = "medium";
      break;
    case "medium":
    case "14px":
      iconSize = "large";
      break;
    case "large":
    case "16px":
      iconSize = "x-large";
      break;
    case "x-large":
      iconSize = "xx-large";
      break;
    case "xx-large":
      iconSize = "xxx-large";
      break;

    default:
      break;
    }
    return <Card>
      <div className={styles.cardHeader}
        style={{
          backgroundColor: headerColor
        }}
        onClick={() => {
          this.setState({
            expanded: !this.state.expanded
          });
        }}
      >
        <div style={{
          display: "contents"
        }}>
          {this.state.expanded ?
            <ArrowDropDownIcon style={{
              fontSize: iconSize,
              marginTop: "auto",
              marginBottom: "auto"
            }} />
            :
            <ArrowRightIcon style={{
              fontSize: iconSize,
              marginTop: "auto",
              marginBottom: "auto"
            }} />
          }
          <div style={{
            fontSize,
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: "10px",
            width: "100%"
          }}>{this.props.sectionHeader}</div>
        </div>
      </div>
      {this.state.expanded && <CardContent>
        {this.props.children}
      </CardContent>}
    </Card>;
  }

}