import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _,
    bind
} from "$Imports/Imports";
import {
    PlateVM,
    PlateApiFactory,
    WellContentApiFactory,
    UpdateConcentrationRequestVM,
    ThresholdCycleVM,
    RtPCRResultVM,
    WetlabRunBarcodeReagentMapVM,
    WellAddRequestVM,
    WorkflowRunAssetApiFactory,
    RackVM
} from "$Generated/api";
import * as s from "underscore.string";

import { IWorkflowRunState, initialState, InjectedPropName, PassEnum } from "./IWorkflowRunState"
import { apiExceptionHandler } from "$State/ErrorFreezerService";

export interface ConcentrationVolumeVM {
    Concentration: number | undefined,
    SampleVolume: number | undefined,
    Ratio280: number | undefined,
    Ratio230: number | undefined
}

export class WorkflowRunInternalFreezerService extends FreezerService<IWorkflowRunState, typeof InjectedPropName>{
    constructor() {
        super(initialState,
            InjectedPropName);
    }

    get currentStep() {
        let {
            stepInstances,
            selectedStepInstanceId
        } = this.freezer.get();

        if (stepInstances.hasFetched && stepInstances.data) {
            return _.find(stepInstances.data, (step) => {
                return step.StepInstanceId === selectedStepInstanceId
            })
        }
        return null;
    }

    //#region Barcode state
    get useBarcodeKit() {
        return this.freezer.get().useBarcodeKit;
    }

    set useBarcodeKit(useBarcodeKit: boolean) {
        this.freezer.get().set({ useBarcodeKit });
    }

    get barcodeReagentMap() {
        return this.freezer.get().barcodeReagentMap.toJS();
    }

    set barcodeReagentMap(barcodeReagentMap: WetlabRunBarcodeReagentMapVM[]) {
        this.freezer.get().set({ barcodeReagentMap });
    }

    get barcodeReagentGroupId() {
        return this.freezer.get().barcodeReagentGroupId;
    }

    set barcodeReagentGroupId(barcodeReagentGroupId: string) {
        this.freezer.get().set({ barcodeReagentGroupId });
    }

    get barcodesInUse() {
        return this.freezer.get().barcodesInUse.toJS();
    }

    set barcodesInUse(barcodesInUse: string[]) {
        this.freezer.get().set({ barcodesInUse });
    }

    //#endregion


    //#region Gridding
    get wellsPlate() {
        return this.freezer.get().controlsPlate.toJS();
    }

    get wellsRack() {
        return this.freezer.get().controlsRack.toJS();
    }

    set wellsPlate(newWellsPlate: PlateVM) {
        let plateToInsert = _.clone(newWellsPlate);
        this.freezer.get().set({ controlsPlate: plateToInsert });

        let controlsToAdd: WellAddRequestVM[] = [];
        let currentWorkflowRun = this.freezer.get().workflowRun.toJS();

        if (currentWorkflowRun && currentWorkflowRun.data) {
            for (var control of currentWorkflowRun.data.Controls) {
                var positions = _.filter(plateToInsert.WellContents, (w) => { return ((w.Control ? w.Control.Name === control.Name : false)) }).map(w => w.WellPosition);
                if (positions.length > 0) {
                    controlsToAdd.push({ Id: control.Id, Positions: positions, Name: control.Name, WellContent: "Control" });
                }
            }
            for(var sample of _.filter(plateToInsert.WellContents, w => ((w.Sample ? true : false))))
            {
                controlsToAdd.push({ Id: sample.Sample?.Id || sample.Id, Positions: [sample.WellPosition], Name: sample.Sample?.SampleId || "", WellContent: "Sample" });
            }
        }
        this.freezer.get().set({ selectedControls: controlsToAdd });
    }

    set wellsRack(newWellsRack: RackVM) {
        let rackToInsert = _.clone(newWellsRack);
        this.freezer.get().set({ controlsRack: rackToInsert });

        let controlsToAdd: WellAddRequestVM[] = [];
        let currentWorkflowRun = this.freezer.get().workflowRun.toJS();

        if (currentWorkflowRun && currentWorkflowRun.data) {
            for (var control of currentWorkflowRun.data.Controls) {
                var positions = _.filter(rackToInsert.WellContents, (w) => { return ((w.Control ? w.Control.ControlId === control.Id : false)) }).map(w => w.WellPosition);
                if (positions.length > 0) {
                    controlsToAdd.push({ Id: control.Id, Positions: positions, Name: control.Name, WellContent: "Control" });
                }
            }
            for(var sample of _.filter(rackToInsert.WellContents, w => ((w.Sample ? true : false))))
            {
                controlsToAdd.push({ Id: sample.Sample?.Id || sample.Id, Positions: [sample.WellPosition], Name: sample.Sample?.SampleId || "", WellContent: "Sample" });
            }
        }
        this.freezer.get().set({ selectedControls: controlsToAdd });
    }

    public resetControlsAsset() {
        this.wellsPlate = initialState.controlsPlate;
        this.wellsRack = initialState.controlsRack;
    }

    async saveWells() {
        let {
            controlsPlate,
            workflowRunId,
            selectedStepInstanceId,
            selectedControls,
            stepInstances
        } = this.getState();

        if (selectedStepInstanceId && stepInstances && stepInstances.data) {
            let currentStep = _.find(stepInstances.data, (step) => {
                return step.StepInstanceId === selectedStepInstanceId
            });

            if (currentStep && controlsPlate && workflowRunId && selectedStepInstanceId) {
                let outputAsset = _.find(currentStep.OutputAssets, asset => (asset.Name === currentStep!.OutputName));
                if (outputAsset) {
                    await managedAjaxUtil.fetchResults({
                        freezer: this.freezer,
                        ajaxStateProperty: 'saveControlsResponse',
                        onExecute(apiOptions, params, options) {
                            let factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                            return factory.apiV1PlatePlateIdSaveControlsPatch(params)
                        },
                        params: {
                            plateId: outputAsset.Id,
                            workflowRunId: workflowRunId,
                            body: selectedControls
                        },
                        onError: apiExceptionHandler
                    });
                }
            }
        }
    }

    async addControlsToPlate(plateId: string, workFlowRunId: string, controls: WellAddRequestVM[]) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'saveControlsResponse',
            onExecute(apiOptions, params, options) {
                let factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PlatePlateIdAddControlsPatch(params);
            },
            params: {
                plateId: plateId,
                workflowRunId: workFlowRunId,
                body: controls
            },
            onError: apiExceptionHandler
        });
    }

    async saveWellsToAsset() {
        let {
            controlsPlate,
            workflowRunId,
            selectedStepInstanceId,
            selectedControls,
            stepInstances
        } = this.getState();

        if (selectedStepInstanceId && stepInstances && stepInstances.data) {
            let currentStep = _.find(stepInstances.data, (step) => {
                return step.StepInstanceId === selectedStepInstanceId
            });

            if (currentStep && selectedControls && workflowRunId && selectedStepInstanceId) {
                let outputAsset = _.find(currentStep.OutputAssets, asset => (asset.Name === currentStep!.OutputName));
                if (outputAsset) {
                    await managedAjaxUtil.fetchResults({
                        freezer: this.freezer,
                        ajaxStateProperty: 'saveControlsResponse',
                        onExecute(apiOptions, params, options) {
                            let factory = WorkflowRunAssetApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                            return factory.apiV1WorkflowRunAssetSaveControlsPatch(params)
                        },
                        params: {
                            assetId: outputAsset.Id,
                            assetTypeId: outputAsset.AssetType,
                            workflowRunId: workflowRunId,
                            body: selectedControls
                        },
                        onError: apiExceptionHandler
                    });
                }
            }
        }
    }

    async plateSamples() {
        let {
            controlsPlate,
            workflowRunId,
            selectedStepInstanceId,
            selectedControls,
            stepInstances
        } = this.getState();

        if (selectedStepInstanceId && stepInstances && stepInstances.data) {
            let currentStep = _.find(stepInstances.data, (step) => {
                return step.StepInstanceId === selectedStepInstanceId
            });

            if (currentStep && controlsPlate && workflowRunId && selectedStepInstanceId) {
                let outputAsset = _.find(currentStep.OutputAssets, asset => (asset.Name === currentStep!.OutputName));
                if (outputAsset) {
                    await managedAjaxUtil.fetchResults({
                        freezer: this.freezer,
                        ajaxStateProperty: 'plateSamplesResponse',
                        onExecute(apiOptions, params, options) {
                            let factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                            return factory.apiV1PlatePlateIdPlateSamplesPatch(params)
                        },
                        params: {
                            plateId: outputAsset.Id,
                            workflowRunId: workflowRunId,
                            body: selectedControls
                        },
                        onError: apiExceptionHandler
                    });
                }
            }
        }
    }
    //#endregion

    //#region Concentrations
    get concentrationDictionary() {
        return this.freezer.get().concentrationDictionary;
    }

    set concentrationDictionary(concentrationDictionary: { [index: string]: ConcentrationVolumeVM | undefined }) {
        this.freezer.get().set({ concentrationDictionary });
    }

    public resetConcentrationDictionary() {
        this.concentrationDictionary = initialState.concentrationDictionary;
    }

    public async saveConcentrations() {
        let {
            selectedStepInstanceId,
            stepInstances
        } = this.getState();

        if (selectedStepInstanceId && stepInstances && stepInstances.data) {
            let concentrationUpdateList: UpdateConcentrationRequestVM[] = _.map(this.concentrationDictionary, (concentration, id) => {
                return {
                    Id: id,
                    Concentration: concentration?.Concentration,
                    SampleVolume: concentration?.SampleVolume,
                    AbsorbanceRatio260Over280: concentration?.Ratio280,
                    AbsorbanceRatio260Over230: concentration?.Ratio230
                };
            });

            if (concentrationUpdateList.length > 0) {
                await managedAjaxUtil.fetchResults({
                    freezer: this.freezer,
                    ajaxStateProperty: "saveConcentrationsResponse",
                    onExecute(apiOptions, params, options) {
                        let factory = WellContentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                        return factory.apiV1WellContentUpdateConcentrationDataPost(params)
                    },
                    params: {
                        body: concentrationUpdateList
                    },
                    onError: apiExceptionHandler
                })
            }
        }
    }
    //#endregion

    //#region RTPCR Pass/Fail

    get passFailDictionary() {
        return this.freezer.get().passFailDictionary;
    }

    set passFailDictionary(passFailDictionary: { [index: string]: PassEnum }) {
        this.freezer.get().set({ passFailDictionary });
    }

    get thresholdCycleDictionary() {
        return this.freezer.get().thresholdCycleDictionary.toJS();
    }

    set thresholdCycleDictionary(thresholdCycleDictionary: { [index: string]: ThresholdCycleVM[] | undefined }) {
        this.freezer.get().set({ thresholdCycleDictionary });
    }

    public resetPassFailDictionary() {
        this.passFailDictionary = initialState.passFailDictionary;
    }

    public async updateRtPCRState() {
        const currentStep = this.currentStep;

        let {
            workflowRunId,
        } = this.getState();
        if (currentStep && currentStep.OutputAssets) {

            let inputAsset = _.find(currentStep.InputAssets, a => a.Name === currentStep.InputName);


            if (inputAsset && inputAsset.PlateSummary && workflowRunId) {
                let passFailUpdateList: RtPCRResultVM[] = _.map(this.passFailDictionary, (status, id) => {
                    return {
                        SampleId: id,
                        PassFailContinue: status === "Pass" ? 1 : status === "Fail" ? 0 : status === "ContinueFail" ? 2 : undefined,
                        ThresholdCycles: this.thresholdCycleDictionary[id]
                    };
                });
                await managedAjaxUtil.fetchResults({
                    freezer: this.freezer,
                    ajaxStateProperty: "updateRtPCRStateResponse",
                    onExecute(apiOptions, params, options) {
                        let factory = WellContentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                        return factory.apiV1WellContentUpdateRtPCRStatePost(params)
                    },
                    params: {
                        assetToSaveResultsToId: inputAsset.PlateSummary.Id,
                        body: passFailUpdateList,
                        workflowRunId: workflowRunId
                    },
                    onError: apiExceptionHandler
                });
            }
        }
    }
    //#endregion
}