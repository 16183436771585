import {
    React,
    _
} from "$Imports/Imports";

import {
    TableRow,
    TableHead,
    TableCell
} from "$Imports/MaterialUIComponents";


import { IStandardPlateColumn } from "./IStandardPlateColumn";

interface IPlateColumnHeaderProps {
    columns: Array<IStandardPlateColumn>;
}

export class PlateColumnHeader extends React.PureComponent<IPlateColumnHeaderProps> {

    render() {
        const { columns } = this.props;
        return (
            <TableHead>
                <TableRow>
                    <TableCell />
                    {_.map(columns, (c, cIdx: number) => {
                        return (
                            <TableCell
                                key={cIdx}
                                align={"center"}
                            >
                                {c.headerValue}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    }
}
