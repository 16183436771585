import {
    bind,
    React
} from "$Imports/Imports";
import {
    MenuItem,
    TextField
} from "$Imports/MaterialUIComponents";
import { Autocomplete } from "@material-ui/lab";
import { ChangeEvent } from "react";

import
    * as s
    from "underscore.string";

interface ISafeStoppingPointComponentProps {
}

interface ISafeStoppingPointComponentState {
}

export class SafeStoppingPointComponent extends React.Component<ISafeStoppingPointComponentProps, ISafeStoppingPointComponentState> {

    public state: ISafeStoppingPointComponentState = {
    };

    public render() {
        return <div>
            <h2>SAFE STOPPING POINT</h2>
        </div>;
    }

}