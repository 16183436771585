import { ConvertPositionToString, ConvertStringToPosition } from "$Components/common/StandardPlate/StandardPlate";
import { PlateVM, RackVM, ReagentLotVM, WellContentVM, WetlabRunBarcodeReagentMapVM } from "$Generated/api";
import { StandardPlate, DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Dialog, Card, FormControlLabel, CardActions, Button } from "$Imports/MaterialUIComponents";
import { WorkflowRunFreezerService } from "$State/WorkflowRun/WorkflowRunFreezerService";
import { RadioGroup, Radio } from "@material-ui/core";
import { GetWorkflowType, GetCurrentStepOutputType, IWorkflowScreenProps } from "../WorkflowStep";


interface IBarcodingPrimerPrepMinIONDialogBaseProps {
    open: boolean;
    disabled: boolean;
    close: () => void;
    workflowRunService: WorkflowRunFreezerService;
    showTableToggle: boolean;
}

interface IBarcodingPrimerPrepMinIONDialogState {
    barcodeAsset: RackVM | PlateVM;
    sampleAsset: RackVM | PlateVM;
    inputAsset?: RackVM | PlateVM;
    showAsset: boolean;
    sortedAsset?: WellContentVM[];
    barcodeWells?: ReagentLotVM[];
}

const barcodeStyles: {
    card: string;
    dialog: string;
    AssetDiv: string;
    dataTable: string;
} = require("./Barcoding.scss");

const styles: {
    footerDiv: string;
    mainDiv: string;
    tableHeader: string;
} = require("./CommonStepStyles.scss");



type IBarcodingPrimerPrepMinIONDialogProps = IBarcodingPrimerPrepMinIONDialogBaseProps;

export class BarcodingPrimerPrepMinIONDialog extends React.Component<IBarcodingPrimerPrepMinIONDialogProps, IBarcodingPrimerPrepMinIONDialogState> {

    state: IBarcodingPrimerPrepMinIONDialogState = {
        showAsset: this.props.showTableToggle ? false : true,
        barcodeAsset: {
            WellContents: [],
            Id: "",
            Name: "",
            WellMaterial: "NotApplicable",
            Status: "InUse",
            Barcode: ""
        },
        sampleAsset: {
            WellContents: [],
            Id: "",
            Name: "",
            WellMaterial: "NotApplicable",
            Status: "InUse",
            Barcode: ""
        },
    }

    async componentDidMount() {
        const currentStep = this.props.workflowRunService.currentStep;
        let sortedAsset: WellContentVM[] | undefined;

        if (GetWorkflowType() === "Anthrax") {
            this.setState({
                barcodeAsset: {
                    WellContents: [],
                    Id: "",
                    Name: "bcRack",
                    WellMaterial: "NotApplicable",
                    Status: "InUse",
                    Barcode: ""
                },
            })
            if (currentStep && currentStep.InputAssets) {
                const racks = this.props.workflowRunService.getState().racks;
                if (racks.data) {
                    const inputRack = _.find(racks.data, rack => (rack.Name === currentStep.InputName));
                    sortedAsset = inputRack?.WellContents.sort((a, b) => {
                        return a.WellPosition - b.WellPosition;
                    });
                    if (inputRack) {
                        this.setState({ inputAsset: inputRack });
                        this.setState({ sortedAsset: sortedAsset });
                        this.setState({ sampleAsset: inputRack });
                    }
                }
            }
        }
        else {
            this.setState({
                barcodeAsset: {
                    WellContents: [],
                    Id: "",
                    Name: "bcPlate",
                    WellMaterial: "NotApplicable",
                    Status: "InUse",
                    Barcode: ""
                },
            })
            if (currentStep && currentStep.InputAssets) {
                const plates = this.props.workflowRunService.getState().plates;
                if (plates.data) {
                    const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
                    sortedAsset = inputPlate?.WellContents.sort((a, b) => {
                        return a.WellPosition - b.WellPosition;
                    });
                    if (inputPlate) {
                        this.setState({ inputAsset: inputPlate });
                        this.setState({ sortedAsset: sortedAsset });
                        this.setState({ sampleAsset: inputPlate });
                    }
                }
            }
        }

        const workflowRunBarcodeData = this.props.workflowRunService.getState().fetchWorkflowRunBarcodes.data || [];
        if (workflowRunBarcodeData.length > 0) {

            const sampleWells: WellContentVM[] = [];
            _.forEach(workflowRunBarcodeData, barcode => {
                var inputPlateWells;
                if (sortedAsset) {
                    inputPlateWells = _.find(sortedAsset, w => w.WellPosition == barcode.WellPosition)
                }

                if (inputPlateWells?.Sample?.SampleId.includes("TC") || inputPlateWells?.Control?.Name.includes("TC")) {
                    sampleWells.push({
                        Id: barcode.Id,
                        RunCount: 1,
                        WellPosition: inputPlateWells?.WellPosition || 0,
                        Control: {
                            Id: barcode.Id,
                            SampleId: (barcode.Barcode || "").replace("barcode", "BC"),
                            ControlId: barcode.Id,
                            Name: inputPlateWells?.Control?.Name || ""
                        }
                    });
                } else {
                    sampleWells.push({
                        Id: barcode.Id,
                        RunCount: 1,
                        WellPosition: inputPlateWells?.WellPosition || 0,
                        Sample: {
                            Id: barcode.Id,
                            SampleId: (barcode.Barcode || "").replace("barcode", "BC"),
                            SpecimenSource: "",
                            SampleStatus: 0,
                            DateHarvested: new Date()
                        }
                    });
                }
            });


            this.props.workflowRunService.barcodeReagentMap = _.map(workflowRunBarcodeData, barcode => {
                return {
                    ReagentLotId: barcode.ReagentLotId,
                    ReagentGroupLotId: barcode.ReagentGroupLotId || "",
                    WellPosition: barcode.WellPosition
                };
            });

            if (GetWorkflowType() === "Anthrax") {
                this.setState({
                    sampleAsset: {
                        WellContents: sampleWells,
                        Id: "samplerack",
                        Name: "samplerack",
                        WellMaterial: "NotApplicable",
                        Status: "InUse",
                        Barcode: ""
                    },
                })
            }
            else {
                this.setState({
                    sampleAsset: {
                        WellContents: sampleWells,
                        Id: "sampleplate",
                        Name: "sampleplate",
                        WellMaterial: "NotApplicable",
                        Status: "InUse",
                        Barcode: ""
                    },
                })
            }

        }
    }

    private barcodeReagentColumns(): Array<IDataTableColumn<ReagentLotVM>> {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentStep && currentWorkflowRun) {
            let disabled = this.props.disabled || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return [
                {
                    columnName: "barcode-well",
                    columnFieldData: (d) => <div>{d.BarcodeWellPosition}</div>,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Barcode Well",
                },
                {
                    columnName: "sequence-name",
                    columnFieldData: "SequenceName",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sequence Name",
                },
                {
                    columnName: "sequence",
                    columnFieldData: "Sequence",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sequence",
                },

                {
                    columnName: "sample-well",
                    columnFieldData: (d) => {
                        const mappedVal = _.find(this.props.workflowRunService.barcodeReagentMap, brm => brm.ReagentLotId === d.Id);
                        if (this.state.inputAsset && mappedVal !== undefined) {
                            if (this.state.sortedAsset) {
                                const well = _.find(this.state.sortedAsset, w => w.WellPosition == mappedVal.WellPosition);
                                if (well) {
                                    return <div>{ConvertPositionToString(well.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow)}</div>;
                                }
                            }
                        }
                        return <></>;
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample Well",
                },
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => {
                        //Convert from A1
                        const mappedVal = _.find(this.props.workflowRunService.barcodeReagentMap, brm => brm.ReagentLotId === d.Id);
                        if (this.state.inputAsset && mappedVal !== undefined) {
                            if (this.state.sortedAsset) {
                                const well = _.find(this.state.sortedAsset, w => w.WellPosition == mappedVal.WellPosition);
                                if (well) {
                                    return <div>{(well.Sample ? well.Sample.SampleId : (well.Control ? well.Control.Name : ""))}</div>;
                                }
                            }
                        }
                        return <></>;
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample Id",
                },
            ];
        }
        return [];
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        let wellCount = 0;
        let columnCount = 0;
        //TODO: Re-evaluate when allowing multiple barcode kit selection
        const selectedbarcodeKitData = _.find(this.props.workflowRunService.getState().fetchBarcodeKits.data, b => _.find(b.Lots, l => l.Id === this.props.workflowRunService.barcodeReagentGroupId) !== undefined);
        let barcodeWells: ReagentLotVM[] = [];
        if (selectedbarcodeKitData) {
            barcodeWells = _.orderBy(_.find(selectedbarcodeKitData.Lots, l => l.Id === this.props.workflowRunService.barcodeReagentGroupId)?.ReagentLots || [],
                r => ConvertStringToPosition(r.BarcodeWellPosition || "", currentWorkflowRun?.AssetColCount || 0, currentWorkflowRun?.AssetRowCount || 0, currentWorkflowRun?.AssetPositionByRow || false));
        }

        if (GetWorkflowType() === "Anthrax") {
            wellCount = 12;
            columnCount = 12;
        }
        else {
            wellCount = 96;
            columnCount = 12;
        }

        if (currentWorkflowRun) {
            return <Dialog
                PaperProps={{ className: barcodeStyles.dialog }}
                className={barcodeStyles.dialog}
                open={this.props.open}
            >
                <Card className={barcodeStyles.card}>
                    {this.props.showTableToggle &&
                        <RadioGroup row value={this.state.showAsset} onChange={this.handleRadioChange} >
                            <FormControlLabel value={false} control={<Radio />} label="Show Table" />
                            <FormControlLabel value={true} control={<Radio />} label="Show Asset" />
                        </RadioGroup>}
                    {this.state.showAsset ?
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {!this.props.disabled && <div className={barcodeStyles.AssetDiv}>
                                <div style={{ display: "flex", flexDirection: "row", marginRight: "5px", }}>
                                    <h2 style={{ margin: "0px" }}>{this.state.barcodeAsset.Name}</h2>
                                    <div style={{ margin: "auto", marginLeft: "5px", }}>Select starting well position on Barcode Sample</div>
                                </div>
                                <StandardPlate
                                    wellContents={this.state.barcodeAsset.WellContents}
                                    wellCount={wellCount}
                                    columnCount={columnCount}
                                    positionByRow={currentWorkflowRun.AssetPositionByRow}
                                    wellClick={this.props.disabled ? () => { } : this.handleWellClick}
                                    additionalTooltipInformation={undefined}
                                />
                            </div>}
                            <div className={barcodeStyles.AssetDiv} style={{ margin: "0px 0px 0px 25px" }}>
                                <h2 style={{ margin: "0px" }}>Sample</h2>
                                <StandardPlate
                                    wellCount={currentWorkflowRun.AssetColCount * currentWorkflowRun.AssetRowCount}
                                    columnCount={currentWorkflowRun.AssetColCount}
                                    wellContents={this.state.sampleAsset.WellContents}
                                    positionByRow={currentWorkflowRun.AssetPositionByRow}
                                    wellClick={this.props.disabled ? () => { } : this.handleWellClick}
                                />
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", }}
                            className={barcodeStyles.dataTable}
                        >
                            <DataTable
                                columns={this.barcodeReagentColumns()}
                                data={(this.state.barcodeWells == null ? barcodeWells : this.state.barcodeWells)}
                                onRowClick={this.props.disabled ? undefined : (e, data) => {
                                    const intVal = ConvertStringToPosition(data.BarcodeWellPosition || "", currentWorkflowRun?.AssetColCount || 0, currentWorkflowRun?.AssetRowCount || 0, !!currentWorkflowRun?.AssetPositionByRow);
                                    this.handleWellClick(intVal)
                                }}
                            />
                        </div>
                    }
                    <CardActions style={{ float: "right" }} >
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => { this.props.close() }}
                            disabled={this.state.sampleAsset.WellContents.length === 0}
                        >
                            Done
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>;
        }
        return <></>;
    }

    @bind
    private handleWellClick(position: number) {
        if (this.state.inputAsset && position + this.state.inputAsset.WellContents.length <= 96) {
            const barcodeKitData = _.find(this.props.workflowRunService.getState().fetchBarcodeKits.data, b => _.find(b.Lots, l => l.Id === this.props.workflowRunService.barcodeReagentGroupId) !== undefined);
            const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
            if (barcodeKitData && currentWorkflowRun && this.state.sortedAsset) {
                const barcodeAssetWells: WellContentVM[] = [];
                const sampleAssetWells: WellContentVM[] = [];
                const barcodeWells = _.find(barcodeKitData.Lots, l => l.Id === this.props.workflowRunService.barcodeReagentGroupId)?.ReagentLots || [];
                const barcodeMaps: WetlabRunBarcodeReagentMapVM[] = [];

                for (let x = 0; x < this.state.inputAsset.WellContents.length; x++) {

                    var barcodeWellPos = ConvertPositionToString(x + position, 12, 8, currentWorkflowRun.AssetPositionByRow)
                    const barcodeReagents = _.filter(barcodeWells, well => barcodeWellPos === well.BarcodeWellPosition);
                    const sample = this.state.sortedAsset[x];

                    _.forEach(barcodeReagents, barcodeReagent => {
                        barcodeMaps.push({
                            ReagentLotId: barcodeReagent?.Id || "",
                            ReagentGroupLotId: this.props.workflowRunService.barcodeReagentGroupId,
                            WellPosition: sample.WellPosition,
                        });

                        if (sample?.Control) {
                            sampleAssetWells.push({
                                Id: x.toString(),
                                RunCount: 1,
                                WellPosition: sample.WellPosition,
                                Control: {
                                    Id: x.toString(),
                                    SampleId: barcodeReagent?.ReagentName || "TEST" + x,
                                    ControlId: x.toString(),
                                    Name: sample?.Control?.Name || x.toString()
                                }
                            });
                            barcodeAssetWells.push({
                                Id: x.toString(),
                                RunCount: 1,
                                WellPosition: x + position,
                                Control: {
                                    Id: x.toString(),
                                    SampleId: barcodeReagent?.ReagentName || "TEST" + x,
                                    ControlId: x.toString(),
                                    Name: sample?.Control?.Name || x.toString()
                                }
                            });
                        } else if (sample?.Sample) {
                            sampleAssetWells.push({
                                Id: x.toString(),
                                RunCount: 1,
                                WellPosition: sample.WellPosition,
                                Sample: {
                                    Id: x.toString(),
                                    SampleId: barcodeReagent?.ReagentName || "TEST" + x,
                                    SpecimenSource: sample.Sample.SpecimenSource,
                                    SampleStatus: sample.Sample.SampleStatus,
                                    DateHarvested: sample.Sample.DateHarvested
                                }
                            });
                            barcodeAssetWells.push({
                                Id: x.toString(),
                                RunCount: 1,
                                WellPosition: x + position,
                                Sample: {
                                    Id: x.toString(),
                                    SampleId: barcodeReagent?.ReagentName || "TEST" + x,
                                    SpecimenSource: sample.Sample.SpecimenSource,
                                    SampleStatus: sample.Sample.SampleStatus,
                                    DateHarvested: sample.Sample.DateHarvested
                                }
                            });
                        }
                    });
                }

                this.props.workflowRunService.barcodeReagentMap = barcodeMaps;

                this.setState({
                    barcodeAsset: {
                        WellContents: barcodeAssetWells,
                        Id: "",
                        Name: barcodeKitData.Name,
                        WellMaterial: "NotApplicable",
                        Status: "InUse",
                        Barcode: ""
                    },
                    sampleAsset: {
                        WellContents: sampleAssetWells,
                        Id: "",
                        Name: GetWorkflowType() === "Anthrax" ? "sampleRack" : "samplePlate",
                        WellMaterial: "NotApplicable",
                        Status: "InUse",
                        Barcode: ""
                    },
                })
            }
        }
    }

    @bind
    private handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ showAsset: (event.target as HTMLInputElement).value.toLowerCase() === "true" })
    }
}