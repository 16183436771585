import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";

import {
    SampleInfoTable,
    StandardPlate,
    IDataTableColumn,
    StepActionsControl,
    StepChangeControl,
    AdvanceTextField,
    DataTable,
    QCUIComponent,
    QuantificationBeforePoolingConcentrationEntryMandatoryCheck,
    QuantificationBeforePoolingNTCConcentrationCheck,
    CollapsibleSection,
    DataLoadingDisplay,
    QuantificationBeforePoolingPTCConcentrationCheck
} from "$Imports/CommonComponents";

import {
    QCCheckInstanceVM,
    QCCheckResultVM,
    WellContentVM
} from "$Generated/api";

import {
    ConcentrationVolumeVM
} from "$State/WorkflowRun/WorkflowRunInternalFreezerService"

import {
    Snackbar,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    TableCellProps,
    Tooltip,
    ErrorIcon
} from "$Imports/MaterialUIComponents";

import * as s from "underscore.string";
import { ChangeEvent } from "react";
import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";
import { Autocomplete } from "@material-ui/lab";

const styles: {
    tableHeader: string;
    mainDiv: string;
    table: string;
    input: string;
    failRow: string;
    errorIcon: string;
    inputDiv: string;
} = require("./Quantification.scss");

const commonStyles: {
    footerDiv: string;
} = require("./CommonStepStyles.scss");

export interface IQuantificationState {
    messageSnackbarOpen: boolean;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    dataLoaded: boolean;
}

export class QuantificationScreen extends React.Component<IWorkflowScreenProps, IQuantificationState> {

    state: IQuantificationState = {
        messageSnackbarOpen: false,
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        dataLoaded: false
    };

    @bind
    private cellProps(d: WellContentVM): TableCellProps {
        let { concentrationDictionary } = this.props.workflowRunService;
        let concentrationEntry = concentrationDictionary[d.Id]
        if (d.Control && d.Control.Name === "NTC" && concentrationEntry !== undefined && concentrationEntry !== null && concentrationEntry.Concentration !== undefined && concentrationEntry.Concentration && concentrationEntry.Concentration > 1) {
            return {
                className: styles.failRow
            };
        }
        if (d.Control && d.Control.Name === "PTC" && concentrationEntry !== undefined && concentrationEntry !== null && concentrationEntry.Concentration !== undefined && concentrationEntry.Concentration && concentrationEntry.Concentration < 5) {
            return {
                className: styles.failRow
            };
        }
        return {
        };
    }

    //Returns the columns for the well contents. Needs to be a function to handle the disabled flag as well as correctly updating within react.
    private wellContentColumns(): Array<IDataTableColumn<WellContentVM>> {
        let {
            concentrationDictionary,
            currentStep,
            currentWorkflowRun
        } = this.props.workflowRunService;

        let disabled = false;

        if (currentStep && currentWorkflowRun) {
            disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
        }
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Sample ID",
                    sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
                },
                {
                    columnName: "sample-well-position",
                    columnFieldData: (d) => ConvertPositionToString(d.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Well Position",
                    sortMethod: (d) => d.WellPosition ?? ""
                },
                {
                    columnName: "sample-date-harvested",
                    columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Date Harvested",
                    sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
                },
                {
                    columnName: "concentration",
                    columnFieldData: (d) => {
                        let concentrationEntry = concentrationDictionary[d.Id];

                        return (<div>
                            <TextField disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={concentrationEntry !== undefined ? concentrationEntry.Concentration !== undefined ? concentrationEntry.Concentration : "" : ""} onChange={(e) => { this.onConcentrationChanged(d.Id, e.target.value); }} />
                            {(d.Control && d.Control.Name === "NTC" && concentrationEntry !== undefined && concentrationEntry !== null && concentrationEntry.Concentration !== undefined && concentrationEntry.Concentration > 1) &&
                                <Tooltip
                                    title={"NTC DNA Concrentration cannot be > 1 ng/ul"}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>}
                            {(d.Control && d.Control.Name === "PTC" && concentrationEntry !== undefined && concentrationEntry !== null && concentrationEntry.Concentration !== null && concentrationEntry.Concentration !== undefined && concentrationEntry.Concentration < 5) &&
                                <Tooltip
                                    title={"PTC DNA Concrentration cannot be 0 ng/ul"}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>}

                        </div>);
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Concentration (ng/ul)",
                }
            ];
        }
        return [];
    }

    async componentDidMount() {
        this.props.workflowRunService.resetConcentrationDictionary();

        if (GetWorkflowType() === "Anthrax") {
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }

        await this.props.workflowRunService.getConcentrationControls(true);
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
        await this.props.workflowRunService.fetchInstrumentOptions();

        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;

        if (currentStep) {
            const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
            let concentrationDictionary: { [index: string]: ConcentrationVolumeVM | undefined } = {};
            if (inputPlate) {
                _.forEach(inputPlate.WellContents, w => {
                    concentrationDictionary[w.Id] = {
                        Concentration: w.Concentration,
                        SampleVolume: inputPlate.WellVolume,
                        Ratio230: 0,
                        Ratio280: 0
                    };
                });
                this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
            }
        }
        this.setState({ dataLoaded: true });
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {

        const plates = this.props.workflowRunService.getState().plates;
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const controls = this.props.workflowRunService.getState().getConcentrationControlsState;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        if (plates.data && currentStep && currentWorkflowRun && instrumentOptions) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });

            const customFields = currentStep.CustomFields;
            if (customFields && inputPlate && controls.data) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={styles.mainDiv}>
                                <div>
                                    <h2>Absorbance Measurement</h2>
                                    UV Absorbance Instrument: <Autocomplete
                                        freeSolo
                                        disabled={disabled}
                                        id="instrument-name"
                                        options={instrumentOptions}
                                        onChange={(e, val) => this.onTextChanged("UVAbsorbanceInstrument", val || "")}
                                        value={(customFields["UVAbsorbanceInstrument"] ? customFields["UVAbsorbanceInstrument"] : "")}
                                        renderInput={(params) =>
                                        (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                                autoFocus={true}
                                                className={styles.inputDiv}
                                                disabled={disabled}
                                                label=""
                                                value={(customFields["UVAbsorbanceInstrument"] ? customFields["UVAbsorbanceInstrument"] : "")}
                                                onChange={(e) => { this.onTextChanged("UVAbsorbanceInstrument", e.target.value) }} />
                                        )}
                                    />
                                    <h2>DNA Concentration Controls </h2>
                                    <div>
                                        <InputLabel id="kit-name-label">Concentration Control Kit Name</InputLabel>
                                        <Select
                                            className={styles.input}
                                            disabled={disabled}
                                            labelId="kit-name-label"
                                            id="kit-name"
                                            value={(customFields["KitName"] ? customFields["KitName"] : "")}
                                            onChange={(event) => { this.props.workflowRunService.updateCustomField("KitName", (event.target.value as string)); }}
                                        >
                                            {_.map(controls.data, k => {
                                                return (<MenuItem key={k.Id} value={k.Name}>{k.Name}</MenuItem>)
                                            })}
                                        </Select>
                                    </div>

                                </div>
                                <SampleInfoTable
                                    data={inputPlate?.WellContents}
                                    columns={this.wellContentColumns()}
                                    header={"Samples"}
                                    defaultSortColumnName={"sample-well-position"} />
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                    completeStep={this.completeMoveToNextStep}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={"All information is required, NTC controls must be < 1 ng/ul, and PTC controls must be > 0 ng/ul"}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div >)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private onTextChanged(key: string, newValue: string) {
        this.props.workflowRunService.updateCustomField(key, newValue);
    }



    @bind
    private onConcentrationChanged(wellContentId: string, newValue: string) {
        let concentrationDictionary = _.cloneDeep(this.props.workflowRunService.concentrationDictionary);
        let num = parseFloat(newValue);

        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;

        if (currentStep) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName && p.Status !== "Empty" });
            concentrationDictionary[wellContentId] = {
                Concentration: isNaN(num) ? undefined : num,
                SampleVolume: inputPlate ? inputPlate.WellVolume : 0,
                Ratio230: 0,
                Ratio280: 0
            };
            this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
        }
    }

    @bind
    private async moveToNextStepBegin() {
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        let concentrationDictionary = this.props.workflowRunService.concentrationDictionary
        if (currentStep && plates.data && currentWorkflowRun) {
            let failedQCs: QCCheckInstanceVM[] = [];
            let qcResults: QCCheckResultVM[] = [];
            const customFields = currentStep.CustomFields;
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            if (inputPlate) {
                //Run all QCs
                _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                    switch (qc.QCCheckType) {
                        case QuantificationBeforePoolingConcentrationEntryMandatoryCheck:
                            let allConcentrationDataSet = _.every(concentrationDictionary, c => c !== undefined && c !== null && c.Concentration != null);
                            if (!allConcentrationDataSet) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? allConcentrationDataSet ? undefined : 1 : 2, //passed/modify/skipped
                                    MeasuredValue: allConcentrationDataSet ? "All Concentration Data Set" : "Concentration Data Missing",
                                    Date: new Date(Date.now()),
                                    Pass: allConcentrationDataSet,
                                    QCCheckInstance: qc,
                                }
                            );
                            break;
                        case QuantificationBeforePoolingPTCConcentrationCheck:
                            let minPTCConcParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MinConcentration")?.Value || "0")
                            let ptcChecksPass = _.every(concentrationDictionary, (c, id) => {
                                if (c !== undefined && c !== null) {
                                    if (inputPlate) {
                                        let well = _.find(inputPlate.WellContents, w => w.Id === id)
                                        if (well && c && c.Concentration) {
                                            if (well.Control && well.Control.Name === "PTC" && (c.Concentration < minPTCConcParam)) {
                                                return false;
                                            }
                                        }
                                    }
                                    return true;
                                }
                                return true;
                            });
                            if (!ptcChecksPass) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? ptcChecksPass ? undefined : 1 : 2, //passed/modify/skipped
                                    MeasuredValue: ptcChecksPass ? "PTC Concentration Passed" : "PTC Concentration Less Than Accepted Minimum",
                                    Date: new Date(Date.now()),
                                    Pass: ptcChecksPass,
                                    QCCheckInstance: qc,
                                }
                            );
                            break;
                        case QuantificationBeforePoolingNTCConcentrationCheck:
                            let minConcParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MinConcentration")?.Value || "0")
                            let maxConcParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MaxConcentration")?.Value || "0")
                            let ntcChecksPass = _.every(concentrationDictionary, (c, id) => {
                                if (c !== undefined && c !== null) {
                                    if (inputPlate) {
                                        let well = _.find(inputPlate.WellContents, w => w.Id === id)
                                        if (well && c && c.Concentration) {
                                            if (well.Control && well.Control.Name === "NTC" && (c.Concentration > maxConcParam || c.Concentration < minConcParam)) {
                                                return false;
                                            }
                                        }
                                    }
                                    return true;
                                }
                                return true;
                            });
                            if (!ntcChecksPass) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? ntcChecksPass ? undefined : 1 : 2, //passed/modify/skipped
                                    MeasuredValue: ntcChecksPass ? "NTC Concentration Passed" : "NTC Concentration Outside Accepted Range",
                                    Date: new Date(Date.now()),
                                    Pass: ntcChecksPass,
                                    QCCheckInstance: qc,
                                }
                            );
                            break;
                    }
                })
                if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                    this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
                }
                else {
                    this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                    this.completeMoveToNextStep();
                }
            }
        }
    }

    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentStep && plates.data && currentWorkflowRun) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            if (inputPlate) {
                const customFields = currentStep.CustomFields;

                let fieldWithMissingData = _.find(customFields, c => s.isBlank(c));

                if (fieldWithMissingData === undefined) {
                    await Promise.all([
                        this.props.workflowRunService.updateCustomFields(),
                        this.props.workflowRunService.addInstrument(customFields["UVAbsorbanceInstrument"]),
                        this.props.workflowRunService.saveConcentrations(),
                    ]);
                    await this.props.workflowRunService.completeStep();
                }
                else {
                    this.setState({ messageSnackbarOpen: true });
                }
            }
        }
    }
}