import {
    React,
    Freezer,
    bind,
    _,
    IAjaxState,
    moment
} from "$Imports/Imports";

import {
    PlateVM,
    SampleVM,
    WellContentVM,
    StepInstanceVM,
    WetlabRunVM,
    WetlabRunAssetsVM,
    StepInputOutputType
} from "$Generated/api";


import {
    Icon, Dialog
} from "$Imports/MaterialUIComponents";

import { IDataTableColumn } from "../DataTable/IDataTableColumn";
import { SampleInfoTable, StandardPlate } from "$Imports/CommonComponents";
import { ConvertPositionToString } from "../StandardPlate/StandardPlate";

const plate = require("./plate.svg") as string;

const rack = require("./rack.svg") as string;


const styles: {
    inputOutputContainerDiv: string;
    inputOutputRow: string;
    inputOutputLabelCell: string;
    inputOutputDataCell: string;
    imageIcon: string;
    iconRoot: string;
    tableHeader: string;
    dialogContainerDiv: string;
    dialog: string;
    scaledPlateDiv: string;
    componentDiv: string;
    plateNameDiv: string;
    plateRowDiv: string;
    inputTable: string;
    outputTable: string
} = require("./InputOutputHeader.scss");

export interface IInputOutputHeaderProps {
    step: Freezer.Types.IFrozenObject<StepInstanceVM>;
    workflowRunAssets: IAjaxState<WetlabRunAssetsVM>;
    fetchAssets: (force: boolean) => void;
    workflowRun: WetlabRunVM;
    inputAssetType: StepInputOutputType;
    outputAssetType: StepInputOutputType;
}

export interface IInputOutputHeaderState {
    open: boolean;
    fromInput: boolean;
    wellContents?: WellContentVM[];
    assetWellMaterial: string;
    assetStatus: string;
}

export class InputOutputHeader extends React.PureComponent<IInputOutputHeaderProps, IInputOutputHeaderState> {

    private readonly columns: Array<IDataTableColumn<WellContentVM>> = [
        {
            columnName: "sample-id",
            columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sample ID",
            sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
        },
        {
            columnName: "sample-date-harvested",
            columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Date Harvested",
            sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
        },
        {
            columnName: "sample-specimen-source",
            columnFieldData: (d) => (d.Sample ? d.Sample.SpecimenSource : ""),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Specimen Source",
            sortMethod: (d) => (d.Sample ? d.Sample.SpecimenSource : "") ?? ""
        },
        {
            columnName: "sample-well-position",
            columnFieldData: (d) => ConvertPositionToString(d.WellPosition, this.props.workflowRun.AssetColCount, this.props.workflowRun.AssetRowCount, this.props.workflowRun.AssetPositionByRow),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Well Position",
            sortMethod: (d) => d.WellPosition ?? ""
        }
    ];


    constructor(props: IInputOutputHeaderProps) {
        super(props);
        this.state = { open: false, fromInput: false, assetStatus: "", assetWellMaterial: ""  };
    }

    @bind
    private async openInputModal() {
        if (!this.props.workflowRunAssets.hasFetched) {
            await this.props.fetchAssets(true);
        }

        if (this.props.workflowRunAssets.data) {
            const { step } = this.props;

            switch(this.props.inputAssetType) {
                case "Plate": 
                    let plateAsset = _.find(this.props.workflowRunAssets.data.Plates, (p) => { return p.Name === step.InputName });
                    if(plateAsset) {
                        this.setState({ 
                            open: true, 
                            fromInput: true, 
                            wellContents: plateAsset.WellContents,
                            assetWellMaterial: plateAsset.WellMaterial,
                            assetStatus: plateAsset.Status
                        });
                    }
                    break;
                case "Rack": 
                    let rackAsset = _.find(this.props.workflowRunAssets.data.Racks, (r) => { return r.Name === step.InputName });
                    if(rackAsset) {
                        this.setState({ 
                            open: true, 
                            fromInput: true, 
                            wellContents: rackAsset.WellContents,
                            assetWellMaterial: rackAsset.WellMaterial,
                            assetStatus: rackAsset.Status
                        });
                    }
                    break;
            }
        }
    }

    @bind
    private async openOutputModal() {
        this.props.fetchAssets(true);

        if (this.props.workflowRunAssets.data) {
            const { step } = this.props;

            switch(this.props.outputAssetType) {
                case "Plate": 
                    let plateAsset = _.find(this.props.workflowRunAssets.data.Plates, (p) => { return p.Name === step.OutputName });
                    if(plateAsset) {
                        this.setState({ 
                            open: true, 
                            fromInput: false, 
                            wellContents: plateAsset.WellContents,
                            assetWellMaterial: plateAsset.WellMaterial,
                            assetStatus: plateAsset.Status
                        });
                    }
                    break;
                case "Rack": 
                    let rackAsset = _.find(this.props.workflowRunAssets.data.Racks, (r) => { return r.Name === step.OutputName });
                    if(rackAsset) {
                        this.setState({ 
                            open: true, 
                            fromInput: false, 
                            wellContents: rackAsset.WellContents,
                            assetWellMaterial: rackAsset.WellMaterial,
                            assetStatus: rackAsset.Status
                         });
                    }
                    break;
            }
        }
    }

    @bind
    private handleClose() {
        this.setState({ open: false });
    }

    render() {

        const { step } = this.props;
        let inputAssetSvg : string = "";
        let outputAssetSvg : string = "";

        if(step.InputType === "Plate") {
            inputAssetSvg = plate; 
        } else if (step.InputType === "Rack") {
            inputAssetSvg = rack;
        }

        if(step.OutputType === "Plate") {
            outputAssetSvg = plate; 
        } else if (step.OutputType === "Rack") {
            outputAssetSvg = rack;
        }

        //Data has been looked up already and returned in the step, we do not need to look at the output of the previous step.
        return (
            <div className={styles.componentDiv}>
                <div className={styles.inputOutputContainerDiv}>

                <table className={styles.inputTable} style={step.InputType !== "Plate" && step.InputType !== "Rack" ? { height: "64px" } : {}}>
                        <tbody>
                            <tr className={styles.inputOutputRow} >
                                <td className={styles.inputOutputLabelCell}>{step.Name} Input:</td>
                                <td className={styles.inputOutputDataCell}>
                                    <div className={styles.plateRowDiv}>
                                        <div className={styles.plateNameDiv}>
                                            {step.InputName}
                                        </div>
                                        {(step.InputType === "Plate" || step.InputType === "Rack") && <div>
                                            <Icon className={styles.iconRoot} onClick={this.openInputModal}>
                                                <img className={styles.imageIcon} src={inputAssetSvg} />
                                            </Icon>
                                        </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr className={styles.inputOutputRow}>
                                <td className={styles.inputOutputLabelCell}>Input Description:</td>
                                <td className={styles.inputOutputDataCell}>{step.InputDescription}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className={styles.outputTable} style={step.OutputType !== "Plate" && step.InputType !== "Rack"  ? { height: "64px" } : {}}>
                        <tbody>
                            <tr className={styles.inputOutputRow}>
                                <td className={styles.inputOutputLabelCell}>{step.Name} Output:</td>
                                <td className={styles.inputOutputDataCell}>
                                    <div className={styles.plateRowDiv}>
                                        <div className={styles.plateNameDiv}>
                                            {step.OutputName}
                                        </div>
                                        {(step.OutputType === "Plate" || step.InputType === "Rack") && <div>
                                            <Icon className={styles.iconRoot} onClick={this.openOutputModal}>
                                                <img className={styles.imageIcon} src={outputAssetSvg} />
                                            </Icon>
                                        </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr className={styles.inputOutputRow}>
                                <td className={styles.inputOutputLabelCell}>Output Description:</td>
                                <td className={styles.inputOutputDataCell}>{step.OutputDescription}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {this.state.wellContents &&
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        PaperProps={{ className: styles.dialog }}
                    >
                        <h1 className={styles.tableHeader}>{this.state.fromInput ? step.InputName : step.OutputName}</h1>
                        <h2 className={styles.tableHeader}>{this.state.assetWellMaterial + ": " + this.state.assetStatus}</h2>
                        <h2 className={styles.tableHeader}></h2>

                        <div className={styles.dialogContainerDiv}>
                            <SampleInfoTable
                                data={this.state.wellContents}
                                columns={this.columns}
                                defaultSortColumnName={"sample-well-position"}
                            />
                            <div className={styles.scaledPlateDiv}>
                                <StandardPlate
                                    wellContents={this.state.wellContents}
                                    wellCount={this.props.workflowRun.AssetColCount * this.props.workflowRun.AssetRowCount}
                                    columnCount={this.props.workflowRun.AssetColCount}
                                    positionByRow={this.props.workflowRun.AssetPositionByRow}
                                />
                            </div>
                        </div>
                    </Dialog>
                }
            </div>
        );

    }
}