import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports";
import
    * as s
    from "underscore.string";

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";
import {
    StepActionsControl,
    StepChangeControl,
    InstructionSetControl,
    CleanAfterReverseTranscriptaseTemperatureMandatoryEntryCheck,
    CleanAfterReverseTranscriptaseTemperatureInRangeCheck,
    QCUIComponent,
    CleanAfterBarcodingTemperatureInRangeCheck,
    CleanAfterBarcodingTemperatureMandatoryEntryCheck,
    CleanAfterEndRepairTemperatureMandatoryEntryCheck,
    CleanAfterEndRepairTemperatureInRangeCheck,
    CollapsibleSection,
    PCRControl,
    DataLoadingDisplay
} from "$Imports/CommonComponents";
import { TextField } from "@material-ui/core";
import { ChangeEvent } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { missingData } from "$Components/common/WorkflowControls/InstructionSetControl";
import { QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";
import { ErrorIcon } from "$Imports/MaterialUIComponents";

const styles: {
    tempField: string;
    tempDiv: string;
    tempLabelDiv: string;
    errorIcon: string;
} = require("./Cleanup.scss");

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");


interface ICleanupScreenState {
    displayValidationWarning: boolean;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    tempNotFilled: boolean;
    canMove: boolean;
}


export class CleanupWithPCRScreen extends React.Component<IWorkflowScreenProps, ICleanupScreenState> {

    state: ICleanupScreenState = {
        displayValidationWarning: false,
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        tempNotFilled: false,
        canMove: true
    };

    async componentDidMount() {
        this.loadData()
    }

    async componentDidUpdate(prevProps: IWorkflowScreenProps) {
        if (this.props.stepId !== prevProps.stepId) {
            this.loadData();
        }
    }

    private loadData() {
        this.props.workflowRunService.fetchCleaningDataForStep(true);

        if (GetWorkflowType() === "Anthrax") {
            this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            this.props.workflowRunService.fetchPlatesForStep(true);
        }
        this.props.workflowRunService.fetchInputPoolsForStep(true);
        this.props.workflowRunService.fetchStepInstanceCustomFields();
        if (GetWorkflowType() === "SarsCov2") {
            this.props.workflowRunService.fetchPCRForStep(true);
        }
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    @bind
    private moveToNextStepBegin() {
        let {
            currentStep,
        } = this.props.workflowRunService;

        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        if (currentStep && pcrInfoState.data) {

            if (!s.isBlank(pcrInfoState.data.InstrumentNameValue || "")) {

                let failedQCs: QCCheckInstanceVM[] = [];
                let qcResults: QCCheckResultVM[] = [];
                const customFields = currentStep.CustomFields;

                //Run all QCs
                _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                    switch (qc.QCCheckType) {
                        case CleanAfterReverseTranscriptaseTemperatureMandatoryEntryCheck:
                        case CleanAfterBarcodingTemperatureMandatoryEntryCheck:
                        case CleanAfterEndRepairTemperatureMandatoryEntryCheck:
                            let tempMandPass = true;
                            if (s.isBlank(customFields["Temperature"])) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                                tempMandPass = false;
                                this.setState({ tempNotFilled: true });
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? tempMandPass ? undefined : 1 : 2, //passed/modify/skipped
                                    MeasuredValue: s.isBlank(customFields["Temperature"]) ? "None" : customFields["Temperature"],
                                    Date: new Date(Date.now()),
                                    Pass: tempMandPass,
                                    QCCheckInstance: qc,
                                }
                            );
                            break;
                        case CleanAfterReverseTranscriptaseTemperatureInRangeCheck:
                        case CleanAfterBarcodingTemperatureInRangeCheck:
                        case CleanAfterEndRepairTemperatureInRangeCheck:
                            let tempRangePass = true;
                            let temp = Number.parseInt(customFields["Temperature"]);
                            if (!isNaN(temp)) {
                                let minTempParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MinTemperature")?.Value || "0")
                                let maxTempParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MaxTemperature")?.Value || "0")
                                if (temp < minTempParam || temp > maxTempParam) {
                                    if (qc.Enabled) {
                                        failedQCs.push(qc);
                                    }
                                    tempRangePass = false;
                                }
                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: qc.Enabled ? tempRangePass ? undefined : 0 : 2, //passed/executed/skipped
                                        MeasuredValue: customFields["Temperature"],
                                        Date: new Date(Date.now()),
                                        Pass: tempRangePass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            }
                            break;
                    }
                })
                if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                    this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
                }
                else {
                    this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                    this.completeMoveToNextStep();
                }
            }
            else {
                this.setState({ displayValidationWarning: true });
            }
        }
    }

    @bind
    private async completeMoveToNextStep() {
        const cleaningDataState = this.props.workflowRunService.getState().cleaningDataState;

        let {
            currentStep
        } = this.props.workflowRunService;

        if (cleaningDataState.data && currentStep) {
            let missingCleaning = missingData(cleaningDataState.data[0]);
            let currentStepData = currentStep.toJS();

            if ((GetWorkflowType() === "SarsCov2" || (currentStepData.CustomFields["Temperature"] && !isNaN(parseFloat(currentStepData.CustomFields["Temperature"])))) && !missingCleaning) {
                if (this.state.canMove) {
                    await Promise.all([
                        this.props.workflowRunService.saveCleaningData(true),
                        this.props.workflowRunService.updateCustomFields(),
                        this.props.workflowRunService.completeStep(),
                        this.props.workflowRunService.savePCRInfo(),
                    ]);
                }
            }

            else {
                this.showValidationWarning();
            }
        }
    }


    @bind
    updateTempField(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.props.workflowRunService.updateCustomField("Temperature", newValue);
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue) {
                this.props.workflowRunService.updateCustomField("Temperature", numericValue.toString());
            }
        }
    }

    @bind
    hideValidationWarning() {
        this.setState({ displayValidationWarning: false });
    }

    @bind
    showValidationWarning() {
        this.setState({ displayValidationWarning: true });
    }

    render() {
        let {
            currentStep,
            currentWorkflowRun,
        } = this.props.workflowRunService;
        let {
            cleaningDataState,
            plates,
            inputPools
        } = this.props.workflowRunService.getState();

        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;

        if (plates.data && inputPools.data && currentStep && currentWorkflowRun && lotOptions) {
            let customFields = currentStep.toJS().CustomFields;
            let tempValue = customFields["Temperature"];
            const inputName = currentStep.InputName;
            let inputPlate = _.find(plates.data, p => p.Name === inputName);

            var disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || (currentWorkflowRun && currentWorkflowRun.RunState !== "InProgress"))
            return <div>
                <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                    <div className={commonStyles.collapsibleDiv}>
                        <div>
                            <h2>Cleaning Instructions</h2>
                            {cleaningDataState.data ?
                                <InstructionSetControl
                                    componentSetData={cleaningDataState.data[0]}
                                    updateComponentSetDataSteps={this.props.workflowRunService.updateCleaningDataSteps}
                                    disabled={disabled}
                                    poolOrPlate={inputPlate ? "Plate" : "Pool"}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove: boolean) => { this.setState({ canMove: canMove }) }}
                                />
                                : <div>Cleaning Data Loading...</div>
                            }
                            {GetWorkflowType() !== "SarsCov2" &&
                                <div className={styles.tempDiv}>
                                    <div className={styles.tempLabelDiv}>{"Temperature " + customFields["degrees"] + "C"}</div>
                                    <TextField disabled={disabled} className={styles.tempField} type="number" value={tempValue || ""} onChange={this.updateTempField} inputProps={{ pattern: "[0-9.]*" }} />
                                    {this.state.tempNotFilled && <ErrorIcon className={styles.errorIcon} />}
                                </div>
                            }
                        </div>
                        <div>
                            {GetWorkflowType() === "SarsCov2" && pcrInfoState && pcrInfoState.data && instrumentOptions &&
                                <PCRControl disabled={disabled} controlInfo={pcrInfoState.data} updateInstrumentName={this.props.workflowRunService.updatePCRName} instrumentOptions={instrumentOptions} />
                            }
                        </div>
                    </div>
                </CollapsibleSection>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {currentStep.QCCheckInstances &&
                        <QCUIComponent
                            open={this.state.qcOpen}
                            failedQCs={this.state.failedQcs}
                            close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                            results={this.state.qcResults}
                            step={currentStep}
                            workflowRunId={currentWorkflowRun.Id}
                            workflowName={currentWorkflowRun.WorkflowName}
                            completeStep={this.completeMoveToNextStep}
                        />
                    }
                    <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={this.state.displayValidationWarning}
                    message={"Lot numbers and ThermoCycler name are required"}
                    autoHideDuration={5000}
                    onClose={this.hideValidationWarning}
                />
            </div >;
        }
        return <DataLoadingDisplay />;
    }
}