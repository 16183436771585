import { MinitMinderJobStatusVM } from "$Generated/api";
import { DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";
import { Button, Card } from "$Imports/MaterialUIComponents";

import { MinitMinderService, IMinitMinderServiceInjectedProps } from "$State/MinitMinderFreezerService";

interface IMinderJobStatusPageBaseProps {
}

type IMinderJobStatusPageProps = IMinderJobStatusPageBaseProps & IMinitMinderServiceInjectedProps;

const PollInterval = 30; //poll at 30s

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    continueButtonStyle: string;
    dialog: string;
} = require("./MinderJobStatusPage.scss");

export class _MinderJobStatusPage extends React.Component<IMinderJobStatusPageProps> {

    private timerID: any;  // Specifing the type causes compilation issues.  Changing to Any for now.

    private readonly columns: Array<IDataTableColumn<MinitMinderJobStatusVM>> = [
        {
            columnName: "run-id",
            columnFieldData: "RunId",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Run Id",
            sortMethod: (d) => d.RunId ?? ""
        },
        {
            columnName: "run-tag",
            columnFieldData: "Tag",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Run Tag",
        },
        {
            columnName: "job-status",
            columnFieldData: "Status",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Status",
            sortMethod: (d) => d.Status ?? ""
        },
        {
            columnName: "job-directory-path",
            columnFieldData: "DirectoryPath",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Directory Path",
            sortMethod: (d) => d.DirectoryPath ?? "",
            cellProps: {
                style: { maxWidth: "200px", wordWrap: "break-word" }
            }
        },
        {
            columnName: "job-detected-file-count",
            columnFieldData: "DetectedFileCount",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Detected File Count",
            sortMethod: (d) => d.DetectedFileCount ?? ""
        },
        {
            columnName: "job-queued-file-count",
            columnFieldData: "QueuedFileCount",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Queued File Count",
            sortMethod: (d) => d.QueuedFileCount ?? ""
        },
        {
            columnName: "job-processed-file-count",
            columnFieldData: "ProcessedFileCount",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Processed File Count",
            sortMethod: (d) => d.ProcessedFileCount ?? ""
        },
        {
            columnName: "job-success-failure",
            columnFieldData: "SuccessFailure",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Success/Failure",
        },
        {
            columnName: "job-last-updated",
            columnFieldData: (d) => {
                if (d.Status === "FINISHED") {
                    return moment(d.FinishedTime).format("MM-DD-YY, h:mm a")
                } else {
                    return moment(d.LastUpdated).format("MM-DD-YY, h:mm a")
                }
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Last Updated",
            sortMethod: (d) => {
                if (d.Status === "FINISHED") {
                    return moment().diff(moment(d.FinishedTime), 'seconds')
                } else {
                    return (d.LastUpdated ? moment().diff(moment(d.LastUpdated), 'seconds') ?? "" : "" )
                }
            }
        },
        {
            columnName: "job-action",
            columnFieldData: (d) => {
                if (d.Status === "INACTIVE") {
                    return <Button
                        className={styles.continueButtonStyle}
                        variant="contained"
                        color="primary"
                        onClick={() => { this.startRun(d.Tag) }}
                    >
                        Start
                    </Button>
                }
                else if(d.Status === "FINISHED"){
                    return <div style={{ display: "flex", flexDirection: "row" }}></div>
                }

                return <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                        className={styles.continueButtonStyle}
                        variant="contained"
                        color="primary"
                        onClick={() => { this.finishRun(d.Tag) }}
                    >
                        Finish
                    </Button>

                    <Button
                        className={styles.continueButtonStyle}
                        variant="contained"
                        color="primary"
                        onClick={() => { this.stopRun(d.Tag) }}
                    >
                        Stop
                    </Button>
                </div>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ];

    async componentDidMount() {
        await this.props.MinitMinderService.fetchJobStatuses(true);
        await this.props.MinitMinderService.fetchModules();
        this.timerID = setInterval(() => this.pollStatus(), PollInterval * 1000);
    }

    componentWillUnmount() {
        //Finish polling
        if (this.timerID) {
            clearInterval(this.timerID);
        }
    }

    render() {
        let jobStatusResults = this.props.MinitMinderService.getState().jobStatusResults;
        let modules = this.props.MinitMinderService.getState().modulesResults;
        if (jobStatusResults.data) {
            return (
                <div className={styles.mainContainer}>
                    <Card
                        className={styles.cardStyle}
                    >
                        <div>
                            <h2 style={{ paddingLeft: "10px" }}>Minit Minder Job Statuses</h2>
                            {_.map(modules.data, (m, mIdx) => (
                            <h4 style={{ paddingLeft: "10px" }}>{m.Module}: Version {m.Version}</h4>
                            ))}
                        </div>
                        <DataTable
                            data={jobStatusResults.data}
                            columns={this.columns}
                            defaultSortColumnName={"job-last-updated"}
                            defaultSortDirection="asc"
                        />
                    </Card>
                </div>
            );
        }
        else if(jobStatusResults.error)
        {
            return <div className={styles.mainContainer}>
            <Card
                className={styles.cardStyle}
            >
                <h2 style={{ paddingLeft: "10px" }}>Job data could not be loaded</h2>
            </Card>
        </div>;
        }
        return <div className={styles.mainContainer}>
            <Card
                className={styles.cardStyle}
            >
                <h2 style={{ paddingLeft: "10px" }}>Loading Jobs</h2>
            </Card>
        </div>;
    }

    @bind
    private async pollStatus() {
        await this.props.MinitMinderService.fetchJobStatuses(true);
    }

    @bind
    private async startRun(runId: string) {
        await this.props.MinitMinderService.startJob(runId);
    }

    @bind
    private async finishRun(runId: string) {
        await this.props.MinitMinderService.finishJob(runId);
    }

    @bind
    private async stopRun(runId: string) {
        await this.props.MinitMinderService.stopJob(runId);
    }

    @bind
    private async getModules() {
        await this.props.MinitMinderService.fetchModules();
    }
}

export const MinderJobStatusPage = MinitMinderService.inject(_MinderJobStatusPage);
