import {
    React,
    _,
    ReactGA
} from "$Imports/Imports";

import {
    Card,
    Button
} from "$Imports/MaterialUIComponents";

import {
    AdvanceTextField, DataLoadingDisplay
} from "$Imports/CommonComponents";

import {
    WorkflowsService,
    IWorkflowsServiceInjectedProps
} from "$State/WorkflowsFreezerService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    mainDiv: string;
    dataCell: string;
} = require("./PerformancePage.scss");

interface IPerformancePageBaseProps {

}

export type IPerformancePageProps = IPerformancePageBaseProps & IWorkflowsServiceInjectedProps

export class _PerformancePage extends React.Component<IPerformancePageProps> {

    async componentDidMount() {
        await this.props.workflowsService.fetchWorkflowMetrics(true);
    }

    render() {
        const metricData = this.props.workflowsService.freezer.get().workflowMetricsResults.data;

        if (metricData) {

            let metricDisplayElements = _.map(metricData, (metric, idx) => {
                return (<div key={idx}>
                    <h3>{metric.Name}</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td className={styles.dataCell}>
                                    Total Runs: {metric.TotalRuns}
                                </td>
                                <td className={styles.dataCell}>
                                    Samples Run: {metric.SamplesRun}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.dataCell}>
                                    Failed Runs: {metric.FailedRuns} ({(metric.FailedRuns * 100 / metric.TotalRuns).toFixed(1)}%)
                                </td>
                                <td className={styles.dataCell}>
                                    Users worked: TODO
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.dataCell}>
                                    Average run time: {metric.AverageRunTime}
                                </td>
                                <td className={styles.dataCell}>
                                    Run Time SD: {metric.RunTimeStdDev}
                                    <br />Min Run Time: {metric.MinRunTime}
                                    <br />Max Run Time: {metric.MaxRunTime}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>);
            });


            return (
                <div
                    className={styles.mainContainer}
                >
                    <Card
                        className={styles.cardStyle}
                    >
                        <div className={styles.mainDiv}>
                            <h2>Workflow Performance Metrics</h2>
                            {metricDisplayElements}
                        </div>
                    </Card>
                </div>
            );
        }
        return <DataLoadingDisplay />;
    }
}

export const PerformancePage = WorkflowsService.inject(_PerformancePage);