import {
    FreezerService,
    _,
    managedAjaxUtil,
    IAjaxState,
    bind
} from "$Imports/Imports";
import { QCApiFactory, QCCheckResultVM, QCCheckVM, WorkflowQCSummaryVM } from "../generated/api";

import { apiExceptionHandler } from "$State/ErrorFreezerService";
const InjectedPropName = "QCService";

export interface IQCViewerState {
    writeQCResults: IAjaxState<boolean>;
    getQCResults: IAjaxState<QCCheckResultVM[]>;
    fetchQCSummariesResults: IAjaxState<WorkflowQCSummaryVM[]>;
    fetchQCChecksResults: IAjaxState<QCCheckVM[]>;
    saveQCCheckResults: IAjaxState<boolean>;
}

class QCFreezerService extends FreezerService<IQCViewerState, typeof InjectedPropName> {
    constructor() {
        super({
            writeQCResults: managedAjaxUtil.createInitialState(),
            getQCResults: managedAjaxUtil.createInitialState(),
            fetchQCSummariesResults: managedAjaxUtil.createInitialState(),
            fetchQCChecksResults: managedAjaxUtil.createInitialState(),
            saveQCCheckResults: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async writeQCResults(results: QCCheckResultVM[]) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'writeQCResults',
            onExecute: async (apiOptions, param, options) => {
                let factory = QCApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1QCWriteQCCheckResultsPost({body: results})
            },
            onError: apiExceptionHandler
        });
    }

    public async getQCResults(workflowRunId: string, stepId: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'getQCResults',
            onExecute: async (apiOptions, param, options) => {
                let factory = QCApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1QCQCCheckResultsGet({workflowRunId: workflowRunId, stepId: stepId})
            },
            onError: apiExceptionHandler
        });
    }

    public async fetchQCSummaries(nameFilter: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "fetchQCSummariesResults",
            onExecute: (apiOptions, param, options) => {
                const factory = QCApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1QCGetQCSummariesGet({nameFilter: nameFilter});
            },
        });
    }

    public async fetchQCChecks(workflowId: string, stepFilter: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "fetchQCChecksResults",
            onExecute: (apiOptions, param, options) => {
                const factory = QCApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1QCGetQCChecksGet({workflowId: workflowId, stepFilter: stepFilter});
            },
        });
    }

    public async saveQCCheck(toUpdate: QCCheckVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "saveQCCheckResults",
            onExecute: (apiOptions, param, options) => {
                const factory = QCApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1QCSaveQCCheckPatch({body: toUpdate});
            },
        });
    }
    
}

export const QCService = new QCFreezerService();
export type IQCServiceInjectedProps = ReturnType<QCFreezerService["getPropsForInjection"]>;