import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";



import { Card, Button, Dialog, CircularProgress, Select, MenuItem, FormControlLabel, InputLabel, FormControl, Popover, TableCellProps, IconButton } from "$Imports/MaterialUIComponents";

import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";
import { FileService, IFileServiceInjectedProps } from "$State/FileFreezerService";

import { AjaxActionIndicator, IDataTableColumn, DataTable, COV2SampleAndWetlabTemplates, COV2SampleWetlabAndDrylabTemplates, COV2SampleRecord } from "$Imports/CommonComponents";
import { CSVService, ICSVServiceInjectedProps } from "$State/CSVFreezerService";
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import { ArrowDownward, Check, FileCopyIcon, GetAppIcon, Launch, PublishIcon, WarningIcon } from "$Imports/MaterialUIIcons";
import { SampleWithMetadataVM, MetadataTemplateVM, MetadataUploadVM } from "$Generated/api";
import { UploadMetadataControl } from "./UploadMetadataControl";
import { FileSharingAPI } from "$State/FileShareManagementService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    siteButton: string;
    cardHeader: string;
    tableHeader: string;
    selectInput: string;
    selectedRow: string;
    buttonStyle: string;
    formControl: string;
    flexRow: string;
    popOver: string;
    dataTableDiv: string;
    cancelButton: string;
    paperSize: string;
} = require("./SampleManagement.scss");

const commonStyles: {
    flexDivSpaceBetween: string;
    autoMargin: string;
} = require("../../css/common.scss");


interface ISampleManagementPageBaseProps {
}

interface ISampleManagementPageState {
    selectedTemplate?: MetadataTemplateVM;
    templates: MetadataTemplateVM[];
    sampleMetadata: SampleWithMetadataVM[];
    fileInvalid: boolean;
    resultsData: string;
    displayResults: boolean;
    originalFileName: string;
    fileUploading: boolean;
    uploadingSample: boolean;
    usingMetadata: boolean;
    usingSimpleTemplate: boolean;
    accessionDialogOpen: boolean;
    downloadPopoverOpen: boolean;
    uploadPopoverOpen: boolean;
    uploadDialogOpen: boolean;
    uploadFastaOpen: boolean;
    uploadFastaFile?: File;
    fastaSampleUploadedTo?: SampleWithMetadataVM;
    element: Element | null;
}

type ISampleManagementPageProps = ISampleManagementPageBaseProps & ISettingsServiceInjectedProps & IFileServiceInjectedProps & ICSVServiceInjectedProps;



export class _SampleManagementPage extends React.Component<ISampleManagementPageProps, ISampleManagementPageState> {

    //Columns that display in the "Sample Metadata Files" UI Element - Displays the Samples, if a FASTA File was uploaded (PCE Only), and a download option for the metadata
    private metadataColumns(pceOnly: boolean): Array<IDataTableColumn<SampleWithMetadataVM>> {
        var columns: Array<IDataTableColumn<SampleWithMetadataVM>> = [{
            columnName: "sample-id",
            columnFieldData: "SampleId",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sample Id",
        },
        {
            columnName: "sample-file-name",
            columnFieldData: (d) => d.SampleId + ".json",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "File Name",
        }];

        if (pceOnly) {
            columns.push({
                columnName: "fasta-upload",
                columnFieldData: (d) =>
                    <div className={commonStyles.autoMargin}>
                        <IconButton
                            disabled={!!JSON.parse(d.PopulatedJSON || "")["fasta_file_location"]}
                            color="primary"
                            onClick={() => {
                                //Start upload of FASTA file
                                this.setState({ uploadFastaOpen: true, fastaSampleUploadedTo: d })
                            }}>
                            {JSON.parse(d.PopulatedJSON || "")["fasta_file_location"] ? <Check /> : <PublishIcon />}
                        </IconButton>
                    </div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Upload FASTA",
            });
        }
        columns.push({
            columnName: "sample-metadata-download",
            columnFieldData: (d) =>
                <div style={{ margin: "auto" }}>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            this.downloadMetadata(d.PopulatedJSON!, d.SampleId!)
                        }}>
                        <GetAppIcon />
                    </IconButton>
                </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Download",
        });

        return columns;
    }

    private uploadTemplateColumns(): Array<IDataTableColumn<MetadataTemplateVM>> {
        return [
            {
                columnName: "name",
                columnFieldData: (d) =>
                    <div
                        onClick={() => {
                            if (d.Id === "simpleSample") {
                                this.setState({ usingSimpleTemplate: true, selectedTemplate: d, usingMetadata: true, uploadDialogOpen: true });
                            }
                            else if (d.Id === "ncbiAccession") {
                                this.setState({ accessionDialogOpen: true });
                            }
                            else {
                                this.setState({ usingSimpleTemplate: false, selectedTemplate: d, usingMetadata: true, uploadDialogOpen: true });
                            }
                        }}
                    >
                        {d.Name}
                    </div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "",
                cellProps: this.cellProps
            },

        ];
    }

    private downloadTemplateColumns(): Array<IDataTableColumn<MetadataTemplateVM>> {
        return [
            {
                columnName: "name",
                columnFieldData: (d) =>
                    <div
                        onClick={() => {
                            if (d.Id === "simpleSample") {
                                this.setState({ usingSimpleTemplate: true, selectedTemplate: d, usingMetadata: true });
                            }
                            else {
                                this.setState({ usingSimpleTemplate: false, selectedTemplate: d, usingMetadata: true });
                            }
                        }}
                    >
                        {d.Name}
                    </div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "",
                cellProps: this.cellProps
            },

        ];
    }

    //Column for use in displaying upload result text
    private readonly outputColumns: Array<IDataTableColumn<{ text: string }>> = [
        {
            columnName: "text",
            columnFieldData: "text",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ];

    @bind
    //Returns if the table row should be highlighted in CSS
    private cellProps(d: MetadataTemplateVM): TableCellProps {
        if (d.Id === this.state.selectedTemplate?.Id) {
            return {
                className: styles.selectedRow
            };
        }
        return {
        };
    }
    private initialState: ISampleManagementPageState =
        {
            templates: [],
            sampleMetadata: [],
            fileInvalid: false,
            displayResults: false,
            resultsData: "",
            originalFileName: "",
            fileUploading: false,
            uploadingSample: false,
            usingMetadata: false,
            usingSimpleTemplate: false,
            accessionDialogOpen: false,
            downloadPopoverOpen: false,
            element: null,
            uploadFastaOpen: false,
            uploadPopoverOpen: false,
            uploadDialogOpen: false
        };

    //Initial State
    state = this.initialState;

    async componentDidMount() {
        //Used to check if a LIMS exists - seems to mostly be legacy 
        this.props.settingsService.fetchLaunchpadSites(true);

        //fetch settings to check if we are in PCE Only Mode
        this.props.settingsService.getSystemSettings(true);

        //Get installed workflows to display templates
        await this.props.settingsService.getInstalledWorkflows();

        //Prep the template ZIP for downloading
        await this.props.FileService.getCSVTemplateZip("CSVTemplate");

        //Get Sample Metadata in the system, then populate the state
        await this.props.CSVService.getSampleMetadata();
        var metadata = this.props.CSVService.getState().sampleMetadataResults.data
        if (metadata) {
            this.setState({ sampleMetadata: metadata })
        }
        await this.props.CSVService.getMetdataTemplates();
        var templates = this.props.CSVService.getState().metadataResults.data;
        if (templates) {
            this.setState({ templates: templates });
        }
    }

    static contextType = ApplicationSecurityContext;

    render() {
        let systemSettings = this.props.settingsService.getState().systemSettings.data;
        let pceOnly = !!(systemSettings && (systemSettings['use_wetlab'] === 'pce_only'));

        const sites = this.props.settingsService.freezer.get().launchpadSitesResults.data;
        let noLimsAvailable = false;
        const workflows = this.props.settingsService.freezer.get().installedWorkflows.data;

        //Map available LIMS sites
        let siteRows = _.map(sites, site => {
            let link = site.Url;
            if (site.Description === "No Lims Available") {
                noLimsAvailable = true;
            }
            return (<Button
                size={"large"}
                className={styles.siteButton}
                disabled={!site.Available}
                variant="contained"
                color="primary"
                onClick={() => { window.open(link, "_self"); }}
                startIcon={<Launch />}
            >
                <div>
                    {"Navigate to " + site.Description}
                </div>
            </Button>);
        });

        //Dont display Simple Sample for Anthrax and NBS
        let templateItems = _.sortBy(this.state.templates, t => t).filter(t => t.Downloadable);
        if (workflows?.find((workflow) => (workflow.Name !== "Anthrax") && (workflow.Name !== "Newborn Screening")) != undefined) {
            templateItems.push({
                Name: "Simple Sample Record Template",
                Downloadable: true,
                Id: "simpleSample",
            });
        }

        templateItems.push({
            Name: "NCBI Accession Upload",
            Downloadable: false,
            Id: "ncbiAccession",
        })

        return (
            <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    <div className={styles.content}>
                        {!noLimsAvailable && <div>
                            <div style={{ textAlign: "center", paddingBottom: "10px", margin: "auto" }}>
                                <i><b>Integrated LIMS will populate here.</b></i>
                            </div>
                            <div style={{ margin: "auto", textAlign: "center" }}>
                                {siteRows}
                            </div>
                        </div>
                        }
                        {noLimsAvailable &&
                            <div>
                                <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                                    <i><b>Accession samples at the run-level by uploading a .csv file below. Users can accession simple samples or metadata depending on the user's needs. Refer to the downloadable templates for guidance on how to order specific agents or upload a run's wet lab or/and dry lab metadata.</b></i>
                                </div>

                                <div className={styles.flexRow} style={{ justifyContent: "space-evenly" }}>
                                    <div className={styles.flexRow}>
                                        <h3>Upload File</h3>
                                        <FormControl className={styles.formControl}>
                                            <InputLabel id="upload-label">Choose a file to upload</InputLabel>
                                            <Select
                                                id="upload"
                                                labelId="upload-label"
                                                label="Choose a file to upload"
                                                value={""}
                                                disableUnderline
                                                disabled
                                                onClick={this.onUploadClick}
                                                IconComponent={ArrowDownward}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className={styles.flexRow}>
                                        <h3>Download Template</h3>
                                        <FormControl className={styles.formControl}>
                                            <InputLabel id="download-label">Choose a sample upload template to download</InputLabel>
                                            <Select
                                                id="download"
                                                labelId="download-label"
                                                label="Choose a sample upload template to download"
                                                value={""}
                                                disableUnderline
                                                disabled
                                                onClick={this.onDownloadClick}
                                                IconComponent={ArrowDownward}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                {/*Download File Template Popup*/}
                                <Popover
                                    open={this.state.downloadPopoverOpen}
                                    onClose={this.handleClose}
                                    anchorEl={this.state.element}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    style={{ marginTop: "1px" }}
                                    PaperProps={{ square: true, variant: "outlined" }}
                                >
                                    <div className={styles.popOver}>
                                        <h2>Sample File Types</h2>
                                        <h3><i>Choose .csv templates and guidance ReadMe files to download.</i></h3>
                                        <div className={styles.dataTableDiv}>
                                            <DataTable
                                                data={templateItems}
                                                columns={this.downloadTemplateColumns()}
                                            />
                                        </div>
                                        <Button
                                            variant="contained"
                                            startIcon={<GetAppIcon />}
                                            disabled={(!this.state.usingSimpleTemplate && this.state.selectedTemplate == undefined)}
                                            className={styles.buttonStyle}
                                            onClick={() => {
                                                if (this.state.usingSimpleTemplate) {
                                                    this.downloadSimpleTemplate();
                                                }
                                                else {
                                                    this.downloadMetadataTemplate();
                                                }
                                            }}>Download</Button>
                                    </div>
                                </Popover>
                                {/*Upload Sample File Popup*/}
                                <Popover
                                    open={this.state.uploadPopoverOpen}
                                    onClose={this.handleClose}
                                    anchorEl={this.state.element}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    style={{ marginTop: "1px" }}
                                    PaperProps={{ square: true, variant: "outlined" }}
                                >
                                    {
                                        this.state.fileUploading ?
                                            //Show Spinning circle
                                            <CircularProgress />
                                            :
                                            <div className={styles.popOver}>
                                                <h2>Sample File Types</h2>
                                                <h3><i>Choose the file type to upload.</i></h3>
                                                <div className={styles.dataTableDiv}>
                                                    <DataTable
                                                        data={templateItems}
                                                        columns={this.uploadTemplateColumns()}
                                                    />
                                                </div>
                                            </div>
                                    }
                                </Popover>
                            </div>
                        }

                    </div>
                    <div className={styles.content}>
                        <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                            <h3>Sample Metadata Files</h3>
                            <i><b>For sample metadata, download JSON files here</b></i>
                        </div>
                        <div style={{ margin: "auto", width: "70%" }}>
                            <DataTable
                                data={this.state.sampleMetadata}
                                columns={this.metadataColumns(pceOnly)}
                            />
                        </div>
                    </div>
                    {/*Result Display popup*/}
                    <Dialog
                        open={this.state.displayResults}
                        onClose={this.handleClose}
                        PaperProps={{ style: { width: "1000px", maxWidth: "1000px" } }}
                    >
                        <div style={{ padding: "20px", textAlign: "center" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                {this.state.fileInvalid && <WarningIcon style={{ marginTop: "auto", marginBottom: "auto", color: "red" }} />}
                                <h2>{this.state.fileInvalid ? "Resolve sample upload errors below and try again." : "Sample Upload Receipt"}</h2>
                            </div>
                            <DataTable
                                data={_.map(this.state.resultsData.split("\n"), line => {
                                    return { text: line }
                                })}
                                columns={this.outputColumns}
                                isError={this.state.fileInvalid}
                            />
                        </div>
                        <Button
                            variant="contained"
                            startIcon={<GetAppIcon />}
                            style={{ margin: "auto", borderRadius: "10px", marginBottom: "10px" }}
                            onClick={() => {
                                var saveDocument = document.createElement('a');
                                saveDocument.href = 'data:text/plain;charset=utf-8,' + encodeURI(this.state.resultsData);
                                saveDocument.target = '_blank';
                                saveDocument.download = this.state.originalFileName.slice(0, -4) + "_ImportSummary_" + moment(Date.now()).format("MM-DD-YYYY-HH-mm") + ".txt";
                                saveDocument.click();
                            }}>Download Receipt</Button>
                    </Dialog>
                    <Dialog
                        open={this.state.accessionDialogOpen}
                        onClose={this.handleClose}
                        PaperProps={{ style: { width: "1000px", maxWidth: "1000px" } }}
                    >
                        {
                            this.state.fileUploading ?
                                //Show Spinning circle
                                <CircularProgress />
                                :
                                <div style={{ padding: "20px" }}>
                                    <h2>
                                        Choose a .tsv file
                                    </h2>
                                    {this.state.fileInvalid && <h3 style={{ color: "red" }}>{this.state.resultsData}</h3>}
                                    <input type="file" disabled={this.state.usingMetadata} id="input"></input>
                                    <Button variant="contained"
                                        style={{ justifyContent: "left" }} disabled={this.state.fileUploading} onClick={() => {
                                            const input = document.getElementById('input') as HTMLInputElement;
                                            if (input && input.files) {
                                                const file = input.files[0];
                                                this.setState({ originalFileName: file.name })
                                                const reader = new FileReader();
                                                reader.onload = () => { this.uploadAccession(reader); };
                                                reader.readAsText(file);
                                            }
                                        }}>
                                        Upload File
                                    </Button>
                                </div>
                        }
                    </Dialog>
                    <Dialog
                        open={this.state.uploadFastaOpen}
                        onClose={this.handleClose}
                        PaperProps={{ className: styles.paperSize }}
                    >
                        {
                            this.state.fileUploading ?
                                //Show Spinning circle
                                <CircularProgress />
                                :
                                <div>
                                    <div style={{ padding: "20px" }}>
                                        <h2>
                                            Upload File
                                        </h2>
                                        <input type="file" id="input"></input>
                                        <h4>Note: Once a .fasta file is uploaded for a sample, no other .fasta files may be uploaded for that sample</h4>
                                        <div className={commonStyles.flexDivSpaceBetween}>
                                            <Button variant="contained"
                                                className={styles.cancelButton} disabled={this.state.fileUploading} onClick={() => {
                                                    this.handleClose();
                                                }}>
                                                Cancel
                                            </Button>
                                            <Button variant="contained"
                                                disabled={this.state.fileUploading} onClick={() => {
                                                    const input = document.getElementById('input') as HTMLInputElement;
                                                    if (input && input.files) {
                                                        const file = input.files[0];
                                                        this.setState({ uploadFastaFile: file })
                                                        const reader = new FileReader();
                                                        reader.onload = () => { this.uploadFasta(reader); };
                                                        reader.readAsText(file);
                                                    }
                                                }}>
                                                Upload File
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                        }
                    </Dialog>
                </Card>
                <UploadMetadataControl
                    template={this.state.selectedTemplate}
                    close={this.handleClose}
                    open={this.state.uploadDialogOpen}
                    upload={this.uploadFullMetadata}
                />
            </div>
        );
    }

    //Upload the entire set of metadata based on what template was selected. 
    @bind
    private async uploadFullMetadata(sampleMetadata: string, wetlabMetadata: string, drylabMetadata: string) {
        if (this.state.selectedTemplate?.Id === "simpleSample") {
            await this.props.CSVService.uploadResults(sampleMetadata);
            const uploadResults = this.props.CSVService.getState().uploadResults.data;
            if (uploadResults && !uploadResults.Success) {
                this.setState({ displayResults: true, fileInvalid: true, fileUploading: false, resultsData: uploadResults.ResultText, uploadDialogOpen: false });
            }
            else if (uploadResults) {
                this.setState({ displayResults: true, resultsData: uploadResults.ResultText, fileUploading: false, uploadDialogOpen: false });
            }
        }
        //Check Type of selected template - only do this upload for COV2
        else if (this.state.selectedTemplate && (this.state.selectedTemplate.Id === COV2SampleAndWetlabTemplates || this.state.selectedTemplate.Id === COV2SampleWetlabAndDrylabTemplates || this.state.selectedTemplate.Id === COV2SampleRecord)) {
            const metadataFiles: MetadataUploadVM = {
                SampleMetadataCSV: sampleMetadata,
                WetlabMetadataCSV: wetlabMetadata,
                DrylabMetadataCSV: drylabMetadata
            }
            await this.props.CSVService.uploadFullMetadata(metadataFiles);
            const uploadResults = this.props.CSVService.getState().uploadFullMetadata.data;
            if (uploadResults && !uploadResults.Success) {
                this.setState({ displayResults: true, fileInvalid: true, fileUploading: false, resultsData: uploadResults.ResultText, uploadDialogOpen: false });
            }
            else if (uploadResults) {
                await this.props.CSVService.getSampleMetadata();
                var metadata = this.props.CSVService.getState().sampleMetadataResults.data
                if (metadata) {
                    this.setState({ displayResults: true, resultsData: uploadResults.ResultText, fileUploading: false, uploadDialogOpen: false, sampleMetadata: metadata });
                }
                else {
                    this.setState({ displayResults: true, resultsData: uploadResults.ResultText, fileUploading: false, uploadDialogOpen: false });
                }
            }
        }
        else {
            //Upload per existing functionality

            await this.props.CSVService.uploadMetadataResults(this.state.selectedTemplate?.Id!, sampleMetadata);
            const uploadResults = this.props.CSVService.getState().uploadMetadataResults.data;
            if (uploadResults && !uploadResults.Success) {
                this.setState({ displayResults: true, fileInvalid: true, fileUploading: false, resultsData: uploadResults.ResultText });
            }
            else if (uploadResults) {
                this.setState({ displayResults: true, resultsData: uploadResults.ResultText, fileUploading: false });
                await this.props.CSVService.getSampleMetadata();
                var metadata = this.props.CSVService.getState().sampleMetadataResults.data
                if (metadata) {
                    this.setState({ sampleMetadata: metadata })
                }
            }
        }
    }

    @bind
    //Upload Fasta File to Simpleshare and add that location to the sample's metadata
    private async uploadFasta(reader: FileReader) {
        if (this.state.uploadFastaFile) {
            this.setState({ fileUploading: true });

            //create containing directory if doesn't exist
            const directoryData = new FormData();
            directoryData.append('Type', "Directory");
            await fetch(FileSharingAPI + 'CreateFileOrDirectory/fastaFiles', { method: "PUT", body: directoryData });


            //upload fasta to simpleshare
            const data = new FormData();
            data.append('Upload', this.state.uploadFastaFile);
            data.append('Type', "FILE");

            await fetch(FileSharingAPI + 'CreateFileOrDirectory/fastaFiles/' + this.state.fastaSampleUploadedTo?.SampleId + "-" + this.state.uploadFastaFile.name, { method: "PUT", body: data });

            //Set file location
            await this.props.FileService.AddFastaFileLocationToSampleMetadata(this.state.fastaSampleUploadedTo?.SampleId || "", 'fastaFiles/' + this.state.fastaSampleUploadedTo?.SampleId + "-" + this.state.uploadFastaFile.name)

            //Reload Metadata
            await this.props.CSVService.getSampleMetadata();
            var metadata = this.props.CSVService.getState().sampleMetadataResults.data
            if (metadata) {
                this.setState({ sampleMetadata: metadata })
            }

            this.setState({ fileUploading: false });
            this.handleClose();
        }
    }

    @bind
    private async downloadMetadataTemplate() {
        await this.props.FileService.getCSVMetadataTemplateZip(this.state.selectedTemplate?.MetadataTemplateFiles!)
        var saveDocument = document.createElement('a');
        saveDocument.href = this.props.FileService.getState().csvMetadataState.data || "";
        saveDocument.target = '_blank';
        saveDocument.download = 'templates.zip';
        saveDocument.click();
        this.handleClose();
    }

    private async downloadSimpleTemplate() {
        var saveDocument = document.createElement('a');
        saveDocument.href = this.props.FileService.getState().csvZipState.data || "";
        saveDocument.target = '_blank';
        saveDocument.download = 'template.zip';
        saveDocument.click();
        this.setState({ usingSimpleTemplate: false })
        this.handleClose();
    }

    @bind
    private async downloadMetadata(json: string, sampleId: string) {
        await this.props.FileService.getJSONSampleMetadata(json)
        var saveDocument = document.createElement('a');
        saveDocument.href = this.props.FileService.getState().jsonSampleMetadataState.data || "";
        saveDocument.target = '_blank';
        saveDocument.download = sampleId + ".json";
        saveDocument.click();
        this.handleClose();
    }

    @bind
    private handleClose() {
        if (!this.state.fileUploading) {
            this.setState({
                fileInvalid: false,
                displayResults: false,
                resultsData: "",
                uploadingSample: false,
                usingMetadata: false,
                selectedTemplate: undefined,
                accessionDialogOpen: false,
                element: null,
                downloadPopoverOpen: false,
                uploadPopoverOpen: false,
                uploadDialogOpen: false,
                fileUploading: false,
                uploadFastaOpen: false
            });
        }
    }

    @bind
    private onDownloadClick(event: React.MouseEvent) {
        this.setState({ downloadPopoverOpen: true, element: event.currentTarget });
    }

    @bind
    private onUploadClick(event: React.MouseEvent) {
        this.setState({ uploadPopoverOpen: true, element: event.currentTarget });
    }

    @bind
    private async uploadAccession(reader: FileReader) {
        this.setState({ fileUploading: true });
        await this.props.CSVService.updateResultsMetadata(reader.result as string);
        const uploadResults = this.props.CSVService.getState().updateResultsMetadata.data;
        if (uploadResults && !uploadResults.Success) {
            this.setState({ displayResults: true, fileInvalid: true, fileUploading: false, resultsData: uploadResults.ResultText });
        }
        else if (uploadResults) {
            this.setState({ displayResults: true, resultsData: uploadResults.ResultText, fileUploading: false, uploadDialogOpen: false });
        }
    }
}

export const SampleManagementPage = CSVService.inject(FileService.inject(SettingsService.inject(_SampleManagementPage)));