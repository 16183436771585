import { BasespaceAnalysisVM } from "$Generated/api";
import {
    React,
    _,
} from "$Imports/Imports";
import { Button, Card, MenuItem, TextField } from "$Imports/MaterialUIComponents";

import { IPipelineServiceInjectedProps, PipelineService } from "$State/PipelineFreezerService";

import { Send } from "$Imports/MaterialUIIcons";
import { NavigationService } from "$State/NavigationFreezerService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    dropdownStyle: string;
    cardHeader: string;
    flexRow: string;
    dropdownLabel: string;
} = require("./PipelineSampleSelection.scss");


interface IPipelineSampleSelectionBaseProps {
}

interface IPipelineSampleSelectionState {
    wetlabRunId: string;
    selectedBasespaceAnalysisId: string;
    selectableAnalyses?: BasespaceAnalysisVM[]
}

type IPipelineSampleSelectionProps = IPipelineSampleSelectionBaseProps & IPipelineServiceInjectedProps;

export class _PipelineSampleSelectionPage extends React.Component<IPipelineSampleSelectionProps, IPipelineSampleSelectionState> {


    state: IPipelineSampleSelectionState = {
        wetlabRunId: "",
        selectedBasespaceAnalysisId: ""
    }


    async componentDidMount() {
        await this.props.PipelineService.getPipelineRunWetlabRunBasespaceAnalyses();
    }

    render() {
        let data = this.props.PipelineService.getState().pipelineRunWetlabRunBasespaceAnalyses.data;
        return <div className={styles.mainContainer}>
            <Card
                className={styles.cardStyle}
            >
                <div className={styles.cardHeader}>
                    <h2>Sample Selection</h2>
                    <div><i>Choose which samples to run.</i></div>
                </div>
                <div className={styles.flexRow}>
                    <div className={styles.dropdownLabel}>
                        <b>
                            Wet Lab Workflow Run:
                        </b>
                    </div>
                    <TextField
                        className={styles.dropdownStyle}
                        select
                        value={this.state.wetlabRunId}
                        id="wet-lab-workflow-run"
                        onChange={(event) => {
                            let id = event.target.value;
                            let entries = _.find(data || [], d => d.WetlabRunId === id);
                            if (entries !== undefined) {
                                this.setState({ wetlabRunId: event.target.value, selectableAnalyses: entries.BasespaceAnalyses });
                            }
                        }}
                    >
                        {_.map(data, wetLabRun => {
                            return (<MenuItem key={wetLabRun.WetlabRunId} value={wetLabRun.WetlabRunId}>{wetLabRun.Name}</MenuItem>)
                        })}
                    </TextField>
                </div>
                <div className={styles.flexRow}>
                    <div className={styles.dropdownLabel}>
                        <b>
                            BaseSpace Analysis to Select:
                        </b>
                    </div>
                    <TextField
                        disabled={this.state.wetlabRunId === ""}
                        value={this.state.selectedBasespaceAnalysisId}
                        className={styles.dropdownStyle}
                        select
                        id="basespace-analysis"
                        onChange={(event) => {
                            let id = event.target.value;
                            this.setState({ selectedBasespaceAnalysisId: id });
                        }}
                    >
                        {_.map(this.state.selectableAnalyses, analysis => {
                            return (<MenuItem key={analysis.Id} value={analysis.Id}>{analysis.Name}</MenuItem>)
                        })}
                    </TextField>
                </div>
                <Button
                    style={{ float: "right" }}
                    onClick={async () => {
                        //Set status to "Ready for Processing"
                        await this.props.PipelineService.initiateBasespacePipelineRun(this.state.selectedBasespaceAnalysisId);

                        //Redirect to Pipeline Run Page
                        NavigationService.navigateTo("/existingPipelineRun");
                    }}
                    color="primary"
                    variant="contained"
                    endIcon={<Send />}
                >
                    Launch Pipeline
                </Button>
            </Card>
        </div>
    }
}

export const PipelineSampleSelectionPage = PipelineService.inject(_PipelineSampleSelectionPage);