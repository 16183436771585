import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    DataLoadingDisplay,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import {
    WorkflowConfigService,
    IWorkflowConfigServiceInjectedProps
} from "$State/WorkflowConfigFreezerService";
import { ComponentSetVM } from "$Generated/api";

import { EditComponentSet } from "./EditComponentSet";
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import { WorkflowConfigHelper } from "./WorkflowConfigHelper";
import { IWorkflowsServiceInjectedProps, WorkflowsService } from "$State/WorkflowsFreezerService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
} = require("./WorkflowComponentManagement.scss");

interface IReagentListPageBaseProps {
}

interface IReagentListPageState {
    editDialogOpen: boolean,
    newComponentSet: boolean,
    ReagentListToEdit?: ComponentSetVM,
    isCopy: boolean,
    filteredWorkflows: string;
}

type IReagentListPageProps = IReagentListPageBaseProps & IWorkflowConfigServiceInjectedProps & IWorkflowsServiceInjectedProps;

export class _ReagentListPage extends React.Component<IReagentListPageProps, IReagentListPageState> {

    private readonly columns: Array<IDataTableColumn<ComponentSetVM>> = [
        {
            columnName: "name",
            columnFieldData: "DisplayName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.setState({ ReagentListToEdit: d, editDialogOpen: true });
                                }}
                            >
                                {(d.AssociatedRunNumber !== 0 || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1) ? "View" : "Edit"}
                            </Button>
                            <Button
                                style={{ marginLeft: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({ ReagentListToEdit: d, editDialogOpen: true, newComponentSet: true, isCopy: true });
                                }}
                            >
                                Copy
                            </Button>
                        </div>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    state: IReagentListPageState = {
        editDialogOpen: false,
        newComponentSet: false,
        isCopy: false,
        filteredWorkflows: '',
    }

    async componentDidMount() {
        await this.props.workflowConfigService.fetchReagentLists(true);
        await this.props.workflowConfigService.fetchSimplifiedReagentLists(true);
    }

    render() {

        let {
            reagentListFetchResults,
            simplifiedReagentListFetchResults
        } = this.props.workflowConfigService.getState();
        if (reagentListFetchResults.data && simplifiedReagentListFetchResults.data) {

            let reagentListData : ComponentSetVM[] = [...reagentListFetchResults.data];
            let simplifiedReagentListData : ComponentSetVM[] = [...simplifiedReagentListFetchResults.data];

            //Filter according to the selected workflow.
            if (this.state.filteredWorkflows !=='')
            {
                reagentListData = [];
                _.forEach(reagentListFetchResults.data, x => {
                    if (x.AssociatedWorkflows && x.AssociatedWorkflows.some( y => y === this.state.filteredWorkflows)){
                        reagentListData.push(x);
                    }
                })

                simplifiedReagentListData = [];
                _.forEach(simplifiedReagentListFetchResults.data, x => {
                    if (x.AssociatedWorkflows && x.AssociatedWorkflows.some( y => y === this.state.filteredWorkflows)){
                        simplifiedReagentListData.push(x);
                    }
                })
            }


            var data = _.concat(reagentListData, simplifiedReagentListData);
            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div
                        className={styles.mainContainer}
                    >
                        <Card
                            className={styles.cardStyle}
                        >
                            <h2>Reagent Lists</h2>
                            <Button
                                style={{ float: "right", marginRight: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({
                                        editDialogOpen: true,
                                        newComponentSet: true,
                                        ReagentListToEdit: {
                                            AssociatedRunNumber: 0,
                                            AssociatedWorkflows: [],
                                            ComponentSetItems: [],
                                            ComponentSetFooters: [],
                                            ComponentSetType: "ReagentList",
                                            AdditionalAmountFactorType: "NumberOfSamples",
                                            DisplayHeader: "",
                                            DisplayName: "NAME",
                                            Id: "",
                                            ReagentUsedByType: "PerWellOfSamplePlate",
                                            AdditionalAmountFactor: 0,
                                            CreatedOn: new Date(Date.now()),
                                            IsActive: true,
                                            CreatedBy: "",
                                            DeactivatedOn: new Date(Date.now()),
                                        }
                                    })
                                }}
                            >
                                Add
                            </Button>
                            <WorkflowConfigHelper
                                onChange={e => this.setState({filteredWorkflows: e.target.value})}
                                filteredWorkflows={this.state.filteredWorkflows}>
                            </WorkflowConfigHelper>
                            
                            <div style={{fontStyle: 'italic'}}>Note: Edit button will disappear once an instruction set/component has been used in a workflow run.
                             Users will only be able to view Reagent Lists after being used in a run.
                            </div>

                            <DataTable
                                data={data}
                                columns={this.columns}
                            />
                            <div>
                                <EditComponentSet
                                    handleClose={this.handleClose}
                                    handleSave={this.handleReagentListUpdate}
                                    open={this.state.editDialogOpen}
                                    newComponentSet={this.state.newComponentSet}
                                    initialComponentSet={this.state.ReagentListToEdit}
                                    user={value.applicationContext.userContext}
                                    existingNames={_.map(data, r => r.DisplayName || "")}
                                    isCopy={this.state.isCopy}
                                />
                            </div>
                        </Card>
                    </div >
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private handleClose() {
        this.setState({ editDialogOpen: false, newComponentSet: false, ReagentListToEdit: undefined, isCopy: false });
    }

    @bind
    private async handleReagentListUpdate(ReagentList: ComponentSetVM) {
        if (this.state.newComponentSet) {
            //add
            await this.props.workflowConfigService.addComponentSet(ReagentList);
        }
        else {
            //update
            await this.props.workflowConfigService.updateComponentSet(ReagentList);
        }

        await this.props.workflowConfigService.fetchReagentLists(true);
        this.handleClose();
    }
}

export const ReagentListPage = WorkflowsService.inject(WorkflowConfigService.inject(_ReagentListPage));