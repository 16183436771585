import {
    React,
    bind,
    moment,
    _,
    ReactGA
} from "$Imports/Imports";

import {
    Card,
    TableRow,
    Dialog,
    Link,
    IconButton,
    PlayCircleFilledWhiteIcon,
    SendIcon,
    Button
} from "$Imports/MaterialUIComponents";

import {
    AjaxActionIndicator,
    DataTable,
    DataTablePager,
    IDataTableColumn,
    SampleInfoTable,
    AdvanceTextField
} from "$Imports/CommonComponents";
import { ArchivedAssetContentVM, WellContentVM, ArchivedContainerVM, WellMaterial, WorkflowStepWithInitiationPointVM, WorkflowVM, ReagentGroupLotVM, ReagentLotVM, ReagentGroupVM, ReagentVM } from "$Generated/api";

import {
    ISampleServiceInjectedProps,
    SampleService
} from "$State/SampleFreezerService";

import {
    WorkflowsService,
    IWorkflowsServiceInjectedProps
} from "$State/WorkflowsFreezerService";
import { NavigationService } from "$State/NavigationFreezerService";
import { Chip, DialogActions } from "@material-ui/core";

var urljoin = require('url-join');

interface ISamplesPageBaseProp {

}

type ISamplesPageProp = ISampleServiceInjectedProps & ISamplesPageBaseProp & IWorkflowsServiceInjectedProps;

interface ISamplesPageState {
    detailsOpen: boolean;
    data?: WellContentVM[];
    containerNameSearch: string;
    containerNameBox: string;
    sampleNameSearch: string;
    sampleNameBox: string;
    viewBySample: boolean;
    workflowSelectionOpen: boolean;
    workflowStepViewOpen: boolean;
    initiationPoints?: WorkflowStepWithInitiationPointVM[];
    workflows?: WorkflowVM[];
    currentAsset?: ArchivedAssetContentVM;
    workflow?: WorkflowVM;
    currentContainer?: ArchivedContainerVM;
    isStarting: boolean;
    associationFailedOpen: boolean;
    deactivatedReagentsOpen: boolean;
    selectLotsOpen: boolean;
    selectedReagentLotsDictionary: { [index: string]: { lot: (ReagentLotVM | ReagentGroupLotVM), kitId: string } };
    stepViewStructure: { step: string, reagents: (ReagentVM | ReagentGroupVM)[] }[];
    viewByStep: boolean;
    notAllAssociatedOpen: boolean;
    selectedStepId: string;
    shipConfirmOpen: boolean;
    plateId: string;
}

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    dialog: string;
    searchButton: string;
    containerName: string;
    flexRow: string;
    shipCard: string;
    shipButtons: string;
} = require("./SamplesPage.scss");

export class _SamplesPage extends React.Component<ISamplesPageProp, ISamplesPageState> {

    private readonly archivedAssetColumns: Array<IDataTableColumn<ArchivedAssetContentVM>> = [
        {
            columnName: "sample-id",
            columnFieldData: "SampleId",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sample ID",
            sortMethod: (d) => d.SampleId ?? ""
        },
        {
            columnName: "date-harvested",
            columnFieldData: (d) => moment(d.HarvestedDate).format("L LT"),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Date Harvested",
            sortMethod: (d) => d.HarvestedDate ?? ""
        },
        {
            columnName: "archive-container",
            columnFieldData: (d) => {
                if(d.Shipped) {
                    return <div>
                        {d.Container.Name}
                        <Button variant="contained" color="primary" style={{ marginLeft: "20px" }} size={"small"} disabled={true} endIcon={<SendIcon fontSize={"small"} />}>
                            Shipped
                        </Button>
                    </div>
                }
                return (<div className={styles.flexRow} >
                    <Link className={styles.containerName} onClick={async () => {
                        if (d.Container.AssetType === "Plate") {
                            await this.props.samplesService.getPlate(d.Container.PlateSummary ? d.Container.PlateSummary.Id : "");
                            let data = this.props.samplesService.freezer.get().plateDataResults.data;
                            if (data) {
                                this.setState({ detailsOpen: true, data: data.WellContents.toJS() })
                            }
                        }
                        else if (d.Container.AssetType === "Pool") {
                            await this.props.samplesService.getPoolContents(d.Container.PoolSummary ? d.Container.PoolSummary.Id : "");
                            let data = this.props.samplesService.freezer.get().poolDataResults.data;
                            if (data) {
                                this.setState({ detailsOpen: true, data: data.toJS() })
                            }
                        }
                    }}>
                        {d.Container.Name}
                    </Link>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button variant="contained" color="primary" size={"small"} onClick={async () => {
                            await this.props.workflowsService.fetchWorkflows();
                            let workflowData = this.props.workflowsService.freezer.get().workflowFetchResults.data;
                            if (workflowData) {
                                this.setState({ currentAsset: d, workflowSelectionOpen: true, workflows: workflowData.toJS() });
                            }
                        }} endIcon={<PlayCircleFilledWhiteIcon fontSize={"small"} />}>
                            Start Run
                        </Button>
                        <Button variant="contained" color="primary" style={{ marginTop: "10px" }} size={"small"} onClick={() => { this.setState({plateId: d.Container.Id, shipConfirmOpen: true}); }} endIcon={<SendIcon fontSize={"small"} />}>
                            Ship
                        </Button>
                    </div>
                </div>);
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Archive Container",
            sortMethod: (d) => d.Container.Name ?? ""
        },
        {
            columnName: "date-archived",
            columnFieldData: (d) => moment(d.ArchiveDate).format("L LT"),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Archive Date",
            sortMethod: (d) => d.ArchiveDate ?? ""
        },
        {
            columnName: "run-number",
            columnFieldData: "RunNumber",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Run Number",
            sortMethod: (d) => d.RunNumber ?? ""
        },
        {
            columnName: "workflow-name",
            columnFieldData: "Workflow",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Workflow",
            sortMethod: (d) => d.Workflow ?? ""
        },


    ];

    private readonly archivedContainerColumns: Array<IDataTableColumn<ArchivedContainerVM>> = [
        {
            columnName: "archive-container",
            columnFieldData: (d) => {
                if(d.Shipped) {
                    return <div>
                        {d.Name}
                        <Button variant="contained" color="primary" style={{ marginLeft: "20px" }} size={"small"} disabled={true} endIcon={<SendIcon fontSize={"small"} />}>
                            Shipped
                        </Button>
                    </div>
                }
                return (<div className={styles.flexRow} >
                    <div className={styles.containerName}>
                        {d.Name}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button variant="contained" color="primary" size={"small"} onClick={async () => {
                            await this.props.workflowsService.fetchWorkflows();
                            let workflowData = this.props.workflowsService.freezer.get().workflowFetchResults.data;
                            if (workflowData) {
                                this.setState({ currentContainer: d, workflowSelectionOpen: true, workflows: workflowData.toJS() });
                            }
                        }} endIcon={<PlayCircleFilledWhiteIcon fontSize={"small"} />}>
                            Start Run
                        </Button>
                        <Button variant="contained" color="primary" style={{ marginTop: "10px" }} size={"small"} onClick={() => { this.setState({plateId: d.Id, shipConfirmOpen: true}); }} endIcon={<SendIcon fontSize={"small"} />}>
                            Ship
                        </Button>
                    </div>
                </div >);
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Archive Container",
            sortMethod: (d) => d.Name ?? ""
        },
        {
            columnName: "archived-samples",
            columnFieldData: (d) => <Link onClick={async () => {
                if (d.ContainerType === "Plate") {
                    await this.props.samplesService.getPlate(d.Id);
                    let data = this.props.samplesService.freezer.get().plateDataResults.data;
                    if (data) {
                        this.setState({ detailsOpen: true, data: data.WellContents.toJS() })
                    }
                }
                else if (d.ContainerType === "Pool") {
                    await this.props.samplesService.getPoolContents(d.Id);
                    let data = this.props.samplesService.freezer.get().poolDataResults.data;
                    if (data) {
                        this.setState({ detailsOpen: true, data: data.toJS() })
                    }
                }
            }}>
                {d.SampleCount}
            </Link>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Archived Samples",
            sortMethod: (d) => d.SampleCount ?? ""
        },
        {
            columnName: "sample-type",
            columnFieldData: "SampleType",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sample Type",
            sortMethod: (d) => d.SampleType ?? ""
        },
        {
            columnName: "date-archived",
            columnFieldData: (d) => moment(d.ArchiveDate).format("L LT"),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Archive Date",
            sortMethod: (d) => d.ArchiveDate ?? ""
        },
        {
            columnName: "run-number",
            columnFieldData: "RunNumber",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Run Number",
            sortMethod: (d) => d.RunNumber ?? ""
        },
        {
            columnName: "workflow-name",
            columnFieldData: "Workflow",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Workflow",
            sortMethod: (d) => d.Workflow ?? ""
        },


    ];

    private readonly sampleColumns: Array<IDataTableColumn<WellContentVM>> = [
        {
            columnName: "sample-id",
            columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sample ID",
            sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
        },
        {
            columnName: "sample-date-harvested",
            columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Date Harvested",
            sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
        },
        {
            columnName: "sample-specimen-source",
            columnFieldData: (d) => (d.Sample ? d.Sample.SpecimenSource : ""),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Specimen Source",
            sortMethod: (d) => (d.Sample ? d.Sample.SpecimenSource : "") ?? ""
        },
    ];

    private reagentColumns(): Array<IDataTableColumn<ReagentVM | ReagentGroupVM>> {
        let columns: Array<IDataTableColumn<ReagentVM | ReagentGroupVM>> = [
            {
                columnName: "kit-name",
                columnFieldData: (d) => <div>{this.isReagent(d) ? d.KitName : ""}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Kit Name",
            },
            {
                columnName: "reagent-name",
                columnFieldData: (d) => <div>{d.Name}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Name",
            },
            {
                columnName: "step-names",
                columnFieldData: (d) => <div>{_.map(d.UsedInSteps, step => <div>{step.Item1}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Steps",
            },
            {
                columnName: "lot-number",
                columnFieldData: (d) => {
                    let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                    data = _.union(data, d.Lots);
                    let isGroup = !this.isReagent(d);
                    let mapId = this.isReagent(d) ? (d.Id || "") + (d.KitName || "") : d.Id || "";
                    let isKit = this.isReagent(d) ? d.KitId !== undefined : false;
                    return <div>{_.map(_.sortBy(data.filter((lot, i, arr) => (arr.findIndex(t => t.LotNumber === lot.LotNumber) === i) && lot.IsActive && new Date(lot.ExpirationDate) > new Date(Date.now())), d => d.CreatedOn).reverse(), (l) => {
                        let temp = l as ReagentLotVM | ReagentGroupLotVM;
                        if (temp.LotNumber !== undefined) {
                            let selected = _.find(this.state.selectedReagentLotsDictionary, r => r.lot.Id === l.Id) !== undefined;
                            return <Chip
                                label={temp.LotNumber}
                                key={temp.Id}
                                style={{ minWidth: "50px", margin: "5px", backgroundColor: selected ? "green" : "" }}
                                onClick={() => { selected ? this.removeWorkflowReagent(mapId, this.isReagent(d) ? d : undefined) : this.addWorkflowReagent(mapId, temp, this.isReagent(d) ? d : undefined); }}
                            />;
                        }
                        return <></>;
                    })}
                        <AdvanceTextField
                            disabled={this.state.selectedReagentLotsDictionary[mapId] !== undefined && !this.state.selectedReagentLotsDictionary[mapId].lot.IsNew}
                            value={this.state.selectedReagentLotsDictionary[mapId] !== undefined && this.state.selectedReagentLotsDictionary[mapId].lot.IsNew ? this.state.selectedReagentLotsDictionary[mapId].lot.LotNumber : ""}
                            onDebouncedChange={(value) => {
                                isGroup ?
                                    this.addWorkflowReagent(mapId, {
                                        LotNumber: value,
                                        Id: "",
                                        IsActive: true,
                                        CreatedOn: new Date(Date.now()),
                                        CreatedBy: "",
                                        UsedInRun: "NotUsed",
                                        IsNew: true,
                                        ReagentLots: [],
                                        ReagentGroupId: d.Id,
                                        ExpirationDate: new Date()
                                    }, this.isReagent(d) ? d : undefined)
                                    :
                                    this.addWorkflowReagent(mapId, {
                                        LotNumber: value,
                                        Id: "",
                                        IsActive: true,
                                        CreatedOn: new Date(Date.now()),
                                        CreatedBy: "",
                                        UsedInRun: "NotUsed",
                                        IsNew: true,
                                        ReagentId: d.Id,
                                        ReagentName: "",
                                        ReagentDescription: "",
                                        ExpirationDate: new Date()
                                    }, this.isReagent(d) ? d : undefined)
                            }}

                        />
                    </div>;
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Lot/Ref Number",
            }
        ];
        return columns;
    }

    private reagentColumnsByStep(): Array<IDataTableColumn<{ step: string, reagents: (ReagentVM | ReagentGroupVM)[] }>> {
        return [
            {
                columnName: "step",
                columnFieldData: "step",
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Step",
            },
            {
                columnName: "kit-name",
                columnFieldData: (d) => <div>{_.map(d.reagents, reagent => <div style={{ height: "32px" }}>{this.isReagent(reagent) ? reagent.KitName : ""}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Kit Name",
            },
            {
                columnName: "reagent-name",
                columnFieldData: (d) => <div>{_.map(d.reagents, reagent => <div style={{ height: "32px" }}>{reagent.Name}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Reagent Name",
            },

            {
                columnName: "lot-number",
                columnFieldData: (d) => {
                    return _.map(d.reagents, reagent => {
                        let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                        data = _.union(data, reagent.Lots);
                        let isGroup = !this.isReagent(reagent);
                        let mapId = this.isReagent(reagent) ? (reagent.Id || "") + (reagent.KitName || "") : reagent.Id || "";
                        let isKit = this.isReagent(reagent) ? reagent.KitId !== undefined : false;
                        return <div>{_.map(_.sortBy(data.filter((lot, i, arr) => (arr.findIndex(t => t.LotNumber === lot.LotNumber) === i) && lot.IsActive && new Date(lot.ExpirationDate) > new Date(Date.now())), d => d.CreatedOn).reverse(), (l) => {
                            let temp = l as ReagentLotVM | ReagentGroupLotVM;
                            if (temp.LotNumber !== undefined) {
                                let selected = _.find(this.state.selectedReagentLotsDictionary, r => r.lot.Id === l.Id) !== undefined;
                                return <Chip
                                    label={temp.LotNumber}
                                    key={temp.Id}
                                    style={{ minWidth: "50px", margin: "5px", backgroundColor: selected ? "green" : "" }}
                                    onClick={() => { selected ? this.removeWorkflowReagent(mapId) : this.addWorkflowReagent(mapId, temp, this.isReagent(reagent) ? reagent : undefined); }}
                                />;
                            }
                            return <></>;
                        })}
                            <AdvanceTextField
                                disabled={this.state.selectedReagentLotsDictionary[mapId] !== undefined && !this.state.selectedReagentLotsDictionary[mapId].lot.IsNew}
                                value={this.state.selectedReagentLotsDictionary[mapId] !== undefined && this.state.selectedReagentLotsDictionary[mapId].lot.IsNew ? this.state.selectedReagentLotsDictionary[mapId].lot.LotNumber : ""}
                                onDebouncedChange={(value) => {
                                    let isKit = this.isReagent(reagent) ? reagent.KitId !== undefined : false;
                                    isGroup ?
                                        this.addWorkflowReagent(mapId, {
                                            LotNumber: value,
                                            Id: "",
                                            IsActive: true,
                                            CreatedOn: new Date(Date.now()),
                                            CreatedBy: "",
                                            UsedInRun: "NotUsed",
                                            IsNew: true,
                                            ReagentLots: [],
                                            ReagentGroupId: reagent.Id,
                                            ExpirationDate: new Date()
                                        }, this.isReagent(reagent) ? reagent : undefined)
                                        :
                                        this.addWorkflowReagent(mapId, {
                                            LotNumber: value,
                                            Id: "",
                                            IsActive: true,
                                            CreatedOn: new Date(Date.now()),
                                            CreatedBy: "",
                                            UsedInRun: "NotUsed",
                                            IsNew: true,
                                            ReagentId: reagent.Id,
                                            ReagentName: "",
                                            ReagentDescription: "",
                                            ExpirationDate: new Date()
                                        }, this.isReagent(reagent) ? reagent : undefined)
                                }}

                            />
                        </div>;
                    })
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Lot/Ref Number",
            }
        ];
    }

    state: ISamplesPageState = {
        detailsOpen: false,
        containerNameBox: "",
        containerNameSearch: "",
        sampleNameBox: "",
        sampleNameSearch: "",
        viewBySample: true,
        workflowStepViewOpen: false,
        workflowSelectionOpen: false,
        isStarting: false,
        selectedReagentLotsDictionary: {},
        associationFailedOpen: false,
        deactivatedReagentsOpen: false,
        selectLotsOpen: false,
        stepViewStructure: [],
        viewByStep: true,
        notAllAssociatedOpen: false,
        selectedStepId: "",
        shipConfirmOpen: false,
        plateId: ""
    }

    async componentDidMount() {
        this.setState({
            detailsOpen: false,
            containerNameBox: "",
            containerNameSearch: "",
            sampleNameBox: "",
            sampleNameSearch: "",
            viewBySample: true,
            workflowStepViewOpen: false
        });
        this.props.samplesService.resetResults();
        await this.props.samplesService.fetchArchivedAssetContentsBySample(this.state.containerNameSearch, true);
    }

    render() {

        const state = this.props.samplesService.getState();

        const {
            archivedAssetContentsResults,
            archivedContainersResults,
            currentPage
        } = state;

        const workflowState = this.props.workflowsService.getState();
        let { fetchWorkflowReagentsResults } = workflowState;
        let reagentsData: (ReagentVM | ReagentGroupVM)[] = [];
        reagentsData = _.union(reagentsData, fetchWorkflowReagentsResults.data?.ReagentGroups, fetchWorkflowReagentsResults.data?.Reagents);

        let archivedAssetData: ArchivedAssetContentVM[] = [];
        let archivedContainerData: ArchivedContainerVM[] = [];

        if (this.state.viewBySample) {
            archivedAssetData = archivedAssetContentsResults[currentPage].data?.ArchivedAssetContents ?? [];
        }
        else {
            archivedContainerData = archivedContainersResults[currentPage].data?.ArchivedContainers ?? [];
        }
        return <div
            className={styles.mainContainer}
        >
            <Card
                className={styles.cardStyle}
            >
                {(this.state.viewBySample ?
                    <div>
                        <div>
                            <AdvanceTextField label="Search by Container" value={this.state.containerNameBox} onDebouncedChange={(value) => { this.setState({ containerNameBox: value }) }} />
                            <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.runContainerSearch(); }}>
                                Search
                            </Button>
                        </div>
                        <AjaxActionIndicator
                            state={archivedAssetContentsResults}
                        />
                        <div className={styles.flexRow}>
                            <DataTable
                                data={archivedAssetData}
                                columns={this.archivedAssetColumns}
                                defaultSortDirection={"desc"}
                                tableFooterComponent={(
                                    <TableRow>
                                        <DataTablePager
                                            count={this.props.samplesService.totalCount}
                                            page={currentPage}
                                            rowsPerPage={this.props.samplesService.pageSize}
                                            rowsPerPageOptions={[this.props.samplesService.pageSize]}
                                            onPagerStateChange={this._onPagerStateChanged}
                                            onChangePage={() => {/*This is not actually required, but there is a typescript issue that thinks it's required */ }}
                                        />
                                    </TableRow>
                                )}
                            />
                            <Link onClick={async () => {
                                this.props.samplesService.resetResults();
                                await this.props.samplesService.fetchArchivedContainers(this.state.sampleNameSearch);
                                this.setState({ viewBySample: false });
                            }}>
                                View By Container
                            </Link>
                        </div>

                    </div>
                    :
                    <div>
                        <div>
                            <AdvanceTextField label="Search by Sample" value={this.state.sampleNameBox} onDebouncedChange={(value) => { this.setState({ sampleNameBox: value }) }} />
                            <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.runSampleSearch(); }}>
                                Search
                            </Button>
                        </div>
                        <AjaxActionIndicator
                            state={archivedContainersResults}
                        />
                        <div className={styles.flexRow}>
                            <DataTable
                                data={archivedContainerData}
                                columns={this.archivedContainerColumns}
                                defaultSortDirection={"desc"}
                                tableFooterComponent={(
                                    <TableRow>
                                        <DataTablePager
                                            count={this.props.samplesService.totalCount}
                                            page={currentPage}
                                            rowsPerPage={this.props.samplesService.pageSize}
                                            rowsPerPageOptions={[this.props.samplesService.pageSize]}
                                            onPagerStateChange={this._onPagerStateChanged}
                                            onChangePage={() => {/*This is not actually required, but there is a typescript issue that thinks it's required */ }}
                                        />
                                    </TableRow>
                                )}
                            />
                            <Link onClick={async () => {
                                this.props.samplesService.resetResults();
                                await this.props.samplesService.fetchArchivedAssetContentsBySample(this.state.containerNameSearch);
                                this.setState({ viewBySample: true });
                            }}>
                                View By Sample
                            </Link>
                        </div>

                    </div>)}
                <Dialog
                    open={this.state.detailsOpen}
                    onClose={this.handleClose}
                    PaperProps={{ className: styles.dialog }}
                >
                    {this.state.data && <SampleInfoTable
                        data={this.state.data}
                        columns={this.sampleColumns}
                        defaultSortColumnName={"sample-well-position"}
                    />}
                </Dialog>

                <Dialog
                    open={this.state.workflowStepViewOpen}
                    onClose={this.handleClose}
                    PaperProps={{ className: styles.dialog }}
                >
                    <Card
                        className={styles.cardStyle}
                    >
                        {this.state.initiationPoints &&
                            _.map(_.orderBy(this.state.initiationPoints, ['WorkflowOrder'], ['asc']), (ip, idx) => {
                                return (<div key={idx} >
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "5px", marginBottom: "5px" }}>
                                        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                                            {idx + 1}. {ip.Name}
                                        </div>
                                        {ip.IsInitiationPoint &&
                                            <IconButton disabled={this.state.isStarting} color="primary" size={"small"} onClick={async () => {
                                                await this.props.workflowsService.fetchWorkflowReagents(this.state.workflow?.WorkflowId || "", true);

                                                let fetchWorkflowReagentsResults = this.props.workflowsService.getState().fetchWorkflowReagentsResults;
                                                let reagentsData: (ReagentVM | ReagentGroupVM)[] = [];
                                                reagentsData = _.union(reagentsData, fetchWorkflowReagentsResults.data?.ReagentGroups, fetchWorkflowReagentsResults.data?.Reagents);

                                                let stepViewStructure: { [index: string]: {reagents: (ReagentVM | ReagentGroupVM)[], index: number }} = {};

                                                _.forEach(reagentsData, reagent => {
                                                    _.forEach(reagent.UsedInSteps, stepTuple => {
                                                        if (stepViewStructure[stepTuple.Item1 || ""] === undefined) {
                                                            stepViewStructure[stepTuple.Item1 || ""] = {index: stepTuple.Item2 || 0, reagents: []};
                                                        }
                                                        stepViewStructure[stepTuple.Item1 || ""].reagents.push(reagent);
                                                    });
                                                });
                                                var results = _.map(stepViewStructure, (orderedReagents, idx) => {
                                                    return { step: idx, reagents: orderedReagents.reagents, order: orderedReagents.index };
                                                });
                                                var orderedResults = _.orderBy(results, r => r.order);
                                                this.setState({selectLotsOpen: true, stepViewStructure: orderedResults, selectedStepId: ip.Id });

                                            }} >
                                                <PlayCircleFilledWhiteIcon fontSize={"small"} />
                                            </IconButton>
                                        }
                                    </div>
                                </div>);
                            })

                        }
                    </Card>
                </Dialog>
                <Dialog
                    open={this.state.workflowSelectionOpen}
                    onClose={this.handleClose}
                    PaperProps={{ className: styles.dialog }}
                >
                    <Card
                        className={styles.cardStyle}
                    >
                        <h2>Choose Workflow</h2>
                        {this.state.workflows &&
                            _.map(this.state.workflows, (w, idx) => {
                                return (<div key={idx} >
                                    <Button className={styles.searchButton} variant="contained" color="primary" onClick={
                                        async () => {
                                            let material: WellMaterial = "DNA";
                                            if (this.state.currentAsset) {
                                                material = this.state.currentAsset.WellMaterial;
                                            }
                                            else if (this.state.currentContainer) {
                                                material = this.state.currentContainer.SampleType;
                                            }
                                            await this.props.workflowsService.fetchWorkflowStepsWithInitiationPoints(w.Id || "", material);
                                            let points = this.props.workflowsService.freezer.get().workflowStepWithInitiationPointsResults.data;
                                            if (points) {
                                                this.setState({ initiationPoints: points.toJS(), workflowStepViewOpen: true, workflowSelectionOpen: false, workflow: w });
                                            }

                                        }}>
                                        {w.Name}
                                    </Button>
                                </div>);
                            })

                        }
                    </Card>
                </Dialog>
                <Dialog
                    PaperProps={{ style: { maxWidth: "700px" } }}
                    open={this.state.associationFailedOpen}
                    onClose={this.handleClose}
                    className={styles.dialog}
                >
                    Some workflow steps do not have required components associated. This run cannot be started.
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.handleClose() }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    PaperProps={{ style: { maxWidth: "700px" } }}
                    open={this.state.deactivatedReagentsOpen}
                    onClose={this.handleClose}
                >
                    {_.map(this.state.workflow?.DeactivatedReagents, r => <div>Reagent {r} is deactivated. </div>)}
                    <div>This run cannot be started.</div>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.handleClose() }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    PaperProps={{ style: { maxWidth: "1500px" } }}
                    open={this.state.selectLotsOpen}
                    onClose={this.handleClose}
                >
                    <Card>
                        <h2>Components</h2>
                        <h3>Go to the Assets tab to edit components and add/edit lot numbers.</h3>
                        <Link>
                            <div onClick={() => {
                                this.setState({ viewByStep: !this.state.viewByStep })
                            }}>
                                {this.state.viewByStep ? "View by Component" : "View by Workflow Step"}
                            </div>
                        </Link>
                        <div style={{ overflow: "auto", height: "450px" }}>
                            {this.state.viewByStep ?
                                <DataTable
                                    columns={this.reagentColumnsByStep()}
                                    data={this.state.stepViewStructure}
                                />
                                :
                                <DataTable
                                    columns={this.reagentColumns()}
                                    data={reagentsData}
                                />
                            }
                        </div>
                    </Card>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                let selectedCount = 0;
                                _.forEach(this.state.selectedReagentLotsDictionary, d => selectedCount++);
                                if (selectedCount !== reagentsData.length) {
                                    this.setState({ notAllAssociatedOpen: true })
                                }
                                else {
                                    this.startFromArchivedPlate(this.state.selectedStepId);
                                }
                            }}
                        >
                            Continue
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.handleClose() }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    PaperProps={{ style: { maxWidth: "700px" } }}
                    open={this.state.notAllAssociatedOpen}
                    onClose={() => this.setState({ notAllAssociatedOpen: false })}
                    className={styles.dialog}
                >
                    You have not specified lot numbers for some components that this run will use.
                    <DialogActions>
                        {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.startFromArchivedPlate(this.state.selectedStepId); }}
                        >
                            Continue
                        </Button> */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.setState({ notAllAssociatedOpen: false })}
                        >
                            Change Lot Numbers
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.shipConfirmOpen}
                    onClose={() => this.setState({shipConfirmOpen: false})}
                    maxWidth={'lg'}
                >
                    <Card className={styles.shipCard}>
                        <h2>Ship Sample?</h2>
                        <div className={styles.shipButtons}>
                            <Button onClick={() => { this.updateCDCStatus(this.state.plateId) }}>Yes</Button>
                            <Button onClick={() => this.setState({shipConfirmOpen: false})}>No</Button>
                        </div>
                    </Card>
                </Dialog>
            </Card>
        </div>

    }

    @bind
    private async runContainerSearch() {
        await this.setState({ containerNameSearch: this.state.containerNameBox });
        this.props.samplesService.resetResults();
        await this.props.samplesService.fetchArchivedAssetContentsBySample(this.state.containerNameSearch, true);
    }

    @bind
    private async runSampleSearch() {
        await this.setState({ sampleNameSearch: this.state.sampleNameBox });
        this.props.samplesService.resetResults();
        await this.props.samplesService.fetchArchivedContainers(this.state.sampleNameSearch, true);
    }

    @bind
    private _onPagerStateChanged(page: number, rowsPerPage: number) {
        this.props.samplesService.setPageNumber(page, this.state.viewBySample, this.state.viewBySample ? this.state.containerNameSearch : this.state.sampleNameSearch);
    }

    @bind
    private handleClose() {
        this.setState({ detailsOpen: false, data: undefined, initiationPoints: undefined, workflowStepViewOpen: false, workflowSelectionOpen: false, selectLotsOpen: false });
    }

    @bind
    private startFromArchivedPlate(stepId: string) {
        if (this.state.workflow) {
            this.setState({ isStarting: true });
            let assetId = "";
            let workflowRunId = "";

            if (this.state.currentAsset) {
                assetId = this.state.currentAsset.Container.Id;
                workflowRunId = this.state.currentAsset.WorkflowRunId;
            }
            else if (this.state.currentContainer) {
                assetId = this.state.currentContainer.Id;
                workflowRunId = this.state.currentContainer.WorkflowRunId;
            }
            this.props.workflowsService.startWorkflowFromArchivedPlate(this.state.workflow?.Id || "", stepId, assetId, workflowRunId, this.state.selectedReagentLotsDictionary, async (workflowInstanceId, stepInstanceId) => {
                NavigationService.navigateTo(urljoin("/WorkflowRun", workflowInstanceId, "step", stepInstanceId));
            });
        }
    }

    @bind
    private async updateCDCStatus(plateId: string) {
        await this.props.samplesService.updateCDCStatus(plateId, true);
        await this.props.samplesService.resetResults();
        if(this.state.viewBySample) {
            await this.props.samplesService.fetchArchivedAssetContentsBySample(this.state.containerNameSearch, true);
        } else {
            await this.props.samplesService.fetchArchivedContainers(this.state.containerNameSearch, true);
        }

        this.setState({ shipConfirmOpen: false });
    }

    @bind
    private addWorkflowReagent(reagentId: string, reagentLot: ReagentLotVM | ReagentGroupLotVM, reagent?: ReagentVM) {
        let newReagents = _.cloneDeep(this.state.selectedReagentLotsDictionary);

        newReagents[reagentId] = { lot: reagentLot, kitId: reagent?.KitId || "" };


        if (reagent && reagent.KitName) {
            //kits
            //loop all reagents, if kit id = reagent.kitId, find where lot number = same and set.
            let state = this.props.workflowsService.getState();
            let { fetchWorkflowReagentsResults } = state;
            _.forEach(fetchWorkflowReagentsResults.data?.Reagents, r => {
                if (r.KitId === reagent.KitId && r.Id !== reagent.Id) {
                    var lot = _.find(r.Lots, l => l.LotNumber === reagentLot.LotNumber);
                    if (lot !== undefined) {
                        newReagents[(r.Id || "") + (r.KitName || "")] = { lot: lot, kitId: reagent?.KitId || "" };;
                    }
                    else {
                        if (reagentLot.IsNew) {
                            newReagents[(r.Id || "") + (r.KitName || "")] =
                            {
                                lot: {
                                    LotNumber: reagentLot.LotNumber,
                                    Id: "",
                                    IsActive: true,
                                    CreatedOn: new Date(Date.now()),
                                    CreatedBy: "",
                                    UsedInRun: "NotUsed",
                                    IsNew: true,
                                    ReagentId: r.Id,
                                    ReagentName: "",
                                    ReagentDescription: "",
                                    ExpirationDate: new Date()
                                },
                                kitId: reagent?.KitId || ""
                            };
                        }
                    }
                }
            });
        }

        this.setState({ selectedReagentLotsDictionary: newReagents });
    }

    @bind
    private removeWorkflowReagent(reagentId: string, reagent?: ReagentVM) {
        let newReagents = _.cloneDeep(this.state.selectedReagentLotsDictionary);
        delete newReagents[reagentId];

        //kits
        if (reagent && reagent.KitName) {
            let state = this.props.workflowsService.getState();
            let { fetchWorkflowReagentsResults } = state;
            _.forEach(fetchWorkflowReagentsResults.data?.Reagents, r => {
                if (r.KitId === reagent.KitId && r.Id !== reagent.Id) {
                    delete newReagents[(r.Id || "") + (r.KitName || "")];
                }
            });
        }

        this.setState({ selectedReagentLotsDictionary: newReagents });
    }

    private isReagent(r: ReagentVM | ReagentGroupVM): r is ReagentVM {
        return (r as ReagentVM).InKits !== undefined;
    }
}


export const SamplesPage = WorkflowsService.inject(SampleService.inject(
    _SamplesPage
));