import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";

import {
    SampleInfoTable,
    StandardPlate,
    IDataTableColumn,
    StepActionsControl,
    StepChangeControl,
    AdvanceTextField,
    QCUIComponent,
    PoolingPoolTargetMandatoryValueCheck,
    PoolingVolumeFactorMandatoryValueCheck,
    CollapsibleSection,
    DataLoadingDisplay
} from "$Imports/CommonComponents";

import {
    WellContentVM, PopulatePoolFromPlateRequestVM, PoolingResultVM, QCCheckInstanceVM, QCCheckResultVM
} from "$Generated/api";

import {
    InputLabel,
    Select,
    MenuItem,
    Snackbar,
    TableCellProps
} from "$Imports/MaterialUIComponents";

import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";
import {
    CalculationService
} from "$State/CalculationFreezerService";

const styles: {
    tableHeader: string;
    sampleInfoDiv: string;
    poolingRowDiv: string;
    poolingInput: string;
    selectedRow: string;
} = require("./Pooling.scss");

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

export interface IPoolingState {
    poolWellDataDictionary: { [index: string]: IPoolWellData }
    totalScaledVolume: number;
    selectedWell: number;
    totalWells: number;
    messageSnackbarOpen: boolean;
    message: string;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    dataLoaded: boolean;
}

interface IPoolWellData {
    RawVolume: number;
    ScaledVolume: number;
}

export class PoolingScreen extends React.Component<IWorkflowScreenProps, IPoolingState> {

    state: IPoolingState = {
        poolWellDataDictionary: {},
        totalScaledVolume: 0,
        selectedWell: 0,
        totalWells: 0,
        messageSnackbarOpen: false,
        message: "",
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        dataLoaded: false
    };

    private rowDivs: (HTMLDivElement | null)[] = []

    @bind
    private cellProps(d: WellContentVM): TableCellProps {
        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            const plates = this.props.workflowRunService.getState().plates;
            const racks = this.props.workflowRunService.getState().racks;
            let inputAsset;
            if (plates.data) {
                inputAsset = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
                if (inputAsset) {
                    if (_.orderBy(inputAsset.WellContents, wc => wc.WellPosition)[this.state.selectedWell].Id == d.Id) {
                        return {
                            className: styles.selectedRow
                        };
                    }
                }
            }
            else if (racks.data) {
                inputAsset = _.find(racks.data, (p) => { return p.Name === currentStep.InputName });
                if (inputAsset) {
                    if (_.orderBy(inputAsset.WellContents, wc => wc.WellPosition)[this.state.selectedWell].Id == d.Id) {
                        return {
                            className: styles.selectedRow
                        };
                    }
                }
            }
        }
        return {
        };
    }

    private columns(): Array<IDataTableColumn<WellContentVM>> {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => { return (<div ref={ref => this.rowDivs[d.WellPosition] = ref}>{(d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : ""))}</div>) },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Sample ID",
                    sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
                },
                {
                    columnName: "sample-well-position",
                    columnFieldData: (d) => ConvertPositionToString(d.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Well Position",
                    sortMethod: (d) => d.WellPosition ?? ""
                },
                {
                    columnName: "sample-scaled-volume",
                    columnFieldData: (d) => (this.state.poolWellDataDictionary[d.Id] ? (this.state.poolWellDataDictionary[d.Id].ScaledVolume > 0 ? this.state.poolWellDataDictionary[d.Id].ScaledVolume.toFixed(2) : "FAIL") 
                                            : (GetWorkflowType() === "Anthrax" ? (d.Control ? (d.Control.Name === "NTC" ? "Not Included" : "10 ul") : (d.Sample ? "10 ul" : "")) : "")),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Scaled Volume (ul)",
                    sortMethod: (d) => (this.state.poolWellDataDictionary[d.Id] ? (this.state.poolWellDataDictionary[d.Id].ScaledVolume > 0 ? this.state.poolWellDataDictionary[d.Id].ScaledVolume.toFixed(2) : (GetWorkflowType() === "Anthrax" ? "NOT INCLUDED" : "FAIL")) : "") ?? ""
                }
            ];
        }
        return [];
    }

    async componentDidMount() {
        if (GetWorkflowType() === "Anthrax") {
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
        document.addEventListener("keydown", this.handleKeyDown);

        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            const customFields = currentStep.CustomFields;
            if (customFields["TargetPoolDNA"] && customFields["VolumeFactor"]) {
                this.calculatePoolData();
            }
            const plates = this.props.workflowRunService.getState().plates;
            const racks = this.props.workflowRunService.getState().racks;
            if (plates.data) {
                let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
                if (inputPlate) {
                    this.setState({ totalWells: inputPlate.WellContents.length });
                }
            }
            else if (racks.data) {
                let inputRack = _.find(racks.data, (p) => { return p.Name === currentStep.InputName });
                if (inputRack) {
                    this.setState({ totalWells: inputRack.WellContents.length });
                }
            }
        }
        this.setState({ dataLoaded: true });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
        this.props.workflowRunService.resetInternalData();
    }

    render() {

        const plates = this.props.workflowRunService.getState().plates;
        const racks = this.props.workflowRunService.getState().racks;
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if ((plates.data || racks.data) && currentStep && currentWorkflowRun) {
            let inputAsset;
            if (plates.data) {
                inputAsset = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            } else if (racks.data) {
                inputAsset = _.find(racks.data, (p) => { return p.Name === currentStep.InputName });
            }

            const customFields = currentStep.CustomFields;
            if (customFields && inputAsset) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.mainDiv}>
                                <div className={styles.sampleInfoDiv}>
                                    <div>
                                        <h2>{inputAsset.Name}</h2>
                                        <StandardPlate
                                            wellContents={inputAsset.WellContents}
                                            wellCount={(GetWorkflowType() === "Anthrax") ? 8 : 96}
                                            columnCount={currentWorkflowRun.AssetColCount}
                                            positionByRow={currentWorkflowRun.AssetPositionByRow}
                                            selectedPosition={_.orderBy(inputAsset.WellContents, wc => wc.WellPosition) [this.state.selectedWell].WellPosition}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <h2>Pooling Calculations</h2>
                                            <div className={styles.poolingRowDiv}>
                                                <div>
                                                    Sample Count: {inputAsset.WellContents.length}
                                                </div>
                                                <div className={styles.poolingInput}>
                                                    <InputLabel id="target-pool-amount-label">Target Pool DNA amount (ng):</InputLabel>
                                                    {(GetWorkflowType() === "Anthrax")
                                                    ? <div></div>
                                                    : <Select
                                                        autoFocus={true}
                                                        disabled={disabled}
                                                        className={styles.poolingInput}
                                                        labelId="target-pool-amount-label"
                                                        id="target-pool-amount"
                                                        value={(customFields["TargetPoolDNA"] ? customFields["TargetPoolDNA"] : "")}
                                                        onChange={(event) => { this.props.workflowRunService.updateCustomField("TargetPoolDNA", (event.target.value as string)); this.calculatePoolData(); }}
                                                    >
                                                        <MenuItem key={"value-1000"} value={1000}>{1000}</MenuItem>
                                                        <MenuItem key={"value-1100"} value={1100}>{1100}</MenuItem>
                                                        <MenuItem key={"value-1200"} value={1200}>{1200}</MenuItem>
                                                    </Select> }
                                                </div>
                                                <div className={styles.poolingInput}>
                                                    {(GetWorkflowType() === "Anthrax") 
                                                    ? <div></div>
                                                    : <InputLabel id="volume-factor-label">Volume Factor</InputLabel> }
                                                    {(GetWorkflowType() === "Anthrax")
                                                    ? <div style={{ float: "left"}}><InputLabel>400</InputLabel></div>
                                                    : <Select
                                                        disabled={disabled}
                                                        className={styles.poolingInput}
                                                        labelId="volume-factor-label"
                                                        id="volume-factor"
                                                        value={(customFields["VolumeFactor"] ? customFields["VolumeFactor"] : "")}
                                                        onChange={(event) => { this.props.workflowRunService.updateCustomField("VolumeFactor", (event.target.value as string)); this.calculatePoolData(); }}
                                                    >
                                                        <MenuItem key={"value-1"} value={1}>{1}</MenuItem>
                                                        <MenuItem key={"value-2"} value={2}>{2}</MenuItem>
                                                        <MenuItem key={"value-3"} value={3}>{3}</MenuItem>
                                                    </Select> }
                                                </div>
                                            </div>
                                        </div>
                                        <SampleInfoTable
                                            data={inputAsset?.WellContents}
                                            columns={this.columns()}
                                            defaultSortColumnName={"sample-well-position"}
                                            footer={
                                                (<div>
                                                    <div>Total Scaled Volume: {(GetWorkflowType()=== "Anthrax") ? (this.state.totalWells - 1)*10 : this.state.totalScaledVolume.toFixed(2)} ul</div>
                                                    {(GetWorkflowType() === "Anthrax") ? <div></div> : (this.state.totalScaledVolume < 48 ? (<div>Volume of water to pool 48ul: {(48 - this.state.totalScaledVolume).toFixed(2)} ul</div>) : <></>)}
                                                </div>)
                                            }
                                            overrideStyles={{ height: "350px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} hideArchiveRetain={inputAsset.WellContents[0].RunCount >= currentWorkflowRun.WorkflowMaxSampleRun} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.message}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private handleKeyDown(event: KeyboardEvent) {

        const currentIndex = this.state.selectedWell;
        if (event.key === "ArrowUp") {
            event.preventDefault();
            event.stopPropagation();
            if (currentIndex > 0) {
                this.setState({ selectedWell: this.state.selectedWell - 1 });
            }
        }
        if (event.key === "ArrowDown") {
            event.preventDefault();
            event.stopPropagation();
            if (currentIndex + 1 < this.state.totalWells) {
                this.setState({ selectedWell: this.state.selectedWell + 1 });
            }
        }
        var newRow = this.rowDivs[this.state.selectedWell];
        if (newRow) {
            newRow.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" });
        }
    }

    @bind
    private async moveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];

        if (currentWorkflowRun && currentStep) {
            const customFields = currentStep.CustomFields;
            //Run all QCs
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case PoolingPoolTargetMandatoryValueCheck:
                        let volFactorSet = customFields["VolumeFactor"] !== "";
                        if (!volFactorSet) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                        }

                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? volFactorSet ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: volFactorSet ? "Volume Factor Set" : "Volume Factor Not Set",
                                Date: new Date(Date.now()),
                                Pass: volFactorSet,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case PoolingVolumeFactorMandatoryValueCheck:
                        let targetAmountSet = customFields["TargetPoolDNA"] !== "";
                        if (!targetAmountSet) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                        }

                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? targetAmountSet ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: targetAmountSet ? "Target Amount Set" : "Target Amount Not Set",
                                Date: new Date(Date.now()),
                                Pass: targetAmountSet,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                }
            });
        }
        if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
            this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
        }
        else {
            this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
            if (currentStep && currentStep.OutputAssets) {
                let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName)
                let inputAsset = _.find(currentStep.toJS().InputAssets, a => a.Name === currentStep.InputName)
                if (inputAsset && inputAsset.PlateSummary && inputAsset.PlateSummary.Status !== "Retained") {
                    const plates = this.props.workflowRunService.getState().plates;
                    const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
                    const racks = this.props.workflowRunService.getState().racks;
                    const inputRack = _.find(racks.data, rack => (rack.Name === currentStep.InputName));
                    if (currentWorkflowRun && 
                        ((inputPlate && inputPlate.WellContents[0].RunCount < currentWorkflowRun.WorkflowMaxSampleRun) || 
                        (inputRack && inputRack.WellContents[0].RunCount < currentWorkflowRun.WorkflowMaxSampleRun))) {
                        this.setState({ messageSnackbarOpen: true, message: inputAsset.Name + " must be retained before continuing" });
                        return;
                    }
                    else if (inputPlate) {
                        //Auto discard plate
                        await this.props.workflowRunService.updatePlateStatus([inputPlate.Id], "Discarded", false);
                    }
                    else if (inputRack) {
                        //same as above, auto discard rack
                        await this.props.workflowRunService.updateAssetStatus([inputRack.Id], "Discarded", "Rack");
                    }
                }
                if (outputAsset && outputAsset.PoolSummary && inputAsset && (inputAsset.PlateSummary || inputAsset.RackSummary) && currentWorkflowRun) {
                    //Create PopulatePoolFromPlateRequestVM
                    const poolWellDataDictionary = this.state.poolWellDataDictionary;

                    let poolingResults: PoolingResultVM[] = _.map(poolWellDataDictionary, (val, id) => {
                        return {
                            Id: id,
                            ScaledVolume: val.ScaledVolume,
                            ShouldBePooled: (val.ScaledVolume > 0)
                        };
                    });

                    let asset = "";

                    if (inputAsset.PlateSummary) {
                        asset = inputAsset.PlateSummary.Id;
                    }
                    else if (inputAsset.RackSummary) {
                        asset = inputAsset.RackSummary.Id;
                    }

                    let request: PopulatePoolFromPlateRequestVM =
                    {
                        AssetId: asset,
                        PoolId: outputAsset.PoolSummary.Id,
                        PoolingResults: poolingResults,
                        WorkflowRunId: currentWorkflowRun.Id
                    };

                    await Promise.all([
                        this.props.workflowRunService.updateCustomFields(),
                        this.props.workflowRunService.savePoolVolume(outputAsset.PoolSummary.Id, 48), //Volume is equal to 48.
                        this.props.workflowRunService.populatePoolFromPlate(request)
                    ]);
                    await this.props.workflowRunService.completeStep();
                }
            }
        }
    }

    @bind
    private async calculatePoolData() {
        const plates = this.props.workflowRunService.getState().plates;
        const racks = this.props.workflowRunService.getState().racks;
        const currentStep = this.props.workflowRunService.currentStep;
        if ((plates.data || racks.data) && currentStep) {
            let inputAsset;
            if (plates.data) {
                inputAsset = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            }
            else if (racks.data) {
                inputAsset = _.find(racks.data, (p) => { return p.Name === currentStep.InputName });
            }
            const customFields = currentStep.CustomFields;
            if (inputAsset) {
                if (!(customFields["TargetPoolDNA"] && customFields["VolumeFactor"])) {
                    return;
                }
                let customFieldsData: { [key: string]: number } = {};

                customFieldsData["TargetPoolDNA"] = parseInt(customFields["TargetPoolDNA"]);
                customFieldsData["VolumeFactor"] = parseInt(customFields["VolumeFactor"]);

                const wellDataDictionary: { [index: string]: number } = {}

                _.forEach(inputAsset.WellContents, well => {
                    wellDataDictionary[well.Id] = well.Concentration || 0;
                });

                await CalculationService.performCalculation("pooling", wellDataDictionary, customFieldsData);
                var data = CalculationService.getState().performCalculationResponse.data?.CalculationResponse as { [key: string]: any };

                this.setState({ poolWellDataDictionary: data["poolData"], totalScaledVolume: data["totalScaledVolume"] })
            }
        }

    }
}