import {
    FreezerService,
    _,
    managedAjaxUtil,
    IAjaxState,
    bind
} from "$Imports/Imports";
import { CSVApiFactory, CSVApiFetchParamCreator, CSVBarcodeVM, CSVQubitFlurometerVM, MetadataTemplateVM, UploadFileResponse, SampleWithMetadataVM, LabAssetUploadVM, BarcodeKitType, MetadataUploadVM } from "$Generated/api";

const InjectedPropName = "CSVService";

export interface ICSVState {
    uploadResults: IAjaxState<UploadFileResponse>;
    uploadLabAssets: IAjaxState<UploadFileResponse>;
    uploadMetadataResults: IAjaxState<UploadFileResponse>;
    metadataResults: IAjaxState<MetadataTemplateVM[]>;
    sampleMetadataResults: IAjaxState<SampleWithMetadataVM[]>;
    parseBarcodeResults: IAjaxState<CSVBarcodeVM[]>;
    parseFlurometerOutputResults: IAjaxState<CSVQubitFlurometerVM[]>;
    updateResultsMetadata: IAjaxState<UploadFileResponse>;
    uploadFullMetadata: IAjaxState<UploadFileResponse>;
}

class CSVFreezerService extends FreezerService<ICSVState, typeof InjectedPropName> {
    constructor() {
        super({
            uploadResults: managedAjaxUtil.createInitialState(),
            uploadLabAssets: managedAjaxUtil.createInitialState(),
            uploadMetadataResults: managedAjaxUtil.createInitialState(),
            metadataResults: managedAjaxUtil.createInitialState(),
            sampleMetadataResults: managedAjaxUtil.createInitialState(),
            parseBarcodeResults: managedAjaxUtil.createInitialState(),
            parseFlurometerOutputResults: managedAjaxUtil.createInitialState(),
            updateResultsMetadata: managedAjaxUtil.createInitialState(),
            uploadFullMetadata: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async uploadResults(csv: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "uploadResults",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVUploadSamplesPost({body: csv});
            },
        });
    }

    public async uploadLabAssets(csvs: LabAssetUploadVM) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "uploadLabAssets",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVUploadLabAssetsPost({body: csvs});
            },
        });
    }

    public async getMetdataTemplates() {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "metadataResults",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVMetadataTemplatesGet();
            },
        });
    }

    public async getSampleMetadata() {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "sampleMetadataResults",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVSampleMetadataGet();
            },
        });
    }

    public async uploadMetadataResults(templateId: string, csv: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "uploadMetadataResults",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVUploadMetadataPost({templateId: templateId, body: csv});
            },
        });
    }

    public async uploadFullMetadata(csvFiles: MetadataUploadVM) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "uploadFullMetadata",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVUploadFullMetadataPost({body: csvFiles});
            },
        });
    }

    public async parseBarcodes(csv: string, barcodeKitType: BarcodeKitType) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "parseBarcodeResults",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVUploadBarcodesPost({barcodeKitType, body: csv});
            },
        });
    }

    public async parseFlurometerOutput(csv: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "parseFlurometerOutputResults",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVParseCSVFlurometerOutputPost({body: csv});
            }
        });
    }

    public async updateResultsMetadata(tsv: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateResultsMetadata",
            onExecute: (apiOptions, param, options) => {
                const factory = CSVApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CSVUpdateResultsMetadataPost({body: tsv});
            },
        });
    }
    
}

export const CSVService = new CSVFreezerService();
export type ICSVServiceInjectedProps = ReturnType<CSVFreezerService["getPropsForInjection"]>;