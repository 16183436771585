import {
  React,
  bind,
  _
} from "$Imports/Imports";

import { 
  MenuList, 
  Button 
} from "$Imports/MaterialUIComponents";

import { 
  qcMetricsNavigation,
  getQCMetricsBasePath
} from "$Utilities/qcMetricsNavigation";

import { ThemeConsumer } from "$Providers/ThemeProvider";
import { withStyles } from "$Imports/MaterialUIStyles";
import { ApplicationSecurityConsumer } from "$Providers/AuthenticationProvider";
import { SideNavigationMenuItem } from "$Components/root-components/SideNavigationMenuItem";
import { NavigationService } from "$State/NavigationFreezerService";
import { INavigationItem } from "$Utilities/navigation";

var urljoin = require('url-join');

const styles: {
  menu:string;
  genomeDataLinkContainer:string;
} = require("./QCMetricsSideNav.scss");

interface ISideNavigationProps {
  runId: string,
  stepInstanceId: string,
  pipelineRunNumber: string,
  sampleId: string
}

/**
 * Navigation component for QC Visualization/Stats pages. These metrics pages require a different nav menu from the primary site.
 */
export class QCMetricsSideNav extends React.Component<ISideNavigationProps> {

  render() {

    const basePath = getQCMetricsBasePath();
    const navItems = qcMetricsNavigation();
    
    return (
      <ThemeConsumer>
      {(context) => {
          const StylesMenuList = withStyles(context.themeConfig.sideNavigationMenuItemIcon)(MenuList);

          return (
              <div style={{backgroundColor:context.themeConfig.sideNavigationColor}}>
                  
                  <StylesMenuList className={styles.menu}>
                    <ApplicationSecurityConsumer>
                        {(authenticationContext) => {
                
                          return _.map(navItems, (n, nIdx) => {
                              return (
                              <SideNavigationMenuItem
                                  key={nIdx}
                                  navigationItem={n}
                                  selected={n.url === basePath}
                                  onMenuItemClick={(e:React.MouseEvent) => this._onChange(e, n)}
                                  securityContext={authenticationContext.securityContext}
                                  useDryLab={true}
                                  useWetLab={true}
                              />
                          )});
                        
                        }}
                    </ApplicationSecurityConsumer>
                  </StylesMenuList>

                  <div className={styles.genomeDataLinkContainer}>
                    <Button variant="contained" 
                      color="primary" 
                      onClick={()=> this.navigateToGenomicsDataPage()}>
                        Return To Genomics Data Page
                    </Button>  
                  </div>

              </div>
          );
      }}
    </ThemeConsumer>
    );
  }

  /**
   * Click event handler that will navigate to screens within the qc metrics section of the app
   * @param e 
   * @param navItem 
   */
  @bind
  private _onChange(e:React.MouseEvent, navItem:INavigationItem) : void {

     //Conditionally set the nav url(include step for NBS)
    const navigateToUrl = this.props.stepInstanceId ?
      urljoin(navItem.url, this.props.runId,'step',this.props.stepInstanceId, 'sample', this.props.sampleId) :
      urljoin(navItem.url, this.props.runId, 'pipelinerunnumber', this.props.pipelineRunNumber, 'sample', this.props.sampleId);

    NavigationService.navigateTo(navigateToUrl)    
  }

  /**
   * Method to navigate back to the genomics data page from which the user came
   * Supplies the run and step instance such that the page will open to the step they clicked from
   */
  private navigateToGenomicsDataPage() : void {
    
    //Conditionally set the nav url(workflow step for NBS, pipeline run for Sars)
    const navigateToUrl = this.props.stepInstanceId ? 
      urljoin('/WorkflowRun', this.props.runId, 'step', this.props.stepInstanceId) : 
      urljoin('/pipelineRun', this.props.pipelineRunNumber);
    
    NavigationService.navigateTo(navigateToUrl);
  }
}