import {
    FreezerService,
    _,
    managedAjaxUtil,
    IAjaxState,
    bind
} from "$Imports/Imports";

import { 
    NGSQCReportsApiFactory,
    NGSQCReportsVM
} from "$Generated/api";
import { Dictionary } from "lodash";

// This string can be anything. It's the key word that is going to be
// consumed by anything the Freezer Service is injected into.
const InjectedPropName = "NGSQCReportsService";

export interface INGSQCreportsState {
    NGSQCReportsData: IAjaxState<NGSQCReportsVM>;
}

class NGSQCReportsFreezerService extends FreezerService<INGSQCreportsState, typeof InjectedPropName> {
    constructor() {
        super({
            NGSQCReportsData: managedAjaxUtil.createInitialState(),
        }, InjectedPropName);
    }

    public async getNGSQCReportsData(workflowRunId: string, workflowId: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "NGSQCReportsData",
            onExecute: (apiOptions, param, options) => {
                const factory = NGSQCReportsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1NGSQCReportsWorkflowRunIdGet({workflowRunId, workflowId});
            },
        });
    }
    
    
    
}

export const NGSQCReportsService = new NGSQCReportsFreezerService();
export type INGSQCReportsServiceInjectedProps = ReturnType<NGSQCReportsFreezerService["getPropsForInjection"]>;