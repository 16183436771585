import {
    React,
    bind,
    _,
} from "$Imports/Imports"

import { IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, CollapsibleSection, DataLoadingDisplay, BioanalyzerInstrument, SimpleQuantificationInstrument, PCRControl, SafeStoppingPointComponent } from "$Imports/CommonComponents";


const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

interface IHybridizeProbesState {
    dataLoaded: boolean;
}

export class HybridizeProbes extends React.Component<IWorkflowScreenProps, IHybridizeProbesState> {

    constructor(props: IWorkflowScreenProps) {
        super(props);

        this.state = {
            dataLoaded: false
        };
    }

    async componentDidMount() {
        await this.props.workflowRunService.fetchReagentListForStep(true);
        this.props.workflowRunService.fetchPCRForStep(true);
        await this.props.workflowRunService.fetchInstrumentOptions();
        this.setState({ dataLoaded: true });
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {


        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;

        if (currentWorkflowRun && currentStep && masterMixInfoState.data && lotOptions && instrumentOptions && pcrInfoState.data) {
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return (
                <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={commonStyles.mainDiv}>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                    {
                                        <h2>Reagent Information</h2>
                                    }
                                    <div style={{ marginRight: "20px" }}>
                                        {currentWorkflowRun.UseSafeStoppingPoints && currentStep.AllowPause && <SafeStoppingPointComponent />}
                                    </div>
                                </div>
                                <MasterMixControl
                                    disabled={disabled}
                                    index={0}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={0}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { }}
                                    hideHeaders
                                />
                            </div>
                            <PCRControl disabled={disabled} controlInfo={pcrInfoState.data} updateInstrumentName={this.updatePCR} instrumentOptions={instrumentOptions} />
                        </div>
                    </CollapsibleSection>
                    <div className={commonStyles.footerDiv}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                    </div>
                </div>)
        }
        return <DataLoadingDisplay />;
    }

    @bind
    updatePCR(val: string) {
        this.props.workflowRunService.updatePCRName(val);
        this.props.workflowRunService.updateCustomField("ThermoCycler", val)
    }

    @bind
    private async moveToNextStep() {
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;

        const currentStep = this.props.workflowRunService.currentStep;

        if (currentStep && masterMixState.data) {
            if (currentStep.OutputAssets) {
                await Promise.all([
                    this.props.workflowRunService.saveMasterMix(true),
                    this.props.workflowRunService.updateCustomFields(),
                    this.props.workflowRunService.savePCRInfo(),
                ]);
                await this.props.workflowRunService.completeStep(); //Needs to be outside due to a race condition
            }
        }
    }


}