import {
    FreezerService,
} from "$Imports/Imports";
import { NavigationService } from "./NavigationFreezerService";

export interface ExceptionVM
{
    ExceptionType: string;
    ErrorMessage: string;
}

export function apiExceptionHandler(err: ExceptionVM, errString: string) {
    err.ErrorMessage = errString;
    ErrorService.error = err;
    NavigationService.navigateTo("/Error");
}

interface IErrorState {
    error: ExceptionVM;
}

const InjectedPropName = "errorService";

class ErrorFreezerService extends FreezerService<IErrorState, typeof InjectedPropName> {
    constructor() {
        super({
            error: {ExceptionType: "", ErrorMessage: ""}
        }, InjectedPropName);
    }


    set error(err: ExceptionVM)
    {
        this.freezer.get().set({error: err});
    }

    get error()
    {
        return this.freezer.get().error
    }
}

export const ErrorService = new ErrorFreezerService();
export type IErrorServiceInjectedProps = ReturnType<ErrorFreezerService["getPropsForInjection"]>;
