import {
    React,
    bind,
    moment,
    _,
    ReactGA
} from "$Imports/Imports";

var urljoin = require('url-join');

import {
    Card, Button, TableRow, LockOpenIcon, IconButton, Dialog, Link, FormControlLabel, Checkbox
} from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AjaxActionIndicator,
    DataTablePager,
    SampleInfoTable,
    ProcessAdminRole,
} from "$Imports/CommonComponents";

import {
    IRunsServiceInjectedProps,
    RunsService
} from "$State/RunsFreezerService";

import {
    WetlabRunVM, WellContentVM
} from "$Generated/api";
import { NavigationService } from "$State/NavigationFreezerService";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { GetAppIcon, Person } from "$Imports/MaterialUIIcons";

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    continueButtonStyle: string;
    dialog: string;
} = require("./RunsPage.scss");

interface IRunsPageBaseProp {

}

type IRunsPageProp = IRunsServiceInjectedProps & IRunsPageBaseProp;

interface IRunsPageState {
    detailsOpen: boolean;
    data?: WellContentVM[];
    usersOpen: boolean;
    users?: string[];
    tableRows?: any[];
}

// tslint:disable-next-line: class-name
export class _RunsPage extends React.Component<IRunsPageProp, IRunsPageState> {

    private _security = new ApplicationSecuritySettings();

    state: IRunsPageState = {
        detailsOpen: false,
        usersOpen: false,
    }

    async componentDidMount() {
        await this.props.runsService.fetchWorkflowRuns(true);
    }


    private readonly columns: Array<IDataTableColumn<WetlabRunVM>> = [
        {
            columnName: "is-locked",
            columnFieldData: (d) => {

                let securityContext = this._security.securityContext
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === ProcessAdminRole || role === "System Admin"));
                    if (hasRole !== undefined) {
                        return (d.IsLocked ?
                            <IconButton onClick={() => {
                                if (d.IsLocked) {
                                    this._unlockRun(d.Id)
                                }
                            }}>
                                <LockOpenIcon fontSize={"large"} />
                            </IconButton>
                            : <></>
                        );
                    }
                }
                return "";

            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: ""
        },
        {
            columnName: "workflow-name",
            columnFieldData: "WorkflowName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Workflow Name",
            sortMethod: (d) => d.WorkflowName ?? ""
        },
        {
            columnName: "workflow-run-number",
            columnFieldData: "RunNumber",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Run Number",
            sortMethod: (d) => d.RunNumber ?? ""
        },
        {
            columnName: "workflow-created-date",
            columnFieldData: (d) => moment(d.CreatedDate).format("L LT"),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Started On",
            sortMethod: (d) => d.CreatedDate ?? ""
        },
        {
            columnName: "workflow-current-step",
            columnFieldData: "CurrentOrLastStepName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Current Step",
            sortMethod: (d) => d.CreatedDate ?? ""
        },
        {
            columnName: "workflow-state",
            columnFieldData: (d) => {
                return <div>
                    <div>
                        {d.RunState === "TechnicalFailure" ? "Technical Failure" : d.RunState === "InProgress" ? "In Progress" : d.RunState}
                    </div>
                    {d.RunState === "InProgress" && <div>
                        {d.SequencingStatus === "Started" ? "(Sequencing Started)" : d.SequencingStatus === "Complete" ? "(Sequencing Complete)" : d.SequencingStatus === "Failure" ? "(Sequencing Failed)" : ""}
                    </div>}
                </div>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Status",
            sortMethod: (d) => d.RunState ?? ""
        },
        {
            columnName: "workflow-gridded-samples",
            columnFieldData: (d) => {

                return (<div onClick={async () => {
                    if (d.GriddingAssetId) {
                        await this.props.runsService.getPlate(d.GriddingAssetId);
                        let data = this.props.runsService.freezer.get().plateDataResults.data;
                        if (data) {
                            this.setState({ detailsOpen: true, data: data.WellContents.toJS() })
                        }
                    }
                }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "5px" }}>
                            {d.GriddingCount ? d.GriddingCount : ""}
                        </div>
                        <Link>
                            {d.GriddingCount ? " Details" : ""}
                        </Link>
                    </div>
                </div>);
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Gridded Samples",
        },
        {
            columnName: "workflow-pooled-samples",
            columnFieldData: (d) => {

                return (<div onClick={async () => {
                    if (d.PoolingAssetId) {
                        await this.props.runsService.getPoolContents(d.PoolingAssetId);
                        let data = this.props.runsService.freezer.get().poolDataResults.data;
                        if (data) {
                            this.setState({ detailsOpen: true, data: data.toJS() })
                        }
                    }
                }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "5px" }}>
                            {d.PoolingCount ? d.PoolingCount : ""}
                        </div>
                        <Link>
                            {d.PoolingCount ? " Details" : ""}
                        </Link>
                    </div>

                </div>);
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Pooled Samples",
        },
        {
            columnName: "workflow-users",
            columnFieldData: (d) => {
                return (<div onClick={async () => {
                    this.setState({ usersOpen: true, users: d.UniqueRunUsers })
                }}>
                    <div style={{ marginRight: "5px" }}>
                        <Link>
                            {d.UniqueRunUsers ? d.UniqueRunUsers.length : ""}
                        </Link>
                    </div>
                </div>);
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Users",
        },
        {
            columnName: "continue-workflow-run",
            columnFieldData: (d) => {
                let userContext = this._security.userContext;
                if (userContext && (!d.CurrentRunUser || d.CurrentRunUser === userContext.preferred_username)) {
                    return (<Button
                        className={styles.continueButtonStyle}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            NavigationService.navigateTo(urljoin("/WorkflowRun", d.Id, "step", d.CurrentOrLastStepInstanceId));
                        }}
                    >{(d.RunState === "Failed" || d.RunState === "TechnicalFailure" || d.RunState === "Completed" || d.RunState === "CompletedWithPipelineError") ? "View" : "Continue"}</Button>);
                }
                else {
                    //return a view button that actually handles view state?
                    return (<Button
                        className={styles.continueButtonStyle}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            NavigationService.navigateTo(urljoin("/WorkflowRun", d.Id, "step", d.CurrentOrLastStepInstanceId));
                        }}
                    >
                        View
                    </Button>);
                }
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Continue Run",
        }
    ];

    private readonly sampleColumns: Array<IDataTableColumn<WellContentVM>> = [
        {
            columnName: "sample-id",
            columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sample ID",
            sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
        },
        {
            columnName: "sample-date-harvested",
            columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Date Harvested",
            sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
        },
        {
            columnName: "sample-specimen-source",
            columnFieldData: (d) => (d.Sample ? d.Sample.SpecimenSource : ""),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Specimen Source",
            sortMethod: (d) => (d.Sample ? d.Sample.SpecimenSource : "") ?? ""
        },
    ];

    render() {
        const state = this.props.runsService.getState();

        const {
            PageListResults: WorkflowRunFetchResults,
            currentPage
        } = state;

        const WorkflowRunData = WorkflowRunFetchResults[currentPage].data?.Runs ?? [];
        // tslint:disable-next-line: no-console

        return (
            <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    <AjaxActionIndicator
                        state={WorkflowRunFetchResults}
                    />
                    <DataTable
                        data={WorkflowRunData}
                        columns={this.columns}
                        defaultSortColumnName={"workflow-created-date"}
                        defaultSortDirection={"desc"}
                        tableFooterComponent={(
                            <TableRow>
                                <DataTablePager
                                    count={this.props.runsService.totalCount}
                                    page={currentPage}
                                    rowsPerPage={this.props.runsService.pageSize}
                                    rowsPerPageOptions={[this.props.runsService.pageSize]}
                                    onPagerStateChange={this._onPagerStateChanged}
                                    onChangePage={() => {/*This is not actually required, but there is a typescript issue that thinks it's required */ }}
                                />
                            </TableRow>
                        )}
                    />
                    <Dialog
                        open={this.state.detailsOpen}
                        onClose={this.handleClose}
                        PaperProps={{ className: styles.dialog }}
                    >
                        {this.state.data && <SampleInfoTable
                            data={this.state.data}
                            columns={this.sampleColumns}
                            defaultSortColumnName={"sample-well-position"}
                        />}
                    </Dialog>
                    <Dialog
                        open={this.state.usersOpen}
                        onClose={this.handleClose}
                        PaperProps={{ className: styles.dialog }}
                    >
                        <Card>
                            <div style={{ width: "500px", height: "500px", textAlign: "center" }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h1 style={{ margin: "auto" }} >Run Users<Person /></h1>

                                </div>
                                {this.state.users &&
                                    _.map(this.state.users, user => <div>{user}</div>)
                                }
                            </div>
                        </Card>
                    </Dialog>
                </Card>
            </div>
        );
    }

    @bind
    private _onPagerStateChanged(page: number, rowsPerPage: number) {
        this.props.runsService.setPageNumber(page);
    }

    @bind
    private async _unlockRun(workflowRunId: string) {
        await this.props.runsService.unlockRun(workflowRunId);
        await this.props.runsService.fetchWorkflowRuns(true);
    }

    @bind
    private handleClose() {
        this.setState({ detailsOpen: false, data: undefined, usersOpen: false, users: undefined });
    }
}


export const RunsPage = RunsService.inject(
    _RunsPage
);
