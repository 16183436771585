import { ReagentGroupLotVM, ReagentGroupVM, ReagentLotVM, ReagentVM } from "$Generated/api";
import { AdvanceTextField, DataLoadingDisplay, DataTable, IDataTableColumn, ProcessAdminRole } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Dialog, TextField, MenuItem, Checkbox, Button, DialogActions, Chip, Link, FormControlLabel } from "@material-ui/core";

import {
    HorizontalSplitIcon,
    BlockIcon,
    KeyboardDatePicker
} from "$Imports/MaterialUIComponents";
import { IUserContext } from "$Utilities/Security/IUserContext";
import { LotNumberChangeLog } from "./LotNumberChangeLog";

import {
    AssetService,
    IAssetServiceInjectedProps
} from "$State/AssetFreezerService";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
    table: string;
} = require("../LabAssets.scss");

interface IEditPoolAndCustomKitBaseProps {
    handleClose: () => void,
    open: boolean,
    newReagentGroup: boolean,
    initialReagentGroup: ReagentGroupVM | undefined,
    handleSave: (reagentGroup: ReagentGroupVM) => void
    user: IUserContext | null;
}

interface IEditPoolAndCustomKitState {
    reagentGroupToEdit?: ReagentGroupVM;
    lotNumberEntry: string;
    expirationEntry: Date;
    lotEntryOpen: boolean;
    lastId: number;
    lotNumberLogOpen: boolean;
    manageComponentsOpen: boolean;
    currentReagents?: ReagentVM[];
    addReagentOpen: boolean;
}

type IEditPoolAndCustomKitProps = IEditPoolAndCustomKitBaseProps & IAssetServiceInjectedProps;

export class _EditPoolAndCustomKit extends React.Component<IEditPoolAndCustomKitProps, IEditPoolAndCustomKitState> {

    private readonly columns: Array<IDataTableColumn<ReagentVM>> = [
        {
            columnName: "reagent-in-group",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={d.Id}
                                    checked={_.find(this.state.currentReagents, r => r.Id === d.Id) !== undefined}
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onChange={() => this.updateChecked(d)}
                                    name={d.Name}
                                />
                            }
                            label={d.Name}
                        />
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ]

    state: IEditPoolAndCustomKitState = {
        lotNumberEntry: "",
        lotEntryOpen: false,
        lastId: 0,
        lotNumberLogOpen: false,
        manageComponentsOpen: false,
        addReagentOpen: false,
        expirationEntry: new Date(Date.now())
    }

    async componentDidUpdate(prevProps: IEditPoolAndCustomKitBaseProps) {
        if (this.props.initialReagentGroup && (prevProps.initialReagentGroup === undefined || (prevProps.initialReagentGroup && this.props.initialReagentGroup.Id !== prevProps.initialReagentGroup.Id))) {
            await this.props.assetService.fetchStandaloneReagents(true);
            let reagentGroup = _.cloneDeep(this.props.initialReagentGroup);
            if (this.props.newReagentGroup) {
                reagentGroup.CreatedBy = this.props.user ? this.props.user.preferred_username : "";
            }
            this.setState({ reagentGroupToEdit: reagentGroup });
        }
    }

    componentDidMount() {
    }

    render() {
        let standaloneData = this.props.assetService.freezer.get().standaloneReagentFetchResults.data;
        if (this.state.reagentGroupToEdit && standaloneData) {
            let noneActive = _.find(this.state.reagentGroupToEdit.Lots, l => l.IsActive) === undefined;
            let lessThanTwoReagents = _.find(this.state.reagentGroupToEdit.Lots, l => l.ReagentLots.length < 2) !== undefined;
            let allLotsSet = _.every(this.state.reagentGroupToEdit.Lots, l => l.ReagentLots.length === this.state.reagentGroupToEdit?.Lots[0].ReagentLots.length);
            let allUnused = _.every(this.state.reagentGroupToEdit.Lots, l => l.UsedInRun == "NotUsed");

            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div>
                        {this.state.reagentGroupToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.props.open}
                                onClose={() => { this.props.handleClose() }}
                                PaperProps={{ style: { width: "1200px" } }}
                            >
                                <div className={styles.content} style={{ marginBottom: "50px" }}>
                                    <h2>{this.state.reagentGroupToEdit.ReagentGroupType === "Pool" ? "Pool" : "Custom Kit"}</h2>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive}
                                            InputLabelProps={{ shrink: true }}
                                            label={"Name"}
                                            value={this.state.reagentGroupToEdit?.Name}
                                            onDebouncedChange={(value) => { this.setName(value) }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive}
                                            InputLabelProps={{ shrink: true }}
                                            label={"Description"}
                                            value={this.state.reagentGroupToEdit?.Description}
                                            onDebouncedChange={(value) => { this.setDescription(value) }}
                                        />
                                    </div>
                                    <h3 style={noneActive ? { color: "red" } : { color: "" }}>
                                        Active Lot/Ref Numbers {noneActive ? " - At least one must be active!" : ""}
                                    </h3>
                                    {_.filter(this.state.reagentGroupToEdit.Lots, l => l.IsActive).map(l => <Chip
                                        label={l.LotNumber}
                                        key={l.Id}
                                        onDelete={l.UsedInRun === "UsedInPrevious" ? () => { this.disableLotNumber(l.Id); } : l.UsedInRun === "NotUsed" ? () => { this.deleteLotNumber(l.Id); } : undefined}
                                        style={{ margin: "5px" }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || l.UsedInRun === "UsedInActive"}
                                        deleteIcon={l.UsedInRun === "UsedInPrevious" ? <BlockIcon /> : undefined}
                                    />)}
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive}
                                            onClick={() => {
                                                this.setState({ lotEntryOpen: true, lotNumberEntry: "" });
                                            }}
                                        >
                                            Add New Lot Number
                                        </Button>
                                        <div style={{ marginTop: "auto", marginLeft: "10px" }} onClick={async () => {
                                            this.setState({ lotNumberLogOpen: true });
                                        }}>
                                            <Link>
                                                <u>View Lot Number Change Log</u>
                                            </Link>
                                        </div>
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive || this.state.reagentGroupToEdit.Lots.length === 0}
                                        onClick={() => {
                                            if (this.state.reagentGroupToEdit) {
                                                let reagents: ReagentVM[] = [];
                                                _.forEach(this.state.reagentGroupToEdit.Lots, lot => {
                                                    _.forEach(lot.ReagentLots, (rl) => {
                                                        let reagent = _.find(standaloneData, r => r.Id === rl.ReagentId);
                                                        if (reagent && _.find(reagents, rg => rg.Id === reagent?.Id) === undefined) {
                                                            reagents.push(reagent.toJS());
                                                        }
                                                    });
                                                });
                                                this.setState({ manageComponentsOpen: true, currentReagents: reagents });
                                            }
                                        }}
                                    >
                                        Manage Components
                                    </Button>
                                    {this.state.reagentGroupToEdit.ReagentGroupType === "CustomKit" &&
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ paddingTop: "10px" }}>
                                                Use Components Independently:
                                            </div>
                                            <Checkbox disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} checked={this.state.reagentGroupToEdit?.CanUseReagentsIndependently}
                                                onChange={(event) => {
                                                    this.setCanUseIndependently(event.target.checked);
                                                }} />
                                        </div>
                                    }
                                    {(lessThanTwoReagents || !allLotsSet) &&
                                        <h3 style={{ color: "red" }}>
                                            Must have at least two reagents, and all lot numbers selected
                                        </h3>
                                    }
                                    {
                                        !this.props.newReagentGroup &&
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ paddingTop: "10px" }}>
                                                    Active:
                                                </div>
                                                <Checkbox disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} checked={this.state.reagentGroupToEdit?.IsActive}
                                                    onChange={(event) => {
                                                        this.setActive(event.target.checked);
                                                    }}
                                                />
                                            </div>
                                            {!this.state.reagentGroupToEdit?.IsActive && this.state.reagentGroupToEdit?.DeactivatedOn &&
                                                <div>
                                                    Deactivated On {moment(this.state.reagentGroupToEdit?.DeactivatedOn).format("L LT")}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || noneActive || lessThanTwoReagents || !allLotsSet || this.state.reagentGroupToEdit.Name === ""}
                                        onClick={() => {
                                            if (this.state.reagentGroupToEdit) {
                                                this.props.handleSave(this.state.reagentGroupToEdit);
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.props.handleClose() }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                        {this.state.reagentGroupToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.lotEntryOpen}
                                onClose={() => { this.setState({ lotEntryOpen: false, lotNumberEntry: "" }) }}
                                PaperProps={{ style: { width: "400px", height: "300px" } }}
                            >
                                <div className={styles.content}>
                                    <h3>{this.state.reagentGroupToEdit.ReagentGroupType === "Pool" ? "Pool" : "Custom Kit"} Name: {this.state.reagentGroupToEdit?.Name}</h3>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <AdvanceTextField
                                            label={"Lot/Reference Number"}
                                            autoFocus={true}
                                            value={this.state.lotNumberEntry}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onDebouncedChange={(value) => { this.setState({ lotNumberEntry: value }) }}
                                        />
                                        <HorizontalSplitIcon />
                                    </div>
                                    {_.find(this.state.reagentGroupToEdit.Lots, l => l.LotNumber === this.state.lotNumberEntry) !== undefined && <h3 style={{ color: "red" }}>
                                        Duplicate Lot Number Entered
                                    </h3>}
                                    <KeyboardDatePicker
                                        label="Expiration Date"
                                        value={this.state.expirationEntry}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onChange={this.onDateChanged}
                                        format="MM/DD/YY" />
                                    <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.lotNumberEntry === "" || _.find(this.state.reagentGroupToEdit.Lots, l => l.LotNumber === this.state.lotNumberEntry) !== undefined}
                                            onClick={() => {
                                                this.addLotNumber();
                                            }}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { this.setState({ lotEntryOpen: false, lotNumberEntry: "" }) }}
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        }
                        {this.state.reagentGroupToEdit &&
                            <LotNumberChangeLog
                                lotNumbers={this.state.reagentGroupToEdit.Lots}
                                name={this.state.reagentGroupToEdit.Name}
                                open={this.state.lotNumberLogOpen}
                                handleClose={() => { this.setState({ lotNumberLogOpen: false }); }}
                            />
                        }
                        {this.state.reagentGroupToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.manageComponentsOpen}
                                onClose={() => { this.setState({ manageComponentsOpen: false }) }}
                                PaperProps={{ style: { width: "600px", height: "500px" } }}
                            >
                                <div className={styles.content} style={{ marginBottom: "50px" }}>
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                        <h2>{this.state.reagentGroupToEdit.Name}</h2>
                                        <Button
                                            style={{ margin: "Auto", marginLeft: "20px" }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ addReagentOpen: true });
                                            }}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !allUnused}
                                        >
                                            Modify Reagents
                                        </Button>
                                    </div>
                                    <table>
                                        <tr>
                                            <th>
                                            </th>
                                            {_.map(this.state.reagentGroupToEdit.Lots, l => {
                                                return <th key={l.LotNumber}>{l.LotNumber}</th>;
                                            })}
                                        </tr>
                                        {_.map(this.state.currentReagents, r => {
                                            if (this.state.reagentGroupToEdit) {
                                                return <tr>
                                                    <td>{r.Name}</td>
                                                    {_.map(this.state.reagentGroupToEdit.Lots, l => {
                                                        var lot = _.find(l.ReagentLots, rl => rl.ReagentId === r.Id);
                                                        if (lot) {
                                                            return <td key={l.Id}>
                                                                <Chip
                                                                    label={lot.LotNumber}
                                                                    key={lot.LotNumber}
                                                                    onDelete={() => {
                                                                        if (lot) {
                                                                            this.removeReagentLotFromGroup(lot, l);
                                                                        }
                                                                    }}
                                                                    style={{ margin: "5px" }}
                                                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !allUnused}
                                                                />
                                                            </td>;
                                                        }
                                                        else //dropdown
                                                        {
                                                            return <td key={l.Id}>
                                                                <TextField
                                                                    select
                                                                    InputLabelProps={{ shrink: true }}
                                                                    label="Lot Number"
                                                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                                    id="lot-number"
                                                                    onChange={(event) => {
                                                                        let lot = _.find(r.Lots, rl => rl.Id === event.target.value);
                                                                        if (lot) {
                                                                            this.addReagentLotToGroup(lot, l);
                                                                        }
                                                                    }}
                                                                >
                                                                    {_.map(r.Lots, rl => {
                                                                        return (<MenuItem key={rl.Id} value={rl.Id}>{rl.LotNumber}</MenuItem>)
                                                                    })}
                                                                </TextField>
                                                            </td>;
                                                        }
                                                    })}
                                                </tr>;
                                            }
                                            return <></>;
                                        })}
                                    </table>
                                </div>
                                <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onClick={() => { this.setState({ manageComponentsOpen: false }) }}
                                    >
                                        Accept
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                        {standaloneData &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.addReagentOpen}
                                onClose={() => { this.setState({ addReagentOpen: false }) }}
                                PaperProps={{ style: { width: "400px", height: "600px", overflow: "auto" } }}
                            >
                                <div className={styles.content}>
                                    <h3>Add Reagent</h3>
                                    <div className={styles.table}>
                                        <DataTable
                                            data={standaloneData.toJS()}
                                            columns={this.columns}
                                            stickyHeader={true}
                                        />
                                    </div>
                                    <DialogActions style={{ bottom: "0px", right: "0px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => { this.setState({ addReagentOpen: false }) }}
                                        >
                                            Accept
                                        </Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        }
                    </div>
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <></>;
    }

    @bind
    private setName(value: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Name = value;
            this.setState({
                reagentGroupToEdit: newVal
            });
        }
    }

    @bind
    private setDescription(value: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Description = value;
            this.setState({
                reagentGroupToEdit: newVal
            });
        }
    }

    @bind
    private setActive(value: boolean) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.DeactivatedOn = value ? oldVal.DeactivatedOn : new Date(Date.now());
            newVal.IsActive = value;
            newVal.DeactivatedBy = value ? oldVal.DeactivatedBy : this.props.user ? this.props.user.preferred_username : "";
            this.setState({ reagentGroupToEdit: newVal });
        }
    }

    @bind
    private setCanUseIndependently(value: boolean) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.CanUseReagentsIndependently = value;
            this.setState({
                reagentGroupToEdit: newVal
            });
        }
    }

    @bind
    private addLotNumber() {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);
            lots.push(
                {
                    CreatedBy: this.props.user ? this.props.user.preferred_username : "",
                    CreatedOn: new Date(Date.now()),
                    Id: (this.state.lastId + 1).toString(),
                    IsActive: true,
                    LotNumber: this.state.lotNumberEntry,
                    UsedInRun: "NotUsed",
                    IsNew: true,
                    ReagentLots: [],
                    ReagentGroupId: oldVal.Id || "",
                    ExpirationDate: this.state.expirationEntry
                }
            )
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentGroupToEdit: newVal,
                lotEntryOpen: false,
                lotNumberEntry: "",
                lastId: this.state.lastId + 1
            });
        }
    }

    @bind
    private deleteLotNumber(id: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots).filter(l => l.Id !== id);
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentGroupToEdit: newVal
            });
        }
    }

    @bind
    private disableLotNumber(id: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);
            let lotToDisable = _.find(lots, l => l.Id === id);
            if (lotToDisable) {

                lotToDisable.IsActive = false;
                lotToDisable.DeactivatedOn = new Date(Date.now());
                lotToDisable.DeactivatedBy = this.props.user ? this.props.user.preferred_username : "";

                let newVal = _.cloneDeep(oldVal);
                newVal.Lots = lots;
                this.setState({
                    reagentGroupToEdit: newVal
                });
            }
        }
    }

    @bind
    private addReagentLotToGroup(reagentLot: ReagentLotVM, groupLot: ReagentGroupLotVM) {
        let reagentGroup = this.state.reagentGroupToEdit;
        if (reagentGroup) {
            let newGroup = _.cloneDeep(reagentGroup);
            let lot = _.find(newGroup.Lots, rgl => rgl.Id === groupLot.Id);
            if (lot) {
                lot.ReagentLots.push(reagentLot);
                this.setState({ reagentGroupToEdit: newGroup });
            }
        }
    }

    @bind
    private removeReagentLotFromGroup(reagentLot: ReagentLotVM, groupLot: ReagentGroupLotVM) {
        let reagentGroup = this.state.reagentGroupToEdit;
        if (reagentGroup) {
            let newGroup = _.cloneDeep(reagentGroup);
            let lot = _.find(newGroup.Lots, rgl => rgl.Id === groupLot.Id);
            if (lot) {
                lot.ReagentLots = _.filter(lot.ReagentLots, rl => rl.Id !== reagentLot.Id);
                this.setState({ reagentGroupToEdit: newGroup });
            }
        }
    }

    @bind
    private updateChecked(reagent: ReagentVM) {
        let oldReagents = this.state.currentReagents;
        if (oldReagents) {
            let newReagents = _.cloneDeep(oldReagents);

            if (_.find(newReagents, r => r.Id === reagent.Id) !== undefined) {

                let reagentGroup = this.state.reagentGroupToEdit;
                if (reagentGroup) {
                    let newGroup = _.cloneDeep(reagentGroup);
                    _.forEach(newGroup.Lots, gl => {
                        gl.ReagentLots = _.filter(gl.ReagentLots, rl => rl.ReagentId !== reagent.Id);
                    });
                    this.setState({ reagentGroupToEdit: newGroup });
                }
                newReagents = _.filter(newReagents, s => s.Id !== reagent.Id);
            }
            else {
                newReagents.push(reagent);
            }
            this.setState({ currentReagents: newReagents });
        }
    }

    @bind
    private onDateChanged(date: MaterialUiPickersDate) {
        if (date) {
            this.setState({ expirationEntry: date.toDate() })
        }
    }
}

export const EditPoolAndCustomKit = AssetService.inject(_EditPoolAndCustomKit);