import {
    React,
    bind,
    IAjaxState,
    Freezer
} from "$Imports/Imports";

import * as _ from "lodash";

import {
    Button,
    ArrowForwardIosIcon,
    ArrowBackIosIcon,
    IconButton,
    ListIcon,
    Dialog,
    Link
} from "$Imports/MaterialUIComponents";

import {
    WetlabRunVM, StepInstanceVM
} from "$Generated/api";
import { NavigationService } from "$State/NavigationFreezerService";

var urljoin = require('url-join');


const styles: {
    header: string;
    bar: string;
    buttonDivWidth: string;
    headerDivWidth: string;
    forwardButton: string;
    backwardButton: string;
    controlDiv: string;
    headerButtons: string;
    stepDiv: string;
    currentStepRow: string;
} = require("./SimpleStatusHeader.scss");


export interface IStatusHeaderProps {
    goToStep?: (stepId: string) => void;
    workflowRun: IAjaxState<WetlabRunVM>;
    previousStep?: StepInstanceVM;
    nextStep?: StepInstanceVM;
    currentStep: Freezer.Types.IFrozenObject<StepInstanceVM>;
    allSteps: StepInstanceVM[];
}

interface IStatusHeaderState {
}


export class SimpleStatusHeader extends React.PureComponent<IStatusHeaderProps, IStatusHeaderState> {

    state: IStatusHeaderState = {
    }

    @bind
    handleStepChange(step?: StepInstanceVM) {
        step && this.props.goToStep && this.props.goToStep(step.StepId);
    }

    render() {
        let {
            hasFetched,
            data
        } = this.props.workflowRun;

        return (
            hasFetched && data &&
            <>
                <div className={styles.controlDiv}>
                    <div className={styles.buttonDivWidth}>
                        {(this.props.previousStep ? (<Button
                            className={styles.backwardButton}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => { this.handleStepChange(this.props.previousStep) }}
                        >
                            {this.props.previousStep ? this.props.previousStep.Name : ""}
                        </Button>) : (<div></div>))}
                    </div>
                    <div className={styles.headerDivWidth}>
                        <h1 className={styles.header}>
                            {this.props.currentStep.Name}
                        </h1>
                        <h3 className={styles.header}>
                            Run {data.RunNumber} - {data.WorkflowName}
                        </h3>
                    </div>
                    <div className={styles.buttonDivWidth}>
                        {(this.props.nextStep && this.props.nextStep.StepInstanceId ? (
                            <Button
                                className={styles.forwardButton}
                                endIcon={<ArrowForwardIosIcon />}
                                onClick={() => { this.handleStepChange(this.props.nextStep) }}
                            >
                                {this.props.nextStep ? this.props.nextStep.Name : ""}
                            </Button>) : (<div></div>))}
                    </div>
                </div>
                <div className={styles.bar} />
            </>
        );
    }
}