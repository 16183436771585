// #region Imports
import {
    React,
    _,
    bind,
    ReactGA
} from "$Imports/Imports";

import {
    YaharaThemeProvider
} from "$Providers/index";

import {
    MuiThemeProvider,
    createMuiTheme,
    Theme
} from "$Imports/MaterialUIStyles";

import {
    MainLayout,
    Routing,
    ApplicationBar,
    SideNavigation,
    ApplicationFooter
} from "$Components/root-components";

import {
    ApplicationSecurityProvider
} from "$Providers/index";

import { defaultTheme } from "$Themes/index";

import { ISinglePageApplicationProps } from "@yahara/react-spa";
import { getLogger } from "@yahara/logging";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";
import { WorkflowRunService, IWorkflowRunServiceInjectedProps } from "$State/WorkflowRun/WorkflowRunFreezerService";
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import { Dialog, Card, DialogActions, Button } from "$Imports/MaterialUIComponents";
import { IPipelineServiceInjectedProps, PipelineService } from "$State/PipelineFreezerService";
import { urlsToHideSideNav, urlsToHideLaunchPadButton } from "$Imports/CommonComponents";
// #endregion Imports

// Include the global styles
require("./css/global.scss");


const styles = require("./Application.scss") as {
    active: string;
    link: string;
    logo: string;
    main: string;
    navbar: string;
    page: string;
    pageOuter: string;
    toolbar: string;
    card: string;
    feedbackUrl: string;
};

const logger = getLogger("Application");
const logo = require("./images/logo.png") as string;
const logoFavicon = require("./images/favicon.ico") as string;

export interface IApplicationState {
    showFeedbackDialog: boolean;
}


type IApplicationProps = ISinglePageApplicationProps & IWorkflowRunServiceInjectedProps & ISettingsServiceInjectedProps & IPipelineServiceInjectedProps;


export class _Application extends React.Component<IApplicationProps, IApplicationState> {

    constructor(props: IApplicationProps) {
        super(props);
        this.state = { showFeedbackDialog: false };
    }

    async componentDidMount() {
        await this.props.settingsService.fetchGATrackingId();
        this.props.settingsService.fetchLimsSet(true);

        //Initiate Google Analytics for LimsLite Portal
        const trackingId = this.props.settingsService.freezer.get().GATrackingIdResults.data!;
        if (trackingId.GATrackingId) {
            ReactGA.initialize(trackingId.GATrackingId);
            ReactGA.ga('set', 'checkProtocolTask', null);
        }
    }

    render() {
        this.props.setFaviconUrl(logoFavicon);
        const materialUITheme: Theme = createMuiTheme(defaultTheme.themeOptions);
        const limsSet = this.props.settingsService.getState().isLimsSetResults.data;

        logger.info("Rendering application");

        return (
            <MuiThemeProvider
                theme={materialUITheme}
            >
                <MuiPickersUtilsProvider
                    utils={MomentUtils}
                >
                    <ApplicationSecurityProvider>
                        <YaharaThemeProvider
                            themeConfig={defaultTheme}
                        >
                            <ApplicationSecurityContext.Consumer>
                                {value => value.applicationContext.userContext && (
                                    <MainLayout
                                        applicationBar={(
                                            <ApplicationBar
                                                user={value.applicationContext.userContext}
                                                logout={() => {
                                                    if (this.props.settingsService.getState().userPreferenceResults.data?.GiveFeedback) {
                                                        this.setState({ showFeedbackDialog: true });
                                                    } else {
                                                        value.logout();
                                                    }
                                                }}
                                                showLaunchpadButton={!urlsToHideLaunchPadButton.some(urlPart => window.location.pathname.toLowerCase().startsWith(urlPart.toLowerCase()))}
                                            />
                                        )}
                                        applicationFooter={(
                                            <ApplicationFooter />
                                        )}
                                        sideNavigation={<SideNavigation />}
                                        showSideNav={!urlsToHideSideNav.some(urlPart => window.location.pathname.toLowerCase().startsWith(urlPart.toLowerCase()))}
                                    >
                                        <Routing
                                            authContext={value}
                                        />
                                        <Dialog open={this.state.showFeedbackDialog}>
                                            <Card className={styles.card}>
                                                <h3 className={styles.feedbackUrl}>
                                                    Click <a onClick={value.logout} href={this.props.settingsService.getState().demoEnvironmentInformation.data?.FeedbackUrl || ""} target="_blank">here</a> to provide feedback.
                                                </h3>
                                            </Card>
                                            <DialogActions>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={value.logout}
                                                >
                                                    Logout Without Feedback
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </MainLayout>
                                )}
                            </ApplicationSecurityContext.Consumer>
                        </YaharaThemeProvider>
                    </ApplicationSecurityProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

export const Application = PipelineService.inject(WorkflowRunService.inject(SettingsService.inject(_Application)));