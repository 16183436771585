import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _,
    bind,
    ReactGA
} from "$Imports/Imports";

import {
    WorkflowScreens
} from "../../components/WorkflowStep/WorkflowStep";
import { NavigationService } from "$State/NavigationFreezerService";
var urljoin = require('url-join');

import {
    WorkflowRunApiFactory,
    PlateStatus,
    PlateApiFactory,
    StepApiFactory,
    PCRApiFactory,
    PoolStatus,
    PoolApiFactory,
    PopulatePoolFromPlateRequestVM,
    LookupValueVM,
    SequencingStatus,
    QCApiFactory,
    QuantAfterEndRepairQCRequest,
    StepInstanceVM,
    AssetManagementApiFactory,
    ComponentSetItemVM,
    ComponentSetVM,
    ComponentSetItemInstanceVM,
    ReagentGroupType,
    WorkflowRunApiFetchParamCreator,
    RackApiFactory,
    WorkflowRunAssetApiFactory,
    AssetType,
    RackStatus,
    ChunkedUploadVM,
    FileShareApiFactory,
    WorkflowApiFactory,
    MinitMinderApiFactory
} from "$Generated/api";

import { IWorkflowRunState, initialState, InjectedPropName } from "./IWorkflowRunState";
import { apiExceptionHandler } from "$State/ErrorFreezerService";



export class WorkflowRunExternalFreezerService extends FreezerService<IWorkflowRunState, typeof InjectedPropName> {
    constructor() {
        super(initialState, InjectedPropName);
    }

    set workflowId(WorkflowRunId: string) {
        this.freezer.get().set({ workflowRunId: WorkflowRunId });
    }

    set selectedStepInstanceId(stepInstanceId: string) {
        //New pageview call to GA Tracking
        let {
            stepInstances
        } = this.freezer.get();

        if (stepInstances.hasFetched && stepInstances.data) {
            var stepData = _.find(stepInstances.data, (step) => {
                return step.StepInstanceId === stepInstanceId
            })
            if (stepData && WorkflowScreens[stepData.StepTypeId] && WorkflowScreens[stepData.StepTypeId].path) {
                ReactGA.pageview(WorkflowScreens[stepData.StepTypeId].path);
            }
        }

        //Changing the selectedStepID should reset the plates, as we won't be looking at the same ones. 
        //If the full plate data is needed then we can see it hasn't been fetched
        this.freezer.get().set({ selectedStepInstanceId: stepInstanceId, plates: managedAjaxUtil.createInitialState() });
    }

    get currentStep() {
        let {
            stepInstances,
            selectedStepInstanceId
        } = this.freezer.get();

        if (stepInstances.hasFetched && stepInstances.data) {
            return _.find(stepInstances.data, (step) => {
                return step.StepInstanceId === selectedStepInstanceId
            })
        }
        return null;
    }

    get currentWorkflowRun() {
        let {
            workflowRun,
        } = this.freezer.get();

        if (workflowRun.hasFetched && workflowRun.data) {
            return workflowRun.data
        }
        return null;
    }

    public resetCurrentWorkflowRun() {
        this.freezer.get().set({ workflowRun: managedAjaxUtil.createInitialState() })
    }



    public async fetchWorkflowRun(forceUpdate?: boolean) {
        const { workflowRun, workflowRunId } = this.freezer.get();

        if (workflowRunId && (!workflowRun.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'workflowRun',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunIdGet({ id: workflowRunId })
                },
                onError: apiExceptionHandler
            });
            let {
                workflowRun,
                selectedStepInstanceId
            } = this.freezer.get();

            if (workflowRun.hasFetched && workflowRun.data) {
                await this.fetchStepInstances(true);
            }
        }
    }

    public async fetchWorkflowRunSequencingStatus(forceUpdate?: boolean) {
        const { fetchSequencingStatusState, workflowRunId } = this.freezer.get();

        if (workflowRunId && (!fetchSequencingStatusState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchSequencingStatusState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunSequencingStatusIdGet({ id: workflowRunId })
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async fetchWorkflowRunSequencingData(forceUpdate?: boolean) {
        const { fetchSequencingDataState, workflowRunId } = this.freezer.get();

        if (workflowRunId && (!fetchSequencingDataState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchSequencingDataState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet({ workflowRunId: workflowRunId })
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async fetchStepInstances(forceUpdate?: boolean) {
        const { stepInstances, workflowRunId } = this.freezer.get();

        if (workflowRunId && (!stepInstances.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'stepInstances',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet({ workflowRunId: workflowRunId })
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async fetchStepInstanceCustomFields() {
        const { stepInstances, workflowRunId, selectedStepInstanceId } = this.freezer.get();

        if (workflowRunId && selectedStepInstanceId) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchStepCustomFields',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet({ stepInstanceId: selectedStepInstanceId })
                },
                onError: apiExceptionHandler
            });

            //replace custom fields in current step instances
            const newData = this.freezer.get().fetchStepCustomFields.data;
            if (stepInstances.data && newData) {

                const updatedSteps = _.map(stepInstances.data, s => {
                    const newModel: StepInstanceVM = _.clone(s.toJS());

                    if (newModel.StepInstanceId === selectedStepInstanceId) {
                        newModel.CustomFields = newData;
                    }

                    return newModel;
                });

                stepInstances.data.set(updatedSteps);
            }
        }
    }

    @bind
    public async fetchRacksForStep(forceUpdate?: boolean) {
        const { racks, selectedStepInstanceId, workflowRunId } = this.freezer.get();
        if (selectedStepInstanceId && workflowRunId && (!racks.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'racks',
                onExecute: (apiOptions, param, options) => {
                    let factory = RackApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1RackGetAssetsForStepStepInstanceIdGet({ stepInstanceId: selectedStepInstanceId, workflowRunId: workflowRunId });
                },
                onError: apiExceptionHandler
            })
        }
    }

    @bind
    public async fetchPlatesForStep(forceUpdate?: boolean) {
        const { plates, selectedStepInstanceId, workflowRunId } = this.freezer.get();
        if (selectedStepInstanceId && workflowRunId && (!plates.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'plates',
                onExecute: (apiOptions, param, options) => {
                    let factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1PlateGetPlatesForStepStepInstanceIdGet({ stepInstanceId: selectedStepInstanceId, workflowRunId: workflowRunId });
                },
                onError: apiExceptionHandler
            })
        }
    }


    @bind
    public async fetchInputPoolsForStep(forceUpdate?: boolean) {
        const { inputPools, selectedStepInstanceId, workflowRunId } = this.freezer.get();
        if (selectedStepInstanceId && workflowRunId && (!inputPools.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'inputPools',
                onExecute: (apiOptions, param, options) => {
                    let factory = PoolApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1PoolGetInputPoolsForStepStepInstanceIdGet({ stepInstanceId: selectedStepInstanceId, workflowRunId: workflowRunId });
                },
                onError: apiExceptionHandler
            })
        }
    }

    @bind
    public async fetchAssetsForStep(forceUpdate?: boolean) {
        const { workflowAssets, selectedStepInstanceId, workflowRunId } = this.freezer.get();
        if (selectedStepInstanceId && workflowRunId && (!workflowAssets.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'workflowAssets',
                onExecute: (apiOptions, param, options) => {
                    let factory = WorkflowRunAssetApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet({ stepInstanceId: selectedStepInstanceId, workflowRunId: workflowRunId });
                },
                onError: apiExceptionHandler
            })
        }
    }

    public async fetchPCRForStep(forceUpdate?: boolean) {
        const { pcrInfoState, selectedStepInstanceId } = this.freezer.get();
        if (selectedStepInstanceId && (!pcrInfoState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'pcrInfoState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet({ stepInstanceId: selectedStepInstanceId });
                },
                onError: apiExceptionHandler
            })
        }
    }

    public async fetchMasterMixForStep(forceUpdate?: boolean) {
        const { masterMixInfoState, selectedStepInstanceId, fetchWorkflowReagentsResults, workflowRun } = this.freezer.get();
        if (selectedStepInstanceId && (!masterMixInfoState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'masterMixInfoState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet({ workflowRunId: workflowRun.data?.Id, stepInstanceId: selectedStepInstanceId, type: "ReagentMix" });
                },
                onError: apiExceptionHandler
            })
        }

        if (!fetchWorkflowReagentsResults.hasFetched || forceUpdate) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchWorkflowReagentsResults',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementWorkflowReagentsGet({ workflowId: workflowRun.data?.WorkflowId });
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async fetchReagentListForStep(forceUpdate?: boolean) {
        const { masterMixInfoState, selectedStepInstanceId, fetchWorkflowReagentsResults, workflowRun } = this.freezer.get();
        if (selectedStepInstanceId && (!masterMixInfoState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'masterMixInfoState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet({ workflowRunId: workflowRun.data?.Id, stepInstanceId: selectedStepInstanceId, type: "ReagentList" });
                },
                onError: apiExceptionHandler
            })
        }

        if (!fetchWorkflowReagentsResults.hasFetched || forceUpdate) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchWorkflowReagentsResults',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementWorkflowReagentsGet({ workflowId: workflowRun.data?.WorkflowId });
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async fetchSimplifiedReagentListForStep(forceUpdate?: boolean) {
        const { masterMixInfoState, selectedStepInstanceId, fetchWorkflowReagentsResults, workflowRun } = this.freezer.get();
        if (selectedStepInstanceId && (!masterMixInfoState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'masterMixInfoState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet({ workflowRunId: workflowRun.data?.Id, stepInstanceId: selectedStepInstanceId, type: "SimplifiedReagentList" });
                },
                onError: apiExceptionHandler
            })
        }

        if (!fetchWorkflowReagentsResults.hasFetched || forceUpdate) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchWorkflowReagentsResults',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementWorkflowReagentsGet({ workflowId: workflowRun.data?.WorkflowId });
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async fetchCleaningDataForStep(forceUpdate?: boolean) {
        const { cleaningDataState, selectedStepInstanceId, fetchWorkflowReagentsResults, workflowRun } = this.freezer.get();
        if (selectedStepInstanceId && (!cleaningDataState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'cleaningDataState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet({ workflowRunId: workflowRun.data?.Id, stepInstanceId: selectedStepInstanceId, type: "InstructionSet" });
                },
                onError: apiExceptionHandler
            });
        }

        if (!fetchWorkflowReagentsResults.hasFetched || forceUpdate) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchWorkflowReagentsResults',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementWorkflowReagentsGet({ workflowId: workflowRun.data?.WorkflowId });
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async addComment(stepInstanceId: string, userId: string, remarks: string) {

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'addCommentState',
            onExecute: (apiOptions, params, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunCreateNotePost(params);
            },
            params: {
                stepInstanceId: stepInstanceId,
                userId: userId,
                remarks: remarks,
            },
            onError: apiExceptionHandler
        });

        //add comment record - we don't actually need the ID

        if (this.currentStep && this.currentStep.Notes) {
            //update plate status locally
            let currentStepNotes = this.currentStep.Notes.toJS();
            currentStepNotes.push({
                Id: "",
                Remarks: remarks,
                UserId: userId
            });

            this.currentStep.set({ Notes: currentStepNotes });
        }
        await this.fetchStepInstances(true);
    }

    public async failRun(stepInstanceId: string, userId: string, remarks: string, WorkflowRunId: string, plateId: string) {

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'failRunState',
            onExecute: (apiOptions, params, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunFailRunPost(params);
            },
            params: {
                stepInstanceId: stepInstanceId,
                userId: userId,
                remarks: remarks,
                workflowRunId: WorkflowRunId,
                plateId: plateId
            },
            onError: apiExceptionHandler
        });
    }

    public async updatePlateStatus(plateIds: string[], status: PlateStatus, fetchSteps: boolean = true) {

        if (this.currentWorkflowRun) {
            const body =
            {
                Ids: plateIds,
                UpdateStatus: status,
                RunNumber: this.currentWorkflowRun.RunNumber,
                WorkflowRunId: this.currentWorkflowRun.Id
            }

            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'updatePlateStatusState',
                onExecute: (apiOptions, params, options) => {
                    const factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1PlateUpdatePlateStatusPost(params);
                },
                params: {
                    body: body
                },
                onError: apiExceptionHandler
            });

            if (this.currentStep) {
                //update plate status locally
                let currentStepAssets = this.currentStep.InputAssets?.toJS();
                _.forEach(plateIds, plateId => {
                    let plate = _.find(currentStepAssets, asset => asset.Id === plateId);
                    if (plate && plate.PlateSummary) {
                        plate.PlateSummary.Status = status;

                    }
                });
                this.currentStep.set({ InputAssets: currentStepAssets });
                const { stepInstances } = this.freezer.get();
                const updatedStepInstances = _.map(stepInstances.data, step => {
                    const newModel: StepInstanceVM = _.clone(step.toJS());
                    if (step.Status == "Completed") {
                        _.forEach(plateIds, plateId => {
                            let plate = _.find(newModel.InputAssets, asset => asset.Id === plateId);
                            if (plate && plate.PlateSummary) {
                                plate.PlateSummary.Status = status;
                            }
                        })
                    }
                    return newModel;
                });
                stepInstances.data?.set(updatedStepInstances);
            }
        }
    }

    public async updateAssetStatus(assetIds: string[], status: string, assetType: AssetType) {

        if (this.currentWorkflowRun) {
            const body =
            {
                Ids: assetIds,
                UpdatePlateStatus: status as PlateStatus,
                UpdateRackStatus: status as RackStatus,
                AssetType: assetType,
                RunNumber: this.currentWorkflowRun.RunNumber,
                WetlabRunId: this.currentWorkflowRun.Id
            }

            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'updatePlateStatusState',
                onExecute: (apiOptions, params, options) => {
                    const factory = WorkflowRunAssetApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunAssetUpdateWorkflowRunAssetsStatusPost(params);
                },
                params: {
                    body: body
                },
                onError: apiExceptionHandler
            });

            if (this.currentStep) {
                //update asset status locally
                let currentStepAssets = this.currentStep.InputAssets?.toJS();
                _.forEach(assetIds, assetId => {
                    let asset = _.find(currentStepAssets, asset => asset.Id === assetId);
                    if (asset && asset.PlateSummary) {
                        asset.PlateSummary.Status = status as PlateStatus;
                    }
                    if (asset && asset.RackSummary) {
                        asset.RackSummary.Status = status as RackStatus;
                    }
                });
                this.currentStep.set({ InputAssets: currentStepAssets });
                const { stepInstances } = this.freezer.get();
                const updatedStepInstances = _.map(stepInstances.data, step => {
                    const newModel: StepInstanceVM = _.clone(step.toJS());
                    if (step.Status == "Completed") {
                        _.forEach(assetIds, assetId => {
                            let asset = _.find(newModel.InputAssets, asset => asset.Id === assetId);
                            if (asset && asset.PlateSummary) {
                                asset.PlateSummary.Status = status as PlateStatus;
                            }
                            if (asset && asset.RackSummary) {
                                asset.RackSummary.Status = status as RackStatus;
                            }
                        })
                    }
                    return newModel;
                });
                stepInstances.data?.set(updatedStepInstances);
            }
        }
    }

    public async savePlateWellVolume(plateId: string, newVolume: number) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "savePlateWellVolumeState",
            onExecute: (apiOptions, params, options) => {
                const factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch(params);
            },
            params: {
                plateId: plateId,
                volume: newVolume
            },
            onError: apiExceptionHandler
        });
    }

    public async changePoolStatus(poolIds: string[], status: PoolStatus, fetchSteps: boolean = true) {

        if (this.currentWorkflowRun) {
            const body =
            {
                Ids: poolIds,
                UpdateStatus: status,
                RunNumber: this.currentWorkflowRun.RunNumber
            }

            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'changePoolStatusState',
                onExecute: (apiOptions, params, options) => {
                    const factory = PoolApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1PoolChangePoolStatusPatch(params);
                },
                params: {
                    body: body
                },
                onError: apiExceptionHandler
            });

            if (this.currentStep) {
                //update plate status locally
                let currentStepAssets = this.currentStep.InputAssets?.toJS();
                _.forEach(poolIds, p => {
                    let pool = _.find(currentStepAssets, a => a.Id === p);
                    if (pool && pool.PoolSummary) {
                        pool.PoolSummary.Status = status;

                    }
                });
                this.currentStep.set({ InputAssets: currentStepAssets });
            }
        }
    }

    public async savePoolVolume(poolId: string, newVolume: number) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "savePoolVolumeState",
            onExecute: (apiOptions, params, options) => {
                const factory = PoolApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PoolPoolIdUpdatePoolVolumeVolumePatch(params);
            },
            params: {
                poolId: poolId,
                volume: newVolume
            },
            onError: apiExceptionHandler
        });
    }

    public async populatePoolFromPlate(request: PopulatePoolFromPlateRequestVM) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "populatePoolFromPlateResponse",
            onExecute: (apiOptions, params, options) => {
                const factory = PoolApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PoolPopulatePoolFromPlatePost(params);
            },
            params: {
                body: request
            },
            onError: apiExceptionHandler
        });
    }

    public async saveMasterMix(addUsedLots: boolean) {
        let masterMix = this.getState().masterMixInfoState;
        let currentWorkflowRun = this.currentWorkflowRun;
        if (currentWorkflowRun && masterMix && masterMix.data) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'saveMasterMixResponse',
                onExecute(apiOptions, params, options) {
                    let factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunSaveComponentSetsPut(params)
                },
                params: {
                    workflowRunId: currentWorkflowRun.Id,
                    addUsedLots: addUsedLots,
                    body: masterMix.data,
                },
                onError: apiExceptionHandler
            })
        }
    }

    public async saveCleaningData(addUsedLots: boolean) {
        let cleaningData = this.getState().cleaningDataState;
        let currentWorkflowRun = this.currentWorkflowRun;
        if (currentWorkflowRun && cleaningData && cleaningData.data) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: "saveCleaningDataReponse",
                onExecute(apiOptions, params, options) {
                    let factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunSaveComponentSetsPut(params)
                },
                params: {
                    workflowRunId: currentWorkflowRun.Id,
                    addUsedLots: addUsedLots,
                    body: cleaningData.data
                },
                onError: apiExceptionHandler
            })
        }
    }


    public async updateCustomFields() {

        let currentStep = this.currentStep;
        if (currentStep) {
            let customFields = currentStep.CustomFields;
            let fields = _.map(customFields, (val, key) => { return { Key: key, Value: val } })
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'updateCustomFieldsResponse',
                onExecute(apiOptions, params, options) {
                    let factory = StepApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1StepStepInstanceIdCustomFieldsPatch(params);
                },
                params: {
                    stepInstanceId: currentStep.StepInstanceId,
                    body: fields
                },
                onError: apiExceptionHandler
            })
        }
    }

    public async completeStep(regrid: boolean = false) {
        if (this.getState().isCompletingStep) {
            return;
        }
        this.freezer.get().set({ isCompletingStep: true });
        const currentWorkflowRun = this.currentWorkflowRun;
        const currentStep = this.currentStep;
        if (currentWorkflowRun && currentStep) {
            const body =
            {
                StepInstanceId: currentStep.StepInstanceId,
                WorkflowRunId: currentWorkflowRun.Id,
                RegridUsingPassedSamples: regrid
            }

            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'completeStep',
                onExecute: (apiOptions, params, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunCompleteStepPost(params);
                },
                params: {
                    body: body
                },
                onError: apiExceptionHandler
            });

            let state = this.getState().completeStep;
            if (state.hasFetched && state.data && state.data.StepInstanceId) {
                await this.fetchStepInstances(true);
                this.selectedStepInstanceId = state.data.StepInstanceId;
                NavigationService.navigateTo(urljoin("/WorkflowRun", this.currentWorkflowRun?.Id , "step", state.data.StepInstanceId));
            }
            this.freezer.get().set({ isCompletingStep: false });
        }
    }

    public async completeWorkflow(errored: boolean) {
        if (this.getState().isCompletingStep) {
            return;
        }
        this.freezer.get().set({ isCompletingStep: true });
        const currentWorkflowRun = this.currentWorkflowRun;
        const currentStep = this.currentStep;
        if (currentWorkflowRun && currentStep) {
            const body =
            {
                StepInstanceId: currentStep.StepInstanceId,
                WorkflowRunId: currentWorkflowRun.Id,
            }

            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'completeWorkflow',
                onExecute: (apiOptions, params, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunCompleteWorkflowPost(params);
                },
                params: {
                    body: body,
                    errored: errored
                },
                onError: apiExceptionHandler
            });
        }

        await this.fetchStepInstances(true);
        this.selectedStepInstanceId = _.last(this.getState().stepInstances.data)?.StepInstanceId || "";
        this.freezer.get().set({ isCompletingStep: false });
    }

    public async autoDiscardRetainedAssets() {
        const currentWorkflowRun = this.currentWorkflowRun;
        if (currentWorkflowRun) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'discardRetainedAssets',
                onExecute: (apiOptions, params, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunDiscardRetainedPlatesPost(params);
                },
                params: {
                    workflowRunId: currentWorkflowRun.Id,
                },
                onError: apiExceptionHandler
            });
        }
    }


    //#endregion

    public async getExtractionReagents(forceUpdate?: boolean) {

        const { getExtractionReagentsState, workflowRunId } = this.freezer.get();

        if (workflowRunId && (!getExtractionReagentsState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'getExtractionReagentsState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetExtractionReagentsGet();
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async getConcentrationControls(forceUpdate?: boolean) {

        const { getConcentrationControlsState, workflowRunId } = this.freezer.get();

        if (workflowRunId && (!getConcentrationControlsState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'getConcentrationControlsState',
                onExecute: (apiOptions, param, options) => {
                    const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetConcentrationControlKitsGet();
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async getRtPCRResults(plateId: string, forceUpdate?: boolean) {

        const { getRtPCRState, workflowRunId } = this.freezer.get();

        if (workflowRunId && (!getRtPCRState.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'getRtPCRState',
                onExecute: (apiOptions, param, options) => {
                    const factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1PlateGetPlateRtPCRResultsPlateIdGet({ plateId: plateId });
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async getPreviousStepControlsCounts(stepInstanceId: string) {

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'previousStepControlsCount',
            onExecute: (apiOptions, params, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet(params);
            },
            params: {
                stepInstanceId: stepInstanceId
            },
            onError: apiExceptionHandler
        });
    }

    @bind
    public updateCustomField(key: string, value: string) {
        if (this.currentStep && this.currentStep.CustomFields) {
            const dictionary = this.currentStep.CustomFields.toJS()
            dictionary[key] = value;
            this.currentStep.set({ CustomFields: dictionary });
        }
    }

    @bind
    public updatePCRName(name: string) {
        const { pcrInfoState } = this.freezer.get();
        if (pcrInfoState.data) {
            pcrInfoState.data.set({ InstrumentNameValue: name });
        }
    }

    @bind
    public updateMasterMixReagents(reagents: ComponentSetItemInstanceVM[], index: number) {
        const { masterMixInfoState } = this.freezer.get();
        if (masterMixInfoState.data && masterMixInfoState.data[index].ComponentSetInstances) {
            (masterMixInfoState.data[index].ComponentSetInstances || [])[0].ComponentSetItemInstances.set(reagents);
        }
    }

    @bind
    public updateCleaningDataSteps(reagents: ComponentSetItemInstanceVM[]) {

        const { cleaningDataState } = this.freezer.get();
        if (cleaningDataState.data && cleaningDataState.data[0].ComponentSetInstances) {
            cleaningDataState.data[0].ComponentSetInstances[0].ComponentSetItemInstances.set(reagents);
        }
    }


    @bind
    public async savePCRInfo() {
        const pcrInfoState = this.getState().pcrInfoState;
        let currentStep = this.currentStep;
        if (currentStep && pcrInfoState && pcrInfoState.data) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'savePCRResponse',
                onExecute(apiOptions, params, options) {
                    let factory = PCRApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1PCRUpdatePCRForStepStepInstanceIdPut(params)
                },
                params: {
                    stepInstanceId: currentStep.StepInstanceId,
                    body: pcrInfoState.data
                },
                onError: apiExceptionHandler
            })
        }
    }

    @bind
    public async updateSequencingStatus(newStatus: SequencingStatus) {
        let currentWorkflowRun = this.currentWorkflowRun;
        if (currentWorkflowRun) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'updateSequencingStatusResponse',
                onExecute(apiOptions, params, options) {
                    let factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunUpdateWorkflowRunSequencingStatusPost(params)
                },
                params: {
                    workflowRunId: currentWorkflowRun.Id,
                    newStatus: newStatus
                },
                onError: apiExceptionHandler
            })
        }
    }

    @bind
    public async quantAfterEndRepairQC(request: QuantAfterEndRepairQCRequest) {
        const x = this.getState().quantificationAfterEndRepairQCResponse;
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'quantificationAfterEndRepairQCResponse',
            onExecute(apiOptions, params, options) {
                let factory = QCApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1QCQuantAfterEndRepairQCPost(params)
            },
            params: {
                body: request
            },
            onError: apiExceptionHandler
        });
    }

    @bind
    public async fetchRunUsers(forceUpdate?: boolean) {
        const { fetchRunUsers, workflowRunId } = this.freezer.get();

        if (workflowRunId && (!fetchRunUsers.hasFetched || forceUpdate)) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchRunUsers',
                onExecute: (apiOptions, param, options) => {
                    let factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunGetRunUsersGet({ workflowRunId: workflowRunId });
                },
                onError: apiExceptionHandler
            });
        }
    }

    @bind
    public async setCurrentRunUser(userId: string) {
        const { workflowRunId } = this.freezer.get();

        if (workflowRunId) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'setCurrentRunUser',
                onExecute: (apiOptions, param, options) => {
                    let factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunSetCurrentRunUserPost({
                        body:
                        {
                            IsCurrentUser: true,
                            UserId: userId,
                            WorkflowRunId: workflowRunId
                        }
                    });
                },
                onError: apiExceptionHandler
            });
        }
    }

    @bind
    public async fetchInstrumentOptions() {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'fetchInstrumentOptions',
            onExecute: (apiOptions, param, options) => {
                let factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunInstrumentOptionsGet();
            },
            onError: apiExceptionHandler
        });
    }

    public async addInstrument(instrument: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'addInstrumentResult',
            onExecute: (apiOptions, param, options) => {
                let factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunAddInstrumentPost({ instrument: instrument });
            },
            onError: apiExceptionHandler
        });
    }

    @bind
    public async fetchBarcodeKits(activeOnly: boolean, type: ReagentGroupType, forceUpdate?: boolean) {
        const { fetchBarcodeKits } = this.freezer.get();

        if (!fetchBarcodeKits.hasFetched || forceUpdate) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchBarcodeKits',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementReagentGroupsGet({ type: type, activeOnly: activeOnly });
                },
                onError: apiExceptionHandler
            });
        }
    }

    @bind
    public async fetchBarcodeReagents(forceUpdate?: boolean) {
        const { fetchBarcodeReagents } = this.freezer.get();

        if (!fetchBarcodeReagents.hasFetched || forceUpdate) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchBarcodeReagents',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementReagentsGet({ type: "BarcodeReagent" });
                },
                onError: apiExceptionHandler
            });
        }
    }

    @bind
    public async fetchWorkflowRunBarcodes(forceUpdate?: boolean) {
        const { workflowRunId, fetchWorkflowRunBarcodes } = this.freezer.get();

        if (!fetchWorkflowRunBarcodes.hasFetched || forceUpdate) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchWorkflowRunBarcodes',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementWorkflowRunBarcodesGet({ workflowRunId: workflowRunId });
                },
                onError: apiExceptionHandler
            });
        }
    }

    @bind
    public async associateBarcodeKitToRun() {
        const { workflowRunId, useBarcodeKit, barcodeReagentMap } = this.freezer.get();
        if (useBarcodeKit && barcodeReagentMap) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'associateBarcodeKitToRun',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementAssociateBarcodeKitToRunPost(
                        {
                            body: barcodeReagentMap.toJS(),
                            workflowRunId: workflowRunId,
                        })
                },
                onError: apiExceptionHandler
            });


        }
    }

    public async getSampleSheet(workflowRunId: string, stepInstanceId: string, isCOV2: boolean) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "sampleSheetResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunGenerateIlluminaSampleSheetGet({ workflowRunId, stepInstanceId, isCOV2 });
            },
        });
    }

    public async releaseRun(workflowRunId: string, release: boolean) {

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'releaseRunResult',
            onExecute: (apiOptions, params, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunReleaseRunPost(params);
            },
            params: {
                workflowRunId: workflowRunId,
                releaseRun: release
            },
            onError: apiExceptionHandler
        });
    }    

    public async initializeChunkedUpload(path: string, chunks: number, chunkSize: number, name: string, size: number, fileType: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "initializeUploadResult",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareInitializeChunkedUploadPathPost({
                    path: path, body:
                    {
                        Chunks: chunks,
                        ChunkSize: chunkSize,
                        Name: name,
                        Size: size,
                        Type: fileType
                    }
                });
            }
        });
    }

    public async getFileShareDirectories(path: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "fileShareDirectories",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                if (path === "") {
                    return factory.apiV1FileShareGetRootDirectoriesGet();
                } else {
                    return factory.apiV1FileShareGetDirectoriesPathGet({path: path});
                }
            },
        });
    }

    public async exportBugSeqCsv(runId: string, runHash: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "exportBugSeqCsvResult",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunExportBugSeqCsvPost({ workflowRunId: runId, runHash: runHash});
            },
        });
    }
    
    public async exportBugSeqCsvExtended(runId: string, runHash: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "exportBugSeqCsvResult",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunExportBugSeqCsvExtendedPost({ workflowRunId: runId, runHash: runHash});
            },
        });
    }

    public async getMinderJobs() {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "minderJobStatus",
            onExecute: (apiOptions, param, options) => {
                const factory = MinitMinderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinitMinderJobStatusGet();
            },
        });
    }

    @bind
    public async createPdfReport(runNumber: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "generateReportResult",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowGeneratePdfReportRunNumberPost({runNumber: runNumber});
            }
        });
    }

    @bind
    public async fetchWorkflowRunMetadata(forceUpdate?: boolean) {
      const { workflowRunMetadata, workflowRunId } = this.freezer.get();
      if (workflowRunId && (!workflowRunMetadata.hasFetched || forceUpdate)) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "workflowRunMetadata",
            onExecute: (apiOptions, param, options) => {
              const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
              return factory.apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet({workflowRunId: workflowRunId});
            }
        });
      }
    }

    @bind
    //Reset everything at once, this helps prevent any missed cases if we were to do it per step.
    public resetInternalData() {
        this.freezer.get().set(
            {
                cleaningDataState: managedAjaxUtil.createInitialState(),
                inputPools: managedAjaxUtil.createInitialState(),
                getConcentrationControlsState: managedAjaxUtil.createInitialState(),
                getRtPCRState: managedAjaxUtil.createInitialState(),
                fetchSequencingDataState: managedAjaxUtil.createInitialState(),
                fetchStepCustomFields: managedAjaxUtil.createInitialState(),
                masterMixInfoState: managedAjaxUtil.createInitialState(),
                pcrInfoState: managedAjaxUtil.createInitialState(),
                plates: managedAjaxUtil.createInitialState(),
                fetchBarcodeKits: managedAjaxUtil.createInitialState(),
                barcodesInUse: [],
                useBarcodeKit: true,
                barcodeReagentMap: [],
                barcodeReagentGroupId: "",
                racks: managedAjaxUtil.createInitialState(),
                fetchSequencingStatusState: managedAjaxUtil.createInitialState(),
                workflowRunMetadata: managedAjaxUtil.createInitialState()
            }
        );
    }

}
