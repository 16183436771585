import {
  React,
  bind
} from "$Imports/Imports";

import {
  Card,
  Button
} from "$Imports/MaterialUIComponents";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
    ErrorService,
    IErrorServiceInjectedProps
} from "$State/ErrorFreezerService";

interface IWebErrorAPIPageBaseProps {
}

export type IWebErrorAPIPageProps = IWebErrorAPIPageBaseProps & IErrorServiceInjectedProps;

const styles: {
  mainContainer: string;
  cardStyle: string;
} = require("./NoAccess.scss");

export class _NoAccessPage extends React.Component<IWebErrorAPIPageProps> {

    componentDidMount() {
    }

  render() {
    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <p>
            You do not have access to this page.
          </p>
        </Card>
      </div>
    );
  }
}
export const NoAccessPage = ErrorService.inject(_NoAccessPage);