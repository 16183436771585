import Home from "@material-ui/icons/Home";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import DataUsage from "@material-ui/icons/DataUsage";
import Dashboard from "@material-ui/icons/Dashboard";
import People from "@material-ui/icons/People";
import Settings from "@material-ui/icons/Settings";
import Help from "@material-ui/icons/Help";
import CloudQueueOutlined from "@material-ui/icons/CloudQueueOutlined";
import Cloud from "@material-ui/icons/Cloud";
import Check from "@material-ui/icons/Check";
import Error from "@material-ui/icons/Error";
import Search from "@material-ui/icons/Search";
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Autorenew from "@material-ui/icons/Autorenew";
import Edit from "@material-ui/icons/Edit";
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Close from "@material-ui/icons/Close";
import Loop from "@material-ui/icons/Loop";
import HelpOutline from "@material-ui/icons/HelpOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AddIcon from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import Palette from "@material-ui/icons/Palette";
import Speed from "@material-ui/icons/Speed";
import Tune from "@material-ui/icons/Tune";
import PieChart from "@material-ui/icons/PieChart";
import Send from "@material-ui/icons/Send";
import GetAppIcon from '@material-ui/icons/GetApp';
import FeedbackIcon from '@material-ui/icons/Feedback';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PublishIcon from '@material-ui/icons/Publish';
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import HighQualityIcon from '@material-ui/icons/HighQuality';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import ShareIcon from '@material-ui/icons/Share';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PlayArrow from '@material-ui/icons/PlayArrow';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Notes from "@material-ui/icons/Notes";
import Launch from "@material-ui/icons/Launch";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ComputerIcon from '@material-ui/icons/Computer';
import StorageIcon from '@material-ui/icons/Storage';
import WorkIcon from '@material-ui/icons/Work';
import ExtensionIcon from '@material-ui/icons/Extension';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VpnLockIcon from '@material-ui/icons/VpnLock';

export {
  Delete,
  ArrowDownward,
  ArrowUpward,
  Home,
  AddCircleOutline,
  DataUsage,
  Dashboard,
  People,
  Settings,
  Help,
  HelpOutline,
  CloudQueueOutlined,
  Cloud,
  Check,
  Error,
  Search,
  Menu,
  MoreVert,
  Autorenew,
  Edit,
  Person,
  ExitToApp,
  Close,
  Loop,
  AccessTimeIcon,
  AddIcon,
  DirectionsRun,
  Palette,
  Speed,
  Tune,
  PieChart,
  Send,
  GetAppIcon,
  FeedbackIcon,
  FileCopyIcon,
  PublishIcon,
  VerifiedUserIcon,
  PlaylistAddCheckIcon,
  HighQualityIcon,
  MoreHorizIcon,
  AcUnitIcon,
  ShareIcon,
  DescriptionIcon,
  ExpandMoreIcon,
  ChevronRightIcon,
  AnnouncementIcon,
  ArrowDropDownIcon,
  ArrowRightIcon,
  PlayArrow,
  WarningIcon,
  ExpandMore,
  ExpandLess,
  Notes,
  Launch,
  ContactSupportIcon,
  MenuBookIcon,
  ComputerIcon,
  StorageIcon,
  WorkIcon,
  ExtensionIcon,
  AssessmentIcon,
  BookmarksIcon,
  AssignmentTurnedInIcon,
  VpnLockIcon
};
