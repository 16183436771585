import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    DataLoadingDisplay,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import {
    AssetService,
    IAssetServiceInjectedProps
} from "$State/AssetFreezerService";
import { ManipulationTypeVM } from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
} = require("./LabAssets.scss");

interface IManipulationTypesPageBaseProps {
}

interface IManipulationTypesPageState {
    editDialogOpen: boolean,
    newManipulationType: boolean,
    manipulationToEdit?: ManipulationTypeVM,
}

type IManipulationTypesPageProps = IManipulationTypesPageBaseProps & IAssetServiceInjectedProps;

export class _ManipulationTypesPage extends React.Component<IManipulationTypesPageProps, IManipulationTypesPageState> {

    private readonly columns: Array<IDataTableColumn<ManipulationTypeVM>> = [
        {
            columnName: "name",
            columnFieldData: "Name",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "description",
            columnFieldData: "Description",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Description",
        },
        {
            columnName: "active",
            columnFieldData: (d) => d.IsActive ? "Active" : "Inactive",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Active",
        },
        {
            columnName: "deactivated-on",
            columnFieldData: (d) => !d.IsActive ? moment(d.DeactivatedOn).format("L LT") : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Deactivated On",
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                            onClick={() => {
                                this.setState({ manipulationToEdit: d, editDialogOpen: true });
                            }}
                        >
                            Edit
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    state: IManipulationTypesPageState = {
        editDialogOpen: false,
        newManipulationType: false,
    }

    async componentDidMount() {
        await this.props.assetService.fetchManipulationTypes(true);
    }

    render() {

        let {
            manipulationTypesFetchResults,
        } = this.props.assetService.getState();
        if (manipulationTypesFetchResults.data) {
            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div
                        className={styles.mainContainer}
                    >
                        <Card
                            className={styles.cardStyle}
                        >
                            <h2>Manipulation Types</h2>
                            <Button
                                style={{ float: "right", marginRight: "20px" }}
                                variant="contained"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                color="primary"
                                onClick={() => {
                                    this.setState({
                                        editDialogOpen: true,
                                        newManipulationType: true,
                                        manipulationToEdit: {
                                            Description: "",
                                            Id: "",
                                            IsActive: true,
                                            Name: "",
                                            CreatedOn: new Date(Date.now()),
                                            DeactivatedOn: new Date(Date.now())
                                        }
                                    })
                                }}
                            >
                                Add
                            </Button>
                            {manipulationTypesFetchResults.data &&
                                <DataTable
                                    data={manipulationTypesFetchResults.data}
                                    columns={this.columns}
                                />
                            }
                            <Dialog
                                className={styles.dialog}
                                open={this.state.editDialogOpen}
                                onClose={this.handleClose}
                            >
                                <div className={styles.content}>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            InputLabelProps={{ shrink: true }}
                                            label={"Name"}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            value={this.state.manipulationToEdit?.Name}
                                            onDebouncedChange={(value) => { this.setName(value) }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            InputLabelProps={{ shrink: true }}
                                            label={"Description"}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            value={this.state.manipulationToEdit?.Description}
                                            onDebouncedChange={(value) => { this.setDescription(value) }}
                                        />
                                    </div>
                                    {
                                        !this.state.newManipulationType &&
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ paddingTop: "10px" }}>
                                                    Active:
                                                </div>
                                                <Checkbox disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} checked={this.state.manipulationToEdit?.IsActive}
                                                    onChange={(event) => {
                                                        this.setActive(event.target.checked);
                                                    }} />
                                            </div>
                                            {!this.state.manipulationToEdit?.IsActive && this.state.manipulationToEdit?.DeactivatedOn &&
                                                <div>
                                                    Deactivated On {moment(this.state.manipulationToEdit?.DeactivatedOn).format("L LT")}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.state.newManipulationType ? this.addManipulationType() : this.updateManipulationType() }}
                                        disabled={
                                            value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                            (this.state.newManipulationType &&
                                                !(this.state.manipulationToEdit && this.state.manipulationToEdit.Description && this.state.manipulationToEdit.Name))
                                        }
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Card>
                    </div >
                )}
            </ApplicationSecurityContext.Consumer>;
        }

        return <DataLoadingDisplay />;
    }

    @bind
    private handleClose() {
        this.setState({ editDialogOpen: false, newManipulationType: false });
    }

    @bind
    private setName(value: string) {
        let oldVal = this.state.manipulationToEdit;
        if (oldVal) {
            this.setState({
                manipulationToEdit:
                {
                    Description: oldVal.Description,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: oldVal.DeactivatedOn,
                    IsActive: oldVal.IsActive,
                    Id: oldVal.Id,
                    Name: value
                }
            })
        }
    }

    @bind
    private setDescription(value: string) {
        let oldVal = this.state.manipulationToEdit;
        if (oldVal) {
            this.setState({
                manipulationToEdit:
                {
                    Description: value,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: oldVal.DeactivatedOn,
                    IsActive: oldVal.IsActive,
                    Id: oldVal.Id,
                    Name: oldVal.Name
                }
            })
        }
    }

    @bind
    private setActive(value: boolean) {
        let oldVal = this.state.manipulationToEdit;
        if (oldVal) {
            this.setState({
                manipulationToEdit:
                {
                    Description: oldVal.Description,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: value ? oldVal.DeactivatedOn : new Date(Date.now()),
                    IsActive: value,
                    Id: oldVal.Id,
                    Name: oldVal.Name
                }
            })
        }
    }

    @bind
    private async updateManipulationType() {
        if (this.state.manipulationToEdit) {
            await this.props.assetService.updateManipulationType(this.state.manipulationToEdit);
            await this.props.assetService.fetchManipulationTypes(true);
            this.handleClose();
        }
    }

    @bind
    private async addManipulationType() {
        if (this.state.manipulationToEdit) {
            await this.props.assetService.addManipulationType(this.state.manipulationToEdit);
            await this.props.assetService.fetchManipulationTypes(true);
            this.handleClose();
        }
    }
}

export const ManipulationTypesPage = AssetService.inject(_ManipulationTypesPage);