import {
  React,
  _
} from "$Imports/Imports";

import {
  NGSQCReportsService,
  INGSQCReportsServiceInjectedProps
} from "$State/NGSQCReportsFreezerService";

import {
  SettingsService,
  ISettingsServiceInjectedProps
} from "$State/SettingsFreezerService"

import {
  Button, Card
} from "$Imports/MaterialUIComponents";

import ScrollSpyLayout from "$Components/common/ScrollSpyLayout/ScrollSpyLayout"
import { NavigationService } from "$State/NavigationFreezerService";
import { WetLabReport } from "./Reports/WetLabReport";
import { BioInformaticsPipelineReport } from "./Reports/BioInformaticsPipelineReport";
import { InstrumentReport } from "./Reports/InstrumentReport";
import {
  InfluenzaWorkflowId,
  NBSWorkflowId,
  SarsCov2WorkflowId
} from "$Imports/CommonComponents";

var urljoin = require('url-join');

interface INGSQCReportsBaseProps {

};

interface INGSQCReportsBaseState {
  workflowRunId: string;  // The GUID that identifies the workflow run.
  stepRNANextId: string;  // The GUID for the RNA Step Instance associated with the workflow id.
  workflowName: string;   // The name of the workflow.
  workflowDisplayName: string; //Display name values
}

type INGSQCReportsProps = INGSQCReportsServiceInjectedProps & INGSQCReportsBaseProps & ISettingsServiceInjectedProps;

class _NGSQCReportsLayoutPage extends React.Component<INGSQCReportsProps, INGSQCReportsBaseState> {

  state: INGSQCReportsBaseState = {
    workflowRunId: "",
    stepRNANextId: "",
    workflowName: "",
    workflowDisplayName: ""
  }

  
  async componentDidMount() {
    let workflowRunId = this.getWorkflowRunId();
    let workflowId = this.getWorkflowId();
    await this.props.NGSQCReportsService.getNGSQCReportsData(workflowRunId, workflowId);

    // Assign a string for the workflowName.
    // TODO: Standardize the string.
    switch (workflowId){
      case InfluenzaWorkflowId:
        this.setState({workflowName: "Influenza", workflowDisplayName:"Influenza Virus Sequencing Prep", workflowRunId : workflowRunId});
        break;
      case NBSWorkflowId:
        this.setState({workflowName: "NBS", workflowDisplayName:"Newborn Screening Targeted NGS Workflow", workflowRunId : workflowRunId});
        break;
      case SarsCov2WorkflowId:
        this.setState({workflowName: "COV2", workflowDisplayName:"SARS-CoV-2 COVIDSeq Illumina/ARTIC V4 Workflow", workflowRunId : workflowRunId});
        break;
      default:
        // Do nothing.
        break;
    }

  }

  render() {

    const reportData = this.props.NGSQCReportsService.getState().NGSQCReportsData.data;

    // Data for the main NGS QC Report.
    const baseReportData = reportData?.BaseReportInformation;
    
    const runNumber = baseReportData?.RunNumber || "";
    const runTag = baseReportData?.RunAdditionalInfo ? baseReportData?.RunAdditionalInfo["run_tag"] : "";
    const users = baseReportData?.RunUsers;

    // Data for each of the sub-sections of the Wet-Lab report.    
    const oAnalysisRunNotes = reportData?.AnalysisRunNotes;
    const oWorkflowStandardization = reportData?.WetlabStandardization;
    const oSequencingRunNotes = reportData?.SequencingRunNotes;
    const oInstrumentPrepStandardization = reportData?.InstrumentPrepStandardization;
    const oPoolingCalculations = reportData?.PoolingCalculations;
    const oDilutionCalculations = reportData?.DilutionCalculations;
    const oSequencingQcChecks = reportData?.SequencingQcChecks;
    const systemSettings = this.props.settingsService.getState().systemSettings.data || {};
    const bioinformaticsInformation = reportData?.BioinformaticsPipelineAnalysis;
    const instrumentReportData = reportData?.InstrumentSelection;

    const startDate = oWorkflowStandardization?.SequencingAnalysisStartDate;
    let currentWorkflowRunId = this.state.workflowRunId;

    return (

      <Card style={{ paddingTop: "70px" }}>
        <ScrollSpyLayout
          itemsInScroll={[
            {
              menuText: "Wet-Lab Report",
              component: <WetLabReport
                workflowRunId={currentWorkflowRunId}
                workflowRunNumber={runNumber}
                startDate={startDate}
                dataAnalysisRunNotes={oAnalysisRunNotes}
                dataWorkflowStandardization={oWorkflowStandardization}
                dataSequencingRunNotes={oSequencingRunNotes}
                dataInstrumentPrepStandardization={oInstrumentPrepStandardization}
                dataPoolingCalculations={oPoolingCalculations}
                dataDilutionCalculations={oDilutionCalculations}
                dataSequencingQcChecks={oSequencingQcChecks}
                workflowName={this.state.workflowName}
                workflowDisplayName={this.state.workflowDisplayName}
                users={users}
                systemSettings={systemSettings}
                />
            },
            {
              menuText: "Instrument Report",
              component: <InstrumentReport 
                          instrumentSelectionDetail={instrumentReportData}
                          workflowRunId={currentWorkflowRunId}
                          workflowRunNumber={runNumber}
                          workflowName={this.state.workflowName}
                          workflowDisplayName={this.state.workflowDisplayName}
                          users={users}
                          systemSettings={systemSettings}/>
            },
            {
              menuText: "Bioinformatics Pipeline Report",
              component: <BioInformaticsPipelineReport
                workflowRunNumber={runNumber}
                workflowRunTag={runTag}
                workflowName={this.state.workflowName}
                users={users}
                systemSettings={systemSettings}
                bioinformaticsInformation={bioinformaticsInformation}
              />
            }
          ]}

          scrollSpyPageHeader={null}

          scrollSpyMenuHeader={null}

          scrollSpyMenuFooter={<Button variant="contained"
            color="primary"
            onClick={() => this.navigateToResultsPage()}>
            Return To Results Page
          </Button>}

        />
      </Card>

    )
  }



  private getWorkflowRunId(): string {
    const urlParts = window.location.pathname.split('/');
    return urlParts[2];
  }

  private getWorkflowId(): string {
    const urlParts = window.location.pathname.split('/');
    return urlParts[3];
  }

  /*
  Method to navigate back to the results page from which the user came
  */
  private navigateToResultsPage(): void {
    const navigateToUrl = urljoin('/results', ':selectedRun');
    NavigationService.navigateTo(navigateToUrl);
  }

}

export const NGSQCReportsLayoutPage = SettingsService.inject(NGSQCReportsService.inject(
  _NGSQCReportsLayoutPage
));