import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";

import { Card, FormControlLabel, Checkbox, Button, TableRow, Dialog, Link, KeyboardDatePicker, Select, InputLabel, MenuItem, TextField, CircularProgress } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    DataTablePager,
    DataLoadingDisplay
} from "../../imports/CommonComponents";

import {
    AuditRecordService,
    IAuditRecordServiceInjectedProps
} from "$State/AuditRecordFreezerService";
import { AuditQuerySummaryVM } from "$Generated/api";
import { MoreHorizIcon } from "$Imports/MaterialUIIcons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { drop } from "lodash";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    table: string;
    searchDiv: string;
    searchButton: string;
    searchBox: string;
    searchInput: string;
    outputCell: string;
} = require("./AuditDisplay.scss");

interface IAuditDisplayPageBaseProps {
}

interface IAuditDisplayPageState {
    sampleIdSearch: string;
    userIdSearch: string;
    runIdSearch: string;
    instrumentSearch: string[];
    reagentSearch: string[];
    lotNumberSearch: string;
    fromDate: string;
    toDate: string;
    auditActionSearch: string[];
    auditModuleSearch: string[];
    popupStrings: string[];
    popupSelectedStrings: string[];
    popupOpen: boolean;
    popupType: "Modules" | "Actions" | "Samples" | "";
    loading: boolean;
    viewColumnsOpen: boolean;
}

type IAuditDisplayPageProps = IAuditDisplayPageBaseProps & IAuditRecordServiceInjectedProps;

export class _AuditDisplayPage extends React.Component<IAuditDisplayPageProps, IAuditDisplayPageState> {

    columns = [
        "Audit Action",
        "Module",
        "Date",
        "User Id",
        "Sample Id",
        "Run Id",
        "Well Position",
        "System Version",
        "Reagents",
        "Lot Numbers",
        "Additional Information"
    ]

    state: IAuditDisplayPageState = {
        sampleIdSearch: "",
        userIdSearch: "",
        runIdSearch: "",
        lotNumberSearch: "",
        instrumentSearch: [],
        reagentSearch: [],
        fromDate: "",
        toDate: "",
        auditActionSearch: [],
        auditModuleSearch: [],
        popupOpen: false,
        popupSelectedStrings: [],
        popupStrings: [],
        popupType: "",
        loading: false,
        viewColumnsOpen: false
    };

    async componentDidMount() {
        await this.props.auditRecordService.getDropdownOptions();
    }

    render() {
        const data = this.props.auditRecordService.getState().auditSummaryResults.data;

        let fromDate = new Date(this.state.fromDate);
        let toDate = new Date(this.state.toDate);

        const dropdownData = this.props.auditRecordService.getState().dropdownOptionResults.data;
        if (dropdownData) {

            return <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    {this.state.loading ?
                        <Dialog open={true} onClose={this.handleClose}>
                            <CircularProgress />
                        </Dialog> :
                        <div>
                            <div className={styles.content}>
                                <div style={{ display: "block" }}>
                                    <h3>Audit Parameters</h3>
                                    <div className={styles.searchDiv}>
                                        <div className={styles.searchBox}>
                                            <InputLabel id="audit-action-label">Audit Action</InputLabel>
                                            <Select
                                                multiple
                                                className={styles.searchInput}
                                                labelId="audit-action-label"
                                                id="audit-action"
                                                value={this.state.auditActionSearch}
                                                onChange={(event) => { this.setState({ auditActionSearch: event.target.value as string[] }) }}
                                            >
                                                {_.sortBy(dropdownData.AuditActions, a => a).map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
                                            </Select>
                                        </div>
                                        <div className={styles.searchBox}>
                                            <InputLabel id="audit-module-label">Module</InputLabel>
                                            <Select
                                                multiple
                                                className={styles.searchInput}
                                                labelId="audit-module-label"
                                                id="audit-module"
                                                value={this.state.auditModuleSearch}
                                                onChange={(event) => { this.setState({ auditModuleSearch: event.target.value as string[] }) }}
                                            >
                                                {_.sortBy(dropdownData.AuditModules, a => a).map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
                                            </Select>
                                        </div>
                                        <KeyboardDatePicker
                                            className={styles.searchBox}
                                            label="From Date"
                                            value={fromDate}
                                            onChange={this.onFromDateChanged}
                                            format="MM/DD/YY"
                                            invalidLabel=""
                                            invalidDateMessage=""
                                        />
                                        <KeyboardDatePicker
                                            className={styles.searchBox}
                                            label="To Date"
                                            value={toDate}
                                            onChange={this.onToDateChanged}
                                            format="MM/DD/YY"
                                            invalidLabel=""
                                            invalidDateMessage=""
                                        />
                                        <TextField multiline className={styles.searchBox} label="User ID" value={this.state.userIdSearch} onChange={(event) => { this.setState({ userIdSearch: event.target.value }) }} />
                                    </div>
                                    <h3>Workflow Run Parameters</h3>
                                    <div className={styles.searchDiv}>
                                        <div className={styles.searchBox}>
                                            <InputLabel id="instrument-label">Instrument</InputLabel>
                                            <Select
                                                multiple
                                                className={styles.searchInput}
                                                labelId="instrument-label"
                                                id="instrument"
                                                value={this.state.instrumentSearch}
                                                onChange={(event) => { this.setState({ instrumentSearch: event.target.value as string[] }) }}
                                            >
                                                {_.sortBy(dropdownData.Instruments, a => a).map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
                                            </Select>
                                        </div>
                                        <div className={styles.searchBox}>
                                            <InputLabel id="reagent-label">Reagent</InputLabel>
                                            <Select
                                                multiple
                                                className={styles.searchInput}
                                                labelId="reagent-label"
                                                id="reagent"
                                                value={this.state.reagentSearch}
                                                onChange={(event) => { this.setState({ reagentSearch: event.target.value as string[] }) }}
                                            >
                                                {_.sortBy(dropdownData.Reagents, a => a).map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
                                            </Select>
                                        </div>
                                        <TextField multiline className={styles.searchBox} label="Lot Number" value={this.state.lotNumberSearch} onChange={(event) => { this.setState({ lotNumberSearch: event.target.value }) }} />
                                        <TextField multiline className={styles.searchBox} label="Run Id" value={this.state.runIdSearch} onChange={(event) => { this.setState({ runIdSearch: event.target.value }) }} />
                                        <TextField multiline className={styles.searchBox} label="Sample ID" value={this.state.sampleIdSearch} onChange={(event) => { this.setState({ sampleIdSearch: event.target.value }) }} />
                                    </div>
                                    <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.runSearch(); }}>
                                        Search
                                    </Button>
                                </div>
                                {data && <div>
                                    <h2>Search Results Summary</h2>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <b style={{ marginTop: "auto", marginBottom: "auto" }}>Your search has resulted in {data.TotalRecords} records.</b>
                                        {data.TotalRecords > 10000 && <b style={{ marginTop: "auto", marginBottom: "auto" }}>Search may take a while to export. Consider adding additional filters.</b>}
                                        <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.exportRecords(); }}>
                                            Export
                                        </Button>
                                        <div style={{ marginTop: "auto", marginBottom: "auto" }} onClick={async () => {
                                            this.setState({viewColumnsOpen: true});
                                        }}>
                                            <Link>
                                                <u>View Audit Fields for Exported Data</u>
                                            </Link>
                                        </div>
                                    </div>
                                    {data.TotalRecords > 0 && <table>
                                        <tbody>
                                            <tr>
                                                <td className={styles.outputCell}>
                                                    Number of runs:
                                        </td>
                                                <td className={styles.outputCell}>
                                                    {data.NumberOfRuns}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.outputCell}>
                                                    Date Range:
                                        </td>
                                                <td>
                                                    {moment(data.StartDate).format("MM/DD/YY")} - {moment(data.EndDate).format("MM/DD/YY")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.outputCell}>
                                                    Number of LIMS Lite Modules:
                                        </td>
                                                <td className={styles.outputCell}>
                                                    <div onClick={async () => {
                                                        this.openFilteredModules()
                                                    }}>
                                                        <div style={{ marginRight: "5px" }}>
                                                            <Link>
                                                                <u>{data.NumberOfModules}</u>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.outputCell}>
                                                    Number of unique samples:
                                        </td>
                                                <td className={styles.outputCell}>
                                                    <div onClick={async () => {
                                                        this.openFilteredSamples()
                                                    }}>
                                                        <div style={{ marginRight: "5px" }}>
                                                            <Link>
                                                                <u>{data.NumberOfUniqueSamples}</u>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.outputCell}>
                                                    Number of Audit Actions:
                                        </td>
                                                <td className={styles.outputCell}>
                                                    <div onClick={async () => {
                                                        this.openFilteredActions()
                                                    }}>
                                                        <div style={{ marginRight: "5px" }}>
                                                            <Link>
                                                                <u>{data.NumberOfActions}</u>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>}
                                </div>}
                            </div>
                            <Dialog
                                open={this.state.popupOpen}
                                onClose={this.handleClose}
                            >
                                <Card>
                                    <div style={{ width: "500px", height: "500px", textAlign: "center", overflowY: "scroll" }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <h1 style={{ margin: "auto" }} >{this.state.popupType}</h1>

                                        </div>
                                        {
                                            _.map(this.state.popupStrings, value => <div style={{ textAlign: "start" }}>
                                                <Checkbox checked={_.includes(this.state.popupSelectedStrings, value)}
                                                    onChange={(event) => {
                                                        let list = this.state.popupSelectedStrings;
                                                        if (event.target.checked) {
                                                            list.push(value);
                                                        }
                                                        else {
                                                            list = _.filter(list, v => v !== value);
                                                        }
                                                        this.setState({ popupSelectedStrings: list });
                                                    }}/> 
                                                {value}
                                            </div>)
                                        }
                                    </div>
                                    <div>
                                        <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.handleClose(); }}>
                                            Cancel
                                    </Button>
                                        <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.updateFilters() }}>
                                            Add To Filter
                                    </Button>

                                    </div>
                                </Card>
                            </Dialog>
                            <Dialog
                                open={this.state.viewColumnsOpen}
                                onClose={this.handleClose}
                            >
                                <Card>
                                    <div style={{ width: "500px", height: "500px", textAlign: "center"}}>
                                        <h1 style={{ margin: "auto" }} >Columns</h1>
                                        {_.map(this.columns, col => <div>{col}</div>)}
                                    </div>
                                    <div>
                                        <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.handleClose(); }}>
                                            Cancel
                                    </Button>
                                    </div>
                                </Card>
                            </Dialog>
                        </div>}
                </Card>
            </div>;
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private handleClose() {
        this.setState({ popupOpen: false , viewColumnsOpen: false});
    }

    @bind
    private updateFilters() {
        let newFilters = this.state.popupSelectedStrings;
        let currentFilters: string[] = [];
        if (this.state.popupType === "Modules") {
            currentFilters = this.state.auditModuleSearch;
        }
        else if (this.state.popupType === "Actions") {
            currentFilters = this.state.auditActionSearch;
        }
        else if (this.state.popupType === "Samples") {
            if (this.state.sampleIdSearch !== "") {
                currentFilters = _.split(this.state.sampleIdSearch, "\n");
            }
        }
        _.forEach(newFilters, value => {
            if (!_.includes(currentFilters, value)) {
                currentFilters.push(value);
            }
        });

        if (this.state.popupType === "Modules") {
            this.setState({ auditModuleSearch: currentFilters });
        }
        else if (this.state.popupType === "Actions") {
            this.setState({ auditActionSearch: currentFilters });
        }
        else if (this.state.popupType === "Samples") {
            this.setState({ sampleIdSearch: _.join(currentFilters, "\n") });
        }

        this.handleClose();
    }

    @bind
    private async runSearch() {
        this.setState({ loading: true });
        await this.props.auditRecordService.fetchAuditRecordSummary(
            this.state.auditActionSearch,
            this.state.auditModuleSearch,
            this.state.fromDate,
            this.state.toDate,
            this.state.userIdSearch,
            this.state.instrumentSearch,
            this.state.reagentSearch,
            this.state.lotNumberSearch,
            this.state.runIdSearch,
            this.state.sampleIdSearch,
            true);

        this.setState({ loading: false });
    }

    @bind
    private onFromDateChanged(date: MaterialUiPickersDate, value: string | null | undefined) {
        this.setState({ fromDate: value ? value : "" });
    }

    @bind
    private onToDateChanged(date: MaterialUiPickersDate, value: string | null | undefined) {
        this.setState({ toDate: value ? value : "" });
    }

    @bind
    private async openFilteredModules() {
        this.setState({ loading: true });
        await this.props.auditRecordService.fetchFilteredModules(
            this.state.auditActionSearch,
            this.state.auditModuleSearch,
            this.state.fromDate,
            this.state.toDate,
            this.state.userIdSearch,
            this.state.instrumentSearch,
            this.state.reagentSearch,
            this.state.lotNumberSearch,
            this.state.runIdSearch,
            this.state.sampleIdSearch,
            true);
        var data = this.props.auditRecordService.freezer.get().filteredModuleResults.data;
        if (data) {
            this.setState({ popupOpen: true, popupStrings: data.toJS(), popupSelectedStrings: [], popupType: "Modules" });
        }
        this.setState({ loading: false });
    }

    private async openFilteredActions() {
        this.setState({ loading: true });
        await this.props.auditRecordService.fetchFilteredActions(
            this.state.auditActionSearch,
            this.state.auditModuleSearch,
            this.state.fromDate,
            this.state.toDate,
            this.state.userIdSearch,
            this.state.instrumentSearch,
            this.state.reagentSearch,
            this.state.lotNumberSearch,
            this.state.runIdSearch,
            this.state.sampleIdSearch,
            true);
        var data = this.props.auditRecordService.freezer.get().filteredActionResults.data;
        if (data) {
            this.setState({ popupOpen: true, popupStrings: data.toJS(), popupSelectedStrings: [], popupType: "Actions" });
        }
        this.setState({ loading: false });
    }

    private async openFilteredSamples() {
        this.setState({ loading: true });
        await this.props.auditRecordService.fetchFilteredSamples(
            this.state.auditActionSearch,
            this.state.auditModuleSearch,
            this.state.fromDate,
            this.state.toDate,
            this.state.userIdSearch,
            this.state.instrumentSearch,
            this.state.reagentSearch,
            this.state.lotNumberSearch,
            this.state.runIdSearch,
            this.state.sampleIdSearch,
            true);
        var data = this.props.auditRecordService.freezer.get().filteredSampleResults.data;
        if (data) {
            this.setState({ popupOpen: true, popupStrings: data.toJS(), popupSelectedStrings: [], popupType: "Samples" });
        }
        this.setState({ loading: false });
    }

    @bind
    private async exportRecords() {
        await this.props.auditRecordService.exportAuditRecords(
            this.state.auditActionSearch,
            this.state.auditModuleSearch,
            this.state.fromDate,
            this.state.toDate,
            this.state.userIdSearch,
            this.state.instrumentSearch,
            this.state.reagentSearch,
            this.state.lotNumberSearch,
            this.state.runIdSearch,
            this.state.sampleIdSearch,
            true);
        var saveDocument = document.createElement('a');
        saveDocument.href = 'data:text/plain;charset=utf-8,' + encodeURI(this.props.auditRecordService.getState().exportRecordsResults.data?.ExportedCSV || "");
        saveDocument.target = '_blank';
        saveDocument.download = "Audit_Export_" + moment(Date.now()).format("MM-DD-YYYY-HH-mm") + ".csv";
        saveDocument.click();
    }
}

export const AuditDisplayPage = AuditRecordService.inject(_AuditDisplayPage);