import {
    React,
    _
} from "$Imports/Imports";

import { PlateHeader } from "./PlateHeader";
import { PlateColumnHeader } from "./PlateColumnHeader";
import { IStandardPlateColumn } from "./IStandardPlateColumn";
import { StandardPlateRows } from "./StandardPlateRows";
import { IStandardPlateWell } from "./IStandardPlateWell";

import { Table } from "$Imports/MaterialUIComponents";

import { PlateVM, RackVM, SampleVM, WellContentVM } from "$Generated/api";
const styles: {
    plateTable: string;
    plateWell: string;
    wellWithSample: string;
    wellWithControl: string;
    wellWithPTC: string;
    wellWithNTC: string;
    legend: string;
    legendRow: string;
    legendElement: string;
    legendElementLabel: string;
    wellWithFailedSample: string;
} = require("./StandardPlateStyles.scss");

interface IStandardPlateProps<T = unknown> {
    name?: string;
    wellCount: number;
    columnCount: number;
    wellContents: WellContentVM[];
    positionByRow: boolean;
    wellClick?: (position: number) => void;
    selectedPosition?: number;
    disabledAfterWell?: number;
    additionalTooltipInformation?: (well: WellContentVM) => string;
}

export interface IEmptyWell {
    WellPosition: number;
}

export function instanceOfWellContentVM(object: any): object is WellContentVM {
    return 'Id' in object;
}

export function instanceOfRackVM(object: any): object is RackVM {
    return 'Id' in object;
}

export function instanceOfPlateVM(object: any): object is PlateVM {
    return 'Id' in object;
}

export function ConvertPositionToString(position: number, columnCount: number, rowCount: number, positionByRow: boolean): string {
    if (positionByRow) {
        const col = position % columnCount;
        const row = (position - col) / columnCount;
        var asciiCode = row + 65; //65 is the ascii code for 'A'
        return String.fromCharCode(asciiCode) + (col + 1)
    }
    else {
        const row = position % rowCount;
        const col = (position - row) / rowCount;
        var asciiCode = row + 65; //65 is the ascii code for 'A'
        return String.fromCharCode(asciiCode) + (col + 1)
    }
}

export function ConvertStringToPosition(stringRep: string, columnCount: number, rowCount: number, positionByRow: boolean): number {
    if (positionByRow) {
        // Row number is calculated from charCode, subtracting 65, so A is 0
        // For incrementing through rows, each row is columnCount wells long
        // Well number when counting through rows first is (row number * number of wells per row) + well number in row
        const rowVal = (stringRep.charCodeAt(0) - 65) * columnCount;
        const colVal = (Number.parseInt(stringRep.substr(1,2)) - 1);
        return colVal + rowVal;
    }
    else {
        // Row number is calculated from charCode, subtracting 65, so A is 0
        // For incrementing through columns, each column is rowCount wells long
        // Well number when counting through columns first is (column number * number of wells per column) + well number in column
        const rowVal = (stringRep.charCodeAt(0) - 65);
        const colVal = (Number.parseInt(stringRep.substr(1,2)) - 1) * rowCount;
        return colVal + rowVal;
    }
}


export class StandardPlate<T = unknown> extends React.PureComponent<IStandardPlateProps<T>> {

    private get rowTotal(): number {
        return this.props.wellCount / this.props.columnCount;
    }



    private generateColumnHeader(): IStandardPlateColumn[] {
        const columnList = [];

        for (let i = 1; i <= this.props.columnCount; i++) {
            const tempColumn = {
                columnName: i.toString(),
                headerValue: i.toString()
            } as IStandardPlateColumn;

            columnList.push(tempColumn);
        }

        return columnList;
    }

    buildLayout() {
        var plateLayout: (WellContentVM | IEmptyWell)[][] = [];
        var rowCount = this.rowTotal;
        var samplePosition = 0;

        for (let row = 0; row < rowCount; row++) {
            plateLayout[row] = [];
        }

        if (this.props.positionByRow) {
            for (let row = 0; row < this.rowTotal; row++) {
                for (let col = 0; col < this.props.columnCount; col++) {
                    var well = _.find(this.props.wellContents, (w) => { return ((w.WellPosition === samplePosition)) });
                    if (well) {
                        plateLayout[row][col] = well;
                    }
                    else {
                        plateLayout[row][col] = { WellPosition: samplePosition };
                    }
                    samplePosition++;
                }
            }
        }

        else {
            for (let col = 0; col < this.props.columnCount; col++) {
                for (let row = 0; row < this.rowTotal; row++) {
                    var well = _.find(this.props.wellContents, (w) => { return ((w.WellPosition === samplePosition)) });
                    if (well) {
                        plateLayout[row][col] = well;
                    }
                    else {
                        plateLayout[row][col] = { WellPosition: samplePosition };
                    }
                    samplePosition++;
                }
            }
        }

        return plateLayout;
    }

    render() {
        let { name, wellContents } = this.props;
        let sampleCount = wellContents.length === 1 ? `(${wellContents.length} sample)` : `(${wellContents.length} samples)`;

        let wellContentsList = this.buildLayout();

        let wellTypes = new Array<string>();

        for (let wellRow of wellContentsList) {
            for (let well of wellRow) {
                if (instanceOfWellContentVM(well)) {
                    if (well.RtpcrPass === "Fail" || well.RtpcrPass === "ContinueWithFailed") {
                        wellTypes.push("Failed");
                    }
                    else if (well.Control) {
                        wellTypes.push(well.Control.Name);
                    }
                    else {
                        wellTypes.push("Sample");
                    }
                }
                else {
                    wellTypes.push("Empty");
                }
            }
        }

        wellTypes = _.uniq(wellTypes);

        return (
            <div>
                {name && <PlateHeader header={`${name} ${sampleCount}`} />}
                <Table className={styles.plateTable}>
                    <PlateColumnHeader
                        columns={this.generateColumnHeader()}
                    />
                    <StandardPlateRows
                        wellContents={wellContentsList}
                        wellClick={this.props.wellClick}
                        positionByRow={this.props.positionByRow}
                        selectedPosition={this.props.selectedPosition}
                        disabledAfterWell={this.props.disabledAfterWell}
                        additionalTooltipInformation={this.props.additionalTooltipInformation}
                    />
                </Table>
                <div className={styles.legend}>
                    <div className={styles.legendRow}>
                        {
                            wellTypes.find(w => w === 'Known') &&
                            <div className={styles.legendElement}>
                                <div className={`${styles.plateWell} ${styles.wellWithControl}`} />
                                <span className={styles.legendElementLabel}>Knowns</span>
                            </div>
                        }
                        {
                            wellTypes.find(w => w === 'NTC') &&
                            <div className={styles.legendElement}>
                                <div className={`${styles.plateWell} ${styles.wellWithNTC}`} />
                                <span className={styles.legendElementLabel}>NTC</span>
                            </div>
                        }
                        {
                            wellTypes.find(w => w === 'PTC') &&
                            <div className={styles.legendElement}>
                                <div className={`${styles.plateWell} ${styles.wellWithPTC}`} />
                                <span className={styles.legendElementLabel}>PTC</span>
                            </div>
                        }
                        {
                            wellTypes.find(w => w === 'Sample') &&
                            <div className={styles.legendElement}>
                                <div className={`${styles.plateWell} ${styles.wellWithSample}`} />
                                <span className={styles.legendElementLabel}>Samples</span>
                            </div>
                        }
                        {
                            wellTypes.find(w => w === "Failed") &&
                            <div className={styles.legendElement}>
                                <div className={`${styles.plateWell} ${styles.wellWithFailedSample}`}>F</div>
                                <span className={styles.legendElementLabel}>Failed</span>
                            </div>
                        }
                        {
                            wellTypes.find(w => w === 'Empty') &&
                            <div className={styles.legendElement}>
                                <div className={styles.plateWell} />
                                <span className={styles.legendElementLabel}>Empty</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
