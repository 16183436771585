import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"


import {
    CollapsibleSection,
} from "$Imports/CommonComponents";


import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { MinKnowService, IMinKnowServiceInjectedProps } from "$State/MinKnowFreezerService";
import { MinKnowConnectionInformationResponse, QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Button } from "$Imports/MaterialUIComponents";


export interface IMinderConnectionStatusPageState {
    connectionData?: MinKnowConnectionInformationResponse;
}

interface IMinderConnectionStatusPageBaseProps {

}

type IMinderConnectionStatusPageProps = IMinderConnectionStatusPageBaseProps & IMinKnowServiceInjectedProps;

class _MinderConnectionStatusPage extends React.Component<IMinderConnectionStatusPageProps, IMinderConnectionStatusPageState> {

    private _security: ApplicationSecuritySettings = new ApplicationSecuritySettings();

    state: IMinderConnectionStatusPageState = {
    };

    async componentDidMount() {
        await this.checkConnection();
    }

    @bind
    private async checkConnection() {
        await this.props.minKnowService.fetchConnectionStatus(true);
        let connectionData = this.props.minKnowService.freezer.get().connectionResults.data;
        if (connectionData) {
            this.setState({ connectionData })
        }

    }

    render() {
        let {
            connectionData
        } = this.state;

        if(connectionData)
        {
        return (
            <div>
                <CollapsibleSection sectionHeader="" expanded={true}>
                    <div>
                        <div>
                            <h2>
                                Pre Run Check
                            </h2>
                            <table>
                                <tbody>
                                    <tr>
                                        <td> MinIT Connected to Laptop </td>
                                        <td>
                                            {(connectionData && connectionData.ConnectedToLaptop) ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            MinION connected to MinIT
                                        </td>
                                        <td>
                                            {(connectionData && connectionData.ConnectedToMinIT) ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Flow cell installed
                                        </td>
                                        <td>
                                            {(connectionData && connectionData.FlowCellInstalled) ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button variant="contained" color="primary" onClick={this.checkConnection}>
                                Check Connection
                            </Button>
                        </div>
                    </div>

                    <div>
                        <h2>
                            Instrument Info
                        </h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        MinIT Name:
                                    </td>
                                    <td>
                                        {connectionData.MinITName}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Flow Cell ID:
                                    </td>
                                    <td>
                                        {connectionData.FlowCellId}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Flow Cell Product Code:
                                    </td>
                                    <td>
                                        {connectionData.FlowCellProductCode}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Disk Space on MinIT:
                                    </td>
                                    <td>
                                        {connectionData.SpaceOnMinIT}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Disk Space on Laptop:
                                    </td>
                                    <td>
                                        {connectionData.SpaceOnLaptop}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </CollapsibleSection>
            </div>);
    }
    return <></>;
}
}

export const MinderConnectionStatusPage = MinKnowService.inject(_MinderConnectionStatusPage);