import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _
} from "$Imports/Imports";

import {
    WorkflowApiFactory,
    WorkflowVM,
    WetlabRunVM,
    WorkflowPTCAndNTCCheck,
    WorkflowRunApiFactory,
    SampleVM,
    UpdateSamplePriority,
    HttpResponseMessage,
    WorkflowMetricVM,
    WorkflowStepWithInitiationPointVM,
    WellMaterial,
    WorkflowReagentsVM,
    AssetManagementApiFactory,
    ReagentLotVM,
    ReagentGroupLotVM
} from "$Generated/api";
import { apiExceptionHandler } from "./ErrorFreezerService";

export interface IWorkflowsState {
    workflowFetchResults: IAjaxState<WorkflowVM[]>;
    workflowStartResults: IAjaxState<WetlabRunVM>;
    workflowPTCAndNTCCheck: IAjaxState<WorkflowPTCAndNTCCheck>;
    workflowSampleResults: IAjaxState<SampleVM[]>;
    updateSamplePriorityState: IAjaxState<HttpResponseMessage>;
    workflowMetricsResults: IAjaxState<WorkflowMetricVM[]>;
    workflowStepWithInitiationPointsResults: IAjaxState<WorkflowStepWithInitiationPointVM[]>;
    workflowStartFromArchivedPlateResults: IAjaxState<WetlabRunVM>;
    reopenWorkflowFromStepResults: IAjaxState<WetlabRunVM>;
    fetchWorkflowReagentsResults: IAjaxState<WorkflowReagentsVM>;
    setWorkflowRunReagentsResults: IAjaxState<boolean>;
}

const InjectedPropName = "workflowsService";

class WorkflowsFreezerService extends FreezerService<IWorkflowsState, typeof InjectedPropName> {
    constructor() {
        super({
            workflowFetchResults: managedAjaxUtil.createInitialState(),
            workflowStartResults: managedAjaxUtil.createInitialState(),
            workflowPTCAndNTCCheck: managedAjaxUtil.createInitialState(),
            workflowSampleResults: managedAjaxUtil.createInitialState(),
            updateSamplePriorityState: managedAjaxUtil.createInitialState(),
            workflowMetricsResults: managedAjaxUtil.createInitialState(),
            workflowStepWithInitiationPointsResults: managedAjaxUtil.createInitialState(),
            workflowStartFromArchivedPlateResults: managedAjaxUtil.createInitialState(),
            reopenWorkflowFromStepResults: managedAjaxUtil.createInitialState(),
            fetchWorkflowReagentsResults: managedAjaxUtil.createInitialState(),
            setWorkflowRunReagentsResults: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async fetchWorkflows(forceUpdate: boolean = false) {
        const { workflowFetchResults } = this.freezer.get();

        if (workflowFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "workflowFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowGet();
            },
            onError: apiExceptionHandler
        });
    }

    public fetchWorkflowMetrics(forceUpdate: boolean = false) {
        const { workflowMetricsResults } = this.freezer.get();

        if (workflowMetricsResults.hasFetched && !forceUpdate) {
            return;
        }

        managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "workflowMetricsResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowGetWorkflowMetricsGet();
            },
            onError: apiExceptionHandler
        });
    }

    public async startWorkflowTask(id: string, maxLoad: number, onBeginWorkflow: (workflowId: string) => void) {

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "workflowStartResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunCreateWorkflowMaxLoadPost(param);
            },
            params: {
                workflowId: id,
                plateMaxLoad: maxLoad
            },
            onOk: (data) => {
                this.fetchWorkflows(true);
            },
            onError: apiExceptionHandler
        });
        let result = this.freezer.get().workflowStartResults;
        if (result.hasFetched && result.data && result.data.Id) {
            onBeginWorkflow(result.data.Id);
        }
    }

    public async getPTCAndNTCCheck(workflowId: string) {
        if (this.freezer.get().workflowPTCAndNTCCheck.hasFetched) {
            //Reset on each call
            this.freezer.get().set({ workflowPTCAndNTCCheck: managedAjaxUtil.createInitialState() });
        }
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "workflowPTCAndNTCCheck",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet(param);
            },
            params: {
                workflowId: workflowId
            },
            onError: apiExceptionHandler
        });
    }

    public async getSamples(testId: string) {
        if (this.freezer.get().workflowSampleResults.hasFetched) {
            //Reset on each call
            this.freezer.get().set({ workflowSampleResults: managedAjaxUtil.createInitialState() });
        }
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "workflowSampleResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowGetSamplesTestIdGet(param);
            },
            params: {
                testId: testId
            },
            onError: apiExceptionHandler
        });
    }

    public async updateSamplePriorities(samples: UpdateSamplePriority[]) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateSamplePriorityState",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowUpdateSamplePriorityPost(param);
            },
            params: {
                body: {
                    Samples: samples
                }
            },
            onError: apiExceptionHandler
        });
    }

    public async fetchWorkflowStepsWithInitiationPoints(workflowId: string, wellMaterial: WellMaterial) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "workflowStepWithInitiationPointsResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowGetWorkflowInitiationPointsGet(param);
            },
            params: {
                workflowId: workflowId,
                wellMaterial: wellMaterial
            },
            onError: apiExceptionHandler
        });

    }

    public async startWorkflowFromArchivedPlate(workflowId: string, stepId: string, archivedPlateId: string, workflowRunId: string, reagentLots: { [index: string]: { lot: (ReagentLotVM | ReagentGroupLotVM), kitId: string } }, onBeginWorkflow: (workflowId: string, stepInstanceId: string) => void) {

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "workflowStartFromArchivedPlateResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunCreateWorkflowFromArchivedPlatePost(param);
            },
            params: {
                workflowId: workflowId,
                stepId: stepId,
                archivedPlateId: archivedPlateId,
                workflowRunId: workflowRunId,
                body: _.map(reagentLots, (r, id) => {
                    return {
                        LotId: r.lot.Id || "new",
                        IsGroup: !this.isReagentLot(r.lot),
                        ReagentId: this.isReagentLot(r.lot) ? r.lot.ReagentId || "" : r.lot.ReagentGroupId || "",
                        IsNew: r.lot.IsNew,
                        LotNumber: r.lot.LotNumber,
                        KitId: r.kitId
                    }
                })
            },
            onError: apiExceptionHandler
        });
        let result = this.freezer.get().workflowStartFromArchivedPlateResults;
        if (result.hasFetched && result.data && result.data.Id && result.data.CurrentOrLastStepInstanceId) {
            onBeginWorkflow(result.data.Id, result.data.CurrentOrLastStepInstanceId);
        }
    }

    public async reopenWorkflowFromStep(stepId: string, assetId: string, workflowRunId: string, reagentLots: { [index: string]: { lot: (ReagentLotVM | ReagentGroupLotVM), kitId: string } }, onBeginWorkflow: (workflowId: string, stepInstanceId: string) => void) {

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "reopenWorkflowFromStepResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunReopenRunFromStepPost(param);
            },
            params: {
                stepId: stepId,
                assetId: assetId,
                workflowRunId: workflowRunId,
                body: _.map(reagentLots, (r, id) => {
                    return {
                        LotId: r.lot.Id || "new",
                        IsGroup: !this.isReagentLot(r.lot),
                        ReagentId: this.isReagentLot(r.lot) ? r.lot.ReagentId || "" : r.lot.ReagentGroupId || "",
                        IsNew: r.lot.IsNew,
                        LotNumber: r.lot.LotNumber,
                        KitId: r.kitId
                    }
                })
            },
            onError: apiExceptionHandler
        });
        let result = this.freezer.get().reopenWorkflowFromStepResults;
        if (result.hasFetched && result.data && result.data.Id && result.data.CurrentOrLastStepInstanceId) {
            onBeginWorkflow(result.data.Id, result.data.CurrentOrLastStepInstanceId);
        }
    }

    public async fetchWorkflowReagents(workflowId: string, forceUpdate?: boolean) {
        const { fetchWorkflowReagentsResults: fetchWorkflowReagents } = this.freezer.get();

        if (!fetchWorkflowReagents.hasFetched || forceUpdate) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'fetchWorkflowReagentsResults',
                onExecute: (apiOptions, param, options) => {
                    let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AssetManagementWorkflowReagentsGet({ workflowId: workflowId });
                },
                onError: apiExceptionHandler
            });
        }
    }

    public async setWorkflowRunReagents(workflowRunId: string, reagents: { [index: string]: { lot: (ReagentLotVM | ReagentGroupLotVM), kitId: string } }) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'setWorkflowRunReagentsResults',
            onExecute: (apiOptions, param, options) => {
                let factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementWorkflowReagentsPost({
                    workflowRunId: workflowRunId, body: _.map(reagents, (r, id) => {
                        return {
                            LotId: r.lot.Id || "",
                            IsGroup: !this.isReagentLot(r.lot),
                            ReagentId: this.isReagentLot(r.lot) ? r.lot.ReagentId || "" : r.lot.ReagentGroupId || "",
                            IsNew: r.lot.IsNew,
                            LotNumber: r.lot.LotNumber,
                            KitId: r.kitId
                        }
                    })
                });
            },
            onError: apiExceptionHandler
        });
    }

    private isReagentLot(r: ReagentLotVM | ReagentGroupLotVM): r is ReagentLotVM {
        return (r as ReagentLotVM).ReagentName !== undefined;
    }

}

export const WorkflowsService = new WorkflowsFreezerService();
export type IWorkflowsServiceInjectedProps = ReturnType<WorkflowsFreezerService["getPropsForInjection"]>;
