import {
    bind,
    React
} from "$Imports/Imports";
import {
    TextField
} from "$Imports/MaterialUIComponents";
import { Autocomplete } from "@material-ui/lab";
import { ChangeEvent } from "react";

import
    * as s
    from "underscore.string";

const styles: {
    Table: string;
    Row: string;
    LabelCell: string;
    DataCell: string;
    markdown: string;
    Input: string;
    DataCellHeader: string
} = require("./BioanalyzerInstrument.scss");

interface IBioanalyzerInstrumentProps {
    displayNotes: string;
    disabled: boolean;
    updateInstrumentName: (name: string) => void;
    instrumentName: string;
    instrumentOptions?: string[];
    librarySize: number;
    updateLibrarySize: (newSize: number) => void;
}

interface IBioanalyzerInstrumentState {
}

export class BioanalyzerInstrument extends React.Component<IBioanalyzerInstrumentProps, IBioanalyzerInstrumentState> {

    public state: IBioanalyzerInstrumentState = {
    };

    public render() {
        return <div>
            <h2>Bioanalyzer Instrument</h2>

            <i>{this.props.displayNotes}</i>
            <table className={styles.Table}>
                <tbody>
                    <tr className={styles.Row} >
                        <td className={styles.DataCellHeader}>
                            Bioanalyzer:
                        </td>
                        <td className={styles.DataCell}>
                            <Autocomplete
                                freeSolo
                                disabled={this.props.disabled}
                                id="instrument-name"
                                options={this.props.instrumentOptions || []}
                                onChange={(e, val) => this.props.updateInstrumentName(val || "")}
                                value={this.props.instrumentName}
                                renderInput={(params) =>
                                (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                        disabled={this.props.disabled} style={{ textAlign: "right" }} value={this.props.instrumentName} onChange={(props) => this.props.updateInstrumentName(props.target.value)} />
                                )}
                            />
                        </td>
                    </tr>
                    <tr className={styles.Row}>
                        <td className={styles.DataCellHeader}>
                            Avg. Library Size (bp):
                        </td>
                        <td className={styles.DataCell} >
                            <TextField type="number" inputProps={{ min: 0 }} value={this.props.librarySize}
                                disabled={this.props.disabled}
                                onChange={this.updateLibrarySizeField} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }

    @bind
    updateLibrarySizeField(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.props.updateLibrarySize(0);
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue >= 0) {
                this.props.updateLibrarySize(numericValue);
            }
        }
    }

}