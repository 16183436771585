import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, PCRControl, CollapsibleSection } from "$Imports/CommonComponents";
import Snackbar from "@material-ui/core/Snackbar";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";

const styles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

export interface IEndRepairState {
    messageSnackbarOpen: boolean;
    message: string;
    canMove: boolean;
}

export class EndRepairScreen extends React.Component<IWorkflowScreenProps, IEndRepairState> {

    state: IEndRepairState = { messageSnackbarOpen: false, message: "", canMove: true };

    componentDidMount() {
        this.props.workflowRunService.fetchPCRForStep(true);
        this.props.workflowRunService.fetchMasterMixForStep(true);
        this.props.workflowRunService.fetchInputPoolsForStep(true);
        this.props.workflowRunService.fetchInstrumentOptions();
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;

        if (currentWorkflowRun && currentStep && pcrInfoState.data && masterMixInfoState.data && instrumentOptions && lotOptions) {
            const pools = this.props.workflowRunService.getState().inputPools;
            const inputPool = _.find(pools.data, pool => (pool.Name === currentStep.InputName));
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            if (inputPool && inputPool.RunCount) {
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={styles.mainDiv}>
                                <MasterMixControl
                                    disabled={disabled}
                                    index={0}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={1}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                />
                                <PCRControl disabled={disabled} controlInfo={pcrInfoState.data} updateInstrumentName={this.props.workflowRunService.updatePCRName} instrumentOptions={instrumentOptions} />
                            </div>
                        </CollapsibleSection>
                        <div className={styles.footerDiv}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} hideArchiveRetain={(inputPool.RunCount >= currentWorkflowRun.WorkflowMaxSampleRun)} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.message}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>);
            }
        }
        return <></>
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private async moveToNextStep() {
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (pcrInfoState.data && masterMixState.data && currentStep && currentStep.OutputAssets) {
            let missingMasterMixData = missingData(masterMixState.data[0]);
            let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName)
            let inputAsset = _.find(currentStep.toJS().InputAssets, a => a.Name === currentStep.InputName)
            if (inputAsset && inputAsset.PoolSummary && inputAsset.PoolSummary.Status !== "Retained") {
                this.setState({ messageSnackbarOpen: true, message: inputAsset.Name + " must be retained before continuing" });
            }

            if (inputAsset && inputAsset.PoolSummary && inputAsset.PoolSummary.Status !== "Retained") {
                const pools = this.props.workflowRunService.getState().inputPools;
                const inputPool = _.find(pools.data, pool => (pool.Name === currentStep.InputName));
                if (currentWorkflowRun && inputPool && inputPool.RunCount && inputPool.RunCount < currentWorkflowRun.WorkflowMaxSampleRun) {
                    this.setState({ messageSnackbarOpen: true, message: inputAsset.Name + " must be retained before continuing" });
                    return;
                }
                else if (inputPool) {
                    //Auto discard plate
                    await this.props.workflowRunService.changePoolStatus([inputPool.Id], "Discarded", false);
                }

            }
            if (pcrInfoState.data.InstrumentNameValue && !missingMasterMixData && outputAsset && outputAsset.PoolSummary) {
                //Continue to next step
                if (this.state.canMove) {
                    await Promise.all([
                        this.props.workflowRunService.saveMasterMix(true),
                        this.props.workflowRunService.savePCRInfo(),
                        this.props.workflowRunService.addInstrument(pcrInfoState.data.InstrumentNameValue),
                        this.props.workflowRunService.savePoolVolume(outputAsset.PoolSummary.Id, 60), //Volume is equal to all mastermix reagents added together. Currently this is 60. If this changes down the line we will need to rework the master mix data structure.
                        this.props.workflowRunService.completeStep()
                    ]);
                }
            }
            else {
                this.setState({ messageSnackbarOpen: true, message: "Lot numbers and ThermoCycler name are required" });
            }
        }
    }
}