import { ConvertPositionToString, ConvertStringToPosition } from "$Components/common/StandardPlate/StandardPlate";
import { PlateVM, ReagentLotVM, WellContentVM, WetlabRunBarcodeReagentMapVM } from "$Generated/api";
import { StandardPlate, DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Dialog, Card, FormControlLabel, CardActions, Button } from "$Imports/MaterialUIComponents";
import { WorkflowRunFreezerService } from "$State/WorkflowRun/WorkflowRunFreezerService";
import { RadioGroup, Radio } from "@material-ui/core";
import { sample } from "lodash";
import { IWorkflowScreenProps } from "../WorkflowStep";


interface IBarcodingPrimerPrepIlluminaDialogBaseProps {
    open: boolean;
    disabled: boolean;
    close: () => void;
    workflowRunService: WorkflowRunFreezerService;
}

interface IBarcodingPrimerPrepIlluminaDialogState {
    tableDisplay: IAdapterMap[];
}

const barcodeStyles: {
    card: string;
    dialog: string;
    plateDiv: string;
    dataTable: string;
} = require("./Barcoding.scss");

const styles: {
    footerDiv: string;
    mainDiv: string;
    tableHeader: string;
} = require("./CommonStepStyles.scss");

export interface IAdapterMap {
    sampleId: string;
    position: string;
    positionAsNumber: number;
    i7Sequence: string;
    i7ReagentId: string;
    i5Sequence: string;
    i5ReagentId: string;
}

type IBarcodingPrimerPrepIlluminaDialogProps = IBarcodingPrimerPrepIlluminaDialogBaseProps;

export class BarcodingPrimerPrepIlluminaDialog extends React.Component<IBarcodingPrimerPrepIlluminaDialogProps, IBarcodingPrimerPrepIlluminaDialogState> {

    state: IBarcodingPrimerPrepIlluminaDialogState = {
        tableDisplay: []
    }

    async componentDidUpdate(prevProps: IBarcodingPrimerPrepIlluminaDialogProps) {
        if (this.props.open && !prevProps.open) {
            const selectedbarcodeKitData = _.find(this.props.workflowRunService.getState().fetchBarcodeKits.data, b => _.find(b.Lots, l => l.Id === this.props.workflowRunService.barcodeReagentGroupId) !== undefined);
            const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
            const currentStep = this.props.workflowRunService.currentStep;
            //For Illumina Adapters - create an i5/i7 mapping for each sample.
            let adapters: WetlabRunBarcodeReagentMapVM[] = [];
            if (selectedbarcodeKitData && currentWorkflowRun && currentStep) {

                const plates = this.props.workflowRunService.getState().plates;
                if (plates.data) {
                    const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
                    const sortedPlate = inputPlate?.WellContents.sort((a, b) => {
                        return a.WellPosition - b.WellPosition;
                    });

                    const reagents = _.find(selectedbarcodeKitData.Lots, l => l.Id === this.props.workflowRunService.barcodeReagentGroupId)?.ReagentLots;
                    const tableDisplay: IAdapterMap[] = [];
                    _.forEach(sortedPlate, sampleWell => {
                        var wellPos = ConvertPositionToString(sampleWell.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow)
                        var i7 = _.find(reagents, r => r.BarcodePosition === wellPos.substring(1));
                        var i5 = _.find(reagents, r => r.BarcodePosition === wellPos.charAt(0));

                        tableDisplay.push(
                            {
                                sampleId: sampleWell.Sample ? sampleWell.Sample.SampleId : (sampleWell.Control ? sampleWell.Control.Name : ""),
                                position: ConvertPositionToString(sampleWell.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow),
                                positionAsNumber: sampleWell.WellPosition,
                                i7Sequence: i7?.Sequence || "",
                                i7ReagentId: i7?.ReagentId || "",
                                i5Sequence: i5?.Sequence || "",
                                i5ReagentId: i5?.ReagentId || ""
                            }
                        );
                        adapters.push({
                            ReagentGroupLotId: this.props.workflowRunService.barcodeReagentGroupId,
                            ReagentLotId: i7?.Id || "",
                            WellPosition: sampleWell.WellPosition
                        });

                        adapters.push({
                            ReagentGroupLotId: this.props.workflowRunService.barcodeReagentGroupId,
                            ReagentLotId: i5?.Id || "",
                            WellPosition: sampleWell.WellPosition
                        });

                    });
                    this.setState({tableDisplay});
                    this.props.workflowRunService.barcodeReagentMap = adapters;
                }
            }
        }
    }

    private columns(): Array<IDataTableColumn<IAdapterMap>> {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: "sampleId",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample Id",
                },
                {
                    columnName: "position",
                    columnFieldData: "position",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Position",
                },
                {
                    columnName: "i7-sequence",
                    columnFieldData: "i7Sequence",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "i7 Adapter Sequence",
                },
                {
                    columnName: "i5-sequence",
                    columnFieldData: "i5Sequence",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "i5 Adapter Sequence",
                },

            ];
    }

    render() {
        return <Dialog
            PaperProps={{ className: barcodeStyles.dialog }}
            className={barcodeStyles.dialog}
            open={this.props.open}
        >
            <Card className={barcodeStyles.card}>
                <div style={{ display: "flex", flexDirection: "row" }}
                    className={barcodeStyles.dataTable}
                >
                    <DataTable
                        columns={this.columns()}
                        data={this.state.tableDisplay}
                    />
                </div>
                <CardActions style={{ float: "right" }} >
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => { this.props.close() }}
                    >
                        Done
                    </Button>
                </CardActions>
            </Card>
        </Dialog>;
    }
}