import {
    NgsReportInstrumentPrepStandardizationVM,
} from "$Generated/api";
import {
    React,
    _,
  } from "$Imports/Imports";
  
  
import { NavigationService } from "$State/NavigationFreezerService";
import { Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
var urljoin = require('url-join');

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    tempLabelDiv: string;
    tempDiv: string;
    tempField: string;
    errorIcon: string;
    table: string;
} = require("../../../css/common.scss");


const wetLabStyles: {
    analysisTableHeader : string;
    pageLink : string;
    textEmphasis : string;
} = require("./WetLabReport.scss");


interface IWetLabStandardizationOfInstrumentPrepProps {
    workflowRunId : string;
    dataInstrumentStandardization: NgsReportInstrumentPrepStandardizationVM | undefined;
};

interface IWetLabStandardizationOfInstrumentState{
    stepInstrumentSetUpId: string;
    stepPrepFlowCellId: string;
}

export class WetLabStandardizationOfInstrumentPrep extends React.Component<IWetLabStandardizationOfInstrumentPrepProps, IWetLabStandardizationOfInstrumentState> {

    state : IWetLabStandardizationOfInstrumentState = {
        stepInstrumentSetUpId: '',
        stepPrepFlowCellId: '',
    }

    async componentDidMount(){
        
    }
    
    render() {

        // Get the guids for the step instances.
        if (this.props.dataInstrumentStandardization){
            const oTmp = this.props.dataInstrumentStandardization;
            oTmp.Steps?.map( s => {
                switch(s.StepNumber){
                    case 19:
                        if (this.state.stepInstrumentSetUpId === '') this.setState({stepInstrumentSetUpId: (s.StepInstanceId ? s.StepInstanceId : '')});
                        break;
                    case 20:
                        if (this.state.stepPrepFlowCellId === '') this.setState({stepPrepFlowCellId: (s.StepInstanceId ? s.StepInstanceId : '')});
                        break;
                }
                
            });
        }
        
        // Table headers for (LL-2801)
        var colHeadersStandardizationWetLabWorkflow = ['', 'Pre-Loading Count', 'Post-Loading Count', 'Post-Loading/Pre-Loading Ratio'];
        
        // Pre and post-loading counts.
        const tableData = this.props.dataInstrumentStandardization;
        const nPreLoadingPoresCount : number = tableData?.PreLoadingCount ? tableData.PreLoadingCount : 0;
        const nPostLoadingPoresCount : number = tableData?.PostLoadingCount ? tableData.PostLoadingCount : 0;

        return (
            
            <div>
                <h2>Standardization of Instrument Preparation</h2>
                <ol>
                    <li><section className={wetLabStyles.textEmphasis}>Qubit-measured Input Mass for DNA repair:</section>
                    Guidance is present in <a onClick={e => this.clickLink(e, this.state.stepInstrumentSetUpId)} className={wetLabStyles.pageLink}>Step #19: Instrument Set Up</a> to ensure X-Y ng of DNA is loaded into the instrument.</li>
                    <li><section className={wetLabStyles.textEmphasis}>Quantity of Good Quality Library into the Flow Cell:</section>
                    Guidance is present in <a onClick={e => this.clickLink(e, this.state.stepPrepFlowCellId)} className={wetLabStyles.pageLink}>Step #20: Prep Flow Cell</a> to ensure the following quantities of good quality library are loaded into the user's flow cell:</li>
                    <li><section className={wetLabStyles.textEmphasis}>Heatsink Temperatures During Pre-loading and Post-loading:</section>
                    The MinKNOW application indicates the Heatsink temperature during pre-loading and post-loading. Guidance is present in <a onClick={e => this.clickLink(e, this.state.stepPrepFlowCellId)} className={wetLabStyles.pageLink}>Step #20: Prep Flow Cell</a> to ensure that the temperature does not exceed 34{'\u00b0'}C.</li>
                    <li><section className={wetLabStyles.textEmphasis}>Ratio of Active Pores:</section>
                    The number of active pores should be 800 or greater at all times. The ratio of active pores during post-and pre-loading indicate the quality of the run.</li>
                </ol>

                <TableContainer component={Paper} >
                <Table className={commonStyles.table}>
                    <TableHead>
                        <TableRow>
                            {colHeadersStandardizationWetLabWorkflow.map(h => (
                                <TableCell key={h} style={{fontWeight: 'bold', textAlign: 'center'}}>{h}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell style={{fontWeight: 'bold'}}>Active Pores</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{nPreLoadingPoresCount.toString()}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{nPostLoadingPoresCount.toString()}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{(nPostLoadingPoresCount / nPreLoadingPoresCount).toString()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>

                <section>*Post-loading/Pre-loading ratio is an indicator of instrument preparation quality.</section>
                
            </div>

        )
      
    }

    // Click event for links to workflow run steps.
    clickLink = (event : React.MouseEvent<HTMLAnchorElement>, stepGuid : string) => {
        event.preventDefault();
        // Ensure the stepGuids are loaded before trying to navigate.
        if (stepGuid != '' ) this.navigateToWorkflowStep(stepGuid);
    }
    
    private navigateToWorkflowStep(stepGuid : string) : void {
        const navigateToUrl = urljoin('/WorkflowRun', this.props.workflowRunId, 'step', stepGuid);
        NavigationService.navigateTo(navigateToUrl);
    }

}
