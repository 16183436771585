import {
    React,
    bind
} from "$Imports/Imports";

import {
    StepStatus, StepInstanceVM
} from "$Generated/api";


const styles: {
    container: string;
    indicator: string;
    text: string;
    notStarted: string;
    inProgress: string;
    completed: string;
    hover: string;
} = require("./StatusElement.scss");

export interface IStatusElementProps {
    workflowStepName: string;
    workflowStepId: string;
    workflowStepStatus: StepStatus;
    handleWorkflowStepClick?: (stepId: string) => void;
}

export class StatusElement extends React.PureComponent<IStatusElementProps> {

    @bind
    switchToWorkflowStep() {
        if (this.props.workflowStepStatus !== 'NotStarted' && this.props.handleWorkflowStepClick) {
            this.props.handleWorkflowStepClick(this.props.workflowStepId);
        }
    }

    render() {

        let {
            workflowStepStatus,
            handleWorkflowStepClick
        } = this.props;
        let statusClass = styles.notStarted;

        if (workflowStepStatus === 'InProgress' || workflowStepStatus === 'Paused') {
            statusClass = styles.inProgress;
        }

        else if (workflowStepStatus === 'Completed' || workflowStepStatus === 'Failed' || workflowStepStatus === 'TechnicalFailure') {
            statusClass = styles.completed;
        }

        let hover = workflowStepStatus !== 'NotStarted' && handleWorkflowStepClick ? styles.hover : ''

        return (
            <div className={styles.container} onClick={this.switchToWorkflowStep}>
                <div className={`${styles.text} ${hover}`}>{this.props.workflowStepName}</div>
                <span className={`${styles.indicator} ${statusClass}`}></span>
            </div>
        )
    }
}