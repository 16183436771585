import { IThemeConfiguration } from "./themeConfiguration";
import { withTheme } from "@material-ui/core";

export const defaultTheme: IThemeConfiguration = {
  themeOptions: {
    palette: {
      primary: {
        main: "#688da7",
      },
      secondary: {
        main: "#ff6e40"
      }
    },
  },
  sideNavigationColor: "#f6f6f6",
  sideNavigationSelectedColor: "#293e6b",
  sideNavigationMenuItemIcon: {
    root: {
      color: "black"
    },
  },
  sideNavigationMenuItem: {
    root: {
      "&.Mui-selected": {
        backgroundColor: "#293e6b",
        color: "white",
        fontWeight: "bold",
        fontSize: "18px"
      },
      '&.Mui-selected > div svg': {
        backgroundColor: '#293e6b',
        color: "white"
      },
      "&.sub": {
        padding: "6px 16px 6px 35px"
      },
      '&:hover': {
        backgroundColor: '#293e6b',
        color: "white"
      },
      '&:hover > div svg': {
        backgroundColor: '#293e6b',
        color: "white"
      },
      color: "black",
      display:"flex",
      justifyContent:"space-between"
    },
  }
};
