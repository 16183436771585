import "./global.types";
import {
  $,
  managedAjaxUtil
} from "$Imports/Imports";

import {
  ApplicationSecuritySettings
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  AuthService
} from "$Utilities/Security/AuthFreezerService";

const baseUrl = `${$("base").attr("href")}`;
// managedAjaxUtil.setBaseUrl(baseUrl);


// User to flag of the existing the fetch function has be wrapped to include
// the authentication token.
const WRAP_FETCH_FLAG: string = "__fetch_is_wrapped_for_windows_auth__";
if (!(WRAP_FETCH_FLAG in window)) {

    // Store a local instance of the current fetch function.
    let oldFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response> = window.fetch;

    // for Edge, bind the old fetch to the window object to avoid "Invalid Calling Object"
    // errors.
    oldFetch = oldFetch.bind(window);
    // Create a new fetch function wrapping the old function.
    // The new function will include the JWT token within the header.
    const newFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const config: RequestInit = init || {};
        config.headers = config.headers || {};
        config.credentials = "include";

        const authSettings: ApplicationSecuritySettings = new ApplicationSecuritySettings();
        if (AuthService.isAuthenticated) {
            // tslint:disable-next-line: no-string-literal
            (config.headers as any)["Authorization"] = `Bearer ${authSettings.applicationJWT}`;
        }

        let response = await oldFetch(input, config);

        if (response.status === 401) {
            
          if (!AuthService.isAuthenticating) {
              await AuthService.refreshAuthentication();
          }
          
          // Wait for authentication to complete before continuing.
          await AuthService.waitForAuthentication();

          (config.headers as any).Authorization = `Bearer ${authSettings.applicationJWT}`;
          const retryResponse = await oldFetch(input, config);
          if (retryResponse.status !== 401) {
            return retryResponse;
          }

          //If we're here we haven't successfully refreshed - we need them to login again
          authSettings.userJWT = null;
          authSettings.applicationJWT = null;
          
          AuthService.login();
        }

        return response;
    };

    (window as any)[WRAP_FETCH_FLAG] = true;

    (window as any).fetch = newFetch;
}

const checkToken: onBeforeFetchEventType = async () => {
  if (!AuthService.isAuthenticating) {
    await AuthService.refreshAuthentication();
  }
};

managedAjaxUtil.setFetch(window.fetch);
managedAjaxUtil.addBeforeOnFetch(checkToken);

declare var __webpack_public_path__: string;
__webpack_public_path__ = baseUrl;

import { Application } from "./Application";
import { SPA } from "@yahara/react-spa";
import { onBeforeFetchEventType } from "$Utilities/managedAjaxUtil";

const el = document.getElementById("reactApp");

const app = (el == null) ? new SPA(baseUrl) : new SPA(baseUrl, el);
app.setRootComponent(Application).render();

// When no module name is provided to module.hot.accept(), it assumes the current
// module:
//   https://github.com/webpack/webpack/blob/7871b6b3dc32425406b3938be490a87aeb6fa794/lib/HotModuleReplacement.runtime.js#L89-L99
declare var module: any;

if (module.hot) {
  module.hot.accept();
}
