import {
    React,
    _,
    bind
} from "$Imports/Imports";

import {
    TextFieldProps,
    TextField
} from "$Imports/MaterialUIComponents";

interface IAdvanceTextFieldBasedProps {
    debounceWaitTime?: number;
    onDebouncedChange?: (newValue: string) => void;
}

interface IAdvanceTextFieldState {
    currentValue?: unknown;
    lastValue?: unknown;
}

export type IAdvanceTextFieldProps = IAdvanceTextFieldBasedProps & TextFieldProps;

export class AdvanceTextField extends React.PureComponent<IAdvanceTextFieldProps, IAdvanceTextFieldState> {

    static defaultProps: Partial<IAdvanceTextFieldProps> = {
        debounceWaitTime: 250,
    };

    state = {
        currentValue: undefined,
        lastValue: undefined
    };

    componentDidMount() {
        this._setDebounceFunction(this.props.debounceWaitTime);
    }

    componentDidUpdate(prevProps: IAdvanceTextFieldProps, prevState: IAdvanceTextFieldState) {
        // Update the debounce function if the value changes.
        if (this.props.debounceWaitTime !== prevProps.debounceWaitTime) {
            this._setDebounceFunction(this.props.debounceWaitTime);
        }
    }

    // The value property is stored in the state to allow typing during the
    // debounce.  The function updates state if the value property changes.
    static getDerivedStateFromProps(props: IAdvanceTextFieldProps, state: IAdvanceTextFieldState): Partial<IAdvanceTextFieldState> | null {

        // Update the state of the lastValue does not match the prop.
        if (state === null || props.value !== state.lastValue) {
            return {
                currentValue: props.value,
                lastValue: props.value
            };
        }

        // Do nothing if the value does not change.
        return null;
    }

    @bind
    private _onChange(e: React.ChangeEvent<HTMLInputElement>): void {

        const updatedValue = e.target.value;

        this.setState({
            currentValue: updatedValue,
        });

        if (this.props.onChange) {
            this.props.onChange(e);
        }

        this._raiseDebounceChange(updatedValue);
    }

    private async _raiseDebounceChange(newValue: string) {
        if (this._onDebounceChange_debounce) {
            this._onDebounceChange_debounce(newValue);
        }
    }

    private _onDebounceChange_debounce: (((newValue: string) => void) & _.Cancelable) | null = null;

    private _onDebounceChange(newValue: string) {
        if (this.props.onDebouncedChange) {
            this.props.onDebouncedChange(newValue);
        }
    }

    private _setDebounceFunction(timeout: number = 500): void {
        this._onDebounceChange_debounce = _.debounce(this._onDebounceChange, this.props.debounceWaitTime ? this.props.debounceWaitTime : 500, {
            trailing: true,
        });
    }

    render() {
        const {
            onChange,
            value,
            onDebouncedChange,
            debounceWaitTime,
            ...passthroughProps
        } = this.props;

        const {
            currentValue
        } = this.state;

        return (
            <TextField
                {...passthroughProps}
                value={currentValue}
                onChange={this._onChange}
            />
        );
    }
}
