import {
    React,
    bind,
    moment,
    _,
    ReactGA
} from "$Imports/Imports";

import {
    Card,
    Button,
    Checkbox,
    Dialog,
    TextField,
    DialogActions,
} from "$Imports/MaterialUIComponents";

var urljoin = require('url-join');

import
    * as s
    from "underscore.string";

import {
    Send
} from "$Imports/MaterialUIIcons";

import {
    WorkflowVM, WellContentVM, SampleVM, UpdateSamplePriority, ReagentVM, ReagentGroupVM, ReagentGroupLotVM, ReagentLotVM, WorkflowPTCAndNTCCheck
} from "$Generated/api";

import {
    WorkflowsService,
    IWorkflowsServiceInjectedProps
} from "$State/WorkflowsFreezerService";

import {
    DataTable,
    IDataTableColumn,
    directionType,
    AjaxActionIndicator,
    SampleInfoTable,
    AdvanceTextField
} from "$Imports/CommonComponents";

import {
    NavigationService
} from "$State/NavigationFreezerService";
import { ChangeEvent } from "react";
import { Chip, Link } from "@material-ui/core";

const styles: {
    mainContainer: string;
    cardStyle: string;
    cardHeader: string;
    cardAction: string;
    tableHeader: string;
    button: string;
    buttonDiv: string;
    dialog: string;
} = require("./Workflows.scss");

interface IWorkflowsPageBaseProps {

}

type PriorityEnum = "Prioritized" | "NotPrioritized";

interface IWorkflowsPageState {
    samplesAsWellContents: WellContentVM[];
    prioritySelectOpen: boolean;
    maxSamplesSelectOpen: boolean;
    maxSamples: number | undefined;
    workflow?: WorkflowVM;
    priorityDictionary: { [index: string]: PriorityEnum };
    associationFailedOpen: boolean;
    deactivatedReagentsOpen: boolean;
    selectLotsOpen: boolean;
    selectedReagentLotsDictionary: { [index: string]: { lot: (ReagentLotVM | ReagentGroupLotVM), kitId: string } };
    stepViewStructure: { step: string, reagents: (ReagentVM | ReagentGroupVM)[] }[];
    viewByStep: boolean;
    notAllAssociatedOpen: boolean;
    PTCAndNTCCheck: WorkflowPTCAndNTCCheck | null | undefined;
    checkGriddingMaximum: boolean;
    showMaxGridError: boolean;
    maxNumberOfWellsAvailable: number;
    windowPixelRatio: number;
}

export type IWorkflowsPageProps = IWorkflowsPageBaseProps & IWorkflowsServiceInjectedProps

class _WorkflowsPage extends React.Component<IWorkflowsPageProps, IWorkflowsPageState> {

    state: IWorkflowsPageState = {
        prioritySelectOpen: false,
        maxSamplesSelectOpen: false,
        samplesAsWellContents: [],
        priorityDictionary: {},
        maxSamples: undefined,
        associationFailedOpen: false,
        deactivatedReagentsOpen: false,
        selectLotsOpen: false,
        selectedReagentLotsDictionary: {},
        stepViewStructure: [],
        viewByStep: true,
        notAllAssociatedOpen: false,
        PTCAndNTCCheck: null,
        checkGriddingMaximum: false,
        showMaxGridError: false,
        maxNumberOfWellsAvailable: 96,
        windowPixelRatio: 1
    }

    private readonly workflowColumns: Array<IDataTableColumn<WorkflowVM>> = [
        {
            columnName: "Name",
            columnFieldData: "Name",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
            sortMethod: (d) => d.Name ?? "",
        },
        {
            columnName: "Test Name",
            columnFieldData: (d) => d.Test && d.Test.Name || "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Test Name",
            sortMethod: (d) => d.Test && d.Test.Name || ""
        },
        {
            columnName: "Test Type",
            columnFieldData: (d) => d.Test && d.Test.Type || "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Test Type",
            sortMethod: (d) => d.Test && d.Test.Type || ""
        },
        {
            columnName: "Sample Count",
            columnFieldData: "MatchingSampleCount",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Number of Samples",
            sortMethod: (d) => d.MatchingSampleCount
        },
        {
            columnName: "Prioritize",
            columnFieldData: (d) => {
                return (
                    <Button
                        onClick={() => { this.beginPrioritizeSamples(d) }}
                        color="primary"
                        variant="contained"
                        disabled={!d.MatchingSampleCount || d.MatchingSampleCount === 0}
                    >
                        Prioritize
                    </Button>)
            },
            headerValue: ""
        },
        {
            columnName: "Start Workflow",
            columnFieldData: (d) => {
                return (
                    <Button
                        onClick={() => { this.openMaxLoadDialog(d) }}
                        color="primary"
                        variant="contained"
                        endIcon={<Send />}
                        disabled={!d.MatchingSampleCount || d.MatchingSampleCount === 0}
                    >
                        Begin
                    </Button>)
            },
            headerValue: ""
        }

    ];

    private sampleColumns(): Array<IDataTableColumn<WellContentVM>> {
        return [
            {
                columnName: "sample-id",
                columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Sample ID",
                sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
            },
            {
                columnName: "sample-date-harvested",
                columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Date Harvested",
                sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
            },
            {
                columnName: "sample-is-prioritized",
                columnFieldData: (d) => {
                    return (<Checkbox
                        checked={(d.Sample && d.Sample.IsPriority)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.samplePriorityChanged(d.Sample && d.Sample.Id ? d.Sample.Id : "", event.target.checked) }}
                    />);
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Prioritized",
                sortMethod: (d) => (d.Sample ? d.Sample.IsPriority : "") ?? ""
            },
        ];
    }

    private reagentColumns(): Array<IDataTableColumn<ReagentVM | ReagentGroupVM>> {
        let columns: Array<IDataTableColumn<ReagentVM | ReagentGroupVM>> = [
            {
                columnName: "kit-name",
                columnFieldData: (d) => <div>{this.isReagent(d) ? d.KitName : ""}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Kit Name",
            },
            {
                columnName: "reagent-name",
                columnFieldData: (d) => <div>{d.Name}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Name",
            },
            {
                columnName: "step-names",
                columnFieldData: (d) => <div>{_.map(d.UsedInSteps, step => <div>{step.Item1}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Steps",
            },
            {
                columnName: "lot-number",
                columnFieldData: (d) => {
                    let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                    data = _.union(data, d.Lots);
                    let isGroup = !this.isReagent(d);
                    let mapId = this.isReagent(d) ? (d.Id || "") + (d.KitName || "") : d.Id;
                    let isKit = this.isReagent(d) ? d.KitId !== undefined : false;
                    return <div>{_.map(_.sortBy(data.filter((lot, i, arr) => (arr.findIndex(t => t.LotNumber === lot.LotNumber) === i) && lot.IsActive && new Date(lot.ExpirationDate) > new Date(Date.now())), d => d.CreatedOn).reverse(), (l) => {
                    
                        let temp = l as ReagentLotVM | ReagentGroupLotVM;
                        if (temp.LotNumber !== undefined) {
                            let selected = _.find(this.state.selectedReagentLotsDictionary, r => r.lot.Id === l.Id) !== undefined;
                            return <Chip
                                label={temp.LotNumber}
                                key={temp.Id}
                                style={{ minWidth: "50px", margin: "5px", backgroundColor: selected ? "green" : "" }}
                                onClick={() => { selected ? this.removeWorkflowReagent(mapId || "", this.isReagent(d) ? d : undefined) : this.addWorkflowReagent(mapId || "", temp, this.isReagent(d) ? d : undefined); }}
                            />;
                        }
                        return <></>;
                    })}
                        <AdvanceTextField
                            disabled={this.state.selectedReagentLotsDictionary[mapId || ""] !== undefined && !this.state.selectedReagentLotsDictionary[mapId || ""].lot.IsNew}
                            value={this.state.selectedReagentLotsDictionary[mapId || ""] !== undefined && this.state.selectedReagentLotsDictionary[mapId || ""].lot.IsNew ? this.state.selectedReagentLotsDictionary[mapId || ""].lot.LotNumber : ""}
                            onDebouncedChange={(value) => {
                                isGroup ?
                                    this.addWorkflowReagent(mapId || "", {
                                        LotNumber: value,
                                        Id: "",
                                        IsActive: true,
                                        CreatedOn: new Date(Date.now()),
                                        CreatedBy: "",
                                        UsedInRun: "NotUsed",
                                        IsNew: true,
                                        ReagentLots: [],
                                        ReagentGroupId: d.Id,
                                        ExpirationDate: new Date()
                                    }, this.isReagent(d) ? d : undefined)
                                    :
                                    this.addWorkflowReagent(mapId || "", {
                                        LotNumber: value,
                                        Id: "",
                                        IsActive: true,
                                        CreatedOn: new Date(Date.now()),
                                        CreatedBy: "",
                                        UsedInRun: "NotUsed",
                                        IsNew: true,
                                        ReagentId: d.Id,
                                        ReagentName: "",
                                        ReagentDescription: "",
                                        ExpirationDate: new Date()
                                    }, this.isReagent(d) ? d : undefined)
                            }}

                        />
                    </div>;
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Lot/Ref Number",
            }
        ];
        return columns;
    }

    private reagentColumnsByStep(): Array<IDataTableColumn<{ step: string, reagents: (ReagentVM | ReagentGroupVM)[] }>> {
        return [
            {
                columnName: "step",
                columnFieldData: "step",
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Step",
            },
            {
                columnName: "kit-name",
                columnFieldData: (d) => <div>{_.map(d.reagents, reagent => <div style={{ height: "32px" }}>{this.isReagent(reagent) ? reagent.KitName : ""}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Kit Name",
            },
            {
                columnName: "reagent-name",
                columnFieldData: (d) => <div>{_.map(d.reagents, reagent => <div style={{ height: "32px" }}>{reagent.Name}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Reagent Name",
            },

            {
                columnName: "lot-number",
                columnFieldData: (d) => {
                    return _.map(d.reagents, reagent => {
                        let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                        data = _.union(data, reagent.Lots);
                        let isGroup = !this.isReagent(reagent);
                        let mapId = this.isReagent(reagent) ? (reagent.Id || "") + (reagent.KitName || "") : reagent.Id;
                        let isKit = this.isReagent(reagent) ? reagent.KitId !== undefined : false;
                        return <div>{_.map(_.sortBy(data.filter((lot, i, arr) => (arr.findIndex(t => t.LotNumber === lot.LotNumber) === i) && lot.IsActive && new Date(lot.ExpirationDate) > new Date(Date.now())), d => d.CreatedOn).reverse(), (l) => {
                            
                            let temp = l as ReagentLotVM | ReagentGroupLotVM;
                            if (temp.LotNumber !== undefined) {
                                let selected = _.find(this.state.selectedReagentLotsDictionary, r => r.lot.Id === l.Id) !== undefined;
                                return <Chip
                                    label={temp.LotNumber}
                                    key={temp.Id}
                                    style={{ minWidth: "50px", margin: "5px", backgroundColor: selected ? "green" : "" }}
                                    onClick={() => { selected ? this.removeWorkflowReagent(mapId || "") : this.addWorkflowReagent(mapId || "", temp, this.isReagent(reagent) ? reagent : undefined); }}
                                />;
                            }
                            return <></>;
                        })}
                            <AdvanceTextField
                                disabled={this.state.selectedReagentLotsDictionary[mapId || ""] !== undefined && !this.state.selectedReagentLotsDictionary[mapId || ""].lot.IsNew}
                                value={this.state.selectedReagentLotsDictionary[mapId || ""] !== undefined && this.state.selectedReagentLotsDictionary[mapId || ""].lot.IsNew ? this.state.selectedReagentLotsDictionary[mapId || ""].lot.LotNumber : ""}
                                onDebouncedChange={(value) => {
                                    let isKit = this.isReagent(reagent) ? reagent.KitId !== undefined : false;
                                    isGroup ?
                                        this.addWorkflowReagent(mapId || "", {
                                            LotNumber: value,
                                            Id: "",
                                            IsActive: true,
                                            CreatedOn: new Date(Date.now()),
                                            CreatedBy: "",
                                            UsedInRun: "NotUsed",
                                            IsNew: true,
                                            ReagentLots: [],
                                            ReagentGroupId: reagent.Id,
                                            ExpirationDate: new Date()
                                        }, this.isReagent(reagent) ? reagent : undefined)
                                        :
                                        this.addWorkflowReagent(mapId || "", {
                                            LotNumber: value,
                                            Id: "",
                                            IsActive: true,
                                            CreatedOn: new Date(Date.now()),
                                            CreatedBy: "",
                                            UsedInRun: "NotUsed",
                                            IsNew: true,
                                            ReagentId: reagent.Id,
                                            ReagentName: "",
                                            ReagentDescription: "",
                                            ExpirationDate: new Date()
                                        }, this.isReagent(reagent) ? reagent : undefined)
                                }}

                            />
                        </div>;
                    })
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Lot/Ref Number",
            }
        ];
    }

    componentDidMount() {
        this.props.workflowsService.fetchWorkflows(true);
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    @bind
    private updateDimensions() {
        this.setState({ windowPixelRatio: window.devicePixelRatio });
    }

    @bind
    async openMaxLoadDialog(workflow: WorkflowVM) {
        if (workflow.AllComponentSetAssociationsMet) {
            if (workflow.DeactivatedReagents.length === 0) {
                this.setState({ maxSamplesSelectOpen: true, workflow: workflow });
            }
            else {
                this.setState({ deactivatedReagentsOpen: true, workflow: workflow });
            }
        }
        else {
            this.setState({ associationFailedOpen: true });
        }
        await this.props.workflowsService.getPTCAndNTCCheck(workflow.Id!);
        await this.props.workflowsService.fetchWorkflowStepsWithInitiationPoints(workflow.Id!, "NotApplicable");
        let {
            workflowPTCAndNTCCheck, workflowStepWithInitiationPointsResults
        } = this.props.workflowsService.getState();
        var firstStep = _.find(workflowStepWithInitiationPointsResults.data, step => step.WorkflowOrder == 1)
        this.setState({ PTCAndNTCCheck: workflowPTCAndNTCCheck.data, checkGriddingMaximum: true });

        // Anthrax has only 8 well slots available. Influenza and COVID have 96.
        let defaultWellCount = 96;
        if (workflow.Name == "Anthrax")
            defaultWellCount = 8;
        // Adjust the maxNumberOfWellsAvailable based on the capacity of wells and the PTC/NTC requirements.
        if (this.state.PTCAndNTCCheck)
            defaultWellCount = Math.max(defaultWellCount - (this.state.PTCAndNTCCheck?.NTCMinimum + this.state.PTCAndNTCCheck?.PTCMinumum), 0)
        // Take the minimum of the plate size or the remaining samples.
        if (this.state.maxSamples)
            defaultWellCount = Math.min(defaultWellCount, this.state.maxSamples);
        this.setState({ maxNumberOfWellsAvailable: defaultWellCount });
    }

    @bind
    beginWorkflow() {
        if (this.state.workflow && this.state.workflow.WorkflowId) {
            this.setState({ prioritySelectOpen: false, maxSamplesSelectOpen: false });
            this.props.workflowsService.startWorkflowTask(this.state.workflow.WorkflowId, this.state.maxSamples || 88, async (workflowInstanceId) => {
                await this.props.workflowsService.setWorkflowRunReagents(workflowInstanceId, this.state.selectedReagentLotsDictionary);
                NavigationService.navigateTo(urljoin("/WorkflowRun", workflowInstanceId, "step", "first"));
            });
        }
    }

    @bind
    private async beginPrioritizeSamples(workflow: WorkflowVM) {
        var testId = workflow.Test ? workflow.Test.Id : "";
        await this.props.workflowsService.getSamples(testId);

        const samples = this.props.workflowsService.getState().workflowSampleResults.data;

        if (samples) {
            let samplesWithPositions = samples.filter(s => s.WellPosition !== undefined);

            const sampleAsWellContent: WellContentVM[] =
                _.map(samplesWithPositions, s => {
                    let wellContent: WellContentVM =
                    {
                        Id: s.Id,
                        Sample: s,
                        WellPosition: s.WellPosition!,
                        RunCount: 1
                    };
                    return wellContent;
                });

            this.setState({ samplesAsWellContents: sampleAsWellContent, prioritySelectOpen: true, priorityDictionary: {} });
        }

    }

    @bind
    private samplePriorityChanged(sampleId: string, isPriority: boolean) {
        const priorityDictionary = _.clone(this.state.priorityDictionary);
        const samplesAsWellContents = _.clone(this.state.samplesAsWellContents);

        let sampleToChange = _.find(samplesAsWellContents, w => w.Id === sampleId);
        if (sampleToChange && sampleToChange.Sample) {
            let s = _.clone(sampleToChange.Sample); //Can't directly change the priority?
            s.IsPriority = isPriority;
            sampleToChange.Sample = s;
            if (priorityDictionary[sampleId]) {
                delete priorityDictionary[sampleId];

            }
            else {
                priorityDictionary[sampleId] = (isPriority ? "Prioritized" : "NotPrioritized");
            }

            this.setState({ priorityDictionary: priorityDictionary, samplesAsWellContents: samplesAsWellContents });
        }
        this.forceUpdate();
    }


    render() {
        let state = this.props.workflowsService.getState();
        let { workflowFetchResults, fetchWorkflowReagentsResults } = state;
        let reagentsData: (ReagentVM | ReagentGroupVM)[] = [];
        reagentsData = _.union(reagentsData, fetchWorkflowReagentsResults.data?.ReagentGroups, fetchWorkflowReagentsResults.data?.Reagents);

        return (
            <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    <AjaxActionIndicator
                        state={workflowFetchResults}
                    />
                    <h2 className={styles.cardHeader}>Workflows</h2>
                    {
                        workflowFetchResults.hasFetched && workflowFetchResults.data &&
                        <DataTable
                            data={workflowFetchResults.data}
                            columns={this.workflowColumns}
                        />
                    }
                </Card>
                {this.state.prioritySelectOpen &&
                    <Dialog
                        open={this.state.prioritySelectOpen}
                        onClose={this.handleClose}
                        maxWidth={"lg"}
                    >
                        <SampleInfoTable
                            header={"Samples"}
                            columns={this.sampleColumns()}
                            data={this.state.samplesAsWellContents}
                            defaultSortColumnName={"sample-date-harvested"} />
                        <div className={styles.buttonDiv}>
                            <Button variant="contained" className={styles.button} onClick={this.updatePriorities}>
                                Save
                            </Button>
                            <Button variant="contained" className={styles.button} onClick={this.handleClose}>
                                Cancel
                            </Button>
                        </div>
                    </Dialog>
                }
                {this.state.maxSamplesSelectOpen &&
                    <Dialog
                        open={this.state.maxSamplesSelectOpen}
                        onClose={this.handleClose}
                    >
                        <div style={{ paddingLeft: ".5em", paddingRight: ".5em" }}>
                            Number of Samples <TextField style={{ paddingLeft: 10 }} type="number" value={this.state.maxSamples || ""} onChange={this.updateMaxLoad} inputProps={{ min: 1, max: this.state.maxNumberOfWellsAvailable }} />
                            {this.state.PTCAndNTCCheck && this.state.checkGriddingMaximum &&
                                <p>Maximum Number of Samples: {this.state.maxNumberOfWellsAvailable}</p>
                            }
                            {this.state.showMaxGridError &&
                                <p style={{ color: "red" }}>The Plate Load cannot exceed the size of the given plate</p>
                            }
                            <div className={styles.buttonDiv}>
                                <Button variant="contained" color="primary" disabled={this.state.maxSamples === undefined || this.state.showMaxGridError} className={styles.button} onClick={async () => {
                                    await this.props.workflowsService.fetchWorkflowReagents(this.state.workflow?.WorkflowId || "", true);

                                    let fetchWorkflowReagentsResults = this.props.workflowsService.getState().fetchWorkflowReagentsResults;
                                    let reagentsData: (ReagentVM | ReagentGroupVM)[] = [];
                                    reagentsData = _.union(reagentsData, fetchWorkflowReagentsResults.data?.ReagentGroups, fetchWorkflowReagentsResults.data?.Reagents);

                                    let stepViewStructure: { [index: string]: { reagents: (ReagentVM | ReagentGroupVM)[], index: number } } = {};

                                    _.forEach(reagentsData, reagent => {
                                        _.forEach(reagent.UsedInSteps, stepTuple => {
                                            if (stepViewStructure[stepTuple.Item1 || ""] === undefined) {
                                                stepViewStructure[stepTuple.Item1 || ""] = { index: stepTuple.Item2 || 0, reagents: [] };
                                            }
                                            stepViewStructure[stepTuple.Item1 || ""].reagents.push(reagent);
                                        });
                                    });
                                    var results = _.map(stepViewStructure, (orderedReagents, idx) => {
                                        return { step: idx, reagents: orderedReagents.reagents, order: orderedReagents.index };
                                    });
                                    var orderedResults = _.orderBy(results, r => r.order);

                                    this.setState({ maxSamplesSelectOpen: false, selectLotsOpen: true, stepViewStructure: orderedResults });
                                }}>
                                    Start
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                }
                <Dialog
                    PaperProps={{ style: { maxWidth: "700px" } }}
                    open={this.state.associationFailedOpen}
                    onClose={this.handleClose}
                    className={styles.dialog}
                >
                    Some workflow steps do not have required components associated. This run cannot be started.
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.handleClose() }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    PaperProps={{ style: { maxWidth: "700px" } }}
                    open={this.state.deactivatedReagentsOpen}
                    onClose={this.handleClose}
                >
                    {_.map(this.state.workflow?.DeactivatedReagents, r => <div>Reagent {r} is deactivated. </div>)}
                    <div>This run cannot be started.</div>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.handleClose() }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    PaperProps={{ style: { maxWidth: "1500px" } }}
                    open={this.state.selectLotsOpen}
                    onClose={this.handleClose}
                >
                    <Card>
                        <h2>Components</h2>
                        <h3>Go to the Assets tab to edit components and add/edit lot numbers.</h3>
                        <Link>
                            <div onClick={() => {
                                this.setState({ viewByStep: !this.state.viewByStep })
                            }}>
                                {this.state.viewByStep ? "View by Component" : "View by Workflow Step"}
                            </div>
                        </Link>

                        <div style={{ overflow: "auto", height: 450 / (this.state.windowPixelRatio * this.state.windowPixelRatio) + "px" }}>
                            {this.state.viewByStep ?
                                <DataTable
                                    columns={this.reagentColumnsByStep()}
                                    data={this.state.stepViewStructure}
                                />
                                :
                                <DataTable
                                    columns={this.reagentColumns()}
                                    data={reagentsData}
                                />
                            }
                        </div>
                    </Card>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                let selectedCount = 0;
                                _.forEach(this.state.selectedReagentLotsDictionary, d => selectedCount++);
                                if (selectedCount !== reagentsData.length) {
                                    this.setState({ notAllAssociatedOpen: true })
                                }
                                else {
                                    this.beginWorkflow();
                                }
                            }}
                        >
                            Continue
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.handleClose() }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    PaperProps={{ style: { maxWidth: "700px" } }}
                    open={this.state.notAllAssociatedOpen}
                    onClose={() => this.setState({ notAllAssociatedOpen: false })}
                    className={styles.dialog}
                >
                    You have not specified lot numbers for some components that this run will use.
                    <DialogActions>
                        {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.beginWorkflow(); }}
                        >
                            Continue
                        </Button> */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.setState({ notAllAssociatedOpen: false })}
                        >
                            Change Lot Numbers
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    @bind
    updateMaxLoad(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.setState({ maxSamples: undefined });
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue) {
                this.setState({ maxSamples: numericValue });
                if (this.state.checkGriddingMaximum && numericValue > this.state.maxNumberOfWellsAvailable) {
                    this.setState({ showMaxGridError: true });
                }
                else {
                    this.setState({ showMaxGridError: false });
                }
            }
        }
    }

    @bind
    private handleClose() {
        this.setState({ prioritySelectOpen: false, maxSamplesSelectOpen: false, associationFailedOpen: false, deactivatedReagentsOpen: false, workflow: undefined, selectLotsOpen: false, notAllAssociatedOpen: false });
    }

    @bind
    private async updatePriorities() {
        const priorities: UpdateSamplePriority[] = [];
        for (var sampleId in this.state.priorityDictionary) {
            priorities.push({
                Id: sampleId,
                NewPriority: (this.state.priorityDictionary[sampleId] === "Prioritized")
            });
        }

        await this.props.workflowsService.updateSamplePriorities(priorities)
        this.setState({ prioritySelectOpen: false });
    }

    @bind
    private addWorkflowReagent(reagentId: string, reagentLot: ReagentLotVM | ReagentGroupLotVM, reagent?: ReagentVM) {
        let newReagents = _.cloneDeep(this.state.selectedReagentLotsDictionary);

        newReagents[reagentId] = { lot: reagentLot, kitId: reagent?.KitId || "" };


        if (reagent && reagent.KitName) {
            //kits
            //loop all reagents, if kit id = reagent.kitId, find where lot number = same and set.
            let state = this.props.workflowsService.getState();
            let { fetchWorkflowReagentsResults } = state;
            _.forEach(fetchWorkflowReagentsResults.data?.Reagents, r => {
                if (r.KitId === reagent.KitId && r.Id !== reagent.Id) {
                    var lot = _.find(r.Lots, l => l.LotNumber === reagentLot.LotNumber);
                    if (lot !== undefined) {
                        newReagents[(r.Id || "") + (r.KitName || "")] = { lot: lot, kitId: reagent?.KitId || "" };;
                    }
                    else {
                        if (reagentLot.IsNew) {
                            newReagents[(r.Id || "") + (r.KitName || "")] =
                            {
                                lot: {
                                    LotNumber: reagentLot.LotNumber,
                                    Id: "",
                                    IsActive: true,
                                    CreatedOn: new Date(Date.now()),
                                    CreatedBy: "",
                                    UsedInRun: "NotUsed",
                                    IsNew: true,
                                    ReagentId: r.Id,
                                    ReagentName: "",
                                    ReagentDescription: "",
                                    ExpirationDate: new Date()
                                },
                                kitId: reagent?.KitId || ""
                            };
                        }
                    }
                }
            });
        }

        this.setState({ selectedReagentLotsDictionary: newReagents });
    }

    @bind
    private removeWorkflowReagent(reagentId: string, reagent?: ReagentVM) {
        let newReagents = _.cloneDeep(this.state.selectedReagentLotsDictionary);
        delete newReagents[reagentId];

        //kits
        if (reagent && reagent.KitName) {
            let state = this.props.workflowsService.getState();
            let { fetchWorkflowReagentsResults } = state;
            _.forEach(fetchWorkflowReagentsResults.data?.Reagents, r => {
                if (r.KitId === reagent.KitId && r.Id !== reagent.Id) {
                    delete newReagents[(r.Id || "") + (r.KitName || "")];
                }
            });
        }

        this.setState({ selectedReagentLotsDictionary: newReagents });
    }

    private isReagent(r: ReagentVM | ReagentGroupVM): r is ReagentVM {
        return (r as ReagentVM).InKits !== undefined;
    }
}

export const WorkflowsPage = WorkflowsService.inject(_WorkflowsPage);
