import {
    React,
    _,
    bind,
} from "$Imports/Imports";

import {
    ComponentSetVM,
    ComponentSetItemVM,
    WorkflowReagentsVM,
    ComponentSetItemInstanceVM
} from "$Generated/api";

import { GetWorkflowType } from "../../WorkflowStep/WorkflowStep";

import {
    AdvanceTextField
} from "$Imports/CommonComponents";

export interface IInstructionSetControlProps {
    componentSetData: ComponentSetVM;
    updateComponentSetDataSteps: (reagents: ComponentSetItemInstanceVM[]) => void;
    disabled: boolean;
    poolOrPlate: PoolOrPlate;
    lotOptions: WorkflowReagentsVM;
    setCanMoveState: (canMove: boolean) => void;
    ignoreFocus?: boolean;
    samples?: number;
}

export interface IInstructionSetControlState {
    showLotNumberError: boolean;
    selectedLotNumber: string[];
}

export type PoolOrPlate = "Pool" | "Plate";

const styles: {
    cell: string;
    cellTopDivider: string;
    lotNumberEntry: string;
    table: string;
    numberCell: string;
    lotNumbercell: string;
    selectLotNumber: string;
} = require("./InstructionSetControl.scss");

import * as s from "underscore.string";
import {
    HorizontalSplitIcon,
    MenuItem,
    TextField
} from "$Imports/MaterialUIComponents";

import { InputAdornment } from "@material-ui/core";

export function missingData(data: ComponentSetVM): boolean {

    let missingData = _.find(data.ComponentSetInstances, setInstance => _.find(setInstance.ComponentSetItemInstances, item => !s.isBlank(item.ReagentId || "") && s.isBlank(item.LotNumber || "")));
    return (missingData !== undefined);
}

export class InstructionSetControl extends React.PureComponent<IInstructionSetControlProps, IInstructionSetControlState> {

    state: IInstructionSetControlState = {
        showLotNumberError: false,
        selectedLotNumber: []
    }

    componentDidMount() {
        let componentSetItemInstances = _.sortBy(this.props.componentSetData!.ComponentSetInstances![0].ComponentSetItemInstances, item => item.Ordinal);
        let initialLotNumberSelection: string[] = [];
        for (let i = 0; i < componentSetItemInstances.length; i++) {
            initialLotNumberSelection[i] = componentSetItemInstances[i].LotNumber!;
        }
        this.setState({ selectedLotNumber: initialLotNumberSelection })
    }

    render() {
        const { componentSetData } = this.props;

        let firstField = true;
        let manualEntryDisabled = GetWorkflowType() === "Anthrax";

        const stepList = componentSetData.ComponentSetInstances ? _.sortBy(componentSetData.ComponentSetInstances[0].ComponentSetItemInstances, item => item.Ordinal).map((componentSetItemInstance, idx) => {
            var setFocus = false;
            if (firstField && !this.props.ignoreFocus) {
                setFocus = true;
                firstField = false;
            }
            if (componentSetData.ComponentSetInstances) {
                return <tr key={idx}>
                    <td className={styles.numberCell}>{componentSetItemInstance.Ordinal}</td>
                    <td className={styles.cell}>{componentSetItemInstance.Description}</td>
                    <td className={styles.cell}>{componentSetItemInstance.ReagentName}</td>
                    <td className={styles.numberCell}>
                        {s.isBlank(componentSetItemInstance.AdditionalInfo || "") ?
                            componentSetItemInstance.TotalVolume === 0 ? "" : Number(componentSetItemInstance.TotalVolume?.toPrecision(3) || "") :
                            (Number(JSON.parse(componentSetItemInstance.AdditionalInfo || "")["multiplicationFactor"]) * (this.props.samples || 0)).toPrecision(3)
                        }
                    </td>
                    {!s.isBlank(componentSetItemInstance.ReagentId || "") ?
                        <td className={styles.lotNumbercell}>
                            <div style={{ flexDirection: "row" }}>
                                <TextField
                                    select
                                    InputLabelProps={{ shrink: true }}
                                    disabled={true} //Disable per LL-2840 //{this.props.disabled || componentSetItemInstance.Locked}
                                    label="Select Lot Number"
                                    id={"lotNumberSelect" + idx}
                                    value={this.state.selectedLotNumber[idx] || ""}
                                    inputProps={{ id: "lotSelect" + idx }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end' style={{ marginRight: "20px" }}>
                                                <HorizontalSplitIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(event) => {
                                        if (event.target.value != "Enter Manually") {
                                            this.lotNumberChanged(event.target.value, componentSetItemInstance.Id)
                                        }
                                        let selectedLots = _.cloneDeep(this.state.selectedLotNumber);
                                        selectedLots[idx] = event.target.value
                                        this.setState({ selectedLotNumber: selectedLots })
                                    }}
                                >
                                    {
                                        _.filter(this.props.lotOptions.Reagents, rg => rg.Id === componentSetItemInstance.ReagentId)
                                            .map(reagent => {
                                                return reagent.Lots
                                                    .map(lot => {
                                                        return <MenuItem key={lot.LotNumber} value={lot.LotNumber}>{lot.LotNumber}</MenuItem>
                                                    })
                                            })
                                    }
                                    {
                                        _.filter(this.props.lotOptions.ReagentGroups, rg => rg.Id === componentSetItemInstance.ReagentId)
                                            .map(reagent => {
                                                return reagent.Lots
                                                    .map(lot => {
                                                        return <MenuItem key={lot.LotNumber} value={lot.LotNumber}>{lot.LotNumber}</MenuItem>
                                                    })
                                            })
                                    }
                                    <MenuItem key={"EnterManually"} value={"Enter Manually"} disabled={manualEntryDisabled}>Enter Manually</MenuItem>
                                </TextField>
                                <AdvanceTextField
                                    autoFocus={setFocus}
                                    disabled={this.props.disabled || componentSetItemInstance.Locked}
                                    value={componentSetItemInstance.LotNumber}
                                    className={styles.lotNumberEntry}
                                    onDebouncedChange={(value) => {
                                        if (componentSetData.ComponentSetInstances) {
                                            this.lotNumberChanged(value, componentSetItemInstance.Id);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <HorizontalSplitIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    style={{ display: this.state.selectedLotNumber[idx] == "Enter Manually" ? 'block' : 'none' }}
                                />
                            </div>
                        </td> :
                        <td className={styles.cell}></td>
                    }
                </tr>
            }
        }) : [];

        return (
            <div>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td colSpan={5}><b>{componentSetData.DisplayHeader}</b></td>
                        </tr>
                        <tr>
                            <th className={styles.numberCell}>Step #</th>
                            <th className={styles.cell}>Description</th>
                            <th className={styles.cell}>Reagent</th>
                            <th className={styles.numberCell}>{this.props.poolOrPlate === "Plate" ? "uL Per Well" : "uL To Add"}</th>
                            <th className={styles.lotNumbercell}>Lot Number</th>
                        </tr>
                        {stepList}
                    </tbody>
                </table>
                {this.state.showLotNumberError && <h3 style={{ color: "red" }}>Instruction Set contains invalid lot numbers. Please add the numbers in the Assets tab.</h3>}
            </div>
        );
    }

    @bind
    private lotNumberChanged(newValue: string, itemInstanceId: string) {
        if (this.props.componentSetData.ComponentSetInstances) {

            let kitId = "";

            let updatedModels = _.map(this.props.componentSetData.ComponentSetInstances[0].ComponentSetItemInstances, r => {
                let newModel = _.cloneDeep(r);
                if (r.Id === itemInstanceId) {
                    //is the lot number valid?
                    let validReagentGroup = _.find(this.props.lotOptions.ReagentGroups, rg => rg.Id === r.ReagentId && _.find(rg.Lots, l => l.LotNumber === newValue) !== undefined);
                    let validReagent = _.find(this.props.lotOptions.Reagents, rg => rg.Id === r.ReagentId && _.find(rg.Lots, l => l.LotNumber === newValue) !== undefined);

                    let currentReagent = _.find(this.props.lotOptions.Reagents, rg => rg.Id === r.ReagentId)
                    if (currentReagent) {
                        kitId = validReagent?.KitId || ""
                    }

                    if (validReagent) {
                        this.setState({ showLotNumberError: false });
                        this.props.setCanMoveState(true);
                        newModel.LotNumber = newValue;
                        newModel.ReagentLotId = _.find(validReagent.Lots, l => l.LotNumber === newValue)?.Id;
                    }
                    else if (validReagentGroup) {
                        this.setState({ showLotNumberError: false });
                        this.props.setCanMoveState(true);
                        newModel.LotNumber = newValue;
                        newModel.ReagentLotId = _.find(validReagentGroup.Lots, l => l.LotNumber === newValue)?.Id;
                    }
                    else {
                        this.setState({ showLotNumberError: true });
                        this.props.setCanMoveState(false);
                    }
                }
                return newModel;
            });

            //Kit validation
            if (kitId !== "") {
                updatedModels = _.map(this.props.componentSetData.ComponentSetInstances[0].ComponentSetItemInstances, r => {
                    let newModel = _.cloneDeep(r);
                    let reagent = _.find(this.props.lotOptions.Reagents, rg => rg.Id === r.ReagentId && _.find(rg.Lots, l => l.LotNumber === newValue) !== undefined);
                    if (reagent && reagent.KitId === kitId) {
                        newModel.LotNumber = newValue;
                        newModel.ReagentLotId = _.find(reagent.Lots, l => l.LotNumber === newValue)?.Id;
                    }
                    return newModel;
                });
            }
            this.props.updateComponentSetDataSteps(updatedModels);
        }

    }
}
