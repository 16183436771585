import { ExchangeDetailsVM } from "$Generated/api";
import { DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Card, IconButton } from "$Imports/MaterialUIComponents";
import { GetAppIcon } from "$Imports/MaterialUIIcons";

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    divPadding: string;
    tablePadding: string;
} = require("./DataExchanges.scss");

import { SharingService, ISharingServiceInjectedProps } from "$State/SharingFreezerService";

interface ISentDataExchangesBaseProps {
}

interface ISentDataExchangesState {
}

type ISentDataExchangesProps = ISentDataExchangesBaseProps & ISharingServiceInjectedProps;

export class _SentDataExchangesPage extends React.Component<ISentDataExchangesProps, ISentDataExchangesState> {

    state: ISentDataExchangesState = {
    }

    async componentDidMount() {
        await this.props.SharingService.getExchangeDetails(false);
    }

    private readonly columns: Array<IDataTableColumn<ExchangeDetailsVM>> = [
        {
            columnName: "date",
            columnFieldData: (d) => {
                if (d.ExchangeDate) {
                    return moment(d.ExchangeDate).format("MM-DD-YY, h:mm a");
                }
                return "";
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Date",
            sortMethod: (d) => d.ExchangeDate ?? ""
        },
        {
            columnName: "receiving-partner",
            columnFieldData: (d) => {
                return d.ReceivingOrganizationName;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Receiving Partner",
            sortMethod: (d) => d.ReceivingOrganizationName ?? ""
        },
        {
            columnName: "data-category",
            columnFieldData: (d) => {
                return d.DataCategory;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Data Category",
            sortMethod: (d) => d.DataCategory ?? ""
        },
        {
            columnName: "data-description",
            columnFieldData: (d) => {
                return JSON.parse(d.JsonPayload)["s3KeyName"];
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Data Description",
            sortMethod: (d) => d.DataCategory ?? ""
        },
        {
            columnName: "exchange-status",
            columnFieldData: (d) => {
                return d.DeliveryStatus === 5 ? "Delivery Failed" : "Delivery Successful";
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Exchange Status",
        },
    ];

    render() {
        const data = this.props.SharingService.getState().exchangeDetailsResult.data || [];

        return <div className={styles.mainContainer}>
            <Card
                className={styles.cardStyle}
            >
                <div className={styles.divPadding}>
                    <h2>Sent Data Exchanges</h2>
                    <i>A complete list of Partner Collaboration Engine data exchanges shared with this site is below.</i>
                </div>
                <div className={styles.tablePadding}>
                    <DataTable
                        data={data}
                        columns={this.columns}
                        defaultSortColumnName="date"
                        defaultSortDirection="desc"
                    />
                </div>
            </Card>
        </div>
    }
}

export const SentDataExchangesPage = SharingService.inject(_SentDataExchangesPage);