import {
    React,
    _,
    bind
} from "$Imports/Imports";

import { Card, Link, Button, FormControlLabel } from "$Imports/MaterialUIComponents";

import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import { ISecurityContext } from "$Utilities/Security/ISecurityContext";
import { CircularProgress, Radio, RadioGroup } from "@material-ui/core";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { DataLoadingDisplay, ProcessAdminRole } from "$Imports/CommonComponents";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    siteButton: string;
} = require("./LimsSelection.scss");


interface ILimsSelectionPageBaseProps {
}

interface ILimsSelectionState {
    selection: string;
    selectionUploading: boolean;
}

type ILimsSelectionPageProps = ILimsSelectionPageBaseProps & ISettingsServiceInjectedProps;

export class _LimsSelectionPage extends React.Component<ILimsSelectionPageProps, ILimsSelectionState> {

    state: ILimsSelectionState = {
        selection: "",
        selectionUploading: false
    }

    componentDidMount() {
        this.props.settingsService.fetchLimsNames(true);
    }

    private _security = new ApplicationSecuritySettings();

    render() {
        var data = this.props.settingsService.getState().limsNamesResults.data;

        if (data) {
            var list = _.map(data, d => <FormControlLabel value={d} control={<Radio />} label={d !== "NoLims" ? d : "No LIMS System"} />)

            let securityContext = this._security.securityContext
            if (securityContext && securityContext.realm_access) {
                const hasRole = _.find(securityContext.realm_access.roles, role => (role === ProcessAdminRole || role === "System Admin"));
                return (
                    <div className={styles.mainContainer}>
                        <Card className={styles.cardStyle}>
                            <div className={styles.content}>
                                {hasRole !== undefined ?
                                    this.state.selectionUploading ?
                                        //Show Spinning circle
                                        <CircularProgress /> :
                                        <div>
                                            <h2>LIMS Options</h2>
                                            <RadioGroup style={{ alignContent: "center", paddingBottom: "20px" }}
                                                name="LIMS Implementation"
                                                value={this.state.selection}
                                                onChange={this.handleRadioChange}>
                                                {list}
                                            </RadioGroup>
                                            <Button variant="contained" onClick={async () => {
                                                if (this.state.selection) {
                                                    this.setState({selectionUploading: true});
                                                    await this.props.settingsService.setLims(this.state.selection);
                                                    await this.props.settingsService.fetchLimsSet(true);
                                                }
                                            }}>
                                                Save
                                        </Button>
                                        </div> :
                                    <h2>LIMS to be used with the application is not set up. Please contact your administrator</h2>}
                            </div>
                        </Card>
                    </div>);
            }
        }


        return <DataLoadingDisplay />;
    }

    @bind
    private handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ selection: (event.target as HTMLInputElement).value });
    }
}

export const LimsSelectionPage = SettingsService.inject(_LimsSelectionPage);