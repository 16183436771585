import { StyledTableCell, StyledTableHeaderRow } from "$Imports/CommonComponents";
import {
  React,
  _
} from "$Imports/Imports";

import { 
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "$Imports/MaterialUIComponents";

import {
  withStyles
} from "$Imports/MaterialUIStyles";

import {
  WorkflowRunService,
  IWorkflowRunServiceInjectedProps
} from "$State/WorkflowRun/WorkflowRunFreezerService";

const styles: {
  mainContainer:string;
  cardStyle:string;
  headerContainer:string;
  title:string;
  run:string;
  sampleLabel:string;
  quantitativeMetricsContainer:string;
  qualitativeMetricsContainer:string;
  metricHeaderLabel:string;
  ampliconSublabel:string;
} = require("./QCStatistics.scss");

interface IQCStatisticsBaseProps {
  runId: string;
  sampleId: string;
}

export type IQCStatisticsProps = IWorkflowRunServiceInjectedProps & IQCStatisticsBaseProps;

class _QCStatistics extends React.Component<IQCStatisticsProps,any> {
  
  async componentDidMount() {
      this.props.workflowRunService.workflowId = this.props.runId;
      this.props.workflowRunService.fetchWorkflowRunSequencingData(true);
      this.props.workflowRunService.fetchWorkflowRun(true);
  }

  render() {
    const { fetchSequencingDataState, workflowRun } = this.props.workflowRunService.freezer.get()
    let sequencingData:any = undefined;
    //Retrieve metadata for Given Sample
    if(fetchSequencingDataState.hasFetched && fetchSequencingDataState.data){
      sequencingData = _.find(fetchSequencingDataState.data, d => d.SampleId === this.props.sampleId)?.MetadataDictionary;
    }

    return (
        <div
          className={styles.mainContainer}
        >
          <Card 
              className={styles.cardStyle}
          >

            <div className={styles.headerContainer}>
              <div className={styles.title}>Sample-Level Bioinformatics Quality Control Metrics - Statistics</div>
              <div className={styles.run}>Run {workflowRun.data && workflowRun.data.RunNumber} SARS-CoV-2 Virus Sequencing Prep</div>
            </div>

            <div className={styles.sampleLabel}>Sample ID {sequencingData && sequencingData.sample}</div>

            <div className={styles.quantitativeMetricsContainer}>
              <div className={styles.metricHeaderLabel}>Quantitative Metrics</div>
              <Table>

                <TableHead>
                  <StyledTableHeaderRow>
                    <StyledTableCell>Metric Name</StyledTableCell>
                    <StyledTableCell>Source</StyledTableCell>
                    <StyledTableCell>Statistics for Coverage</StyledTableCell>
                  </StyledTableHeaderRow>
                </TableHead>

                <TableBody>
        
                  <TableRow>
                    <StyledTableCell>Percent of reference genome control</StyledTableCell>
                    <StyledTableCell>Samtools coverage</StyledTableCell>
                    <StyledTableCell>{sequencingData && sequencingData.breadth_of_coverage_value}</StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>Mean depth of coverage across reference genome</StyledTableCell>
                    <StyledTableCell>Samtools coverage</StyledTableCell>
                    <StyledTableCell>{sequencingData && sequencingData.depth_of_coverage_value && (sequencingData.depth_of_coverage_value + " reads")}</StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>MAmplicon Depth</StyledTableCell>
                    <StyledTableCell>Samtools plot ampliconstants</StyledTableCell>
                    <StyledTableCell>
                      {sequencingData && sequencingData.amplicon_depth_of_coverage_min &&
                      <div>
                        <span className={styles.ampliconSublabel}>Min: </span><span>{sequencingData.amplicon_depth_of_coverage_min + " reads"}</span>
                      </div>}
                      {sequencingData && sequencingData.amplicon_depth_of_coverage_median &&
                      <div>
                        <span className={styles.ampliconSublabel}>Median: </span><span>{sequencingData && (sequencingData.amplicon_depth_of_coverage_median + " reads")}</span>
                      </div>}
                      {sequencingData && sequencingData.amplicon_depth_of_coverage_max && 
                        <div>
                        <span className={styles.ampliconSublabel}>Max: </span><span>{sequencingData && (sequencingData.amplicon_depth_of_coverage_max + " reads")}</span>
                      </div>}
                    </StyledTableCell>
                  </TableRow>
              
                </TableBody>

              </Table>
            </div>
          
            <div className={styles.qualitativeMetricsContainer}>
              <div className={styles.metricHeaderLabel}>Qualitative Metrics</div>
              <Table >

                <TableHead>
                  <StyledTableHeaderRow>
                    <StyledTableCell>Metric Name</StyledTableCell>
                    <StyledTableCell>Source</StyledTableCell>
                  </StyledTableHeaderRow>
                </TableHead>

                <TableBody>
        
                  <TableRow>
                    <StyledTableCell>Average Q score</StyledTableCell>
                    <StyledTableCell>Fast QC</StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>Per bases sequence quality</StyledTableCell>
                    <StyledTableCell>Fast QC</StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>Per base N content across all reads</StyledTableCell>
                    <StyledTableCell>Fast QC</StyledTableCell>
                  </TableRow>
              
                </TableBody>

              </Table>
            </div>

          </Card>
        
       </div>
      )
  }
}



export const QCStatistics = WorkflowRunService.inject(_QCStatistics);