import {
    React,
    _,
    bind
} from "$Imports/Imports";

import { Card, Link } from "$Imports/MaterialUIComponents";

import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";
import { FileService, IFileServiceInjectedProps } from "$State/FileFreezerService";
import { NavigationService } from "$State/NavigationFreezerService";

import { CSVService, ICSVServiceInjectedProps } from "$State/CSVFreezerService";
import { PlayArrow } from "$Imports/MaterialUIIcons";
import { ProductivityBenchmark, TestOrderStatusBoard, TodaysWorkload, UserGuideLinks, YearToDateSampleInfo } from "$Imports/CommonComponents";
import { IDashboardServiceInjectedProps, DashboardService } from "$State/DashboardFreezerService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    navLinksContainer: string;
    navLinksColumn: string;
    navLinksSection: string;
    navLinksSectionHeader: string;
    navLinksSectionBody: string;
    navLink: string;
    navLinkDisabled: string;
} = require("./Home.scss");


interface IHomePageBaseProps {
}

interface IHomePageState {
}

type IHomePageProps = IHomePageBaseProps & IDashboardServiceInjectedProps;

export class _HomePage extends React.Component<IHomePageProps, IHomePageState> {

    async componentDidMount() {
        await this.props.dashboardService.fetchTestOrderStatusBoard();
        await this.props.dashboardService.fetchTodaysWorkload();
        await this.props.dashboardService.fetchProductivityBenchmark();
    }
    state: IHomePageState = {};

    render() {
        return (
            <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
                        <UserGuideLinks width={30} />
                        <TodaysWorkload width={30} />
                        <TestOrderStatusBoard id="testOrderBoard" width={30} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
                        <ProductivityBenchmark id="productivityBoard" width={45} />
                        <YearToDateSampleInfo id="yearToDateSampleInfo" width={45} />
                    </div>
                </Card>
            </div>
        );
    }

    @bind
    private navigate(url: string) {
        NavigationService.navigateTo(url);
    }
}

export const HomePage = DashboardService.inject(_HomePage);