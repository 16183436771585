/******* This is the scroll spy magic */
/*
Credits: Material UI
Source: 
https://github.com/mui-org/material-ui/blob/404c2ba16816f5c7ab7d8b2caf6bbc3d2218b820/docs/src/modules/utils/textToHash.js
*/

//Core was ported from this online example(this example uses tabs, changed to menu item here)
//https://codesandbox.io/s/material-demo-xu80m?file=/index.js


import {
  React,
  _,
} from "$Imports/Imports";
import { findLastIndex, throttle }from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import {
  ThemeConsumer
} from "$Providers/index";

//This accounts for the static app-bar height(ensures the start of the scrollable content is below the app bar)
const appBarHeight = 70;
//This handles the offset of the header(This needs to be made more dynamic)
const headerHeight = 200;

const styles: {
  main:string;
  scrollContentContainer:string;
} = require("./ScrollSpyLayout.scss");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  indicator: {
    padding: theme.spacing(1)
  },
  navBackground: {
    backgroundColor: "rgb(149, 149, 149)",
    position: "fixed",
    top: appBarHeight,
    left: 0,
    width: "200px",
    overflowX:"hidden",
    height:"100%"
  }
}));

const makeUnique:any = (hash:any, unique:any, i:number = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;
  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }
  return makeUnique(hash, unique, i + 1);
};

const textToHash = (text:string, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "")
    ),
    unique
  );
};

const noop = () => {};

interface IScrollSpySection{
  menuText: string,
  component: any,
  icon?:React.ComponentType<SvgIconProps>
}

interface IScrollSpyLayoutProps {
  itemsInScroll:IScrollSpySection[],
  scrollSpyPageHeader:any,
  scrollSpyMenuHeader:any,
  scrollSpyMenuFooter:any
}

function useThrottledOnScroll(callback:any, delay:any) {
  const throttledCallback = React.useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  React.useEffect(() => {
    if (throttledCallback === noop) return undefined;
    window.addEventListener("scroll", throttledCallback);
    return () => {
      window.removeEventListener("scroll", throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
}

function ScrollSpyLayout(props:IScrollSpyLayoutProps) {
  const [activeState, setActiveState] = React.useState(null);
  const { itemsInScroll } = props;
  let itemsServer:any = itemsInScroll.map((tab:IScrollSpySection) => {
    const hash = textToHash(tab.menuText);
    return {
      icon: tab.icon || "",
      text: tab.menuText,
      component: tab.component,
      hash: hash,
      node: document.getElementById(hash)
    };
  });

  const itemsClientRef = React.useRef([]);

  React.useEffect(() => {
    itemsClientRef.current = itemsServer;
  }, [itemsServer]);

  const clickedRef = React.useRef(false);

  const unsetClickedRef:any = React.useRef(null);

  const findActiveIndex = React.useCallback(() => {
    // set default if activeState is null
    if (activeState === null) setActiveState(itemsServer[0].hash);
    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return;
    let active;
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = { hash: null };
        break;
      }
      const item:any = itemsClientRef.current[i];
      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 8 +
            appBarHeight + headerHeight//header height
      ) {
        active = item;
        break;
      }
    }
    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, itemsServer]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166);

  const handleClick = (hash:any) => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);
    if (activeState !== hash) {
      setActiveState(hash);
      if (window)
        window.scrollTo({
          top:
            (document as any).getElementById(hash).getBoundingClientRect().top +
            window.pageYOffset - appBarHeight,//Note: This is the height of the app bar
          behavior: "smooth"
        });
    }
  };

  React.useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    []
  );

  const classes = useStyles();
  return (
    <div className={styles.main}>

      <nav className={classes.navBackground}>
        {props.scrollSpyMenuHeader}
        <ThemeConsumer>
          {(context) => {
              const StylesMenuList = withStyles(context.themeConfig.sideNavigationMenuItemIcon)
              ((props:any) => <MenuList {...props}  />);
              return <StylesMenuList open={true}>
                {itemsServer.map((section:any) => (
                  <MenuItem style={{whiteSpace:"normal"}}
                    key={section.hash}
                    selected= {section.hash == (activeState ? (activeState as any) : (itemsServer[0].hash as any))}
                    onClick={handleClick(section.hash)}
                    value={section.hash}>
                    {section.text}
                  </MenuItem>
                ))}
            </StylesMenuList>
          }}
        </ThemeConsumer>
        {props.scrollSpyMenuFooter}
        <div className={classes.indicator} />
      </nav>

      <div className={styles.scrollContentContainer}>

        {props.scrollSpyPageHeader}

        {itemsServer.map((section:any) => (
          <article id={section.hash} key={section.text}>
            {section.component}
          </article>
        ))}

      </div>
    </div>
  );
}
export default ScrollSpyLayout;