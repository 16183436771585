import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"

import {
    IWorkflowScreenProps,
    GetCurrentStepInputType,
    GetWorkflowType
} from "../WorkflowStep";

import {
    SampleInfoTable,
    IDataTableColumn,
    StepActionsControl,
    StepChangeControl,
    CollapsibleSection,
    DataLoadingDisplay,
    AmpliconQuantificationNTCConcentrationCheck,
    AmpliconQuantificationPTCConcentrationCheck,
    AmpliconQuantificationSampleConcentrationCheck,
    SampleMinConcentration,
    PTCMinConcentration,
    NTCMaxConcentration,
    QCUIComponent,
    MasterMixControl,
    FluorometricQuantificationConcentrationEmpty,
    FluorometricQuantificationLotNumberEmpty
} from "$Imports/CommonComponents";

import {
    QCCheckInstanceVM,
    QCCheckResultVM,
    WellContentVM,
    QCCheckConfigParametersVM,
    QubitSampleVM
} from "$Generated/api";

import {
    ConcentrationVolumeVM
} from "$State/WorkflowRun/WorkflowRunInternalFreezerService"

import {
    Snackbar,
    TextField,
    Button,
    TableCellProps,
    Tooltip,
    ErrorIcon
} from "$Imports/MaterialUIComponents";

import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";

import {
    CSVService
} from "$State/CSVFreezerService";

import { FileService, IFileServiceInjectedProps } from "$State/FileFreezerService";

const styles: {
    tableHeader: string;
    mainDiv: string;
    table: string;
    input: string;
    failRow: string;
    errorIcon: string;
    inputDiv: string;
} = require("./Quantification.scss");

const commonStyles: {
    footerDiv: string;
} = require("./CommonStepStyles.scss");

interface QCParam {
    Id: string;
    Value: number;
}

interface QCConfig {
    [index : string]: QCParam[]
}

export interface IQuantificationState {
    messageSnackbarOpen: boolean;
    messageContent: string;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    dataLoaded: boolean;
    samples: QubitSampleVM[];
    qcConfig: QCConfig;
    wellContents: WellContentVM[];
    canMove: boolean;
    wellVolume: number;
}

type FluorometricQuantificationProps = IWorkflowScreenProps & IFileServiceInjectedProps;

export class _FluorometricQuantificationScreen extends React.Component<FluorometricQuantificationProps, IQuantificationState> {

    state: IQuantificationState = {
        messageSnackbarOpen: false,
        messageContent: "",
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        dataLoaded: false,
        samples: [],
        qcConfig: {},
        wellContents: [],
        canMove: true,
        wellVolume: 0
    };

    @bind
    private cellProps(d: WellContentVM): TableCellProps {
        let { concentrationDictionary } = this.props.workflowRunService;
        let concentrationEntry = concentrationDictionary[d.Id]
        
        let ntcQcParam = this.getQcParam(AmpliconQuantificationNTCConcentrationCheck, "NTCConcentrationValid");
        let ptcQcParam = this.getQcParam(AmpliconQuantificationPTCConcentrationCheck, "PTCConcentrationValid");
        let sameplQcParam = this.getQcParam(AmpliconQuantificationSampleConcentrationCheck, "SampleConcentrationValid");
        let concentrationEntryValue = concentrationEntry && concentrationEntry.Concentration ? concentrationEntry.Concentration : 0;

        if (d.Control && d.Control.Name === "NTC" && ntcQcParam && concentrationEntryValue > ntcQcParam.Value) {
            return {
                className: styles.failRow
            };
        }
        if (d.Control && d.Control.Name === "PTC" && ptcQcParam && concentrationEntryValue <= ptcQcParam.Value) {
            return {
                className: styles.failRow
            };
        }
        if(d.Sample && sameplQcParam && concentrationEntryValue <= sameplQcParam.Value) {
            return {
                className: styles.failRow
            };
        }
        return {
        };
    }

    //Returns the columns for the well contents. Needs to be a function to handle the disabled flag as well as correctly updating within react.
    private wellContentColumns(): Array<IDataTableColumn<WellContentVM>> {
        let {
            concentrationDictionary,
            currentStep,
            currentWorkflowRun
        } = this.props.workflowRunService;

        let disabled = false;

        if (currentStep && currentWorkflowRun) {
            disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
        }
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Sample ID",
                    sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
                },
                {
                    columnName: "sample-well-position",
                    columnFieldData: (d) => ConvertPositionToString(d.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Well Position",
                    sortMethod: (d) => d.WellPosition ?? ""
                },
                {
                    columnName: "sample-date-harvested",
                    columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Date Harvested",
                    sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
                },
                {
                    columnName: "concentration",
                    columnFieldData: (d) => {
                        let concentrationEntry = concentrationDictionary[d.Id];
                        let ntcQcParam = this.getQcParam(AmpliconQuantificationNTCConcentrationCheck, "NTCConcentrationValid");
                        let ptcQcParam = this.getQcParam(AmpliconQuantificationPTCConcentrationCheck, "PTCConcentrationValid");
                        let sameplQcParam = this.getQcParam(AmpliconQuantificationSampleConcentrationCheck, "SampleConcentrationValid");
                        let concentrationEntryValue = concentrationEntry && concentrationEntry.Concentration ? concentrationEntry.Concentration : 0;

                        return (<div>
                            <TextField disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={(concentrationEntry !== undefined && concentrationEntry !== null) ? concentrationEntry.Concentration : ""} onChange={(e) => { this.onConcentrationChanged(d.Id, e.target.value); }} inputProps={{ min: 0 }} />
                            {(d.Control && d.Control.Name === "NTC"  && ntcQcParam && concentrationEntryValue > ntcQcParam.Value) &&
                                <Tooltip
                                    title={"NTC DNA Concrentration cannot be > " + ntcQcParam.Value + " ng/ul"}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>}
                            {(d.Control && d.Control.Name === "PTC" && ptcQcParam && concentrationEntryValue <= ptcQcParam.Value) &&
                                <Tooltip
                                    title={"PTC DNA Concrentration cannot be < " + ptcQcParam.Value + " ng/ul"}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>}
                            {(d.Sample && sameplQcParam && concentrationEntryValue <= sameplQcParam.Value) &&
                                <Tooltip
                                    title={"Sample Concrentration cannot be <= " + sameplQcParam.Value + " ng/ul"}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>}

                        </div>);
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Concentration (ng/ul)",
                }
            ];
        }
        return [];
    }

    async componentDidMount() {
        await this.props.workflowRunService.resetConcentrationDictionary();
        await this.props.workflowRunService.fetchAssetsForStep(true);
        await this.props.workflowRunService.getConcentrationControls(true);
        await this.props.workflowRunService.getExtractionReagents(true);
        await this.props.workflowRunService.fetchMasterMixForStep(true);

        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const { workflowAssets } = this.props.workflowRunService.getState();

        if (currentStep && workflowAssets.data) {
            const inputAssetType = GetCurrentStepInputType();
            let assetWellContents : WellContentVM[] = [];
            let wellVolume : number | undefined = 0;
            
            switch(inputAssetType) {
                case "Plate": 
                    const inputPlate = _.find(workflowAssets.data.Plates, (p) => { return p.Name === currentStep.InputName && p.Status !== "Empty" });
                    if(inputPlate) {
                        assetWellContents = inputPlate.WellContents;
                        wellVolume = inputPlate.WellVolume;
                    }
                    break;
                case "Rack":
                    const inputRack = _.find(workflowAssets.data.Racks, (r) => { return r.Name === currentStep.InputName && r.Status !== "Empty" });
                    if(inputRack) {
                        assetWellContents = inputRack.WellContents;
                        wellVolume = inputRack.WellVolume;
                    }
                    break;
            }

            let samples: QubitSampleVM[] = [];
            let concentrationDictionary: { [index: string]: ConcentrationVolumeVM | undefined } = {};
            _.forEach(assetWellContents, w => {
                concentrationDictionary[w.Id] = { 
                    Concentration: w.Concentration,
                    SampleVolume: wellVolume,
                    Ratio230: 0,
                    Ratio280: 0
                };
                
                if(w.Sample) {
                    let qubitSample: QubitSampleVM = {
                        Id: w.Sample.Id,
                        WellContentId: w.Id,
                        SampleId: w.Sample.SampleId,
                        WellPosition: ConvertPositionToString(w.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true)
                    };
                    samples.push(qubitSample);
                } else if (w.Control) {
                    let qubitControl: QubitSampleVM = {
                        Id: w.Control.Id,
                        WellContentId: w.Id,
                        SampleId: w.Control.Name,
                        WellPosition: ConvertPositionToString(w.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true)
                    };
                    samples.push(qubitControl);
                }
            });
            this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
            this.setState({ samples: samples, wellContents: assetWellContents, wellVolume: wellVolume ? wellVolume : 0 });

            let qcConfig : QCConfig = {};
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                if(qc.CheckConfig) {
                    let qcParams : QCParam[] = [];
                    _.forEach(qc.CheckConfig.Parameters, (param) => {
                        qcParams.push({
                            Id: param.Id,
                            Value: parseInt(param.Value)
                        });
                    });
                    qcConfig[qc.QCCheckType] = qcParams;
                }
            });
            this.setState({ qcConfig: qcConfig });
        }
        
        this.setState({ dataLoaded: true });
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {

        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const controls = this.props.workflowRunService.getState().getConcentrationControlsState;
        const runCount = this.state.wellContents && this.state.wellContents[0] ? this.state.wellContents[0].RunCount : 0;

        if (currentStep && currentWorkflowRun && masterMixInfoState.data && lotOptions) {

            const customFields = currentStep.CustomFields;
            if (customFields && controls.data) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={styles.mainDiv}>
                                <div>
                                    <MasterMixControl
                                            disabled={disabled}
                                            masterMix={masterMixInfoState.data[0]}
                                            sampleCount={this.state.wellContents.length}
                                            updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                            lotOptions={lotOptions}
                                            setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                            index={0}
                                        />
                                </div>
                                <div>
                                    <SampleInfoTable
                                        data={this.state.wellContents}
                                        columns={this.wellContentColumns()}
                                        header={"Samples"}
                                        defaultSortColumnName={"sample-well-position"} />
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} hideArchiveRetain={runCount >= currentWorkflowRun.WorkflowMaxSampleRun} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.messageContent}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div >)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private onConcentrationChanged(wellContentId: string, newValue: string) {
        let concentrationDictionary = _.cloneDeep(this.props.workflowRunService.concentrationDictionary);
        let num = parseFloat(newValue);

        concentrationDictionary[wellContentId] = {
            Concentration: isNaN(num) ? 0 : num,
            SampleVolume: this.state.wellVolume,
            Ratio230: 0,
            Ratio280: 0
        };
        this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
    }

    @bind
    private async moveToNextStepBegin() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentStep && currentWorkflowRun) {
            let allConcentrationDataSet = _.every(this.props.workflowRunService.concentrationDictionary, c => c !== undefined && c !== null);
            if (!allConcentrationDataSet)
            {
                this.setState({ messageSnackbarOpen: true, messageContent: "Please enter required data. Sample concentrations are missing."});
            }
            else
            {
                this.completeMoveToNextStep();
            }
        }
    }

    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];

        if (currentStep && currentWorkflowRun) {
            // check concentration thresholds for controls and samples
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                
                switch (qc.QCCheckType) {
                    case AmpliconQuantificationNTCConcentrationCheck:
                        const ntcs = _.filter(this.state.samples, (sample) => (sample.SampleId!.includes('NTC')));
                        _.forEach(ntcs, ntc => {
                            if(ntc.WellContentId && qc.CheckConfig) {
                                let concentration = this.props.workflowRunService.concentrationDictionary[ntc.WellContentId];
                                let concentrationValue = concentration && concentration.Concentration ? concentration.Concentration : 0;
                                let qcParam = this.getQcParam(AmpliconQuantificationNTCConcentrationCheck, "NTCConcentrationValid");
                                let ntcPass = true;

                                if(qcParam && concentration && qcParam.Value < concentrationValue) {
                                    if (qc.Enabled) {
                                        failedQCs.push(qc);
                                        ntcPass = false;
                                    }
                                }

                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: qc.Enabled ? ntcPass ? undefined : 0 : 2, //passed/executed/skipped
                                        MeasuredValue: concentration ? concentrationValue.toString() : "0",
                                        Date: new Date(Date.now()),
                                        Pass: ntcPass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            }
                        });
                        break;
                    case AmpliconQuantificationPTCConcentrationCheck:
                        const ptcs = _.filter(this.state.samples, (sample) => (sample.SampleId!.includes('PTC')));
                        _.forEach(ptcs, ptc => {
                            if(ptc.WellContentId && qc.CheckConfig) {
                                let concentration = this.props.workflowRunService.concentrationDictionary[ptc.WellContentId];
                                let concentrationValue = concentration && concentration.Concentration ? concentration.Concentration : 0;
                                let qcParam = this.getQcParam(AmpliconQuantificationPTCConcentrationCheck, "PTCConcentrationValid");
                                let ptcPass = true;
                                
                                if(qcParam && concentration && qcParam.Value >= concentrationValue) {
                                    if (qc.Enabled) {
                                        failedQCs.push(qc);
                                        ptcPass = false;
                                    }
                                }
                                else if (GetWorkflowType() === "Anthrax" && concentration && 60 > concentrationValue) {
                                    //T3 isn't pulling the QcParams the same way as T8, so this sets the value correctly for this workflow
                                    if (qc.Enabled) {
                                        failedQCs.push(qc);
                                        ptcPass = false;
                                    }
                                }

                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: qc.Enabled ? ptcPass ? undefined : 1 : 2, //passed/modify/skipped, 
                                        MeasuredValue: concentration ? concentrationValue.toString() : "0",
                                        Date: new Date(Date.now()),
                                        Pass: ptcPass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            }
                        });
                        break;
                    case AmpliconQuantificationSampleConcentrationCheck:
                        const samples = _.filter(this.state.samples, (sample) => (!sample.SampleId!.includes('PTC') && !sample.SampleId!.includes('NTC')));
                        _.forEach(samples, sample => {
                            if(sample.WellContentId && qc.CheckConfig) {
                                let concentration = this.props.workflowRunService.concentrationDictionary[sample.WellContentId];
                                let concentrationValue = concentration && concentration.Concentration ? concentration.Concentration : 0;
                                let qcParam = this.getQcParam(AmpliconQuantificationSampleConcentrationCheck, "SampleConcentrationValid");
                                let samplePass = true;
                                
                                if(qcParam && concentration && qcParam.Value >= concentrationValue) {
                                    if (qc.Enabled) {}
                                    failedQCs.push(qc);
                                    samplePass = false;
                                }
                                else if (GetWorkflowType() === "Anthrax" && concentration && 60 > concentrationValue) {
                                    //T3 isn't pulling the QcParams the same way as T8, so this sets the value correctly for this workflow
                                    if (qc.Enabled) {
                                        failedQCs.push(qc);
                                        samplePass = false;
                                    }
                                }

                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: qc.Enabled ? samplePass ? undefined : 1 : 2, //passed/modify/skipped, 
                                        MeasuredValue: concentration ? concentrationValue.toString() : "0",
                                        Date: new Date(Date.now()),
                                        Pass: samplePass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            }
                        });
                        break;
                        case FluorometricQuantificationConcentrationEmpty:
                            const fsamples = this.state.wellContents;
                            _.forEach(fsamples, sample => {
                                    let concentration = this.props.workflowRunService.concentrationDictionary[sample.Id];
                                    let concentrationValue = concentration && concentration.Concentration != null ? concentration.Concentration : -1;
                                    let fqcePass = true;

                                    if(concentrationValue < 0 || concentrationValue === undefined) {
                                        if (qc.Enabled) {
                                            failedQCs.push(qc);
                                            fqcePass = false;
                                        }
                                    }

                                    qcResults.push(
                                        {
                                            Id: "",
                                            FailureActionStatus: qc.Enabled ? fqcePass ? undefined : 0 : 2, //passed/executed/skipped, 
                                            MeasuredValue: concentration ? concentrationValue.toString() : "0",
                                            Date: new Date(Date.now()),
                                            Pass: fqcePass,
                                            QCCheckInstance: qc,
                                        }
                                    );
                            });
                            break;
                       
                }
            });

            if (failedQCs.length > 0) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                await Promise.all([
                    this.props.workflowRunService.saveConcentrations(),
                    this.props.workflowRunService.saveMasterMix(true)
                ]);
                await this.props.workflowRunService.completeStep();
            }
        }
    }

    getQcParam(qcCheckType: string, qcParamId: string) {
        const qcParams = this.state.qcConfig[qcCheckType];
        const qcParam = _.find(qcParams, (param) => param.Id === qcParamId);
        return qcParam;
    }
}

export const FluorometricQuantificationScreen = FileService.inject(_FluorometricQuantificationScreen);