import {
    React,
    bind,
    _,
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    DataLoadingDisplay,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import {
    WorkflowConfigService,
    IWorkflowConfigServiceInjectedProps
} from "$State/WorkflowConfigFreezerService";
import { ComponentSetVM } from "$Generated/api";

import { EditComponentSet } from "./EditComponentSet";
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import { IWorkflowsServiceInjectedProps, WorkflowsService } from "$State/WorkflowsFreezerService";
import { WorkflowConfigHelper } from "./WorkflowConfigHelper";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
} = require("./WorkflowComponentManagement.scss");

interface IInstructionSetPageBaseProps {
}

interface IInstructionSetPageState {
    editDialogOpen: boolean,
    newComponentSet: boolean,
    InstructionSetToEdit?: ComponentSetVM,
    isCopy: boolean,
    filteredWorkflows: string,
}

type IInstructionSetPageProps = IInstructionSetPageBaseProps & IWorkflowConfigServiceInjectedProps & IWorkflowsServiceInjectedProps;

export class _InstructionSetPage extends React.Component<IInstructionSetPageProps, IInstructionSetPageState> {

    private readonly columns: Array<IDataTableColumn<ComponentSetVM>> = [
        {
            columnName: "name",
            columnFieldData: "DisplayName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.setState({ InstructionSetToEdit: d, editDialogOpen: true });
                                }}
                            >
                                {(d.AssociatedRunNumber !== 0 || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1) ? "View" : "Edit"}
                            </Button>
                            <Button
                                style={{ marginLeft: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({ InstructionSetToEdit: d, editDialogOpen: true, newComponentSet: true, isCopy: true });
                                }}
                            >
                                Copy
                            </Button>
                        </div>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    state: IInstructionSetPageState = {
        editDialogOpen: false,
        newComponentSet: false,
        isCopy: false,
        filteredWorkflows: '',
    }

    async componentDidMount() {
        await this.props.workflowConfigService.fetchInstructionSets(true);
    }

    render() {

        let {
            instructionSetFetchResults,
        } = this.props.workflowConfigService.getState();

        if (instructionSetFetchResults.data) {

            let instructionSetData : ComponentSetVM[] = [...instructionSetFetchResults.data];
            //Filter according to the selected workflow.
            if (this.state.filteredWorkflows !=='')
            {
                instructionSetData = [];
                _.forEach(instructionSetFetchResults.data, x => {
                    if (x.AssociatedWorkflows && x.AssociatedWorkflows.some( y => y === this.state.filteredWorkflows)){
                        instructionSetData.push(x);
                    }
                })
            }


            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div
                        className={styles.mainContainer}
                    >
                        <Card
                            className={styles.cardStyle}
                        >
                            <h2>Instruction Sets</h2>
                            <Button
                                style={{ float: "right", marginRight: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({
                                        editDialogOpen: true,
                                        newComponentSet: true,
                                        InstructionSetToEdit: {
                                            AssociatedRunNumber: 0,
                                            AssociatedWorkflows: [],
                                            ComponentSetItems: [],
                                            ComponentSetFooters: [],
                                            ComponentSetType: "InstructionSet",
                                            DisplayHeader: "",
                                            DisplayName: "NAME",
                                            Id: "",
                                            ReagentUsedByType: "PerWellOfSamplePlate",
                                            AdditionalAmountFactor: 0,
                                            CreatedOn: new Date(Date.now()),
                                            IsActive: true,
                                            CreatedBy: "",
                                            DeactivatedOn: new Date(Date.now()),
                                        }
                                    })
                                }}
                            >
                                Add
                            </Button>

                            <WorkflowConfigHelper
                                onChange={e => this.setState({filteredWorkflows: e.target.value})}
                                filteredWorkflows={this.state.filteredWorkflows}>
                            </WorkflowConfigHelper>

                            <div style={{fontStyle: 'italic'}}>Note: Edit button will disappear once an instruction set/component has been used in a workflow run. 
                                Users will only be able to view Instruction Sets after being used in a run.</div>

                            {instructionSetFetchResults.data &&
                                <DataTable
                                    data={instructionSetData}
                                    columns={this.columns}
                                />
                            }
                            <div>
                                <EditComponentSet
                                    handleClose={this.handleClose}
                                    handleSave={this.handleInstructionSetUpdate}
                                    open={this.state.editDialogOpen}
                                    newComponentSet={this.state.newComponentSet}
                                    initialComponentSet={this.state.InstructionSetToEdit}
                                    user={value.applicationContext.userContext}
                                    existingNames={_.map(instructionSetFetchResults.data, r => r.DisplayName || "")}
                                    isCopy={this.state.isCopy}
                                />
                            </div>
                        </Card>
                    </div >
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private handleClose() {
        this.setState({ editDialogOpen: false, newComponentSet: false, InstructionSetToEdit: undefined, isCopy: false });
    }

    @bind
    private async handleInstructionSetUpdate(instructionSet: ComponentSetVM) {
        if (this.state.newComponentSet) {
            //add
            await this.props.workflowConfigService.addComponentSet(instructionSet);
        }
        else {
            //update
            await this.props.workflowConfigService.updateComponentSet(instructionSet);
        }

        await this.props.workflowConfigService.fetchInstructionSets(true);
        this.handleClose();
    }
}

export const InstructionSetPage = WorkflowsService.inject(WorkflowConfigService.inject(_InstructionSetPage));