import { ComponentSetVM, ReagentGroupVM, ReagentVM } from "$Generated/api";
import { AdvanceTextField, DataTable, IDataTableColumn, ProcessAdminRole } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Dialog, Checkbox, Button, DialogActions, FormControlLabel, RadioGroup, Radio, TextField, Card, IconButton, InputAdornment } from "@material-ui/core";

import { IUserContext } from "$Utilities/Security/IUserContext";

import {
    AssetService,
    IAssetServiceInjectedProps
} from "$State/AssetFreezerService";
import { ChangeEvent } from "react";

import { MasterMixControlPreview } from "./MasterMixControlPreview";
import { InstructionSetControlPreview } from "./InstructionSetControlPreview";
import { InfoIcon, HorizontalSplitIcon } from "$Imports/MaterialUIComponents";
import { MasterMixTemplate } from "$Imports/CommonIcons";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
    table: string;
    searchDiv: string;
    searchBox: string;
    searchButton: string;
    headerButtons: string;
    componentDialogCard:string;
} = require("./WorkflowComponentManagement.scss");

interface IEditComponentSetBaseProps {
    handleClose: () => void,
    open: boolean,
    newComponentSet: boolean,
    isCopy: boolean,
    initialComponentSet: ComponentSetVM | undefined,
    handleSave: (componentSet: ComponentSetVM) => void,
    user: IUserContext | null,
    existingNames: string[]
}

interface IEditComponentSetState {
    componentSetToEdit?: ComponentSetVM;
    addFooterOpen: boolean;
    footerText: string;
    addComponentOpen: boolean;
    addVolumeOpen: boolean;
    volumeEditObject?: { reagentId: string, reagentName: string, reagentDescription: string, kitName: string, isPool: boolean };
    reagentVolume: string;
    componentSearch: string;
    lotNumberSearch: string;
    templateHelpOpen: boolean;
    instructionDescription: string;
    nextOrdinal: number;
    instructionsUseReagentInput: boolean;
    instructionReagent: string;
}

type IComponentSetProps = IEditComponentSetBaseProps & IAssetServiceInjectedProps;

type IReagentListItem = {
  reagentId: string, 
  reagentName: string, 
  reagentDescription: string, 
  kitName: string, 
  isPool: boolean, 
  lotNumbers:string[]
}

export class _EditComponentSet extends React.Component<IComponentSetProps, IEditComponentSetState> {

    state: IEditComponentSetState = {
        addFooterOpen: false,
        footerText: "",
        addComponentOpen: false,
        addVolumeOpen: false,
        reagentVolume: "",
        componentSearch: "",
        lotNumberSearch: "", 
        templateHelpOpen: false,
        instructionDescription: "",
        nextOrdinal: 1,
        instructionsUseReagentInput: false,
        instructionReagent: ""
    }

    private readonly columns: Array<IDataTableColumn<IReagentListItem>> = [
        {
            columnName: "reagent-name",
            columnFieldData: (d) => <div>{d.reagentName}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "kit-name",
            columnFieldData: (d) => <div>{d.kitName}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Kit Name",
        },
        {
            columnName: "reagent-description",
            columnFieldData: (d) => <div>{d.reagentDescription}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Description",
        },
        {
          columnName: "lot-numbers",
          columnFieldData: (d) => <div>{d.lotNumbers.join(", ")}</div>,
          headerProps: {
              className: styles.tableHeader,
          },
          headerValue: "Lot Numbers",
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            style={{ float: "right" }}
                            variant="contained"
                            color="primary"
                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                            onClick={() => {
                                this.setState({ volumeEditObject: d, addVolumeOpen: true });
                            }}
                        >
                            Select
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    async componentDidUpdate(prevProps: IComponentSetProps) {
        if (this.props.initialComponentSet && (prevProps.initialComponentSet === undefined || (prevProps.initialComponentSet && this.props.initialComponentSet.Id !== prevProps.initialComponentSet.Id))) {
            let componentSet = _.cloneDeep(this.props.initialComponentSet);
            if (this.props.newComponentSet) {
                componentSet.CreatedBy = this.props.user ? this.props.user.preferred_username : "";
                if (this.props.isCopy) {
                    componentSet.DisplayName = componentSet.DisplayName + " - Copy";
                }
            }
            this.setState({ componentSetToEdit: componentSet, componentSearch: "", nextOrdinal: (componentSet.ComponentSetItems?.length || 0) + 1 });

            //fetch all components
            this.props.assetService.fetchCommercialKits(false);
            this.props.assetService.fetchCustomKits(false);
            this.props.assetService.fetchStandaloneReagents();
            this.props.assetService.fetchPools(false);
        }
    }

    render() {
        if (this.state.componentSetToEdit) {
            let {
                standaloneReagentFetchResults,
                poolFetchResults,
                customKitFetchResults,
                commercialKitFetchResults,
            } = this.props.assetService.getState();

            const disabled = this.state.componentSetToEdit?.AssociatedRunNumber !== 0;

            const data = this.mergeReagentListData(standaloneReagentFetchResults.data, poolFetchResults.data, customKitFetchResults.data, commercialKitFetchResults.data);

            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div>
                        <Dialog
                            className={styles.dialog}
                            open={this.props.open}
                            onClose={this.props.handleClose}
                            PaperProps={{ style: { width: "800px", maxWidth: "1200px", height: "800px" } }}
                        >
                            <div className={styles.content}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    {this.state.componentSetToEdit && <h2>{this.state.componentSetToEdit.ComponentSetType === "ReagentMix" ? "Reagent Mix" : this.state.componentSetToEdit.ComponentSetType === "ReagentList" ? "Reagent List" : this.state.componentSetToEdit.ComponentSetType === "SimplifiedReagentList" ? "Simplified Reagent List" : "Instruction Set"}</h2>}
                                    <IconButton disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} className={styles.headerButtons} onClick={(event) => { this.setState({ templateHelpOpen: true }) }}>
                                        <InfoIcon />
                                    </IconButton>
                                </div>
                                <div style={{ paddingTop: "10px", display: "flex", flexDirection: "row" }}>
                                    {this.state.componentSetToEdit && <AdvanceTextField
                                        InputLabelProps={{ shrink: true }}
                                        label={(this.state.componentSetToEdit.ComponentSetType === "ReagentMix" ? "Reagent Mix" : this.state.componentSetToEdit.ComponentSetType === "ReagentList" ? "Reagent List" : this.state.componentSetToEdit.ComponentSetType === "SimplifiedReagentList" ? "Simplified Reagent List" : "Instruction Set") + " Name"}
                                        value={this.state.componentSetToEdit?.DisplayName}
                                        onDebouncedChange={(value) => { this.setName(value) }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || disabled}
                                    />}
                                    {this.props.newComponentSet && _.find(this.props.existingNames, name => name === this.state.componentSetToEdit?.DisplayName) !== undefined && <h3 style={{ color: "red" }}>
                                        Names must be unique!
                                    </h3>}
                                </div>
                                <div style={{ paddingTop: "10px", display: "flex", flexDirection: "row" }}>
                                    <AdvanceTextField
                                        InputLabelProps={{ shrink: true }}
                                        label={"Display Header"}
                                        value={this.state.componentSetToEdit?.DisplayHeader}
                                        onDebouncedChange={(value) => { this.setHeader(value) }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || disabled}
                                    />
                                </div>
                                {this.state.componentSetToEdit?.ComponentSetType === "ReagentMix" &&
                                    <div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            {"Additional amount factor"}
                                            <RadioGroup row style={{ marginLeft: "20px " }} value={this.state.componentSetToEdit?.AdditionalAmountFactorType === "PercentFactor"} onChange={this.handleAmountFactorRadioChange} >
                                                <FormControlLabel disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) === -1 || disabled} value={true} control={<Radio />} label="percent factor" />
                                                <FormControlLabel disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) === -1 || disabled} value={false} control={<Radio />} label="# of samples" />
                                            </RadioGroup>
                                        </div>
                                        <TextField disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || disabled} type="number" value={this.state.componentSetToEdit?.AdditionalAmountFactor} onChange={this.setAdditionalAmount} />
                                    </div>
                                }
                                <div style={{ paddingTop: "10px", display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                                        {this.state.componentSetToEdit && (this.state.componentSetToEdit.ComponentSetType === "ReagentMix" ? "Reagent Mix" : this.state.componentSetToEdit.ComponentSetType === "ReagentList" ? "Reagent List" : this.state.componentSetToEdit.ComponentSetType === "SimplifiedReagentList" ? "Simplified Reagent List" : "Instruction Set") + " Used:"}
                                    </div>
                                    <RadioGroup row value={this.state.componentSetToEdit?.ReagentUsedByType == "PerWellOfSamplePlate"} onChange={this.handleRadioChange} >
                                        <FormControlLabel disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || disabled} value={true} control={<Radio />} label="per well of sample plate" />
                                        <FormControlLabel disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || disabled} value={false} control={<Radio />} label="sample pool" />
                                    </RadioGroup>
                                </div>
                                {(this.state.componentSetToEdit?.ComponentSetType === "ReagentList" || this.state.componentSetToEdit?.ComponentSetType === "SimplifiedReagentList") &&
                                    <div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.componentSetToEdit.ComponentSetType === "ReagentList"} onChange={this.handleCheckboxChange} name={"VolumeRequired"} />}
                                                label={"Is volume required for this reagent list?"}
                                            />
                                        </div>
                                    </div>
                                }
                                {!disabled &&
                                    <div style={{ paddingTop: "10px", display: "flex", flexDirection: "row" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => this.setState({ addComponentOpen: true })}
                                            size="small"
                                        >
                                            Add Step
                                        </Button>

                                        {(this.state.componentSetToEdit?.ComponentSetType === "ReagentMix" || this.state.componentSetToEdit?.ComponentSetType === "ReagentList" || this.state.componentSetToEdit?.ComponentSetType === "SimplifiedReagentList") && <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => this.setState({ addFooterOpen: true })}
                                            size="small"
                                            style={{ marginLeft: "10px" }}
                                        >
                                            Add Footer Item
                                        </Button>}
                                    </div>
                                }
                                {
                                    !this.props.newComponentSet &&
                                    <div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ paddingTop: "10px" }}>
                                                Active:
                                            </div>
                                            <Checkbox disabled={disabled || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} checked={this.state.componentSetToEdit?.IsActive}
                                                onChange={(event) => {
                                                    this.setActive(event.target.checked);
                                                }}
                                            />
                                        </div>
                                        {!this.state.componentSetToEdit?.IsActive && this.state.componentSetToEdit?.DeactivatedOn &&
                                            <div>
                                                Deactivated On {moment(this.state.componentSetToEdit?.DeactivatedOn).format("L LT")}
                                            </div>
                                        }
                                    </div>
                                }

                                {this.state.componentSetToEdit && <div style={{ overflowY: "auto", maxHeight: "400px" }}>
                                    {this.state.componentSetToEdit?.ComponentSetType !== "InstructionSet" ?
                                        <MasterMixControlPreview
                                            masterMixes={[this.state.componentSetToEdit]}
                                            isPool={this.state.componentSetToEdit.ReagentUsedByType === "SamplePool"}
                                            updateMasterMixReagents={() => { }}
                                            overrideTitle={this.state.componentSetToEdit?.DisplayName + " view in workflow"}
                                            deleteMixStep={disabled ? undefined : this.deleteStepItem}
                                            deleteFooterStep={disabled ? undefined : this.deleteFooterStep}
                                            disabled
                                        />
                                        :
                                        <InstructionSetControlPreview
                                            componentSetData={this.state.componentSetToEdit}
                                            updateComponentSetDataSteps={() => { }}
                                            disabled
                                            poolOrPlate={this.state.componentSetToEdit?.ReagentUsedByType === "PerWellOfSamplePlate" ? "Plate" : "Pool"}
                                            deleteStep={disabled ? undefined : this.deleteStepItem}
                                        />

                                    }
                                </div>}
                            </div>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        if (this.state.componentSetToEdit) {
                                            this.props.handleSave(this.state.componentSetToEdit)
                                        }
                                    }}
                                    disabled={
                                        value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                        (this.props.newComponentSet &&
                                            !(this.state.componentSetToEdit && this.state.componentSetToEdit.DisplayName && this.state.componentSetToEdit.DisplayHeader))
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.props.handleClose}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            className={styles.dialog}
                            open={this.state.addFooterOpen}
                            onClose={() => { this.setState({ footerText: "", addFooterOpen: false }) }}
                        >
                            <Card>
                                <h2>
                                    Enter footer text
                                </h2>
                                <AdvanceTextField value={this.state.footerText} onDebouncedChange={(val) => { this.setState({ footerText: val }); }} />
                            </Card>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onClick={() => { this.addFooterItem(true) }}
                                >
                                    Save and Next
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onClick={() => { this.addFooterItem(false) }}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { this.setState({ footerText: "", addFooterOpen: false }) }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            className={styles.dialog}
                            PaperProps={{ style: { width: "700px", maxWidth: "700px", height: "800px" }}}
                            open={this.state.addComponentOpen}
                            onClose={() => { this.setState({ addComponentOpen: false, componentSearch: "" }) }}
                        >
                            <Card className={styles.componentDialogCard}>
                                <h2>
                                    Components
                                </h2>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div className={styles.searchDiv}>
                                        <AdvanceTextField
                                            variant="outlined"
                                            label={"Search by name"}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            value={this.state.componentSearch}
                                            onDebouncedChange={(value) => { this.setState({ componentSearch: value }); }}
                                        />
                                    </div>
                                    <div className={styles.searchDiv}>
                                        <AdvanceTextField
                                            variant="outlined"
                                            InputProps={{endAdornment:<InputAdornment position="end"><HorizontalSplitIcon /></InputAdornment>}}
                                            label={"Search by lot number"}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            value={this.state.lotNumberSearch}
                                            onDebouncedChange={(value) => { this.setState({ lotNumberSearch: value }); }}
                                        />
                                    </div>
                                </div>
                                <div className={styles.table}>
                                    <DataTable
                                        data={data}
                                        columns={this.columns}
                                        stickyHeader
                                    />
                                </div>
                            </Card>
                            <DialogActions>
                                {this.state.componentSetToEdit?.ComponentSetType === "InstructionSet" &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onClick={() => {
                                            this.setState({
                                                volumeEditObject: {
                                                    isPool: false,
                                                    kitName: "",
                                                    reagentDescription: "",
                                                    reagentId: "",
                                                    reagentName: ""
                                                },
                                                addVolumeOpen: true,
                                                instructionsUseReagentInput: true
                                            });
                                        }}
                                    >
                                        Select None
                                    </Button>
                                }
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { this.setState({ addComponentOpen: false, componentSearch: "", instructionsUseReagentInput: false }) }}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            className={styles.dialog}
                            open={this.state.addVolumeOpen}
                            onClose={() => { this.setState({ addVolumeOpen: false }) }}
                        >
                            <Card style={{ padding: "50px" }}>
                                <h2>
                                    Step
                                </h2>
                                <table>
                                    <tr>
                                        {this.state.componentSetToEdit?.ComponentSetType === "InstructionSet" && <th>
                                            Step Description:
                                        </th>}
                                        <th style={{ paddingLeft: "50px" }}>
                                            Reagent:
                                        </th>
                                        {this.state.componentSetToEdit?.ComponentSetType !== "SimplifiedReagentList" && <th style={{ paddingLeft: "50px" }}>
                                            Volume:
                                        </th>}
                                    </tr>

                                    {this.state.volumeEditObject ?
                                        <tr>
                                            {this.state.componentSetToEdit?.ComponentSetType === "InstructionSet" &&
                                                <td style={{ paddingLeft: "50px" }}>
                                                    <AdvanceTextField
                                                        style={{ width: "80px" }}
                                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                        InputLabelProps={{ shrink: true }}
                                                        onDebouncedChange={(value) => { this.setState({ instructionDescription: value }) }}
                                                    />
                                                </td>}
                                            <td style={{ paddingLeft: "50px" }}>
                                                {this.state.instructionsUseReagentInput ?
                                                    <AdvanceTextField
                                                        style={{ width: "80px" }}
                                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                        InputLabelProps={{ shrink: true }}
                                                        onDebouncedChange={(value) => { this.setState({ instructionReagent: value }) }}
                                                    />
                                                    : this.state.volumeEditObject.reagentName}
                                            </td>
                                            {this.state.componentSetToEdit?.ComponentSetType !== "SimplifiedReagentList" &&
                                            <td style={{ paddingLeft: "50px" }}>
                                                <AdvanceTextField
                                                    type="number"
                                                    autoFocus={true}
                                                    style={{ width: "50px" }}
                                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                    InputLabelProps={{ shrink: true }}
                                                    onDebouncedChange={(value) => { this.setState({ reagentVolume: value }) }}
                                                /> ul
                                            </td>}
                                        </tr>
                                        : <></>}
                                </table>
                            </Card>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { this.addComponentSetItem(true) }}
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                        (this.state.componentSetToEdit?.ComponentSetType === "InstructionSet" && this.state.instructionDescription === "") ||
                                        (this.state.componentSetToEdit?.ComponentSetType !== "InstructionSet" && this.state.componentSetToEdit?.ComponentSetType !== "SimplifiedReagentList" && this.state.reagentVolume === "")}
                                >
                                    Save and Next
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { this.addComponentSetItem(false) }}
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                        (this.state.componentSetToEdit?.ComponentSetType === "InstructionSet" && this.state.instructionDescription === "") ||
                                        (this.state.componentSetToEdit?.ComponentSetType !== "InstructionSet" && this.state.componentSetToEdit?.ComponentSetType !== "SimplifiedReagentList" && this.state.reagentVolume === "")}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { this.setState({ addVolumeOpen: false, instructionDescription: "", instructionReagent: "", instructionsUseReagentInput: false, reagentVolume: "" }) }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            className={styles.dialog}
                            style={{ height: "800px" }}
                            open={this.state.templateHelpOpen}
                            onClose={() => { this.setState({ templateHelpOpen: false }) }}
                        >
                            <Card>
                                <img src={MasterMixTemplate} alt="Help Template" />
                            </Card>
                        </Dialog>
                    </div>
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <></>;
    }

    @bind
    private setName(value: string) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.DisplayName = value;
            this.setState({
                componentSetToEdit: newVal
            });
        }
    }

    @bind
    private setHeader(value: string) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.DisplayHeader = value;
            this.setState({
                componentSetToEdit: newVal
            });
        }
    }


    @bind
    private setAdditionalAmount(e: ChangeEvent<HTMLTextAreaElement>) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let numericValue = parseFloat(e.target.value);
            if (numericValue >= 0) {
                let newVal = _.cloneDeep(oldVal);
                newVal.AdditionalAmountFactor = numericValue;
                this.setState({
                    componentSetToEdit: newVal
                });
            }
            else {
                let newVal = _.cloneDeep(oldVal);
                newVal.AdditionalAmountFactor = undefined;
                this.setState({
                    componentSetToEdit: newVal
                });
            }
        }
    }

    @bind
    private setActive(value: boolean) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.DeactivatedOn = value ? oldVal.DeactivatedOn : new Date(Date.now());
            newVal.IsActive = value;
            newVal.DeactivatedBy = value ? oldVal.DeactivatedBy : this.props.user ? this.props.user.preferred_username : "";
            this.setState({ componentSetToEdit: newVal });
        }
    }

    @bind
    private handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.ReagentUsedByType = (event.target as HTMLInputElement).value.toLowerCase() === "true" ? "PerWellOfSamplePlate" : "SamplePool";
            this.setState({
                componentSetToEdit: newVal
            });
        }
    }

    @bind
    private handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.ComponentSetType = (event.target as HTMLInputElement).checked ? "ReagentList" : "SimplifiedReagentList";
            this.setState({
                componentSetToEdit: newVal
            });
        }
    }

    @bind
    private handleAmountFactorRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.AdditionalAmountFactorType = (event.target as HTMLInputElement).value.toLowerCase() === "true" ? "PercentFactor" : "NumberOfSamples";
            this.setState({
                componentSetToEdit: newVal
            });
        }
    }

    @bind
    private addFooterItem(saveAndNext: boolean) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.ComponentSetFooters?.push(
                {
                    Description: this.state.footerText,
                }
            )
            this.setState({
                componentSetToEdit: newVal,
                footerText: "",
            });
        }

        if (saveAndNext) {
            this.setState({ addFooterOpen: true })
        }
        else {
            this.setState({ addFooterOpen: false })
        }
    }

    @bind
    private addComponentSetItem(saveAndNext: boolean) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.ComponentSetItems?.push(
                {
                    Volume: parseFloat(this.state.reagentVolume) || undefined,
                    ReagentId: this.state.instructionsUseReagentInput ? "" : this.state.volumeEditObject?.isPool ? "" : this.state.volumeEditObject?.reagentId,
                    DisplayName: this.state.instructionsUseReagentInput ? this.state.instructionReagent : this.state.volumeEditObject?.reagentName,
                    Ordinal: this.state.nextOrdinal,
                    Description: newVal.ComponentSetType === "InstructionSet" ? this.state.instructionDescription : "",
                    CustomReagentName: this.state.instructionsUseReagentInput ? this.state.instructionReagent : "",
                    ReagentGroupId: this.state.instructionsUseReagentInput ? "" : this.state.volumeEditObject?.isPool ? this.state.volumeEditObject?.reagentId : ""
                }
            )
            this.setState({
                componentSetToEdit: newVal,
                nextOrdinal: this.state.nextOrdinal + 1,
                reagentVolume: ""
            });
        }
        if (saveAndNext) {
            this.setState({ addComponentOpen: true, addVolumeOpen: false, volumeEditObject: undefined, instructionsUseReagentInput: false, instructionDescription: "", instructionReagent: "", reagentVolume: "" })
        }
        else {
            this.setState({ addComponentOpen: false, componentSearch: "", addVolumeOpen: false, volumeEditObject: undefined, instructionDescription: "", instructionReagent: "", reagentVolume: "" })
        }
    }

    @bind
    private deleteStepItem(index: number) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.ComponentSetItems?.splice(index, 1);

            _.forEach(newVal.ComponentSetItems, (i, idx) => {
                i.Ordinal = idx + 1;
            });

            this.setState({
                componentSetToEdit: newVal,
                nextOrdinal: this.state.nextOrdinal - 1
            });
        }
    }

    @bind
    private deleteFooterStep(index: number) {
        let oldVal = this.state.componentSetToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.ComponentSetFooters?.splice(index, 1);
            this.setState({
                componentSetToEdit: newVal,
            });
        }
    }

    private mergeReagentListData (standaloneReagents?:ReagentVM[] | null, 
      poolReagentGroups?:ReagentGroupVM[] | null, 
      customKitReagentGroups?:ReagentGroupVM[] | null, 
      commercialKitReagentGroups?:ReagentGroupVM[] | null ): IReagentListItem[]{

      let data:IReagentListItem[] = [];

      _.forEach(standaloneReagents, r => data.push({ 
          reagentId: r.Id || "", 
          reagentName: r.Name, 
          reagentDescription: r.Description || "", 
          kitName: "",
          isPool: false,
          lotNumbers: (r.Lots && r.Lots.length > 0) ? _.map(r.Lots, l => l.LotNumber) : []
        }));

      _.forEach(poolReagentGroups, r => data.push({ 
        reagentId: r.Id || "", 
        reagentName: r.Name, 
        reagentDescription: r.Description || "", 
        kitName: "", 
        isPool: true,
        lotNumbers: (r.Lots && r.Lots.length > 0) ? _.map(r.Lots, l => l.LotNumber) : []
      }));


      let kitData: ReagentGroupVM[] = [];
      kitData = _.union(kitData, customKitReagentGroups, commercialKitReagentGroups);

      _.forEach(kitData, g => _.forEach(g.Lots[0].ReagentLots, rl => {
          data.push({ 
            reagentId: rl.ReagentId || "", 
            reagentName: rl.ReagentName || "", 
            reagentDescription: g.Description || "", 
            kitName: g.Name, 
            isPool: false,
            lotNumbers: [rl.LotNumber]
           });
      }))

      //Filter component set by reagentName and lotNumber
      data = _.filter(data, d => 
        d.reagentName.toLowerCase().includes(this.state.componentSearch.toLowerCase()) && 
        d.lotNumbers.some(ln => ln.toLowerCase().includes(this.state.lotNumberSearch.toLowerCase())));

        return data;
    }
}

export const EditComponentSet = AssetService.inject(_EditComponentSet);