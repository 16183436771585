import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";

import {
    StepActionsControl,
    StepChangeControl,
    DataTable,
    IDataTableColumn,
    DataLoadingDisplay,
} from "$Imports/CommonComponents";

import { Button, Card, Dialog, DialogContent, DialogTitle, DialogActions, List, ListItem, ListItemIcon, TextField, Link } from "$Imports/MaterialUIComponents";
import { ArrowRight, ArrowBack } from "@material-ui/icons";

import { NavigationService } from "$State/NavigationFreezerService";
import { SequencingDataVM } from "$Generated/api";
import { exception } from "react-ga";
import { ChangeEvent } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { MinitMinderService, IMinitMinderServiceInjectedProps } from "$State/MinitMinderFreezerService";

var urljoin = require('url-join');

const styles: {
    dialog: string;
    tableHeader: string;
    pipelineButton: string;
    qcDataNavCol: string;
} = require("./CollectGenomicsData.scss");

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");

const PollInterval = 30; //poll at 30s
const TimeEstimation = require("$Images/TimeEstimation.PNG"); //AppVeyor seems to have issues differentiating between .png and .PNG

export interface ICollectGenomicsDataScreenState {
    confirmCompleteOpen: boolean;
    dataLoaded: boolean;
    confirmReleaseOpen: boolean;
    dirStructure: { [key: string]: any };
    currentDirSubDirs: string[];
    directoryList: string[];
    selectDirectoryOpen: boolean;
    selectedDirectory: string;
    pipelineStarted: boolean;
    minderFileShareStatus: boolean;
    poreOccupancyValid: boolean;
}

export class CollectGenomicsDataScreen extends React.Component<IWorkflowScreenProps, ICollectGenomicsDataScreenState> {

    private readonly influenzaColumns: Array<IDataTableColumn<SequencingDataVM>> = [
        {
            columnName: "sampleId",
            columnFieldData: (d) => d.SampleId,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sample Id",
            sortMethod: (d) => (d.SampleId) ?? ""
        },
        {
            columnName: "barcode",
            columnFieldData: (d) => d.MetadataDictionary ? d.MetadataDictionary["barcode"] : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Barcode",
            sortMethod: (d) => d.MetadataDictionary ? d.MetadataDictionary["barcode"] : "" ?? ""
        },
        {
            columnName: "wellPosition",
            columnFieldData: (d) => d.SampleWellPosition,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Well Position",
            sortMethod: (d) => (d.SampleWellPosition) ?? ""
        },
        {
            columnName: "subType",
            columnFieldData: (d) => d.MetadataDictionary ? d.MetadataDictionary["sub_type"] : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sub Type",
            sortMethod: (d) => d.MetadataDictionary ? d.MetadataDictionary["sub_type"] : "" ?? ""
        },
    ];

    private readonly sarsCOV2Columns: Array<IDataTableColumn<SequencingDataVM>> = [
        {
            columnName: "sampleId",
            columnFieldData: (d) => d.SampleId || (d.MetadataDictionary ? d.MetadataDictionary["ERROR"] : ""),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sample Id",
            sortMethod: (d) => (d.SampleId) ?? ""
        },
        {
            columnName: "wellPosition",
            columnFieldData: (d) => d.SampleWellPosition,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Well Position",
            sortMethod: (d) => (d.SampleWellPosition) ?? ""
        },
        {
            columnName: "pangolinLineage",
            columnFieldData: (d) => d.MetadataDictionary ? d.MetadataDictionary["pangolin_lineage"] : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Pangolin Lineage",
            sortMethod: (d) => d.MetadataDictionary ? d.MetadataDictionary["pangolin_lineage"] : "" ?? ""
        },
        {
            columnName: "pangolinStatus",
            columnFieldData: (d) => d.MetadataDictionary ? d.MetadataDictionary["pangolin_status"] : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Pangolin Status",
            sortMethod: (d) => d.MetadataDictionary ? d.MetadataDictionary["pangolin_status"] : "" ?? ""
        },
        {
            columnName: "nextcladeClade",
            columnFieldData: (d) => d.MetadataDictionary ? d.MetadataDictionary["nextclade_clade"] : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Nextclade Clade",
            sortMethod: (d) => d.MetadataDictionary ? d.MetadataDictionary["nextclade_clade"] : "" ?? ""
        },
        {
            columnName: "NavToQcMetrics",
            columnFieldData: (d) =>
                <Button variant="contained"
                    color="primary"
                    onClick={() => this.navigateToQcMetricsPage(d.SampleId)}>
                    View Quality Control Metrics
                </Button>,
            cellProps: {
                className: styles.qcDataNavCol
            },
            headerProps: {
                className: styles.tableHeader
            },
            headerValue: "Sample-Level Bioinformatics Quality Control Metrics"
        },
    ];


    private timerID: any;  // Specifing the type causes compilation issues.  Changing to Any for now.
    state: ICollectGenomicsDataScreenState = {
        confirmCompleteOpen: false,
        dataLoaded: false,
        confirmReleaseOpen: false,
        dirStructure: {},
        currentDirSubDirs: [],
        directoryList: [],
        selectDirectoryOpen: false,
        selectedDirectory: "",
        pipelineStarted: false,
        minderFileShareStatus: false,
        poreOccupancyValid: true,
    };

    async componentDidMount() {
        await this.props.workflowRunService.fetchWorkflowRunSequencingStatus(true); //Poll the run for the sequencing status.
        await MinitMinderService.fetchJobStatuses(true);
        this.setState({ dataLoaded: true });
        if (GetWorkflowType() === "Anthrax") {
            await this.getPipelineFileshareStatus().then(() => {
                this.GetDirectories();
            });
        }

        const sequencingStatusData = this.props.workflowRunService.freezer.get().fetchSequencingStatusState.data;
        if (sequencingStatusData && (sequencingStatusData.SequencingStatus === "Complete" || sequencingStatusData.SequencingStatus === "Failure" || sequencingStatusData.SequencingStatus === "PartialResults")) {
            await this.props.workflowRunService.fetchWorkflowRunSequencingData(true);
        }
        else {
            this.timerID = setInterval(() => this.pollStatus(), PollInterval * 1000);
        }
    }

    componentWillUnmount() {
        //Finish polling
        if (this.timerID) {
            clearInterval(this.timerID);
        }
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const sequencingStatusData = this.props.workflowRunService.freezer.get().fetchSequencingStatusState.data;
        const sequencingData = this.props.workflowRunService.freezer.get().fetchSequencingDataState.data;

        let jobStatusResults = MinitMinderService.getState().jobStatusResults;
        let detectedFiles = 0;
        let processedFiles = 0;
        _.forEach(jobStatusResults.data, d => {
            if (d.DetectedFileCount) {
                detectedFiles += d.DetectedFileCount;
            }
            if (d.ProcessedFileCount) {
                processedFiles += d.ProcessedFileCount;
            }
        });

        if (currentStep && currentWorkflowRun && sequencingStatusData) {
            const customFields = currentStep.CustomFields;
            let sequencingStatus = "";
            switch (sequencingStatusData.SequencingStatus) {
                case "Started":
                    sequencingStatus = "Pipeline Started";
                    break;
                case "Failure":
                    sequencingStatus = "Sequencing Failed";
                    break;
                case "Complete":
                    sequencingStatus = "Sequencing Complete";
                    break;
                case "PartialResults":
                    sequencingStatus = "Partial Results"
                    break;
                default:
                case "NotStarted":
                    sequencingStatus = "Not Started";
                    break;
            }

            if (customFields) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return <div>
                    <div style={{ float: "left" }}>
                        <h2 style={{ paddingBottom: 10 }}>
                            Sequencing Progress
                        </h2>
                        {GetWorkflowType() === "Anthrax" &&
                            <div style={{ paddingBottom: 20 }}>
                                Pore Occupancy: <TextField
                                    autoFocus={true}
                                    disabled={disabled}
                                    style={{ paddingLeft: 10 }}
                                    type="number"
                                    value={(customFields["poreOccupancy"] ? customFields["poreOccupancy"] : "")}
                                    onChange={e => { this.onTextChanged("poreOccupancy", e.target.value) }}
                                    inputProps={{ min: 0 }} /> %
                                <Snackbar
                                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                    open={(!this.state.poreOccupancyValid)}
                                    message={"Pore Occupancy must be between 0 and 100%"}
                                    autoHideDuration={2000} />
                            </div>
                        }
                        {GetWorkflowType() === "Anthrax" &&
                            <div>
                                Select the directory of .fastq files for analysis
                                <div style={{ paddingTop: 10 }}>
                                    {(!this.state.pipelineStarted && !("fastqPath" in customFields)) ?
                                        <button onClick={() => this.setState({ selectDirectoryOpen: true })}>Choose Directory</button> :
                                        <button disabled onClick={() => this.setState({ selectDirectoryOpen: true })}>Choose Directory</button>}
                                    {("fastqPath" in customFields) ? "  /" + customFields["fastqPath"] : this.state.selectedDirectory != undefined ? "  " + this.state.directoryList.join("/") + "/" + this.state.selectedDirectory : <></>}
                                    <div style={{ textAlign: "center", paddingTop: 10 }}>
                                        {(!this.state.pipelineStarted && this.state.selectedDirectory != "" && this.state.poreOccupancyValid && !("fastqPath" in customFields)) ?
                                            <Button className={styles.pipelineButton} variant="contained" color="primary" onClick={this.startPipeline}>Confirm .fastq Files are Ready</Button> :
                                            <Button className={styles.pipelineButton} variant="contained" color="primary" disabled>Confirm .fastq Files are Ready</Button>}
                                    </div>
                                </div>
                            </div>}

                        <div>
                            Sequencing Status: {sequencingStatus}
                            {GetWorkflowType() === "Anthrax" &&
                                <div>
                                    <div> Files Detected: {detectedFiles} Files Processed: {processedFiles} </div>
                                    <h2 onClick={this.navigateToResultsPage}>
                                        <Link>
                                            View Results
                                        </Link>
                                    </h2>
                                </div>
                            }
                        </div>
                        {GetWorkflowType() !== "Anthrax" ? <>{(sequencingStatusData.SequencingStatus === "Complete" ||
                            sequencingStatusData.SequencingStatus === "Failure" ||
                            sequencingStatusData.SequencingStatus === "PartialResults")
                            && sequencingData &&
                            <div>
                                <DataTable
                                    data={sequencingData.toJS()}
                                    columns={GetWorkflowType() === "Influenza" ? this.influenzaColumns : this.sarsCOV2Columns}
                                    stickyHeader={true}
                                    defaultSortColumnName="wellPosition"
                                    defaultSortDirection="asc" />
                                <StepChangeControl disabled={disabled} nextStep={"Finish Workflow"} showPause={false} moveToNextStep={this.completeWorkflow} failRun={this.props.failRun} />
                            </div>}</> : <></>
                        }
                    </div>
                    {GetWorkflowType() === "Anthrax" &&
                        <div style={{ float: "right", paddingTop: 30 }}>
                            <img src={TimeEstimation} />
                        </div>
                    }
                    {GetWorkflowType() === "Anthrax" &&
                        <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                            <StepChangeControl disabled={disabled} nextStep={"Finish Workflow"} showPause={false} moveToNextStep={this.completeWorkflow} failRun={this.props.failRun} />
                        </div>
                    }

                    <Dialog
                        PaperProps={{ className: styles.dialog }}
                        open={this.state.confirmReleaseOpen}
                        onClose={() => { this.setState({ confirmReleaseOpen: false }); }}
                    >
                        <Card style={{ width: "400px" }}>
                            <div style={{ margin: "10px" }}>
                                <h2>Workflow Release</h2>
                                <h3>Is this workflow ready for release?</h3>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Button key={"yes"} variant="contained" size="small" color="primary" style={{ margin: "10px" }}
                                    onClick={(event) => {
                                        this.completeAndReleaseWorkflow(true);
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button key={"no"} variant="contained" size="small" color="primary" style={{ margin: "10px" }}
                                    onClick={(event) => {
                                        this.completeAndReleaseWorkflow(false);
                                    }}
                                >
                                    No
                                </Button>
                                <Button key={"cancel"} variant="contained" size="small" color="primary" style={{ margin: "10px" }}
                                    onClick={(event) => {
                                        this.setState({ confirmReleaseOpen: false });
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Card>
                    </Dialog>
                    <Dialog open={this.state.selectDirectoryOpen}>
                        <DialogTitle>Select Fastq Directory</DialogTitle>
                        <DialogContent style={{ height: '30vh', width: '40vh' }}>
                            {(this.state.directoryList.length != 1) && <Button onClick={this.goUpDirectory}><ArrowBack /></Button>}
                            <List>
                                {
                                    this.state.currentDirSubDirs.map((dir) => {
                                        return <ListItem selected={this.state.selectedDirectory == dir}>
                                            <ListItem onClick={() => this.setState({ selectedDirectory: dir })}>{dir}</ListItem>
                                            {(this.hasSubdirectory(dir) || this.state.minderFileShareStatus) && <ListItemIcon onClick={() => this.changeDirectory(dir)}><ArrowRight /></ListItemIcon>}
                                        </ListItem>
                                    })
                                }
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ selectDirectoryOpen: false })}>Finish</Button>
                        </DialogActions>
                    </Dialog>
                </div>;
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private hasSubdirectory(currentDir: string) {
        if (!this.state.minderFileShareStatus) {
            var base_dir = Object.keys(this.state.dirStructure)[0]
            if ((this.state.dirStructure == undefined) || (currentDir === base_dir)) {
                return false;
            }
            var directory = this.state.dirStructure;
            for (var dir of this.state.directoryList) {
                directory = directory[dir];
            }
            return Object.keys(directory[currentDir]).length != 0;
        } else {
            return true;
        }
    }

    @bind
    private goUpDirectory() {
        var directoryList = this.state.directoryList;
        directoryList.pop()
        this.setState({ directoryList: directoryList });
        // Move through directory structure to last directory
        var directory = this.state.dirStructure;
        for (var dir of this.state.directoryList) {
            directory = directory[dir];
        }
        this.setState({ currentDirSubDirs: Object.keys(directory) })
    }

    @bind
    private changeDirectory(newDir: string) {
        if (this.state.minderFileShareStatus || !this.hasSubdirectory(newDir)) {
            this.GetFileShareDirectories(newDir);
        } else {
            var newDirectoryList = this.state.directoryList;
            newDirectoryList.push(newDir);
            this.setState({ directoryList: newDirectoryList });
            // Move through directory structure to new directory
            var directory = this.state.dirStructure;
            for (var dir of this.state.directoryList) {
                directory = directory[dir];
            }
            this.setState({ currentDirSubDirs: Object.keys(directory) })
        }
    }

    @bind
    private async GetDirectories() {
        if (this.state.minderFileShareStatus) {
            this.GetFileShareDirectories("");
        } else {
            await fetch('api/v1/MinitMinder/Directory', { method: "GET" }).then(
                response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw exception;
                    }
                    response.json().then((res) => {
                        var tree = res['directory_trees']['anthrax-202112']
                        var base_dir = Object.keys(tree)[0]
                        this.setState({ dirStructure: tree, currentDirSubDirs: Object.keys(tree[base_dir]), directoryList: [base_dir] });
                    })
                })
        }
    }

    @bind
    private async GetFileShareDirectories(newDir: string) {
        var dirList = this.state.directoryList;
        if (newDir != "") {
            dirList.push(newDir);
        }
        this.setState({ directoryList: dirList });
        var current_path = this.state.directoryList.join("/");
        await this.props.workflowRunService.getFileShareDirectories(current_path);
        var subDirs: string[] = [];
        this.props.workflowRunService.freezer.get().fileShareDirectories.data?.toJS()!.forEach((dir) => {
            subDirs.push(dir.Name);
        });
        this.setState({ currentDirSubDirs: subDirs });
    }

    @bind
    private async startPipeline() {
        if (this.state.minderFileShareStatus) {
            var dirList = this.state.directoryList;
            dirList.push(this.state.selectedDirectory);
            await fetch('/api/v1/MinitMinder/FileShare/' + this.props.workflowRunService.currentWorkflowRun?.RunNumber + "?directory=" + dirList.join("/"), { method: "POST" }).then(
                response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw exception;
                    }
                    this.setState({ pipelineStarted: true });
                    return response.blob()
                })
        } else {
            await fetch('api/v1/MinitMinder/Directory/' + this.props.workflowRunService.currentWorkflowRun?.RunNumber + "?directory=" + this.state.directoryList.join("/") + "/" + this.state.selectedDirectory, { method: "POST" }).then(
                response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw exception;
                    }
                    this.setState({ pipelineStarted: true });
                    return response.blob()
                })
        }
        this.props.workflowRunService.updateCustomField("fastqPath", this.state.directoryList.join("/") + "/" + this.state.selectedDirectory);
        this.props.workflowRunService.updateCustomFields();
    }

    @bind
    private async getPipelineFileshareStatus() {
        await fetch('/api/v1/MinitMinder/FileShare', { method: "GET" }).then(
            response => {
                if (response.status < 200 || response.status >= 300) {
                    throw exception;
                }
                return response.json().then((res) => {
                    return this.setState({ minderFileShareStatus: res["status"].toLowerCase() === "true" });
                });
            })
    }

    @bind
    private async pollStatus() {

        await this.props.workflowRunService.fetchWorkflowRunSequencingStatus(true); //Fetch status
        const sequencingStatusData = this.props.workflowRunService.freezer.get().fetchSequencingStatusState.data;
        if (sequencingStatusData && (sequencingStatusData.SequencingStatus === "Complete" || sequencingStatusData.SequencingStatus === "Failure")) {
            //get data

            //Finish polling
            if (this.timerID) {
                clearInterval(this.timerID);
            }

            await this.props.workflowRunService.fetchWorkflowRunSequencingData(true);
        }
    }

    @bind
    private async generatePdfReport() {
        await this.props.workflowRunService.createPdfReport(this.props.workflowRunService.currentWorkflowRun?.RunNumber.toString()!);
    }

    @bind
    private async completeWorkflow() {
        if (GetWorkflowType() === "SarsCov2") {
            this.setState({ confirmReleaseOpen: true });
        }
        else {
            const currentStep = this.props.workflowRunService.currentStep;
            const sequencingStatusData = this.props.workflowRunService.freezer.get().fetchSequencingStatusState.data;
            if (currentStep && sequencingStatusData) {
                if (sequencingStatusData.SequencingStatus === "Failure") {
                    await this.props.workflowRunService.completeWorkflow(true);
                }
                else {
                    const customFields = currentStep.CustomFields;
                    if (customFields["poreOccupancy"] === "" && GetWorkflowType() === "Anthrax") {
                        this.state.poreOccupancyValid = false;
                    }
                    else {
                        await this.props.workflowRunService.completeWorkflow(false);
                    }
                    if (GetWorkflowType() === "Anthrax") {
                        this.generatePdfReport();
                        this.props.workflowRunService.getMinderJobs().then(() => {
                            var minderRun = this.props.workflowRunService.getState().minderJobStatus.data?.find((status) => {
                                return Number.parseInt(status.RunId) == this.props.workflowRunService.getState().workflowRun.data?.RunNumber;
                            })
                            this.props.workflowRunService.exportBugSeqCsv(this.props.workflowRunService.getState().workflowRunId!, minderRun?.Tag!);
                            this.props.workflowRunService.exportBugSeqCsvExtended(this.props.workflowRunService.getState().workflowRunId!, minderRun?.Tag!);
                        });
                    }
                }
            }
        }
        await Promise.all([
            this.props.workflowRunService.updateCustomFields()
        ]);
    }

    private async completeAndReleaseWorkflow(releaseData: boolean) {
        const currentStep = this.props.workflowRunService.currentStep;
        const sequencingStatusData = this.props.workflowRunService.freezer.get().fetchSequencingStatusState.data;
        if (currentStep && sequencingStatusData) {
            await Promise.all([
                this.props.workflowRunService.releaseRun(this.props.workflowRunService.currentWorkflowRun?.Id || "", releaseData),
                this.props.workflowRunService.completeWorkflow(sequencingStatusData.SequencingStatus === "Failure")
            ]);

            this.setState({ confirmReleaseOpen: false });
        }
    }

    @bind
    private onTextChanged(key: string, newValue: string) {
        let num = parseInt(newValue);

        // between 100 and 0
        if ((num <= 100) && (num >= 0)) {
            this.setState({ poreOccupancyValid: true });
            this.props.workflowRunService.updateCustomField(key, newValue);
        }
        else {
            this.setState({ poreOccupancyValid: false });
        }
        this.props.workflowRunService.updateCustomFields();
    }


    @bind
    private async navigateToQcMetricsPage(sampleId: string) {
        const navigateUrl = urljoin('/qcmetrics', 'statistics', this.props.workflowRunService.currentWorkflowRun?.Id, 'step', this.props.workflowRunService.currentStep?.StepInstanceId, 'sample', sampleId);
        NavigationService.navigateTo(navigateUrl);
    }

    @bind
    private async navigateToResultsPage() {
        const navigateUrl = urljoin('/results', this.props.workflowRunService.currentWorkflowRun?.Id)
        this.props.workflowRunService.resetCurrentWorkflowRun();
        this.props.workflowRunService.selectedStepInstanceId = "";
        NavigationService.navigateTo(navigateUrl);
    }

}