import {
    NgsReportSequenceQcChecksVM, QCCheckInstanceVM, QCCheckResultVM,
} from "$Generated/api";
import {
    bind,
    React,
    _,
  } from "$Imports/Imports";
  
import {Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

import {
    QCCheckResultsDialog
} from "$Components/common/WorkflowControls/QC/QCCheckResultsDialog"

const commonStyles: {
    table: string;
} = require("../../../css/common.scss");

const wetLabStyles: {
    analysisTableHeader : string;
    textEmphasis : string;
    pageLink : string;
} = require("./WetLabReport.scss");


interface IWetLabSequencingQcChecksProps {
    dataSequencingQcChecks: NgsReportSequenceQcChecksVM[] | undefined;
};

interface IWetLabSequencingQCChecksState {
    open: boolean;
    qcInstance?: QCCheckResultVM;
    qcStepName: string;
}

export class WetLabSequencingQcChecks extends React.Component<IWetLabSequencingQcChecksProps, IWetLabSequencingQCChecksState> {

    state : IWetLabSequencingQCChecksState = {
        open: false,
        qcStepName: '',
    }
    
    async componentDidMount(){

    }
  
    render() {

        // Table headers for Sequencing Run Notes (LL-2797)
        var colHeadersSequencingQcChecks = ['Step Name', 'QC Check Requirement', 'Workflow Run Value', 'Passed/Failed'];
       
        return (
            
            <div>

                {this.props.dataSequencingQcChecks ?
                
                // Build an element 
                <>
                <h2>Sequencing Quality Controls</h2>
                <section className={wetLabStyles.textEmphasis}>Review a list of high-level passes/faliures of PTCs and NTCs using the workflow specific QC steps.</section>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    PaperProps={{ style: { maxWidth: "1200px" } }}
                >
                {
                    <QCCheckResultsDialog
                        resultDetails={this.state.qcInstance}
                        stepName={this.state.qcStepName}
                    />
                }
                </Dialog>

                <TableContainer component={Paper} >
                <Table className={commonStyles.table}>
                    <TableHead>
                        <TableRow key='header'>
                            {colHeadersSequencingQcChecks.map((h, nIdx) => (
                                <TableCell key={nIdx} style={{fontWeight: 'bold'}}>{h}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {this.props.dataSequencingQcChecks
                        //.sort((a, b) => a.StepNumber < b.StepNumber ? 1 : -1)
                        .map( (d, nIdx) => (
                            <TableRow key={nIdx}>
                                <TableCell>{d.StepNumber}. {d.StepName}</TableCell>
                                <TableCell><a className={wetLabStyles.pageLink} onClick={e => this.onLinkClick(e, d.QCCheckResult, d.StepName) } >{d.QcCheckRequirement}</a></TableCell>
                                <TableCell>{d.WorkflowRunValue}</TableCell>
                                <TableCell>{(d.Passed && d.Passed == true) ? "Passed" : "Failed"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
                </>
                :
                // Return an empty element.
                <></>
                }
            </div>

        )
      
    }


    // Click event to open the QC Check Result dialog.
    @bind
    private onLinkClick(event : React.MouseEvent<HTMLAnchorElement>, qcCheckResult : QCCheckResultVM, stepName : string) {
        event.preventDefault();
        this.setState({open: true, qcInstance: qcCheckResult, qcStepName: stepName});
    }

    // Handle closing of the QC Check Result dialog.
    @bind
    private handleClose() {
        this.setState({open: false})
    }

}
