import {
    React,
    _
} from "$Imports/Imports";

import { Card, Button } from "$Imports/MaterialUIComponents";

import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";

import { AjaxActionIndicator } from "$Imports/CommonComponents";
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    siteButton: string;
    cardHeader: string;
} = require("./BioinformaticsAnalysis.scss");


interface IBioinformaticsAnalysisPageBaseProps {
}

interface IBioinformaticsAnalysisPageState {
}

type IBioinformaticsAnalysisProps = IBioinformaticsAnalysisPageBaseProps & ISettingsServiceInjectedProps;

export class _BionformaticsAnalysis extends React.Component<IBioinformaticsAnalysisProps, IBioinformaticsAnalysisPageState> {

    state: IBioinformaticsAnalysisPageState =
        {};

    componentDidMount() {
        this.props.settingsService.fetchMIASite(true);
        this.props.settingsService.fetchNextstrainSite(true);
    }

    static contextType = ApplicationSecurityContext;

    render() {
        let state = this.props.settingsService.getState();
        let { MIASiteResult, NextstrainSiteResult } = state;
        const MIA = this.props.settingsService.freezer.get().MIASiteResult.data;
        const Nextstrain = this.props.settingsService.freezer.get().NextstrainSiteResult.data;

        return (
            <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    
                    <h2 className={styles.cardHeader}>Bioinformatics Analysis</h2>
                    <div className={styles.content}>
                        <table>
                            <tbody>
                                <tr key={(MIA == null ? "/Mia" : MIA.Url)}>
                                    <td>
                                        <AjaxActionIndicator
                                            state={MIASiteResult}
                                        />
                                        <Button size={"large"} className={styles.siteButton} disabled={(MIA == null ? true : !MIA.Available)} variant="contained" color="primary" onClick={() => { window.open(MIA?.Url, "_self"); }}>
                                            <div style={{ fontSize: "18pt" }}>
                                                {(MIA == null ? "Loading..." : MIA.Description)}
                                            </div>
                                            <div>
                                                {(MIA == null ? "" : MIA.Available ? "(Online)" : "(Offline)")}
                                            </div>
                                        </Button>
                                    </td>
                                </tr>
                                <tr key={(Nextstrain == null ? "/Nextstrain" : Nextstrain.Url)}>
                                    <td>
                                        <AjaxActionIndicator
                                            state={NextstrainSiteResult}
                                        />
                                        <Button size={"large"} className={styles.siteButton} disabled={(Nextstrain == null ? true : !Nextstrain.Available)} variant="contained" color="primary" onClick={() => { window.open(Nextstrain?.Url, "_self"); }}>
                                            <div style={{ fontSize: "18pt" }}>
                                                {(Nextstrain == null ? "Loading..." : Nextstrain.Description)}
                                            </div>
                                            <div>
                                                {(Nextstrain == null ? "" : Nextstrain.Available ? "(Online)" : "(Offline)")}
                                            </div>
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>
        );
    }
}

export const BioinformaticsAnalysisPage = SettingsService.inject(_BionformaticsAnalysis);