import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { GetWorkflowType, IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, PCRControl, IDataTableColumn, SampleInfoTable, AdvanceTextField, CollapsibleSection, DataLoadingDisplay } from "$Imports/CommonComponents";
import { Snackbar } from "$Imports/MaterialUIComponents";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";


const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

interface IRtPCRScreenState {
    displayValidationWarning: boolean;
    canMove: boolean;
    dataLoaded: boolean;
}

export class RtPCRScreen extends React.Component<IWorkflowScreenProps, IRtPCRScreenState> {

    constructor(props: IWorkflowScreenProps) {
        super(props);

        this.state = {
            displayValidationWarning: false,
            canMove: true,
            dataLoaded: false
        };
    }

    async componentDidMount() {
        if (GetWorkflowType() !== "SarsCov2") {
            await this.props.workflowRunService.fetchPCRForStep(true);
        }
        await this.props.workflowRunService.fetchMasterMixForStep(true);
        if (GetWorkflowType() === "Anthrax") {
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }
        this.props.workflowRunService.fetchInstrumentOptions();
        this.setState({ dataLoaded: true });
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const plates = this.props.workflowRunService.getState().plates;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;

        if (currentWorkflowRun && currentStep && masterMixInfoState.data && plates.data && lotOptions && instrumentOptions) {
            const outputPlate = _.find(plates.data, plate => (plate.Name === currentStep.OutputName));
            if (outputPlate) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.mainDiv}>
                                <MasterMixControl
                                    disabled={disabled}
                                    index={0}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={outputPlate.WellContents.length}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                />
                                {GetWorkflowType() !== "SarsCov2" && pcrInfoState.data &&
                                    <PCRControl disabled={disabled} controlInfo={pcrInfoState.data} updateInstrumentName={this.props.workflowRunService.updatePCRName} instrumentOptions={instrumentOptions} />
                                }
                            </div>
                        </CollapsibleSection>
                        <div className={commonStyles.footerDiv}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.displayValidationWarning}
                            message={"Lot numbers are required"}
                            autoHideDuration={5000}
                            onClose={this.hideValidationWarning}
                        />
                    </div>)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private async moveToNextStep() {
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;

        const currentStep = this.props.workflowRunService.currentStep;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const workflowType = GetWorkflowType();

        if (currentStep && masterMixState.data && (pcrInfoState.data || workflowType === "SarsCov2")) {

            let missingMasterMixData = missingData(masterMixState.data[0]);

            if (currentStep.OutputAssets) {
                let outputAsset = _.find(currentStep.OutputAssets, a => a.Name === currentStep.OutputName);

                if ((workflowType === "SarsCov2" || (pcrInfoState.data && pcrInfoState.data.InstrumentNameValue)) && !missingMasterMixData && outputAsset && outputAsset.PlateSummary) {
                    if (this.state.canMove) {

                        if(workflowType !== "SarsCov2" && pcrInfoState.data && pcrInfoState.data.InstrumentNameValue)
                        {
                            await this.props.workflowRunService.addInstrument(pcrInfoState.data.InstrumentNameValue);
                        }

                        await Promise.all([
                            this.props.workflowRunService.saveMasterMix(true),
                            this.props.workflowRunService.savePCRInfo(),
                            
                            this.props.workflowRunService.savePlateWellVolume(outputAsset.PlateSummary.Id, 25) //this is equal to all master mix reagents and volume of RNA per well. Currently this is 25. If this changes down the line we will need to rework the master mix data structure.
                        ]);
                        await this.props.workflowRunService.completeStep(GetWorkflowType() !== "SarsCov2"); //Needs to be outside due to a race condition
                    }
                }
                else {
                    this.showValidationWarning();
                }
            }
        }
    }

    @bind
    hideValidationWarning() {
        this.setState({ displayValidationWarning: false });
    }

    @bind
    showValidationWarning() {
        this.setState({ displayValidationWarning: true });
    }
}