import {
    React,
    _,
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link, IconButton, Table, TableCell, TableRow } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    DataLoadingDisplay,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import {
    WorkflowConfigService,
    IWorkflowConfigServiceInjectedProps
} from "$State/WorkflowConfigFreezerService";
import { ComponentSetVM, StepRequirementGroupVM, StepRequirementVM } from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import { AddIcon, Close } from "$Imports/MaterialUIIcons";
import { IWorkflowsServiceInjectedProps, WorkflowsService } from "$State/WorkflowsFreezerService";

import {
    WorkflowConfigHelper,
    getShortWorkflowName,
} from "../WorkflowConfig/WorkflowConfigHelper"

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
    table: string;
} = require("./WorkflowComponentManagement.scss");

interface IWorkflowStepComponentAssociationPageBaseProps {
}

interface IWorkflowStepComponentAssociationPageState {
    requirementToEdit?: StepRequirementVM;
    associateDialogOpen: boolean;
    filteredWorkflows: string;
    filteredColumns: ComponentSetVM[];
}

type IWorkflowStepComponentAssociationPageProps = IWorkflowStepComponentAssociationPageBaseProps & IWorkflowConfigServiceInjectedProps & IWorkflowsServiceInjectedProps;

export class _WorkflowStepComponentAssociationPage extends React.Component<IWorkflowStepComponentAssociationPageProps, IWorkflowStepComponentAssociationPageState> {

    private readonly columns: Array<IDataTableColumn<StepRequirementGroupVM>> = [
        {
            columnName: "workflow-name",
            columnFieldData: (d) =>
                <div>
                    {_.map(d.Requirements, r => <div>{getShortWorkflowName(r.WorkflowId)}</div>)}
                </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },{
            columnName: "step-name",
            columnFieldData: "StepName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Workflow Step",
        },
        {
            columnName: "component-set-type",
            columnFieldData: (d) =>
                <div>
                    {_.map(d.Requirements, r => <div>{r.RequirementType === "InstructionSet" ? "Instruction Set" : r.RequirementType === "ReagentList" ? "Reagent List" : r.RequirementType === "SimplifiedReagentList" ? "Simplified Reagent List" : "Reagent Mix"}</div>)}
                </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Component Set Type",
        },
        {
            columnName: "expected-component-set",
            columnFieldData: (d) =>
                <div>
                    {_.map(d.Requirements, r => <div>{r.RequirementName}</div>)}
                </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Expected Component Set",
        },
        {
            columnName: "associated-component-set",
            columnFieldData: (d) =>
                <div>
                    {_.map(d.Requirements, r => <div>{r.ComponentSetName !== null ? r.ComponentSetName : "None"}</div>)}
                </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Associated Component Set Name",
        },
        {
            columnName: "edit",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <div>
                            {_.map(d.Requirements, r =>
                                <div>
                                    {r.ComponentSetName === null ?
                                        <IconButton disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} onClick={(event) => { this.setState({ requirementToEdit: r, associateDialogOpen: true }) }} size={"small"}>
                                            <AddIcon />
                                        </IconButton>
                                        :
                                        <IconButton disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} onClick={(event) => { this.removeAssociation(r) }} size={"small"}>
                                            <Close />
                                        </IconButton>
                                    }
                                </div>)}
                        </div>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ]

    private readonly associateColumns: Array<IDataTableColumn<ComponentSetVM>> = [
        {
            columnName: "name",
            columnFieldData: "DisplayName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "workflows-associated",
            columnFieldData: "AssociatedWorkflows",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Associated Workflows",
        },
        {
            columnName: "runs-associated",
            columnFieldData: "AssociatedRunNumber",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Used in Runs",
        },
        {
            columnName: "associate-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            style={{ float: "right" }}
                            variant="contained"
                            color="primary"
                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                            onClick={() => {
                                this.associateComponentSet(d);
                            }}
                        >
                            Select
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    state: IWorkflowStepComponentAssociationPageState = {
        associateDialogOpen: false,
        filteredWorkflows: '',
        filteredColumns: [],
    }

    async componentDidMount() {
        await this.props.workflowConfigService.fetchStepRequirements(true);
        await this.props.workflowConfigService.fetchInstructionSets(true);
        await this.props.workflowConfigService.fetchReagentLists(true);
        await this.props.workflowConfigService.fetchReagentMixes(true);
        await this.props.workflowConfigService.fetchSimplifiedReagentLists(true);
        await this.props.workflowsService.fetchWorkflows(true);
    }

    render() {

        let { workflowFetchResults } = this.props.workflowsService.getState();
        
        let {
            stepRequirementsFetchResults,
            instructionSetFetchResults,
            reagentListFetchResults,
            simplifiedReagentListFetchResults,
            reagentMixFetchResults
        } = this.props.workflowConfigService.getState();
        if (stepRequirementsFetchResults.data && instructionSetFetchResults.data && reagentListFetchResults.data &&  simplifiedReagentListFetchResults.data && reagentMixFetchResults.data) {
            
            let stepRequirementsData : StepRequirementGroupVM[] = [...stepRequirementsFetchResults.data];
            let instructionSetData : ComponentSetVM[] = [...instructionSetFetchResults.data];
            let reagentListData : ComponentSetVM[] = [...reagentListFetchResults.data];
            let simplifiedReagentListData : ComponentSetVM[] = [...simplifiedReagentListFetchResults.data];
            let reagentMixData: ComponentSetVM[] = [...reagentMixFetchResults.data];

            //Filter according to the selected workflow.
            if (this.state.filteredWorkflows !=='')
            {
                stepRequirementsData = [];
                _.forEach(stepRequirementsFetchResults.data, x => {
                    let t : StepRequirementGroupVM = {StepName: x.StepName, Requirements: []};
                    
                    _.forEach(x.Requirements, r => {
                        if (r.WorkflowId === this.state.filteredWorkflows){
                            t.Requirements.push(r);
                        }
                    })
                    if (t.Requirements.length !== 0) {stepRequirementsData.push(t);}
                })

                instructionSetData = [];
                _.forEach(instructionSetFetchResults.data, x => {
                    if (x.AssociatedWorkflows && x.AssociatedWorkflows.some( y => y === this.state.filteredWorkflows)){
                        instructionSetData.push(x);
                    }
                })

                reagentListData = [];
                _.forEach(reagentListFetchResults.data, x => {
                    if (x.AssociatedWorkflows && x.AssociatedWorkflows.some( y => y === this.state.filteredWorkflows)){
                        reagentListData.push(x);
                    }
                })

                simplifiedReagentListData = [];
                _.forEach(simplifiedReagentListFetchResults.data, x => {
                    if (x.AssociatedWorkflows && x.AssociatedWorkflows.some( y => y === this.state.filteredWorkflows)){
                        simplifiedReagentListData.push(x);
                    }
                })

                reagentMixData = [];
                _.forEach(simplifiedReagentListFetchResults.data, x => {
                    if (x.AssociatedWorkflows && x.AssociatedWorkflows.some( y => y === this.state.filteredWorkflows)){
                        reagentMixData.push(x);
                    }
                })
            }
            
            // Order the Step Requirements data by Workflow Name.
            stepRequirementsData.sort((a, b) => {
                if (a.Requirements.length > 0 && b.Requirements.length > 0)
                {
                    let p = getShortWorkflowName(a.Requirements[0].WorkflowId);
                    let q = getShortWorkflowName(b.Requirements[0].WorkflowId);
                    return (p > q) ? 1 : (p < q) ? -1 : 0;
                }
                return 0;
            });
            
            
            return <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    <h2>Workflow Step Component Association</h2>
                    {workflowFetchResults &&
                    <div>
                        <WorkflowConfigHelper
                            onChange={e => this.setState({filteredWorkflows: e.target.value})}
                            filteredWorkflows={this.state.filteredWorkflows}>
                        </WorkflowConfigHelper>
                        
                        <div style={{fontStyle: 'italic'}}>Note: The "Add" button will disappear once a component has been associated to a step. Users will be able to change associations for future workflow runs,
                        not the runs that have already been started.
                        </div>    
                        
                    </div>
                    
                    }
                    
                    <DataTable
                        data={stepRequirementsData}
                        columns={this.columns}
                    />
                </Card>
                <Dialog
                    className={styles.dialog}
                    style={{ height: "800px" }}
                    open={this.state.associateDialogOpen}
                    onClose={() => { this.setState({ associateDialogOpen: false }) }}
                >
                    <Card>
                        <h2>
                            {this.state.requirementToEdit?.RequirementType === "InstructionSet" ? "Instruction Sets" : this.state.requirementToEdit?.RequirementType === "ReagentList" ? "Reagent Lists" : this.state.requirementToEdit?.RequirementType === "SimplifiedReagentList" ? "Simplified Reagent Lists" : "Reagent Mixes"}
                        </h2>
                        <div className={styles.table}>
                            <DataTable
                                data={this.state.requirementToEdit?.RequirementType === "InstructionSet" ? instructionSetData : this.state.requirementToEdit?.RequirementType === "ReagentList" ? reagentListFetchResults.data : this.state.requirementToEdit?.RequirementType === "SimplifiedReagentList" ? simplifiedReagentListData : reagentMixData}
                                columns={this.associateColumns}
                                stickyHeader
                            />
                        </div>
                    </Card>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.setState({ associateDialogOpen: false }) }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >;
        }
        return <DataLoadingDisplay />;
    }

    private async associateComponentSet(toAssociate: ComponentSetVM) {
        let requirement = this.state.requirementToEdit;
        if (requirement) {
            let newReq = _.cloneDeep(requirement);
            newReq.ComponentSetId = toAssociate.Id;
            await this.props.workflowConfigService.updateStepRequirement(newReq);
            await this.props.workflowConfigService.fetchStepRequirements(true);
            this.setState({ associateDialogOpen: false, requirementToEdit: undefined })
        }
    }

    private async removeAssociation(toRemove: StepRequirementVM) {
        let newReq = _.cloneDeep(toRemove);
        newReq.ComponentSetId = undefined;
        await this.props.workflowConfigService.updateStepRequirement(newReq);
        await this.props.workflowConfigService.fetchStepRequirements(true);
    }

    
}

export const WorkflowStepComponentAssociationPage = WorkflowsService.inject(WorkflowConfigService.inject(_WorkflowStepComponentAssociationPage));