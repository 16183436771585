import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil
} from "$Imports/Imports";

import {
    GetTestOrderStatusBoardResponse,
    DashboardApiFactory,
    GetTodaysWorkloadResponse,
    GetProductivityBenchmarkResponseDay,
    GetYearToDateInfoResponse,
} from "$Generated/api";
import { apiExceptionHandler } from "./ErrorFreezerService";


interface IDashboardState {
    statusBoardFetchResults: IAjaxState<GetTestOrderStatusBoardResponse>;
    todaysWorkloadFetchResults: IAjaxState<GetTodaysWorkloadResponse>;
    productivityBenchmarkFetchResults: IAjaxState<GetProductivityBenchmarkResponseDay[]>
    yearToDateInfoFetchResults: IAjaxState<GetYearToDateInfoResponse>;
}

const InjectedPropName = "dashboardService";

class DashboardFreezerService extends FreezerService<IDashboardState, typeof InjectedPropName> {
    constructor() {
        super({
            statusBoardFetchResults: managedAjaxUtil.createInitialState(),
            todaysWorkloadFetchResults: managedAjaxUtil.createInitialState(),
            productivityBenchmarkFetchResults: managedAjaxUtil.createInitialState(),
            yearToDateInfoFetchResults: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async fetchTestOrderStatusBoard() {
        managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "statusBoardFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = DashboardApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1DashboardStatusboardGet();
            },
            onError: apiExceptionHandler
        });
    }

    public async fetchTodaysWorkload() {
        managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "todaysWorkloadFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = DashboardApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1DashboardTodaysworkloadGet();
            },
            onError: apiExceptionHandler
        });
    }

    public async fetchProductivityBenchmark() {
        managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "productivityBenchmarkFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = DashboardApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1DashboardProductivitybenchmarkGet();
            },
            onError: apiExceptionHandler
        });
    }

    public async fetchYearToDateInfo() {
        managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "yearToDateInfoFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = DashboardApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1DashboardYearToDateInfoGet();
            },
            onError: apiExceptionHandler
        });
    }
}

export const DashboardService = new DashboardFreezerService();
export type IDashboardServiceInjectedProps = ReturnType<DashboardFreezerService["getPropsForInjection"]>;
