import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  TableRow,
} from "$Imports/MaterialUIComponents";

import {
  DataTableCell
} from "./DataTableCell";

import {
  IDataTableColumn
} from "./IDataTableColumn";

import { styled } from '@material-ui/core/styles';

export interface IDataTableRowProps<T = unknown> {
  data: T;
  columns: Array<IDataTableColumn<T>>;
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) => void;
  onCellClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) => void;
  isError?: boolean;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: "#e1e8ed",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledErrorTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: "#f7dfd7",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export class DataTableRow<T = unknown> extends React.PureComponent<IDataTableRowProps<T>> {

  @bind
  private _onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
    if (this.props.onRowClick) {
      this.props.onRowClick(event, this.props.data);
    }
  }

  @bind
  private _onCellClick(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) {
    if (this.props.onCellClick) {
      this.props.onCellClick(event, data, column);
    }
  }

  render() {
    const { data, columns } = this.props;

    return (this.props.isError ? 
      <StyledErrorTableRow
        onClick={this._onRowClick}
      >
        {
          _.map(columns, (c, cidx) => (
            <DataTableCell
              column={c}
              data={data}
              key={cidx}
              onCellClick={this._onCellClick}
            />
          ))
        }
      </StyledErrorTableRow>
      :
      <StyledTableRow
        onClick={this._onRowClick}
      >
        {
          _.map(columns, (c, cidx) => (
            <DataTableCell
              column={c}
              data={data}
              key={cidx}
              onCellClick={this._onCellClick}
            />
          ))
        }
      </StyledTableRow>
    );
  }
}
