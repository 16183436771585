import {
    React,
    bind,
    Freezer,
} from "$Imports/Imports";

import {
    PCRControlInfoVM
} from "$Generated/api";


import {
    Popover, Link, TextField
} from "$Imports/MaterialUIComponents";
import {
    AdvanceTextField, CustomDisplayTypes
} from "$Imports/CommonComponents";

import { Autocomplete } from "@material-ui/lab";
import { GetWorkflowType } from "../../WorkflowStep/WorkflowStep";


const ReactMarkdown = require('react-markdown');

const styles: {
    PCRTable: string;
    PCRRow: string;
    PCRLabelCell: string;
    PCRDataCell: string;
    markdown: string;
    PCRInput: string;
    PCRDataCellHeader: string
} = require("./PCRControl.scss");

export interface IPCRControlProps {
    controlInfo: PCRControlInfoVM;
    updateInstrumentName: (name: string) => void;
    disabled?: boolean;
    headerOverride?: string;
    instrumentOptions?: string[];
    customDisplay?: CustomDisplayTypes;
}

export interface IPCRControlState {
    open: boolean;
    element: Element | null
}

export class PCRControl extends React.PureComponent<IPCRControlProps, IPCRControlState> {

    state: IPCRControlState = {
        open: false,
        element: null
    }

    render() {
        const pcrInfo = this.props.controlInfo;

        let overrideName = "PCR Information"
        let workflowType = GetWorkflowType();
        if (workflowType === "Anthrax" || (workflowType === "SarsCov2" && this.props.customDisplay === "tagAmp")){
            overrideName = "Thermocycler Instrument"
        }
        return (
            <div>
                <h2>{this.props.headerOverride ? this.props.headerOverride : overrideName}</h2>
                <table className={styles.PCRTable}>
                    <tbody>

                        <tr className={styles.PCRRow} >
                            <td className={styles.PCRDataCellHeader}>
                                {pcrInfo.InstrumentNameLabel}
                            </td>
                            <td className={styles.PCRDataCell}>
                                {(pcrInfo.InstrumentNameIsReadonly) ? pcrInfo.InstrumentNameValue :
                                    <Autocomplete
                                        freeSolo
                                        disabled={this.props.disabled}
                                        id="instrument-name"
                                        options={this.props.instrumentOptions || []}
                                        onChange={(e,val) => this.props.updateInstrumentName(val || "")}
                                        value={pcrInfo.InstrumentNameValue}
                                        renderInput={(params) =>
                                            (
                                                <TextField
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }} 
                                                    disabled={this.props.disabled} className={styles.PCRInput} value={pcrInfo.InstrumentNameValue} onChange={(props) => this.props.updateInstrumentName(props.target.value)} />
                                            )}
                                    />

                                }
                            </td>
                        </tr>
                        <tr className={styles.PCRRow}>
                            <td className={styles.PCRDataCellHeader}>
                                {pcrInfo.ProgramNameLabel}
                            </td>
                            <td className={styles.PCRDataCell} onClick={this.onClick}>
                                <Link>
                                    {pcrInfo.ProgramNameValue}
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Popover
                    open={this.state.open}
                    onClose={this.handleClose}
                    anchorEl={this.state.element}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <ReactMarkdown className={styles.markdown} source={pcrInfo.ProgramStepsMarkdown} />
                </Popover>
            </div>
        );

    }

    @bind
    private onClick(event: React.MouseEvent) {
        this.setState({ open: true, element: event.currentTarget });
    }

    @bind
    private handleClose() {
        this.setState({ open: false });
    }
}