import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Button, Card } from "$Imports/MaterialUIComponents";

import { IPipelineServiceInjectedProps, PipelineService } from "$State/PipelineFreezerService";

import ScrollSpyLayout from "$Components/common/ScrollSpyLayout/ScrollSpyLayout"

const styles: {
    main: string;
} = require("./PipelineRunLayoutPage.scss");

import { NavigationService } from "$State/NavigationFreezerService";
import { PipelineAnalysis } from "$Components/common/PipelineRuns/PipelineAnalysis";
import { IWorkflowRunServiceInjectedProps, WorkflowRunService } from "$State/WorkflowRun/WorkflowRunFreezerService";

interface IPipelineRunBaseProps {
    runNumber: number;
}

interface IPipelineRunState {
}

type IPipelineRunProps = IPipelineRunBaseProps & IPipelineServiceInjectedProps & IWorkflowRunServiceInjectedProps;

export class _PipelineRunLayoutPage extends React.Component<IPipelineRunProps, IPipelineRunState> {

    state: IPipelineRunState = {
    }

    async componentDidMount() {
        await this.props.PipelineService.getPipelineRun(this.props.runNumber);

        this.props.workflowRunService.workflowId = this.props.PipelineService.getState().currentPipelineRun.data?.WetlabRunId as string;

        await Promise.all([
            this.props.workflowRunService.fetchWorkflowRunMetadata(true),
            this.props.workflowRunService.fetchWorkflowRunSequencingData(true)
        ]);
    }

    async componentWillUnmount() {
        this.props.PipelineService.resetData();
    }

    render() {

        return (
            <Card style={{ paddingTop: "70px" }}>
                <ScrollSpyLayout
                    itemsInScroll={[
                        {
                            menuText: "Pipeline Analysis",
                            component: <PipelineAnalysis />
                        },
                    ]}

                    scrollSpyPageHeader={null}

                    scrollSpyMenuHeader={null}

                    scrollSpyMenuFooter={<Button variant="contained"
                        color="primary"
                        onClick={() => this.navigateToPipelineRunsPage()}>
                        Exit Pipeline Run
                    </Button>}

                />
            </Card>
        )
    }

    private navigateToPipelineRunsPage(): void {
        NavigationService.navigateTo("/existingPipelineRun");
    }
}

export const PipelineRunLayoutPage = WorkflowRunService.inject(PipelineService.inject(_PipelineRunLayoutPage));