import {
    React,
    bind,
    _,
} from "$Imports/Imports";

import {
    IWorkflowScreenProps, GetWorkflowType
} from "../WorkflowStep";
import { WarningIcon } from "$Imports/MaterialUIIcons";
import { CollapsibleSection, 
        DataLoadingDisplay, 
        DataTable, 
        IDataTableColumn, 
        StepActionsControl, 
        StepChangeControl, 
        DilutionMinConcentration, 
        QCUIComponent, 
        DilutionNTCConcentrationCheck,
        DilutionSampleMinConcentrationCheck  } from "$Imports/CommonComponents";
import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";
import { TableCellProps } from "$Imports/MaterialUIComponents";

import {
    CalculationService
} from "$State/CalculationFreezerService";
import {
    QCCheckInstanceVM,
    QCCheckResultVM
} from "$Generated/api";

const commonStyles: {
    footerDiv: string;
    collapsibleDiv: string;
    tableHeader: string;
    rowHighlight: string;
} = require("./CommonStepStyles.scss");

interface IAmpliconDilutionScreenState {
    dilutionData: IDilutionData[];
    dataLoaded: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    qcOpen: boolean;
    warningShown: boolean;
}

interface IDilutionData {
    sampleId: string;
    wellPosition: string;
    quantifiedConcentration: number | undefined;
    currentSampleVolume: number;
    sampleVolumeToUseInDilution: number;
    waterVolumeToUseInDilution: number;
    resultingVolume: number;
    resultingConcentration: number;
    underMinimum: boolean;
}


export class AmpliconDilutionStepScreen extends React.Component<IWorkflowScreenProps, IAmpliconDilutionScreenState> {


    @bind
    private cellProps(d: IDilutionData): TableCellProps {
        if (d.underMinimum && !d.sampleId.includes('NTC')) {
            return {
                className: commonStyles.rowHighlight
            };
        }
        return {
        };
    }

    private columns(): Array<IDataTableColumn<IDilutionData>> {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "warning-icon",
                    columnFieldData: (d) => (d.underMinimum && !d.sampleId.includes('NTC')) ? <WarningIcon /> : <></>,
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "",
                    cellProps: this.cellProps,
                },
                {
                    columnName: "sample-id",
                    columnFieldData: "sampleId",
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "Sample ID",
                    cellProps: this.cellProps,
                },
                {
                    columnName: "well-position",
                    columnFieldData: "wellPosition",
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "Well Position",
                    sortMethod: (d) => d.wellPosition ?? "",
                    cellProps: this.cellProps,
                },
                {
                    columnName: "quantified-concentration",
                    columnFieldData: (d) => {
                        return d.quantifiedConcentration != undefined ? d.quantifiedConcentration.toFixed(2) : 0;
                    },
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "Quantified Concentration (ng/uL)",
                    cellProps: this.cellProps,
                },
                {
                    columnName: "current-sample-volume",
                    columnFieldData: (d) => {
                        return d.currentSampleVolume.toFixed(2);
                    },
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "Current Sample Volume (uL)",
                    cellProps: this.cellProps,
                },
                {
                    columnName: "sample-volume-to-use-dilution",
                    columnFieldData: (d) => {
                        return d.sampleVolumeToUseInDilution.toFixed(2);
                    },
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "Sample Volume to Use in Dilution (uL)",
                    cellProps: this.cellProps,
                },
                {
                    columnName: "water-volume-to-use-dilution",
                    columnFieldData: (d) => {
                        return d.waterVolumeToUseInDilution.toFixed(2);
                    },
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "Water Volume to Use in Dilution (uL)",
                    cellProps: this.cellProps,
                },
                {
                    columnName: "resulting-volume",
                    columnFieldData: (d) => {
                        return d.resultingVolume.toFixed(2);
                    },
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "Resulting Volume (uL)",
                    cellProps: this.cellProps,
                },
                {
                    columnName: "resulting-concentration",
                    columnFieldData: (d) => {
                        return d.resultingConcentration.toFixed(2);
                    },
                    headerProps: {
                        className: commonStyles.tableHeader,
                    },
                    headerValue: "Resulting Concentration (ng/uL)",
                    cellProps: this.cellProps,
                },
            ];
        }
        return [];
    }


    state: IAmpliconDilutionScreenState = {
        dilutionData: [],
        dataLoaded: false,
        failedQcs: [],
        qcResults: [],
        qcOpen: false,
        warningShown: false
    };

    async componentDidMount() {
        if (GetWorkflowType() === "Anthrax") {
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }
        this.calculateDilutionData();
        this.setState({dataLoaded: true});
    }

    componentWillUnmount() {
       this.props.workflowRunService.resetInternalData();
    }

    @bind
    private async moveToNextStepBegin() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];

        let samples = this.state.dilutionData;
        
        if (currentWorkflowRun && currentStep) {
            //Run all QCs
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case DilutionNTCConcentrationCheck:
                        //this QC is only NTCs
                        let ntcs = _.filter(samples, (sample) => (sample.sampleId.includes('NTC')));
                        _.forEach(ntcs, ntc => {
                            let concentrationPass = true;
                            //right now we aren't doing any caculation for maximum concentration, so this check will be a little magical
                            //NTC resulting concentrations should be <= 1 ng/ul
                            if (ntc.resultingConcentration > 1) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                                concentrationPass = false;
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? concentrationPass ? undefined : 1 : 2,
                                    MeasuredValue: ntc.resultingConcentration.toString(),
                                    Date: new Date(Date.now()),
                                    Pass: concentrationPass,
                                    QCCheckInstance: qc,
                                }
                            );
                        });
                        break;
                    case DilutionSampleMinConcentrationCheck:
                        //this QC is everything except NTCs
                        let step = _.filter(samples, (sample) => (!sample.sampleId.includes('NTC')));
                        _.forEach(step, sample => {
                            let minConcentrationPass = true;
                            //as above, this is a magic check
                            //this is because we are setting the minimum concentration locally in render()
                            //concentration should be >= 60 ng/ul
                            if (sample.resultingConcentration < 60) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                            }
                            minConcentrationPass = false;
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? minConcentrationPass ? undefined : 1 : 2,
                                    MeasuredValue: sample.resultingConcentration.toString(),
                                    Date: new Date(Date.now()),
                                    Pass: minConcentrationPass,
                                    QCCheckInstance: qc,
                                }
                            );
                        });
                        break;
                }
            });
        
            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled) && !this.state.warningShown) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
                //anthrax qc steps are just a warning so we'll continue after the failed QCs are displayed
                this.setState({ warningShown: true});
            }
            else if ((failedQCs.length === 0) || (this.state.warningShown)) {
                this.setState({ qcOpen: true, qcResults: qcResults });
                this.completeMoveToNextStep();
            }

        }
    }

    private async completeMoveToNextStep() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        if (currentWorkflowRun && currentStep) {
            await Promise.all([
                this.props.workflowRunService.saveConcentrations()
            ]);
            await this.props.workflowRunService.completeStep();
        }
    }

    render() {
        let {
            currentStep,
            currentWorkflowRun,
        } = this.props.workflowRunService;
        if (currentStep && currentWorkflowRun) {
            const plates = this.props.workflowRunService.getState().plates;
            const racks = this.props.workflowRunService.getState().racks;
            let inputAsset;
            if (GetWorkflowType() === "Anthrax") {
                inputAsset = _.find(racks.data, (p) => { return p.Name === currentStep?.InputName });
            }
            else {
                inputAsset = _.find(plates.data, (p) => { return p.Name === currentStep?.InputName });
            }
            
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");


            let minConcentration = 0;
            let startMaxConcentration = 0;
            let startMinConcentration = 0;
            let sampleVolume = 0;

            if (GetWorkflowType() === "Anthrax") {
                minConcentration = 60;
                startMinConcentration = 60;
                startMaxConcentration = 100;
                sampleVolume = 46;
            }
            else {
                minConcentration = 4;
                startMinConcentration = 100;
                startMaxConcentration = 500;
                sampleVolume = 30;
            }
            
            //it appears this QC is here because of the scope of minConcentration
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                if(qc.CheckConfig) {
                    const param = _.find(qc.CheckConfig.Parameters, (param) => param.Id === DilutionMinConcentration);
                    if(param) {
                        minConcentration = parseInt(param.Value);
                    }
                }
            });

            return <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.collapsibleDiv}>
                                <div>
                                    <h2>Dilution Preparation</h2>
                                    {(GetWorkflowType() === "Anthrax")
                                        ? <h3><i>Desired DNA Concentration / Sample: { startMinConcentration }-{ startMaxConcentration } ng/ul</i></h3>
                                        : <h3><i>Desired DNA Concentration / Sample: { startMinConcentration }-{ startMaxConcentration } ng in { sampleVolume } μL CED Water</i></h3>

                                    }
                                    
                                    <div>Sample Count: {inputAsset?.WellContents.length}</div>
                                    <DataTable
                                        columns={this.columns()}
                                        data={this.state.dilutionData}
                                        defaultSortColumnName={"well-position"}
                                    />
                                    {
                                        _.find(this.state.dilutionData, d => d.underMinimum && !d.sampleId.includes('NTC')) &&
                                        <div style ={{display:"flex", flexDirection:"row"}}>
                                            <div style={{ marginTop: "auto", marginBottom:"auto" }}>
                                                <WarningIcon />
                                            </div>
                                            <i>Warning: These samples will fall below the minimum desired concentration of { minConcentration } ng / uL. Sequencing read quality may be affected.</i>
                                        </div>
                                    }
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                        {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                    completeStep={this.completeMoveToNextStep}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                    </div>;
        }
        return <DataLoadingDisplay />;
    }

    private async calculateDilutionData() {
        const plates = this.props.workflowRunService.getState().plates;
        const racks = this.props.workflowRunService.getState().racks;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const results: IDilutionData[] = [];
        const currentStep = this.props.workflowRunService.currentStep;
        if ((plates.data || racks.data) && currentStep && currentWorkflowRun) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            let outputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.OutputName });
            let inputRack = _.find(racks.data, (p) => { return p.Name === currentStep.InputName });
            let outputRack = _.find(racks.data, (p) => { return p.Name === currentStep.OutputName });
            if ((inputPlate || inputRack) && (outputPlate || outputRack)) {
                
                let customFields;
                if(GetWorkflowType() === "Anthrax") {
                    customFields = {
                        CurrentSampleVolumeToUse: 46,
                        TargetVolume: 46,
                        IsAnthrax: true
                    };
                }
                else {
                    customFields = {
                        CurrentSampleVolumeToUse: 25,
                        TargetVolume: 30
                    };
                }


                const dilutionDataDictionary: { [index: string]: number } = {};

                if (GetWorkflowType() === "Anthrax" && inputRack) {
                    _.forEach(inputRack.WellContents, well => {
                        dilutionDataDictionary[well.Id] = well.Concentration || 0;
                    });
                }
                else if (inputPlate) {
                    _.forEach(inputPlate.WellContents, well => {
                        dilutionDataDictionary[well.Id] = well.Concentration || 0;
                    });
                }


                await CalculationService.performCalculation("dilution", dilutionDataDictionary, customFields);
                let data = CalculationService.getState().performCalculationResponse.data?.CalculationResponse as { [key: string]: any };
                let calculatedDilutionData = data["dilutionData"];
                let concentrationDictionary = _.cloneDeep(this.props.workflowRunService.concentrationDictionary);
                let wellContents;
                let outputAssets = outputPlate;

                if (GetWorkflowType() === "Anthrax" && inputRack) {
                    wellContents = inputRack.WellContents;
                }
                else if (inputPlate) {
                    wellContents = inputPlate.WellContents;
                }

                //pulling this out of the foreach loop for efficiency purposes
                if (GetWorkflowType() === "Anthrax") {
                    outputAssets = outputRack;
                }

                _.forEach(wellContents, well => {
                    let calculatedData = calculatedDilutionData[well.Id];
                    if(well) {
                        results.push({
                            sampleId: well.Sample?.SampleId || well.Control?.SampleId || "",
                            wellPosition: ConvertPositionToString(well.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow),
                            quantifiedConcentration: well.Concentration,
                            currentSampleVolume: calculatedData ? calculatedData.CurrentSampleVolume : 0,
                            sampleVolumeToUseInDilution: calculatedData ? calculatedData.SampleVolumeToUseInDilution : 0,
                            waterVolumeToUseInDilution: calculatedData ? calculatedData.WaterVolumeToUseInDilution : 0,
                            resultingVolume: calculatedData ? calculatedData.ResultingVolume : 0,
                            resultingConcentration: calculatedData ? calculatedData.ResultingConcentration : 0,
                            underMinimum: calculatedData ? calculatedData.UnderMinimum : false
                        });

                        let outputWellId = well.Id;

                        if(well.Control && outputAssets?.WellContents) {
                            let outputWell = _.find(outputAssets.WellContents, (owc) => {
                                if(owc.Control != undefined) {
                                    return owc.Control.ControlId == well.Control?.ControlId;
                                }
                                return false;
                            });
                            if(outputWell) {
                                outputWellId = outputWell.Id;
                            }
                        } else if (well.Sample && outputAssets?.WellContents) {
                            let outputWell = _.find(outputAssets.WellContents, (owc) => {
                                if(owc.Sample != undefined) {
                                    return owc.Sample.SampleId == well.Sample?.SampleId;
                                }
                                return false;
                            });
                            if(outputWell) {
                                outputWellId = outputWell.Id;
                            }
                        }
                        
                        concentrationDictionary[outputWellId] = {
                            Concentration: calculatedData ? calculatedData.ResultingConcentration : 0,
                            SampleVolume: calculatedData ? calculatedData.ResultingVolume : 0,
                            Ratio230: 0,
                            Ratio280: 0
                        }
                    }
                });

                this.props.workflowRunService.concentrationDictionary = concentrationDictionary;

                this.setState({ dilutionData: results });
            }
        }
    }

}