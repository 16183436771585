import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil
} from "$Imports/Imports";

import {
    ControlMaterialVM, AssetManagementApiFactory, ManipulationTypeVM, InstrumentVM, TemperatureProgramVM, ReagentVM, ReagentGroupVM, ReagentType, ReagentGroupType,
} from "$Generated/api";


interface IAssetServiceState {
    controlMaterialsFetchResults: IAjaxState<ControlMaterialVM[]>;
    addControlMaterialResults: IAjaxState<boolean>;
    updateControlMaterialResults: IAjaxState<boolean>;
    controlTypesFetchResults: IAjaxState<string[]>;
    addControlTypeResults: IAjaxState<boolean>;
    manipulationTypesFetchResults: IAjaxState<ManipulationTypeVM[]>;
    addManipulationTypeResults: IAjaxState<boolean>;
    updateManipulationTypeResults: IAjaxState<boolean>;
    instrumentFetchResults: IAjaxState<InstrumentVM[]>;
    addInstrumentResults: IAjaxState<boolean>;
    updateInstrumentResults: IAjaxState<boolean>;
    temperatureProgramFetchResults: IAjaxState<TemperatureProgramVM[]>;
    addTemperatureProgramResults: IAjaxState<boolean>;
    updateTemperatureProgramResults: IAjaxState<boolean>;
    temperatureProgramTypesFetchResults: IAjaxState<string[]>;
    addTemperatureProgramTypeResults: IAjaxState<boolean>;
    reagentFetchResults: IAjaxState<ReagentVM[]>;
    barcodeReagentFetchResults: IAjaxState<ReagentVM[]>;
    barcodeKitReagentFetchResults: IAjaxState<ReagentVM[]>;
    addReagentResults: IAjaxState<boolean>;
    updateReagentResults: IAjaxState<boolean>;
    poolFetchResults: IAjaxState<ReagentGroupVM[]>;
    customKitFetchResults: IAjaxState<ReagentGroupVM[]>;
    commercialKitFetchResults: IAjaxState<ReagentGroupVM[]>;
    addReagentGroupResults: IAjaxState<boolean>;
    updatePoolResults: IAjaxState<boolean>;
    standaloneReagentFetchResults: IAjaxState<ReagentVM[]>;
    minIONBarcodeKitFetchResults: IAjaxState<ReagentGroupVM[]>;
    illuminaBarcodeKitFetchResults: IAjaxState<ReagentGroupVM[]>;
    illuminaDualAdapterBarcodeKitFetchResults: IAjaxState<ReagentGroupVM[]>;
}

const InjectedPropName = "assetService";

class AssetFreezerService extends FreezerService<IAssetServiceState, typeof InjectedPropName> {
    constructor() {
        super({
            controlMaterialsFetchResults: managedAjaxUtil.createInitialState(),
            controlTypesFetchResults: managedAjaxUtil.createInitialState(),
            addControlMaterialResults: managedAjaxUtil.createInitialState(),
            updateControlMaterialResults: managedAjaxUtil.createInitialState(),
            addControlTypeResults: managedAjaxUtil.createInitialState(),
            addManipulationTypeResults: managedAjaxUtil.createInitialState(),
            manipulationTypesFetchResults: managedAjaxUtil.createInitialState(),
            updateManipulationTypeResults: managedAjaxUtil.createInitialState(),
            addInstrumentResults: managedAjaxUtil.createInitialState(),
            instrumentFetchResults: managedAjaxUtil.createInitialState(),
            updateInstrumentResults: managedAjaxUtil.createInitialState(),
            addTemperatureProgramResults: managedAjaxUtil.createInitialState(),
            temperatureProgramFetchResults: managedAjaxUtil.createInitialState(),
            updateTemperatureProgramResults: managedAjaxUtil.createInitialState(),
            addTemperatureProgramTypeResults: managedAjaxUtil.createInitialState(),
            temperatureProgramTypesFetchResults: managedAjaxUtil.createInitialState(),
            reagentFetchResults: managedAjaxUtil.createInitialState(),
            addReagentResults: managedAjaxUtil.createInitialState(),
            updateReagentResults: managedAjaxUtil.createInitialState(),
            addReagentGroupResults: managedAjaxUtil.createInitialState(),
            poolFetchResults: managedAjaxUtil.createInitialState(),
            updatePoolResults: managedAjaxUtil.createInitialState(),
            standaloneReagentFetchResults: managedAjaxUtil.createInitialState(),
            barcodeReagentFetchResults: managedAjaxUtil.createInitialState(),
            customKitFetchResults: managedAjaxUtil.createInitialState(),
            commercialKitFetchResults: managedAjaxUtil.createInitialState(),
            minIONBarcodeKitFetchResults: managedAjaxUtil.createInitialState(),
            barcodeKitReagentFetchResults: managedAjaxUtil.createInitialState(),
            illuminaBarcodeKitFetchResults: managedAjaxUtil.createInitialState(),
            illuminaDualAdapterBarcodeKitFetchResults: managedAjaxUtil.createInitialState()

        }, InjectedPropName);
    }

    public async fetchControlMaterials(forceUpdate?: boolean) {
        const { controlMaterialsFetchResults } = this.freezer.get();

        if (controlMaterialsFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "controlMaterialsFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementControlMaterialsGet();
            },
        });
    }

    public async addControlMaterial(toAdd: ControlMaterialVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addControlMaterialResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementAddControlMaterialPost({body: toAdd});
            },
        });
    }

    public async updateControlMaterial(toUpdate: ControlMaterialVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateControlMaterialResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementUpdateControlMaterialPost({body: toUpdate});
            },
        });
    }

    public async fetchControlTypes(forceUpdate?: boolean) {
        const { controlTypesFetchResults } = this.freezer.get();

        if (controlTypesFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "controlTypesFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementControlTypesGet();
            },
        });
    }

    public async addControlType(toAdd: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addControlTypeResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementAddControlTypePost({typeName: toAdd});
            },
        });
    }

    public async fetchManipulationTypes(forceUpdate?: boolean) {
        const { manipulationTypesFetchResults } = this.freezer.get();

        if (manipulationTypesFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "manipulationTypesFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementManipulationTypesGet();
            },
        });
    }

    public async addManipulationType(toAdd: ManipulationTypeVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addManipulationTypeResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementAddManipulationTypePost({body: toAdd});
            },
        });
    }

    public async updateManipulationType(toUpdate: ManipulationTypeVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateManipulationTypeResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementUpdateManipulationTypePost({body: toUpdate});
            },
        });
    }

    public async fetchInstruments(forceUpdate?: boolean) {
        const { instrumentFetchResults } = this.freezer.get();

        if (instrumentFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "instrumentFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementInstrumentsGet();
            },
        });
    }

    public async addInstrument(toAdd: InstrumentVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addInstrumentResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementAddInstrumentPost({body: toAdd});
            },
        });
    }

    public async updateInstrument(toUpdate: InstrumentVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateInstrumentResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementUpdateInstrumentPost({body: toUpdate});
            },
        });
    }

    public async fetchTemperaturePrograms(forceUpdate?: boolean) {
        const { temperatureProgramFetchResults } = this.freezer.get();

        if (temperatureProgramFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "temperatureProgramFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementTemperatureProgramsGet();
            },
        });
    }

    public async addTemperatureProgram(toAdd: TemperatureProgramVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addTemperatureProgramResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementAddTemperatureProgramPost({body: toAdd});
            },
        });
    }

    public async updateTemperatureProgram(toUpdate: TemperatureProgramVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateTemperatureProgramResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementUpdateTemperatureProgramPost({body: toUpdate});
            },
        });
    }

    public async fetchTemperatureProgramTypes(forceUpdate?: boolean) {
        const { temperatureProgramTypesFetchResults } = this.freezer.get();

        if (temperatureProgramTypesFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "temperatureProgramTypesFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementTemperatureProgramTypesGet();
            },
        });
    }

    public async addTemperatureProgramType(toAdd: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addTemperatureProgramTypeResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementAddTemperatureProgramTypePost({typeName: toAdd});
            },
        });
    }

    public async fetchReagents(forceUpdate?: boolean) {
        const { reagentFetchResults } = this.freezer.get();

        if (reagentFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "reagentFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentsGet({type: "Reagent"});
            },
        });
    }

    public async fetchBarcodeReagents(forceUpdate?: boolean) {
        const { barcodeReagentFetchResults } = this.freezer.get();

        if (barcodeReagentFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "barcodeReagentFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentsGet({type: "BarcodeReagent"});
            },
        });
    }

    public async fetchBarcodeKitReagents(forceUpdate?: boolean) {
        const { barcodeKitReagentFetchResults } = this.freezer.get();

        if (barcodeKitReagentFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "barcodeKitReagentFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentsGet({type: "SeededBarcodeKitReagent"});
            },
        });
    }

    public async fetchStandaloneReagents(forceUpdate?: boolean) {
        const { standaloneReagentFetchResults } = this.freezer.get();

        if (standaloneReagentFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "standaloneReagentFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementStandaloneReagentsGet();
            },
        });
    }

    public async addReagent(toAdd: ReagentVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addTemperatureProgramResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementAddReagentPost({body: toAdd});
            },
        });
    }

    public async updateReagent(toUpdate: ReagentVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateTemperatureProgramResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementUpdateReagentPost({body: toUpdate});
            },
        });
    }

    public async fetchPools(activeOnly: boolean, forceUpdate?: boolean) {
        const { poolFetchResults } = this.freezer.get();

        if (poolFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "poolFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentGroupsGet({type: "Pool", activeOnly: activeOnly});
            },
        });
    }

    public async fetchCustomKits(activeOnly: boolean, forceUpdate?: boolean) {
        const { customKitFetchResults } = this.freezer.get();

        if (customKitFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "customKitFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentGroupsGet({type: "CustomKit", activeOnly: activeOnly});
            },
        });
    }

    public async fetchCommercialKits(activeOnly: boolean, forceUpdate?: boolean) {
        const { commercialKitFetchResults } = this.freezer.get();

        if (commercialKitFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "commercialKitFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentGroupsGet({type: "CommercialKit", activeOnly: activeOnly});
            },
        });
    }

    public async fetchMinIONBarcodeKits(activeOnly: boolean, forceUpdate?: boolean) {
        const { minIONBarcodeKitFetchResults } = this.freezer.get();

        if (minIONBarcodeKitFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "minIONBarcodeKitFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentGroupsGet({type: "MinIONBarcodeKit", activeOnly: activeOnly});
            },
        });
    }

    public async fetchIlluminaBarcodeKits(activeOnly: boolean, forceUpdate?: boolean) {
        const { illuminaBarcodeKitFetchResults } = this.freezer.get();

        if (illuminaBarcodeKitFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "illuminaBarcodeKitFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentGroupsGet({type: "IlluminaNexteraBarcodeKit", activeOnly: activeOnly});
            },
        });
    }

    public async fetchIlluminaDualAdapterBarcodeKits(activeOnly: boolean, forceUpdate?: boolean) {
        const { illuminaDualAdapterBarcodeKitFetchResults } = this.freezer.get();

        if (illuminaDualAdapterBarcodeKitFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "illuminaDualAdapterBarcodeKitFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementReagentGroupsGet({type: "IlluminaDualAdapterBarcodeKit", activeOnly: activeOnly});
            },
        });
    }

    public async addReagentGroup(toAdd: ReagentGroupVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addReagentGroupResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementAddReagentGroupPost({body: toAdd});
            },
        });
    }

    public async updateReagentGroup(toUpdate: ReagentGroupVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updatePoolResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AssetManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AssetManagementUpdateReagentGroupPost({body: toUpdate});
            },
        });
    }

}

export const AssetService = new AssetFreezerService();
export type IAssetServiceInjectedProps = ReturnType<AssetFreezerService["getPropsForInjection"]>;
