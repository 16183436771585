import {WorkflowRunExternalFreezerService} from "./WorkflowRunExternalFreezerService";
import {WorkflowRunInternalFreezerService} from "./WorkflowRunInternalFreezerService";
import {IWorkflowRunState, initialState, InjectedPropName} from "./IWorkflowRunState";

import {
    FreezerService,
} from "$Imports/Imports";

export class WorkflowRunFreezerService extends FreezerService<IWorkflowRunState, typeof InjectedPropName> {
    constructor() {
            super(
                initialState, InjectedPropName
            );
        }

}

export interface WorkflowRunFreezerService extends WorkflowRunInternalFreezerService, WorkflowRunExternalFreezerService  { }

combineFreezerServices(WorkflowRunFreezerService, [WorkflowRunInternalFreezerService, WorkflowRunExternalFreezerService]);

function combineFreezerServices(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            Object.defineProperty(derivedCtor.prototype, name, Object.getOwnPropertyDescriptor(baseCtor.prototype, name) as any);
        });
    });
}

export const WorkflowRunService = new WorkflowRunFreezerService();
export type IWorkflowRunServiceInjectedProps = ReturnType<WorkflowRunFreezerService['getPropsForInjection']>

