import { ReagentGroupLotVM, ReagentGroupVM, ReagentLotVM, ReagentVM } from "$Generated/api";
import { AdvanceTextField, DataLoadingDisplay, DataTable, IDataTableColumn, ProcessAdminRole } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Dialog, TextField, MenuItem, Checkbox, Button, DialogActions, Chip, Link, FormControlLabel } from "@material-ui/core";

import {
    HorizontalSplitIcon,
    BlockIcon,
    KeyboardDatePicker
} from "$Imports/MaterialUIComponents";
import { IUserContext } from "$Utilities/Security/IUserContext";
import { LotNumberChangeLog } from "./LotNumberChangeLog";

import {
    AssetService,
    IAssetServiceInjectedProps
} from "$State/AssetFreezerService";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
    table: string;
} = require("../LabAssets.scss");

interface IEditCommercialKitBaseProps {
    handleClose: () => void,
    open: boolean,
    newReagentGroup: boolean,
    initialReagentGroup: ReagentGroupVM | undefined,
    handleSave: (reagentGroup: ReagentGroupVM) => void
    user: IUserContext | null;
}

interface IEditCommercialKitState {
    reagentGroupToEdit?: ReagentGroupVM;
    lotNumberEntry: string;
    expirationEntry: Date;
    lotEntryOpen: boolean;
    lastLotId: number;
    lastReagentId: number;
    lotNumberLogOpen: boolean;
    manageComponentsOpen: boolean;
    addExistingReagentOpen: boolean;
    addNewReagentOpen: boolean;
    newReagentName: string;
    newReagentDescription: string;
    currentReagents?: ReagentVM[];
}

type IEditCommercialKitProps = IEditCommercialKitBaseProps & IAssetServiceInjectedProps;

export class _EditCommercialKit extends React.Component<IEditCommercialKitProps, IEditCommercialKitState> {

    private readonly columns: Array<IDataTableColumn<ReagentVM>> = [
        {
            columnName: "reagent-in-group",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={d.Id}
                                    checked={_.find(this.state.currentReagents, r => r.Id === d.Id) !== undefined}
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onChange={() => this.updateChecked(d)}
                                    name={d.Name}
                                />
                            }
                            label={d.Name}
                        />
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ]

    state: IEditCommercialKitState = {
        lotNumberEntry: "",
        lotEntryOpen: false,
        lastLotId: 0,
        lastReagentId: 0,
        lotNumberLogOpen: false,
        manageComponentsOpen: false,
        addExistingReagentOpen: false,
        newReagentDescription: "",
        newReagentName: "",
        addNewReagentOpen: false,
        expirationEntry: new Date(Date.now())
    }

    async componentDidUpdate(prevProps: IEditCommercialKitBaseProps) {
        if (this.props.initialReagentGroup && (prevProps.initialReagentGroup === undefined || (prevProps.initialReagentGroup && this.props.initialReagentGroup.Id !== prevProps.initialReagentGroup.Id))) {
            await this.props.assetService.fetchStandaloneReagents(true);
            let reagentGroup = _.cloneDeep(this.props.initialReagentGroup);
            if (this.props.newReagentGroup) {
                reagentGroup.CreatedBy = this.props.user ? this.props.user.preferred_username : "";
            }
            this.setState({ reagentGroupToEdit: reagentGroup });
        }
    }

    render() {
        let standaloneData = this.props.assetService.freezer.get().standaloneReagentFetchResults.data;
        if (this.state.reagentGroupToEdit && standaloneData) {
            let noneActive = _.find(this.state.reagentGroupToEdit.Lots, l => l.IsActive) === undefined;
            let lessThanTwoReagents = _.find(this.state.reagentGroupToEdit.Lots, l => l.ReagentLots.length < 2) !== undefined;
            let allLotsSet = _.every(this.state.reagentGroupToEdit.Lots, l => l.ReagentLots.length === this.state.reagentGroupToEdit?.Lots[0].ReagentLots.length);
            let allUnused = _.every(this.state.reagentGroupToEdit.Lots, l => l.UsedInRun == "NotUsed");

            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div>
                        {this.state.reagentGroupToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.props.open}
                                onClose={() => { this.props.handleClose() }}
                                PaperProps={{ style: { width: "1200px" } }}
                            >
                                <div className={styles.content} style={{ marginBottom: "50px" }}>
                                    <h2>Commercial Kit</h2>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive}
                                            InputLabelProps={{ shrink: true }}
                                            label={"Name"}
                                            value={this.state.reagentGroupToEdit?.Name}
                                            onDebouncedChange={(value) => { this.setName(value) }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive}
                                            InputLabelProps={{ shrink: true }}
                                            label={"Description"}
                                            value={this.state.reagentGroupToEdit?.Description}
                                            onDebouncedChange={(value) => { this.setDescription(value) }}
                                        />
                                    </div>
                                    <h3 style={noneActive ? { color: "red" } : { color: "" }}>
                                        Active Lot/Ref Numbers {noneActive ? " - At least one must be active!" : ""}
                                    </h3>
                                    {_.filter(this.state.reagentGroupToEdit.Lots, l => l.IsActive).map(l => <Chip
                                        label={l.LotNumber}
                                        key={l.Id}
                                        onDelete={l.UsedInRun === "UsedInPrevious" ? () => { this.disableLotNumber(l.Id); } : l.UsedInRun === "NotUsed" ? () => { this.deleteLotNumber(l.Id); } : undefined}
                                        style={{ margin: "5px" }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || l.UsedInRun === "UsedInActive"}
                                        deleteIcon={l.UsedInRun === "UsedInPrevious" ? <BlockIcon /> : undefined}
                                    />)}
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive}
                                            onClick={() => {
                                                this.setState({ lotEntryOpen: true, lotNumberEntry: "" });
                                            }}
                                        >
                                            Add New Lot Number
                                        </Button>
                                        <div style={{ marginTop: "auto", marginLeft: "10px" }} onClick={async () => {
                                            this.setState({ lotNumberLogOpen: true });
                                        }}>
                                            <Link>
                                                <u>View Lot Number Change Log</u>
                                            </Link>
                                        </div>
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive || this.state.reagentGroupToEdit.Lots.length === 0}
                                        onClick={() => {
                                            this.setState({ manageComponentsOpen: true });
                                        }}
                                    >
                                        Manage Components
                                    </Button>
                                    {(lessThanTwoReagents || !allLotsSet) &&
                                        <h3 style={{ color: "red" }}>
                                            Must have at least two reagents
                                        </h3>
                                    }
                                    {
                                        !this.props.newReagentGroup &&
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ paddingTop: "10px" }}>
                                                    Active:
                                                </div>
                                                <Checkbox disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} checked={this.state.reagentGroupToEdit?.IsActive}
                                                    onChange={(event) => {
                                                        this.setActive(event.target.checked);
                                                    }}
                                                />
                                            </div>
                                            {!this.state.reagentGroupToEdit?.IsActive && this.state.reagentGroupToEdit?.DeactivatedOn &&
                                                <div>
                                                    Deactivated On {moment(this.state.reagentGroupToEdit?.DeactivatedOn).format("L LT")}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || noneActive || lessThanTwoReagents || !allLotsSet || this.state.reagentGroupToEdit.Name === ""}
                                        onClick={() => {
                                            if (this.state.reagentGroupToEdit) {
                                                this.props.handleSave(this.state.reagentGroupToEdit);
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.props.handleClose() }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                        {this.state.reagentGroupToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.lotEntryOpen}
                                onClose={() => { this.setState({ lotEntryOpen: false, lotNumberEntry: "" }) }}
                                PaperProps={{ style: { width: "400px", height: "300px" } }}
                            >
                                <div className={styles.content}>
                                    <h3>Kit Name: {this.state.reagentGroupToEdit?.Name}</h3>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <AdvanceTextField
                                            label={"Lot/Reference Number"}
                                            autoFocus={true}
                                            value={this.state.lotNumberEntry}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onDebouncedChange={(value) => { this.setState({ lotNumberEntry: value }) }}
                                        />
                                        <HorizontalSplitIcon />
                                    </div>
                                    {_.find(this.state.reagentGroupToEdit.Lots, l => l.LotNumber === this.state.lotNumberEntry) !== undefined && <h3 style={{ color: "red" }}>
                                        Duplicate Lot Number Entered
                                    </h3>}
                                    <KeyboardDatePicker
                                        label="Expiration Date"
                                        value={this.state.expirationEntry}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onChange={this.onDateChanged}
                                        format="MM/DD/YY" />
                                    <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.lotNumberEntry === "" || _.find(this.state.reagentGroupToEdit.Lots, l => l.LotNumber === this.state.lotNumberEntry) !== undefined}
                                            onClick={() => {
                                                this.addLotNumber();
                                            }}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { this.setState({ lotEntryOpen: false, lotNumberEntry: "" }) }}
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        }
                        {this.state.reagentGroupToEdit &&
                            <LotNumberChangeLog
                                lotNumbers={this.state.reagentGroupToEdit.Lots}
                                name={this.state.reagentGroupToEdit.Name}
                                open={this.state.lotNumberLogOpen}
                                handleClose={() => { this.setState({ lotNumberLogOpen: false }); }}
                            />
                        }
                        {this.state.reagentGroupToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.manageComponentsOpen}
                                onClose={() => { this.setState({ manageComponentsOpen: false }) }}
                                PaperProps={{ style: { width: "600px", height: "500px" } }}
                            >
                                <div className={styles.content} style={{ marginBottom: "50px" }}>
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                        <h2>{this.state.reagentGroupToEdit.Name}</h2>
                                        <Button
                                            style={{ margin: "Auto", marginLeft: "20px" }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ addNewReagentOpen: true });
                                            }}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !allUnused}
                                        >
                                            Add New Reagent
                                        </Button>
                                    </div>
                                    {this.state.reagentGroupToEdit.Lots[0] && _.map(this.state.reagentGroupToEdit.Lots[0].ReagentLots, r => <Chip
                                        label={r.ReagentName}
                                        key={r.ReagentId}
                                        onDelete={() => { this.deleteReagent(r.ReagentId || "") }}
                                        style={{ margin: "5px" }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !allUnused}
                                    />)}
                                </div>
                                <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onClick={() => { this.setState({ manageComponentsOpen: false }) }}
                                    >
                                        Accept
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                        {standaloneData &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.addNewReagentOpen}
                                onClose={() => { this.setState({ addNewReagentOpen: false, newReagentDescription: "", newReagentName: "" }) }}
                                PaperProps={{ style: { width: "400px", overflow: "auto" } }}
                            >
                                <div className={styles.content}>
                                    <h3>Add Reagent</h3>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            InputLabelProps={{ shrink: true }}
                                            label={"Name"}
                                            value={this.state.newReagentName}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onDebouncedChange={(value) => { this.setState({ newReagentName: value }) }}
                                        />
                                    </div>
                                    {_.find(standaloneData.toJS(), r => r.Name === this.state.newReagentName) !== undefined && <h3 style={{ color: "red" }}>
                                        Names must be unique!
                                    </h3>}
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            InputLabelProps={{ shrink: true }}
                                            label={"Description"}
                                            value={this.state.newReagentDescription}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onDebouncedChange={(value) => { this.setState({ newReagentDescription: value }) }}
                                        />
                                    </div>

                                    <DialogActions style={{ bottom: "0px", right: "0px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { this.addNewReagent() }}
                                            disabled={_.find(standaloneData.toJS(), r => r.Name === this.state.newReagentName) !== undefined || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        >
                                            Accept
                                        </Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        }
                        {standaloneData &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.addExistingReagentOpen}
                                onClose={() => { this.setState({ addExistingReagentOpen: false }) }}
                                PaperProps={{ style: { width: "400px", height: "600px", overflow: "auto" } }}
                            >
                                <div className={styles.content}>
                                    <h3>Add Reagent</h3>
                                    <div className={styles.table}>
                                        <DataTable
                                            data={standaloneData.toJS()}
                                            columns={this.columns}
                                            stickyHeader={true}
                                        />
                                    </div>
                                    <DialogActions style={{ bottom: "0px", right: "0px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => { this.setState({ addExistingReagentOpen: false }) }}
                                        >
                                            Accept
                                        </Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        }
                    </div>
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <></>;
    }

    @bind
    private setName(value: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Name = value;
            this.setState({
                reagentGroupToEdit: newVal
            });
        }
    }

    @bind
    private setDescription(value: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Description = value;
            this.setState({
                reagentGroupToEdit: newVal
            });
        }
    }

    @bind
    private setActive(value: boolean) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.DeactivatedOn = value ? oldVal.DeactivatedOn : new Date(Date.now());
            newVal.IsActive = value;
            newVal.DeactivatedBy = value ? oldVal.DeactivatedBy : this.props.user ? this.props.user.preferred_username : "";
            this.setState({ reagentGroupToEdit: newVal });
        }
    }

    @bind
    private addLotNumber() {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);

            let existing = _.cloneDeep(_.first(oldVal.Lots)?.ReagentLots) || [];
            _.forEach(existing, rl => {
                rl.LotNumber = this.state.lotNumberEntry;
                rl.ExpirationDate = this.state.expirationEntry;
            });
            lots.push(
                {
                    CreatedBy: this.props.user ? this.props.user.preferred_username : "",
                    CreatedOn: new Date(Date.now()),
                    Id: (this.state.lastLotId + 1).toString(),
                    IsActive: true,
                    LotNumber: this.state.lotNumberEntry,
                    UsedInRun: "NotUsed",
                    IsNew: true,
                    ReagentLots: existing,
                    ReagentGroupId: oldVal.Id || "",
                    ExpirationDate: this.state.expirationEntry
                }
            )
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentGroupToEdit: newVal,
                lotEntryOpen: false,
                lotNumberEntry: "",
                lastLotId: this.state.lastLotId + 1
            });
        }
    }

    @bind
    private deleteLotNumber(id: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots).filter(l => l.Id !== id);
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentGroupToEdit: newVal,
            });
        }
    }

    @bind
    private disableLotNumber(id: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);
            let lotToDisable = _.find(lots, l => l.Id === id);
            if (lotToDisable) {

                lotToDisable.IsActive = false;
                lotToDisable.DeactivatedOn = new Date(Date.now());
                lotToDisable.DeactivatedBy = this.props.user ? this.props.user.preferred_username : "";

                let newVal = _.cloneDeep(oldVal);
                newVal.Lots = lots;
                this.setState({
                    reagentGroupToEdit: newVal,
                });
            }
        }
    }

    @bind
    private addNewReagent() {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);
            //add a new value to everything
            _.forEach(lots, lot => {
                lot.ReagentLots.push(
                    {
                        ReagentName: this.state.newReagentName,
                        ReagentDescription: this.state.newReagentDescription,
                        CreatedOn: new Date(Date.now()),
                        IsActive: true,
                        Id: "",
                        CreatedBy: this.props.user ? this.props.user.preferred_username : "",
                        IsNew: true,
                        ReagentId: this.state.lastReagentId.toString(),
                        UsedInRun: "NotUsed",
                        LotNumber: lot.LotNumber,
                        ExpirationDate: this.state.expirationEntry
                    }
                )
            });

            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentGroupToEdit: newVal,
                lastReagentId: this.state.lastReagentId + 1
            });
        }
        this.setState({ addNewReagentOpen: false, newReagentDescription: "", newReagentName: "" })
    }

    @bind
    private deleteReagent(reagentId: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);

            _.forEach(lots, lot => {
                lot.ReagentLots = _.filter(lot.ReagentLots, l => l.ReagentId !== reagentId);
            });
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentGroupToEdit: newVal,
            });

        }
        this.setState({ addExistingReagentOpen: false, newReagentDescription: "", newReagentName: "" })
    }

    @bind
    private updateChecked(reagent: ReagentVM) {
        let oldReagents = this.state.currentReagents;
        if (oldReagents) {
            let newReagents = _.cloneDeep(oldReagents);

            if (_.find(newReagents, r => r.Id === reagent.Id) !== undefined) {

                let reagentGroup = this.state.reagentGroupToEdit;
                if (reagentGroup) {
                    let newGroup = _.cloneDeep(reagentGroup);
                    _.forEach(newGroup.Lots, gl => {
                        gl.ReagentLots = _.filter(gl.ReagentLots, rl => rl.ReagentId !== reagent.Id);
                    });
                    this.setState({ reagentGroupToEdit: newGroup });
                }
                newReagents = _.filter(newReagents, s => s.Id !== reagent.Id);
            }
            else {
                newReagents.push(reagent);

                //add in the new reagent to all
                let reagentGroup = this.state.reagentGroupToEdit;
                if (reagentGroup) {
                    let newGroup = _.cloneDeep(reagentGroup);
                    let lots = _.cloneDeep(newGroup.Lots);

                    _.forEach(lots, lot => {
                        lot.ReagentLots.push(
                            {
                                ReagentName: reagent.Name,
                                ReagentDescription: reagent.Description || "",
                                CreatedOn: new Date(Date.now()),
                                IsActive: true,
                                Id: "",
                                CreatedBy: this.props.user ? this.props.user.preferred_username : "",
                                IsNew: false,
                                ReagentId: reagent.Id || "",
                                UsedInRun: "NotUsed",
                                LotNumber: lot.LotNumber,
                                ExpirationDate: this.state.expirationEntry
                            }
                        )
                    });
                    newGroup.Lots = lots;
                    this.setState({
                        reagentGroupToEdit: newGroup,
                    });
                }
                this.setState({ currentReagents: newReagents });
            }
        }
    }

    @bind
    private onDateChanged(date: MaterialUiPickersDate) {
        if (date) {
            this.setState({ expirationEntry: date.toDate() })
        }
    }

}

export const EditCommercialKit = AssetService.inject(_EditCommercialKit);