import {
    React,
    bind,
    _,
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link, Table, TableRow, TableCell } from "$Imports/MaterialUIComponents";

import {
    InfluenzaWorkflowId,
    SarsCov2WorkflowId,
    AnthraxWorkflowId,
    NBSWorkflowId,
} from "../../imports/CommonComponents";


import { IWorkflowsServiceInjectedProps, WorkflowsService } from "$State/WorkflowsFreezerService";

interface IWorkflowConfigHelperBaseProps {
    onChange: (e : any) => void;
    filteredWorkflows: string;
}

interface IReagentMixPageState {
    
}

type IWorkflowConfigHelperProps = IWorkflowConfigHelperBaseProps & IWorkflowsServiceInjectedProps;

export class _WorkflowConfigHelper extends React.Component<IWorkflowConfigHelperProps, IReagentMixPageState> {

    async componentDidMount() {
        await this.props.workflowsService.fetchWorkflows(true);
    }

    render() {

        let { workflowFetchResults } = this.props.workflowsService.getState();
        
        if (workflowFetchResults.data) {

            return <>
                <div>
                    <Table>
                        <tbody>
                            <tr>
                                <TableCell style={{width: 80}}><div style={{fontWeight: 'bold'}}>Workflow: </div></TableCell>
                                <TableCell>
                                    <TextField
                                        style={{width: 200}}
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        disabled={false}
                                        //className={styles.reagentInput}
                                        label="Filter by Workflow"
                                        id="workflowSelector"
                                        value={this.props.filteredWorkflows}
                                        onChange={this.props.onChange}
                                    >
                                        <MenuItem key={'None'} value={''}>All Workflows</MenuItem>
                                        {_.map(workflowFetchResults.data, (k, ind) => {
                                            return (<MenuItem key={ind} value={k.Id}>{getShortWorkflowName(k.Id)}</MenuItem>)
                                        })}
                                    </TextField>
                                </TableCell>
                            </tr>
                        </tbody>
                    </Table>
                
                </div>
            </>
            
           
        }
        return <></>
    }

}


export function getShortWorkflowName(guid: string | undefined): string{
    let rv = '';
    
    switch (guid){
        case InfluenzaWorkflowId:
            rv = 'Influenza';
            break;
        case SarsCov2WorkflowId:
            rv = 'SARS-CoV-2';
            break;
        case AnthraxWorkflowId:
            rv = 'B. anthracis';
            break;
        case NBSWorkflowId:
            rv = 'Newborn Screening';
            break;
    }
  
    return rv;
  }

export const WorkflowConfigHelper = WorkflowsService.inject(_WorkflowConfigHelper);