import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _
} from "$Imports/Imports";

import {
    WorkflowRunApiFactory,
    WetlabRunVM,
    GetWetlabRunsResponse,
    GetWetlabRunInstrumentInfoResponse,
    PlateVM,
    PlateApiFactory,
    WellContentVM,
    PoolApiFactory
} from "$Generated/api";
import { apiExceptionHandler } from "./ErrorFreezerService";

interface IRunsState {
    PageListResults: IAjaxState<GetWetlabRunsResponse>[];
    instrumentPageListResults: IAjaxState<GetWetlabRunInstrumentInfoResponse>[];
    plateDataResults: IAjaxState<PlateVM>;
    poolDataResults: IAjaxState<WellContentVM[]>;
    unlockRun: IAjaxState<any>;
    currentPage: number;
    totalPageCount: number;
    totalCount: number;
    runsWithMetadataPageListResults: IAjaxState<GetWetlabRunsResponse>[];
}

const InjectedPropName = "runsService";
const pageSize = 10;

class RunsFreezerService extends FreezerService<IRunsState, typeof InjectedPropName> {
    constructor() {
        super({
            PageListResults: [managedAjaxUtil.createInitialState()],
            instrumentPageListResults: [managedAjaxUtil.createInitialState()],
            plateDataResults: managedAjaxUtil.createInitialState(),
            poolDataResults: managedAjaxUtil.createInitialState(),
            unlockRun: managedAjaxUtil.createInitialState(),
            currentPage: 0,
            totalPageCount: 0,
            totalCount: 0,
            runsWithMetadataPageListResults: [managedAjaxUtil.createInitialState()],
        }, InjectedPropName);
    }

    public get totalCount(): number {
        return this.freezer.get().totalCount;
    }

    public get pageSize(): number {
        return pageSize;
    }

    public async fetchWorkflowRuns(forceUpdate: boolean = false) {
        
        if(forceUpdate === true)
        {
            //Reset pagination state.
            this.freezer.get().set({PageListResults: [managedAjaxUtil.createInitialState()], currentPage: 0, totalPageCount: 0, totalCount: 0});
        }
        
        const { PageListResults, currentPage, totalPageCount } = this.freezer.get();
        
        if (PageListResults[currentPage].hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            getAjaxState: (options) => {
                return this.freezer.get().PageListResults[this.freezer.get().currentPage].toJS();
            },
            setAjaxState: (options, newStatus) => {
                const workflowRunFetchResults = this.freezer.get().PageListResults.toJS();
                workflowRunFetchResults[this.freezer.get().currentPage] = newStatus;
                this.freezer.get().set({ PageListResults: workflowRunFetchResults });
            },
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunGetWorkflowRunsPageGet(param);
            },
            onOk: (data: GetWetlabRunsResponse) => {
                const workflowRunFetchResults = this.freezer.get().PageListResults.toJS();
                workflowRunFetchResults[this.freezer.get().currentPage].data = data;
                this.freezer.get().set({ PageListResults: workflowRunFetchResults });
            },
            onError: apiExceptionHandler,
            params:
            {
                page: currentPage,
                pageSize: pageSize
            }
        });
        let pageList = this.freezer.get().PageListResults.toJS();
        let initialResults = pageList[0];

        if (initialResults.hasFetched && initialResults.data && initialResults.data.TotalPageCount && totalPageCount === 0) {
            for (var x = 1; x < initialResults.data.TotalPageCount; x++) {
                pageList.push(managedAjaxUtil.createInitialState());
            }
            this.freezer.get().set({ PageListResults: pageList, totalPageCount: initialResults.data.TotalPageCount, totalCount: initialResults.data.TotalRunCount });
        }

    }

    public async fetchWorkflowRunsWithMetadata(forceUpdate: boolean = false) {
        
        if(forceUpdate === true)
        {
            //Reset pagination state.
            this.freezer.get().set({runsWithMetadataPageListResults: [managedAjaxUtil.createInitialState()], currentPage: 0, totalPageCount: 0, totalCount: 0});
        }
        
        const { runsWithMetadataPageListResults, currentPage, totalPageCount } = this.freezer.get();
        
        if (runsWithMetadataPageListResults[currentPage].hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            getAjaxState: (options) => {
                return this.freezer.get().runsWithMetadataPageListResults[this.freezer.get().currentPage].toJS();
            },
            setAjaxState: (options, newStatus) => {
                const wetlabRunFetchResults = this.freezer.get().runsWithMetadataPageListResults.toJS();
                wetlabRunFetchResults[this.freezer.get().currentPage] = newStatus;
                this.freezer.get().set({ runsWithMetadataPageListResults: wetlabRunFetchResults });
            },
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet(param);
            },
            onOk: (data: GetWetlabRunsResponse) => {
                const wetlabRunFetchResults = this.freezer.get().runsWithMetadataPageListResults.toJS();
                wetlabRunFetchResults[this.freezer.get().currentPage].data = data;
                this.freezer.get().set({ runsWithMetadataPageListResults: wetlabRunFetchResults });
            },
            onError: apiExceptionHandler,
            params:
            {
                page: currentPage,
                pageSize: pageSize
            }
        });
        let pageList = this.freezer.get().runsWithMetadataPageListResults.toJS();
        let initialResults = pageList[0];

        if (initialResults.hasFetched && initialResults.data && initialResults.data.TotalPageCount && totalPageCount === 0) {
            for (var x = 1; x < initialResults.data.TotalPageCount; x++) {
                pageList.push(managedAjaxUtil.createInitialState());
            }
            this.freezer.get().set({ runsWithMetadataPageListResults: pageList, totalPageCount: initialResults.data.TotalPageCount, totalCount: initialResults.data.TotalRunCount });
        }

    }

    public async fetchWorkflowInstruments(forceUpdate: boolean = false) {
        const { instrumentPageListResults, currentPage, totalPageCount } = this.freezer.get();

        if (instrumentPageListResults[currentPage].hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            getAjaxState: (options) => {
                return this.freezer.get().instrumentPageListResults[this.freezer.get().currentPage].toJS();
            },
            setAjaxState: (options, newStatus) => {
                const workflowRunInstrumentFetchResults = this.freezer.get().instrumentPageListResults.toJS();
                workflowRunInstrumentFetchResults[this.freezer.get().currentPage] = newStatus;
                this.freezer.get().set({ instrumentPageListResults: workflowRunInstrumentFetchResults });
            },
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet(param);
            },
            onOk: (data: GetWetlabRunInstrumentInfoResponse) => {
                const workflowRunInstrumentFetchResults = this.freezer.get().instrumentPageListResults.toJS();
                workflowRunInstrumentFetchResults[this.freezer.get().currentPage].data = data;
                this.freezer.get().set({ instrumentPageListResults: workflowRunInstrumentFetchResults });
            },
            onError: apiExceptionHandler,
            params:
            {
                page: currentPage,
                pageSize: pageSize
            }
        });
        let pageList = this.freezer.get().instrumentPageListResults.toJS();
        let initialResults = pageList[0];

        if (initialResults.hasFetched && initialResults.data && initialResults.data.TotalPageCount && totalPageCount === 0) {
            for (var x = 1; x < initialResults.data.TotalPageCount; x++) {
                pageList.push(managedAjaxUtil.createInitialState());
            }
            this.freezer.get().set({ instrumentPageListResults: pageList, totalPageCount: initialResults.data.TotalPageCount, totalCount: initialResults.data.TotalRunCount });
        }

    }

    public async setPageNumber(page: number) {
        if (page >= 0) {
            this.freezer.get().set({ currentPage: page });
            let state = this.getState().PageListResults[page];
            if (!state.hasFetched) {
                await this.fetchWorkflowRuns();
            }
        }
    }

    public async unlockRun(workflowRunId: string) {
            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: 'unlockRun',
                onExecute: (apiOptions, param, options) => {
                    let factory = WorkflowRunApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1WorkflowRunUnlockRunPost({ workflowRunId: workflowRunId });
                },
                onError: apiExceptionHandler
            });
    }

    public async getPlate(plateId: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'plateDataResults',
            onExecute: (apiOptions, param, options) => {
                let factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PlateIdGet({id: plateId});
            },
            onError: apiExceptionHandler
        });
    }

    public async getPoolContents(poolId: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'poolDataResults',
            onExecute: (apiOptions, param, options) => {
                let factory = PoolApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PoolPoolContentsIdGet({id: poolId});
            },
            onError: apiExceptionHandler
        });
    }
}

export const RunsService = new RunsFreezerService();
export type IRunsServiceInjectedProps = ReturnType<RunsFreezerService["getPropsForInjection"]>;
