import {
    React,
    _,
    bind,
} from "$Imports/Imports";

import {
    ComponentSetVM,
    ComponentSetItemVM,
    ComponentSetFooterVM,
    ComponentSetItemInstanceVM,
    WorkflowReagentsVM,
    ReagentLotVM,
    ReagentGroupLotVM,
    WorkflowVM
} from "$Generated/api";

import {
    AdvanceTextField, DataLoadingDisplay
} from "$Imports/CommonComponents";

import {
    DeleteIcon,
    HorizontalSplitIcon, IconButton,
    MenuItem,
    TextField
} from "$Imports/MaterialUIComponents";

import {
    WorkflowsService,
    IWorkflowsServiceInjectedProps
} from "$State/WorkflowsFreezerService";

export interface IMasterMixControlProps {
    masterMix: ComponentSetVM,
    sampleCount: number,
    updateMasterMixReagents: (reagents: ComponentSetItemInstanceVM[], index: number) => void;
    disabled?: boolean;
    hideHeaders?: boolean;
    ignoreFocus?: boolean;
    overrideTitle?: string;
    deleteMixStep?: (index: number) => void;
    deleteFooterStep?: (index: number) => void;
    lotOptions: WorkflowReagentsVM;
    setCanMoveState: (canMove: boolean) => void;
    index: number;
    workFlowType?: string;
    sampleHeaderOverride?: string;
}

export interface IMasterMixControlState {
    showLotNumberError: boolean;
    selectedLotNumber: ILotNumberEntry[];
    selectedLotNumberManualEntry: ILotNumberEntry[];
    selectedReagentLotsDictionary: { [index: string]: { lot: (ReagentLotVM | ReagentGroupLotVM), kitId: string } };
    workflow?: WorkflowVM;
}

export interface ILotNumberEntry {
    lotNumber: string;
    ordinal: number;
    itemInstanceId: string;
}

const styles: {
    cell: string;
    cellTopDivider: string;
    lotNumberEntry: string;
    table: string;
    selectLotNumber: string;
} = require("./MasterMixControl.scss");

import * as s from "underscore.string";
import { first } from "lodash";
import { InputAdornment, Step } from "@material-ui/core";
import { GetWorkflowType } from "../../WorkflowStep/WorkflowStep";
import {IWorkflowRunServiceInjectedProps, WorkflowRunFreezerService, WorkflowRunService} from "$State/WorkflowRun/WorkflowRunFreezerService";


export function missingData(masterMix: ComponentSetVM): boolean {
    let missingMasterMixData = _.find(masterMix.ComponentSetInstances, setInstance => _.find(setInstance.ComponentSetItemInstances, item => s.isBlank(item.LotNumber || "")));
    return (missingMasterMixData !== undefined);
}

export class MasterMixControl extends React.PureComponent<IMasterMixControlProps, IMasterMixControlState> {

    state: IMasterMixControlState = {
        showLotNumberError: false,
        selectedLotNumber: [],
        selectedLotNumberManualEntry: [],
        selectedReagentLotsDictionary: {}
    }

    @bind
    private lotNumberChanged() {
        if (this.props.masterMix.ComponentSetInstances) {

            let kitId = "";
            let canMove = true;
            let currentWorkflowRun = WorkflowRunService.currentWorkflowRun?.WorkflowId;

            let updatedModels = _.sortBy(this.state.selectedLotNumber, l => l.ordinal).map(l => {
                let newVal = l.lotNumber;
                if (newVal == "Enter Manually") {
                    newVal = _.find(this.state.selectedLotNumberManualEntry, ml => ml.ordinal == l.ordinal)!.lotNumber;
                }
                let itemInstance = _.find(this.props.masterMix.ComponentSetInstances![0].ComponentSetItemInstances, r => r.Ordinal == l.ordinal);
                let newModel = _.cloneDeep(itemInstance);

                let validReagentGroup = _.find(this.props.lotOptions.ReagentGroups, rg => rg.Id === itemInstance!.ReagentId);
                let validReagent = _.find(this.props.lotOptions.Reagents, rg => rg.Id === itemInstance!.ReagentId);

                let currentReagent = _.find(this.props.lotOptions.Reagents, rg => rg.Id === itemInstance!.ReagentId);
                if (currentReagent) {
                    kitId = validReagent?.KitId || ""
                }

                if (validReagent) {
                    newModel!.LotNumber = newVal;
                    newModel!.ReagentLotId = _.find(validReagent.Lots, lo => lo.LotNumber === newVal)?.Id;
                }
                else if (validReagentGroup) {
                    newModel!.LotNumber = newVal;
                    newModel!.ReagentLotId = _.find(validReagentGroup.Lots, lo => lo.LotNumber === newVal)?.Id;
                }
                else {
                    canMove = false;
                }
                //Kit validation
                if (kitId !== "") {
                    let reagent = _.find(this.props.lotOptions.Reagents, rg => rg.Id === itemInstance!.ReagentId && _.find(rg.Lots, lo => lo.LotNumber === newVal) !== undefined);
                    if (reagent && reagent.KitId === kitId) {
                        newModel!.LotNumber = newVal;
                        newModel!.ReagentLotId = _.find(reagent.Lots, lo => lo.LotNumber === newVal)?.Id;
                    }
                }

                return newModel!;
            })
            if (!canMove) {
                this.setState({ showLotNumberError: true });
                this.props.setCanMoveState(false);
            }
            else {
                this.setState({ showLotNumberError: false });
                this.props.setCanMoveState(true);
            }
            if (updatedModels) {
                let newReagents = _.cloneDeep(this.state.selectedReagentLotsDictionary);
                _.forEach(updatedModels, k => {
                    //create new lot based on the new models
                    let newLot: ReagentLotVM | ReagentGroupLotVM = {
                        LotNumber: k.LotNumber!,
                        Id: "",
                        IsActive: true,
                        CreatedOn: new Date(Date.now()),
                        CreatedBy: "",
                        UsedInRun: "UsedInActive",
                        IsNew: true,
                        ReagentId: k.ReagentId,
                        ReagentName: "",
                        ReagentDescription: "",
                        ExpirationDate: new Date()
                    }

                    if (newLot && k.ReagentId && newLot.LotNumber !== "") {
                        newReagents[k.ReagentId] = { lot: newLot , kitId: ""};
                    }

                });

                if(newReagents) {
                    this.setState({ selectedReagentLotsDictionary: newReagents });
                }

                WorkflowsService.setWorkflowRunReagents(currentWorkflowRun || "", this.state.selectedReagentLotsDictionary);
                this.props.updateMasterMixReagents(updatedModels, this.props.index || 0);
            }
        }
    }



    componentDidMount() {
        let componentSetItemInstances = _.sortBy(this.props.masterMix!.ComponentSetInstances![0].ComponentSetItemInstances, item => item.Ordinal);
        let initialLotNumberSelection: string[] = [];
        let initialSelection: ILotNumberEntry[] = [];
        for (let i = 0; i < componentSetItemInstances.length; i++) {
            initialLotNumberSelection[i] = componentSetItemInstances[i].LotNumber!;
            initialSelection[i] = {
                lotNumber: componentSetItemInstances[i].LotNumber!,
                ordinal: componentSetItemInstances[i].Ordinal!,
                itemInstanceId: componentSetItemInstances[i].Id!
            }
        }
        this.setState({ selectedLotNumber: initialSelection, selectedLotNumberManualEntry: initialSelection});
    }

    render() {
        const { masterMix: masterMixes, sampleCount } = this.props;
        const masterMix = masterMixes;
        const showTotal = this.props.workFlowType !== "SarsCov2" && ((sampleCount > 1 || (masterMix.AdditionalAmountFactor !== undefined && masterMix.AdditionalAmountFactor !== 0 && masterMix.AdditionalAmountFactor > 0)) || GetWorkflowType() === "Anthrax");

        let total = 0;
        let key = 0;
        let firstField = true;

        let manualEntryDisabled = this.disableManualEntry();

        if (masterMix.ComponentSetInstances) {
            const reagentList = _.sortBy(masterMix.ComponentSetInstances[0].ComponentSetItemInstances, item => item.Ordinal).map((componentSetItemInstance, idx) => {
                total += componentSetItemInstance.TotalVolume || 0;
                key++;
                var setFocus = false;

                if (firstField && !this.props.ignoreFocus) {
                    setFocus = true;
                    firstField = false;
                }

                let reagentTotalVolume = 0;
                if (masterMix.AdditionalAmountFactorType === "PercentFactor") {
                    reagentTotalVolume = Number(((componentSetItemInstance.TotalVolume || 0) * this.props.sampleCount * (masterMix.AdditionalAmountFactor ? 1 + (masterMix.AdditionalAmountFactor / 100) : 1)).toPrecision(3));
                } else if (masterMix.AdditionalAmountFactorType === "NumberOfSamples") {
                    reagentTotalVolume = Number(((componentSetItemInstance.TotalVolume || 0) * (this.props.sampleCount + (masterMix.AdditionalAmountFactor ? masterMix.AdditionalAmountFactor : 0))).toPrecision(3));
                }

                return <tr key={(componentSetItemInstance.ReagentName || "") + key}>
                    <td className={styles.cell}>{componentSetItemInstance.ReagentName}</td>
                    {masterMix.ComponentSetType !== "SimplifiedReagentList" && <td className={styles.cell}>{Number(componentSetItemInstance.TotalVolume?.toPrecision(3))}</td>}
                    {showTotal && masterMix.ComponentSetType !== "SimplifiedReagentList" && <td className={styles.cell}>{reagentTotalVolume}</td>}
                    <td className={styles.cell}>
                        {this.state.selectedLotNumber[idx] &&
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                <div>
                                    <TextField
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        disabled={true} //Disable Per LL-2840 for now //this.props.disabled || componentSetItemInstance.Locked}
                                        label="Select Lot Number"
                                        id={"lotNumberSelect" + idx}
                                        value={this.state.selectedLotNumber[idx].lotNumber || ""}
                                        inputProps={{ id: "lotSelect" + idx }}
                                        className={styles.selectLotNumber}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end' style={{ marginRight: "20px" }}>
                                                    <HorizontalSplitIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(event) => {
                                            let selectedLots = _.cloneDeep(this.state.selectedLotNumber);
                                            selectedLots[idx].lotNumber = event.target.value;
                                            this.setState({selectedLotNumber: selectedLots}, () => {this.lotNumberChanged()});
                                        }}
                                    >
                                        {
                                            _.filter(this.props.lotOptions.Reagents, rg => rg.Id === componentSetItemInstance.ReagentId)
                                                .map(reagent => {
                                                    return reagent.Lots
                                                    .map(lot => {
                                                        if ((this.state.selectedLotNumber[idx].lotNumber === lot.LotNumber) || (this.state.selectedLotNumber[idx].lotNumber === "")) { //this disables the lot number changing inside of the workflow step as per LL-2840 and AN-338
                                                            return <MenuItem key={lot.LotNumber} value={lot.LotNumber}>{lot.LotNumber}</MenuItem>
                                                        }
                                                    })
                                                })
                                        }
                                        {
                                            _.filter(this.props.lotOptions.ReagentGroups, rg => rg.Id === componentSetItemInstance.ReagentId)
                                                .map(reagent => {
                                                    return reagent.Lots
                                                        .map(lot => {
                                                            if ((this.state.selectedLotNumber[idx].lotNumber === lot.LotNumber)  || (this.state.selectedLotNumber[idx].lotNumber === "")) { //this disables the lot number changing inside of the workflow step as per LL-2840 and AN-338
                                                                return <MenuItem key={lot.LotNumber} value={lot.LotNumber}>{lot.LotNumber}</MenuItem>
                                                            }
                                                        })
                                                })
                                        }
                                            <MenuItem key={"Enter Manually"} value={"Enter Manually"} disabled={manualEntryDisabled}>Enter Manually</MenuItem>

                                    </TextField>
                                </div>
                                <div style={{ display: (this.state.selectedLotNumber[idx] && this.state.selectedLotNumber[idx].lotNumber == "Enter Manually") ? 'block' : 'none' }}>
                                    <AdvanceTextField
                                        autoFocus={setFocus}
                                        disabled={this.props.disabled || componentSetItemInstance.Locked}
                                        value={this.state.selectedLotNumberManualEntry[idx].lotNumber}
                                        className={styles.lotNumberEntry}
                                        onDebouncedChange={
                                            (value) => {
                                                let selectedLotsManualEntry = _.cloneDeep(this.state.selectedLotNumberManualEntry);
                                                selectedLotsManualEntry[idx].lotNumber = value;
                                                this.setState({selectedLotNumberManualEntry: selectedLotsManualEntry}, () => {this.lotNumberChanged()});
                                            }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <HorizontalSplitIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </td>
                    {this.props.deleteMixStep && <td>
                        <IconButton
                            onClick={() => {
                                if (this.props.deleteMixStep) {
                                    this.props.deleteMixStep(idx);
                                }
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </td>}
                </tr>
            });

            const footerList = _.map(masterMix.ComponentSetFooters, (f, idx) => {
                return <tr key={f.Description}>
                    <td colSpan={this.props.deleteFooterStep ? 3 : 4} className={styles.cell}>{f.Description}</td>
                    {this.props.deleteFooterStep && <td>
                        <IconButton
                            onClick={() => {
                                if (this.props.deleteFooterStep) {
                                    this.props.deleteFooterStep(idx);
                                }
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </td>}
                </tr>
            });

            const additionalAmountFactor = masterMix.AdditionalAmountFactorType === "PercentFactor" ? masterMix.AdditionalAmountFactor + "%" : masterMix.AdditionalAmountFactor + " samples";

            return (
                <div>
                    {(!this.props.hideHeaders && <h2>{s.isBlank(this.props.overrideTitle || "") ? "Master Mix" : this.props.overrideTitle}</h2>)}
                    {(!this.props.hideHeaders && <h4>{"Sample Count: " + sampleCount}</h4>)}
                    {(!this.props.hideHeaders && <h4>{"Additional amount factor: " + additionalAmountFactor}</h4>)}
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <td colSpan={4}><b>{masterMix.DisplayHeader}</b></td>
                            </tr>
                            <tr>
                                <th className={styles.cell}>Reagent</th>
                                {masterMix.ComponentSetType !== "SimplifiedReagentList" && <th className={styles.cell}>{this.props.sampleHeaderOverride ? this.props.sampleHeaderOverride :masterMix.ReagentUsedByType === "SamplePool" ? "uL" : "uL Per Well"}</th>}
                                {showTotal && masterMix.ComponentSetType !== "SimplifiedReagentList" && <th className={styles.cell}>Total Volume</th>}
                                <th className={styles.cell}>Lot Number</th>
                                {this.props.deleteMixStep && <th></th>}
                            </tr>
                            {reagentList}
                            {showTotal && masterMix.ComponentSetType !== "SimplifiedReagentList" &&  <tr>
                                <td className={styles.cellTopDivider}>Total</td>
                                <td colSpan={3} className={styles.cellTopDivider}>{Number(total.toPrecision(3))}</td>
                            </tr>}
                            {footerList}
                        </tbody>
                    </table>
                    {this.state.showLotNumberError && <h3 style={{ color: "red" }}>Mix contains invalid lot numbers. Please add the numbers in the Assets tab.</h3>}
                </div>
            );
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private disableManualEntry() {
        const currentStep = WorkflowRunService.currentStep?.Name;
        if (currentStep === "DNA Extraction" || currentStep === "Fluorometric Quantification" || currentStep === "Gel Electrophoresis") {
            return  false;
        }
        return true;
    }
}
