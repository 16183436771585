import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _
} from "$Imports/Imports";

import {
    AuditManagementCategoryVM, AuditManagementApiFactory, AuditRecordApiFactory, DropdownOptionsVM, AuditQuerySummaryVM, ExportRecordsResponse
} from "$Generated/api";


interface IAuditRecordsState {
    auditSummaryResults: IAjaxState<AuditQuerySummaryVM>;
    dropdownOptionResults: IAjaxState<DropdownOptionsVM>;
    filteredModuleResults: IAjaxState<string[]>;
    filteredActionResults: IAjaxState<string[]>;
    filteredSampleResults: IAjaxState<string[]>;
    exportRecordsResults: IAjaxState<ExportRecordsResponse>;
}

const InjectedPropName = "auditRecordService";
const pageSize = 10;

class AuditRecordFreezerService extends FreezerService<IAuditRecordsState, typeof InjectedPropName> {
    constructor() {
        super({
            auditSummaryResults: managedAjaxUtil.createInitialState(),
            dropdownOptionResults: managedAjaxUtil.createInitialState(),
            filteredModuleResults: managedAjaxUtil.createInitialState(),
            filteredActionResults: managedAjaxUtil.createInitialState(),
            filteredSampleResults: managedAjaxUtil.createInitialState(),
            exportRecordsResults: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async fetchAuditRecordSummary(auditActions: string[], auditModules: string[], fromDate: string, toDate: string, userId: string, instruments: string[], reagents: string[], lotNumber: string, runId: string, sampleId: string, forceUpdate?: boolean) {
        const { auditSummaryResults } = this.freezer.get();

        if (auditSummaryResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "auditSummaryResults",
            params:
            {
                auditActions: auditActions,
                auditModules: auditModules,
                fromDate: fromDate,
                toDate: toDate,
                userIds: userId,
                instruments: instruments,
                reagents: reagents,
                lotNumbers: lotNumber,
                runIds: runId,
                sampleIds: sampleId
            },
            onExecute: (apiOptions, param, options) => {
                const factory = AuditRecordApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AuditRecordQueryAuditRecordsGet(param);
            },
        });
    }

    public async exportAuditRecords(auditActions: string[], auditModules: string[], fromDate: string, toDate: string, userId: string, instruments: string[], reagents: string[], lotNumber: string, runId: string, sampleId: string, forceUpdate?: boolean) {
        const { exportRecordsResults } = this.freezer.get();

        if (exportRecordsResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "exportRecordsResults",
            params:
            {
                auditActions: auditActions,
                auditModules: auditModules,
                fromDate: fromDate,
                toDate: toDate,
                userIds: userId,
                instruments: instruments,
                reagents: reagents,
                lotNumbers: lotNumber,
                runIds: runId,
                sampleIds: sampleId
            },
            onExecute: (apiOptions, param, options) => {
                const factory = AuditRecordApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AuditRecordExportRecordsGet(param);
            },
        });
    }

    public async fetchFilteredModules(auditActions: string[], auditModules: string[], fromDate: string, toDate: string, userId: string, instruments: string[], reagents: string[], lotNumber: string, runId: string, sampleId: string, forceUpdate?: boolean) {
        const { filteredModuleResults } = this.freezer.get();

        if (filteredModuleResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "filteredModuleResults",
            params:
            {
                auditActions: auditActions,
                auditModules: auditModules,
                fromDate: fromDate,
                toDate: toDate,
                userIds: userId,
                instruments: instruments,
                reagents: reagents,
                lotNumbers: lotNumber,
                runIds: runId,
                sampleIds: sampleId
            },
            onExecute: (apiOptions, param, options) => {
                const factory = AuditRecordApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AuditRecordGetFilteredModulesGet(param);
            },
        });
    }

    public async fetchFilteredActions(auditActions: string[], auditModules: string[], fromDate: string, toDate: string, userId: string, instruments: string[], reagents: string[], lotNumber: string, runId: string, sampleId: string, forceUpdate?: boolean) {
        const { filteredActionResults } = this.freezer.get();

        if (filteredActionResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "filteredActionResults",
            params:
            {
                auditActions: auditActions,
                auditModules: auditModules,
                fromDate: fromDate,
                toDate: toDate,
                userIds: userId,
                instruments: instruments,
                reagents: reagents,
                lotNumbers: lotNumber,
                runIds: runId,
                sampleIds: sampleId
            },
            onExecute: (apiOptions, param, options) => {
                const factory = AuditRecordApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AuditRecordGetFilteredActionsGet(param);
            },
        });
    }

    public async fetchFilteredSamples(auditActions: string[], auditModules: string[], fromDate: string, toDate: string, userId: string, instruments: string[], reagents: string[], lotNumber: string, runId: string, sampleId: string, forceUpdate?: boolean) {
        const { filteredSampleResults } = this.freezer.get();

        if (filteredSampleResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "filteredSampleResults",
            params:
            {
                auditActions: auditActions,
                auditModules: auditModules,
                fromDate: fromDate,
                toDate: toDate,
                userIds: userId,
                instruments: instruments,
                reagents: reagents,
                lotNumbers: lotNumber,
                runIds: runId,
                sampleIds: sampleId
            },
            onExecute: (apiOptions, param, options) => {
                const factory = AuditRecordApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AuditRecordGetFilteredSamplesGet(param);
            },
        });
    }

    public async getDropdownOptions() {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'dropdownOptionResults',
            onExecute(apiOptions, params, options) {
                let factory = AuditRecordApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AuditRecordDropdownOptionsGet()
            }
        });
    }
}

export const AuditRecordService = new AuditRecordFreezerService();
export type IAuditRecordServiceInjectedProps = ReturnType<AuditRecordFreezerService["getPropsForInjection"]>;
