import {
    NgsReportAnalysisRunNotesVM,
} from "$Generated/api";

import {
    bind,
    React,
    _,
} from "$Imports/Imports";
  
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

import
    * as s
    from "underscore.string";

const commonStyles: {
    table: string;
} = require("../../../css/common.scss");


const wetLabStyles: {
    analysisTableHeader : string;
    textEmphasis: string;
    tableContainer: string;
} = require("./WetLabReport.scss");

interface IWetLabAnalysisRunNotesProps {
    dataAnalysisRunNotes: NgsReportAnalysisRunNotesVM[] | undefined;
};

export class WetLabAnalysisRunNotes extends React.Component<IWetLabAnalysisRunNotesProps> {

    async componentDidMount(){

    }

    render() {

        // Table headers for Analysis Run Notes (LL-2798)
        var colHeadersAnalysisRunNotes = ['Step', 'Comments'];

        return (
            
            <div>
                    <h2>Analysis Run Notes</h2>
                    <section className={wetLabStyles.textEmphasis}>Relevant notes or instructions that document decisions made or key items to understand the analysis.</section>
                    
                    <TableContainer component={Paper} className={wetLabStyles.tableContainer} >
                    <Table className={commonStyles.table}>
                        <TableHead>
                            <TableRow>
                                {colHeadersAnalysisRunNotes.map((h, ind) => (
                                    <TableCell key={ind} style={{fontWeight: 'bold'}}>{h}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.props.dataAnalysisRunNotes?.map( (a, ind) => (
                                <TableRow key={ind}>
                                    <TableCell>{a.StepNumber}. {a.StepName}</TableCell>
                                    <TableCell>{a.Comments.length > 0 ? a.Comments.map( (c, inx) => (
                                            <section key={inx}>{this.formatDate(c.CommentTime)} {c.Remarks}</section>
                                    )) : "No comments"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                    
                </div>

        )
      
    }

    @bind
    formatDate(d : Date) : string
    {
        const dateInput = new Date(d);
        let rv : string = String(dateInput.getMonth() + 1).padStart(2, '0') + '/' + String(dateInput.getDate()).padStart(2, '0') + '/' + String(dateInput.getFullYear()).slice(-2) + ' ' + String(dateInput.getHours()).padStart(2, '0') + ':' + String(dateInput.getMinutes()).padStart(2, '0');
        return rv;
    }

}
