import { C3Chart, DataLoadingDisplay } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    C3
} from "$Imports/Imports";
import { Card } from "$Imports/MaterialUIComponents";


import { IDashboardServiceInjectedProps, DashboardService } from "$State/DashboardFreezerService";

interface ITestOrderStausBoardBaseProps {
    id: string;
    width: number;
}

const styles: {
    cardStyle: string;
    headerStyle: string;
} = require("./WidgetCommon.scss");

type TestOrderStatusBoardProps = IDashboardServiceInjectedProps & ITestOrderStausBoardBaseProps;

class _TestOrderStatusBoard extends React.Component<TestOrderStatusBoardProps> {

    async componentDidMount() {
        await this.props.dashboardService.fetchTestOrderStatusBoard();
    }

    render() {
        var data = this.props.dashboardService.getState().statusBoardFetchResults.data;

        if (data) {
            return <Card
                className={styles.cardStyle}
                style={{width: this.props.width + "%"}}
            >
                <h3 className={styles.headerStyle}>
                    Test Order Status Board
                </h3>
                <C3Chart
                    data={
                        {
                            type: "pie",
                            columns: [
                                ["In Progress Test Orders: " + data.InProgress, data.InProgress],
                                ["Remaining Test Orders: " + data.Remaining, data.Remaining],
                                ["Failed Test Orders: " + data.Failed, data.Failed]
                            ]
                        }}
                    id={this.props.id}
                    colors={["#688da7", "#68727b", "#d95d39"]}
                    pie={{
                        label: {
                            format: function (value: any, ratio: any, id: any) {
                                return ""; //We don't want to display anything as the label
                            }
                        }
                    }}
                    size={{ height: 250 }}
                    tooltip={{
                        format: {
                            value: function (x, index) { return ""; }
                        }
                    }}
                />
            </Card>;
        }
        return <DataLoadingDisplay />;
    }
}

export const TestOrderStatusBoard = DashboardService.inject(_TestOrderStatusBoard);