import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports";

import { Card, FormControlLabel, Checkbox, Button, Snackbar } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    DataTablePager
} from "../../imports/CommonComponents";

import { NavigationService } from "$State/NavigationFreezerService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
} = require("./LabAssets.scss");

interface ILabAssetsPageProps {
}

export class LabAssetsPage extends React.Component<ILabAssetsPageProps> {

    private readonly columns: Array<IDataTableColumn<{ asset: string, url: string }>> = [
        {
            columnName: "asset-name",
            columnFieldData: "asset",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Asset",
        },
        {
            columnName: "view-link",
            columnFieldData: (d) => <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    NavigationService.navigateTo("/" + d.url);
                }}
            >
                View
            </Button>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ]

    render() {

        var data: { asset: string, url: string }[] = [
            { asset: "Control Material", url: "ControlMaterial" },
            { asset: "Manipulation Types", url: "ManipulationTypes" },
            { asset: "Instruments", url: "Instruments" },
            { asset: "Temperature Management", url: "TemperatureManagement" },
            { asset: "Components", url: "Components" },

        ];
        return <div
            className={styles.mainContainer}
        >
            <Card
                className={styles.cardStyle}
            >
                <div className={styles.content}>
                    <h2>Lab Assets</h2>
                    <DataTable
                        data={data}
                        columns={this.columns}
                    />
                </div>
            </Card>
        </div>;
    }
}