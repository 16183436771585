import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";

import {
    Card,
    Checkbox,
    Dialog
} from "$Imports/MaterialUIComponents";
import { NoteVM, QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";
import { IDataTableColumn, DataTable, AdvanceTextField } from "$Imports/CommonComponents";
import { QCCheckResultsDialog } from "../QC/QCCheckResultsDialog";

export interface IShowQCControlProps {
    qcs: QCCheckInstanceVM[];
    open: boolean;
    close: () => void;
    showResults: boolean;
    results: QCCheckResultVM[];
    workflowName: string;
    stepName: string;
}

export interface IShowQCControlState {
    resultDetails?: QCCheckResultVM;
    open: boolean;
    detailsToDisplay: QCCheckInstanceVM;
    readOnlyMode: boolean;
    popupOpen: boolean;
}

const styles: {
    card: string;
    header: string;
    buttons: string;
    tableHeader: string;
    content: string;
} = require("./ShowQCControl.scss");

export class ShowQCControl extends React.PureComponent<IShowQCControlProps, IShowQCControlState> {

    state: IShowQCControlState = {
        open: false,
        detailsToDisplay: {
            CheckConfig: {
                Parameters: []
            },
            Description: "",
            Enabled: false,
            Id: "",
            QCAction: "",
            QCCategory: "",
            QCName: "",
            StepName: "",
            QCCheckType: ""
        },
        readOnlyMode: false,
        popupOpen: false
    }


    private readonly resultsColumns: Array<IDataTableColumn<QCCheckResultVM>> = [
        {
            columnName: "qc-name",
            columnFieldData: (d) => <div onClick={() => { this.setState({ open: true, resultDetails: d }); }}>
                <u>{d.QCCheckInstance.QCName}</u>
            </div>,

            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "QC Name",
            sortMethod: (d) => d.QCCheckInstance.QCName ?? ""
        },
        {
            columnName: "date",
            columnFieldData: (d) => (moment(d.Date).format("MM-DD-YY, h:mm a")),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Event Date",
            sortMethod: (d) => d.Date ?? ""
        },
        {
            columnName: "conditions",
            columnFieldData: (d) => <div>
                {_.map(d.QCCheckInstance.CheckConfig?.Parameters, p => {
                    return <div key={p.Id}>
                        {p.Display} : {p.Value}
                    </div>;
                })}
            </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Conditions",
        },
        {
            columnName: "measured-value",
            columnFieldData: "MeasuredValue",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Measurement Value",
        },
        {
            columnName: "result",
            columnFieldData: (d) => <div style={{ color: d.Pass ? "blue" : "red" }}>
                {d.Pass ? "Pass" : "Fail"}
            </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Result",
        },
        {
            columnName: "failure-action",
            columnFieldData: (d) => <div>
                {d.QCCheckInstance.QCAction}
            </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Failure Action",
        },
        {
            columnName: "action-status",
            columnFieldData: (d) => <div>
                {d.FailureActionStatus === 0 ? "Executed" : d.FailureActionStatus === 1 ? "Modified" : d.FailureActionStatus === 2 ? "Skipped" : ""}
            </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Action Status",
        },
        {
            columnName: "action-note",
            columnFieldData: "ActionNote",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Note",
        },
    ];

    private readonly qcColumns: Array<IDataTableColumn<QCCheckInstanceVM>> = [
        {
            columnName: "step-name",
            columnFieldData: "StepName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Step Name",
            sortMethod: (d) => d.StepName ?? ""
        },
        {
            columnName: "qc-name",
            columnFieldData: (d) =>
                <div onClick={() => { this.showQCDetails(d, true); }}>
                    <u>{d.QCName}</u>
                </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "QC Name",
            sortMethod: (d) => d.QCName ?? ""
        },
        {
            columnName: "QC Category",
            columnFieldData: "QCCategory",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "QC Category",
            sortMethod: (d) => d.QCCategory ?? ""
        },
        {
            columnName: "qc-failure-action",
            columnFieldData: "QCAction",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "QC Failure Action",
            sortMethod: (d) => d.QCAction ?? ""
        },

        {
            columnName: "qc-enabled",
            columnFieldData: (d) => <Checkbox
                key={d.QCName}
                checked={d.Enabled}
                disabled={true}
            />,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Enabled",
            sortMethod: (d) => d.Enabled ?? ""
        },
    ]

    render() {
        return (
            // TODO: Replace the first section of this Dialog control with the QCCheckResultsDialog control.
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{ style: { maxWidth: "1200px" } }}
            >
                <Card className={styles.card}>
                    {!this.state.open ? <h2>{this.props.showResults ? "QC Results" : "QC Checks"} - {this.props.workflowName} , {this.props.stepName}</h2> : <></>}
                    <div>
                        <div style={{ height: "600px", overflow: "auto" }}>
                            {this.state.open && this.state.resultDetails ?
                                <QCCheckResultsDialog
                                    stepName={this.state.resultDetails?.QCCheckInstance.StepName}
                                    resultDetails={this.state.resultDetails}
                                />
                                :
                                this.props.showResults ?
                                    <DataTable
                                        data={this.props.results}
                                        columns={this.resultsColumns}
                                        stickyHeader={true}
                                        defaultSortColumnName={"date"}
                                    /> :
                                    <DataTable
                                        data={this.props.qcs}
                                        columns={this.qcColumns}
                                        stickyHeader={true}
                                    />}
                        </div>
                    </div>
                </Card>
                <Dialog
                    open={this.state.popupOpen}
                    onClose={this.handleInfoClose}
                >
                    <div className={styles.content}>
                        <h1>
                            QC Check - {this.state.detailsToDisplay?.QCName}
                        </h1>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ paddingRight: "20px" }}>
                                        Description:
                                    </td>
                                    <td>
                                        {this.state.detailsToDisplay?.Description}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingRight: "20px" }}>
                                        Step:
                                    </td>
                                    <td>
                                        {this.state.detailsToDisplay?.StepName}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {
                            this.state.detailsToDisplay?.CheckConfig &&
                            this.state.detailsToDisplay?.CheckConfig.Parameters &&
                            <div>
                                <h2>
                                    QC Check Detail
                                </h2>
                                {_.map(this.state.detailsToDisplay?.CheckConfig.Parameters, p =>
                                    <div key={p.Id}>
                                        <AdvanceTextField
                                            disabled={this.state.readOnlyMode}
                                            InputLabelProps={{ shrink: true }}
                                            label={p.Display}
                                            value={p.Value}
                                        />
                                    </div>)}
                            </div>
                        }
                        <h2>
                            QC Check Failure Action
                        </h2>
                        <div>
                            {this.state.detailsToDisplay?.QCAction}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <h2>
                                QC Enabled
                            </h2>
                            <Checkbox
                                checked={this.state.detailsToDisplay?.Enabled}
                                disabled={this.state.readOnlyMode}
                            />
                        </div>
                    </div>
                </Dialog>
            </Dialog>
        );

    }

    @bind
    private handleClose() {
        this.setState({ open: false })
        this.props.close();
    }

    @bind
    private handleInfoClose() {
        this.setState({ popupOpen: false })
    }

    @bind
    private async showQCDetails(qc: QCCheckInstanceVM, readOnly: boolean) {
        this.setState({ detailsToDisplay: qc, readOnlyMode: readOnly, popupOpen: true });
    }
}