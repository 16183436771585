import {
    React,
    _
} from "$Imports/Imports";

import {
    ISecurityContext
} from "$Utilities/Security/ISecurityContext";

import {
    Home,
    AddCircleOutline,
    DataUsage,
    Dashboard,
    People,
    Settings,
    Search,
    DirectionsRun,
    Palette,
    Speed,
    Tune,
    PieChart,
    VerifiedUserIcon,
    PlaylistAddCheckIcon,
    HighQualityIcon,
    AcUnitIcon,
    ShareIcon,
    DescriptionIcon,
    AnnouncementIcon,
    Notes,
    StorageIcon,
    WorkIcon,
    ExtensionIcon,
    AssessmentIcon,
    BookmarksIcon,
    AssignmentTurnedInIcon,
    VpnLockIcon
} from "$Imports/MaterialUIIcons";

import { DemoWelcomePage, HomePage } from "$Pages/Home";
import { SampleManagementPage } from "$Pages/SampleManagement";
import { WorkflowsPage, WorkflowRunPage } from "$Pages/Workflows";
import { RunsPage } from "$Pages/Runs";
import { ResultsPage } from "$Pages/Results"
import { SamplesPage } from "$Pages/Samples";
import { PerformancePage } from "$Pages/Performance";
import { InstrumentationPage } from "$Pages/Instrumentation";
import { BioinformaticsAnalysisPage, ExistingPipelineRunsPage, NewPipelineRunPage, PipelineRunLayoutPage, PipelineSampleSelectionPage, ReleaseRunPage } from "$Pages/BioinformaticsPipeline";
import { WebAPIErrorPage } from "$Pages/Errors";
import { NoAccessPage } from "$Pages/NoAccess";
import { MinderJobStatusPage } from "$Pages/MinitMinderPages"
import { AuditManagementPage, AuditDisplayPage } from "$Pages/Audit";
import { ComponentsPage, ControlMaterialsPage, InstrumentsPage, LabAssetsPage, ManipulationTypesPage, TemperatureProgramsPage } from "$Pages/Assets";
import { WorkflowQCChecksPage, WorkflowsQCSummaryPage } from "$Pages/QC";
import { InstructionSetPage, ReagentListPage, ReagentMixPage, WorkflowComponentManagementPage, WorkflowStepComponentAssociationPage } from "$Pages/WorkflowConfig";
import { FileShareManagementPage, RemoteConnectivityPage, ReceivedDataExchangesPage, SentDataExchangesPage } from "$Pages/Sharing";
import { QCMetricsLayoutPage } from "$Pages/QCMetrics";
import { NGSQCReportsLayoutPage } from "$Pages/NGSQCReports";
import { SoftwareInformationPage, SystemSettingsPage } from "$Pages/SystemSettings";

import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { tsExpressionWithTypeArguments } from "@babel/types";
import { SettingsService } from "$State/SettingsFreezerService";
import { TurnedIn } from "@material-ui/icons";
import { MinderConnectionStatusPage } from "$Pages/MinitMinderPages/MinderConnectionStatusPage";
import { ProcessAdminRole } from "$Imports/CommonComponents";

interface IMatchProps<P> {
    match?: {
        params?: P;
    };
}

export type PageType = "Wetlab" | "Drylab" | "System" | "PCEOnly";

export interface INavigationItem {
    url?: string;
    redirectUrls?: string[];
    label: string;
    externalLink: boolean;
    enabled: boolean | ((navItem: INavigationItem, securityContext: ISecurityContext | null) => boolean);
    hasAccess?: boolean | ((navItem: INavigationItem, securityContext: ISecurityContext | null) => boolean);
    childNavigation?: INavigationItem[];
    icon?: React.ComponentType<SvgIconProps>;
    component?: React.ComponentClass | React.FunctionComponent;
    title?: string;
    isVisibleInNavMenu: boolean;
    pageType: PageType;
}


export const mainNavigation = () => {
    const isDemo = !!SettingsService.getState().demoEnvironmentResults.data;
    var array: INavigationItem[] = [
        /* Saving for future reference{
            redirectUrls: ["/"],
            url: "/tasks",
            label: "Tasks",
            externalLink: false,
            component: TaskViewPage,
            hasAccess: true,
            enabled: true,
            icon: Home,
          }, */
        {
            url: "/DemoDetails",
            label: "Demo Details",
            externalLink: false,
            component: DemoWelcomePage,
            hasAccess: () => {
                // Add Access Logic Test.
                return true;
            },
            enabled: true,
            icon: AnnouncementIcon,
            isVisibleInNavMenu: true,
            pageType: "Wetlab"
        },
        {
            url: "/launchpad",
            label: "Sequencing LaunchPad",
            externalLink: false,
            component: HomePage,
            hasAccess: () => {
                // Add Access Logic Test.
                return true;
            },
            enabled: true,
            icon: Home,
            pageType: "System",
            isVisibleInNavMenu: false

        },
        {
            url: "/datamanagement",
            label: "Data Management",
            externalLink: false,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: StorageIcon,
            pageType: "System",
            isVisibleInNavMenu: true,
            childNavigation: [
                {
                    url: "/samplemanagement",
                    label: "Sample Management",
                    externalLink: false,
                    component: SampleManagementPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "System",
                    isVisibleInNavMenu: true,
                },
                {
                    url: "/releaseRunData",
                    label: "Release Run Data",
                    externalLink: false,
                    component: ReleaseRunPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "PCEOnly",
                    isVisibleInNavMenu: true,
                }
            ]
        },
        {
            url: "/wetlabRuns",
            label: "Wet Lab Workflow Runs",
            externalLink: false,
            hasAccess: true,
            enabled: true,
            icon: WorkIcon,
            isVisibleInNavMenu: true,
            pageType: "Wetlab",
            childNavigation: [
                {
                    url: "/workflows",
                    label: "New Workflow Run",
                    externalLink: false,
                    component: WorkflowsPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    isVisibleInNavMenu: true,
                    pageType: "Wetlab",
                },
                {
                    url: "/runs",
                    label: "Existing Workflow Runs",
                    externalLink: false,
                    component: RunsPage,
                    hasAccess: true,
                    enabled: true,
                    pageType: "Wetlab",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/samples",
                    label: "Archived Sample Library",
                    externalLink: false,
                    component: SamplesPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "Wetlab",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/performance",
                    label: "Performance History",
                    externalLink: false,
                    component: PerformancePage,
                    hasAccess: true,
                    enabled: true,
                    pageType: "Wetlab",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/minderjobstatus",
                    label: "Job Status",
                    externalLink: false,
                    component: MinderJobStatusPage,
                    hasAccess: true,
                    enabled: true,
                    pageType: "Drylab",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/devicestatus",
                    label: "Device Status",
                    externalLink: false,
                    component: MinderConnectionStatusPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "Drylab",
                    isVisibleInNavMenu: true
                }
            ]
        },
        {
            url: "/workflow",
            label: "Workflow Configuration",
            externalLink: false,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: ExtensionIcon,
            pageType: "Wetlab",
            isVisibleInNavMenu: true,
            childNavigation: [
                {
                    url: "/workflowcomponentmanagement",
                    label: "Component Configurator",
                    externalLink: false,
                    component: WorkflowComponentManagementPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "Wetlab",
                    isVisibleInNavMenu: true
                }
            ]
        },
        {
            url: "/pipelineRun/:runNumber",
            label: "",
            externalLink: false,
            component: (props: React.PropsWithChildren<IMatchProps<{ runNumber: number }>>) => {
                const componentProps: { runNumber: number } = props.match && props.match.params ? props.match.params : { runNumber: 0 };
                return React.createElement(PipelineRunLayoutPage, componentProps);
            },
            enabled: true,
            pageType: "Drylab",
            isVisibleInNavMenu: false
        },
        {
            url: "/bioinformaticsanalysis",
            label: "Bioinformatics Analysis",
            externalLink: false,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: AssessmentIcon,
            pageType: "Drylab",
            isVisibleInNavMenu: true,
            childNavigation: [
                
                {
                    url: "/existingPipelineRun",
                    label: "Existing Pipeline Runs",
                    externalLink: false,
                    component: ExistingPipelineRunsPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "Drylab",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/newPipelineRun",
                    label: "New Pipeline Run",
                    externalLink: false,
                    component: NewPipelineRunPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "Drylab",
                    isVisibleInNavMenu: true
                },
            ]
        },
        {
            url: "/pipelineSampleSelection",
            label: "Pipeline Sample Selection",
            externalLink: false,
            component: PipelineSampleSelectionPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            pageType: "Drylab",
            isVisibleInNavMenu: false
        },
        {
            url: "/results/:selectedRun",
            label: "Results",
            externalLink: false,
            component: (props: React.PropsWithChildren<IMatchProps<{ selectedRun: string }>>) => {
                const componentProps: { selectedRun: string } = props.match && props.match.params ? props.match.params : { selectedRun: "" };
                return React.createElement(ResultsPage, componentProps);
            },
            hasAccess: true,
            enabled: true,
            icon: DescriptionIcon,
            pageType: "Wetlab",
            isVisibleInNavMenu: true
        },
        {
            url: "/labassets",
            label: "Lab Assets",
            externalLink: false,
            component: LabAssetsPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: BookmarksIcon,
            isVisibleInNavMenu: true,
            pageType: "Wetlab",
            childNavigation: [
                {
                    url: "/instrumentation",
                    label: "Instruments",
                    externalLink: false,
                    component: InstrumentationPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "Wetlab",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/Components",
                    label: "Component Inventory",
                    externalLink: false,
                    component: ComponentsPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "Wetlab",
                    isVisibleInNavMenu: true
                },
            ]
        },
        {
            url: "/audit",
            label: "Audit Hub",
            externalLink: false,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Show if demo or has admin access
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === ProcessAdminRole || role === "View Only"));
                    return hasRole !== undefined;
                }
                return false;
            },
            enabled: true,
            icon: AssignmentTurnedInIcon,
            isVisibleInNavMenu: true,
            pageType: "Wetlab",
            childNavigation: [
                {
                    url: "/auditManagement",
                    label: "Audit Management",
                    externalLink: false,
                    component: AuditManagementPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === ProcessAdminRole));
                            return hasRole !== undefined;
                        }
                        return false;
                    },
                    enabled: true,
                    pageType: "Wetlab",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/auditDisplay",
                    label: "Audit Records",
                    externalLink: false,
                    component: AuditDisplayPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === ProcessAdminRole || role === "View Only"));
                            return hasRole !== undefined;
                        }
                        return false;
                    },
                    enabled: true,
                    pageType: "Wetlab",
                    isVisibleInNavMenu: true
                }
            ]
        },
        {
            url: "/qc",
            label: "Quality Control",
            externalLink: false,
            component: WorkflowsQCSummaryPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            pageType: "Wetlab",
            icon: VerifiedUserIcon,
            isVisibleInNavMenu: true
        },
        //Ordering in this list matters, so put this entry first to catch any possible parameter usage
        {
            url: "/fileShareManagement/:defaultOpenDirectory",
            label: "File Sharing",
            externalLink: false,
            component: (props: React.PropsWithChildren<IMatchProps<{ defaultOpenDirectory: string }>>) => {
                const componentProps: { defaultOpenDirectory?: string } = props.match && props.match.params ? props.match.params : {};
                return React.createElement(FileShareManagementPage, componentProps);
            },
            enabled: true,
            pageType: "Wetlab",
            isVisibleInNavMenu: false
        },
        {
            url: "/collaborationengine",
            label: "Collaboration Engine",
            externalLink: false,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                return true;
            },
            enabled: true,
            icon: VpnLockIcon,
            isVisibleInNavMenu: true,
            pageType: "System",
            childNavigation: [
                {
                    url: "/fileShareManagement",
                    label: "File Sharing",
                    externalLink: false,
                    component: FileShareManagementPage,
                    enabled: true,
                    pageType: "System",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/receivedDataExchanges",
                    label: "Received Data Exchanges",
                    externalLink: false,
                    component: ReceivedDataExchangesPage,
                    enabled: true,
                    pageType: "System",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/sentDataExchanges",
                    label: "Sent Data Exchanges",
                    externalLink: false,
                    component: SentDataExchangesPage,
                    enabled: true,
                    pageType: "System",
                    isVisibleInNavMenu: true
                },
            ]
        },

        {
            url: "/systemSettings",
            label: "System Settings",
            externalLink: false,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: Settings,
            isVisibleInNavMenu: true,
            pageType: "System",
            childNavigation: [
                {
                    url: "/softwareInformation",
                    label: "Software Information",
                    component: SoftwareInformationPage,
                    externalLink: false,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "System",
                    isVisibleInNavMenu: true
                },
                {
                    url: "/systemSettings",
                    label: "Site Settings",
                    externalLink: false,
                    component: SystemSettingsPage,
                    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                        // Hide for view only user
                        if (securityContext && securityContext.realm_access) {
                            const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                            return hasRole === undefined;
                        }
                        return true;
                    },
                    enabled: true,
                    pageType: "System",
                    isVisibleInNavMenu: true
                }
            ]
        },
        {
            url: "/WorkflowRun/:runId/step/:stepInstanceId",
            label: "Workflow",
            externalLink: false,
            component: (props: React.PropsWithChildren<IMatchProps<{ runId: string, stepInstanceId?: string }>>) => {
                const componentProps: { runId: string, stepInstanceId?: string } = props.match && props.match.params ? props.match.params : { runId: "", stepInstanceId: "" };
                return React.createElement(WorkflowRunPage, componentProps);
            },
            hasAccess: true,
            enabled: true,
            icon: Dashboard,
            isVisibleInNavMenu: false,
            pageType: "Wetlab",
        },
        {
            url: "/ManipulationTypes",
            label: "Manipulation Types",
            externalLink: false,
            component: ManipulationTypesPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                return true;
            },
            enabled: true,
            icon: AcUnitIcon,
            isVisibleInNavMenu: false,
            pageType: "Wetlab",
        },
        {
            url: "/Instruments",
            label: "Instruments",
            externalLink: false,
            component: InstrumentsPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: AcUnitIcon,
            pageType: "Wetlab",
            isVisibleInNavMenu: false
        },
        {
            url: "/WorkflowQCChecks/:workflowId/name/:workflowName",
            label: "QC Checks",
            externalLink: false,
            component: (props: React.PropsWithChildren<IMatchProps<{ workflowId: string, workflowName: string }>>) => {
                const componentProps: { workflowId: string, workflowName: string } = props.match && props.match.params ? props.match.params : { workflowId: "", workflowName: "" };
                return React.createElement(WorkflowQCChecksPage, componentProps);
            },
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: HighQualityIcon,
            pageType: "Wetlab",
            isVisibleInNavMenu: false
        },
        {
            url: "/ReagentMixes",
            label: "Reagent Mixes",
            externalLink: false,
            component: ReagentMixPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: AcUnitIcon,
            pageType: "Wetlab",
            isVisibleInNavMenu: false
        },
        {
            url: "/ReagentLists",
            label: "Reagent Lists",
            externalLink: false,
            component: ReagentListPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: AcUnitIcon,
            pageType: "Wetlab",
            isVisibleInNavMenu: false
        },
        {
            url: "/InstructionSets",
            label: "Instruction Sets",
            externalLink: false,
            component: InstructionSetPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: AcUnitIcon,
            pageType: "Wetlab",
            isVisibleInNavMenu: false
        },
        {
            url: "/WorkflowStepComponentAssociation",
            label: "Workflow Step Component Association",
            externalLink: false,
            component: WorkflowStepComponentAssociationPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            icon: AcUnitIcon,
            pageType: "Wetlab",
            isVisibleInNavMenu: false
        },
        {
            url: "/qcmetrics",
            label: "QC Metrics",
            externalLink: false,
            component: QCMetricsLayoutPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            pageType: "Drylab",
            isVisibleInNavMenu: false
        },
        {
            url: "/ngsreports",
            label: "NGS Reports",
            externalLink: false,
            component: NGSQCReportsLayoutPage,
            hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
                // Hide for view only user
                if (securityContext && securityContext.realm_access) {
                    const hasRole = _.find(securityContext.realm_access.roles, role => (role === "View Only"));
                    return hasRole === undefined;
                }
                return true;
            },
            enabled: true,
            pageType: "Wetlab",
            isVisibleInNavMenu: false
        }
    ]
    return array;
};