import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"

import {
    IWorkflowScreenProps,
    GetCurrentStepOutputType,
    GetWorkflowType
} from "../WorkflowStep";

import {
    SampleInfoTable,
    IDataTableColumn,
    StepChangeControl,
    StepActionsControl,
    StandardPlate,
    QCUIComponent,
    GriddingNTCControlCount,
    GriddingSampleCount,
    GriddingPTCControlCount,
    GriddingGriddedMandatory,
    CollapsibleSection,
    DataLoadingDisplay,
} from "$Imports/CommonComponents";

import {
    WellContentVM,
    ControlVM,
    QCCheckInstanceVM,
    QCCheckResultVM
} from "$Generated/api";

import {
    Snackbar,
    Button,
    EditIcon,
    IconButton,
    DeleteIcon,
    FormControlLabel
} from "$Imports/MaterialUIComponents";
import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";
import { RadioGroup, Radio } from "@material-ui/core";

const styles: {
    tableHeader: string;
    list: string;
    controlInputLabel: string;
    table: string;
    clearButton: string;
    boldText: string;
    contentDiv: string;
    gridSequentialButton: string;
    uploadGriddedSamplesDiv: string;
    griddedSamplesInput: string;
    selectedColor: string;
    positionStringDiv: string;
    flexRow: string;
    bottomFullWidth: string;
} = require("./Gridding.scss");

const commonStyles: {
    mainDiv: string;
    footerDiv: string;
    flexRow: string;
    flexColumn: string;
} = require("./CommonStepStyles.scss");

export interface IGriddingState {
    controlType?: ControlVM;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    selectedSample?: WellContentVM;
    gridSequential: boolean;
    gridViaInterface: boolean;
    messageSnackbarOpen: boolean;
    messageContent: string;
}

export class GriddingScreen extends React.Component<IWorkflowScreenProps, IGriddingState> {

    private columns(): Array<IDataTableColumn<WellContentVM>> {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    cellProps: (d) => { return { style: this.state.selectedSample?.Sample?.SampleId === d.Sample?.SampleId ? { backgroundColor: "#ffa" } : {} } },
                    columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample ID",
                    sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
                },
                {
                    columnName: "sample-date-harvested",
                    cellProps: (d) => { return { style: this.state.selectedSample?.Sample?.SampleId === d.Sample?.SampleId ? { backgroundColor: "#ffa" } : {} } },
                    columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Date Harvested",
                    sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
                },
                {
                    columnName: "sample-specimen-source",
                    cellProps: (d) => { return { style: this.state.selectedSample?.Sample?.SampleId === d.Sample?.SampleId ? { backgroundColor: "#ffa" } : {} } },
                    columnFieldData: (d) => (d.Sample ? d.Sample.SpecimenSource : ""),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Specimen Source",
                    sortMethod: (d) => (d.Sample ? d.Sample.SpecimenSource : "") ?? ""
                },
                {
                    columnName: "sample-well-position",
                    cellProps: (d) => { return { style: this.state.selectedSample?.Sample?.SampleId === d.Sample?.SampleId ? { backgroundColor: "#ffa" } : {} } },
                    columnFieldData: (d) => {
                        const well = this.findSampleWell(d);
                        return (well ? ConvertPositionToString(d.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow) : "");
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Well Position",
                },
                {
                    columnName: "sample-select",
                    cellProps: (d) => { return { style: this.state.selectedSample?.Sample?.SampleId === d.Sample?.SampleId ? { backgroundColor: "#ffa" } : {} } },
                    columnFieldData: (d) => {
                        const well = this.findSampleWell(d);
                        return <IconButton
                            className={styles.controlInputLabel}
                            disabled={this.props.viewMode || !!well}
                            onClick={() => { this.setState({ selectedSample: d, controlType: undefined, gridSequential: false }) }}
                        >
                            <EditIcon />
                        </IconButton>;
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "",
                }

            ];
        }
        return [];
    }

    constructor(props: IWorkflowScreenProps) {
        super(props);

        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentWorkflowRun) {
        }
        this.state = {
            qcOpen: false,
            failedQcs: [],
            qcResults: [],
            gridSequential: false,
            gridViaInterface: true,
            messageContent: "",
            messageSnackbarOpen: false
        };
    }

    async componentDidMount() {
        await this.props.workflowRunService.fetchAssetsForStep(true);
        this.props.workflowRunService.resetControlsAsset();

        const { controlsPlate, controlsRack, workflowAssets } = this.props.workflowRunService.getState();
        const step = this.props.workflowRunService.currentStep;
        if (workflowAssets.data && controlsPlate && step) {
            var plate = _.find(workflowAssets.data.Plates, (p) => { return p.Name === step.OutputName });
            if (plate) {
                let clonedControlPlate = _.cloneDeep(controlsPlate);
                _.forEach(plate.WellContents, w => {
                    clonedControlPlate.WellContents.push(w);
                });
                this.props.workflowRunService.wellsPlate = clonedControlPlate;
            }
        }

        if (workflowAssets.data && controlsRack && step) {
            var rack = _.find(workflowAssets.data.Racks, (p) => { return p.Name === step.OutputName });
            if (rack) {
                let clonedControlRack = _.cloneDeep(controlsRack);
                _.forEach(rack.WellContents, w => {
                    clonedControlRack.WellContents.push(w);
                });
                this.props.workflowRunService.wellsRack = clonedControlRack;
            }
        }
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        if (currentWorkflowRun) {
            const sampleData = currentWorkflowRun.Samples;
            const wellContents = this.getWellContents();

            const sampleAsWellContent: WellContentVM[] = _.map(_.sortBy(sampleData, s => s.SampleId ), s => {
                var assetWellContent = _.find(wellContents, (wc) => (wc.Sample != undefined && wc.Sample.Id == s.Id));
                var wellContent =
                {
                    Id: s.Id,
                    Sample: s.toJS(),
                    WellPosition: s.WellPosition || (assetWellContent && assetWellContent.WellPosition) || 0,
                    RunCount: 1
                };
                return wellContent;
            });
            let selectedCount = 0;

            const inputs = _.filter(currentWorkflowRun.Controls, c => c.Name === "PTC" || c.Name === "NTC").map(c => {
                const currentlySelected = _.filter(wellContents, (w) => {
                    if (w.Control && w.Control.Name === c.Name) {
                        return true;
                    }
                    return false;
                });
                let positionString = "";

                _.forEach(currentlySelected, (w) => {
                    positionString += ConvertPositionToString(w.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow) + ", ";
                    selectedCount++;
                });

                return (<tr key={c.Id} className={this.state.controlType === c ? styles.selectedColor : ""}>
                    <td>
                        <div>
                            {c.Name + ": " + currentlySelected.length}
                        </div>
                    </td>
                    <td>
                        <div className={styles.positionStringDiv}>
                            {positionString}
                        </div>
                    </td>
                    <td>
                        <IconButton
                            className={styles.controlInputLabel}
                            disabled={this.props.viewMode || (currentStep && currentWorkflowRun && currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress")}
                            onClick={() => { this.handleControlClick(c) }}
                        >
                            <EditIcon />
                        </IconButton>
                    </td>
                </tr>);
            });

            if (currentWorkflowRun && currentStep) {
                const disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                const wellCount = currentWorkflowRun.AssetColCount * currentWorkflowRun.AssetRowCount;

                // Calculate the last possible well (LPW) where the first sample can be placed so the samples won't overflow.
                let nLastIndex = wellCount; // The disableAfterWell is a 1 based index, so nLastIndex is as well.
                if (this.state.gridSequential) {
                    nLastIndex = nLastIndex - sampleData.length + 1;
                    let lWellPositions: number[] = [];
                    // Locate the positions of any NTC or PTC already placed.
                    _.forEach(wellContents, (wc) => {
                        if (wc.ContentType == "Control")
                            lWellPositions.push(wc.WellPosition);

                    })
                    // Sort by descending. The LPW shifts to the left for every control that the samples would overlap.
                    lWellPositions = lWellPositions.sort((a, b) => b - a);
                    _.forEach(lWellPositions, (wp) => {
                        if (nLastIndex <= wp + 1)
                            nLastIndex--;
                    })
                    if (nLastIndex < 0)
                        nLastIndex = 0;
                }

                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.mainDiv}>
                                <div>
                                    <h2 className={styles.tableHeader}>
                                        Control Positions
                                    </h2>
                                    <StandardPlate
                                        disabledAfterWell={nLastIndex}
                                        wellCount={wellCount}
                                        columnCount={currentWorkflowRun.AssetColCount}
                                        wellContents={wellContents}
                                        positionByRow={currentWorkflowRun.AssetPositionByRow}
                                        wellClick={this.handleWellClick}
                                    />
                                </div>
                                <div className={styles.contentDiv}>
                                    {GetWorkflowType() === "NBS" && <RadioGroup row value={this.state.gridViaInterface} onChange={this.handleRadioChange} >
                                        <FormControlLabel disabled={disabled} value={true} control={<Radio />} label="Grid via user interface" />
                                        <FormControlLabel disabled={disabled} value={false} control={<Radio />} label="Grid samples with file import" />
                                    </RadioGroup>}
                                    {this.state.gridViaInterface && <div>
                                        <h3><i>Click row to select, then click on a well or tube to assign position.<br />Minimum of 1 PTC, 1 NTC and 1 Sample must be assigned.</i></h3>
                                        <h2 className={styles.tableHeader}>
                                            Select Control Positions
                                        </h2>
                                        <table className={styles.table} >
                                            <thead>
                                                <tr>
                                                    <th>Control</th>
                                                    <th>Position</th>
                                                    <th>Click to Select</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inputs}
                                                <tr>
                                                    <td className={styles.clearButton} colSpan={3}>
                                                        <IconButton
                                                            disabled={disabled}
                                                            onClick={() => { this.clearControls() }}
                                                        >
                                                            <DeleteIcon />
                                                            {(GetWorkflowType() === "Anthrax") ? "Clear Rack" : "Clear Plate"}
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className={styles.gridSequentialButton}>
                                            <Button disabled={disabled} variant="contained" onClick={() => this.setState({ gridSequential: true, selectedSample: undefined, controlType: undefined })}>
                                                Grid Sequential - Click here then well
                                            </Button>
                                        </div>
                                        <SampleInfoTable
                                            overrideStyles={{ height: "250px" }}
                                            header={"Select Sample Positions"}
                                            columns={this.columns()}
                                            data={sampleAsWellContent}
                                        />
                                    </div>}
                                    {!this.state.gridViaInterface && <div>
                                        <div className={styles.boldText}><b>Imported XML files from the PerkinElmer Panthera Puncher are currently supported for gridding.</b></div>
                                        <div className={styles.boldText}><b>Sample IDs are expected to match what was imported and selected for this workflow.</b></div>
                                        <div className={styles.uploadGriddedSamplesDiv}>
                                            <Button variant="contained" color="primary"
                                                onClick={() => {
                                                    const input = document.getElementById('griddingInputFile') as HTMLInputElement;
                                                    if (input && input.files) {
                                                        const file = input.files[0];
                                                        const fileExtRegex = /^.*\.xml/;

                                                        if (fileExtRegex.test(file.name)) {
                                                            this.setState({ messageContent: "", messageSnackbarOpen: false });

                                                            const reader = new FileReader();
                                                            reader.onload = () => { this.parseGriddingInput(reader); };
                                                            // Using encoding windows-1252 
                                                            reader.readAsText(file, "windows-1252");
                                                        } else {
                                                            this.setState({ messageContent: "Please upload a valid XML file", messageSnackbarOpen: true });
                                                        }

                                                    }
                                                }}>Upload Gridded Samples</Button>
                                            <input className={styles.griddedSamplesInput} type="file" id="griddingInputFile"></input>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div className={styles.flexRow}>

                            <QCUIComponent
                                open={this.state.qcOpen}
                                failedQCs={this.state.failedQcs}
                                close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                results={this.state.qcResults}
                                step={currentStep}
                                workflowRunId={currentWorkflowRun.Id}
                                workflowName={currentWorkflowRun.WorkflowName}
                            />

                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                            </div>
                        </div>


                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.messageContent}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>
                );
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private async moveToNextStep() {
        let {
            selectedControls
        } = this.props.workflowRunService.getState();


        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        const currentStep = this.props.workflowRunService.currentStep;

        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];


        if (currentWorkflowRun && currentStep) {

            //Run all QCs
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case GriddingSampleCount:
                        let sampleCountParam = _.find(qc.CheckConfig?.Parameters, p => p.Id === "MinSampleCount")
                        let samplePass = true;
                        if (sampleCountParam && currentWorkflowRun.Samples.length < Number.parseInt(sampleCountParam.Value)) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            samplePass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? samplePass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: currentWorkflowRun.Samples.length.toString(),
                                Date: new Date(Date.now()),
                                Pass: samplePass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case GriddingGriddedMandatory:
                        let griddedPass = true;
                        let wellContentsToCount = this.getWellContents();
                        const count = _.filter(wellContentsToCount, wc => wc.Sample !== undefined && wc.Sample !== null).length;
                        if (currentWorkflowRun.Samples.length !== count) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            griddedPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? griddedPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: currentWorkflowRun.Samples.length.toString(),
                                Date: new Date(Date.now()),
                                Pass: griddedPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case GriddingNTCControlCount:
                        let ntcControls = _.find(selectedControls, c => c.Name === "NTC")
                        let ntcPass = true;
                        let ntcCountParam = _.find(qc.CheckConfig?.Parameters, p => p.Id === "MinNTCCount")
                        if ((ntcCountParam && ntcControls && ntcControls.Positions.length < Number.parseInt(ntcCountParam.Value)) || !ntcControls) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            ntcPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? ntcPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: ntcControls ? ntcControls.Positions.length.toString() : "0",
                                Date: new Date(Date.now()),
                                Pass: ntcPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case GriddingPTCControlCount:
                        let ptcCountParam = _.find(qc.CheckConfig?.Parameters, p => p.Id === "MinPTCCount")
                        let ptcControls = _.find(selectedControls, c => c.Name === "PTC")
                        let ptcPass = true;
                        if ((ptcCountParam && ptcControls && ptcControls.Positions.length < Number.parseInt(ptcCountParam.Value)) || !ptcControls) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            ptcPass = false
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? ptcPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: ptcControls ? ptcControls.Positions.length.toString() : "0",
                                Date: new Date(Date.now()),
                                Pass: ptcPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                }
            });

            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving

                const outputAsset = _.find(currentStep.OutputAssets, asset => (asset.Name === currentStep.OutputName));

                if (outputAsset) {
                    let selectedCount = 0;
                    _.forEach(selectedControls, s => selectedCount += s.Positions.length);

                    await Promise.all([
                        this.props.workflowRunService.saveWellsToAsset(),
                        this.props.workflowRunService.updateAssetStatus([outputAsset.Id], "InUse", outputAsset.AssetType),
                    ]);
                    await this.props.workflowRunService.completeStep(); //Race condition on plates
                }
            }
        }
    }

    @bind
    private handleWellClick(position: number) {
        if (this.state.gridSequential) {
            this.gridSequential(position);
            this.setState({ gridSequential: false });
        }
        else {
            const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
            const currentStep = this.props.workflowRunService.currentStep;
            const assetType = GetCurrentStepOutputType();

            if (currentStep && currentWorkflowRun && (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress")) {
                return;
            }

            if (currentStep && assetType) {
                let wellContent = _.cloneDeep(this.state.selectedSample);
                const controlType = this.state.controlType;
                if (controlType) {
                    wellContent = {
                        Id: controlType.Id,
                        Control: controlType,
                        WellPosition: position,
                        ContentType: "Control",
                        RtpcrPass: "Pass", //Set so legend is correct,
                        RunCount: 1
                    };
                }
                else if (this.state.selectedSample) {
                    wellContent = _.cloneDeep(this.state.selectedSample);
                    wellContent.WellPosition = position;
                    if(this.state.selectedSample.Sample) {
                        this.setState({selectedSample:undefined})
                    }
                }

                switch (assetType) {
                    case "Rack":
                        let rackToUpdate = _.cloneDeep(this.props.workflowRunService.wellsRack);
                        const rackWellExists = _.find(rackToUpdate.WellContents, (w) => { return ((w.WellPosition === position)) });
                        if (!rackWellExists && wellContent != undefined) {
                            rackToUpdate.WellContents.push(wellContent);
                            this.props.workflowRunService.wellsRack = rackToUpdate;
                        }
                        break;
                    case "Plate":
                        let plateToUpdate = _.cloneDeep(this.props.workflowRunService.wellsPlate);
                        const plateWellExists = _.find(plateToUpdate.WellContents, (w) => { return ((w.WellPosition === position)) });
                        if (!plateWellExists && wellContent != undefined) {
                            plateToUpdate.WellContents.push(wellContent);
                            this.props.workflowRunService.wellsPlate = plateToUpdate;
                        }
                        break;
                }
            }
        }
    }

    @bind
    private gridSequential(position: number) {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        if (currentStep && currentWorkflowRun && (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress")) {
            return;
        }

        let samplesToPlace: WellContentVM[] = [];
        let offSet = position;
        const assetType = GetCurrentStepOutputType();
        if (currentWorkflowRun && currentStep && assetType) {
            let assetWellContents: WellContentVM[] = [];
            switch (assetType) {
                case "Plate":
                    assetWellContents = this.props.workflowRunService.wellsPlate.WellContents;
                    break;
                case "Rack":
                    assetWellContents = this.props.workflowRunService.wellsRack.WellContents;
                    break;
            }

            samplesToPlace = _.filter(assetWellContents, wc => wc.Control !== undefined);

            _.forEach(_.sortBy(currentWorkflowRun.Samples, s => s.SampleId ), sample => {
                while (_.find(samplesToPlace, wc => wc.WellPosition === offSet) !== undefined) {
                    offSet++;
                }
                samplesToPlace.push({
                    Id: sample.Id,
                    Sample: sample.toJS(),
                    WellPosition: offSet,
                    RunCount: 1,
                    ContentType: "Sample",
                    RtpcrPass: "Pass", //Set so legend is correct,
                });
            });

            switch (assetType) {
                case "Plate":
                    let plateToUpdate = _.cloneDeep(this.props.workflowRunService.wellsPlate);
                    plateToUpdate.WellContents = samplesToPlace;
                    this.props.workflowRunService.wellsPlate = plateToUpdate;
                    break;
                case "Rack":
                    let rackToUpdate = _.cloneDeep(this.props.workflowRunService.wellsRack);
                    rackToUpdate.WellContents = samplesToPlace;
                    this.props.workflowRunService.wellsRack = rackToUpdate;
                    break;
            }
        }
    }

    @bind
    private handleControlClick(controlToSet: ControlVM) {
        this.setState({ controlType: controlToSet, selectedSample: undefined, gridSequential: false });
    }

    @bind
    private clearControls() {
        this.props.workflowRunService.resetControlsAsset();
    }

    @bind
    private getWellContents() {
        let assetContents: WellContentVM[] = [];
        const assetType = GetCurrentStepOutputType();

        if (assetType) {
            switch (assetType) {
                case "Rack":
                    assetContents = this.props.workflowRunService.wellsRack.WellContents;
                    break;
                case "Plate":
                    assetContents = this.props.workflowRunService.wellsPlate.WellContents;
                    break;
            }
        }

        return assetContents;
    }

    @bind
    private findSampleWell(well: WellContentVM) {
        const assetType = GetCurrentStepOutputType();
        if (assetType) {
            switch (assetType) {
                case "Plate":
                    const plateSampleWell = _.find(this.props.workflowRunService.wellsPlate.WellContents, w => w.Sample?.Id === well.Sample?.Id);
                    return plateSampleWell;
                case "Rack":
                    const rackSampleWell = _.find(this.props.workflowRunService.wellsRack.WellContents, w => w.Sample?.Id === well.Sample?.Id);
                    return rackSampleWell;
            }
        }

        return null;
    }


    @bind
    private handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ gridViaInterface: (event.target as HTMLInputElement).value === "true" });
    }

    @bind
    private async parseGriddingInput(reader: FileReader) {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        try {
            if (currentWorkflowRun) {
                const sampleData = currentWorkflowRun.Samples;
                const wellContents = this.getWellContents();

                const sampleAsWellContent: WellContentVM[] = _.map(sampleData, s => {
                    var assetWellContent = _.find(wellContents, (wc) => (wc.Sample != undefined && wc.Sample.Id == s.Id));
                    var wellContent =
                    {
                        Id: s.Id,
                        Sample: s.toJS(),
                        WellPosition: s.WellPosition || (assetWellContent && assetWellContent.WellPosition) || 0,
                        RunCount: 1
                    };
                    return wellContent;
                });


                let xml = reader.result as string;
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(xml, "text/xml");

                let orderByColumns = xmlDoc.getElementsByTagName("Plate")[0].getElementsByTagName("TraversalOrder")[0].children[0].textContent === "Columns";
                let wells = xmlDoc.getElementsByTagName("Plate")[0].getElementsByTagName("Wells")[0].children;

                for (let index = 0; index < wells.length; index++) {
                    let well = wells[index];
                    let wellPosition = Number.parseInt(well.getElementsByTagName("Index")[0].textContent || "0");
                    let sampleId = well.getElementsByTagName("SampleBarcode")[0].textContent;
                    let wellType = well.getElementsByTagName("WellType")[0].getElementsByTagName("Type")[0].textContent;

                    if (wellType === "Patient") {
                        let sample = _.find(sampleAsWellContent, s => s.Sample?.SampleId === sampleId);
                        if (sample !== undefined) {
                            this.setState({ selectedSample: sample, controlType: undefined, gridSequential: false });
                        }
                    }
                    else if (wellType === "Control") {
                        let controlToSet: ControlVM | undefined;
                        switch (sampleId) {
                            case "PositiveControl":
                                controlToSet = _.find(currentWorkflowRun.Controls, c => c.Name === "PTC")?.toJS();
                                break;
                            case "NegativeControl":
                            default:
                                controlToSet = _.find(currentWorkflowRun.Controls, c => c.Name === "NTC")?.toJS();
                                break;
                        }

                        this.setState({ controlType: controlToSet, selectedSample: undefined, gridSequential: false });
                    }
                    if (wellType !== "Empty") {
                        this.handleWellClick(wellPosition);
                    }

                }
                //show popup and return to table view
                this.setState({ gridViaInterface: true, messageContent: "Upload was successful. Please verify the mappings on the screen plate map.", messageSnackbarOpen: true });

            }
        }
        catch (error) {
            //something went wrong during parsing.
            this.setState({ messageContent: "Error during XML Parsing.", messageSnackbarOpen: true });
        }
    }
}