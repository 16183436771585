import {
    bind,
    React
} from "$Imports/Imports";
import {
    MenuItem,
    TextField
} from "$Imports/MaterialUIComponents";
import { Autocomplete } from "@material-ui/lab";
import { ChangeEvent } from "react";

import
    * as s
    from "underscore.string";

const styles: {
    Table: string;
    Row: string;
    LabelCell: string;
    DataCell: string;
    markdown: string;
    Input: string;
    DataCellHeader: string
} = require("./SimpleQuantificationInstrument.scss");

interface ISimpleQuantificationInstrumentProps {
    displayNotes: string;
    disabled: boolean;
    updateInstrumentName: (name: string) => void;
    instrumentName: string;
    instrumentOptions?: string[];
    concentration: number;
    updateConcentration: (newConc: number) => void;
    concentrationUnit: string;
    updateConcentrationUnit: (newUnit: string) => void;
}

interface ISimpleQuantificationInstrumentState {
}

export class SimpleQuantificationInstrument extends React.Component<ISimpleQuantificationInstrumentProps, ISimpleQuantificationInstrumentState> {

    public state: ISimpleQuantificationInstrumentState = {
    };

    public render() {
        return <div>
            <h2>Quantification Instrument</h2>
            <i>{this.props.displayNotes}</i>
            <table className={styles.Table}>
                <tbody>
                    <tr className={styles.Row} >
                        <td className={styles.DataCellHeader}>
                            Quantification Instrument:
                        </td>
                        <td className={styles.DataCell}>
                            <Autocomplete
                                freeSolo
                                disabled={this.props.disabled}
                                id="instrument-name"
                                options={this.props.instrumentOptions || []}
                                onChange={(e, val) => this.props.updateInstrumentName(val || "")}
                                value={this.props.instrumentName}
                                renderInput={(params) =>
                                (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                        disabled={this.props.disabled} style={{ textAlign: "right" }} value={this.props.instrumentName} onChange={(props) => this.props.updateInstrumentName(props.target.value)} />
                                )}
                            />
                        </td>
                    </tr>
                    <tr className={styles.Row}>
                        <td className={styles.DataCellHeader}>
                            Concentration:
                        </td>
                        <td className={styles.DataCell} >
                            <TextField type="number" inputProps={{ min: 0 }} value={this.props.concentration}
                                disabled={this.props.disabled}
                                onChange={this.updateConcentrationField} />
                        </td>
                        <td>
                            <TextField
                                select
                                disabled={this.props.disabled}
                                label=""
                                id="concentrationUnits"
                                value={this.props.concentrationUnit}
                                onChange={(event) => { this.props.updateConcentrationUnit(event.target.value as string); }}
                            >
                                <MenuItem key={"ng"} value={"ng/uL"}>{"ng/uL"}</MenuItem>
                                <MenuItem key={"pg"} value={"pg/uL"}>{"pg/uL"}</MenuItem>
                                <MenuItem key={"ug"} value={"ug/uL"}>{"ug/uL"}</MenuItem>
                            </TextField>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }

    @bind
    updateConcentrationField(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.props.updateConcentration(0);
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue >= 0) {
                this.props.updateConcentration(numericValue);
            }
        }
    }

}