import {
    React,
    bind,
    _,
} from "$Imports/Imports";

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";
import {
    CollapsibleSection,
    DataLoadingDisplay,
    InitialDilutionTargetVolumeMinCheck,
    QCUIComponent,
    StepActionsControl,
    StepChangeControl,
} from "$Imports/CommonComponents";
import { Checkbox, TextField } from "$Imports/MaterialUIComponents";

import {
    CalculationService
} from "$State/CalculationFreezerService";
import { QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";

const commonStyles: {
    footerDiv: string;
    collapsibleDiv: string;
    tableHeader: string;
    rowHighlight: string;
} = require("./CommonStepStyles.scss");

const styles: {
    italicizedText: string;
    concentrationVolumeInputs: string;
    Table: string;
    Row: string;
    DataCell: string;
    DataCellHeader: string;
} = require("./InitialDilutionNBS.scss");

interface IInitialDilutionNBSScreenState {
    useBioanalyzer: boolean;
    avgLibrarySize: number;
    pooledLibraryConcentration: number;
    targetVolume: number;
    completePoolPlateConcentration?: number;
    targetConcentrationRatio?: number;
    poolVolumeToUse?: number;
    RSBVolumeToUse?: number;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    qcOpen: boolean;
}

export class InitialDilutionNBS extends React.Component<IWorkflowScreenProps, IInitialDilutionNBSScreenState> {

    state: IInitialDilutionNBSScreenState = {
        useBioanalyzer: false,
        avgLibrarySize: 350,
        pooledLibraryConcentration: 0,
        targetVolume: 0,
        failedQcs: [],
        qcResults: [],
        qcOpen: false,
    };

    async componentDidMount() {
        await this.props.workflowRunService.fetchStepInstanceCustomFields();

        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            let customFields = currentStep.CustomFields;
            let averageLibrarySize = 350;
            let concentration = 0;
            if (GetWorkflowType() === "SarsCov2") {
                averageLibrarySize = 400;
            }

            let stepInstances = this.props.workflowRunService.getState().stepInstances;
            if (stepInstances.hasFetched && stepInstances.data && currentStep) { 
                let stepInstancesData = _.sortBy(stepInstances.data, i => i.WorkflowOrder);
                let previousStepInstance = _.find(stepInstancesData, (instance) => instance.WorkflowOrder === currentStep?.WorkflowOrder - 1);
    
                if(previousStepInstance && previousStepInstance.StepInstanceId && previousStepInstance.CustomFields["Concentration"]) {
                    concentration = parseFloat(previousStepInstance.CustomFields["Concentration"])
                }
            }

            this.setState({
                avgLibrarySize: customFields["AverageLibrarySize"] ? parseFloat(customFields["AverageLibrarySize"]) : averageLibrarySize,
                pooledLibraryConcentration: customFields["PooledLibraryConcentration"] ? parseFloat(customFields["PooledLibraryConcentration"]) : concentration,
                targetVolume: customFields["TargetStartingVolume"] ? parseInt(customFields["TargetStartingVolume"]) : this.getMinTargetVol(),
                completePoolPlateConcentration: customFields["CompletePoolPlateConcentration"] ? parseFloat(customFields["CompletePoolPlateConcentration"]) : undefined,
                poolVolumeToUse: customFields["PoolVolumeToUse"] ? parseFloat(customFields["PoolVolumeToUse"]) : undefined,
                RSBVolumeToUse: customFields["RSBVolumeToUse"] ? parseFloat(customFields["RSBVolumeToUse"]) : undefined,
                targetConcentrationRatio: customFields["TargetConcentrationRatio"] ? parseFloat(customFields["TargetConcentrationRatio"]) : undefined,
                useBioanalyzer: customFields["UseBioanalyzerResults"] ? customFields["TargetConcentrationRatio"] === "true" : false,
            }, () => {
                this.calculateDilutionData();
            });
        }
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    @bind
    private getMinTargetVol(){
        const currentStep = this.props.workflowRunService.currentStep;
        const minTargetVolCheck = _.find(currentStep?.QCCheckInstances?.toJS(), q => q.QCCheckType === "InitialDilutionTargetVolumeMinCheck");
        const targetVolumeMin = _.find(minTargetVolCheck?.CheckConfig?.Parameters, p => p.Id === "Volume");

        return targetVolumeMin ? parseInt(targetVolumeMin.Value) : 30;
    }

    @bind
    private async moveToNextStepBegin() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];
        // Run all QCs.
        if (currentStep) {
            let customFields = currentStep.toJS().CustomFields;
            let targetVolume: number = Number.parseFloat(customFields["TargetStartingVolume"]);
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case InitialDilutionTargetVolumeMinCheck:
                        let targetVolumeMin = _.find(qc.CheckConfig?.Parameters, p => p.Id === "Volume");
                        let targetVolumeMinPass = true;
                        if (targetVolumeMin && targetVolume < Number.parseInt(targetVolumeMin.Value)) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            targetVolumeMinPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? targetVolumeMinPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: targetVolume.toString(),
                                Date: new Date(Date.now()),
                                Pass: targetVolumeMinPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    default:
                        break;
                }
            });
            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else if (failedQCs.length === 0) {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                this.completeMoveToNextStep();
            }
        }
    }

    @bind
    private async completeMoveToNextStep() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        if (currentWorkflowRun && currentStep) {
            await Promise.all([
                this.props.workflowRunService.updateCustomFields()
            ]);
            await this.props.workflowRunService.completeStep();
        }
    }

    render() {
        let {
            currentStep,
            currentWorkflowRun,
        } = this.props.workflowRunService;
        let targetConcentration = 2;
        if (GetWorkflowType() === "SarsCov2") {
            targetConcentration = 4;
        }
        if (currentStep && currentWorkflowRun) {
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return <div>
                <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                    <div className={commonStyles.collapsibleDiv}>
                        <div>
                            <h2>Dilute to Starting Concentration</h2>
                            <div style={{ display: "flex", flexDirection: "row", marginLeft: "20px", marginBottom: "20px" }}>
                                <div className={styles.concentrationVolumeInputs}>
                                    <div style={{ paddingTop: "5px", paddingRight: "5px" }}>
                                        Pooled Library Concentration (ng/uL):
                                    </div>
                                    <TextField disabled={disabled} type="number" inputProps={{ min: 0 }} value={this.state.pooledLibraryConcentration}
                                        onChange={(event) => {
                                            this.setState({ pooledLibraryConcentration: parseFloat(event.target.value) });
                                            if (parseFloat(event.target.value)) {
                                                this.setState({ pooledLibraryConcentration: parseFloat(event.target.value) }, this.calculateDilutionData);
                                            }
                                        }} />
                                </div>
                                <div className={styles.concentrationVolumeInputs}>
                                    <div style={{ paddingTop: "5px", paddingRight: "5px" }}>
                                        Avg. Library Size (bp):
                                    </div>
                                    <TextField type="number" inputProps={{ min: 0 }} value={this.state.avgLibrarySize}
                                        disabled={!this.state.useBioanalyzer || disabled}
                                        onChange={(event) => {
                                            this.setState({ avgLibrarySize: parseFloat(event.target.value) }, this.calculateDilutionData);
                                        }} />
                                    <Checkbox value={this.state.useBioanalyzer} disabled={disabled}
                                        onChange={(event) => {
                                            this.setState({ useBioanalyzer: event.target.checked })
                                        }} />
                                    <span style={{ paddingTop: "5px", paddingRight: "5px" }} className={styles.italicizedText}>Use bioanalyzer results</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ paddingTop: "5px", paddingRight: "20px" }}>
                                    {GetWorkflowType() === "SarsCov2"
                                        ? <i>This calculation supports the MiSeq and iSeq sequencing systems.</i>
                                        : <i>This calculation supports the NextSeq 550 and NextSeq 500 sequencing systems.</i>}
                                </div>
                                <div style={{ paddingTop: "5px", paddingRight: "20px", color: "#00049F" }}>
                                    Target Concen. (nM): {targetConcentration}
                                </div>
                                <div className={styles.concentrationVolumeInputs}>
                                    <div style={{ paddingTop: "5px", paddingRight: "5px", color: "#00049F" }}>
                                        Target Volume (ul):
                                    </div>
                                    <TextField type="number" inputProps={{ min: 0 }} value={this.state.targetVolume} disabled={disabled}
                                        onChange={(event) => {
                                            this.setState({ targetVolume: parseInt(event.target.value) }, this.calculateDilutionData);
                                        }} />
                                </div>

                            </div>
                            <table className={styles.Table}>
                                <tbody>
                                    <tr className={styles.Row} >
                                        <td className={styles.DataCellHeader}>
                                            Complete Plate Pool Concen. (nM):
                                        </td>
                                        <td className={styles.DataCell}>
                                            {this.state.completePoolPlateConcentration?.toFixed(4)}
                                        </td>
                                    </tr>
                                    <tr className={styles.Row} >
                                        <td className={styles.DataCellHeader}>
                                            Target Concen. to Current Concen. Ratio:
                                        </td>
                                        <td className={styles.DataCell}>
                                            {this.state.targetConcentrationRatio?.toFixed(4)}
                                        </td>
                                    </tr>
                                    <tr className={styles.Row} >
                                        <td className={styles.DataCellHeader}>
                                            <b>Pool Volume to Use (ul):</b>
                                        </td>
                                        <td className={styles.DataCell}>
                                            {Number(this.state.poolVolumeToUse?.toPrecision(3) || "")}
                                        </td>
                                    </tr>
                                    <tr className={styles.Row} >
                                        <td className={styles.DataCellHeader}>
                                            <b>RSB Volume to Use (ul):</b>
                                        </td>
                                        <td className={styles.DataCell}>
                                            {Number(this.state.RSBVolumeToUse?.toPrecision(3) || "")}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CollapsibleSection>
                {currentStep.QCCheckInstances &&
                    <QCUIComponent
                        open={this.state.qcOpen}
                        failedQCs={this.state.failedQcs}
                        close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                        results={this.state.qcResults}
                        step={currentStep}
                        workflowRunId={currentWorkflowRun.Id}
                        workflowName={currentWorkflowRun.WorkflowName}
                        completeStep={this.completeMoveToNextStep}
                    />
                }
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                    </div>
                </div>
            </div>;
        }
        return <DataLoadingDisplay />;
    }

    private async calculateDilutionData() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep && currentWorkflowRun) {
            const customFields: { [index: string]: string } = {};
            let targetVol = this.state.targetVolume;
            customFields["PooledLibraryConcentration"] = this.state.pooledLibraryConcentration.toFixed(3);
            customFields["TargetStartingVolume"] = !isNaN(targetVol) ? targetVol.toFixed(3) : "0";
            customFields["AverageLibrarySize"] = this.state.avgLibrarySize.toFixed(3);
            customFields["TargetConcentration"] = GetWorkflowType() === "SarsCov2" ? "4" : "2";


            const dilutionDataDictionary: { [index: string]: number } = {};
            await CalculationService.performCalculation("initialDilutionNBS", dilutionDataDictionary, customFields);
            let data = CalculationService.getState().performCalculationResponse.data?.CalculationResponse as { [key: string]: any };
            let calculatedDilutionData = data["dilutionData"];

            if (calculatedDilutionData) {
                this.setState(
                    {
                        completePoolPlateConcentration: calculatedDilutionData.CompletePlatePoolConcentration,
                        targetConcentrationRatio: calculatedDilutionData.TargetConcentrationRatio,
                        poolVolumeToUse: calculatedDilutionData.PoolVolumeToUse,
                        RSBVolumeToUse: calculatedDilutionData.RSBVolumeToUse
                    }
                );
            }

            //Update all custom fields with proper values
            this.props.workflowRunService.updateCustomField("PooledLibraryConcentration", customFields["PooledLibraryConcentration"]);
            this.props.workflowRunService.updateCustomField("TargetStartingVolume", customFields["TargetStartingVolume"]);
            this.props.workflowRunService.updateCustomField("AverageLibrarySize", customFields["AverageLibrarySize"]);

            this.props.workflowRunService.updateCustomField("CompletePoolPlateConcentration", calculatedDilutionData.CompletePlatePoolConcentration);
            this.props.workflowRunService.updateCustomField("TargetConcentrationRatio", calculatedDilutionData.TargetConcentrationRatio);
            this.props.workflowRunService.updateCustomField("PoolVolumeToUse", calculatedDilutionData.PoolVolumeToUse);
            this.props.workflowRunService.updateCustomField("RSBVolumeToUse", calculatedDilutionData.RSBVolumeToUse);
            this.props.workflowRunService.updateCustomField("UseBioanalyzerResults", this.state.useBioanalyzer ? "true" : "false");

        }
    }

}