import {
  React,
  _
} from "$Imports/Imports";

import { 
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "$Imports/MaterialUIComponents";

import {
  WorkflowRunService,
  IWorkflowRunServiceInjectedProps
 } from "$State/WorkflowRun/WorkflowRunFreezerService";

import { FileSharingAPI } from "$State/FileShareManagementService";
import { StyledTableCell, StyledTableHeaderRow } from "$Imports/CommonComponents";

const styles: {
  mainContainer:string;
  cardStyle:string;
  headerContainer:string;
  title:string;
  run:string;
  sampleLabel:string;
  quantitativeMetricsContainer:string;
  qualitativeMetricsContainer:string;
  metricHeaderLabel:string;
  ampliconSublabel:string;
} = require("./QCStatistics.scss");

interface IQCVisualizationBaseProps {
  runId: string;
  sampleId: string;
};
interface IQCVisualizationState {
  imageData?: {[key : string] : Blob},  // Holds blob data, labeled by image name.
  sampleName: string                        // The sample name for the run.
}

// Default image for when images have not yet loaded.
const DefaultImg = require("../../../images/placeholder.png") as string;

export type IQCVisualizationProps = IWorkflowRunServiceInjectedProps & IQCVisualizationBaseProps;

class _QCVisualization extends React.Component<IQCVisualizationProps, IQCVisualizationState> {

  DEFAULT_WIDTH: number = 500;
  DEFAULT_HEIGHT: number = 400;

  // Initialize the state.
  state: IQCVisualizationState = {
    imageData: undefined,
    sampleName: ""
  };
  
  /*
   * Returns a component with an image from the state.imageData.
   */
  private GetImage(imgName: string) {
    let imgData = this.state.imageData
    return (imgData === undefined || !(imgName in imgData)) 
      ? <img src={DefaultImg}/>
      : <img src={URL.createObjectURL(imgData[imgName])} height={this.DEFAULT_HEIGHT} width={this.DEFAULT_WIDTH}/>
  }

  /*
   * Creates a 2-column row for with left column as label and right column as the image.
   */  
  private AddTableRow(label: string, imgName: string){
    return (<TableRow>
              <StyledTableCell align="left">{label}</StyledTableCell>
              <StyledTableCell>{this.GetImage(imgName)}</StyledTableCell>
            </TableRow>)
  }

  async componentDidMount() {
    this.props.workflowRunService.workflowId = this.props.runId;
    await this.props.workflowRunService.fetchWorkflowRunSequencingData(true);
    await this.props.workflowRunService.fetchWorkflowRun(true);

    const { fetchSequencingDataState, workflowRun } = this.props.workflowRunService.freezer.get()
    let sequencingData:any = undefined;
    if(fetchSequencingDataState.hasFetched && fetchSequencingDataState.data){
      sequencingData = _.find(fetchSequencingDataState.data, d => d.SampleId === this.props.sampleId)?.MetadataDictionary;
    }
    
    this.state.sampleName = sequencingData.sample_name;

    /*  The complete list of images to be loaded.
        If a new image needs to be added to this page, it should be added to this list
        as it appears on the FileShare.
    */
    let lImages = [
                  this.state.sampleName + '-combined-depth.png',
                  this.state.sampleName + '.cov.hist',
                  this.state.sampleName + '_R1per_sequence_quality.png',
                  this.state.sampleName + '_R2per_sequence_quality.png',
                  this.state.sampleName + '_R1per_base_quality.png',
                  this.state.sampleName + '_R2per_base_quality.png',
                  this.state.sampleName + '_R1per_base_n_content.png',
                  this.state.sampleName + '_R2per_base_n_content.png'
                ];

    // Loop through the images to be loaded and put them into the state.imageData.
    let tempImageData : { [key : string] :Blob}  = {};
    for (let i = 0; i < lImages.length; i++)
    {
      await fetch(FileSharingAPI + 'DownloadFile/' + this.state.sampleName + '/' + lImages[i], { method: "GET"})
      .then(response => {
        if (response.status != 200)
        {
          throw new Error('Error downloading file: ' + lImages[i] + '. Given response: ' + response.status);
        }
        return response.blob()
      })
      .then(blob => tempImageData[lImages[i]] = blob)
      .then(() => this.setState({imageData: tempImageData}))
      .catch(err => console.log('Outside error: ' + err))
    }

  }
  
  render() {
    
    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          
          <div className={styles.headerContainer}>
            <div className={styles.title}>Sample-Level Bioinformatics Quality Control Metrics - Visualization</div>
            <div>Run {this.state.sampleName} - SARS-CoV-2 Virus Sequencing Prep</div>
          </div>
          
          <div>
            <Table>
              
              <TableHead>
                <StyledTableHeaderRow>
                  <StyledTableCell align="left">Metric Name</StyledTableCell>
                  <StyledTableCell align="left">Visualization</StyledTableCell>
                </StyledTableHeaderRow>
              </TableHead>

              <TableBody>

                {this.AddTableRow('Amplicon Depth', this.state.sampleName + '-combined-depth.png')}
                {this.AddTableRow('Average Q score R1', this.state.sampleName + '_R1per_sequence_quality.png')}
                {this.AddTableRow('Average Q score R2', this.state.sampleName + '_R2per_sequence_quality.png')}
                {this.AddTableRow('Per Base Sequence Quality R1', this.state.sampleName + '_R1per_base_quality.png')}
                {this.AddTableRow('Per Base Sequence Quality R2', this.state.sampleName + '_R2per_base_quality.png')}
                {this.AddTableRow('Per Base N Content Across All Reads R1', this.state.sampleName + '_R1per_base_n_content.png')}
                {this.AddTableRow('Per Base N Content Across All Reads R2', this.state.sampleName + '_R2per_base_n_content.png')}
                
              </TableBody>

            </Table>
            
          </div>

        </Card>
      
      
      </div>
    )
  }
}

export const QCVisualization = WorkflowRunService.inject(_QCVisualization);