import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import
    * as s
    from "underscore.string";

import { IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, PCRControl, QCUIComponent, CleanAfterLigationTemperatureMandatoryEntryCheck, CleanAfterLigationTemperatureInRangeCheck, InstructionSetControl, CollapsibleSection } from "$Imports/CommonComponents";
import Snackbar from "@material-ui/core/Snackbar";
import { RadioGroup, FormControlLabel, Radio, TextField } from "@material-ui/core";
import { ChangeEvent } from "react";
import { ErrorIcon } from "$Imports/MaterialUIComponents";
import { QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";
import { missingData } from "$Components/common/WorkflowControls/InstructionSetControl";

const styles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

const cleanupStyles: {
    errorIcon: string;
} = require("./Cleanup.scss");

export interface ICleanupAfterLigationState {
    messageSnackbarOpen: boolean;
    message: string;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    tempNotFilled: boolean;
    canMove: boolean;
}

export class CleanupAfterLigationScreen extends React.Component<IWorkflowScreenProps, ICleanupAfterLigationState> {

    state: ICleanupAfterLigationState = {
        messageSnackbarOpen: false,
        message: "",
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        tempNotFilled: false,
        canMove: true
    };

    componentDidMount() {
        this.props.workflowRunService.fetchPCRForStep(true);
        this.props.workflowRunService.fetchCleaningDataForStep(true);
        this.props.workflowRunService.fetchInputPoolsForStep(true);
        this.props.workflowRunService.fetchStepInstanceCustomFields();
        this.props.workflowRunService.fetchInstrumentOptions();
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;

        let {
            cleaningDataState,
            pcrInfoState,
            inputPools
        } = this.props.workflowRunService.getState();

        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;

        if (currentWorkflowRun && currentStep && pcrInfoState.data && cleaningDataState.data && instrumentOptions && lotOptions) {
            let customFields = currentStep.toJS().CustomFields;
            let tempValue = customFields["RoomTemperature"];
            let usePcr = customFields["UsePcr"] === "true";

            const inputName = currentStep.InputName;
            let inputPool = _.find(inputPools.data, p => p.Name === inputName);
            const disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return (
                <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={styles.mainDiv}>
                            <div>
                                <h2>Cleaning Instructions</h2>
                                <InstructionSetControl
                                    componentSetData={cleaningDataState.data[0]}
                                    updateComponentSetDataSteps={this.props.workflowRunService.updateCleaningDataSteps}
                                    disabled={disabled}
                                    poolOrPlate={"Pool"}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove: boolean) => { this.setState({ canMove: canMove }) }}
                                />
                            </div>

                            <div>
                                <RadioGroup row value={usePcr} onChange={this.handleRadioChange} >
                                    <FormControlLabel disabled={disabled} value={true} control={<Radio />} label="Use Thermocycler" />
                                    <FormControlLabel disabled={disabled} value={false} control={<Radio />} label="Incubate at room temparature" />
                                </RadioGroup>
                                {usePcr ?
                                    <PCRControl disabled={disabled} controlInfo={pcrInfoState.data} updateInstrumentName={this.props.workflowRunService.updatePCRName} headerOverride={"Thermocycler Information"} instrumentOptions={instrumentOptions} /> :
                                    (<div>
                                        {"Temperature " + customFields["degrees"] + "C"} <TextField disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={tempValue || ""} onChange={this.updateTempField} inputProps={{ pattern: "[0-9.]*" }} />
                                        {this.state.tempNotFilled && <ErrorIcon className={cleanupStyles.errorIcon} />}
                                    </div>)
                                }
                            </div>
                        </div>
                    </CollapsibleSection>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {currentStep.QCCheckInstances &&
                            <QCUIComponent
                                open={this.state.qcOpen}
                                failedQCs={this.state.failedQcs}
                                close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                results={this.state.qcResults}
                                step={currentStep}
                                workflowRunId={currentWorkflowRun.Id}
                                workflowName={currentWorkflowRun.WorkflowName}
                                completeStep={this.completeMoveToNextStep}
                            />
                        }
                        <div className={styles.footerDiv} style={{ width: "100%" }}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={this.state.messageSnackbarOpen}
                        message={this.state.message}
                        autoHideDuration={5000}
                        onClose={this.snackbarClose}
                    />
                </div>);
        }
        return <></>
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.workflowRunService.updateCustomField("UsePcr", (event.target as HTMLInputElement).value);
    }

    @bind
    updateTempField(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.props.workflowRunService.updateCustomField("RoomTemperature", newValue);
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue) {
                this.props.workflowRunService.updateCustomField("RoomTemperature", newValue);
            }
        }
    }

    @bind
    private async moveToNextStep() {
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const cleaningDataState = this.props.workflowRunService.getState().cleaningDataState;
        const currentStep = this.props.workflowRunService.currentStep;

        if (pcrInfoState.data && cleaningDataState.data && currentStep) {

            let missingCleaning = missingData(cleaningDataState.data[0]);
            let customFields = currentStep.toJS().CustomFields;
            let usePcr = customFields["UsePcr"] === "true";

            if (usePcr) {
                if (pcrInfoState.data.InstrumentNameValue && !missingCleaning) {
                    //Continue to next step
                    if (this.state.canMove) {
                        await Promise.all([
                            this.props.workflowRunService.saveCleaningData(true),
                            this.props.workflowRunService.savePCRInfo(),
                            this.props.workflowRunService.updateCustomFields(),
                            this.props.workflowRunService.addInstrument(pcrInfoState.data.InstrumentNameValue),
                            this.props.workflowRunService.completeStep(),
                        ]);
                    }
                }
                else {
                    this.setState({ messageSnackbarOpen: true, message: "Lot numbers and ThermoCycler name are required" });
                }
            }
            else {
                //Run all QCs
                let failedQCs: QCCheckInstanceVM[] = [];
                let qcResults: QCCheckResultVM[] = [];
                _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                    switch (qc.QCCheckType) {
                        case CleanAfterLigationTemperatureMandatoryEntryCheck:
                            let tempMandPass = true;
                            if (s.isBlank(customFields["RoomTemperature"])) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                                tempMandPass = false;
                                this.setState({ tempNotFilled: true });
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? tempMandPass ? undefined : 1 : 2, //passed/modify/skipped
                                    MeasuredValue: s.isBlank(customFields["RoomTemperature"]) ? "None" : customFields["RoomTemperature"],
                                    Date: new Date(Date.now()),
                                    Pass: tempMandPass,
                                    QCCheckInstance: qc,
                                }
                            );
                            break;
                        case CleanAfterLigationTemperatureInRangeCheck:
                            let tempRangePass = true;
                            let temp = Number.parseInt(customFields["RoomTemperature"]);
                            if (!isNaN(temp)) {
                                let minTempParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MinTemperature")?.Value || "0")
                                let maxTempParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MaxTemperature")?.Value || "0")
                                if (temp < minTempParam || temp > maxTempParam) {
                                    if (qc.Enabled) {
                                        failedQCs.push(qc);
                                    }
                                    tempRangePass = false;
                                }
                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: qc.Enabled ? tempRangePass ? undefined : 0 : 2, //passed/executed/skipped
                                        MeasuredValue: customFields["RoomTemperature"],
                                        Date: new Date(Date.now()),
                                        Pass: tempRangePass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            }
                            break;
                    }
                })
                if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                    this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
                }
                else {
                    this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                    await this.completeMoveToNextStep();
                }
            }
        }
    }

    @bind
    private async completeMoveToNextStep() {
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const cleaningDataState = this.props.workflowRunService.getState().cleaningDataState;
        const currentStep = this.props.workflowRunService.currentStep;

        if (pcrInfoState.data && cleaningDataState.data && currentStep) {
            let missingCleaning = missingData(cleaningDataState.data[0]);
            if (!missingCleaning) {
                //Continue to next step
                if (this.state.canMove) {
                    await Promise.all([
                        this.props.workflowRunService.saveCleaningData(true),
                        this.props.workflowRunService.savePCRInfo(),
                        this.props.workflowRunService.updateCustomFields(),
                        this.props.workflowRunService.completeStep(),
                    ]);
                }
            }
            else {
                this.setState({ messageSnackbarOpen: true, message: "Lot numbers are required" });
            }
        }
    }
}