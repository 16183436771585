import {
  React,
} from "$Imports/Imports";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

import {
  Button
} from "$Imports/MaterialUIComponents";

import { getLogger } from "@yahara/logging";

const styles: {
  mainContainer: string;
  displayContainer: string;
} = require("./AuthTokenExpiringModal.scss");

interface IAuthTokenExpiringModalProps {
  refreshToken: () => void;
  logout: () => void;
}

interface IAuthTokenExpiringModalState {
  secondsLeft:number | undefined;
  startTime: number
}

/** Constants */
const timeoutLengthMs:number = 60000;
const intervalLengthMs:number = 1000;

export class AuthTokenExpiringModal extends React.Component<IAuthTokenExpiringModalProps, IAuthTokenExpiringModalState> {

  constructor(props: IAuthTokenExpiringModalProps) {
    super(props);
    this.state = {
      secondsLeft: undefined,
      startTime: (new Date()).getTime()
    }
  };

  private readonly _logger = getLogger("AuthTokenExpiringModal");

  /**
   * This is the length that we would display the modal before logging out. 
   * When this fires we log the user out.
   */
  timeout = setTimeout(() => {
    this._logger.warn("Auth expiring modal expired - logging user out")
    this.props.logout();
  } , timeoutLengthMs);
  
  /**
   * This interval is just for display purposes. Allows us to provide the countdown in the modal
   */
  interval = setInterval(() => {
    this.setState({secondsLeft: this.getSecondsLeft()});
  } , intervalLengthMs);

  /**
   * @returns the number of seconds left before the timeout timer fires
   */
  private getSecondsLeft() : number{
    return Math.floor((timeoutLengthMs - ((new Date()).getTime() - this.state.startTime))/1000);
  }

  /**
   * Event handler that fires when the user clicks to remain logged in
   */
  private keepLoggedIn():void{
    this._logger.info("User clicked to remain logged in - attempting to refresh token")
    this.props.refreshToken();
  }

  /**
   * Clear both the timer and interval before the component unmounts
   */
  async componentWillUnmount(): Promise<void> {
    this._logger.info("Auth expiring modal unmounting - clearing timeout and interval")
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  }

  render() {
    return (
      <>
          <ApplicationSecurityContext.Consumer>
            {value => (
              <div className={styles.mainContainer}>
                  <div className={styles.displayContainer}>
                    <h2>You've been inactive for a while</h2>
                    <p>You will be automatically logged out in {this.state.secondsLeft} seconds</p>
                    <Button 
                      variant="contained"
                      onClick={() => 
                        this.keepLoggedIn()
                      }>
                        Please keep me logged in!</Button>
                  </div>
              </div>
            )}
          </ApplicationSecurityContext.Consumer>
      </>
    );
  }
}