import {
    FreezerService,
    _,
    managedAjaxUtil,
    IAjaxState,
    bind
} from "$Imports/Imports";
import {
    BarcodeKitType,
    DocumentsApiFactory,
    DocumentsApiFetchParamCreator,
    HttpResponseMessage,
    MetadataTemplateFileVM,
    QubitSamplesVM,
    SampleApiFactory
} from "../generated/api";
import { apiExceptionHandler } from "./ErrorFreezerService";

const InjectedPropName = "FileService";

export interface IFileState {
    csvZipState: IAjaxState<string>;
    csvMetadataState: IAjaxState<string>;
    assetZipState: IAjaxState<string>;
    jsonSampleMetadataState: IAjaxState<string>;
    barcodeCsvState: IAjaxState<string>;
    qubitQuantificationZipState: IAjaxState<string>;
    fastaFileLocationState: IAjaxState<boolean>;
}

class FileFreezerService extends FreezerService<IFileState, typeof InjectedPropName> {
    constructor() {
        super({
            csvZipState: managedAjaxUtil.createInitialState(),
            csvMetadataState: managedAjaxUtil.createInitialState(),
            assetZipState: managedAjaxUtil.createInitialState(),
            jsonSampleMetadataState: managedAjaxUtil.createInitialState(),
            barcodeCsvState: managedAjaxUtil.createInitialState(),
            qubitQuantificationZipState: managedAjaxUtil.createInitialState(),
            fastaFileLocationState: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    //Get a template based on the name
    public async getCSVTemplateZip(templateName: string, forceUpdate?: boolean) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'csvZipState',
            onExecute: async (apiOptions, param, options) => {
                const factory = DocumentsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                let httpResponse = await factory.apiV1DocumentsCsvTemplatePost(param);
                let response = new Response(httpResponse.body);
                let blob = await response.blob();
                let blobUrl = URL.createObjectURL(blob);
                return blobUrl;
            },
            params: {
                body: templateName
            }
        });
    }

    public async getLabAssetTemplateZip(forceUpdate?: boolean) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'assetZipState',
            onExecute: async (apiOptions, param, options) => {
                let handbookArgs = DocumentsApiFetchParamCreator.apiV1DocumentsLabassetTemplateGet();
                let httpResponse = await apiOptions.wrappedFetch(apiOptions.baseUrl + '/api/v1/documents/labasset_template', handbookArgs);
                let response = new Response(httpResponse.body);
                let blob = await response.blob();
                let blobUrl = URL.createObjectURL(blob);
                return blobUrl;
            },
        });
    }

    public async getCSVMetadataTemplateZip(templateFiles: MetadataTemplateFileVM[], forceUpdate?: boolean) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'csvMetadataState',
            onExecute: async (apiOptions, param, options) => {
                const factory = DocumentsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                let httpResponse = await factory.apiV1DocumentsCsvMetadataTemplatePost(param);
                let response = new Response(httpResponse.body);
                let blob = await response.blob();
                let blobUrl = URL.createObjectURL(blob);
                return blobUrl;
            },
            params: {
                body: templateFiles
            }
        });
    }

    public async getJSONSampleMetadata(json: string, forceUpdate?: boolean) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'jsonSampleMetadataState',
            onExecute: async (apiOptions, param, options) => {
                const factory = DocumentsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                let httpResponse = await factory.apiV1DocumentsJsonMetadataPost(param);
                let response = new Response(httpResponse.body);
                let blob = await response.blob();
                let blobUrl = URL.createObjectURL(blob);
                return blobUrl;
            },
            params: {
                body: json
            }
        });
    }

    public async getQubitQuantificationTemplateZip(samples: QubitSamplesVM) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'qubitQuantificationZipState',
            onExecute: async (apiOptions, param, options) => {
                const factory = DocumentsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                let httpResponse = await factory.apiV1DocumentsQubitQuantificationCsvTemplatePost(param);
                let response = new Response(httpResponse.body);
                let blob = await response.blob();
                let blobUrl = URL.createObjectURL(blob);
                return blobUrl;
            },
            params: 
            {
                body: samples
            }
        });
    }

    public async AddFastaFileLocationToSampleMetadata(sampleId: string, fastaFileName: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'fastaFileLocationState',
            onExecute: (apiOptions, param, options) => {
                let factory = SampleApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SampleAddFastaFileLocationToSampleMetadataPost({ sampleId, fastaFileName });
            },
            onError: apiExceptionHandler
        });
    }

}

export const FileService = new FileFreezerService();
export type IFileServiceInjectedProps = ReturnType<FileFreezerService["getPropsForInjection"]>;