import { React, withRouter, ReactGA } from "$Imports/Imports";
import { NavigationService } from "$State/NavigationFreezerService";

class AppContainerComponent extends React.PureComponent<any> {

  componentDidMount(){
    this.props.history.listen((location: any, action: any) => {
      ReactGA.pageview(location.pathname);
    })
  }

  render() {
    NavigationService.initHistory(this.props.history);

    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

const AppContainer = withRouter(AppContainerComponent);
export { AppContainer };
