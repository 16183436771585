import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";


import
    * as s
    from "underscore.string";

import {
    CollapsibleSection,
    DataLoadingDisplay,
    StepActionsControl,
    StepChangeControl,
} from "$Imports/CommonComponents";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";

import { MinKnowService, IMinKnowServiceInjectedProps } from "$State/MinKnowFreezerService";
import { Button, KeyboardDatePicker } from "$Imports/MaterialUIComponents";


import {
    MinKnowConnectionInformationResponse
} from "$Generated/api";

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Snackbar from "@material-ui/core/Snackbar";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

type ISetupMinIONInstrumentScreenProps = IWorkflowScreenProps & IMinKnowServiceInjectedProps;

export class _SetupMinIONInstrumentScreen extends React.Component<ISetupMinIONInstrumentScreenProps> {

    async componentDidMount() {
        await this.checkConnection()
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentStep && currentWorkflowRun) {
            const customFields = currentStep.CustomFields;
            if (customFields) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                let date = new Date((customFields["dateStarted"] ? customFields["dateStarted"] : Date.now()));
                if (customFields["dateStarted"] === "") {
                    this.props.workflowRunService.updateCustomField("dateStarted", date.toString());
                }

                let connectionData = this.props.minKnowService.freezer.get().connectionResults.data;
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.mainDiv}>
                                <div>
                                    <div>
                                        <h2>
                                            Pre Run Check
                                        </h2>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    {GetWorkflowType() === "Anthrax"
                                                        ? <td> MinION Connected to Laptop </td>
                                                        : <td> MinIT Connected to Laptop </td>
                                                    }
                                                    <td>
                                                        {(connectionData && connectionData.ConnectedToLaptop) ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
                                                    </td>
                                                </tr>
                                                {GetWorkflowType() === "Anthrax"
                                                    ?<></>
                                                    :<tr>
                                                    <td>
                                                        MinION connected to MinIT
                                                    </td>
                                                    <td>
                                                        {(connectionData && connectionData.ConnectedToMinIT) ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
                                                    </td>
                                                </tr>
                                                }
                                                <tr>
                                                    <td>
                                                        Flow cell installed
                                                    </td>
                                                    <td>
                                                        {(connectionData && connectionData.FlowCellInstalled) ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Button disabled={disabled} variant="contained" color="primary" onClick={this.checkConnection}>
                                            Check Connection
                                        </Button>
                                    </div>
                                </div>
                                <div><KeyboardDatePicker
                                                        disabled={disabled}
                                                        label="Date Started"
                                                        value={date}
                                                        onChange={this.onDateChanged}
                                                        format="MM/DD/YY" />
                                </div>
                                <div>
                                    <h2>
                                        Instrument Info
                                    </h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    MinIT Name:
                                                </td>
                                                <td>
                                                    {customFields["MinITName"]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Flow Cell ID:
                                                </td>
                                                <td>
                                                    {customFields["FlowCellID"]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Flow Cell Product Code:
                                                </td>
                                                <td>
                                                    {customFields["FlowCellProductCode"]}
                                                </td>
                                            </tr>
                                            {GetWorkflowType() === "Anthrax"
                                            ? <></>
                                            :<tr>
                                                <td>
                                                    Disk Space on MinIT:
                                                </td>
                                                <td>
                                                    {customFields["MinITAvailableDiskSpace"]}
                                                </td>
                                            </tr>
                                            }
                                            <tr>
                                                <td>
                                                    Disk Space on Laptop:
                                                </td>
                                                <td>
                                                    {customFields["LaptopAvailableDiskSpace"]}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div className={commonStyles.footerDiv}>
                            <StepActionsControl step={currentStep} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                            <StepChangeControl disabled={disabled || (!connectionData) || !this.hasValidConnectionData(connectionData)} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                    </div>);
            }
        }
        return <DataLoadingDisplay />;
    }

    //Determines if all necessary connection information is present
    @bind
    private hasValidConnectionData(connectionData: MinKnowConnectionInformationResponse) {
        return (connectionData.ConnectedToLaptop &&
            connectionData.ConnectedToMinIT &&
            connectionData.FlowCellId.length > 0 &&
            connectionData.FlowCellInstalled &&
            connectionData.FlowCellProductCode.length > 0 &&
            connectionData.MinITName.length > 0 &&
            connectionData.SpaceOnLaptop.length > 0 &&
            connectionData.SpaceOnMinIT.length > 0)
    }

    @bind
    private async checkConnection() {
        await this.props.minKnowService.fetchConnectionStatus(true);
        let connectionData = this.props.minKnowService.freezer.get().connectionResults.data;
        if (connectionData) {
            //Set custom fields
            this.props.workflowRunService.updateCustomField("MinITName", connectionData.MinITName);
            this.props.workflowRunService.updateCustomField("FlowCellID", connectionData.FlowCellId);
            this.props.workflowRunService.updateCustomField("FlowCellProductCode", connectionData.FlowCellProductCode);
            this.props.workflowRunService.updateCustomField("MinITAvailableDiskSpace", connectionData.SpaceOnMinIT);
            this.props.workflowRunService.updateCustomField("LaptopAvailableDiskSpace", connectionData.SpaceOnLaptop);
        }
        this.setState({ hasCheckedConnection: true })
    }

    @bind
    private async moveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            await Promise.all([
                this.props.workflowRunService.updateCustomFields(),
                this.props.workflowRunService.completeStep()
            ]);
        }
    }

    @bind
    private onDateChanged(date: MaterialUiPickersDate, value: string | null | undefined) {

        this.props.workflowRunService.updateCustomField("dateStarted", value ? moment(value).format("MM-DD-YY, h:mm a") : "");
    }

}

export const SetupMinIONInstrumentScreen = MinKnowService.inject(_SetupMinIONInstrumentScreen);