import { ExchangeDetailsVM } from "$Generated/api";
import { DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Card, IconButton } from "$Imports/MaterialUIComponents";
import { GetAppIcon } from "$Imports/MaterialUIIcons";

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    divPadding: string;
    tablePadding: string;
} = require("./DataExchanges.scss");

import { SharingService, ISharingServiceInjectedProps } from "$State/SharingFreezerService";

interface IReceivedDataExchangesBaseProps {
}

interface IReceivedDataExchangesState {
}

type IReceivedDataExchangesProps = IReceivedDataExchangesBaseProps & ISharingServiceInjectedProps;

export class _ReceivedDataExchangesPage extends React.Component<IReceivedDataExchangesProps, IReceivedDataExchangesState> {

    state: IReceivedDataExchangesState = {
    }

    async componentDidMount() {
        await this.props.SharingService.getExchangeDetails(true);
    }

    private readonly columns: Array<IDataTableColumn<ExchangeDetailsVM>> = [
        {
            columnName: "download",
            columnFieldData: (d) => {
                const key = JSON.parse(d.JsonPayload)["s3KeyName"];
                const fileType = JSON.parse(d.JsonPayload)["fileType"];
                if (key) {
                    return <div style={{ margin: "auto" }}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                this.downloadData(key, fileType)
                            }}>
                            <GetAppIcon />
                        </IconButton>
                    </div>;
                }
                return <></>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Download",
        },
        {
            columnName: "date",
            columnFieldData: (d) => {
                if(d.ExchangeDate)
                {
                return moment(d.ExchangeDate).format("MM-DD-YY, h:mm a");
                }
                return "";
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Date",
            sortMethod: (d) => d.ExchangeDate ?? ""
        },
        {
            columnName: "sending-partner",
            columnFieldData: (d) => {
                return d.SendingOrganizationName;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sending Partner",
            sortMethod: (d) => d.SendingOrganizationName ?? ""
        },
        {
            columnName: "data-category",
            columnFieldData: (d) => {
                return d.DataCategory;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Data Category",
            sortMethod: (d) => d.DataCategory ?? ""
        },
        {
            columnName: "data-description",
            columnFieldData: (d) => {
                return JSON.parse(d.JsonPayload)["s3KeyName"];
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Data Description",
            sortMethod: (d) => d.DataCategory ?? ""
        },
        {
            columnName: "exchange-status",
            columnFieldData: (d) => {
                return d.DeliveryStatus === 5 ? "Delivery Failed" : "Delivery Successful";
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Exchange Status",
        },
    ];

    render() {
        const data = this.props.SharingService.getState().exchangeDetailsResult.data || [];

        return <div className={styles.mainContainer}>
            <Card
                className={styles.cardStyle}
            >
                <div className={styles.divPadding}>
                    <h2>Received Data Exchanges</h2>
                    <i>A complete list of Partner Collaboration Engine data exchanges shared with this site is below.</i>
                </div>
                <div className={styles.tablePadding}>
                    <DataTable
                        data={data}
                        columns={this.columns}
                        defaultSortColumnName="date"
                        defaultSortDirection="desc"
                    />
                </div>
            </Card>
        </div>
    }

    @bind
    public async downloadData(key: string, fileType: string) {
        await this.props.SharingService.getS3File(key);
        const saveDocument = document.createElement('a');
        //Type this properly
        saveDocument.href = this.props.SharingService.getState().fileResult.data || "";
        saveDocument.target = '_blank';
        saveDocument.download = `${key}` + `${fileType}`;
        saveDocument.click();
    }
}

export const ReceivedDataExchangesPage = SharingService.inject(_ReceivedDataExchangesPage);