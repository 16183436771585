import { NgsReportBioinformaticsPipelineAnalysisDetailsVM } from "$Generated/api";
import {
    React,
    _,
} from "$Imports/Imports";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";


import {
    BioInformaticsPipelineFiles,
} from "./ReportComponents/BioInformaticsPipelineFiles";

const styles: {
    analysisTableHeader: string;
    mainReport: string;
    pageLink: string;
} = require("./WetLabReport.scss");

const commonStyles: {
    table: string;
} = require("../../../css/common.scss");

interface IWetLabSequencingRunNotesProps {
    runNumber: string;
    runTag: string;
    workflowName: string;
    bioinformaticsInformation: NgsReportBioinformaticsPipelineAnalysisDetailsVM | undefined;
};

export class BioInformaticsPipelineAnalysisDetails extends React.Component<IWetLabSequencingRunNotesProps> {

    async componentDidMount() {

    }

    render() {

        var bioinformaticsHeaders = ["Bioinformatics Tools", "Version"];
        var referenceHeaders = ["Reference Datasets", "Version"];
        return (

            <div>

                <h2>Bioinformatics Pipeline Analysis Details</h2>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    {this.props.workflowName !== "Influenza" && <div style={{width:"50%", margin:"10px"}}>
                        <h3>Pipeline Run Date: {this.props.bioinformaticsInformation?.PipelineRunDate}</h3>
                        <TableContainer component={Paper} style={{height:"500px", overflowY:"auto"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {bioinformaticsHeaders.map((h, ind) => (
                                            <TableCell key={ind} style={{ fontWeight: 'bold' }}>{h}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.sortBy(this.props.bioinformaticsInformation?.BioinformaticTools, a => a.Item1?.toLowerCase()).map((a, ind) => (
                                        <TableRow key={ind}>
                                            <TableCell>{a.Item1}</TableCell>
                                            <TableCell>{a.Item2}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>}
                    <div style={{width:"50%", margin:"10px"}}>
                        {this.props.workflowName !== "Influenza" && <div>
                            <h3>Pipeline Version: {this.props.bioinformaticsInformation?.PipelineVersion}</h3>
                            <TableContainer component={Paper} >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {referenceHeaders.map((h, ind) => (
                                                <TableCell key={ind} style={{ fontWeight: 'bold' }}>{h}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.bioinformaticsInformation?.ReferenceDatasets?.map((a, ind) => (
                                            <TableRow key={ind}>
                                                <TableCell>{a.Item1}</TableCell>
                                                <TableCell>{a.Item2}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>}
                        <BioInformaticsPipelineFiles
                            runNumber={this.props.runNumber}
                            runTag={this.props.runTag}
                            workflowName={this.props.workflowName}
                            bioinformaticsInformation={this.props.bioinformaticsInformation}
                        />
                    </div>
                </div>

            </div>

        )

    }


}
