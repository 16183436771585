import {
    React,
    _,
    bind,
    ReactGA
} from "$Imports/Imports";

var urljoin = require('url-join');

const styles: {
    searchDiv: string;
    searchBox: string;
    searchButton: string;
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
} = require("./WorkflowsQCSummary.scss");

import { NavigationService } from "$State/NavigationFreezerService";

import { QCService, IQCServiceInjectedProps } from "$State/QCFreezerService";
import {
    Card,
    Button,
    TableRow,
    Dialog,
    Link,
    TextField
} from "$Imports/MaterialUIComponents";
import { DataTable, IDataTableColumn, ProcessAdminRole } from "$Imports/CommonComponents";
import { WorkflowQCSummaryVM } from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

interface IWorkflowsQCSummaryPageBaseProps {
}

interface IWorkflowsQCSummaryState {
    workflowNameSearch: string;
}

type IWorkflowsQCSummaryPageProps = IWorkflowsQCSummaryPageBaseProps & IQCServiceInjectedProps;

export class _WorkflowsQCSummaryPage extends React.Component<IWorkflowsQCSummaryPageProps, IWorkflowsQCSummaryState> {

    state: IWorkflowsQCSummaryState = {
        workflowNameSearch: ""
    }


    private readonly columns: Array<IDataTableColumn<WorkflowQCSummaryVM>> = [
        {
            columnName: "workflow-name",
            columnFieldData: "WorkflowName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Workflow",
            sortMethod: (d) => d.WorkflowName ?? ""
        },
        {
            columnName: "number-qc",
            columnFieldData: "NumberOfQC",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "# of QCs",
            sortMethod: (d) => d.NumberOfQC ?? ""
        },
        {
            columnName: "number-qc-enabled",
            columnFieldData: "NumberOfQCEnabled",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "# of QCs enabled",
            sortMethod: (d) => d.NumberOfQCEnabled ?? ""
        },
        {
            columnName: "number-qc-disabled",
            columnFieldData: "NumberOfQCDisabled",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "# of QCs disabled",
            sortMethod: (d) => d.NumberOfQCDisabled ?? ""
        },
        {
            columnName: "edit",
            columnFieldData: (d) => {
                return <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.editWorkflow(d.WorkflowId, d.WorkflowName); }}>
                            {value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ? "View" : "Edit"}
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        }
    ]

    componentDidMount() {
        this.loadData();
    }

    render() {
        const data = this.props.QCService.getState().fetchQCSummariesResults.data;

        return <div
            className={styles.mainContainer}
        >
            <Card
                className={styles.cardStyle}
            >
                <div>
                    <h3>Workflow QC</h3>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className={styles.searchDiv}>
                            <TextField className={styles.searchBox} label="Workflow Name" value={this.state.workflowNameSearch} onChange={(event) => { this.setState({ workflowNameSearch: event.target.value }) }} />
                        </div>
                        <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.loadData(); }}>
                            Search
                        </Button>
                    </div>
                    {data && <DataTable
                        data={data}
                        columns={this.columns}
                        stickyHeader={true}
                    />}
                </div>
            </Card>
        </div>;
    }

    @bind
    private async loadData() {
        await this.props.QCService.fetchQCSummaries(this.state.workflowNameSearch);
    }

    @bind
    private async editWorkflow(workflowId: string, workflowName: string) {
        NavigationService.navigateTo(urljoin("/WorkflowQCChecks", workflowId, "name", workflowName));
    }
}

export const WorkflowsQCSummaryPage = QCService.inject(_WorkflowsQCSummaryPage);