import {
    React,
    _,
    bind,
    moment,
    ReactGA
} from "$Imports/Imports";

import { CollapsibleSection } from "$Imports/CommonComponents";

import { Button, Card, Checkbox, Dialog } from "$Imports/MaterialUIComponents";

import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";

import { ArrowDropDownIcon, ArrowRightIcon } from "$Imports/MaterialUIIcons";
import { LaptopWindows } from "@material-ui/icons";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    siteButton: string;
    dialog: string;
    button: string;
    buttonsContainer: string;
    resetProgressContainer: string;
    resetProgressBar: string;
    loadingIcon: string;
    welcomePageTextSection: string;
} = require("./Home.scss");


interface IDemoWelcomePageBaseProps {
}

interface IDemoWelcomePageState {
    feedbackChecked: boolean;
    popupOpen: boolean;
    resetProgress: string;
    errorMsg: string;
    isResetting: boolean;
    resetFailed: boolean;
    resetSucceeded: boolean;
    showProgress: boolean;
    expandAdministrativeFunctionality: boolean;
    expandWetLabFunctionality: boolean;
    expandDryLabFunctionality: boolean;
}

type IDemoWelcomePageProps = IDemoWelcomePageBaseProps & ISettingsServiceInjectedProps;

const ConfluenceView = require("../../images/Welcome-Page-Confluence-View.png");
const MIAView = require("../../images/Welcome-Page-MIA-View.png");

export class _DemoWelcomePage extends React.Component<IDemoWelcomePageProps, IDemoWelcomePageState> {

    state: IDemoWelcomePageState =
        {
            feedbackChecked: false,
            popupOpen: false,
            resetProgress: "0",
            errorMsg: "",
            isResetting: false,
            resetFailed: false,
            showProgress: false,
            resetSucceeded: false,
            expandAdministrativeFunctionality: false,
            expandWetLabFunctionality: false,
            expandDryLabFunctionality: false
        };

    async componentDidMount() {
        await this.props.settingsService.fetchUserPreferences();
        await this.props.settingsService.fetchDemoEnvironmentInformation();
        const prefs = this.props.settingsService.getState().userPreferenceResults.data;
        if (prefs) {
            this.setState({
                feedbackChecked: !!prefs.GiveFeedback
            });
        }
    }

    render() {
        return (
            <div
                className={styles.mainContainer}
            >
                <Card
                    className={styles.cardStyle}
                >
                    <div className={styles.content}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div style={{ paddingRight: ".5em" }}>
                                <h1 style={{ textAlign: "center" }}>LIMS Lite Sandbox Welcome Page</h1>
                                <p className={styles.welcomePageTextSection}>Welcome to the LIMS Lite Sandbox Environment, where users can experience the LIMS Lite platform!  The Sandbox Environment will be available to your team for 30 days, as denoted in the countdown marker in the top right of this screen.  Users will be able to utilize full administrative functionality of the LIMS Lite platform and customize quality controls and laboratory assets.  At the end of each session, you will be asked to provide feedback.</p>
                                <p className={styles.welcomePageTextSection}>Let’s begin!  Learn how to use different functions by following the links below to the LIMS Lite Confluence Page.</p>
                                <CollapsibleSection sectionHeader={"Administrative Functionality: Configure Your LIMS Lite Platform"}>
                                    <ul>
                                        <li>Add lot numbers of different <a href="https://limslitejira.atlassian.net/wiki/spaces/LLC/pages/66748429/Lab+Partners+Interchange+Controller+LPIC#Lab-Assets" target="_blank">Lab Assets</a>, such as reagents and kits, to be used during your experiment.</li>
                                        <li>Enable and disable components of LIMS Lite to audit through <a href="https://limslitejira.atlassian.net/wiki/spaces/LLC/pages/66748429/Lab+Partners+Interchange+Controller+LPIC#Access-Audit-Records-(Administrators-only)" target="_blank">Audit Records.</a></li>
                                        <li>Search for Audit Record and filter by Audit Paraments and Workflow Run Parameters through <a href="https://limslitejira.atlassian.net/wiki/spaces/LLC/pages/66748429/Lab+Partners+Interchange+Controller+LPIC#Audit-Logs-(Administrators-only)" target="_blank">Audit Logs.</a></li>
                                        <li>Edit which <a href="https://limslitejira.atlassian.net/wiki/spaces/LLC/pages/66748429/Lab+Partners+Interchange+Controller+LPIC#Quality-Controls-(QCs)" target="_blank">Quality Controls</a> are enabled for each workflow through the QC module.  Learn about the QC Failure Actions you can expect to see in the Workflow Engine.</li>
                                    </ul>
                                </CollapsibleSection>
                                <CollapsibleSection sectionHeader={"Wet-lab Functionality: Start Your Online Experiment in the Workflow Engine"}>
                                    <div>
                                        <p className={styles.welcomePageTextSection}>The Sandbox Environment already has specimen data loaded into the system, so instead of accessioning your samples through a LIMS or .csv upload, users can begin starting a run at the Workflow Engine.  Follow these steps for a successful practice run:</p>
                                        <ol>
                                            <li>Get familiar with the screen functions during a run in <a href="https://limslitejira.atlassian.net/wiki/spaces/LLC/pages/83558407/Laboratory+Workflow+Engine" target="_blank">the Workflow Engine</a>.  Learn about how to familiarize yourself with the QC Check Failure Actions, which can occur during a run in order to preserve experimental integrity.</li>
                                            <li>
                                                <div>
                                                    <a href="https://limslitejira.atlassian.net/wiki/spaces/LLC/pages/83394562/Start+a+Run" target="_blank">Start a run</a> in the Workflow Engine.
                                                    <ul>
                                                        <li>Follow the Confluence page articles in sequential order according to the site map, indicated below, for the user guides of each workflow step.</li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <img src={ConfluenceView} />
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </CollapsibleSection>
                                <CollapsibleSection sectionHeader={"Dry-lab Functionality: Visualize the Bioinformatics Pipeline Processing Your Data in MIA"}>
                                    <div>
                                        <ol>
                                            <li><a href="https://limslitejira.atlassian.net/wiki/spaces/LLC/pages/1506771005/Sequencing+Progress+Completion+of+a+Pipeline+Run+All+Users" target="_blank">Review the progress of your pipeline run</a> in the Workflow Engine.</li>
                                            <li>
                                                <div>
                                                    Select your Run Number in MIA to review live data of your pipeline run.
                                                </div>
                                                <div>
                                                    <img src={MIAView} />
                                                </div>
                                            </li>
                                            <li><a href="https://limslitejira.atlassian.net/wiki/spaces/LLC/pages/1506836517/See+Results+on+the+Genomics+Data+Page+All+Users" target="_blank">Review results in the Genomics Data page</a> when the pipeline run is finished.</li>
                                        </ol>
                                    </div>
                                </CollapsibleSection>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ paddingTop: "10px" }}>
                                        Are you willing to give feedback about your experience upon completion?:
                                    </div>
                                    <Checkbox checked={this.state.feedbackChecked}
                                        onChange={(event) => {
                                            this.setState({ feedbackChecked: event.target.checked });
                                        }}
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.save}
                                >
                                    Save
                                </Button>
                            </div>
                            <div>
                                <div>Demo Time Remaining: {this.props.settingsService.getState().demoEnvironmentInformation.data?.RemainingTime || ""}</div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.openResetDialog}
                                >
                                    Reset Environment
                                </Button>
                            </div>

                        </div>
                    </div>
                </Card>
                <Dialog
                    className={styles.dialog}
                    open={this.state.popupOpen}
                    onClose={this.closeResetDialog}
                >
                    <div className={styles.content}>
                        <div>
                            {this.state.resetSucceeded ? <div>Site reset succeeded. Refreshing page.</div> : (this.state.isResetting ? <div>The site is being shut down. Please do not navigate away or refresh the page.</div> :
                                (this.state.resetFailed ? <div>The reset was unsuccessful. The site may not function as expected. Click this link to <a href="" onClick={this.handleFailedReset}>contact support</a>. You will be notified when the issue is resolved.</div> :
                                    <div>This will make the site unresponsive. The site will be shut down. Please allow up to 5 minutes for the process to complete.</div>))}
                        </div>
                        <div style={{ color: "red" }}>
                            {this.state.errorMsg}
                        </div>
                        <div className={styles.resetProgressContainer} style={{ visibility: this.state.showProgress ? "visible" : "hidden" }}>
                            <div className={styles.resetProgressBar} style={{ width: this.state.resetProgress }}></div>
                        </div>
                        <div className={styles.buttonsContainer}>
                            <Button variant="contained" className={styles.button} onClick={this.resetEnvironment} disabled={this.state.isResetting}>
                                Reset
                            </Button>
                            <Button variant="contained" className={styles.button} onClick={this.closeResetDialog} disabled={this.state.isResetting}>
                                Cancel
                            </Button>
                        </div>
                    </div>

                </Dialog>
            </div>
        );
    }

    @bind
    private async save() {
        //Do other settings options
        await this.props.settingsService.setUserPreferences({
            GiveFeedback: this.state.feedbackChecked
        });
        await this.props.settingsService.fetchUserPreferences(true);
        const prefs = this.props.settingsService.getState().userPreferenceResults.data;
        if (prefs) {
            this.setState({
                feedbackChecked: !!prefs.GiveFeedback
            });
        }
    }

    @bind
    private async handleFailedReset() {
        const feedbackUrl = SettingsService.getState().demoEnvironmentInformation.data?.FeedbackUrl || "";
        window.open(feedbackUrl, "_blank");
        window.close();
    }

    @bind
    private async resetEnvironment() {
        this.setState({ isResetting: true, showProgress: true });

        try {
            await this.props.settingsService.resetEnvironment();
            var results = this.props.settingsService.getState().resetEnvironmentResults.data;
            var pollToken = results?.PollToken;
            // Check that poll token was returned
            if (!pollToken || pollToken?.length == 0) {
                this.setState({ isResetting: false, resetFailed: true, showProgress: false });
                return;
            }

            this.setState({ resetProgress: "10%" });
            var progress = 10;
            var secondsLoading = 0;
            // Poll service every 10 seconds
            var intervalId = setInterval(async () => {
                secondsLoading += 10;
                // Handle if running for >= 5 minutes
                if (secondsLoading >= 5 * 60) {
                    this.setState({ isResetting: false, resetFailed: true, showProgress: false });
                    clearInterval(intervalId);
                }

                await this.props.settingsService.pollResetStatus(pollToken ?? "");
                var pollResult = this.props.settingsService.getState().pollResetStatus.data;

                // Check for success
                if (pollResult?.Success) {
                    clearInterval(intervalId);
                    this.setState({ isResetting: false, resetSucceeded: true, resetProgress: "100%" });
                    setTimeout(() => {
                        window.location.reload();
                    }, 5 * 1000);
                }
                progress = progress < 50 ? progress + 2 : 50;
                this.setState({ resetProgress: progress.toString() + "%" });
            }, 10 * 1000)
        } catch (err) {
            this.setState({ errorMsg: "Failed to reset demo environment", isResetting: false, showProgress: false })
        }
    }

    @bind
    private openResetDialog() {
        this.setState({ popupOpen: true, isResetting: false, showProgress: false, errorMsg: "", resetProgress: "0%" });
    }

    @bind
    private closeResetDialog() {
        this.setState({ popupOpen: false, isResetting: false, showProgress: false, errorMsg: "", resetProgress: "0%" });
    }
}

export const DemoWelcomePage = SettingsService.inject(_DemoWelcomePage);