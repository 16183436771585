import { React } from "$Imports/Imports";

interface IPlateHeaderProps {
    header: string;
}

const styles: {
    plateHeader: string;
} = require("./StandardPlateStyles.scss");

export class PlateHeader extends React.PureComponent<IPlateHeaderProps> {

    render() {
        const { header } = this.props;

        return (
            <h2 className={styles.plateHeader}>
                {header}
            </h2>
        );
    }
}