import { C3Chart, DataLoadingDisplay } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    C3
} from "$Imports/Imports";
import { Card } from "$Imports/MaterialUIComponents";


import { IDashboardServiceInjectedProps, DashboardService } from "$State/DashboardFreezerService";

interface IProductivityBenchmarkBaseProps {
    id: string;
    width: number;
}

const styles: {
    cardStyle: string;
    headerStyle: string;
} = require("./WidgetCommon.scss");

type IProductivityBenchmarkProps = IDashboardServiceInjectedProps & IProductivityBenchmarkBaseProps;

class _ProductivityBenchmark extends React.Component<IProductivityBenchmarkProps> {

    render() {
        var data = this.props.dashboardService.getState().productivityBenchmarkFetchResults.data;

        if (data) {

            let sortedData = _.sortBy(data, d => d.Day);
            let days = _.map(sortedData, s => s.Day);
            let todaysMean = _.map(sortedData, s => s.TodaysMeanTime);
            let goal = _.map(sortedData, s => s.Goal);
            let rollingAvg = _.map(sortedData, s => s.RollingAverage);

            return <Card
                className={styles.cardStyle}
                style={{width: this.props.width + "%"}}
            >
                <h3 className={styles.headerStyle}>
                    Productivity Benchmark
                </h3>
                <C3Chart
                    data={
                        {
                            x: 'Day',
                            columns: [
                                ['Day', ...days],
                                ['Todays Mean Time Elapsed per Run', ...todaysMean],
                                ['Goal', ...goal],
                                ['Rolling Average for the Last 100 Runs', ...rollingAvg],
                            ],
                            type: 'bar',
                            types: {
                                Goal: 'line',
                                'Rolling Average for the Last 100 Runs': 'line'
                            },

                        }}
                    axis={{
                        y: {
                            label: {
                                text: 'Mean Time Elapsed Per Run (Minutes)',
                                position: 'outer-middle'
                            }
                        },
                        x: {
                            label: {
                                text: 'Day',
                                position: 'outer-center'
                            },
                        }
                    }}
                    size={{ height: 350 }}
                    id={this.props.id}
                    colors={["#688da7", "#d95d39", "#68727b"]}
                    bar={{
                        label: {
                            format: function (value: any, ratio: any, id: any) {
                                return ""; //We don't want to display anything as the label
                            }
                        },
                    }}
                />
            </Card>;
        }
        return <DataLoadingDisplay />;
    }
}

export const ProductivityBenchmark = DashboardService.inject(_ProductivityBenchmark);