import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { GetWorkflowType, IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, PCRControl, CollapsibleSection } from "$Imports/CommonComponents";
import Snackbar from "@material-ui/core/Snackbar";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";
import { PlateVM } from "$Generated/api";

const styles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");

export interface ITwoMasterMixPCRState {
    messageSnackbarOpen: boolean;
    message: string;
    canMove: boolean;
}

export class TwoMasterMixPCRStepScreen extends React.Component<IWorkflowScreenProps, ITwoMasterMixPCRState> {

    state: ITwoMasterMixPCRState = { messageSnackbarOpen: false, message: "", canMove: true };
    componentDidMount() {
        this.props.workflowRunService.fetchPCRForStep(true);
        this.props.workflowRunService.fetchMasterMixForStep(true);
        if (GetWorkflowType() === "Anthrax"){
            this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            this.props.workflowRunService.fetchPlatesForStep(true);
        }
        
        this.props.workflowRunService.fetchInstrumentOptions();
        ReactGA.pageview("/WorkflowRun/amplification");
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const plates = this.props.workflowRunService.getState().plates;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        if (currentWorkflowRun && currentStep && pcrInfoState.data && masterMixInfoState.data && plates.data && instrumentOptions && lotOptions) {
            const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
            let masterMixA = _.find(masterMixInfoState.data, mix => (mix.DisplayName === 'Tiled Amplification Mix - A'));
            let masterMixB = _.find(masterMixInfoState.data, mix => (mix.DisplayName === 'Tiled Amplification Mix - B'));

            if (inputPlate && masterMixA && masterMixB) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");

                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={styles.collapsibleDiv}>
                                <div className={styles.mainDiv}>
                                    <div>
                                        <MasterMixControl
                                            disabled={disabled}
                                            masterMix={masterMixA}
                                            sampleCount={inputPlate.WellContents.length}
                                            updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                            lotOptions={lotOptions}
                                            setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                            index={0}
                                        />
                                        <PCRControl disabled={disabled} controlInfo={pcrInfoState.data} updateInstrumentName={this.updatePCR} instrumentOptions={instrumentOptions} />
                                    </div>
                                    <div>
                                        <MasterMixControl
                                            disabled={disabled}
                                            masterMix={masterMixB}
                                            sampleCount={inputPlate.WellContents.length}
                                            updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                            lotOptions={lotOptions}
                                            setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                            index={1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div className={styles.footerDiv}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} hideArchiveRetain={inputPlate.WellContents[0].RunCount >= currentWorkflowRun.WorkflowMaxSampleRun} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.message}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>);
            }
        }
        return <></>
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind updatePCR(val:string) {
        this.props.workflowRunService.updatePCRName(val);
        this.props.workflowRunService.updateCustomField("ThermoCycler", val)
    }

    @bind
    private async moveToNextStep() {
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        const currentStep = this.props.workflowRunService.currentStep;

        if (pcrInfoState.data && masterMixState.data && currentStep) {
            let missingMasterMixData = missingData(masterMixState.data[0]);
            if (currentStep.OutputAssets) {
                let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName)
                if (pcrInfoState.data.InstrumentNameValue && !missingMasterMixData && outputAsset && outputAsset.PlateSummary) {
                    //Continue to next step
                    if (this.state.canMove) {
                        await Promise.all([
                            this.props.workflowRunService.updateCustomFields(),
                            this.props.workflowRunService.saveMasterMix(true),
                            this.props.workflowRunService.savePCRInfo(),
                            this.props.workflowRunService.addInstrument(pcrInfoState.data.InstrumentNameValue),
                            this.props.workflowRunService.completeStep(),
                        ]);
                    }
                }
                else {
                    this.setState({ messageSnackbarOpen: true, message: "Lot numbers and ThermoCycler name are required" });
                }
            }
        }
    }
}