import { ChunkedUploadVM, DirectoryListingVM, FilesAndDirectoriesVM, FileShareApiFactory } from "$Generated/api";
import {
    FreezerService, IAjaxState, managedAjaxUtil
} from "$Imports/Imports";

const InjectedPropName = "FileSharingService";

export interface IFileSharingFreezerState {
    directoryListingResult: IAjaxState<FilesAndDirectoriesVM>;
    currentFiles: IAjaxState<DirectoryListingVM[]>;
    currentDirectories: IAjaxState<DirectoryListingVM[]>;
    deleteFileOrDirectoryResult: IAjaxState<number>;
    shareFileResult: IAjaxState<boolean>;
    unshareFileResult: IAjaxState<boolean>;
    initializeUploadResult: IAjaxState<ChunkedUploadVM>;
}


class FileSharingFreezerService extends FreezerService<IFileSharingFreezerState, typeof InjectedPropName> {
    constructor() {
        super({
            directoryListingResult: managedAjaxUtil.createInitialState(),
            currentFiles: managedAjaxUtil.createInitialState(),
            currentDirectories: managedAjaxUtil.createInitialState(),
            deleteFileOrDirectoryResult: managedAjaxUtil.createInitialState(),
            shareFileResult: managedAjaxUtil.createInitialState(),
            unshareFileResult: managedAjaxUtil.createInitialState(),
            initializeUploadResult: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    // Get directories and files at the root of the filesystem
    public async fetchRootDirectoryListing(forceUpdate?: boolean) {
        const { directoryListingResult } = this.freezer.get();

        if (directoryListingResult.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "directoryListingResult",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareGetRootFilesAndDirectoriesGet()
            }
        })
        return "";
    }

    // Get files at the root
    public async fetchRootFiles() {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "currentFiles",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareGetRootFilesGet();
            }
        });
    }

    // Get files at a given path
    public async fetchFiles(path: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "currentFiles",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareGetFilesPathGet({ path: path });
            }
        });
    }

    // Get directories at a given path
    public async fetchDirectories(path: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "currentDirectories",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareGetDirectoriesPathGet({ path: path });
            }
        });
    }

    // Delete directory or file at a given path
    public async deleteFileOrDirectory(path: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "deleteFileOrDirectoryResult",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareDeleteFileOrDirectoryPathDelete({ path: path });
            }
        });
    }

    public async initializeChunkedUpload(path: string, chunks: number, chunkSize: number, name: string, size: number, fileType: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "initializeUploadResult",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareInitializeChunkedUploadPathPost({
                    path: path, body:
                    {
                        Chunks: chunks,
                        ChunkSize: chunkSize,
                        Name: name,
                        Size: size,
                        Type: fileType
                    }
                });
            }
        });
    }

    public async shareFile(path: string, instanceId: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "shareFileResult",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareSharePathPut({
                    path: path, body:
                    {
                        InstanceId: instanceId
                    }
                });
            }
        });
    }

    public async unshareFile(path: string, instanceId: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "unshareFileResult",
            onExecute: (apiOptions, param, options) => {
                const factory = FileShareApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1FileShareSharePathDelete({
                    path: path, body:
                    {
                        InstanceId: instanceId
                    }
                });
            }
        });
    }
}

export const FileSharingService = new FileSharingFreezerService();
export type IFileSharingServiceInjectedProps = ReturnType<FileSharingFreezerService["getPropsForInjection"]>;
export const FileSharingAPI: string = "/api/v1/FileShare/";