import {
    React,
} from "$Imports/Imports";

import {
    Button
} from "$Imports/MaterialUIComponents";

const styles: {
    stepChangeControlContainerDiv: string;
    stepButton: string;
    lowerDiv: string;
    pauseButton: string;
    stopButtonFull: string;
    stopButtonFullDisabled: string;
    stopButtonHalf: string;
    stopButtonHalfDisabled: string;
} = require("./StepChangeControl.scss");


//Functions can be updated as their functionality is built out
export interface IStepChangeControlProps {
    showPause: boolean;
    nextStep: string;
    moveToNextStep: () => void;
    pauseRun?: () => void;
    failRun: () => void;
    disabled: boolean
}

export class StepChangeControl extends React.PureComponent<IStepChangeControlProps> {
    render() {

        return (
            <div className={styles.stepChangeControlContainerDiv}>
                <Button disabled={this.props.disabled} variant="contained" color="primary" className={styles.stepButton} onClick={this.props.moveToNextStep}>
                    {this.props.nextStep}
                </Button>
                <div className={styles.lowerDiv}>
                    {this.props.showPause && <Button disabled={this.props.disabled} variant="contained" className={styles.pauseButton} onClick={this.props.pauseRun}>
                        Pause Run
                    </Button>}
                    <Button disabled={this.props.disabled} variant="contained" className={this.props.showPause ? (this.props.disabled ? styles.stopButtonHalfDisabled : styles.stopButtonHalf) : (this.props.disabled ? styles.stopButtonFullDisabled : styles.stopButtonFull)} onClick={this.props.failRun}>
                        Fail Run
                    </Button>
                </div>
            </div>
        );
    }
}
