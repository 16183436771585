import {
    React,
    bind,
    _,
    moment
} from "$Imports/Imports";

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";
import { CollapsibleSection, DataLoadingDisplay, MasterMixControl, StepActionsControl, StepChangeControl } from "$Imports/CommonComponents";
import { Button, KeyboardDatePicker, Snackbar } from "$Imports/MaterialUIComponents";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import * as s from "underscore.string";

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

interface SetupIlluminaInstrumentScreenState {
    messageSnackbarOpen: boolean,
    canMove: boolean
}


export class SetupIlluminaInstrumentStepScreen extends React.Component<IWorkflowScreenProps, SetupIlluminaInstrumentScreenState> {

    state: SetupIlluminaInstrumentScreenState = {
        messageSnackbarOpen: false,
        canMove: true
    };

    async componentDidMount() {
        await this.props.workflowRunService.fetchSimplifiedReagentListForStep(true);
        this.props.workflowRunService.fetchStepInstanceCustomFields();
    }

    @bind
    private async completeMoveToNextStep() {

        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            const customFields = currentStep.CustomFields;

            let fieldWithMissingData = _.find(customFields, c => s.isBlank(c));

            if (this.state.canMove && fieldWithMissingData === undefined) { //Can't use !fieldWithMissingData because that !"" = true.
                await Promise.all([
                    this.props.workflowRunService.updateCustomFields(),
                ]);
                await this.props.workflowRunService.completeStep(); //Needs to be outside due to a race condition
            }
            else {
                this.setState({ messageSnackbarOpen: true });
            }
        }
    }

    @bind
    private async completeWorkflow() {
        const currentStep = this.props.workflowRunService.currentStep;
        //Custom fields needs to be updated first, complete workflow does a refresh of state
        await Promise.all([
            this.props.workflowRunService.updateCustomFields()
        ]);
        if (currentStep) {
          await this.props.workflowRunService.completeWorkflow(false);
        }
    }

    render() {
        let {
            currentStep,
            currentWorkflowRun,
        } = this.props.workflowRunService;

        let {
            masterMixInfoState,
        } = this.props.workflowRunService.getState();

        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;

        if (currentStep && currentWorkflowRun && lotOptions && masterMixInfoState.data) {
            const customFields = currentStep.CustomFields;
            if (customFields) {
                let date = new Date((customFields["SequencerStartDate"] !== "" ? customFields["SequencerStartDate"] : Date.now()));
                if (customFields["SequencerStartDate"] === "") {
                    this.props.workflowRunService.updateCustomField("SequencerStartDate", moment(date).format("MM-DD-YY, h:mm a"));
                }
                const disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={commonStyles.mainDiv}>
                            <div>
                                <MasterMixControl
                                    disabled={disabled}
                                    index={0}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={0}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { }}
                                    hideHeaders
                                />
                            </div>
                            <div>
                                <KeyboardDatePicker
                                    disabled={disabled}
                                    label="Sequencer Start Date"
                                    value={date}
                                    onChange={this.onDateChanged}
                                    format="MM/DD/YY" />
                                <div style={{ marginTop: "20px" }}>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        this.sampleSheet();
                                    }}>
                                        Download Sample Sheet
                                    </Button>
                                    <div>
                                        Once you push the Download Sample Sheet button, a properly formatted file will be saved to your downloads folder.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CollapsibleSection>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                            <StepChangeControl disabled={disabled} nextStep={"Finish Wet Lab Workflow"} showPause={false} moveToNextStep={(GetWorkflowType() === "SarsCov2") ? this.completeWorkflow : this.completeMoveToNextStep } failRun={this.props.failRun} />
                        </div>
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={this.state.messageSnackbarOpen}
                        message={"Reagent information and Sequencer Start Date is required"}
                        autoHideDuration={5000}
                        onClose={this.snackbarClose}
                    />
                </div>;
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private async sampleSheet() {
        let {
            currentWorkflowRun,
            currentStep
        } = this.props.workflowRunService;
        await this.props.workflowRunService.getSampleSheet(currentWorkflowRun?.Id || "", currentStep?.StepInstanceId || "", GetWorkflowType() === "SarsCov2");
        let result = this.props.workflowRunService.getState().sampleSheetResults.data;
        if (result && currentWorkflowRun) {
            var saveDocument = document.createElement('a');
            saveDocument.href = 'data:text/plain;charset=utf-8,' + encodeURI(result.SampleSheet || "");
            saveDocument.target = '_blank';
            saveDocument.download = "Run_" + currentWorkflowRun.RunNumber+ "_" + currentWorkflowRun.WorkflowName + "_sample_sheet.csv";
            saveDocument.click();
        }
    }

    @bind
    private onDateChanged(date: MaterialUiPickersDate, value: string | null | undefined) {

        this.props.workflowRunService.updateCustomField("SequencerStartDate", value ? moment(value).format("MM-DD-YY, h:mm a") : "");
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }
}