import {
    React,
    bind,
    _,
} from "$Imports/Imports";

import {
    SideNavigationMenuItem
} from "./SideNavigationMenuItem";

import {
    ThemeConsumer,
    ApplicationSecurityConsumer
} from "$Providers/index";

import {
    INavigationItem,
    mainNavigation
} from "$Utilities/navigation";

import {
    Button,
    Divider,
    MenuList
} from "$Imports/MaterialUIComponents";

import {
    withStyles
} from "$Imports/MaterialUIStyles";

import {
    INavigationServiceInjectedProps,
    NavigationService
} from "$State/NavigationFreezerService";

import { MinKnowService, IMinKnowServiceInjectedProps } from "$State/MinKnowFreezerService";
import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";
import { FeedbackIcon } from "$Imports/MaterialUIIcons";

interface ISideNavigationBaseProps { }

interface ISideNavigationState {
    mainNav: INavigationItem[]
}

type ISideNavigationProps = ISideNavigationBaseProps & INavigationServiceInjectedProps & IMinKnowServiceInjectedProps & ISettingsServiceInjectedProps;

class _SideNavigation extends React.Component<ISideNavigationProps, ISideNavigationState> {

    @bind
    private _onChange(e: React.MouseEvent<HTMLElement, MouseEvent>, newValue: INavigationItem) {
        this.props.navigationService.navigateTo(newValue.url || "");
    }

    @bind
    private _onChildToggle(newValue: INavigationItem) {
        this.props.navigationService.toggleExpandedPath(newValue.url || "");
    }

    async componentDidMount() {
        await this.props.settingsService.fetchDemoEnvironmentSet();
        const isDemo = !!this.props.settingsService.getState().demoEnvironmentResults.data;

        var nav = mainNavigation();
        var filteredNav = _.filter(nav, (n) => !n.externalLink && n.isVisibleInNavMenu).filter(n => ((n.label !== "Demo Details" || isDemo) && !n.externalLink && (n.label !== "Workflow")));
        this.setState({ mainNav: filteredNav });
    }

    render() {
        const navDetails = this.props.navigationService.getParentPath(window.location.pathname);
        const isDemo = !!this.props.settingsService.getState().demoEnvironmentResults.data;
        const giveFeedback = !!this.props.settingsService.getState().userPreferenceResults.data?.GiveFeedback;
        const feedbackUrl = this.props.settingsService.getState().demoEnvironmentInformation.data?.FeedbackUrl || "";
        const expandedPaths = this.props.navigationService.expandedPaths;
        this.props.navigationService.updateTitle();

        const useWetlab = (this.props.settingsService.getState().systemSettings.data || {})["use_wetlab"] === "use_wetlab";
        const useDryLab = ((this.props.settingsService.getState().systemSettings.data || {})["use_wetlab"] === "headless") || ((this.props.settingsService.getState().systemSettings.data || {})["use_wetlab"] === "use_wetlab");


        if (this.props.navigationService.getState().url?.includes("ngsreports")) {
            return <></>;
        }
        return (
            <ThemeConsumer>
                {(context) => {
                    const StylesMenuList = withStyles(context.themeConfig.sideNavigationMenuItemIcon)(MenuList);

                    return (
                        <div>
                            <StylesMenuList
                                style={{
                                    width: "300px"
                                }}
                            >
                                <ApplicationSecurityConsumer>
                                    {(authenticationContext) => {
                                        if (this.state && this.state.mainNav) {
                                            return _.map(_.filter(this.state.mainNav, n => {
                                                let allowed = true;
                                                switch (n.pageType) {
                                                    case "Wetlab":
                                                        allowed = useWetlab;
                                                        break;
                                                    case "Drylab":
                                                        allowed = useDryLab;
                                                        break;
                                                    case "PCEOnly":
                                                        allowed = (!useWetlab && !useDryLab);
                                                        break;
                                                    default:
                                                    case "System":
                                                        break;
                                                }
                                                return allowed;
                                            }
                                            ), (n, nIdx) => {
                                                let showChildren = expandedPaths[n.url || ""];
                                                return (<div>
                                                    <SideNavigationMenuItem
                                                        key={nIdx}
                                                        navigationItem={n}
                                                        selected={n.url === (navDetails === null ? null : navDetails.url)}
                                                        onMenuItemClick={this._onChange}
                                                        securityContext={authenticationContext.securityContext}
                                                        showChildren={showChildren}
                                                        onChildToggle={this._onChildToggle}
                                                        navDetailsUrl={(navDetails === null ? "" : navDetails.url)}
                                                        useDryLab={useDryLab}
                                                        useWetLab={useWetlab}
                                                    />
                                                    <div style={{ borderTop: "2px dotted black" }} />
                                                </div>
                                                )
                                            });
                                        } else {
                                            return <></>
                                        }
                                    }}
                                </ApplicationSecurityConsumer>
                            </StylesMenuList>
                            {isDemo && <Button
                                startIcon={<FeedbackIcon />}
                                style={{ position: "fixed", bottom: "0", width: "300px" }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    window.open(feedbackUrl, "_blank");
                                }}
                            >
                                Give Feedback
                            </Button>}
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }
}

export const SideNavigation = SettingsService.inject(MinKnowService.inject(NavigationService.inject(
    _SideNavigation,
)));
