import {
  React,
  bind,
  _,
} from "$Imports/Imports";

import {
  MenuItem,
  ListItemIcon,
  MenuList,
  Collapse
} from "$Imports/MaterialUIComponents";

import {
  INavigationItem,
} from "$Utilities/navigation";

import {
  ISecurityContext,
} from "$Utilities/Security/ISecurityContext";

import {
  ThemeConsumer,
} from "$Providers/index";

import {
  withStyles
} from "$Imports/MaterialUIStyles";
import { Keyboard, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

interface ISideNavigationMenuItemProps<T = ISecurityContext> {
  navigationItem: INavigationItem;
  securityContext: T;
  selected?: boolean;
  subItem?: boolean;
  showChildren?: boolean;
  onMenuItemClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>, navigationItem: INavigationItem) => void;
  onChildToggle: (navigationItem: INavigationItem) => void;
  navDetailsUrl: string;
  useWetLab: boolean;
  useDryLab: boolean;
}

export class SideNavigationMenuItem extends React.PureComponent<ISideNavigationMenuItemProps> {
  static defaultProps: Partial<ISideNavigationMenuItemProps> = {
    selected: false,
  };

  state = {
    showChildren: this.props.showChildren || false
  };

  @bind
  private _onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (this.props.navigationItem.childNavigation) {
      let showChildren = !this.state.showChildren;
      this.setState({
        showChildren: showChildren
      });
      this.props.onChildToggle(this.props.navigationItem);
    }
    else if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.navigationItem);
    }
  }

  render() {
    const {
      navigationItem,
      selected,
      securityContext
    } = this.props;

    const disabled = !(typeof navigationItem.enabled === "function" ? navigationItem.enabled(navigationItem, securityContext) : navigationItem.enabled);

    const hasAccess = navigationItem.hasAccess !== undefined ?
      typeof navigationItem.hasAccess === "function" ? navigationItem.hasAccess(navigationItem, securityContext) : navigationItem.hasAccess :
      true;

    const subItem = this.props.subItem === true ? "sub" : "";


    return hasAccess ? (
      <ThemeConsumer>
        {(context) => {
          const StylesMenuItem = withStyles(context.themeConfig.sideNavigationMenuItem)(MenuItem);
          const StylesListItemIcon = withStyles(context.themeConfig.sideNavigationMenuItemIcon)(ListItemIcon);

          return (
            <>
              <StylesMenuItem
                selected={selected}
                disabled={disabled}
                className={subItem}
                onClick={this._onClick}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {
                    navigationItem.icon ? (
                      <StylesListItemIcon>
                        {React.createElement(navigationItem.icon)}
                      </StylesListItemIcon>
                    ) : null
                  }
                  <div>{navigationItem.label}</div>
                </div>
                {this.props.navigationItem.childNavigation ? this.state.showChildren ? <KeyboardArrowUp /> : <KeyboardArrowDown /> : <></>}
              </StylesMenuItem>
              {this.props.navigationItem.childNavigation ?
                <Collapse in={this.state.showChildren} >
                  {_.map(_.filter(navigationItem.childNavigation, n => {
                    let allowed = true;
                    switch (n.pageType) {
                      case "Wetlab":
                        allowed = this.props.useDryLab;
                        break;
                      case "Drylab":
                        allowed = this.props.useDryLab;
                        break;
                      case "PCEOnly":
                        allowed = (!this.props.useWetLab && !this.props.useDryLab);
                        break;
                      default:
                      case "System":
                        break;
                    }
                    return allowed;
                  })

                    , (n, nIdx) => (
                      <SideNavigationMenuItem
                        key={nIdx}
                        navigationItem={n}
                        selected={n.url === this.props.navDetailsUrl}
                        subItem={true}
                        onMenuItemClick={this.props.onMenuItemClick}
                        securityContext={this.props.securityContext}
                      />
                    ))}
                </Collapse> : null}
            </>
          )
        }}
      </ThemeConsumer>
    ) : null;
  }
}
