import {
    NgsReportWetLabDilutionCalculationsVM,
} from "$Generated/api";

import {
    React,
    _,
} from "$Imports/Imports";
  
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const commonStyles: {
    table: string;
} = require("../../../css/common.scss");


const wetLabStyles: {
    analysisTableHeader : string;
    textEmphasis: string;
} = require("./WetLabReport.scss");

interface IWetLabDilutionCalculationsProps {
    dataDilutionCalculations: NgsReportWetLabDilutionCalculationsVM | undefined;
    workflowName: string;
};

export class WetLabDilutionCalculations extends React.Component<IWetLabDilutionCalculationsProps> {

    async componentDidMount(){
        //noop
    }

    render() {
        // Headers outlined in story
        var tableHeaders = ['Pooled Library Concentration (ng/uL)',
            'Avg. Library Size (bp)', 
            'Target Concen. (nM)',
            (this.props.workflowName === "NBS") ? 'Target Starting Volume (uL)' : 'Target Volume (uL)',
            'Complete Plate Pool Concen. (nM)',
            'Target Concen. to Current Concen. Ratio',
            'Pool Volume to Use (uL)',
            'RSB Volume to Use (uL)'];
        // Descriptions outlined in story
        var tableNotes = ['user input',
            (this.props.workflowName === "NBS") ? 'user input with default of 350' : 'user input with default of 400',
            (this.props.workflowName === "NBS") ? 'based on protocol, 2' : 'per Illumina COVIDSeq Assay (96 samples) protocol',
            (this.props.workflowName === "NBS") ?'user input with default of 10' : 'user input with minimum of 30',
            '(Pooled Library Concentration * 10^6) / (Avg. Library Size * 660 g/mol)',
            'Target Concen. / Complete Plate Pool Concen.',
            'Target Concen. to Current Concen. Ratio * Target Starting Volume',
            '(1 - Target Concen. to Current Concen. Ratio) * Target Starting Volume'];

        
        // Values from workflow and calculated values
        let pooledLibraryConcentration = this.props.dataDilutionCalculations?.LibraryConcetration ? parseFloat(this.props.dataDilutionCalculations.LibraryConcetration) : 0;
        let avgLibrarySize = this.props.dataDilutionCalculations?.LibrarySize ? parseFloat(this.props.dataDilutionCalculations.LibrarySize) : 350;
        let targetConcentration = (this.props.workflowName === "NBS") ? 2 : 4; //this is based on protocol, making it a variable for maintainability
        let targetStartingVolume = this.props.dataDilutionCalculations?.TargetStartingVolume ? parseFloat(this.props.dataDilutionCalculations.TargetStartingVolume) : 10;
        let completePlatePoolConcentration = (pooledLibraryConcentration * Math.pow(10, 6)) / (avgLibrarySize * 660);
        let targetConcentrationToCurrentConcentrationRatio = targetConcentration / completePlatePoolConcentration;
        let poolVolumeToUse = targetConcentrationToCurrentConcentrationRatio * targetStartingVolume;
        let rsbVolumeToUse = (1 - targetConcentrationToCurrentConcentrationRatio) * targetStartingVolume;

        return ( 
            <div>
                <h2>Initial Dilution for Loading Calculation</h2>
                <section className={wetLabStyles.textEmphasis}>Relevant notes or instructions that document decisions made or key items to understand the analysis.</section>

                <TableContainer component={Paper}>
                    <Table className={commonStyles.table}>

                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((h, nInd) => (
                                    <TableCell key={nInd} style={{fontWeight: 'bold'}}>{h}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                {tableNotes.map((n, nInd) => (
                                    <TableCell key={nInd} className={wetLabStyles.textEmphasis}>{n}</TableCell>
                                ))}
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    {Number(pooledLibraryConcentration).toFixed(2).toString()}
                                </TableCell>
                                <TableCell>
                                    {Number(avgLibrarySize).toFixed(0).toString()}
                                </TableCell>
                                <TableCell>
                                    {Number(targetConcentration).toFixed(0).toString()}
                                </TableCell>
                                <TableCell>
                                    {Number(targetStartingVolume).toFixed(0).toString()}
                                </TableCell>
                                <TableCell>
                                    {Number(completePlatePoolConcentration).toFixed(4).toString()}
                                </TableCell>
                                <TableCell>
                                    {Number(targetConcentrationToCurrentConcentrationRatio).toFixed(4).toString()}
                                </TableCell>
                                <TableCell>
                                    {Number(poolVolumeToUse).toFixed(4).toString()}
                                </TableCell>
                                <TableCell>
                                    {Number(rsbVolumeToUse).toFixed(4).toString()}
                                </TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
            </div>
        )
    }

}
