import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _
} from "$Imports/Imports";

import {
    AuditManagementCategoryVM, AuditManagementApiFactory
} from "$Generated/api";


interface ISettingsState {
    auditingEnabledResults: IAjaxState<AuditManagementCategoryVM[]>;
    auditingEnabledSaveRequest: IAjaxState<boolean>;
}

const InjectedPropName = "auditManagementService";

class AuditManagementFreezerService extends FreezerService<ISettingsState, typeof InjectedPropName> {
    constructor() {
        super({
            auditingEnabledResults: managedAjaxUtil.createInitialState(),
            auditingEnabledSaveRequest: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async fetchEnabledResults(forceUpdate?: boolean) {
        const { auditingEnabledResults } = this.freezer.get();

        if (auditingEnabledResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "auditingEnabledResults",
            onExecute: (apiOptions, param, options) => {
                const factory = AuditManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1AuditManagementAuditingEnabledGet();
            },
        });
    }

    public async saveEnabledResults() {
        const { auditingEnabledResults } = this.freezer.get();

        if (auditingEnabledResults.data) {

            await managedAjaxUtil.fetchResults({
                freezer: this.freezer,
                ajaxStateProperty: "auditingEnabledSaveRequest",
                onExecute: (apiOptions, param, options) => {
                    const factory = AuditManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                    return factory.apiV1AuditManagementUpdateAuditingEnabledPost({ body: auditingEnabledResults.data ? auditingEnabledResults.data.toJS() : undefined });
                },
            });
        }
    }

    public updateEnabledStatus(enabled: boolean, index: number) {
        const { auditingEnabledResults } = this.freezer.get();
        if (auditingEnabledResults.data) {
            const list = auditingEnabledResults.data.toJS()
            list[index].Enabled = enabled;
            auditingEnabledResults.data.set(list);
        }
    }
}

export const AuditManagementService = new AuditManagementFreezerService();
export type IAuditManagementServiceInjectedProps = ReturnType<AuditManagementFreezerService["getPropsForInjection"]>;
