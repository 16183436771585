// #region Imports
import {
    React,
    _
} from "$Imports/Imports";

/*
This is necessary in order to support silent authentication/token renewal: 
 
https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc
 
At a high level, this gets opened as an iframe to facilitate "background" communication with keycloak that is transparent to the user.
*/
export class SilentAuthFrame extends React.Component {
    componentDidMount() {
        parent.postMessage(location.href, location.origin);
    }
    render() {
        return (
            <></>
        )
    }
}