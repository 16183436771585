import {
    React,
    _
} from "$Imports/Imports";

import {
    TableRow,
    TableCell,
    TableBody
} from "$Imports/MaterialUIComponents";
import {instanceOfWellContentVM} from "./StandardPlate";
import { StandardPlateWell } from "./StandardPlateWell";
import { WellContentVM } from "$Generated/api";
import { IEmptyWell } from "./StandardPlate";

const styles: {
    plateRowHeader: string;
} = require("./StandardPlateStyles.scss");

interface IStandardPlateRowsProps {
    wellContents: (WellContentVM | IEmptyWell)[][];
    wellClick?: (position: number) => void;
    selectedPosition?: number;
    positionByRow: boolean;
    disabledAfterWell?: number;
    additionalTooltipInformation?: (well: WellContentVM) => string;
}

export class StandardPlateRows extends React.PureComponent<IStandardPlateRowsProps> {

    getRowCharacter(index: number) {
        var asciiCode = index + 65; //65 is the ascii code for 'A'
        return String.fromCharCode(asciiCode);
    }

    render() {
        const { wellContents, positionByRow } = this.props;
        return (
            <TableBody>
                {
                    _.map(wellContents, (wellRow, rowIndex) => {
                        return (

                            <TableRow key={rowIndex}>
                                <TableCell className={styles.plateRowHeader}>{this.getRowCharacter(rowIndex)}</TableCell>
                                {
                                    _.map(wellRow, (wellContent, colIndex) => {
                                        return (
                                            <StandardPlateWell
                                                key={`${rowIndex} ${colIndex}`}
                                                wellContent={instanceOfWellContentVM(wellContent) ? wellContent as WellContentVM : null}
                                                wellClick={this.props.wellClick}
                                                position={wellContent.WellPosition}
                                                selected={(wellContent.WellPosition === this.props.selectedPosition)}
                                                disabledAfterWell={this.props.disabledAfterWell}
                                                additionalTooltipInformation={this.props.additionalTooltipInformation}
                                            />
                                        );
                                    })
                                }
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        );
    }
}
