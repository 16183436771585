import { PipelineRunVM } from "$Generated/api";
import { DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Button, Card } from "$Imports/MaterialUIComponents";

import { IPipelineServiceInjectedProps, PipelineService } from "$State/PipelineFreezerService";

import { NavigationService } from "$State/NavigationFreezerService";
import { getStatusText } from "$Components/common/PipelineRuns/PipelineAnalysis";
var urljoin = require('url-join');

const styles: {
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    divPadding: string;
    tablePadding: string;
    continueButtonStyle: string;
} = require("./ExistingPipelineRuns.scss");


interface IExistingPipelineRunsBaseProps {
}

interface IExistingPipelineRunsState {
}

const PollInterval = 30; //poll at 30s

type IExistingPipelineRunsProps = IExistingPipelineRunsBaseProps & IPipelineServiceInjectedProps;

export class _ExistingPipelineRunsPage extends React.Component<IExistingPipelineRunsProps, IExistingPipelineRunsState> {

    state: IExistingPipelineRunsState = {
    }

    private readonly columns: Array<IDataTableColumn<PipelineRunVM>> = [
        {
            columnName: "name",
            columnFieldData: "PipelineName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Pipeline Name",
        },
        {
            columnName: "pipelineRunNumber",
            columnFieldData: "RunNumber",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Pipeline Run Number",
        },
        {
            columnName: "wetlabRunNumber",
            columnFieldData: (d) => d.WetlabRunNumber ? d.WetlabRunNumber : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Wet Lab Run Number",
        },
        {
            columnName: "startedOn",
            columnFieldData: (d) => moment(d.StartTime).format("MM-DD-YY, h:mm a"),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Started On",
        },
        {
            columnName: "status",
            columnFieldData: (d) => {
                return getStatusText(d);
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Status",
        },
        {
            columnName: "button",
            columnFieldData: (d) => {
                return <Button
                    className={styles.continueButtonStyle}
                    variant="contained"
                    color="primary"
                    onClick={() => this.navigateToPipelineRunPage(d.RunNumber)}
                >{(d.Status === "ResultsReleased" || d.Status === "ResultsNotReleased") ? "View" : "Continue"}
                </Button>
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ];

    async componentDidMount() {
        await this.props.PipelineService.getPipelineRuns();
        this.timerID = setInterval(() => this.props.PipelineService.getPipelineRuns(), PollInterval * 1000);
    }

    componentWillUnmount() {
        //Finish polling
        if (this.timerID) {
            clearInterval(this.timerID);
        }
    }

    private timerID: any;  // Specifing the type causes compilation issues.  Changing to Any for now.
    

    render() {
        const data = this.props.PipelineService.getState().pipelineRuns.data || [];

        return <div className={styles.mainContainer}>
            <Card
                className={styles.cardStyle}
            >
                <div className={styles.divPadding}>
                    <h2>Pipeline Runs</h2>
                    <i>Select a pipeline run to continue or review.</i>
                </div>
                <div className={styles.tablePadding}>
                    <DataTable
                        data={data}
                        columns={this.columns}
                        defaultSortColumnName="startedOn"
                        defaultSortDirection="desc"
                    />
                </div>
            </Card>
        </div>
    }

    @bind
    private async navigateToPipelineRunPage(runNumber: number) {
        const navigateUrl = urljoin('/pipelineRun', runNumber.toString());
        NavigationService.navigateTo(navigateUrl);
    }
}

export const ExistingPipelineRunsPage = PipelineService.inject(_ExistingPipelineRunsPage);