import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil
} from "$Imports/Imports";

import {
    ComponentSetVM, StepRequirementGroupVM, StepRequirementVM, WorkflowConfigApiFactory,
} from "$Generated/api";


interface IWorkflowConfigServiceState {
    reagentMixFetchResults: IAjaxState<ComponentSetVM[]>;
    reagentListFetchResults: IAjaxState<ComponentSetVM[]>;
    simplifiedReagentListFetchResults: IAjaxState<ComponentSetVM[]>;
    instructionSetFetchResults: IAjaxState<ComponentSetVM[]>;
    stepRequirementsFetchResults: IAjaxState<StepRequirementGroupVM[]>;
    addComponentSetResults: IAjaxState<boolean>;
    updateComponentSetResults: IAjaxState<boolean>;
    updateStepRequirementResults: IAjaxState<boolean>;
}

const InjectedPropName = "workflowConfigService";

class WorkflowConfigFreezerService extends FreezerService<IWorkflowConfigServiceState, typeof InjectedPropName> {
    constructor() {
        super({
            reagentMixFetchResults: managedAjaxUtil.createInitialState(),
            reagentListFetchResults: managedAjaxUtil.createInitialState(),
            simplifiedReagentListFetchResults: managedAjaxUtil.createInitialState(),
            instructionSetFetchResults: managedAjaxUtil.createInitialState(),
            stepRequirementsFetchResults: managedAjaxUtil.createInitialState(),
            addComponentSetResults: managedAjaxUtil.createInitialState(),
            updateComponentSetResults: managedAjaxUtil.createInitialState(),
            updateStepRequirementResults: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async fetchReagentMixes(forceUpdate?: boolean) {
        const { reagentMixFetchResults } = this.freezer.get();

        if (reagentMixFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "reagentMixFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowConfigComponentSetGet({type: "ReagentMix"});
            },
        });
    }

    public async fetchReagentLists(forceUpdate?: boolean) {
        const { reagentListFetchResults } = this.freezer.get();

        if (reagentListFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "reagentListFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowConfigComponentSetGet({type: "ReagentList"});
            },
        });
    }

    public async fetchSimplifiedReagentLists(forceUpdate?: boolean) {
        const { reagentListFetchResults } = this.freezer.get();

        if (reagentListFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "simplifiedReagentListFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowConfigComponentSetGet({type: "SimplifiedReagentList"});
            },
        });
    }

    public async fetchInstructionSets(forceUpdate?: boolean) {
        const { instructionSetFetchResults } = this.freezer.get();

        if (instructionSetFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "instructionSetFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowConfigComponentSetGet({type: "InstructionSet"});
            },
        });
    }

    public async addComponentSet(toAdd: ComponentSetVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "addComponentSetResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowConfigAddComponentSetPost({body: toAdd});
            },
        });
    }

    public async updateComponentSet(toUpdate: ComponentSetVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateComponentSetResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowConfigUpdateComponentSetPost({body: toUpdate});
            },
        });
    }

    public async fetchStepRequirements(forceUpdate?: boolean) {
        const { stepRequirementsFetchResults } = this.freezer.get();

        if (stepRequirementsFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "stepRequirementsFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowConfigStepRequirementsGet();
            },
        });
    }

    public async updateStepRequirement(toUpdate: StepRequirementVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "updateStepRequirementResults",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowConfigUpdateStepRequirementPost({body: toUpdate});
            },
        });
    }
}

export const WorkflowConfigService = new WorkflowConfigFreezerService();
export type IWorkflowConfigServiceInjectedProps = ReturnType<WorkflowConfigFreezerService["getPropsForInjection"]>;
