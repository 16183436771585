import {
    React,
    bind,
    _,
} from "$Imports/Imports"

import {
    IWorkflowScreenProps
} from "../WorkflowStep";

import {
    StepChangeControl,
    DataLoadingDisplay,
    CollapsibleSection,
    AdvanceTextField,
} from "$Imports/CommonComponents";

import { Button, Card, Dialog, Link, TextField } from "$Imports/MaterialUIComponents";

import { NavigationService } from "$State/NavigationFreezerService";
import { SequencingDataVM, SequencingStatus, SimplifiedMinderRunStatusVM } from "$Generated/api";
import Error from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MinitMinderService } from "$State/MinitMinderFreezerService";

var urljoin = require('url-join');

const styles: {
    dialog: string;
    tableHeader: string;
    pipelineButton: string;
    qcDataNavCol: string;
    Table: string;
    Row: string;
    DataCell: string;
    DataCellHeader: string;
    pipelineInputs: string;
    pipelineLaunchCard: string;
    pipelineInputLabel: string;
    pipelineLaunchHeader: string;
} = require("./CollectGenomicsData.scss");

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");

const PollInterval = 5; //poll at 5s

export interface ICollectGenomicsDataNBSScreenState {
    confirmCompleteOpen: boolean;
    confirmReleaseOpen: boolean;
    dirStructure: { [key: string]: any };
    currentDirSubDirs: string[];
    directoryList: string[];
    selectDirectoryOpen: boolean;
    selectedDirectory: string;
    pipelineStarted: boolean;
    minderFileShareStatus: boolean;
    poreOccupancyValid: boolean;
    pipelineLaunchOpen: boolean;
    nextflowToken: string;
    s3uri: string;
}

export class CollectGenomicsDataNBSScreen extends React.Component<IWorkflowScreenProps, ICollectGenomicsDataNBSScreenState> {

    private timerID: any;  // Specifing the type causes compilation issues.  Changing to Any for now.
    state: ICollectGenomicsDataNBSScreenState = {
        confirmCompleteOpen: false,
        confirmReleaseOpen: false,
        pipelineLaunchOpen: false,
        dirStructure: {},
        currentDirSubDirs: [],
        directoryList: [],
        selectDirectoryOpen: false,
        selectedDirectory: "",
        pipelineStarted: false,
        minderFileShareStatus: false,
        poreOccupancyValid: true,
        nextflowToken: "",
        s3uri: ""
    };

    async componentDidMount() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            await this.pollStatus();
            this.timerID = setInterval(() => this.pollStatus(), PollInterval * 1000);
        }
    }

    componentWillUnmount() {
        //Finish polling
        if (this.timerID) {
            clearInterval(this.timerID);
        }
        this.props.workflowRunService.resetInternalData();
    }

    private generateStatusText(status: SequencingStatus): string {
        switch (status) {
            case "Started":
                return "Pipeline Started";
            case "Failure":
                return "Sequencing Failed";
            case "Complete":
                return "Sequencing Complete";
            case "PartialResults":
                return "Partial Results";
            default:
            case "NotStarted":
                return "Not Started";
        }
    }

    render() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const sequencingDataResults = this.props.workflowRunService.getState().fetchSequencingDataState.data;
        const sequencingData: SequencingDataVM | undefined = sequencingDataResults && sequencingDataResults.length > 0 ? sequencingDataResults[0] : undefined;

        let jobStatusResults = MinitMinderService.getState().runStatusResults.data;

        if (currentStep && currentWorkflowRun) {
            const customFields = currentStep.CustomFields;
            let sequencingStatusText = "Not Started";
            let sequencingStatus: SequencingStatus = "NotStarted"

            if (jobStatusResults) {
                sequencingStatus = jobStatusResults.SequencingStatus;
                sequencingStatusText = this.generateStatusText(sequencingStatus)
            }
            var completed = (sequencingStatus === "Complete" ||
                sequencingStatus === "Failure" ||
                sequencingStatus === "PartialResults");

            if (customFields) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={commonStyles.collapsibleDiv}>
                            <div className={commonStyles.mainDiv}>
                                <div>
                                    <h2 style={{ paddingBottom: 10 }}>
                                        Sequencing Progress
                                    </h2>
                                    <Button variant="contained" color="primary"
                                        disabled={sequencingStatus !== "NotStarted"}
                                        onClick={(event) => {
                                            this.setState({ pipelineLaunchOpen: true });
                                        }}
                                    >
                                        Manually Launch Pipeline
                                    </Button>

                                    <div>
                                        <b>Sequencing Status: {sequencingStatusText}</b>
                                    </div>
                                    <div>Time Elapsed: {jobStatusResults?.TimeElapsed}</div>
                                    {completed && <h2 onClick={this.navigateToResultsPage}>
                                        <Link>
                                            View Results
                                        </Link>
                                    </h2>}
                                </div>
                                <div>
                                    <h2 style={{ paddingBottom: 10 }}>
                                        Overall Workflow Run Quality
                                    </h2>
                                    {completed && sequencingData && <table className={styles.Table}>
                                        <tbody>
                                            <tr className={styles.Row} >
                                                <td className={styles.DataCellHeader}>
                                                    Coverage:
                                                </td>
                                                <td className={styles.DataCell}>
                                                    {sequencingData.MetadataDictionary ? sequencingData.MetadataDictionary["Breadth of coverage"] : "ERROR"}
                                                </td>
                                                <td className={styles.DataCell}>
                                                    {sequencingData.MetadataDictionary && parseFloat(sequencingData.MetadataDictionary["Breadth of coverage"]) >= 30 ? <CheckCircleIcon style={{ color: "green" }} /> : <Error style={{ color: "red" }} />}
                                                </td>

                                            </tr>
                                            <tr className={styles.Row} >
                                                <td className={styles.DataCellHeader}>
                                                    Q30:
                                                </td>
                                                <td className={styles.DataCell}>
                                                    {sequencingData.MetadataDictionary ? sequencingData.MetadataDictionary["% >Q30"] : "ERROR"}
                                                </td>
                                                <td className={styles.DataCell}>
                                                    {sequencingData.MetadataDictionary && parseFloat(sequencingData.MetadataDictionary["% >Q30"]) >= .75 ? <CheckCircleIcon style={{ color: "green" }} /> : <Error style={{ color: "red" }} />}
                                                </td>
                                            </tr>
                                            <tr className={styles.Row} >
                                                <td className={styles.DataCellHeader}>
                                                    % GC Content:
                                                </td>
                                                <td className={styles.DataCell}>
                                                    {sequencingData.MetadataDictionary ? sequencingData.MetadataDictionary["% GC content"] : "ERROR"}
                                                </td>
                                                <td className={styles.DataCell}>
                                                    {sequencingData.MetadataDictionary && parseFloat(sequencingData.MetadataDictionary["% GC content"]) <= 50.0 && parseFloat(sequencingData.MetadataDictionary["% GC content"]) >= 45.0 ? <CheckCircleIcon style={{ color: "green" }} /> : <Error style={{ color: "red" }} />}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>}
                                </div>
                            </div>
                        </div>
                    </CollapsibleSection>
                    {completed
                        && sequencingData &&
                        <div>
                            <StepChangeControl disabled={disabled} nextStep={"Release Run"} showPause={false} moveToNextStep={this.completeWorkflow} failRun={this.props.failRun} />
                        </div>}
                    <Dialog
                        PaperProps={{ className: styles.dialog }}
                        open={this.state.confirmReleaseOpen}
                        onClose={() => { this.setState({ confirmReleaseOpen: false }); }}
                    >
                        <Card style={{ width: "400px" }}>
                            <div style={{ margin: "10px" }}>
                                <h2>Workflow Release</h2>
                                <h3>Is this workflow ready for release?</h3>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Button key={"yes"} variant="contained" size="small" color="primary" style={{ margin: "10px" }}
                                    onClick={(event) => {
                                        this.completeAndReleaseWorkflow(true);
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button key={"no"} variant="contained" size="small" color="primary" style={{ margin: "10px" }}
                                    onClick={(event) => {
                                        this.completeAndReleaseWorkflow(false);
                                    }}
                                >
                                    No
                                </Button>
                                <Button key={"cancel"} variant="contained" size="small" color="primary" style={{ margin: "10px" }}
                                    onClick={(event) => {
                                        this.setState({ confirmReleaseOpen: false });
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Card>
                    </Dialog>
                    <Dialog
                        PaperProps={{ className: styles.dialog }}
                        open={this.state.pipelineLaunchOpen}
                        onClose={() => { this.setState({ pipelineLaunchOpen: false }); }}
                    >
                        <Card className={styles.pipelineLaunchCard}>
                            <div className={styles.pipelineLaunchHeader}>
                                <h2>Pipeline Launch</h2>
                            </div>
                            <div className={styles.pipelineInputs}>
                                <div className={styles.pipelineInputLabel}>
                                    Nextflow Token:
                                </div>
                                <TextField value={this.state.nextflowToken}
                                    onChange={(event) => {
                                        this.setState({ nextflowToken: event.target.value });
                                    }} />
                            </div>
                            <div className={styles.pipelineInputs}>
                            <div className={styles.pipelineInputLabel}>
                                    Enter S3 URI
                                </div>
                                <TextField value={this.state.s3uri}
                                    onChange={(event) => {
                                        this.setState({ s3uri: event.target.value });
                                    }} />
                            </div>
                            <Button variant="contained" color="primary"
                                onClick={(event) => {
                                    this.startNBSRun();
                                }}
                            >
                                Launch Pipeline
                            </Button>
                        </Card>
                    </Dialog>
                </div>;
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private async pollStatus() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        await this.props.workflowRunService.fetchWorkflowRunSequencingStatus(true); //Fetch status
        if (currentWorkflowRun) {

            await MinitMinderService.fetchSimplifiedRun(currentWorkflowRun.RunNumber.toString());
            let jobStatusResults = MinitMinderService.getState().runStatusResults.data;

            if (jobStatusResults && (jobStatusResults.SequencingStatus === "Complete" || jobStatusResults.SequencingStatus === "Failure" || jobStatusResults.SequencingStatus === "PartialResults")) {
                //Get Right Side of data
                await this.props.workflowRunService.fetchWorkflowRunSequencingData();

                //Finish polling
                if (this.timerID) {
                    clearInterval(this.timerID);
                }

            }
        }
    }

    @bind
    private async completeWorkflow() {
        this.setState({ confirmReleaseOpen: true });
    }

    private async completeAndReleaseWorkflow(releaseData: boolean) {
        const currentStep = this.props.workflowRunService.currentStep;
        //const sequencingStatusData = this.props.workflowRunService.freezer.get().fetchSequencingStatusState.data;
        if (currentStep) {
            await Promise.all([
                this.props.workflowRunService.releaseRun(this.props.workflowRunService.currentWorkflowRun?.Id || "", releaseData),
                this.props.workflowRunService.completeWorkflow(false)
            ]);

            this.setState({ confirmReleaseOpen: false });
        }
    }

    @bind
    private async navigateToResultsPage() {
        const navigateUrl = urljoin('/results', this.props.workflowRunService.currentWorkflowRun?.Id)
        this.props.workflowRunService.resetCurrentWorkflowRun();
        this.props.workflowRunService.selectedStepInstanceId = "";
        NavigationService.navigateTo(navigateUrl);
    }

    @bind
    private async startNBSRun() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            await MinitMinderService.startNBSRun(this.state.nextflowToken, this.state.s3uri, currentWorkflowRun.RunNumber.toString());
            this.setState({pipelineLaunchOpen: false});
        }
    }

}