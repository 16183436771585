import {
    React,
    _,
} from "$Imports/Imports";

import {
    ComponentSetVM,
    ComponentSetItemInstanceVM
} from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

import {
    DataLoadingDisplay, ProcessAdminRole
} from "$Imports/CommonComponents";

import * as s from "underscore.string";
import { DeleteIcon, IconButton } from "$Imports/MaterialUIComponents";

export interface IInstructionSetControlProps {
    componentSetData: ComponentSetVM;
    updateComponentSetDataSteps: (reagents: ComponentSetItemInstanceVM[]) => void;
    disabled: boolean;
    poolOrPlate: PoolOrPlate;
    deleteStep?: (index: number) => void;
}

export interface IInstructionSetControlState {
    showLotNumberError: boolean;
}

export type PoolOrPlate = "Pool" | "Plate";

export function missingData(data: ComponentSetVM): boolean {

    let missingData = _.find(data.ComponentSetInstances, setInstance => _.find(setInstance.ComponentSetItemInstances, item => !s.isBlank(item.ReagentId || "") && s.isBlank(item.LotNumber || "")));
    return (missingData !== undefined);
}

const styles: {
    cell: string;
    cellTopDivider: string;
    lotNumberEntry: string;
    table: string;
    numberCell: string;
    lotNumbercell: string;
} = require("./InstructionSetControl.scss");

//This component is used in the Edit Component Set page - it is similar to the standard InstructionSetControl but has a few changes required for being in an edit context.
//There could be an opportunity in the future to combine these, as some of the code is similar.
export class InstructionSetControlPreview extends React.PureComponent<IInstructionSetControlProps, IInstructionSetControlState> {

    state: IInstructionSetControlState = {
        showLotNumberError: false
    }

    render() {
        const { componentSetData } = this.props;

        let firstField = true;
        if (componentSetData.ComponentSetItems) {
            const stepList = _.sortBy(componentSetData.ComponentSetItems, item => item.Ordinal).map((componentSetItem, idx) => {
                var setFocus = false;
                if (firstField) {
                    setFocus = true;
                    firstField = false;
                }
                if (componentSetData.ComponentSetItems) {
                    return <ApplicationSecurityContext.Consumer>
                        {value => (
                            <tr key={idx}>
                                <td className={styles.numberCell}>{componentSetItem.Ordinal}</td>
                                <td className={styles.cell}>{componentSetItem.Description}</td>
                                <td className={styles.cell}>{componentSetItem.DisplayName}</td>
                                <td className={styles.numberCell}>
                                    {Number(componentSetItem.Volume?.toPrecision(3))}
                                </td>
                                <td className={styles.numberCell}></td>
                                {this.props.deleteStep && <td>
                                    <IconButton
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onClick={() => {
                                            if (this.props.deleteStep) {
                                                this.props.deleteStep(idx);
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </td>}
                            </tr>
                        )}
                    </ApplicationSecurityContext.Consumer>
                }
            });

            return (
                <div>
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <td colSpan={6}><b>{componentSetData.DisplayHeader}</b></td>
                            </tr>
                            <tr>
                                <th className={styles.numberCell}>Step #</th>
                                <th className={styles.cell}>Description</th>
                                <th className={styles.cell}>Reagent</th>
                                <th className={styles.numberCell}>{this.props.poolOrPlate === "Plate" ? "uL Per Well" : "uL To Add"}</th>
                                <th className={styles.lotNumbercell}>Lot Number</th>
                                <th className={styles.numberCell}></th>
                            </tr>
                            {stepList}
                        </tbody>
                    </table>
                    {this.state.showLotNumberError && <h3 style={{ color: "red" }}>Instruction Set contains invalid lot numbers. Please add the numbers in the Assets tab.</h3>}
                </div>
            );
        }
        return <DataLoadingDisplay />;
    }
}
