import { DataLoadingDisplay } from "$Imports/CommonComponents";
import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports";

import { Card, FormControlLabel, Checkbox, Button, Snackbar } from "$Imports/MaterialUIComponents";

import {
    AuditManagementService,
    IAuditManagementServiceInjectedProps
} from "$State/AuditManagementFreezerService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
} = require("./AuditManagement.scss");

interface IAuditManagementPageBaseProps {
}

export interface IAuditManagementScreenState {
    messageSnackbarOpen: boolean;
}

type IAuditManagementPageProps = IAuditManagementPageBaseProps & IAuditManagementServiceInjectedProps;

export class _AuditManagementPage extends React.Component<IAuditManagementPageProps, IAuditManagementScreenState> {

    async componentDidMount() {
        await this.props.auditManagementService.fetchEnabledResults(true);
    }

    state: IAuditManagementScreenState = {
        messageSnackbarOpen: false
    };

    render() {

        const enabledData = this.props.auditManagementService.freezer.get().auditingEnabledResults.data;

        if (enabledData) {

            const checkboxes = _.map(enabledData, (d, index) => {
                return (
                    <div>
                        <FormControlLabel
                            control={<Checkbox checked={d.Enabled} onChange={(event) => { this.props.auditManagementService.updateEnabledStatus(event.target.checked, index) }} name={d.Name + "AuditEnabled"} />}
                            label={d.Name}

                        />
                    </div>);
            });

            return (
                <div
                    className={styles.mainContainer}
                >
                    <Card
                        className={styles.cardStyle}
                    >
                        <div className={styles.content}>
                            <h2>Enable/Disable Audit Options</h2>
                            {checkboxes}
                        </div>
                        <Button variant="contained" color="primary" onClick={() => { 
                            this.props.auditManagementService.saveEnabledResults();
                            this.setState({ messageSnackbarOpen: true }); }}>
                            Save
                        </Button>
                    </Card>
                    <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={"Audit categories have been saved"}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                    />
                </div>
            );
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {

    }
}

export const AuditManagementPage = AuditManagementService.inject(_AuditManagementPage);