import { TableCell, TableRow } from "$Imports/MaterialUIComponents";
import { withStyles } from "$Imports/MaterialUIStyles";

export const InfluenzaWorkflowId = "0167419c-5765-42ac-af99-388022beefd2";
export const SarsCov2WorkflowId = "720a07e9-546f-4834-88c2-c42fee572398";
export const AnthraxWorkflowId = "60d1d75f-01c8-47b6-be05-5bae710274d4";
export const NBSWorkflowId = "a0905b9f-f987-4248-aad6-acc4b29913ac";

export type WorkflowType = "Influenza" | "SarsCov2" | "Anthrax" | "NBS";

export type CustomDisplayTypes = "tagAmp";

export const KeyCodeEnter = 13;

export const MinIONValidBarcodePositions = 192;

export type AssetType = "Plate" | "Pool" | "Rack";

export const ProcessAdminRole = "Process Admin";

export const StyledTableHeaderRow = withStyles((theme) => ({
    root: {
      backgroundColor: '#B4DDDE',
      color: '#000000'
    },
  }))(TableRow);
  
export const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#B4DDDE',
      color: '#000000',
      padding: "2px 2px 2px 16px",
      fontWeight: "bold",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    body: {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
  }))(TableCell);

//COV2 Template Guids
export const COV2SampleRecord = "11af3e3c-a90e-4035-825d-62e4e8b79e1c";
export const COV2SampleAndWetlabTemplates = "04930e32-c468-4d8d-a130-866ca06fcc40";
export const COV2SampleWetlabAndDrylabTemplates = "8d76671a-2919-4cc1-8b0c-911b21979851";

//Arrays of Urls with which which hide/show components
export const urlsToHideSideNav = ["/pipelinerun", "/workflowrun", "/qcmetrics", "/ngsreports"]
export const urlsToHideLaunchPadButton = ["/workflowrun"]
