import * as React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
} from "react-router-dom";

import { withRouter } from "react-router";
import * as $ from "jquery";
import * as numeral from "numeral";
import * as _ from "lodash";
import { bind } from "bind-decorator";
import * as moment from "moment";
// import * as classnames from "classnames";
import Freezer = require("freezer-js");
import { ILogger } from "@yahara/logging";

import * as ReactGA from "react-ga";

import {
  IAjaxState,
} from "$Utilities/managedAjaxUtil";

import * as managedAjaxUtil from "$Utilities/managedAjaxUtil";
import * as Helpers from "$Utilities/helpers";

import memoizeOne from "memoize-one";

import { FreezerService } from "./FreezerService";

import * as C3 from "c3";

export {
  $,
  Freezer,
  React,
  _,
  bind,
  withRouter,
  moment,
  // classnames,
  Router,
  Route,
  IAjaxState,
  managedAjaxUtil,
  Helpers,
  ILogger,
  FreezerService,
  memoizeOne,
  Link,
  Redirect,
  Switch,
  numeral,
  ReactGA,
  C3
};
