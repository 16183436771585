import {
    React,
    _,
} from "$Imports/Imports";
  
  
import { ResultsService,
    IResultsServiceInjectedProps
} from "$State/ResultsFreezerService";

const stylesWetLabReport: {
    mainReport : string;
} = require("./WetLabReport.scss");

import {
    ReportHeader,
} from "./ReportComponents/ReportHeader"

import {
    BioInformaticsPipelineAnalysisDetails
} from "./BioInformaticsPipelineAnalysisDetails"
import { NgsReportBioinformaticsPipelineAnalysisDetailsVM } from "$Generated/api";

interface IWetLabReportProps {
    // Report Header props.
    workflowRunNumber: string;
    workflowRunTag: string;
    workflowName: string;
    users: string[] | undefined;
    systemSettings: {[key: string]: string};
    bioinformaticsInformation: NgsReportBioinformaticsPipelineAnalysisDetailsVM | undefined;
};


type IWetLabReportPageProps = IResultsServiceInjectedProps & IWetLabReportProps;

export class _BioInformaticsPipelineReport extends React.Component<IWetLabReportPageProps> {

    async componentDidMount(){

    }

    render() {
      
        return (

            <div className={stylesWetLabReport.mainReport}>
                <ReportHeader
                    workflowRunNumber={this.props.workflowRunNumber}
                    workflowDisplayName={this.props.workflowName}
                    users={this.props.users}
                    systemSettings={this.props.systemSettings}
                    reportName="Bioinformatics Pipeline Analysis"
                />
                <BioInformaticsPipelineAnalysisDetails
                    runNumber={this.props.workflowRunNumber}
                    runTag={this.props.workflowRunTag}
                    workflowName={this.props.workflowName}
                    bioinformaticsInformation={this.props.bioinformaticsInformation}
                />
            </div>

        )
      
    }

}


export const BioInformaticsPipelineReport = ResultsService.inject(
    _BioInformaticsPipelineReport
);