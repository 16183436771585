import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";

import {
    SampleInfoTable,
    StandardPlate,
    IDataTableColumn,
    StepActionsControl,
    StepChangeControl,
    AdvanceTextField,
    QCUIComponent,
    RNAExtractionTemperatureMandatoryEntryCheck,
    RNAExtractionTemperatureInRangeCheck,
    MasterMixControl,
    CollapsibleSection,
    DataLoadingDisplay
} from "$Imports/CommonComponents";

import {
    AssetType,
    QCCheckInstanceVM,
    QCCheckResultVM,
    WellContentVM
} from "$Generated/api";

import {
    KeyboardDatePicker,
    MenuItem,
    Snackbar,
    TextField, HorizontalSplitIcon, ErrorIcon
} from "$Imports/MaterialUIComponents";

import {
    MaterialUiPickersDate
} from "@material-ui/pickers/typings/date";

import * as s from "underscore.string";
import { ChangeEvent } from "react";

import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";

const styles: {
    tableHeader: string;
    sampleInfoDiv: string;
    plateDiv: string;
    reagentRowDiv: string;
    reagentInput: string;
    errorIcon: string;
    reagentTempRowDiv: string;
} = require("./RNAExtraction.scss");

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");

export interface IRNAExtractionState {
    messageSnackbarOpen: boolean;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    tempNotFilled: boolean;
    canMove: boolean;
    dataLoaded: boolean;
}

interface Asset {
    InputName: string;
    AssetType: AssetType;
    WellContents: WellContentVM[];
}

export class RNAExtractionScreen extends React.Component<IWorkflowScreenProps, IRNAExtractionState> {

    state: IRNAExtractionState = {
        messageSnackbarOpen: false,
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        tempNotFilled: false,
        canMove: true,
        dataLoaded: false
    };
    private columns(): Array<IDataTableColumn<WellContentVM>> {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample ID",
                    sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
                },
                {
                    columnName: "sample-date-harvested",
                    columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Date Harvested",
                    sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
                },
                {
                    columnName: "sample-specimen-source",
                    columnFieldData: (d) => (d.Sample ? d.Sample.SpecimenSource : ""),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Specimen Source",
                    sortMethod: (d) => (d.Sample ? d.Sample.SpecimenSource : "") ?? ""
                },
                {
                    columnName: "sample-well-position",
                    columnFieldData: (d) => ConvertPositionToString(d.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Well Position",
                    sortMethod: (d) => d.WellPosition ?? ""
                }
            ];
        }
        return [];
    }

    async componentDidMount() {
        if (GetWorkflowType() === "Anthrax") {
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }
        await this.props.workflowRunService.fetchAssetsForStep(true);
        await this.props.workflowRunService.getExtractionReagents(true);
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
        
        if (GetWorkflowType() === "SarsCov2") {
            await this.props.workflowRunService.fetchSimplifiedReagentListForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchReagentListForStep(true);
        }
        this.setState({dataLoaded: true});
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const reagents = this.props.workflowRunService.getState().getExtractionReagentsState;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        let errorMessage = "Reagent information and Temperature is required";
        if (GetWorkflowType() === 'Anthrax') {
            errorMessage = "Reagent information is required";
        }
        if (currentStep && currentWorkflowRun && reagents.data && masterMixInfoState.data && lotOptions) {
            let inputAsset = this.getInputAsset();
            const wellCount = currentWorkflowRun.AssetColCount * currentWorkflowRun.AssetRowCount;
            
            const customFields = currentStep.CustomFields;
            if (customFields) {
                let date = new Date((customFields["dateStarted"] !== "" ? customFields["dateStarted"] : Date.now()));
                if (customFields["dateStarted"] === "") {
                    this.props.workflowRunService.updateCustomField("dateStarted", date.toString());
                }
                if (inputAsset) {
                    let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                    return (<div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.collapsibleDiv}>
                                <div className={styles.sampleInfoDiv}>
                                    <div className={styles.plateDiv}>
                                        <h2>{inputAsset.InputName}</h2>
                                        <StandardPlate
                                            wellContents={inputAsset.WellContents}
                                            wellCount={wellCount}
                                            columnCount={currentWorkflowRun.AssetColCount}
                                            positionByRow={currentWorkflowRun.AssetPositionByRow}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <h2>Reagent Information</h2>
                                            { GetWorkflowType() !== "SarsCov2" && GetWorkflowType() !== "Anthrax" &&
                                            <div>
                                                <div className={styles.reagentRowDiv}>
                                                    <div className={styles.reagentInput}>
                                                        <TextField
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            disabled={disabled}
                                                            className={styles.reagentInput}
                                                            label="Kit Name"
                                                            id="kit-name"
                                                            value={(customFields["kitName"] ? customFields["kitName"] : "")}
                                                            onChange={(event) => { this.props.workflowRunService.updateCustomField("kitName", (event.target.value as string)); }}
                                                        >
                                                            {_.map(reagents.data.Kits, k => {
                                                                return (<MenuItem key={k.Id} value={k.Name}>{k.Name}</MenuItem>)
                                                            })}
                                                        </TextField>
                                                    </div>
                                                    <KeyboardDatePicker
                                                        disabled={disabled}
                                                        className={styles.reagentInput}
                                                        label="Date Started"
                                                        value={date}
                                                        onChange={this.onDateChanged}
                                                        format="MM/DD/YY" />
                                                    <div style={{ width: "195px" }}>
                                                        <div style={{}}>
                                                            {"Temperature " + customFields["degrees"] + "C"}
                                                        </div>
                                                        <TextField disabled={disabled} style={{ paddingLeft: "10px", top: "-5px" }} value={(customFields["Temperature"] ? customFields["Temperature"] : "")} onChange={this.updateTempField} inputProps={{ pattern: "[0-9.]*" }} />
                                                    </div>
                                                    {this.state.tempNotFilled && <ErrorIcon className={styles.errorIcon} />}
                                                </div>
                                                <div className={styles.reagentRowDiv}>
                                                    <AdvanceTextField disabled={disabled} InputLabelProps={{ shrink: true }} label="Investigator Name" value={(customFields["investigatorName"] ? customFields["investigatorName"] : "")} onDebouncedChange={(value) => { this.onTextChanged("investigatorName", value) }} />
                                                    <AdvanceTextField disabled={disabled} InputLabelProps={{ shrink: true }} label="Specimen Volume" value={(customFields["inputSpecimenVolume"] ? customFields["inputSpecimenVolume"] : "")} onDebouncedChange={(value) => { this.onTextChanged("inputSpecimenVolume", value) }} />
                                                    <div className={styles.reagentInput}>
                                                        <TextField
                                                            select
                                                            InputLabelProps={{ shrink: true }}
                                                            disabled={disabled}
                                                            className={styles.reagentInput}
                                                            label="RNA Manipulation"
                                                            id="rna-manipulation"
                                                            value={(customFields["rnaManipulation"] ? customFields["rnaManipulation"] : "")}
                                                            onChange={(event) => { this.props.workflowRunService.updateCustomField("rnaManipulation", (event.target.value as string)); }}
                                                        >
                                                            {_.map(reagents.data.RNAManipulations, k => {
                                                                return (<MenuItem key={k.Id} value={k.Name}>{k.Name}</MenuItem>)
                                                            })}
                                                        </TextField>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        
                                        <MasterMixControl
                                            disabled={disabled}
                                            index={0}
                                            masterMix={masterMixInfoState.data[0]}
                                            sampleCount={1}
                                            updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                            lotOptions={lotOptions}
                                            setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                            hideHeaders
                                            overrideTitle={""}
                                            workFlowType={GetWorkflowType()}
                                        />
                                        <SampleInfoTable
                                            overrideStyles={{ height: "250px", marginTop: "10px" }}
                                            data={inputAsset?.WellContents}
                                            columns={this.columns()}
                                            header={""}
                                            defaultSortColumnName={"sample-well-position"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                    completeStep={this.completeMoveToNextStep}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={errorMessage}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>);
                }
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private onTextChanged(key: string, newValue: string) {
        this.props.workflowRunService.updateCustomField(key, newValue);
    }

    @bind
    private onDateChanged(date: MaterialUiPickersDate, value: string | null | undefined) {

        this.props.workflowRunService.updateCustomField("dateStarted", value ? moment(value).format("MM-DD-YY, h:mm a") : "");
    }

    @bind
    updateTempField(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.props.workflowRunService.updateCustomField("Temperature", newValue);
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue) {
                this.props.workflowRunService.updateCustomField("Temperature", newValue);
            }
        }
    }

    @bind
    private async moveToNextStepBegin() {
        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            let failedQCs: QCCheckInstanceVM[] = [];
            let qcResults: QCCheckResultVM[] = [];
            const customFields = currentStep.CustomFields;

            //Run all QCs
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case RNAExtractionTemperatureMandatoryEntryCheck:
                        let tempMandPass = true;
                        if (s.isBlank(customFields["Temperature"])) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            tempMandPass = false;
                            this.setState({ tempNotFilled: true });
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? tempMandPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: s.isBlank(customFields["Temperature"]) ? "None" : customFields["Temperature"],
                                Date: new Date(Date.now()),
                                Pass: tempMandPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case RNAExtractionTemperatureInRangeCheck:
                        let tempRangePass = true;
                        let temp = Number.parseInt(customFields["Temperature"]);
                        if (!isNaN(temp)) {
                            let minTempParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MinTemperature")?.Value || "0")
                            let maxTempParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MaxTemperature")?.Value || "0")
                            if (temp < minTempParam || temp > maxTempParam) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                                tempRangePass = false;
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? tempRangePass ? undefined : 0 : 2, //passed/executed/skipped
                                    MeasuredValue: customFields["Temperature"],
                                    Date: new Date(Date.now()),
                                    Pass: tempRangePass,
                                    QCCheckInstance: qc,
                                }
                            );
                        }
                        break;
                }
            })
            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                await this.completeMoveToNextStep();

            }
        }
    }

    @bind
    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        if (currentStep && masterMixState.data) {
            const customFields = currentStep.CustomFields;
            let fieldWithMissingData = _.find(customFields, c => s.isBlank(c));
            let missingMasterMixData = missingData(masterMixState.data[0]);

            if (!missingMasterMixData && fieldWithMissingData === undefined && this.state.canMove) { //Can't use !fieldWithMissingData because that !"" = true.
                await Promise.all([
                    this.props.workflowRunService.saveMasterMix(true),
                    this.props.workflowRunService.updateCustomFields(),
                    this.props.workflowRunService.completeStep()
                ]);
            }
            else {
                this.setState({ messageSnackbarOpen: true });
            }
        }
    }

    @bind
    private getInputAsset() {
        const { workflowAssets } = this.props.workflowRunService.getState();
        const currentStep = this.props.workflowRunService.currentStep;
        const inputAssetType = this.getInutputAssetType();
        let inputAsset : Asset | null = null;

        if(currentStep) {
            switch(inputAssetType) {
                case "Plate":
                    const inputPlate = _.find(workflowAssets.data?.Plates, (p) => { return p.Name === currentStep.InputName });
                    if(inputPlate) {
                        inputAsset = { 
                            InputName: inputPlate?.Name,
                            WellContents: inputPlate?.WellContents,
                            AssetType: "Plate"
                        };
                    }
                    break;
                case "Rack":
                    const inputRack = _.find(workflowAssets.data?.Racks, (r) => { return r.Name === currentStep.InputName });
                    if(inputRack) {
                        inputAsset = {
                            InputName: inputRack?.Name,
                            WellContents: inputRack?.WellContents,
                            AssetType: "Rack"
                        };
                    }
                    break;
            }
        }
        return inputAsset;
    }

    @bind 
    private getInutputAssetType() {
        const currentStep = this.props.workflowRunService.currentStep;
        if(currentStep) {
            let inputAsset = _.find(currentStep.InputAssets, asset => (asset.Name === currentStep!.InputName));
            return inputAsset?.AssetType;
        }
        return null;
    }
}