import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports"

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";

import {
    SampleInfoTable,
    IDataTableColumn,
    StepActionsControl,
    StepChangeControl,
    CollapsibleSection,
    DataLoadingDisplay,
    AmpliconQuantificationNTCConcentrationCheck,
    AmpliconQuantificationPTCConcentrationCheck,
    AmpliconQuantificationSampleConcentrationCheck,
    SampleMinConcentration,
    PTCMinConcentration,
    NTCMaxConcentration,
    QCUIComponent
} from "$Imports/CommonComponents";

import {
    QCCheckInstanceVM,
    QCCheckResultVM,
    WellContentVM,
    QCCheckConfigParametersVM,
    QubitSampleVM
} from "$Generated/api";

import {
    ConcentrationVolumeVM
} from "$State/WorkflowRun/WorkflowRunInternalFreezerService"

import {
    Snackbar,
    TextField,
    Button,
    TableCellProps,
    Tooltip,
    ErrorIcon
} from "$Imports/MaterialUIComponents";

import { FileCopyIcon } from "$Imports/MaterialUIIcons";

import * as s from "underscore.string";
import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";

import {
    CSVService
} from "$State/CSVFreezerService";

import { FileService, IFileServiceInjectedProps } from "$State/FileFreezerService";

const styles: {
    tableHeader: string;
    mainDiv: string;
    table: string;
    input: string;
    failRow: string;
    errorIcon: string;
    inputDiv: string;
} = require("./Quantification.scss");

const commonStyles: {
    footerDiv: string;
} = require("./CommonStepStyles.scss");

interface QCParam {
    Id: string;
    Value: number;
}

interface QCConfig {
    [index : string]: QCParam[]
}

export interface IQuantificationState {
    messageSnackbarOpen: boolean;
    messageContent: string;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    dataLoaded: boolean;
    samples: QubitSampleVM[];
    qcConfig: QCConfig;
}

type QubitQuantificationProps = IWorkflowScreenProps & IFileServiceInjectedProps;

export class _QubitQuantificationScreen extends React.Component<QubitQuantificationProps, IQuantificationState> {

    state: IQuantificationState = {
        messageSnackbarOpen: false,
        messageContent: "",
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        dataLoaded: false,
        samples: [],
        qcConfig: {}
    };

    @bind
    private cellProps(d: WellContentVM): TableCellProps {
        let { concentrationDictionary } = this.props.workflowRunService;
        let concentrationEntry = concentrationDictionary[d.Id]
        
        let ntcQcParam = this.getQcParam(AmpliconQuantificationNTCConcentrationCheck, NTCMaxConcentration);
        let ptcQcParam = this.getQcParam(AmpliconQuantificationPTCConcentrationCheck, PTCMinConcentration);
        let sameplQcParam = this.getQcParam(AmpliconQuantificationSampleConcentrationCheck, SampleMinConcentration);
        let concentrationEntryValue = concentrationEntry && concentrationEntry.Concentration ? concentrationEntry.Concentration : 0;

        if (d.Control && d.Control.Name === "NTC" && ntcQcParam && concentrationEntryValue > ntcQcParam.Value) {
            return {
                className: styles.failRow
            };
        }
        if (d.Control && d.Control.Name === "PTC" && ptcQcParam && concentrationEntryValue <= ptcQcParam.Value) {
            return {
                className: styles.failRow
            };
        }
        if(d.Sample && sameplQcParam && concentrationEntryValue <= sameplQcParam.Value) {
            return {
                className: styles.failRow
            };
        }
        return {
        };
    }

    //Returns the columns for the well contents. Needs to be a function to handle the disabled flag as well as correctly updating within react.
    private wellContentColumns(): Array<IDataTableColumn<WellContentVM>> {
        let {
            concentrationDictionary,
            currentStep,
            currentWorkflowRun
        } = this.props.workflowRunService;

        let disabled = false;

        if (currentStep && currentWorkflowRun) {
            disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
        }
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Sample ID",
                    sortMethod: (d) => (d.Sample ? d.Sample.SampleId : "") ?? ""
                },
                {
                    columnName: "sample-well-position",
                    columnFieldData: (d) => ConvertPositionToString(d.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Well Position",
                    sortMethod: (d) => d.WellPosition ?? ""
                },
                {
                    columnName: "sample-date-harvested",
                    columnFieldData: (d) => (d.Sample ? moment(d.Sample.DateHarvested).format("MM-DD-YY, h:mm a") : ""),
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Date Harvested",
                    sortMethod: (d) => (d.Sample ? d.Sample.DateHarvested : "") ?? ""
                },
                {
                    columnName: "concentration",
                    columnFieldData: (d) => {
                        let concentrationEntry = concentrationDictionary[d.Id];
                        let ntcQcParam = this.getQcParam(AmpliconQuantificationNTCConcentrationCheck, NTCMaxConcentration);
                        let ptcQcParam = this.getQcParam(AmpliconQuantificationPTCConcentrationCheck, PTCMinConcentration);
                        let sameplQcParam = this.getQcParam(AmpliconQuantificationSampleConcentrationCheck, SampleMinConcentration);
                        let concentrationEntryValue = concentrationEntry && concentrationEntry.Concentration ? concentrationEntry.Concentration : 0;

                        return (<div>
                            <TextField disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={concentrationEntry !== undefined ? concentrationEntry.Concentration : ""} onChange={(e) => { this.onConcentrationChanged(d.Id, e.target.value); }} inputProps={{ min: 0 }} />
                            {(d.Control && d.Control.Name === "NTC"  && ntcQcParam && concentrationEntryValue > ntcQcParam.Value) &&
                                <Tooltip
                                    title={"NTC DNA Concrentration cannot be > " + ntcQcParam.Value + " ng/ul"}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>}
                            {(d.Control && d.Control.Name === "PTC" && ptcQcParam && concentrationEntryValue <= ptcQcParam.Value) &&
                                <Tooltip
                                    title={"PTC DNA Concrentration cannot be < " + ptcQcParam.Value + " ng/ul"}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>}
                            {(d.Sample && sameplQcParam && concentrationEntryValue <= sameplQcParam.Value) &&
                                <Tooltip
                                    title={"Sample Concrentration cannot be <= " + sameplQcParam.Value + " ng/ul"}
                                    enterDelay={300}
                                    placement={"top"}
                                    arrow={true}
                                >
                                    <ErrorIcon className={styles.errorIcon} />
                                </Tooltip>}

                        </div>);
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    cellProps: this.cellProps,
                    headerValue: "Concentration (ng/ul)",
                }
            ];
        }
        return [];
    }

    async componentDidMount() {
        await this.props.workflowRunService.resetConcentrationDictionary();
        if (GetWorkflowType() === "Anthrax") {
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }
        await this.props.workflowRunService.getConcentrationControls(true);
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
        await this.props.workflowRunService.fetchInstrumentOptions();

        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentStep) {
            const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName && plate.Status !== "Empty"));
            let concentrationDictionary: { [index: string]: ConcentrationVolumeVM | undefined } = {};
            if (inputPlate) {
                let samples: QubitSampleVM[] = [];
                _.forEach(inputPlate.WellContents, w => {
                    concentrationDictionary[w.Id] = { 
                        Concentration: w.Concentration,
                        SampleVolume: inputPlate.WellVolume,
                        Ratio230: 0,
                        Ratio280: 0
                    };
                    
                    if(w.Sample) {
                        let qubitSample: QubitSampleVM = {
                            Id: w.Sample.Id,
                            WellContentId: w.Id,
                            SampleId: w.Sample.SampleId,
                            WellPosition: ConvertPositionToString(w.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true)
                        };
                        samples.push(qubitSample);
                    } else if (w.Control) {
                        let qubitControl: QubitSampleVM = {
                            Id: w.Control.Id,
                            WellContentId: w.Id,
                            SampleId: w.Control.Name,
                            WellPosition: ConvertPositionToString(w.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true)
                        };
                        samples.push(qubitControl);
                    }
                });
                this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
                this.setState({ samples: samples });

                await this.props.FileService.getQubitQuantificationTemplateZip({ Samples: samples });
            }

            let qcConfig : QCConfig = {};
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                if(qc.CheckConfig) {
                    let qcParams : QCParam[] = [];
                    _.forEach(qc.CheckConfig.Parameters, (param) => {
                        qcParams.push({
                            Id: param.Id,
                            Value: parseInt(param.Value)
                        });
                    });
                    qcConfig[qc.QCCheckType] = qcParams;
                }
            });
            this.setState({ qcConfig: qcConfig });
        }
        
        this.setState({ dataLoaded: true });
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {

        const plates = this.props.workflowRunService.getState().plates;
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const controls = this.props.workflowRunService.getState().getConcentrationControlsState;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        if (plates.data && currentStep && currentWorkflowRun && instrumentOptions) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName && p.Status !== "Empty" });

            const customFields = currentStep.CustomFields;
            if (customFields && inputPlate && controls.data) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={styles.mainDiv}>
                                <div>
                                    <h2>Sample Results</h2>
                                    <div style={{ paddingRight: "40px" }}>
                                        <span>Results can be uploaded via a .csv file using the following steps:</span>
                                        <ol>
                                            <li>Download the .csv template and with ReadMe file. The template will contain the Sample ID and Well position for samples in this run.</li>
                                            <li>Export results for all the samples from the Qubit Fluorometer</li>
                                            <li>Open the .csv template in Excel, and paste in the exported fluorometer results. </li>
                                            <li>Save As as .csv file on the computer. </li>
                                            <li>Upload the file using the Upload Sample Results button below.</li>
                                        </ol>
                                        <Button
                                            style={{ justifyContent: "left", marginBottom: "30px" }}
                                            startIcon={<FileCopyIcon />}
                                            onClick={() => {
                                                var saveDocument = document.createElement('a');
                                                saveDocument.href = this.props.FileService.getState().qubitQuantificationZipState.data || "";
                                                saveDocument.target = '_blank';
                                                saveDocument.download = 'Qubit_Sample_Results_Template.csv';
                                                saveDocument.click();
                                            }}>
                                            Download Template
                                        </Button>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center"}}>
                                        <Button variant="contained"
                                            onClick={() => {
                                                const input = document.getElementById('samplesInputFile') as HTMLInputElement;
                                                if (input && input.files) {
                                                    const file = input.files[0];
                                                    const fileExtRegex = /^.*\.csv/;

                                                    if (fileExtRegex.test(file.name)) {
                                                        this.setState({ messageContent: "", messageSnackbarOpen: false });

                                                        const reader = new FileReader();
                                                        reader.onload = () => { this.parseFlurometerOutput(reader); };
                                                        // Using encoding windows-1252 because csv file contains greek characters
                                                        reader.readAsText(file, "windows-1252");
                                                    } else {
                                                        this.setState({ messageContent: "Please upload a valid csv file", messageSnackbarOpen: true });
                                                    }

                                                }
                                            }}>Upload Sample Results</Button>
                                        <input style={{ margin: "0px 20px", flex: "1 0" }} type="file" id="samplesInputFile"></input>
                                    </div>

                                </div>
                                <SampleInfoTable
                                    data={inputPlate?.WellContents}
                                    columns={this.wellContentColumns()}
                                    header={"Samples"}
                                    defaultSortColumnName={"sample-well-position"} />
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} hideArchiveRetain={inputPlate.WellContents[0].RunCount >= currentWorkflowRun.WorkflowMaxSampleRun} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.messageContent}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div >)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private async parseFlurometerOutput(reader: FileReader) {
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;

        await CSVService.parseFlurometerOutput(reader.result as string);
        const parsedOutput = CSVService.getState().parseFlurometerOutputResults.data;

        let hasIncorrectUnits = false;
        if (currentStep && plates.data) {
            // concentration units string format
            const concUnitRegex = /^ng\/.L/;

            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName && p.Status !== "Empty" });
            if (parsedOutput && parsedOutput.length > 0) {
                this.setState({ messageSnackbarOpen: false });
                _.forEach(inputPlate?.WellContents, wc => {
                    let parsedSample = _.find(parsedOutput, (p) => { return p.SampleId === (wc.Sample ? wc.Sample.SampleId : (wc.Control ? wc.Control.Name : "")) });
                    if (parsedSample) {
                        var units = parsedSample.OriginalSasmpleConcentrationUnits ? parsedSample.OriginalSasmpleConcentrationUnits : "";
                        var muCode = units && units.length > 3 ? units.charCodeAt(3) : -1;
                        // Check if units string has correct format and value, 181 is the unicode for the micro symbol
                        if (concUnitRegex.test(units) && muCode === 181) {
                            this.onConcentrationChangedNumber(wc.Id, parsedSample.OriginalSampleConcentration);
                        } else {
                            hasIncorrectUnits = true;
                        }
                    }
                });
            } else {
                this.setState({ messageContent: "No records parsed", messageSnackbarOpen: true });
            }

            if (hasIncorrectUnits) {
                this.setState({
                    messageSnackbarOpen: true,
                    messageContent: "Sample Concentration is expected in units of ng/\xB5L. Pooling calculation will not be completed. Please try upload again."
                });
            }
        }
    }

    @bind
    private onConcentrationChanged(wellContentId: string, newValue: string) {
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;

        if(currentStep) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName && p.Status !== "Empty" });

            let concentrationDictionary = _.cloneDeep(this.props.workflowRunService.concentrationDictionary);
            let num = parseFloat(newValue);
    
            concentrationDictionary[wellContentId] = {
                Concentration: isNaN(num) ? 0 : num,
                SampleVolume: inputPlate ? inputPlate.WellVolume : 0,
                Ratio230: 0,
                Ratio280: 0
            };
            this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
        }
    }

    @bind
    private onConcentrationChangedNumber(wellContentId: string, newValue: number | undefined) {
        if (wellContentId && newValue) {
            const currentStep = this.props.workflowRunService.currentStep;
            const plates = this.props.workflowRunService.getState().plates;

            if(currentStep) {
                let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName && p.Status !== "Empty" });
                let concentrationDictionary = _.cloneDeep(this.props.workflowRunService.concentrationDictionary);
            
                concentrationDictionary[wellContentId] = {
                    Concentration: newValue,
                    SampleVolume: inputPlate ? inputPlate.WellVolume : 0,
                    Ratio230: 0,
                    Ratio280: 0
                };
                this.props.workflowRunService.concentrationDictionary = concentrationDictionary;
            }
        }
    }

    @bind
    private async moveToNextStepBegin() {
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentStep && plates.data && currentWorkflowRun) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName && p.Status !== "Empty" });
            if (inputPlate) {
                let allConcentrationDataSet = _.every(this.props.workflowRunService.concentrationDictionary, c => c !== undefined && c !== null);
                if (!allConcentrationDataSet)
                {
                    this.setState({ messageSnackbarOpen: true, messageContent: "Please enter required data. Sample concentrations are missing."});
                }
                else
                {
                    this.completeMoveToNextStep();
                }
            }
        }
    }

    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];

        if (currentStep && plates.data && currentWorkflowRun) {
            // check concentration thresholds for controls and samples
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                
                switch (qc.QCCheckType) {
                    case AmpliconQuantificationNTCConcentrationCheck:
                        const ntcs = _.filter(this.state.samples, (sample) => (sample.SampleId === 'NTC'));
                        _.forEach(ntcs, ntc => {
                            if(ntc.WellContentId && qc.CheckConfig) {
                                let concentration = this.props.workflowRunService.concentrationDictionary[ntc.WellContentId];
                                let concentrationValue = concentration && concentration.Concentration ? concentration.Concentration : 0;
                                let qcParam = this.getQcParam(AmpliconQuantificationNTCConcentrationCheck, NTCMaxConcentration);
                                let ntcPass = true;

                                if(qcParam && concentration && qcParam.Value < concentrationValue) {
                                    failedQCs.push(qc);
                                    ntcPass = false;
                                }

                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: 1, 
                                        MeasuredValue: concentration ? concentration.toString() : "0",
                                        Date: new Date(Date.now()),
                                        Pass: ntcPass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            }
                        });
                        break;
                    case AmpliconQuantificationPTCConcentrationCheck:
                        const ptcs = _.filter(this.state.samples, (sample) => (sample.SampleId === 'PTC'));
                        _.forEach(ptcs, ptc => {
                            if(ptc.WellContentId && qc.CheckConfig) {
                                let concentration = this.props.workflowRunService.concentrationDictionary[ptc.WellContentId];
                                let concentrationValue = concentration && concentration.Concentration ? concentration.Concentration : 0;
                                let qcParam = this.getQcParam(AmpliconQuantificationPTCConcentrationCheck, PTCMinConcentration);
                                let ptcPass = true;
                                
                                if(qcParam && concentration && qcParam.Value >= concentrationValue) {
                                    failedQCs.push(qc);
                                    ptcPass = false;
                                }

                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: 1, 
                                        MeasuredValue: concentration ? concentration.toString() : "0",
                                        Date: new Date(Date.now()),
                                        Pass: ptcPass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            }
                        });
                        break;
                    case AmpliconQuantificationSampleConcentrationCheck:
                        const samples = _.filter(this.state.samples, (sample) => (sample.SampleId != 'PTC' && sample.SampleId != 'NTC'));
                        _.forEach(samples, sample => {
                            if(sample.WellContentId && qc.CheckConfig) {
                                let concentration = this.props.workflowRunService.concentrationDictionary[sample.WellContentId];
                                let concentrationValue = concentration && concentration.Concentration && concentration.Concentration >= 1 ? concentration.Concentration : 0;
                                let qcParam = this.getQcParam(AmpliconQuantificationSampleConcentrationCheck, SampleMinConcentration);
                                let samplePass = true;
                                
                                if(qcParam && concentration && qcParam.Value >= concentrationValue) {
                                    failedQCs.push(qc);
                                    samplePass = false;
                                }

                                qcResults.push(
                                    {
                                        Id: "",
                                        FailureActionStatus: 1, 
                                        MeasuredValue: concentration ? concentration.toString() : "0",
                                        Date: new Date(Date.now()),
                                        Pass: samplePass,
                                        QCCheckInstance: qc,
                                    }
                                );
                            }
                        });
                        break;
                }
            });

            if (failedQCs.length > 0) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                await Promise.all([
                    this.props.workflowRunService.saveConcentrations(),
                ]);
                await this.props.workflowRunService.completeStep();
            }
        }
    }

    getQcParam(qcCheckType: string, qcParamId: string) {
        const qcParams = this.state.qcConfig[qcCheckType];
        const qcParam = _.find(qcParams, (param) => param.Id === qcParamId);
        return qcParam;
    }
}

export const QubitQuantificationScreen = FileService.inject(_QubitQuantificationScreen);