import { C3Chart, DataLoadingDisplay} from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    C3
} from "$Imports/Imports";
import { Card } from "$Imports/MaterialUIComponents";


import { IDashboardServiceInjectedProps, DashboardService } from "$State/DashboardFreezerService";

interface IYearToDateSampleInfoProps {
    id: string;
    width: number;
}

const styles: {
    cardStyle: string;
    headerStyle: string;
} = require("./WidgetCommon.scss");

type YearToDateSampleInfoProps = IDashboardServiceInjectedProps & IYearToDateSampleInfoProps;

class _YearToDateSampleInfo extends React.Component<YearToDateSampleInfoProps> {

    async componentDidMount() {
        await this.props.dashboardService.fetchYearToDateInfo();
    }

    render() {
        
        var d = this.props.dashboardService.getState().yearToDateInfoFetchResults.data;
        
        if (d){
            
            return (
                <Card className={styles.cardStyle} style={{width: this.props.width + "%"}}>
                
                    <h3 className={styles.headerStyle}>
                        Year-to-Date Total
                    </h3>
                    <C3Chart
                        data={
                            {
                                type: "line",
                                columns: [
                                ["Total Samples", null, ...d.TotalSamples],
                                ["Total Runs", null, ...d.TotalRuns]]
                            }}
                        id={this.props.id}
                        colors={["#293e6b", "#d95d39"]}
                        axis={{
                            y: {
                                label: {
                                    text: 'Total Quantity',
                                    position: 'outer-middle'
                                }
                            },
                            x: {
                                tick: {
                                    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                                },
                                label: {
                                    text: 'Month',
                                    position: 'outer-center'
                                    
                                },
                            }
                        }}
                    />
                
                </Card>
            )
        }
        
        return <DataLoadingDisplay />;
    
    }
}

export const YearToDateSampleInfo = DashboardService.inject(_YearToDateSampleInfo);