import {
    React,
    _,
    bind,
    ReactGA
} from "$Imports/Imports";

const styles: {
    searchDiv: string;
    searchBox: string;
    searchButton: string;
    mainContainer: string;
    cardStyle: string;
    tableHeader: string;
    dialog: string;
    content: string;
    button: string;
} = require("./WorkflowsQCChecks.scss");

import { QCService, IQCServiceInjectedProps } from "$State/QCFreezerService";
import {
    Card,
    Button,
    TableRow,
    Dialog,
    Link,
    TextField,
    Checkbox
} from "$Imports/MaterialUIComponents";
import { AdvanceTextField, DataTable, IDataTableColumn, ProcessAdminRole } from "$Imports/CommonComponents";
import { QCCheckConfigParametersVM, QCCheckVM } from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

interface IWorkflowQCChecksPageBaseProps {
    workflowId: string;
    workflowName: string;
}

interface IWorkflowQCChecksState {
    stepNameSearch: string;
    detailsToDisplay: QCCheckVM;
    readOnlyMode: boolean;
    popupOpen: boolean;
}

type IWorkflowQCChecksPageProps = IWorkflowQCChecksPageBaseProps & IQCServiceInjectedProps;

export class _WorkflowQCChecksPage extends React.Component<IWorkflowQCChecksPageProps, IWorkflowQCChecksState> {

    state: IWorkflowQCChecksState = {
        stepNameSearch: "",
        detailsToDisplay: {
            CheckConfig: {
                Parameters: []
            },
            Description: "",
            Enabled: false,
            Id: "",
            QCAction: "",
            QCCategory: "",
            QCName: "",
            StepName: ""
        },
        readOnlyMode: false,
        popupOpen: false
    }

    private readonly columns: Array<IDataTableColumn<QCCheckVM>> = [
        {
            columnName: "step-name",
            columnFieldData: "StepName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Step Name",
            sortMethod: (d) => d.StepName ?? ""
        },
        {
            columnName: "qc-name",
            columnFieldData: (d) =>
                <div onClick={() => { this.showQCDetails(d, true); }}>
                    <u>{d.QCName}</u>
                </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "QC Name",
            sortMethod: (d) => d.QCName ?? ""
        },
        {
            columnName: "QC Category",
            columnFieldData: "QCCategory",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "QC Category",
            sortMethod: (d) => d.QCCategory ?? ""
        },
        {
            columnName: "qc-failure-action",
            columnFieldData: "QCAction",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "QC Failure Action",
            sortMethod: (d) => d.QCAction ?? ""
        },

        {
            columnName: "qc-enabled",
            columnFieldData: (d) => <Checkbox
                key={d.QCName}
                checked={d.Enabled}
                disabled={true}
            />,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Enabled",
            sortMethod: (d) => d.Enabled ?? ""
        },
        {
            columnName: "edit",
            columnFieldData: (d) => {
                return <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.showQCDetails(d, false); }}>
                            Edit
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        }
    ]

    componentDidMount() {
        this.loadData();
    }

    render() {
        const data = this.props.QCService.getState().fetchQCChecksResults.data;

        return <ApplicationSecurityContext.Consumer>
            {value => (
                <div
                    className={styles.mainContainer}
                >
                    <Card
                        className={styles.cardStyle}
                    >
                        <div>
                            <h3>QC Checks - {this.props.workflowName}</h3>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div className={styles.searchDiv}>
                                    <TextField className={styles.searchBox} label="Step Name" value={this.state.stepNameSearch} onChange={(event) => { this.setState({ stepNameSearch: event.target.value }) }} />
                                </div>
                                <Button className={styles.searchButton} variant="contained" color="primary" onClick={() => { this.loadData(); }}>
                                    Search
                                </Button>
                            </div>
                            {data &&
                                <div style={{ height: "600px", overflow: "auto" }}>
                                    <DataTable
                                        data={data}
                                        columns={this.columns}
                                        stickyHeader={true}
                                    />
                                </div>}
                        </div>
                        <Dialog
                            className={styles.dialog}
                            open={this.state.popupOpen}
                            onClose={this.handleClose}
                        >
                            <div className={styles.content}>
                                <h1>
                                    QC Check - {this.state.detailsToDisplay?.QCName}
                                </h1>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ paddingRight: "20px" }}>
                                                Description:
                                            </td>
                                            <td>
                                                {this.state.detailsToDisplay?.Description}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingRight: "20px" }}>
                                                Step:
                                            </td>
                                            <td>
                                                {this.state.detailsToDisplay?.StepName}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {
                                    this.state.detailsToDisplay?.CheckConfig &&
                                    this.state.detailsToDisplay?.CheckConfig.Parameters &&
                                    <div>
                                        <h2>
                                            QC Check Detail
                                        </h2>
                                        {_.map(this.state.detailsToDisplay?.CheckConfig.Parameters, p =>
                                            <div key={p.Id}>
                                                <AdvanceTextField
                                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.readOnlyMode}
                                                    InputLabelProps={{ shrink: true }}
                                                    label={p.Display}
                                                    value={p.Value}
                                                    onDebouncedChange={(value) => { this.onTextChanged(p.Id, value) }} />
                                            </div>)}
                                    </div>
                                }
                                <h2>
                                    QC Check Failure Action
                                </h2>
                                <div>
                                    {this.state.detailsToDisplay?.QCAction}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h2>
                                        QC Enabled
                                    </h2>
                                    <Checkbox
                                        checked={this.state.detailsToDisplay?.Enabled}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.readOnlyMode}
                                        onChange={(event) => { this.onCheckChange(event.target.checked) }}
                                    />
                                </div>
                                {!(this.state.readOnlyMode || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1) && <div style={{ display: "flex", flexDirection: "row" }}>
                                    <Button variant="contained" className={styles.button} onClick={this.saveData}>
                                        Save
                                    </Button>
                                    <Button variant="contained" className={styles.button} onClick={this.handleClose}>
                                        Cancel
                                    </Button>
                                </div>}
                            </div>
                        </Dialog>
                    </Card>
                </div>
            )}
        </ApplicationSecurityContext.Consumer>;
    }

    @bind
    private async loadData() {
        await this.props.QCService.fetchQCChecks(this.props.workflowId, this.state.stepNameSearch);
    }

    @bind
    private async showQCDetails(qc: QCCheckVM, readOnly: boolean) {
        this.setState({ detailsToDisplay: qc, readOnlyMode: readOnly, popupOpen: true });
    }

    @bind
    private onTextChanged(id: string, newVal: string) {
        //A lot of these values are readonly, so we need to work around, and keep parameters in the same order
        let check = this.state.detailsToDisplay;
        if (check) {
            let params = check.CheckConfig ? check.CheckConfig.Parameters : [];
            let param = _.find(params, p => p.Id === id);
            if (param) {
                let index = _.indexOf(params, param)
                let newParams: QCCheckConfigParametersVM[] = [];
                for (let i = 0; i < params.length; i++) {
                    if (i === index) {
                        newParams.push({
                            Display: params[i].Display,
                            Id: params[i].Id,
                            IsReadOnly: params[i].IsReadOnly,
                            Value: newVal
                        });
                    }
                    else {
                        newParams.push(params[i]);
                    }
                }

                this.setState({
                    detailsToDisplay: {
                        Description: check.Description,
                        Enabled: check.Enabled,
                        Id: check.Id,
                        QCAction: check.QCAction,
                        QCCategory: check.QCCategory,
                        QCName: check.QCName,
                        StepName: check.StepName,
                        CheckConfig: {
                            Parameters: newParams
                        }
                    }
                });
            }


        }
    }

    @bind
    private onCheckChange(enabled: boolean) {
        let check = this.state.detailsToDisplay;
        if (check) {
            this.setState({
                detailsToDisplay: {
                    Description: check.Description,
                    Enabled: enabled,
                    Id: check.Id,
                    QCAction: check.QCAction,
                    QCCategory: check.QCCategory,
                    QCName: check.QCName,
                    StepName: check.StepName,
                    CheckConfig: check.CheckConfig
                }
            });
        }
    }

    @bind
    private async saveData() {
        await this.props.QCService.saveQCCheck(this.state.detailsToDisplay).then(
            response => {
                this.loadData();
                this.handleClose();
            }
        );
    }

    @bind
    private handleClose() {
        this.setState({ popupOpen: false });
    }
}

export const WorkflowQCChecksPage = QCService.inject(_WorkflowQCChecksPage);