import {
    React,
    bind
} from "$Imports/Imports";

import {
    AppBar,
    Toolbar,
    Button,
    Menu,
    MenuItem,
    IconButton,
} from "$Imports/MaterialUIComponents";

import {
    LLIcon
} from "$Imports/CommonIcons";


import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { style } from "@material-ui/system";

import {
    SettingsService,
    ISettingsServiceInjectedProps
} from "$State/SettingsFreezerService";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { IUserContext } from "$Utilities/Security/IUserContext";
import { Home } from "$Imports/MaterialUIIcons";
import { NavigationService, INavigationServiceInjectedProps } from "$State/NavigationFreezerService";

interface IApplicationBarBaseProps {
    user: IUserContext | null;
    logout: () => void;
    showLaunchpadButton: boolean;
}

interface IApplicationBarState {
    anchorEl: HTMLElement | null;
}

type IApplicationBarProps = IApplicationBarBaseProps & ISettingsServiceInjectedProps & INavigationServiceInjectedProps;

const styles: {
    applicationBarContainer: string;
    applicationBarItems: string;
    applicationBarTitle: string;
    logo: string;
} = require("./MainLayout.scss");

export class _ApplicationBar extends React.Component<IApplicationBarProps, IApplicationBarState> {


    state: IApplicationBarState = {
        anchorEl: null
    };

    private _security: ApplicationSecuritySettings = new ApplicationSecuritySettings();

    async componentDidMount() {
        await this.props.settingsService.fetchSettings();
        await this.props.settingsService.fetchPlatformVersion();
    }

    render() {
        const versionInfo = this.props.settingsService.getState().settingsFetchResults.data;
        const platformVersion = this.props.settingsService.getState().platformVersion.data;
        const settings = this.props.settingsService.getState().systemSettings.data;
        var siteName = settings != null ? settings["site_name"] || "" : "";
        var URL = this.props.navigationService.getState().url;

        return (
            <AppBar
                position="fixed" style={{top:0}}
            >
                <Toolbar>
                    <div className={styles.applicationBarContainer}>
                        <div className={styles.applicationBarItems}>
                            {this.props.showLaunchpadButton && <Button
                                style={URL === "/launchpad" ?
                                    {marginTop:"5px", color:"white", borderColor:"white", width: "250px", height:"60px", backgroundColor: "#293e6b"} :
                                    {marginTop:"5px", color:"white", borderColor:"white", width: "250px", height:"60px"}
                                }
                                variant="outlined"
                                startIcon={<Home />}
                                onClick={() => {NavigationService.navigateTo("/launchpad");}}
                            >
                                LIMS Lite Launchpad
                            </Button>}
                        </div>
                        <div className={styles.applicationBarTitle}>
                            <img src={LLIcon} alt="LimsLite Icon Logo" className={styles.logo} />
                            <h2>{siteName}</h2>
                        </div>
                        <div className={styles.applicationBarItems}>
                            <IconButton onClick={this.handleClick}>
                                <AccountCircleIcon style={{ color: "white" }} fontSize={"large"} />
                            </IconButton>
                        </div>
                    </div>
                    <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={this.state.anchorEl !== null}
                        onClose={this.handleClose}
                    >
                        <MenuItem disabled={true}> {this.props.user ? this.props.user.preferred_username : ""}</MenuItem>
                        <MenuItem onClick={this.props.logout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        );
    }

    @bind
    private handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ anchorEl: event.currentTarget });
    }

    @bind
    private handleClose() {
        this.setState({ anchorEl: null });
    }
}

export const ApplicationBar = NavigationService.inject(SettingsService.inject(_ApplicationBar));
