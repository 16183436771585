import { 
    GriddingScreen, 
    RNAExtractionScreen, 
    AmplificationScreen, 
    RtPCRScreen, 
    RegridScreen, 
    BarcodingScreen, 
    CleanupScreen, 
    EndRepairScreen, 
    QuantificationScreen, 
    PoolQuantificationScreen, 
    PoolingScreen, 
    LigationScreen, 
    CleanupAfterLigationScreen, 
    PrepFlowCellScreen, 
    RtPCRQuantificationScreen, 
    SetupMinIONInstrumentScreen, 
    CollectGenomicsDataScreen, 
    BarcodingPrimerPrepScreen, 
    ImageStepScreen, 
    TwoMasterMixPCRStepScreen, 
    AmpliconDilutionStepScreen, 
    QubitQuantificationScreen, 
    IlluminaPoolingScreen, 
    SetupIlluminaInstrumentStepScreen, 
    CleanupWithPCRScreen, 
    FluorometricQuantificationScreen,
    SpectrophotometerQCScreen,
    GelElectrophoresisScreen,
    SinglePooledMasterMixStep,
    BioanalyzerQualification,
    EnrichedLibrariesCheck,
    HybridizeProbes,
    InitialDilutionNBS,
    CollectGenomicsDataNBSScreen,
    NbsPoolingScreen,
    FinalDilutionStepScreen
} from "./Steps";
import {IWorkflowRunServiceInjectedProps, WorkflowRunFreezerService, WorkflowRunService} from "$State/WorkflowRun/WorkflowRunFreezerService";
import { InfluenzaWorkflowId, SarsCov2WorkflowId, AnthraxWorkflowId, WorkflowType, AssetType, NBSWorkflowId } from "$Imports/CommonComponents";
import { _ } from "$Imports/Imports"

interface IWorkflowScreenBaseProps {
    failRun: () => void;
    saveScreen: () => Promise<void>;
    viewMode?: boolean;
    stepId: string;
}

export type IWorkflowScreenProps = IWorkflowScreenBaseProps & IWorkflowRunServiceInjectedProps;

export interface IWorkflowScreenDefinition {
    screen: React.ComponentClass<IWorkflowScreenProps> | React.FunctionComponent<IWorkflowScreenProps>,
    path: string;
    saveActions?: Array<(service: WorkflowRunFreezerService) => void>;
}


export type SaveAction = (service: WorkflowRunFreezerService) => void;

export const SaveActions: { [key: string]: SaveAction } = {
    saveMasterMix: async (service: WorkflowRunFreezerService) => { await service.saveMasterMix(false); },
    savePCRInfo: async (service: WorkflowRunFreezerService) => { await service.savePCRInfo(); },
    savePlateSamples: async (service: WorkflowRunFreezerService) => { await service.plateSamples(); },
    saveCustomFields: async (service: WorkflowRunFreezerService) => { await service.updateCustomFields(); },
    saveConcentrations: async (service: WorkflowRunFreezerService) => { await service.saveConcentrations(); },
    saveRtPCRState: async (service: WorkflowRunFreezerService) => { await service.updateRtPCRState(); },
    saveControls: async (service: WorkflowRunFreezerService) => { await service.saveWells(); },
    saveCleaningData: async (service: WorkflowRunFreezerService) => { await service.saveCleaningData(false); },
    saveBarcodeKit: async (service: WorkflowRunFreezerService) => { await service.associateBarcodeKitToRun(); },
    saveAssetContents: async (service: WorkflowRunFreezerService) => { await service.saveWellsToAsset(); }
}



/*
    Map of available workflow screens, each screen corresponding to a specific workflow step. The key corresponds to the Step Type Id and will be used to
    render the correct workflow step screen based on the workflow step the user is on. When adding new workflow step screens, update this list.
*/
export const WorkflowScreens: { [key: string]: IWorkflowScreenDefinition } = {
    "1eb0a0c8-5db3-4e4e-8c41-eb7b01a3be03": {
        screen: GriddingScreen,
        path: "WorkflowRun/Gridding",
        saveActions: [SaveActions.saveAssetContents]
    },
    "f00ec3ee-4aa6-4ef0-9251-8c6d2e11cc8b": {
        screen: RNAExtractionScreen,
        path: "WorkflowRun/RNAExtraction",
        saveActions: [SaveActions.saveCustomFields]
    },
    "d6aca4c5-8fae-4367-8379-27d1355ebd03": {
        screen: RtPCRScreen,
        path: "WorkflowRun/RtPCR",
        saveActions: [
            SaveActions.savePCRInfo,
            SaveActions.saveMasterMix
        ]
    },
    "9027d488-d16f-4390-a3a9-e29ddbc4b99f": {
        screen: RtPCRQuantificationScreen,
        path: "WorkflowRun/RtPCRQuantification",
        saveActions: [
            SaveActions.saveRtPCRState,
            SaveActions.saveCustomFields
        ]
    },
    "b32eed71-748a-413b-9311-37fb4719f9af": {
        screen: RegridScreen,
        path: "WorkflowRun/Regridding",
        saveActions: []
    },
    "e77d2388-a168-4cc4-b4e0-b5af57d17c68": {
        screen: AmplificationScreen,
        path: "WorkflowRun/Amplification",
        saveActions: [
            SaveActions.saveMasterMix,
            SaveActions.savePCRInfo
        ]
    },
    "00762808-bfd7-420f-9591-0be6fb71fe90": {
        screen: CleanupScreen,
        path: "WorkflowRun/Cleanup",
        saveActions: [
            SaveActions.saveCleaningData,
            SaveActions.saveCustomFields
        ]
    },
    "b46e8c93-7a6a-43ef-85b8-c0b103efa2b9": {
        screen: BarcodingPrimerPrepScreen,
        path: "WorkflowRun/BarcodingPrimerPrep",
        saveActions: [
            SaveActions.saveBarcodeKit
        ],
    },
    "fb6f7465-af5c-4610-a9a1-752e94c80a4b": {
        screen: BarcodingScreen,
        path: "WorkflowRun/Barcoding",
        saveActions: [
            SaveActions.savePCRInfo,
            SaveActions.saveMasterMix,
            SaveActions.saveCustomFields,
        ],
    },
    "fd9992e6-79e8-42ee-9a90-a556abfd50c2": {
        screen: QuantificationScreen,
        path: "WorkflowRun/Quantification",
        saveActions: [
            SaveActions.saveCustomFields,
            SaveActions.saveConcentrations,
        ]
    },
    "8be11e2d-74eb-4001-a5d9-63896c38d8ad": {
        screen: PoolingScreen,
        path: "WorkflowRun/Pooling",
        saveActions: [
            SaveActions.saveCustomFields
        ]
    },
    "c508bc93-729b-4110-bc11-47d96077e103": {
        screen: EndRepairScreen,
        path: "WorkflowRun/EndRepair",
        saveActions: [
            SaveActions.saveMasterMix,
            SaveActions.savePCRInfo
        ]
    },
    "d7bf3b42-acbc-4a11-84aa-573c5abb0374": {
        screen: CleanupScreen,
        path: "WorkflowRun/Cleanup",
        saveActions: [
            SaveActions.saveCleaningData,
            SaveActions.saveCustomFields
        ]
    },
    "61ceee1c-663a-4826-8782-f4b06b8449e1": {
        screen: PoolQuantificationScreen,
        path: "WorkflowRun/PoolQuantification",
        saveActions: [
            SaveActions.saveCustomFields
        ]
    },
    "eade5e8e-2b3b-43be-85dd-97d660ffa24d": {
        screen: LigationScreen,
        path: "WorkflowRun/Ligation",
        saveActions: [
            SaveActions.saveCustomFields,
            SaveActions.saveMasterMix,
            SaveActions.savePCRInfo
        ]
    },
    "fe7bca78-7cf5-4e02-b467-4fa5b80aca4a":
    {
        screen: SetupMinIONInstrumentScreen,
        path: "WorkflowRun/SetupMinIONInstrument",
        saveActions: []
    },
    "8f3f4df9-5131-462d-99b8-646bc859f4ee": {
        screen: PrepFlowCellScreen,
        path: "WorkflowRun/PrepFlowCell",
        saveActions: [
            SaveActions.saveCustomFields,
            SaveActions.saveMasterMix,
            SaveActions.saveCleaningData
        ]
    },
    "e8cebca1-06fb-4ba4-9822-a296db34903b":
    {
        screen: CollectGenomicsDataScreen,
        path: "WorkflowRun/CollectGenomicsData",
        saveActions: []
    },
    "4fa5f0e0-1a2b-46d3-b679-20edd8264d1f":
    {
        screen: ImageStepScreen,
        path: "WorkflowRun/ImageStep",
        saveActions: []
    },
    "3eb72a28-c299-4b45-86ce-b26d145402ac":
    {
        screen: TwoMasterMixPCRStepScreen,
        path: "WorkflowRun/TwoMasterMixPCRStep",
        saveActions: [
            SaveActions.saveMasterMix,
            SaveActions.savePCRInfo
        ]
    },
    "61211592-0e57-4b03-b248-1576ee87b7a4":
    {
        screen: SetupIlluminaInstrumentStepScreen,
        path: "WorkflowRun/SetupIlluminaInstrumentStepScreen",
        saveActions: [
            SaveActions.saveCustomFields
        ]
    },
    "04e4e684-8798-40fe-ab03-8ee3f52909ea": 
    {
        screen: QubitQuantificationScreen,
        path: "WorkflowRun/QubitQuantification",
        saveActions: [
            SaveActions.saveConcentrations
        ]
    },
    "decb4aec-fd56-483d-a50e-037233e687eb":
    {
        screen: AmpliconDilutionStepScreen,
        path: "WorkflowRun/AmpliconDilution",
        saveActions: []
    },
    "60c81ada-a894-48c4-beda-0e34a470c965": 
    {
        screen: CleanupWithPCRScreen,
        path: "WorkflowRun/CleanupWithPCR",
        saveActions: [
            SaveActions.saveCleaningData,
            SaveActions.saveCustomFields,
            SaveActions.savePCRInfo
        ]
    },
    "ddae667c-1a55-498d-9292-5781d7fd26e2":
    {
        screen: IlluminaPoolingScreen,
        path: "WorkflowRun/IlluminaPooling",
        saveActions: []
    },
    "7ddd8cd3-85e7-42c0-9e0d-268004cab84d":
    {
        screen: FluorometricQuantificationScreen,
        path: "WorkflowRun/QubitQuantification",
        saveActions: [
            SaveActions.saveConcentrations
        ]
    },
    "922027fa-8e1c-4368-9f39-b235dc04d01b":
    {
        screen: SpectrophotometerQCScreen,
        path: "WorkflowRun/SpectrophotometerQC",
        saveActions: [
            SaveActions.saveConcentrations,
            SaveActions.saveCustomFields
        ]
    },
    "7e136a28-9a38-419b-b250-080a8e93ff76":
    {
        screen: GelElectrophoresisScreen,
        path: "WorkflowRun/GelElectophoresis",
        saveActions: [
            SaveActions.saveCustomFields
        ]
    },
    "26a48a1a-8738-489d-853b-f61581aae84a":
    {
        screen: SinglePooledMasterMixStep,
        path: "WorkflowRun/SinglePooledMasterMixStep",
        saveActions: [
            SaveActions.saveMasterMix
        ]
    },
    "c8678bec-6cac-46aa-9e41-2572a730575c":
    {
        screen: BioanalyzerQualification,
        path: "WorkflowRun/BioanalyzerQualificationStep",
        saveActions: [
            SaveActions.saveMasterMix,
            SaveActions.saveCustomFields
        ]
    },
    "0b2617e4-553f-4a8c-804a-dfaf0751e91d":
    {
        screen: EnrichedLibrariesCheck,
        path: "WorkflowRun/EnrichedLibrariesCheckStep",
        saveActions: [
            SaveActions.saveMasterMix,
            SaveActions.saveCustomFields
        ]
    },
    "81eb2bbe-cc51-40d7-b728-d2277e088899":
    {
        screen: HybridizeProbes,
        path: "WorkflowRun/HybridizeProbes",
        saveActions: [
            SaveActions.saveMasterMix,
            SaveActions.saveCustomFields,
            SaveActions.savePCRInfo

        ]
    },
    "324e3dc9-a8a0-4983-a8da-78e1fdedec64":
    {
        screen: InitialDilutionNBS,
        path: "WorkflowRun/InitialDilutionNBS",
        saveActions: [
            SaveActions.saveCustomFields
        ]
    },
    "40a31e30-fba8-4f48-b1f8-5393544a77d1":
    {
        screen: CollectGenomicsDataNBSScreen,
        path: "WorkflowRun/CollectGenomicsDataNBS",
        saveActions: []
    },
    "ceb719ed-7fa8-42f8-b416-1c8717a36701":
    {
        screen: NbsPoolingScreen,
        path: "WorkflowRun/NbsPooling",
        saveActions: []
    },
    "a01df8a6-7253-4f63-a5db-87f9b466a107":
    {
        screen: FinalDilutionStepScreen,
        path: "WorkflowRun/FinalDilutionStep",
        saveActions: []
    }
}


export function GetWorkflowType(): WorkflowType {
    const currentWorkflowRun = WorkflowRunService.currentWorkflowRun;
    switch (currentWorkflowRun?.WorkflowId) {
        case InfluenzaWorkflowId:
            return "Influenza";
        case SarsCov2WorkflowId:
            return "SarsCov2";
        case AnthraxWorkflowId:
            return "Anthrax";
        case NBSWorkflowId:
            return "NBS";
    }
    return "Influenza";
}

export function GetCurrentStepInputType() {
    const currentStep = WorkflowRunService.currentStep;
    if(currentStep) {
        let inputAsset = _.find(currentStep.InputAssets, asset => (asset.Name === currentStep!.InputName));
        return inputAsset?.AssetType;
    }
    return null;
}

export function GetCurrentStepOutputType() {
    const currentStep = WorkflowRunService.currentStep;
    if(currentStep) {
        let outputAsset = _.find(currentStep.OutputAssets, asset => (asset.Name === currentStep!.OutputName));
        return outputAsset?.AssetType;
    }
    return null;
}