import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil
} from "$Imports/Imports";

import {
    GetVersionInfoResponse, SettingsApiFactory, LaunchpadSiteVM, UserPreferencesVM, DemoInformationResponse, GetGATrackingIdResponse, GetPlatformVersionResponse, ResetEnvironmentResponse, PollResetStatusResponse, WorkflowApiFactory, WorkflowVM
} from "$Generated/api";


interface ISettingsState {
    settingsFetchResults: IAjaxState<GetVersionInfoResponse>;
    diskSpaceResults: IAjaxState<number>;
    limsSyncUpResults: IAjaxState<boolean>;
    launchpadSitesResults: IAjaxState<LaunchpadSiteVM[]>;
    MIASiteResult: IAjaxState<LaunchpadSiteVM>;
    limsImplementationSetResults: IAjaxState<boolean>;
    demoEnvironmentResults: IAjaxState<boolean>;
    userPreferenceResults: IAjaxState<UserPreferencesVM>;
    demoEnvironmentInformation: IAjaxState<DemoInformationResponse>;
    isLimsSetResults: IAjaxState<boolean>;
    limsNamesResults: IAjaxState<string[]>;
    setLimsResults: IAjaxState<boolean>;
    setUserPreferenceResults: IAjaxState<boolean>;
    seenWelcomePage: boolean;
    resetEnvironmentResults: IAjaxState<ResetEnvironmentResponse>;
    pollResetStatus: IAjaxState<PollResetStatusResponse>;
    GATrackingIdResults: IAjaxState<GetGATrackingIdResponse>;
    platformVersion: IAjaxState<GetPlatformVersionResponse>;
    installedWorkflows: IAjaxState<WorkflowVM[]>;
    NextstrainSiteResult: IAjaxState<LaunchpadSiteVM>;
    systemSettings: IAjaxState<{[key: string]: string}>;
    setSystemSettingsResult: IAjaxState<boolean>;
}

const InjectedPropName = "settingsService";

class SettingsFreezerService extends FreezerService<ISettingsState, typeof InjectedPropName> {
    constructor() {
        super({
            settingsFetchResults: managedAjaxUtil.createInitialState(),
            diskSpaceResults: managedAjaxUtil.createInitialState(),
            limsSyncUpResults: managedAjaxUtil.createInitialState(),
            launchpadSitesResults: managedAjaxUtil.createInitialState(),
            limsImplementationSetResults: managedAjaxUtil.createInitialState(),
            MIASiteResult: managedAjaxUtil.createInitialState(),
            demoEnvironmentResults: managedAjaxUtil.createInitialState(),
            userPreferenceResults: managedAjaxUtil.createInitialState(),
            demoEnvironmentInformation: managedAjaxUtil.createInitialState(),
            limsNamesResults: managedAjaxUtil.createInitialState(),
            isLimsSetResults: managedAjaxUtil.createInitialState(),
            setLimsResults: managedAjaxUtil.createInitialState(),
            setUserPreferenceResults: managedAjaxUtil.createInitialState(),
            seenWelcomePage: false,
            resetEnvironmentResults: managedAjaxUtil.createInitialState(),
            pollResetStatus: managedAjaxUtil.createInitialState(),
            platformVersion: managedAjaxUtil.createInitialState(),
            GATrackingIdResults: managedAjaxUtil.createInitialState(),
            installedWorkflows: managedAjaxUtil.createInitialState(),
            NextstrainSiteResult: managedAjaxUtil.createInitialState(),
            systemSettings: managedAjaxUtil.createInitialState(),
            setSystemSettingsResult: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public get seenWelcomePage(): boolean {
        return this.getState().seenWelcomePage;
    }

    public set seenWelcomePage(val: boolean) {
        this.freezer.get().set({seenWelcomePage: val});
    }

    public async fetchSettings(forceUpdate?: boolean)
    {
        const { settingsFetchResults } = this.freezer.get();

        if (settingsFetchResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "settingsFetchResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsVersionInfoGet();
            },
        });
    }

    public async fetchDiskSpace(forceUpdate?: boolean)
    {
        const { diskSpaceResults } = this.freezer.get();

        if (diskSpaceResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "diskSpaceResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsAvailableSpaceGet();
            },
        });
    }

    public async limsSyncUp()
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "limsSyncUpResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsLimsSyncUpGet();
            },
        });
    }

    public async limsImplementationSet()
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "limsImplementationSetResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsLimsImplementationSetGet();
            },
        });
    }

    public async fetchDemoEnvironmentSet() {
        const { demoEnvironmentResults } = this.freezer.get();

        if (demoEnvironmentResults.hasFetched) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "demoEnvironmentResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsDemoExperienceGet();
            },
        });
    }

    public async fetchDemoEnvironmentInformation() {
        const { demoEnvironmentInformation } = this.freezer.get();

        if (demoEnvironmentInformation.hasFetched) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "demoEnvironmentInformation",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsDemoInformationGet();
            },
        });
    }

    public async fetchUserPreferences(forceUpdate?: boolean) {
        const { userPreferenceResults } = this.freezer.get();

        if (userPreferenceResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "userPreferenceResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsUserPreferencesGet();
            },
        });
    }

    
    public async fetchLaunchpadSites(forceUpdate?: boolean) {
        const { launchpadSitesResults } = this.freezer.get();

        if (launchpadSitesResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "launchpadSitesResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsLaunchpadSitesGet();
            },
        });
    }

    
    public async fetchMIASite(forceUpdate?: boolean) {
        var { MIASiteResult } = this.freezer.get();
        
        if (MIASiteResult.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "MIASiteResult",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsMIASiteGet();
            },
        });
    }

    public async fetchNextstrainSite(forceUpdate?: boolean) {
      var { NextstrainSiteResult } = this.freezer.get();
      
      if (NextstrainSiteResult.hasFetched && !forceUpdate) {
          return;
      }

      await managedAjaxUtil.fetchResults({
          freezer: this.freezer,
          ajaxStateProperty: "NextstrainSiteResult",
          onExecute: (apiOptions, param, options) => {
              const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
              return factory.apiV1SettingsNextstrainSiteGet();
          },
      });
  }

    public async fetchLimsSet(forceUpdate?: boolean) {
        const { isLimsSetResults } = this.freezer.get();

        if (isLimsSetResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "isLimsSetResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsLIMSSetGet();
            },
        });
    }

    public async setLims(limsImplementation: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "setLimsResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsSetLimsImplementationPost({ limsImplementation: limsImplementation });
            },
        });
    }

    public async fetchLimsNames(forceUpdate?: boolean) {
        const { limsNamesResults } = this.freezer.get();

        if (limsNamesResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "limsNamesResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsLIMSNamesGet();
            },
        });
    }

    public async resetEnvironment()
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "resetEnvironmentResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsResetDemoEnvironmentPost();
            },
        });
    }

    public async pollResetStatus(pollToken: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "pollResetStatus",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsPollResetStatusPost({pollToken: pollToken});
            },
        });
    }

    public async setUserPreferences(preferences: UserPreferencesVM) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "setUserPreferenceResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsUserPreferencesPost({body: preferences});
            },
        });
    }
    
    public async fetchGATrackingId(){
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "GATrackingIdResults",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsGATrackingIdGet();
            },
        });
    }

    public async fetchPlatformVersion() {
        const { platformVersion } = this.freezer.get();

        if (platformVersion.hasFetched) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "platformVersion",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsPlatformVersionGet();
            },
        });
    }
    
    public async getInstalledWorkflows(){
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "installedWorkflows",
            onExecute: (apiOptions, param, options) => {
                const factory = WorkflowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1WorkflowGet();
            },
        });
    }

    public async getSystemSettings(forceFetch: boolean = false) {

        const { systemSettings } = this.freezer.get();

        if ((systemSettings.isFetching || systemSettings.hasFetched) && !forceFetch) {
            return;
        }
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "systemSettings",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsSystemSettingsGet();
            }
        })
    }

    public async setSystemSettings(settings: {[key: string]: string}) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "setSystemSettingsResult",
            onExecute: (apiOptions, param, options) => {
                const factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SettingsSystemSettingsPost({body: settings});
            }
        })
    }
}

export const SettingsService = new SettingsFreezerService();
export type ISettingsServiceInjectedProps = ReturnType<SettingsFreezerService["getPropsForInjection"]>;
