import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports";
import
    * as s
    from "underscore.string";

import {
    IWorkflowScreenProps
} from "../WorkflowStep";
import { TextField } from "@material-ui/core";
import { ChangeEvent } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { missingData } from "$Components/common/WorkflowControls/InstructionSetControl";
import { QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";
import { ErrorIcon } from "$Imports/MaterialUIComponents";
import { DataLoadingDisplay, StepActionsControl, StepChangeControl } from "$Imports/CommonComponents";

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

const QubitQuantification = require("../../../images/QubitQuantificationPrep.png");
const AmpliconDilution = require("../../../images/AmpliconDilution.png");
const PrepareSampleSheet = require("../../../images/PrepareSampleSheet.png");
const Pooling = require("../../../images/Pooling.png");

interface IImageScreenState {
}


export class ImageStepScreen extends React.Component<IWorkflowScreenProps, IImageScreenState> {

    state: IImageScreenState = {
    };

    @bind
    private async completeMoveToNextStep() {
        await Promise.all([
            this.props.workflowRunService.completeStep()
        ]);
    }

    render() {
        let {
            currentStep,
            currentWorkflowRun,
        } = this.props.workflowRunService;

        if (currentStep && currentWorkflowRun) {
            const disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return <div>
                <div>
                    {currentStep.StepId === "3769567d-892c-453a-b269-45dbc3c131ca" ?
                        <img src={QubitQuantification} />
                        : currentStep.StepId === "bbe5c5ec-5b9c-41b3-9d3c-4c369ed359b1" ?
                            <img src={AmpliconDilution} />
                            : currentStep.StepId === "5b1c00ab-9570-4ae4-ab60-4afdf5bd8670" ?
                                <img src={PrepareSampleSheet} />
                                : currentStep.StepId === "7d704d6c-5240-42b5-b579-21cc7dffff95" ?
                                    <img src={Pooling} />
                                    : <></>
                    }
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.completeMoveToNextStep} failRun={this.props.failRun} />
                    </div>
                </div>
            </div>;
        }
        return <DataLoadingDisplay />;
    }
}