import { ReagentGroupLotVM, ReagentLotVM, ReagentVM } from "$Generated/api";
import { AdvanceTextField, DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    Link,
    moment
} from "$Imports/Imports";
import { Dialog, TextField, MenuItem, Checkbox, Button, DialogActions, Chip } from "@material-ui/core";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
} = require("../LabAssets.scss");

interface ILotNumberChangeLogBaseProps {
    handleClose: () => void,
    open: boolean,
    lotNumbers: (ReagentLotVM | ReagentGroupLotVM)[];
    name: string;
}

interface ILotNumberChangeLogState {
}

type ILotNumberChangeLogProps = ILotNumberChangeLogBaseProps;

export class LotNumberChangeLog extends React.Component<ILotNumberChangeLogProps, ILotNumberChangeLogState> {

    state: ILotNumberChangeLogState = {
        lotNumberEntry: "",
        lotEntryOpen: false,
        lastId: 0
    }

    private readonly columns: Array<IDataTableColumn<ReagentLotVM | ReagentGroupLotVM>> = [
        {
            columnName: "lot-number",
            columnFieldData: "LotNumber",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Lot Number",
        },
        {
            columnName: "active",
            columnFieldData: (d) => <div>{d.IsActive ? "Active" : "Inactive"}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Active",
        },
        {
            columnName: "created-on",
            columnFieldData: (d) => moment(d.CreatedOn).format("L LT"),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Created On",
        },
        {
            columnName: "created-by",
            columnFieldData: (d) => d.CreatedBy,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Created By",
        },
        {
            columnName: "deactivated-on",
            columnFieldData: (d) => !d.IsActive ? moment(d.DeactivatedOn).format("L LT") : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Deactivated On",
        },
        {
            columnName: "deactivated-by",
            columnFieldData: (d) => !d.IsActive ? d.DeactivatedBy : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Deactivated By",
        },
    ]

    componentDidMount() {
    }

    render() {
        return <Dialog
            className={styles.dialog}
            open={this.props.open}
            onClose={() => { this.props.handleClose() }}
            PaperProps={{ style: { maxWidth: "1200px" } }}
        >
            <div className={styles.content}>
                <h2>Lot Number Change Log - {this.props.name}</h2>
                <DataTable
                    data={this.props.lotNumbers}
                    columns={this.columns}
                />
            </div>
        </Dialog>;
    }
}