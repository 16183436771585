import {
    FreezerService,
    _,
    managedAjaxUtil,
    IAjaxState,
    bind
} from "$Imports/Imports";
import {
    DocumentsApiFactory,
    DocumentsApiFetchParamCreator,
    HttpResponseMessage
} from "../generated/api";
import { apiExceptionHandler } from "$State/ErrorFreezerService";
const InjectedPropName = "PDFViewerService";

export interface IPDFViewerState {
    documentResult: IAjaxState<string>;
}

class PDFViewerFreezerService extends FreezerService<IPDFViewerState, typeof InjectedPropName> {
    constructor() {
        super({
            documentResult: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async getDocument(document: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'documentResult',
            onExecute: async (apiOptions, param, options) => {
                let factory = DocumentsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                let httpResponse = await factory.apiV1DocumentsDocumentGet({fileName: document});
                let response = new Response(httpResponse.body);
                let blob = await response.blob();
                let blobUrl = URL.createObjectURL(blob);
                return blobUrl;
            },
            onError: apiExceptionHandler
        });
    }
    
}

export const PDFViewerService = new PDFViewerFreezerService();
export type IPDFViewerServiceInjectedProps = ReturnType<PDFViewerFreezerService["getPropsForInjection"]>;