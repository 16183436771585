import {
    React,
    _,
    bind
} from "$Imports/Imports";

import {
    Button, Card, Popover, MenuItem, List, ListItem
} from "$Imports/MaterialUIComponents";

import {
    AdvanceTextField
} from "$Imports/CommonComponents";

import {
    AssetVM, AssetType
} from "$Generated/api";

export interface IAssetActionsControlProps {
    open: boolean;
    save: (ids: string[]) => void;
    close: () => void;
    buttonElement: Element | null;
    assets: AssetVM[] | undefined;
    actionString: string;
    type: AssetType;
}

export interface IAssetActionsControlState {
    selectedAssetsDictionary: { [index: string]: boolean };
}

const styles: {
    card: string;
    header: string;
    buttons: string;
    list: string;
} = require("./AssetActionsControl.scss");

export class AssetActionsControl extends React.PureComponent<IAssetActionsControlProps, IAssetActionsControlState> {

    constructor(props: IAssetActionsControlProps) {
        super(props);

        var assetsDictionary: { [index: string]: boolean } = {};

        if (this.props.assets) {
            for (var asset of this.props.assets) {
                assetsDictionary[asset.Id] = false;
            }
        }

        this.state = { selectedAssetsDictionary: assetsDictionary };
    }

    render() {

        return (this.props.buttonElement && this.props.assets &&


            <Popover
                open={this.props.open}
                onClose={this.handleClose}
                anchorEl={this.props.buttonElement}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Card className={styles.card}>
                    <h2 className={styles.header}>
                        {this.props.actionString}
                    </h2>
                    <List
                        id="asset-select"
                        className={styles.list}
                    >
                        {_.filter(this.props.assets, a => {
                            if (a.AssetType === this.props.type) {
                                if (a.PlateSummary && a.PlateSummary.Status !== "InUse") {
                                    return false;
                                }
                                if (a.PoolSummary && a.PoolSummary.Status !== "InUse") {
                                    return false;
                                }
                                return true;
                            }
                            return false;
                        }).map(asset => (
                            <ListItem button key={asset.Id} onClick={event => this.handleChange(asset.Id)} selected={this.state.selectedAssetsDictionary[asset.Id]}>
                                {asset.Name}
                            </ListItem>
                        ))}
                    </List>

                    <div className={styles.buttons}>
                        <Button disabled={_.every(this.state.selectedAssetsDictionary, selected => !selected)} onClick={this.onSave}>Save</Button>
                        <Button onClick={this.props.close}>Close</Button>
                    </div>
                </Card>
            </Popover>
        );

    }

    @bind
    private handleClose() {
        this.props.close();
    }

    @bind
    private handleChange(id: string) {
        let selectedAssetDictionary = _.clone(this.state.selectedAssetsDictionary);
        selectedAssetDictionary[id] = !selectedAssetDictionary[id]; //Toggle the boolean value
        this.setState({ selectedAssetsDictionary: selectedAssetDictionary });
    }

    @bind
    private onSave() {
        const { selectedAssetsDictionary } = this.state;

        const idsToSave: string[] = [];
        for (var assetId in selectedAssetsDictionary) {
            if (selectedAssetsDictionary[assetId]) {
                idsToSave.push(assetId);
            }
        }
        this.props.save(idsToSave);
    }
}