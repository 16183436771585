import { BarcodeKitType, CSVBarcodeVM, MetadataTemplateVM, ReagentGroupLotVM, ReagentGroupVM, ReagentLotVM, ReagentVM } from "$Generated/api";
import { AdvanceTextField, DataLoadingDisplay, DataTable, IDataTableColumn, ProcessAdminRole } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Dialog, TextField, MenuItem, Checkbox, Button, DialogActions, Chip, Link, FormControlLabel, RadioGroup, Radio, FormControl, InputLabel, Select, Popover } from "@material-ui/core";

import {
    HorizontalSplitIcon,
    BlockIcon,
    KeyboardDatePicker,
    DeleteRounded
} from "$Imports/MaterialUIComponents";
import { IUserContext } from "$Utilities/Security/IUserContext";
import { LotNumberChangeLog } from "./LotNumberChangeLog";

import {
    AssetService,
    IAssetServiceInjectedProps
} from "$State/AssetFreezerService";

import {
    CSVService,
    ICSVServiceInjectedProps
} from "$State/CSVFreezerService";

import { FileService, IFileServiceInjectedProps } from "$State/FileFreezerService";
import { ArrowDownward, FileCopyIcon } from "$Imports/MaterialUIIcons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

import { MinIONValidBarcodePositions } from '$Imports/CommonComponents';

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
    table: string;
    formControl: string;
    popOver: string;
    dataTableDiv: string;
    flexRow: string;
} = require("../LabAssets.scss");

interface IEditBarcodeKitBaseProps {
    handleClose: () => void,
    open: boolean,
    newReagentGroup: boolean,
    initialReagentGroup: ReagentGroupVM | undefined,
    handleSave: (reagentGroup: ReagentGroupVM) => void
    user: IUserContext | null;
}

interface IEditBarcodeKitState {
    reagentGroupToEdit?: ReagentGroupVM;
    lastLotId: number;
    lotNumberEntry: string;
    expirationEntry: Date;
    lotEntryOpen: boolean;
    lotNumberLogOpen: boolean;
    barcodeReagents: CSVBarcodeVM[];
    barcodePositionsOpen: boolean;
    barcodeKitType: BarcodeKitType;
    minIONBarcodesValid: boolean;
    instrumentSelectionOpen: boolean;
    element?: Element;
    templateDownloadName: string;
}

type IEditBarcodeKitProps = IEditBarcodeKitBaseProps & IAssetServiceInjectedProps & ICSVServiceInjectedProps & IFileServiceInjectedProps;

export class _EditBarcodeKit extends React.Component<IEditBarcodeKitProps, IEditBarcodeKitState> {

    private readonly columns: Array<IDataTableColumn<CSVBarcodeVM>> = [
        {
            columnName: "position",
            columnFieldData: "Position",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Position",
        },
        {
            columnName: "sequence-name",
            columnFieldData: "SequenceName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "SequenceName",
        },
        {
            columnName: "sequence",
            columnFieldData: "Sequence",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Sequence",
        }
    ]

    private readonly instrumentOptions: Array<{ Name: string, Type: BarcodeKitType }> = [
        {
            Name: "MinION",
            Type: "MinION"
        },
        {
            Name: "Illumina Nextera",
            Type: "IlluminaNextera"
        },
        {
            Name: "Illumina Dual Adapter",
            Type: "IlluminaDualAdapter"
        }
    ]

    state: IEditBarcodeKitState = {
        lotNumberEntry: "",
        expirationEntry: new Date(Date.now()),
        lotEntryOpen: false,
        lotNumberLogOpen: false,
        lastLotId: 0,
        barcodeReagents: [],
        barcodePositionsOpen: false,
        barcodeKitType: "MinION",
        minIONBarcodesValid: true,
        instrumentSelectionOpen: false,
        templateDownloadName: "MinION_barcode_template.zip"
    }

    async componentDidUpdate(prevProps: IEditBarcodeKitProps) {
        if (this.props.initialReagentGroup && (prevProps.initialReagentGroup === undefined || (prevProps.initialReagentGroup && this.props.initialReagentGroup.Id !== prevProps.initialReagentGroup.Id))) {
            await this.props.assetService.fetchBarcodeKitReagents();
            let reagentGroup = _.cloneDeep(this.props.initialReagentGroup);
            if (this.props.newReagentGroup) {
                reagentGroup.CreatedBy = this.props.user ? this.props.user.preferred_username : "";
            }
            this.setState({ reagentGroupToEdit: reagentGroup });
            //Default is MINION
            await this.props.FileService.getCSVTemplateZip("MinION_barcode_template");

            if (this.state.reagentGroupToEdit && this.state.reagentGroupToEdit.Lots.length > 0) {
                //Set barcode reagents based off lot 0
                let barcodeReagents: CSVBarcodeVM[] = _.sortBy(_.map(this.state.reagentGroupToEdit.Lots[0].ReagentLots, rl => {
                    return {
                        Position: reagentGroup.ReagentGroupType === "MinIONBarcodeKit" ? rl.BarcodeWellPosition : rl.BarcodePosition,
                        SequenceName: rl.ReagentName,
                        Sequence: rl.Sequence,
                        ReagentId: rl.ReagentId
                    };
                }), rl => rl.SequenceName);

                this.setState({ barcodeReagents: barcodeReagents, barcodeKitType: reagentGroup.ReagentGroupType === "MinIONBarcodeKit" ? "MinION" : "IlluminaNextera", minIONBarcodesValid: true });
            } else {
                this.setState({ barcodeReagents: [], barcodeKitType: reagentGroup.ReagentGroupType === "MinIONBarcodeKit" ? "MinION" : "IlluminaNextera", minIONBarcodesValid: true });
            }
        }
    }

    render() {
        if (this.state.reagentGroupToEdit) {
            let noneActive = _.find(this.state.reagentGroupToEdit.Lots, l => l.IsActive) === undefined;

            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div>
                        {this.state.reagentGroupToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.props.open}
                                onClose={() => { this.props.handleClose() }}
                                PaperProps={{ style: { width: "1200px" } }}
                            >
                                <div className={styles.content} style={{ marginBottom: "50px" }}>
                                    <h2>Barcode Kit</h2>

                                    <div className={styles.flexRow}>
                                        <h3>Barcode Kit Type</h3>
                                        <FormControl className={styles.formControl}>
                                            <InputLabel id="select-label" disableAnimation>
                                                {!this.state.barcodeKitType || this.state.instrumentSelectionOpen ? (
                                                    <>Choose a instrument used for sequencing</>
                                                ) : (
                                                    <>{this.instrumentOptions.find(opt => opt.Type == this.state.barcodeKitType)?.Name}</>
                                                )}
                                            </InputLabel>
                                            <Select
                                                id="selection"
                                                labelId="select-label"
                                                label=""
                                                value={""}
                                                disableUnderline
                                                disabled
                                                onClick={this.onInstrumentSelectionClick}
                                                IconComponent={ArrowDownward}
                                            />
                                        </FormControl>
                                    </div>
                                    <Popover
                                        open={this.state.instrumentSelectionOpen}
                                        onClose={this.handleClose}
                                        anchorEl={this.state.element}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        style={{ marginTop: "1px" }}
                                        PaperProps={{ square: true, variant: "outlined" }}
                                    >
                                        <div className={styles.popOver}>
                                            <div className={styles.dataTableDiv}>
                                                <DataTable
                                                    data={this.instrumentOptions}
                                                    columns={this.templateColumns()}
                                                    hideHeader
                                                />
                                            </div>
                                        </div>
                                    </Popover>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive}
                                            InputLabelProps={{ shrink: true }}
                                            label={"Name"}
                                            value={this.state.reagentGroupToEdit?.Name}
                                            onDebouncedChange={(value) => { this.setName(value) }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentGroupToEdit?.IsActive}
                                            InputLabelProps={{ shrink: true }}
                                            label={"Description"}
                                            value={this.state.reagentGroupToEdit?.Description}
                                            onDebouncedChange={(value) => { this.setDescription(value) }}
                                        />
                                    </div>
                                    <h3 style={noneActive ? { color: "red" } : { color: "" }}>
                                        Active Lot/Ref Numbers {noneActive ? " - At least one must be active!" : ""}
                                    </h3>
                                    <h3 style={{ color: "red", display: this.state.minIONBarcodesValid ? "none" : "" }}>
                                        Not all barcode positions are filled or valid. Please update and re-upload.
                                    </h3>
                                    {_.filter(this.state.reagentGroupToEdit.Lots, l => l.IsActive).map(l => <Chip
                                        label={l.LotNumber}
                                        key={l.Id}
                                        onDelete={l.UsedInRun === "UsedInPrevious" ? () => { this.disableLotNumber(l.Id); } : l.UsedInRun === "NotUsed" ? () => { this.deleteLotNumber(l.Id); } : undefined}
                                        style={{ margin: "5px" }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || l.UsedInRun === "UsedInActive"}
                                        deleteIcon={l.UsedInRun === "UsedInPrevious" ? <BlockIcon /> : undefined}
                                    />)}
                                    {this.state.barcodeReagents.length === 0 ?
                                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>

                                            <Button variant="contained"
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                style={{ justifyContent: "left" }} onClick={() => {
                                                    const input = document.getElementById('input') as HTMLInputElement;
                                                    if (input && input.files) {
                                                        const file = input.files[0];
                                                        const reader = new FileReader();
                                                        reader.onload = () => { this.parseBarcodePositions(reader); };
                                                        reader.readAsText(file);
                                                    }
                                                }}>
                                                Upload File
                                            </Button>
                                            <input style={{ margin: "auto" }} type="file" id="input"></input>
                                            <Button
                                                style={{ justifyContent: "left" }}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                startIcon={<FileCopyIcon />}
                                                onClick={() => {
                                                    var saveDocument = document.createElement('a');
                                                    saveDocument.href = this.props.FileService.getState().csvZipState.data || "";
                                                    saveDocument.target = '_blank';
                                                    saveDocument.download = this.state.templateDownloadName;
                                                    saveDocument.click();
                                                }}>
                                                Download Template
                                            </Button>
                                        </div>
                                        :
                                        <div>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    this.setState({ barcodePositionsOpen: true })
                                                }}>
                                                View Barcode Positions
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{ display: this.state.reagentGroupToEdit.UsedInRun ? "none" : "" }}
                                                disabled={_.find(this.state.reagentGroupToEdit.Lots, l => l.UsedInRun !== "NotUsed") !== undefined || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                onClick={() => { this.deleteBarcodeReagents() }}
                                                title="Delete Barcode Positions">
                                                <DeleteRounded />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{ display: this.state.reagentGroupToEdit.UsedInRun ? "none" : "" }}
                                                disabled={_.find(this.state.reagentGroupToEdit.Lots, l => l.UsedInRun !== "NotUsed") !== undefined || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                onClick={this.resetLotNumbers}>
                                                Reset Lot Numbers and Upload New Template
                                            </Button>
                                        </div>
                                    }
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={!this.state.reagentGroupToEdit?.IsActive || this.state.barcodeReagents.length === 0 || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => {
                                                this.setState({ lotEntryOpen: true, lotNumberEntry: "" });
                                            }}
                                        >
                                            Add New Lot Number
                                        </Button>
                                        <div style={{ marginTop: "auto", marginLeft: "10px" }} onClick={async () => {
                                            this.setState({ lotNumberLogOpen: true });
                                        }}>
                                            <Link>
                                                <u>View Lot Number Change Log</u>
                                            </Link>
                                        </div>
                                    </div>
                                    {
                                        !this.props.newReagentGroup &&
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ paddingTop: "10px" }}>
                                                    Active:
                                                </div>
                                                <Checkbox disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} checked={this.state.reagentGroupToEdit?.IsActive}
                                                    onChange={(event) => {
                                                        this.setActive(event.target.checked);
                                                    }}
                                                />
                                            </div>
                                            {!this.state.reagentGroupToEdit?.IsActive && this.state.reagentGroupToEdit?.DeactivatedOn &&
                                                <div>
                                                    Deactivated On {moment(this.state.reagentGroupToEdit?.DeactivatedOn).format("L LT")}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || noneActive || this.state.reagentGroupToEdit.Name === ""}
                                        onClick={() => {
                                            if (this.state.reagentGroupToEdit) {
                                                this.props.handleSave(this.state.reagentGroupToEdit);
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.props.handleClose() }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                        {this.state.reagentGroupToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.lotEntryOpen}
                                onClose={() => { this.setState({ lotEntryOpen: false, lotNumberEntry: "" }) }}
                                PaperProps={{ style: { width: "400px", height: "300px" } }}
                            >
                                <div className={styles.content}>
                                    <h3>Kit Name: {this.state.reagentGroupToEdit?.Name}</h3>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <AdvanceTextField
                                            label={"Lot/Reference Number"}
                                            autoFocus={true}
                                            value={this.state.lotNumberEntry}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onDebouncedChange={(value) => { this.setState({ lotNumberEntry: value }) }}
                                        />
                                        <HorizontalSplitIcon />
                                    </div>
                                    {_.find(this.state.reagentGroupToEdit.Lots, l => l.LotNumber === this.state.lotNumberEntry) !== undefined && <h3 style={{ color: "red" }}>
                                        Duplicate Lot Number Entered
                                    </h3>}
                                    <KeyboardDatePicker
                                        label="Expiration Date"
                                        value={this.state.expirationEntry}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onChange={this.onDateChanged}
                                        format="MM/DD/YY" />
                                    <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.lotNumberEntry === "" || _.find(this.state.reagentGroupToEdit.Lots, l => l.LotNumber === this.state.lotNumberEntry) !== undefined}
                                            onClick={() => {
                                                this.addLotNumber();
                                            }}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { this.setState({ lotEntryOpen: false, lotNumberEntry: "" }) }}
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        }
                        <Dialog
                            className={styles.dialog}
                            open={this.state.barcodePositionsOpen}
                            onClose={() => { this.setState({ barcodePositionsOpen: false }) }}
                            PaperProps={{ style: { maxWidth: "1400px", height: "900px" } }}
                        >
                            <div className={styles.content}
                                style={{ overflow: "auto" }}>
                                <DataTable
                                    columns={this.columns}
                                    data={this.state.barcodeReagents}
                                />

                            </div>
                        </Dialog>
                        {this.state.reagentGroupToEdit &&
                            <LotNumberChangeLog
                                lotNumbers={this.state.reagentGroupToEdit.Lots}
                                name={this.state.reagentGroupToEdit.Name}
                                open={this.state.lotNumberLogOpen}
                                handleClose={() => { this.setState({ lotNumberLogOpen: false }); }}
                            />
                        }
                    </div>
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <></>;
    }

    @bind
    private setName(value: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Name = value;
            this.setState({
                reagentGroupToEdit: newVal
            });
        }
    }

    @bind
    private setDescription(value: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Description = value;
            this.setState({
                reagentGroupToEdit: newVal
            });
        }
    }

    @bind
    private setActive(value: boolean) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.DeactivatedOn = value ? oldVal.DeactivatedOn : new Date(Date.now());
            newVal.IsActive = value;
            newVal.DeactivatedBy = value ? oldVal.DeactivatedBy : this.props.user ? this.props.user.preferred_username : "";
            this.setState({ reagentGroupToEdit: newVal });
        }
    }

    @bind
    private addLotNumber() {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);

            //create the reagentLots
            var reagents = this.state.barcodeReagents

            if (reagents.length > 0) {
                let reagentLots: ReagentLotVM[] = [];
                _.forEach(reagents, reagent => {
                    reagentLots.push(
                        {
                            CreatedBy: this.props.user ? this.props.user.preferred_username : "",
                            CreatedOn: new Date(Date.now()),
                            IsNew: true,
                            ReagentId: reagent.ReagentId ? reagent.ReagentId : "new",
                            Id: "new",
                            IsActive: true,
                            LotNumber: this.state.lotNumberEntry,
                            ReagentDescription: reagent.ReagentName || "",
                            ReagentName: reagent.ReagentName || "",
                            UsedInRun: "NotUsed",
                            SequenceName: reagent.SequenceName,
                            Sequence: reagent.Sequence,
                            BarcodeWellPosition: this.state.barcodeKitType !== "IlluminaNextera" ? reagent.Position : "",
                            BarcodePosition: this.state.barcodeKitType === "MinION" ? "barcode" + reagent.SequenceName?.substr(2, 2) : this.state.barcodeKitType === "IlluminaNextera" ? reagent.Position : reagent.SequenceName?.slice(-4),
                            ExpirationDate: this.state.expirationEntry
                        });
                });
                lots.push(
                    {
                        CreatedBy: this.props.user ? this.props.user.preferred_username : "",
                        CreatedOn: new Date(Date.now()),
                        Id: (this.state.lastLotId + 1).toString(),
                        IsActive: true,
                        LotNumber: this.state.lotNumberEntry,
                        UsedInRun: "NotUsed",
                        IsNew: true,
                        ReagentLots: reagentLots,
                        ReagentGroupId: oldVal.Id || "",
                        ExpirationDate: this.state.expirationEntry
                    }
                )
            }

            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentGroupToEdit: newVal,
                lotEntryOpen: false,
                lotNumberEntry: "",
                lastLotId: this.state.lastLotId + 1
            });
        }
    }

    @bind
    private deleteLotNumber(id: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots).filter(l => l.Id !== id);
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentGroupToEdit: newVal,
            });
        }
    }

    @bind
    private disableLotNumber(id: string) {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);
            let lotToDisable = _.find(lots, l => l.Id === id);
            if (lotToDisable) {

                lotToDisable.IsActive = false;
                lotToDisable.DeactivatedOn = new Date(Date.now());
                lotToDisable.DeactivatedBy = this.props.user ? this.props.user.preferred_username : "";

                let newVal = _.cloneDeep(oldVal);
                newVal.Lots = lots;
                this.setState({
                    reagentGroupToEdit: newVal,
                });
            }
        }
    }

    @bind
    private async parseBarcodePositions(reader: FileReader) {
        await this.props.CSVService.parseBarcodes(reader.result as string, this.state.barcodeKitType);
        const barcodes = this.props.CSVService.getState().parseBarcodeResults.data;
        if (barcodes) {
            if (this.state.barcodeKitType) {
                const barcodesValidPosition = _.filter(barcodes, (barcode) => { return barcode.Position });
                if (barcodesValidPosition.length < MinIONValidBarcodePositions) {
                    this.setState({ minIONBarcodesValid: false });
                } else {
                    this.setState({ barcodeReagents: barcodes, minIONBarcodesValid: true });
                }
            } else {
                this.setState({ barcodeReagents: barcodes });
            }

        }
    }

    @bind
    private resetLotNumbers() {
        let oldVal = this.state.reagentGroupToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = [];
            this.setState({
                reagentGroupToEdit: newVal,
                barcodeReagents: []
            });
        }
    }

    @bind
    private onDateChanged(date: MaterialUiPickersDate) {
        if (date) {
            this.setState({ expirationEntry: date.toDate() })
        }
    }

    @bind private deleteBarcodeReagents() {
        this.setState({ barcodeReagents: [] });
    }

    private templateColumns(): Array<IDataTableColumn<{ Name: string, Type: BarcodeKitType }>> {
        return [
            {
                columnName: "name",
                columnFieldData: (d) =>
                    <div
                        onClick={() => {
                            this.updateInstrumentSelection(d.Type);
                        }}
                    >
                        {d.Name}
                    </div>,
                headerProps: {},
                headerValue: "",
                cellProps: {}
            },

        ];
    }

    @bind
    private onInstrumentSelectionClick(event: React.MouseEvent) {
        this.setState({ instrumentSelectionOpen: true, element: event.currentTarget });
    }

    @bind
    private async updateInstrumentSelection(newType: BarcodeKitType) {
        this.setState({ barcodeKitType: newType, instrumentSelectionOpen: false, element: undefined });

        let oldGroup = this.state.reagentGroupToEdit;
        if (oldGroup) {
            let newGroup = _.cloneDeep(oldGroup);
            switch (newType) {
                case "MinION":
                    await this.props.FileService.getCSVTemplateZip("MinION_barcode_template");
                    newGroup.ReagentGroupType = "MinIONBarcodeKit";
                    this.setState({templateDownloadName: "MinION_barcode_template.zip"});
                    break;
                case "IlluminaDualAdapter":
                    await this.props.FileService.getCSVTemplateZip("Illumina_Dual_Adapter_barcode_template");
                    newGroup.ReagentGroupType = "IlluminaDualAdapterBarcodeKit";
                    this.setState({templateDownloadName: "Illumina_Dual_Adapter_barcode_template.zip"});
                    break;
                case "IlluminaNextera":
                    await this.props.FileService.getCSVTemplateZip("Illumina_Nextera_barcode_template");
                    newGroup.ReagentGroupType = "IlluminaNexteraBarcodeKit";
                    this.setState({templateDownloadName: "Illumina_Nextera_barcode_template.zip"});
                    break;
            }
            this.setState({
                reagentGroupToEdit: newGroup
            });
        }
    }

    @bind
    private handleClose() {
        this.setState({ element: undefined, instrumentSelectionOpen: false });
    }
}

export const EditBarcodeKit = FileService.inject(CSVService.inject(AssetService.inject(_EditBarcodeKit)));