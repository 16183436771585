import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { GetWorkflowType, IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, PCRControl, AdvanceTextField, DataTable, IDataTableColumn, CollapsibleSection, SafeStoppingPointComponent } from "$Imports/CommonComponents";
import Snackbar from "@material-ui/core/Snackbar";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";

import * as s from "underscore.string";
import { Dialog, Link, HorizontalSplitIcon } from "$Imports/MaterialUIComponents";
import { ReagentLotVM, WellContentVM, WetlabRunBarcodeVM } from "$Generated/api";
import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";
import { IAdapterMap } from "./BarcodingPrimerPrepIlluminaDialog";
import { Dictionary } from "lodash";
import { CloudUpload } from "@material-ui/icons";

const styles: {
    footerDiv: string;
    mainDiv: string;
    tableHeader: string;
} = require("./CommonStepStyles.scss");

const barcodeStyles: {
    tableHeader: string;
    plateHeader: string;
    table: string;
    cell: string;
} = require("./Barcoding.scss");

export interface IBarcodingState {
    messageSnackbarOpen: boolean;
    message: string;
    barcodePlateOpen: boolean;
    canMove: boolean;
}

export class BarcodingScreen extends React.Component<IWorkflowScreenProps, IBarcodingState> {

    state: IBarcodingState = { messageSnackbarOpen: false, barcodePlateOpen: false, message: "", canMove: true };

    private selectedReagentKitColumns(wellContents: WellContentVM[]): Array<IDataTableColumn<WetlabRunBarcodeVM>> {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentStep && currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => {
                        var sample = _.find(wellContents, wc => wc.WellPosition === d.WellPosition);
                        return <div>{sample?.Id}</div>;
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample ID",
                },
                {
                    columnName: "reagent-name",
                    columnFieldData: "ReagentName",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Reagent Name",
                },
                {
                    columnName: "lot-num",
                    columnFieldData: "ReagentGroupLotNumber",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Lot/Ref Number",
                },
                {
                    columnName: "barcode",
                    columnFieldData: "Barcode",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Barcode",
                },
                {
                    columnName: "well-position",
                    columnFieldData: (d) => <div>{ConvertPositionToString(d.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow)}</div>,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Well Position",
                    sortMethod: (d) => (d.WellPosition) ?? ""
                },
            ];
        }
        return [];
    }

    private illuminaColumns(wellContents: WellContentVM[]): Array<IDataTableColumn<WetlabRunBarcodeVM[]>> {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const workflowType = GetWorkflowType();
        if (currentStep && currentWorkflowRun) {
            let illuminaColumns: Array<IDataTableColumn<WetlabRunBarcodeVM[]>> = [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => {
                        var sample = _.find(wellContents, wc => wc.WellPosition === d[0].WellPosition);
                        return <div>{sample?.Sample?.SampleId || sample?.Control?.SampleId}</div>;
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample ID",
                },
                {
                    columnName: "reagent-name",
                    columnFieldData: (d) => {
                        if(workflowType === "SarsCov2")
                        {
                            //COV2 uses I5/I7 names, trim those off
                            return <div>{d[0].ReagentName?.substring(0, d[0].ReagentName.length - 3)}</div>;
                        }
                        return <div>{d[0].ReagentName}</div>;
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: workflowType === "SarsCov2" ? "Index Name" : "Reagent Name",
                },
                {
                    columnName: "lot-num",
                    columnFieldData: (d) => <div>{d[0].ReagentGroupLotNumber}</div>,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Lot/Ref Number",
                }
            ];

            if (workflowType !== 'SarsCov2') {
                illuminaColumns.push({
                    columnName: "Sequences",
                    columnFieldData: (d) => <div>{_.map(d, seq => <div>{seq.Sequence}</div>)}</div>,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sequences",
                });
            }

            illuminaColumns.push({
                columnName: "well-position",
                columnFieldData: (d) => <div>{ConvertPositionToString(d[0].WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Well Position",
                sortMethod: (d) => (d[0].WellPosition) ?? ""
            });

            return illuminaColumns;
        }
        return [];
    }

    componentDidMount() {
        this.props.workflowRunService.fetchPCRForStep(true);
        this.props.workflowRunService.fetchMasterMixForStep(true);
        if (GetWorkflowType() === "Anthrax") {
            this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            this.props.workflowRunService.fetchPlatesForStep(true);

        }

        this.props.workflowRunService.fetchWorkflowRunBarcodes(true);
        this.props.workflowRunService.fetchInstrumentOptions();
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const plates = this.props.workflowRunService.getState().plates;
        const racks = this.props.workflowRunService.getState().racks;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        const workflowRunBarcodeData = this.props.workflowRunService.getState().fetchWorkflowRunBarcodes.data;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;

        if (currentWorkflowRun && currentStep && pcrInfoState.data && masterMixInfoState.data && (plates.data || racks.data) && instrumentOptions && workflowRunBarcodeData && lotOptions) {

            const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
            const inputRack = _.find(racks.data, rack => (rack.Name === currentStep.InputName));
            let inputAsset;
            //determine which asset to use
            if (GetWorkflowType() === "Anthrax") {
                inputAsset = inputRack;
            }
            else {
                inputAsset = inputPlate;
            }

            if (inputAsset) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={styles.mainDiv}>
                                <div>
                                    {(!(GetWorkflowType() === "Anthrax")) ?
                                        <MasterMixControl
                                            disabled={disabled}
                                            masterMix={masterMixInfoState.data[0]}
                                            sampleCount={inputAsset.WellContents.length}
                                            updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                            lotOptions={lotOptions}
                                            setCanMoveState={(canMove: boolean) => { this.setState({ canMove: canMove }) }}
                                            index={0}
                                        />
                                        : <></>
                                    }
                                    {currentWorkflowRun.UseSafeStoppingPoints && currentStep.AllowPause && <SafeStoppingPointComponent />}
                                </div>
                                <div style={{ width: "1200px", marginLeft: "10px" }}>
                                    <div>
                                        <h2>Barcode Information</h2>
                                        <div>
                                            {(GetWorkflowType() === "SarsCov2" || GetWorkflowType() === "NBS") ?
                                                <div>
                                                    <DataTable
                                                        columns={this.illuminaColumns(inputAsset.WellContents)}
                                                        data={_.map(_.groupBy(workflowRunBarcodeData, wrbd => wrbd.WellPosition), x => x)}
                                                        defaultSortColumnName={"position"}
                                                    /> </div>
                                                :
                                                <div>
                                                    <DataTable
                                                        columns={this.selectedReagentKitColumns(inputAsset.WellContents)}
                                                        data={workflowRunBarcodeData}
                                                        defaultSortColumnName={"well-position"}
                                                    /> </div>
                                            }
                                        </div>
                                    </div>
                                    <PCRControl
                                        disabled={disabled}
                                        controlInfo={pcrInfoState.data}
                                        updateInstrumentName={this.props.workflowRunService.updatePCRName}
                                        instrumentOptions={instrumentOptions}
                                        customDisplay={"tagAmp"}
                                    />
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div className={styles.footerDiv}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} hideArchiveRetain={inputAsset.WellContents[0].RunCount >= currentWorkflowRun.WorkflowMaxSampleRun} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                        {this.state.barcodePlateOpen &&
                            <Dialog
                                open={this.state.barcodePlateOpen}
                                onClose={this.handleClose}
                                maxWidth={"lg"}
                            >
                            </Dialog>
                        }
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.message}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>);
            }
        }
        return <></>
    }

    @bind
    private handleClose() {
        this.setState({ barcodePlateOpen: false });
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private async moveToNextStep() {
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        //Anthrax doesn't have master mix data for this step
        if (pcrInfoState.data && masterMixState.data && currentStep && (GetWorkflowType() !== "Anthrax")) {
            let missingMasterMixData = missingData(masterMixState.data[0]);
            if (currentStep.OutputAssets) {
                let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName)
                let inputAsset = _.find(currentStep.toJS().InputAssets, a => a.Name === currentStep.InputName)


                if (GetWorkflowType() !== "SarsCov2" && GetWorkflowType() !== "NBS") {
                    if (inputAsset && ((inputAsset.PlateSummary && inputAsset.PlateSummary.Status !== "Archived") || (inputAsset.RackSummary && inputAsset.RackSummary.Status !== "Archived"))) {
                        const plates = this.props.workflowRunService.getState().plates;
                        const racks = this.props.workflowRunService.getState().racks;
                        const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
                        const inputRack = _.find(racks.data, rack => (rack.Name === currentStep.InputName));
                        if (currentWorkflowRun && ((inputPlate && inputPlate.WellContents[0].RunCount < currentWorkflowRun.WorkflowMaxSampleRun) || (inputRack && inputRack.WellContents[0].RunCount < currentWorkflowRun.WorkflowMaxSampleRun))) {
                            this.setState({ messageSnackbarOpen: true, message: inputAsset.Name + " must be archived before continuing" });
                            return;
                        }
                        else if (inputPlate) {
                            //Auto discard plate
                            await this.props.workflowRunService.updatePlateStatus([inputPlate.Id], "Discarded", false);
                        }
                    }
                }
                if (pcrInfoState.data.InstrumentNameValue && !missingMasterMixData && outputAsset && outputAsset.PlateSummary) {
                    //Continue to next step
                    if (this.state.canMove) {
                        await Promise.all([
                            this.props.workflowRunService.saveMasterMix(true),
                            this.props.workflowRunService.savePCRInfo(),
                            this.props.workflowRunService.completeStep(),
                            this.props.workflowRunService.addInstrument(pcrInfoState.data.InstrumentNameValue),
                            this.props.workflowRunService.savePlateWellVolume(outputAsset.PlateSummary.Id, 50) //this is equal to all master mix reagents. Currently this is 50. If this changes down the line we will need to rework the master mix data structure.
                        ]);
                    }
                }
                else {
                    this.setState({ messageSnackbarOpen: true, message: "Lot numbers and ThermoCycler name are required" });
                }
            }
        }
        //making next step functionality for Anthrax
        else if (pcrInfoState.data && currentStep) {
            if (currentStep.OutputAssets) {
                let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName)
                let inputAsset = _.find(currentStep.toJS().InputAssets, a => a.Name === currentStep.InputName)
                if (inputAsset) {
                    const racks = this.props.workflowRunService.getState().racks;
                    const inputRack = _.find(racks.data, rack => (rack.Name === currentStep.InputName));

                    if (inputRack) {
                        //Auto discard rack
                        await this.props.workflowRunService.updateAssetStatus([inputRack.Id], "Discarded", "Rack");
                    }
                    if (pcrInfoState.data.InstrumentNameValue && outputAsset && outputAsset.RackSummary) {
                        await Promise.all([
                            this.props.workflowRunService.savePCRInfo(),
                            this.props.workflowRunService.completeStep(),
                            this.props.workflowRunService.addInstrument(pcrInfoState.data.InstrumentNameValue),
                            //this.props.workflowRunService.savePlateWellVolume(outputAsset.RackSummary.Id, 50) //this is equal to all master mix reagents. Currently this is 50. If this changes down the line we will need to rework the master mix data structure.
                        ]);
                    }
                    else {
                        this.setState({ messageSnackbarOpen: true, message: "ThermoCycler name is required" });
                    }
                }
            }
        }
    }
}