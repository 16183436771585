import {
    React,
    _,
    bind,
} from "$Imports/Imports";

import {
    ComponentSetVM,
    ComponentSetItemInstanceVM,
} from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

import {
    AdvanceTextField, DataLoadingDisplay, ProcessAdminRole
} from "$Imports/CommonComponents";

import {
    DeleteIcon,
    HorizontalSplitIcon, IconButton
} from "$Imports/MaterialUIComponents";

export interface IMasterMixControlPreviewProps {
    masterMixes: ComponentSetVM[],
    isPool: boolean;
    updateMasterMixReagents: (reagents: ComponentSetItemInstanceVM[]) => void;
    disabled?: boolean;
    hideHeaders?: boolean;
    ignoreFocus?: boolean;
    overrideTitle?: string;
    deleteMixStep?: (index: number) => void;
    deleteFooterStep?: (index: number) => void;
}

export interface IMasterMixControlPreviewState {
    showLotNumberError: boolean;
}

const styles: {
    cell: string;
    cellTopDivider: string;
    lotNumberEntry: string;
    table: string;
} = require("./MasterMixControl.scss");

import * as s from "underscore.string";
import { first } from "lodash";


export function missingData(masterMix: ComponentSetVM): boolean {
    let missingMasterMixData = _.find(masterMix.ComponentSetInstances, setInstance => _.find(setInstance.ComponentSetItemInstances, item => s.isBlank(item.LotNumber || "")));
    return (missingMasterMixData !== undefined);
}


export class MasterMixControlPreview extends React.PureComponent<IMasterMixControlPreviewProps, IMasterMixControlPreviewState> {

    state: IMasterMixControlPreviewState = {
        showLotNumberError: false
    }

    render() {
        const { masterMixes } = this.props;
        const masterMix = masterMixes[0];
        let total = 0;
        let key = 0;
        let firstField = true;

        if (masterMix.ComponentSetItems) {
            const reagentList = _.sortBy(masterMix.ComponentSetItems, item => item.Ordinal).map((componentSetItem, idx) => {

                key++;
                var setFocus = false;
                if (firstField && !this.props.ignoreFocus) {
                    setFocus = true;
                    firstField = false;
                }
                return <ApplicationSecurityContext.Consumer>
                    {value => (
                        <tr key={(componentSetItem.DisplayName || "") + key}>
                            <td className={styles.cell}>{componentSetItem.DisplayName}</td>
                            {masterMix.ComponentSetType !== "SimplifiedReagentList" && <td className={styles.cell}>{componentSetItem.Volume}</td>}
                            {!this.props.isPool && masterMix.ComponentSetType !== "SimplifiedReagentList" && <td className={styles.cell}>{Number(((componentSetItem.Volume || 0) * (masterMix.AdditionalAmountFactor ? 1 + (masterMix.AdditionalAmountFactor / 100) : 1)).toPrecision(3))}</td>}
                            <td className={styles.cell}></td>
                            {this.props.deleteMixStep && <td>
                                <IconButton
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onClick={() => {
                                        if (this.props.deleteMixStep) {
                                            this.props.deleteMixStep(idx);
                                        }
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </td>}
                        </tr>
                    )}
                </ApplicationSecurityContext.Consumer>
            });

            const footerList = _.map(masterMix.ComponentSetFooters, (f, idx) => {
                return <ApplicationSecurityContext.Consumer>
                    {value => (
                        <tr key={f.Description}>
                            <td colSpan={this.props.deleteFooterStep ? 3 : 4} className={styles.cell}>{f.Description}</td>
                            {this.props.deleteFooterStep && <td>
                                <IconButton
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onClick={() => {
                                        if (this.props.deleteFooterStep) {
                                            this.props.deleteFooterStep(idx);
                                        }
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </td>}
                        </tr>
                    )}
                </ApplicationSecurityContext.Consumer>
            });

            return (
                <div>
                    {(!this.props.hideHeaders && <h2>{s.isBlank(this.props.overrideTitle || "") ? "Master Mix" : this.props.overrideTitle}</h2>)}
                    {(!this.props.hideHeaders && <h4>{"Sample Count: " + (masterMix.AdditionalAmountFactorType === "NumberOfSamples" ? masterMix.AdditionalAmountFactor : "")}</h4>)}
                    {(!this.props.hideHeaders && <h4>{"Additional amount factor: " + (masterMix.AdditionalAmountFactorType === "PercentFactor" ? masterMix.AdditionalAmountFactor + "%" : "")}</h4>)}
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <td colSpan={4}><b>{masterMix.DisplayHeader}</b></td>
                            </tr>
                            <tr>
                                <th className={styles.cell}>Reagent</th>
                                {masterMix.ComponentSetType !== "SimplifiedReagentList" && <th className={styles.cell}>{this.props.isPool ? "uL" : "uL Per Well"}</th>}
                                {!this.props.isPool && masterMix.ComponentSetType !== "SimplifiedReagentList" && <th className={styles.cell}>Total Volume</th>}
                                <th className={styles.cell}>Lot Number</th>
                                {this.props.deleteMixStep && <th></th>}
                            </tr>
                            {reagentList}
                            {masterMix.ComponentSetType !== "SimplifiedReagentList" && <tr>
                                <td className={styles.cellTopDivider}>Total</td>
                                <td colSpan={3} className={styles.cellTopDivider}>{Number(total.toPrecision(3))}</td>
                            </tr>}
                            {footerList}
                        </tbody>
                    </table>
                    {this.state.showLotNumberError && <h3 style={{ color: "red" }}>Mix contains invalid lot numbers. Please add the numbers in the Assets tab.</h3>}
                </div>
            );
        }
        return <DataLoadingDisplay />;
    }
}
