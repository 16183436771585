import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { GetWorkflowType, IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, PCRControl, StandardPlate, CollapsibleSection, QCUIComponent } from "$Imports/CommonComponents";
import Snackbar from "@material-ui/core/Snackbar";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";
import { PlateVM, QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";

import { 
    GriddingNTCControlCount
 } from "$Imports/CommonComponents";

const styles: {
    footerDiv: string;
    mainDiv: string;
    tempLabelDiv: string;
    tempDiv: string;
    tempField: string;
    errorIcon: string;
} = require("./CommonStepStyles.scss");

export interface IAmplificationState {
    messageSnackbarOpen: boolean;
    message: string;
    canMove: boolean;
    tempNotFilled: boolean;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
}

export class AmplificationScreen extends React.Component<IWorkflowScreenProps, IAmplificationState> {

    state: IAmplificationState = { 
        messageSnackbarOpen: false, 
        message: "", 
        canMove: true, 
        tempNotFilled: false, 
        qcOpen: false,
        failedQcs: [],
        qcResults: []
    };
    componentDidMount() {
        this.props.workflowRunService.fetchPCRForStep(true);
        this.props.workflowRunService.fetchMasterMixForStep(true);
        if (GetWorkflowType() === "Anthrax")
        {
            this.props.workflowRunService.fetchRacksForStep(true);
        }
        else
            this.props.workflowRunService.fetchPlatesForStep(true);
            
        this.props.workflowRunService.fetchInstrumentOptions();

        const currentStep = this.props.workflowRunService.currentStep;
        let stepInstances = this.props.workflowRunService.getState().stepInstances;
        if (stepInstances.hasFetched && stepInstances.data && currentStep) { 
            let stepInstancesData = _.sortBy(stepInstances.data, i => i.WorkflowOrder);
            let previousStepInstance = _.find(stepInstancesData, (instance) => instance.WorkflowOrder === currentStep?.WorkflowOrder - 1);

            if(previousStepInstance && previousStepInstance.StepInstanceId) {
                this.props.workflowRunService.getPreviousStepControlsCounts(previousStepInstance.StepInstanceId);
            }
        }
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const plates = this.props.workflowRunService.getState().plates;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;
        const showGridControl = currentStep ? _.filter(currentStep.QCCheckInstances?.toJS(), qc => (qc.QCCheckType === GriddingNTCControlCount)).length > 0 : false;

        if (currentWorkflowRun && currentStep && pcrInfoState.data &&  masterMixInfoState.data && plates.data && instrumentOptions && lotOptions) {
            const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));

            if (inputPlate) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                const controlFilteredPlate: PlateVM = _.clone(inputPlate);
                controlFilteredPlate.WellContents = _.filter(controlFilteredPlate.WellContents, w => {
                    if (w.Control && w.Control.Name === "PTC") {
                        return true;
                    }
                    return false;
                }
                );

                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={styles.mainDiv}>
                            <div>
                                <MasterMixControl
                                    disabled={disabled}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={inputPlate.WellContents.length}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                    index={0}
                                />
                                <PCRControl disabled={disabled} controlInfo={pcrInfoState.data} updateInstrumentName={this.updatePCR} instrumentOptions={instrumentOptions} />
                            </div>
                            {GetWorkflowType() !== "SarsCov2" &&
                                <div>
                                    <h2>{controlFilteredPlate.Name} Control Positions</h2>
                                    <StandardPlate
                                        wellContents={controlFilteredPlate.WellContents}
                                        wellCount={96}
                                        columnCount={currentWorkflowRun.AssetColCount}
                                        positionByRow={currentWorkflowRun.AssetPositionByRow}
                                    />
                                </div>
                            }
                        </div>
                        </CollapsibleSection>
                        {currentStep.QCCheckInstances &&
                            <QCUIComponent
                                open={this.state.qcOpen}
                                failedQCs={this.state.failedQcs}
                                close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                results={this.state.qcResults}
                                step={currentStep}
                                workflowRunId={currentWorkflowRun.Id}
                                workflowName={currentWorkflowRun.WorkflowName}
                                completeStep={this.completeMoveToNextStep}
                            />
                        }
                        <div className={styles.footerDiv}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} showGridControl={showGridControl} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.message}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>);
            }
        }
        return <></>
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind updatePCR(val:string) {
        this.props.workflowRunService.updatePCRName(val);
        this.props.workflowRunService.updateCustomField("ThermoCycler", val)
    }

    @bind
    private async moveToNextStep() {
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        const currentStep = this.props.workflowRunService.currentStep;

        const plates = this.props.workflowRunService.getState().plates;
        const previousStepControlsCount = this.props.workflowRunService.getState().previousStepControlsCount.data;

        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];

        if (pcrInfoState.data && masterMixState.data && currentStep) {
            const outputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName && plate.IsOutput === true));

            //Run all QCs
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case GriddingNTCControlCount:
                        let currentNTCs = _.filter(outputPlate?.WellContents, wc => wc.Control && wc.Control.Name === "NTC").length;
                        let previousStepNTCCount = _.find(previousStepControlsCount, cc => cc.Name === "NTC");
                        let ntcPass = true;

                        if(previousStepNTCCount && previousStepNTCCount.Count && previousStepNTCCount.Count >= currentNTCs) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                                ntcPass = false;
                            }
                        }

                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: 1, 
                                MeasuredValue: currentNTCs ? currentNTCs.toString() : "0",
                                Date: new Date(Date.now()),
                                Pass: ntcPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                }
            });

            if(failedQCs && failedQCs.length > 0 && qcResults) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            } else if (currentStep.OutputAssets) {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                this.completeMoveToNextStep();
            }
        }
    }

    @bind
    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const pcrInfoState = this.props.workflowRunService.getState().pcrInfoState;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;

        if(currentStep && pcrInfoState && pcrInfoState.data && masterMixState && masterMixState.data) {
            let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName);
            let missingMasterMixData = missingData(masterMixState.data[0]);
            if (pcrInfoState.data.InstrumentNameValue && !missingMasterMixData && outputAsset && outputAsset.PlateSummary) {
                //Continue to next step
                if (this.state.canMove) {
                    await Promise.all([
                        this.props.workflowRunService.saveMasterMix(true),
                        this.props.workflowRunService.savePCRInfo(),
                        this.props.workflowRunService.addInstrument(pcrInfoState.data.InstrumentNameValue),
                        this.props.workflowRunService.completeStep(),
                        this.props.workflowRunService.savePlateWellVolume(outputAsset.PlateSummary.Id, 25) //this is equal to all master mix reagents and volume of RNA per well. Currently this is 25. If this changes down the line we will need to rework the master mix data structure.
                    ]);
                }
            }
            else {
                this.setState({ messageSnackbarOpen: true, message: "Lot numbers and ThermoCycler name are required" });
            }
        }
    }
}