import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { GetWorkflowType, IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, IDataTableColumn, SampleInfoTable, QCUIComponent, RealTimePCRResultsNTCThresholdCheck, RealTimePCRResultsPTCThresholdCheck, RealTimePCRResultsNTCThresholdPassCheck, RealTimePCRResultsPTCThresholdPassCheck, CollapsibleSection, DataLoadingDisplay } from "$Imports/CommonComponents";
import { Snackbar, TextField, TableCellProps, Button } from "$Imports/MaterialUIComponents";
import {
    WellContentVM, ThresholdCycleVM, QCCheckInstanceVM, QCCheckResultVM,
} from "$Generated/api";
import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";
import { ChangeEvent } from "react";

import * as s from "underscore.string";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';


const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");

const styles: {
    tableHeader: string;
    failRow: string;
    passRow: string;
    ctThresholdDiv: string;
} = require("./RtPCR.scss");

interface IRtPCRQuantificationScreenState {
    displayValidationWarning: boolean;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    currentWellContent?: WellContentVM;
    dataLoaded: boolean;
}

type PassEnum = "Pass" | "Fail" | "ContinueFail" | "NotSet";

const NumOfCTValues = 1;

export class RtPCRQuantificationScreen extends React.Component<IWorkflowScreenProps, IRtPCRQuantificationScreenState> {

    private elements: any[] = [];

    constructor(props: IWorkflowScreenProps) {
        super(props);

        this.state = {
            displayValidationWarning: false,
            qcOpen: false,
            failedQcs: [],
            qcResults: [],
            dataLoaded: false
        };
    }

    private columns(): Array<IDataTableColumn<WellContentVM>> {
        let {
            thresholdCycleDictionary,
            currentStep,
            currentWorkflowRun
        } = this.props.workflowRunService;

        let disabled = false;

        if (currentStep && currentWorkflowRun) {
            disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
        }
        return [
            {
                columnName: "sample-id",
                columnFieldData: (d) => (d.Sample ? d.Sample.SampleId : (d.Control ? d.Control.Name : "")),
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Sample ID",
            },
            {
                columnName: "sample-well-position",
                columnFieldData: (d) => ConvertPositionToString(d.WellPosition, currentWorkflowRun ? currentWorkflowRun.AssetColCount : 12, currentWorkflowRun ? currentWorkflowRun.AssetRowCount : 8, currentWorkflowRun ? currentWorkflowRun.AssetPositionByRow : true),
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Well Position",
                sortMethod: (d) => d.WellPosition ?? ""
            },
            {
                columnName: "sample-ct",
                columnFieldData: (d) => {
                    var sampleId = d.ContentType === "Sample" ? d.Sample?.SampleId : d.Control?.SampleId;
                    if (sampleId) {
                        let dictionaryValue = thresholdCycleDictionary[sampleId];
                        if (dictionaryValue) {
                            return <TextField
                                disabled={disabled}
                                style={{ paddingLeft: 10 }}
                                type="number"
                                value={(dictionaryValue[0] && dictionaryValue[0].Value !== undefined) ? dictionaryValue[0].Value : ""}
                                onChange={(e) => { this.onCTChanged(d, e.target.value, 0); }}
                                onBlur={() => { this.validateCT(d); }}
                                inputRef={(el) => {
                                    this.elements[d.WellPosition] = el
                                }}
                                inputProps={{ min: 0 }} />
                        }
                    }
                    return <></>;
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "CT",
            },
            {
                columnName: "sample-pass-fail",
                columnFieldData: (d) => {
                    let { passFailDictionary } = this.props.workflowRunService;
                    var sampleId = d.ContentType === "Sample" ? d.Sample?.SampleId : d.Control?.SampleId;
                    if (sampleId) {
                        if (passFailDictionary[sampleId] === "Pass") {
                            return <div style={{ display: "flex", flexDirection: "row" }}>
                                <CheckCircleIcon style={{ color: "green", margin: "auto" }} />
                            </div>;
                        }
                        else if (passFailDictionary[sampleId] === "Fail") {
                            return <div style={{ display: "flex", flexDirection: "row" }}>
                                <CancelIcon style={{ color: "red", margin: "auto" }} />
                            </div>;
                        }
                        else if (passFailDictionary[sampleId] === "ContinueFail") {
                            return <div style={{ display: "flex", flexDirection: "row" }}>
                                <CancelIcon style={{ color: "orange", margin: "auto" }} />
                            </div>;
                        }
                    }
                    return <></>;
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "",
            },
            {
                columnName: "sample-pass-fail-string",
                columnFieldData: (d) => {
                    let { passFailDictionary } = this.props.workflowRunService;
                    var sampleId = d.ContentType === "Sample" ? d.Sample?.SampleId : d.Control?.SampleId;
                    if (sampleId) {
                        if (passFailDictionary[sampleId] === "Pass") {
                            return <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ fontSize: "10px", margin: "auto" }}>{d.ContentType === "Sample" ? "Target Detected." : "Sample Passed."}</div>
                            </div>;
                        }
                        else if (passFailDictionary[sampleId] === "Fail") {
                            return <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ fontSize: "10px", margin: "auto" }}>{d.ContentType === "Sample" ? "Target Not Detected." : "Sample Failed."}</div>
                            </div>;
                        }
                        else if (passFailDictionary[sampleId] === "ContinueFail") {
                            return <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ fontSize: "10px", marginTop: "auto", marginBottom: "auto" }}>Continue with failed sample.</div>
                            </div>;
                        }
                    }
                    return <></>;
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "",
            },
        ];
    }

    async componentDidMount() {
        if (GetWorkflowType() === "Anthrax") {
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }
        this.props.workflowRunService.resetPassFailDictionary();
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;

        if (currentStep) {
            //#region initial setup
            const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
            let passFailDictionary: { [index: string]: PassEnum } = {};
            let thresholdDictionary: { [index: string]: ThresholdCycleVM[] | undefined } = {};
            if (inputPlate) {

                await this.props.workflowRunService.getRtPCRResults(inputPlate.Id, true)
                const data = this.props.workflowRunService.getState().getRtPCRState.data;
                if (data) {
                    _.forEach(inputPlate.WellContents, w => {
                        var sampleId = w.ContentType === "Sample" ? w.Sample?.SampleId : w.Control?.SampleId;
                        if (sampleId) {
                            passFailDictionary[sampleId] = w.RtpcrPass === null ? "NotSet" : (w.RtpcrPass === "Pass" ? "Pass" : w.RtpcrPass === "ContinueWithFailed" ? "ContinueFail" : "Fail");

                            var thresholds = _.find(data, d => d.SampleId === sampleId);
                            if (thresholds) {
                                thresholdDictionary[sampleId] = thresholds.ThresholdCycles;
                            }
                        }
                    });
                }

                this.props.workflowRunService.passFailDictionary = passFailDictionary;
                this.props.workflowRunService.thresholdCycleDictionary = thresholdDictionary;
            }
            //#endregion
        }
        this.setState({dataLoaded: true})
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;

        if (currentWorkflowRun && currentStep && plates.data) {
            const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
            if (inputPlate) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                let ctThresholdValue = currentStep ? currentStep.toJS().CustomFields["CTValueForFailure"] : "";
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.collapsibleDiv}>
                                <h2>Record Results</h2>
                                <div className={styles.ctThresholdDiv}>
                                    <div className={styles.ctThresholdDiv}>
                                        CT Threshold Value:
                                    </div>
                                    <TextField autoFocus={true} disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={ctThresholdValue || 1} onChange={this.updateCtThresholdField} />
                                </div>
                                <SampleInfoTable
                                    data={inputPlate.WellContents}
                                    columns={this.columns()}
                                    overrideStyles={{ width: "1000px" }}
                                    defaultSortColumnName={"sample-well-position"}
                                />
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                    completeStep={this.moveToNextStep}
                                    failSampleAction={() => {
                                        this.setState({ qcOpen: false, failedQcs: [] });
                                        this.validateAnyFailedSamples(false);
                                    }}
                                    modifyFunction={() => { this.elements[this.state.currentWellContent?.WellPosition || 0].focus(); }}
                                    continueAfterFailSampleAction={() => {
                                        if (this.state.currentWellContent) {
                                            this.updatePassFail(this.state.currentWellContent, "ContinueFail");
                                        }
                                    }}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={() => this.validateAnyFailedSamples(true)} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.displayValidationWarning}
                            message={"CT Values and CT Threshold Value are required"}
                            autoHideDuration={5000}
                            onClose={this.hideValidationWarning}
                        />
                    </div>)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private async validateAnyFailedSamples(movingToNextStep: boolean) {
        let {
            passFailDictionary,
        } = this.props.workflowRunService;

        const currentStep = this.props.workflowRunService.currentStep;

        if (currentStep) {

            let failedQCs: QCCheckInstanceVM[] = [];
            let qcResults: QCCheckResultVM[] = [];

            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                //Rework for all pass/fail qcs
                switch (qc.QCCheckType) {
                    case RealTimePCRResultsNTCThresholdPassCheck:
                        if (currentStep.InputAssets) {
                            const plates = this.props.workflowRunService.getState().plates;
                            if (plates.data) {
                                const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
                                if (inputPlate) {
                                    let list = _.filter(inputPlate.WellContents, wc => wc.Control?.Name === "NTC");
                                    var allPass = _.every(list, wc => wc.Control ? passFailDictionary[wc.Control.SampleId] !== "Fail" : false)

                                    if (!allPass) {
                                        failedQCs.push(qc);
                                    }

                                    qcResults.push(
                                        {
                                            Id: "",
                                            FailureActionStatus: qc.Enabled ? allPass ? undefined : 1 : 2, //passed/modify/skipped
                                            MeasuredValue: allPass ? "NTC Pass" : "NTC Failed",
                                            Date: new Date(Date.now()),
                                            Pass: allPass,
                                            QCCheckInstance: qc,
                                        }
                                    );
                                }
                            }
                        }
                        break;

                    case RealTimePCRResultsPTCThresholdPassCheck:
                        if (currentStep.InputAssets) {
                            const plates = this.props.workflowRunService.getState().plates;
                            if (plates.data) {
                                const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
                                if (inputPlate) {
                                    let list = _.filter(inputPlate.WellContents, wc => wc.Control?.Name === "PTC");
                                    var allPass = _.every(list, wc => wc.Control ? passFailDictionary[wc.Control.SampleId] !== "Fail" : false)

                                    if (!allPass) {
                                        failedQCs.push(qc);
                                    }

                                    qcResults.push(
                                        {
                                            Id: "",
                                            FailureActionStatus: qc.Enabled ? allPass ? undefined : 1 : 2, //passed/modify/skipped
                                            MeasuredValue: allPass ? "PTC Pass" : "PTC Failed",
                                            Date: new Date(Date.now()),
                                            Pass: allPass,
                                            QCCheckInstance: qc,
                                        }
                                    );
                                }
                            }
                        }
                        break;
                }
            });

            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else if (movingToNextStep) {
                //What do?
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                this.moveToNextStep();
            }
        }
    }

    @bind
    private async moveToNextStep() {

        let {
            thresholdCycleDictionary
        } = this.props.workflowRunService;

        let allThresholdDataSet = _.every(thresholdCycleDictionary, t => {
            if (t === undefined || t.length !== NumOfCTValues) {
                return false;
            }
            return _.every(t, t => t.Value !== undefined);
        });
        if (allThresholdDataSet) {
            await Promise.all([
                this.props.workflowRunService.updateRtPCRState(),
                this.props.workflowRunService.updateCustomFields()
            ]);
            await this.props.workflowRunService.completeStep(true); //Needs to be outside due to a race condition
        }
        else {
            this.showValidationWarning();
        }
    }

    @bind
    updateCtThresholdField(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.props.workflowRunService.updateCustomField("CTValueForFailure", "1");
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue) {
                this.props.workflowRunService.updateCustomField("CTValueForFailure", numericValue.toString());
            }
        }

        //recalc values
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;

        if (currentWorkflowRun && currentStep && plates.data) {
            const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
            let thresholdCycleDictionary = _.clone(this.props.workflowRunService.thresholdCycleDictionary);
            if (inputPlate) {
                _.forEach(inputPlate.WellContents, wc => {
                    var sampleId = wc.ContentType === "Sample" ? wc.Sample?.SampleId : wc.Control?.SampleId;
                    if (sampleId) {
                        let existingArray = thresholdCycleDictionary[sampleId];
                        if (existingArray) {
                            //this.validateCT(wc, false);
                        }
                    }
                });

                if (this.state.failedQcs.length > 0) {
                    this.setState({ qcOpen: true });
                }
            }
        }
    }

    @bind
    hideValidationWarning() {
        this.setState({ displayValidationWarning: false });
    }

    @bind
    showValidationWarning() {
        this.setState({ displayValidationWarning: true });
    }

    @bind
    private onCTChanged(wellContent: WellContentVM, newValue: string, ordinal: number) {
        //This function should just update the actual value - we will use the onBlur to trigger pass/fail QCs
        let thresholdCycleDictionary = _.clone(this.props.workflowRunService.thresholdCycleDictionary);
        let floatVal = parseFloat(newValue);
        var sampleId = wellContent.ContentType === "Sample" ? wellContent.Sample?.SampleId : wellContent.Control?.SampleId;
        if (sampleId) {
            let existingArray = thresholdCycleDictionary[sampleId];
            if (existingArray) {
                existingArray[ordinal] = {
                    Ordinal: ordinal,
                    Value: (!isNaN(floatVal) ? floatVal : undefined)
                };
                if (existingArray[ordinal].Value === undefined) {
                    this.updatePassFail(wellContent, "NotSet");
                }
            }
            this.props.workflowRunService.thresholdCycleDictionary = thresholdCycleDictionary;
        }
    }

    @bind
    private validateCT(wellContent: WellContentVM) {
        let thresholdCycle = this.props.workflowRunService.thresholdCycleDictionary[wellContent.ContentType === "Sample" ? wellContent.Sample?.SampleId || "" : wellContent.Control?.SampleId || ""];
        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep && thresholdCycle) {

            this.setState({ currentWellContent: wellContent });

            let thresholdValue = parseFloat(currentStep.CustomFields["CTValueForFailure"]);

            if (thresholdCycle[0] && thresholdCycle[0].Value !== undefined) {
                if (wellContent.Control?.Name === "NTC") {
                    //Pass if CT >= threshold
                    if (thresholdCycle[0].Value >= thresholdValue) {
                        this.updatePassFail(wellContent, "Pass");
                    }
                    else //trigger QC
                    {
                        this.updatePassFail(wellContent, "Fail");

                        let failedQCs: QCCheckInstanceVM[] = _.cloneDeep(this.state.failedQcs);
                        let qcResults: QCCheckResultVM[] = _.cloneDeep(this.state.qcResults);

                        _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                            switch (qc.QCCheckType) {
                                case RealTimePCRResultsNTCThresholdCheck:
                                    failedQCs.push(qc);
                                    qcResults.push(
                                        {
                                            Id: "",
                                            FailureActionStatus: qc.Enabled ? 1 : 2,
                                            MeasuredValue: "NTC Failed",
                                            Date: new Date(Date.now()),
                                            Pass: false,
                                            QCCheckInstance: qc,
                                        }
                                    );
                            }
                        });

                        if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                            this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
                        }
                        else {
                            this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                            this.moveToNextStep();
                        }
                    }
                }
                else {
                    //PTC + Samples
                    //Pass if CT < threshold
                    if (thresholdCycle[0].Value < thresholdValue) {
                        this.updatePassFail(wellContent, "Pass");
                    }
                    else //trigger QC
                    {
                        this.updatePassFail(wellContent, "Fail");

                        let failedQCs: QCCheckInstanceVM[] = _.cloneDeep(this.state.failedQcs);
                        let qcResults: QCCheckResultVM[] = _.cloneDeep(this.state.qcResults);


                        _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                            switch (qc.QCCheckType) {
                                case RealTimePCRResultsPTCThresholdCheck:
                                    failedQCs.push(qc);
                                    qcResults.push(
                                        {
                                            Id: "",
                                            FailureActionStatus: qc.Enabled ? 1 : 2,
                                            MeasuredValue: "PTC/Sample Failed",
                                            Date: new Date(Date.now()),
                                            Pass: false,
                                            QCCheckInstance: qc,
                                        }
                                    );
                            }
                        });

                        if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                            this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
                        }
                        else {
                            this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                            this.moveToNextStep();
                        }

                    }
                }
            }
        }
    }

    @bind
    private updatePassFail(wellContent: WellContentVM, newValue: PassEnum) {
        var sampleId = wellContent.ContentType === "Sample" ? wellContent.Sample?.SampleId : wellContent.Control?.SampleId;
        if (sampleId) {
            let passFailDictionary = _.clone(this.props.workflowRunService.passFailDictionary);
            passFailDictionary[sampleId] = newValue;
            this.props.workflowRunService.passFailDictionary = passFailDictionary;
        }
    }
}