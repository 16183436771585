import {
    React,
    bind,
    _,
} from "$Imports/Imports"

import { IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, CollapsibleSection, DataTable, InstructionSetControl, IDataTableColumn } from "$Imports/CommonComponents";
import Snackbar from "@material-ui/core/Snackbar";
import { MetadataTemplateVM } from "$Generated/api";
import { Select, InputLabel, FormControl, Popover } from "$Imports/MaterialUIComponents";
import { ArrowDownward } from "$Imports/MaterialUIIcons";


const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
    flexRow: string;
    italic: string;
    bold: string;
} = require("./CommonStepStyles.scss");

const componentStyles: {
    formControl: string;
    popOver: string;
    dataTableDiv: string;
    buttonStyle: string;
    formControlContents: string;
    greenBg: string;
    tableStyles: string;
    noBottomMargin: string;
} = require("./FinalDilutionStep.scss");

export interface IFinalDilutionState {
    canMove: boolean;
    dilutionType: string;
    dilutionTypeOpen: boolean;
    element: Element | null;
}

export class FinalDilutionStepScreen extends React.Component<IWorkflowScreenProps, IFinalDilutionState> {

    state: IFinalDilutionState = {
        canMove: true,
        dilutionType: "MiSeq",
        dilutionTypeOpen: false,
        element: null
    };

    componentDidMount() {
        this.props.workflowRunService.fetchCleaningDataForStep(true);

        let initialDilutionType = "MiSeq";
        let currentStep = this.props.workflowRunService.currentStep;
        if(currentStep){
            let customFields = currentStep.toJS().CustomFields
            initialDilutionType = customFields["InstrumentSelection"] || "MiSeq";
        }
        this.updateDilutionType(initialDilutionType);
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const dilutionOptions = this.props.workflowRunService.getState().cleaningDataState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        if (currentWorkflowRun && currentStep && dilutionOptions.data && lotOptions) {
            const MiSeqData = _.find(dilutionOptions.data, mix => (mix.DisplayName === 'MiSeq Prep'));
            const iSeqData = _.find(dilutionOptions.data, mix => (mix.DisplayName === 'iSeq Prep'));

            if (MiSeqData && iSeqData) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");

                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.collapsibleDiv}>
                                <div className={commonStyles.flexRow}>
                                    <h3>Instrument Selection</h3>
                                    <FormControl className={componentStyles.formControl}>
                                        <InputLabel id="select-label" disableAnimation>
                                            {this.state.dilutionTypeOpen ? (
                                                <>Choose a instrument used for sequencing</>
                                            ) : (
                                                <>{this.state.dilutionType}</>
                                            )}                                            
                                        </InputLabel>
                                        <Select
                                            id="selection"
                                            labelId="select-label"
                                            label=""
                                            value={""}
                                            disableUnderline
                                            disabled
                                            onClick={this.onDilutionTypeClick}
                                            IconComponent={ArrowDownward}
                                        />
                                    </FormControl>
                                </div>

                                <Popover
                                    open={this.state.dilutionTypeOpen}
                                    onClose={this.handleClose}
                                    anchorEl={this.state.element}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    style={{ marginTop: "1px" }}
                                    PaperProps={{ square: true, variant: "outlined" }}
                                >
                                        <div className={componentStyles.popOver}>
                                            <div className={componentStyles.dataTableDiv}>
                                                <DataTable
                                                    data={[
                                                        {
                                                            Name: "MiSeq",
                                                            Id: "MiSeq"
                                                        },
                                                        {
                                                            Name: "iSeq",
                                                            Id: "iSeq"
                                                        }
                                                    ]}
                                                    columns={this.templateColumns()}
                                                    hideHeader
                                                />
                                            </div>
                                        </div>
                                </Popover>

                                {this.state.dilutionType == "MiSeq" && <div>
                                    <h2>MiSeq Denaturation and Dilution Instructions</h2>
                                    <InstructionSetControl
                                        componentSetData={MiSeqData}
                                        updateComponentSetDataSteps={this.props.workflowRunService.updateCleaningDataSteps}
                                        disabled={false}
                                        poolOrPlate={"Pool"}
                                        lotOptions={lotOptions}
                                        setCanMoveState={(canMove: boolean) => { this.setState({ canMove: canMove }) }}
                                    />
                                    <h2 className={componentStyles.noBottomMargin}>Final Serial Dilution</h2>
                                    <span className={commonStyles.italic}>Combine the listed Pooled Library and HT1 volumes to reach the listed Final Library Conc.</span>
                                    <table className={componentStyles.tableStyles}>
                                        <tr>
                                            <td>Final Library Conc. (pM)</td>
                                            <td>8</td>
                                            <td>10</td>
                                            <td className={componentStyles.greenBg}>12</td>
                                            <td>14</td>
                                            <td>15</td>
                                            <td>16</td>
                                            <td>18</td>
                                            <td>20</td>
                                        </tr>
                                        <tr>
                                            <td>Pooled Library (µl)</td>
                                            <td>400</td>
                                            <td>500</td>
                                            <td className={componentStyles.greenBg}>600</td>
                                            <td>700</td>
                                            <td>750</td>
                                            <td>800</td>
                                            <td>900</td>
                                            <td>1000</td>
                                        </tr>
                                        <tr>
                                            <td>HT1 (µl)</td>
                                            <td>600</td>
                                            <td>500</td>
                                            <td className={componentStyles.greenBg}>400</td>
                                            <td>300</td>
                                            <td>250</td>
                                            <td>200</td>
                                            <td>100</td>
                                            <td>0</td>
                                        </tr>
                                    </table>
                                </div>}

                                {this.state.dilutionType == "iSeq" && <div>
                                    <h2>iSeq Dilution Instructions</h2>
                                    <InstructionSetControl
                                        componentSetData={iSeqData}
                                        updateComponentSetDataSteps={this.props.workflowRunService.updateCleaningDataSteps}
                                        disabled={disabled}
                                        poolOrPlate={"Pool"}
                                        lotOptions={lotOptions}
                                        setCanMoveState={(canMove: boolean) => { this.setState({ canMove: canMove }) }}
                                    />
                                </div>}
                            </div>
                        </CollapsibleSection>
                        <div className={commonStyles.footerDiv}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                    </div>
                );
            }
        }
        return <></>;
    }

    @bind updateDilutionType(newVal:string) {
        this.setState({ dilutionType: newVal, dilutionTypeOpen: false })
        this.props.workflowRunService.updateCustomField("InstrumentSelection", newVal)
    }

    private templateColumns(): Array<IDataTableColumn<MetadataTemplateVM>> {
        return [
            {
                columnName: "name",
                columnFieldData: (d) =>
                    <div
                        onClick={() => {
                            this.updateDilutionType(d.Id || "");
                        }}
                    >
                        {d.Name}
                    </div>,
                headerProps: {},
                headerValue: "",
                cellProps: {}
            },

        ];
    }

    @bind
    private async moveToNextStep() {
        if (this.state.canMove) {
            await Promise.all([
                this.props.workflowRunService.updateCustomFields(),
                this.props.workflowRunService.completeStep(),
            ]);
        }
    }

    @bind
    private onDilutionTypeClick(event: React.MouseEvent) {
        this.setState({ dilutionTypeOpen: true, element: event.currentTarget });
    }

    @bind
    private handleClose() {
        this.setState({ element: null, dilutionTypeOpen: false });
    }
}