import {
    NgsReportAnalysisRunNotesVM,
    NgsReportSequencingRunNotesVM,
    NgsReportInstrumentPrepStandardizationVM,
    NgsReportWetlabStandardizationVM,
    NgsReportWetLabPoolingCalculationsVM,
    NgsReportWetLabDilutionCalculationsVM,
    NgsReportSequenceQcChecksVM
} from "$Generated/api";

import {
    React,
    _,
  } from "$Imports/Imports";
  
  
import { ResultsService,
    IResultsServiceInjectedProps
} from "$State/ResultsFreezerService";

const stylesWetLabReport: {
    analysisTableHeader : string;
    mainReport : string;
} = require("./WetLabReport.scss");

import {
    WetLabSequencingRunNotes
} from "./WetLabSequencingRunNotes";

import{
    WetLabAnalysisRunNotes
} from "./WetLabAnalysisRunNotes";

import {
    WetLabStandardizationOfWorkflow
} from "./WetLabStandardizationOfWorkflow";

import {
    WetLabStandardizationOfInstrumentPrep
} from "./WetLabStandardizationOfInstrumentPrep";

import {
    WetLabPoolingCalculations
} from "./WetLabPoolingCalculations";

import {
    WetLabDilutionCalculations
} from "./WetLabDilutionCalculations";

import {
    WetLabSequencingQcChecks
} from "./WetLabSequencingQcChecks";

import {
    ReportHeader
} from "./ReportComponents/ReportHeader"

interface IWetLabReportProps {
    workflowRunId: string;
    startDate: Date | undefined;
    dataAnalysisRunNotes: NgsReportAnalysisRunNotesVM[] | undefined;
    dataWorkflowStandardization: NgsReportWetlabStandardizationVM | undefined;
    dataSequencingRunNotes: NgsReportSequencingRunNotesVM[] | undefined;
    dataInstrumentPrepStandardization: NgsReportInstrumentPrepStandardizationVM | undefined;
    dataPoolingCalculations: NgsReportWetLabPoolingCalculationsVM | undefined;
    dataDilutionCalculations: NgsReportWetLabDilutionCalculationsVM | undefined;
    dataSequencingQcChecks : NgsReportSequenceQcChecksVM[] | undefined;
    
    // Report Header props.
    workflowRunNumber: string | undefined;
    workflowName: string;
    workflowDisplayName: string;
    users: string[] | undefined;
    systemSettings: {[key: string]: string};
};


type IWetLabReportPageProps = IResultsServiceInjectedProps & IWetLabReportProps;

export class _WetLabReport extends React.Component<IWetLabReportPageProps> {

    async componentDidMount(){

    }

    render() {
      
        return (
            
            <div className={stylesWetLabReport.mainReport}>
                <ReportHeader
                    workflowRunNumber={this.props.workflowRunNumber}
                    workflowDisplayName={this.props.workflowDisplayName}
                    users={this.props.users}
                    systemSettings={this.props.systemSettings}
                    reportName="Wet-Lab Process"
                />
                <WetLabSequencingRunNotes
                    dataSequencingRunNotes={this.props.dataSequencingRunNotes}
                    startDate={this.props.startDate}
                    workflowName={this.props.workflowName}/>
                <WetLabAnalysisRunNotes dataAnalysisRunNotes={this.props.dataAnalysisRunNotes} />
                <WetLabSequencingQcChecks dataSequencingQcChecks={this.props.dataSequencingQcChecks}/>
                {this.props.workflowName == "Influenza" && <WetLabStandardizationOfWorkflow dataWetlabStandardization={this.props.dataWorkflowStandardization} workflowRunId={this.props.workflowRunId}/>}
                {this.props.workflowName == "Influenza" && <WetLabStandardizationOfInstrumentPrep  dataInstrumentStandardization={this.props.dataInstrumentPrepStandardization} workflowRunId={this.props.workflowRunId}/>}
                <WetLabPoolingCalculations dataPoolingCalculations={this.props.dataPoolingCalculations} workflowName={this.props.workflowName} />
                <WetLabDilutionCalculations dataDilutionCalculations={this.props.dataDilutionCalculations} workflowName={this.props.workflowName} />
            
            </div>

        )
      
    }

}


export const WetLabReport = ResultsService.inject(
    _WetLabReport
);