/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign: (target: any, ...sources: any[]) => any = (Object as any).assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export type ActionType = "ArchivePlate" | "RetainPlate" | "DiscardPlate" | "AddComment" | "ArchivePool" | "RetainPool" | "DiscardPool" | "AddControl";

export interface ActionVM {
    "ActionType": ActionType;
    "Name": string;
    "IsPerformedOnStepCompletion": boolean;
}

export type AdditionalAmountFactorType = "PercentFactor" | "NumberOfSamples";

export interface AnthraxPipelineResult {
    "WorkflowRunNumber"?: number;
    "SequencingStatus"?: SequencingStatus;
    "Results"?: { [key: string]: string; };
}

export interface ArchivedAssetContentVM {
    "Id": string;
    "SampleId": string;
    "Container": AssetVM;
    "HarvestedDate": Date;
    "ArchiveDate": Date;
    "RunNumber": number;
    "Workflow": string;
    "WorkflowRunId": string;
    "Shipped"?: boolean;
    "WellMaterial": WellMaterial;
}

export interface ArchivedContainerVM {
    "Id": string;
    "ContainerType": AssetType;
    "Name": string;
    "SampleCount": number;
    "SampleType": WellMaterial;
    "ArchiveDate": Date;
    "RunNumber": number;
    "Workflow": string;
    "WorkflowRunId": string;
    "Shipped"?: boolean;
}

export type AssetType = "Plate" | "Pool" | "Samples" | "Script" | "PipelineData" | "Rack";

export interface AssetVM {
    "Id": string;
    "Name": string;
    "AssetType": AssetType;
    "PlateSummary"?: PlateSummaryVM;
    "PoolSummary"?: PoolSummaryVM;
    "RackSummary"?: RackSummaryVM;
}

export interface AssociationAddVM {
    "Alias": string;
    "Remote": string;
}

export interface AuditItem {
    "CreationSystemVersion"?: string;
    "AuditAction"?: string;
    "PortalURL"?: string;
    "UserId"?: string;
    "ContentType"?: string;
    "AbsoluteURL"?: string;
    "DateCreated"?: string;
    "DateModified"?: string;
    "Identifier"?: string;
    "ReviewState"?: string;
    "ExtensionData"?: { [key: string]: any; };
}

export interface AuditManagementCategoryVM {
    "Id": string;
    "Name": string;
    "Enabled": boolean;
}

export interface AuditQuerySummaryVM {
    "TotalRecords": number;
    "NumberOfRuns": number;
    "StartDate": Date;
    "EndDate": Date;
    "NumberOfModules": number;
    "NumberOfUniqueSamples": number;
    "NumberOfActions": number;
}

export type BarcodeKitType = "MinION" | "IlluminaNextera" | "IlluminaDualAdapter";

export interface BarcodeWithSubType {
    "Barcode"?: string;
    "SubType"?: string;
}

export type BaseSpaceAnalysisStatus = "Discovered" | "ReadyForProcessing" | "Processed";

export interface BasespaceAnalysisVM {
    "Id": string;
    "Name": string;
}

export interface BatchLogEntry {
    "Type"?: string;
    "Level"?: LogLevel;
    "Message"?: string;
    "OriginalLogTime"?: Date;
}

export type BlobFileSystemType = "DIRECTORY" | "FILE";

export type BlobSynchronizationStateType = "IDLE" | "INPROGRESS" | "DONE";

export interface CSVBarcodeVM {
    "Position"?: string;
    "SequenceName"?: string;
    "Sequence"?: string;
    "ReagentId"?: string;
    "ReagentName"?: string;
}

export interface CSVQubitFlurometerVM {
    "SampleId"?: string;
    "WellPosition"?: string;
    "RunId"?: string;
    "AssayName"?: string;
    "TestName"?: string;
    "TestDate"?: Date;
    "QubitConcentration"?: number;
    "QubitConcentrationUnits"?: string;
    "OriginalSampleConcentration"?: number;
    "OriginalSasmpleConcentrationUnits"?: string;
    "SampleVolume"?: number;
    "DilutionFactor"?: number;
    "Std1RFU"?: number;
    "Std2RFU"?: number;
    "Std3RFU"?: number;
    "Excitation"?: string;
    "Emission"?: string;
    "GreenRFU"?: number;
}

export interface CalculationRequestVM {
    "CalculationType"?: string;
    "DataDictionary"?: { [key: string]: any; };
    "ArgsDictionary"?: { [key: string]: any; };
}

export interface CalculationResponseVM {
    "CalculationResponse"?: any;
}

export interface ChunkedUploadVM {
    "guid": string;
}

export type ClassType = "RNA" | "Virus";

export interface CompleteStepRequestVM {
    "StepInstanceId": string;
    "WorkflowRunId": string;
    "RegridUsingPassedSamples"?: boolean;
}

export interface ComponentSetFooterVM {
    "Id"?: string;
    "Description": string;
}

export interface ComponentSetInstanceVM {
    "Id": string;
    "StepInstanceId": string;
    "ComponentSetItemInstances": Array<ComponentSetItemInstanceVM>;
}

export interface ComponentSetItemInstanceVM {
    "Id": string;
    "LotNumber"?: string;
    "ReagentName"?: string;
    "ReagentId"?: string;
    "ReagentLotId"?: string;
    "TotalVolume"?: number;
    "Description"?: string;
    "Ordinal"?: number;
    "Locked"?: boolean;
    "AdditionalInfo"?: string;
}

export interface ComponentSetItemVM {
    "Id"?: string;
    "Ordinal"?: number;
    "ReagentId"?: string;
    "ReagentGroupId"?: string;
    "CustomReagentName"?: string;
    "DisplayName"?: string;
    "Description"?: string;
    "Volume"?: number;
    "AdditionalInfo"?: string;
}

export type ComponentSetType = "ReagentMix" | "InstructionSet" | "ReagentList" | "SimplifiedReagentList";

export interface ComponentSetVM {
    "Id"?: string;
    "DisplayName"?: string;
    "DisplayHeader"?: string;
    "ReagentUsedByType"?: ReagentUsedByType;
    "ComponentSetType"?: ComponentSetType;
    "AdditionalAmountFactorType"?: AdditionalAmountFactorType;
    "AssociatedWorkflows"?: Array<string>;
    "AssociatedRunNumber"?: number;
    "AdditionalAmountFactor"?: number;
    "IsActive"?: boolean;
    "CreatedOn"?: Date;
    "CreatedBy"?: string;
    "DeactivatedOn"?: Date;
    "DeactivatedBy"?: string;
    "ComponentSetItems"?: Array<ComponentSetItemVM>;
    "ComponentSetFooters"?: Array<ComponentSetFooterVM>;
    "ComponentSetInstances"?: Array<ComponentSetInstanceVM>;
}

export type ContentType = "Sample" | "Control";

export interface ControlMaterialVM {
    "Id": string;
    "Name": string;
    "ControlType": string;
    "Description": string;
    "IsActive": boolean;
    "CreatedOn": Date;
    "DeactivatedOn": Date;
}

export interface ControlVM {
    "Id": string;
    "ControlId": string;
    "SampleId": string;
    "Name": string;
}

export interface CustomFieldVM {
    "Key": string;
    "Value"?: string;
}

export interface DemoInformationResponse {
    "RemainingTime"?: string;
    "FeedbackUrl"?: string;
}

export interface DirectoryListingVM {
    "Id": string;
    "Name": string;
    "ContentType": string;
    "CreatedOn": string;
    "SharedFrom": string;
    "SharedWith": Array<FileShareWith>;
    "Signature": string;
    "Type": BlobFileSystemType;
}

export interface DropdownOptionsVM {
    "AuditActions": Array<string>;
    "AuditModules": Array<string>;
    "Instruments": Array<string>;
    "Reagents": Array<string>;
}

export interface ExchangeDetailsVM {
    "Index": string;
    "OrganizationId": string;
    "ContractId": string;
    "DeliveryStatus": number;
    "JsonPayload": string;
    "ContentHash": string;
    "Active": boolean;
    "SendingOrganizationName": string;
    "ReceivingOrganizationName": string;
    "DataCategory": string;
    "ExchangeDate"?: Date;
}

export interface ExportRecordsResponse {
    "ExportedCSV": string;
}

export interface FileOperationResponse {
    "InstrumentFileId"?: string;
    "PresignedUploadURL"?: string;
    "PresignedURLExpiration"?: Date;
    "Success"?: boolean;
}

export interface FileRelevanceRequest {
    "InstrumentId"?: string;
    "FileName"?: string;
    "FilePath"?: string;
    "RelativePath"?: string;
}

export interface FileRelevanceResponse {
    "ShouldProcess"?: boolean;
    "InstrumentFileId"?: string;
    "SampleName"?: string;
    "FileName"?: string;
    "FilePath"?: string;
    "RelativePath"?: string;
    "Success"?: boolean;
}

export interface FileShareWith {
    "InstanceId"?: string;
    "CreatedOn"?: string;
    "State"?: BlobSynchronizationStateType;
}

export interface FilesAndDirectoriesVM {
    "Directories": Array<DirectoryListingVM>;
    "Files": Array<DirectoryListingVM>;
}

export interface GetArchivedAssetContentsBySampleResponse {
    "ArchivedAssetContents": Array<ArchivedAssetContentVM>;
    "TotalPageCount": number;
    "TotalSampleCount": number;
}

export interface GetArchivedContainerResponse {
    "ArchivedContainers": Array<ArchivedContainerVM>;
    "TotalPageCount": number;
    "TotalSampleCount": number;
}

export interface GetExtractionReagentsResponse {
    "Kits": Array<LookupValueVM>;
    "RNAManipulations": Array<LookupValueVM>;
}

export interface GetGATrackingIdResponse {
    "GATrackingId"?: string;
}

export interface GetPlatformVersionResponse {
    "PlatformVersion"?: string;
}

export interface GetProductivityBenchmarkResponseDay {
    "Day": number;
    "Goal": number;
    "TodaysMeanTime": number;
    "RollingAverage": number;
}

export interface GetSequencingStatusResponse {
    "SequencingStatus"?: SequencingStatus;
}

export interface GetTestOrderStatusBoardResponse {
    "Remaining": number;
    "InProgress": number;
    "Failed": number;
}

export interface GetTodaysWorkloadResponse {
    "TotalTestOrders": number;
    "TotalQCFailures": number;
    "LastRunId": string;
    "TimeElapsed": string;
}

export interface GetVersionInfoResponse {
    "VersionInfo"?: string;
}

export interface GetWetlabRunInstrumentInfoResponse {
    "Runs": Array<WetlabRunInstrumentInfoVM>;
    "TotalPageCount": number;
    "TotalRunCount": number;
}

export interface GetWetlabRunsResponse {
    "Runs": Array<WetlabRunVM>;
    "TotalPageCount": number;
    "TotalRunCount": number;
}

export interface GetYearToDateInfoResponse {
    "TotalSamples": Array<number>;
    "TotalRuns": Array<number>;
}

export interface HealthCheckResponse {
    "time"?: string;
    "status"?: string;
    "clientAddress"?: string;
    "forwardedProto"?: string;
    "forwardedFor"?: string;
    "hostname"?: string;
    "service"?: string;
    "commit"?: string;
    "build"?: string;
}

export interface HelpDocumentVM {
    "Filename": string;
    "DisplayName": string;
}

export interface HttpContent {
    "Headers"?: Array<StringStringIEnumerableKeyValuePair>;
}

export interface HttpMethod {
    "Method"?: string;
}

export interface HttpRequestMessage {
    "Version"?: string;
    "VersionPolicy"?: HttpVersionPolicy;
    "Content"?: HttpContent;
    "Method"?: HttpMethod;
    "RequestUri"?: string;
    "Headers"?: Array<StringStringIEnumerableKeyValuePair>;
    "Properties"?: { [key: string]: any; };
    "Options"?: { [key: string]: any; };
}

export interface HttpResponseMessage {
    "Version"?: string;
    "Content"?: HttpContent;
    "StatusCode"?: HttpStatusCode;
    "ReasonPhrase"?: string;
    "Headers"?: Array<StringStringIEnumerableKeyValuePair>;
    "TrailingHeaders"?: Array<StringStringIEnumerableKeyValuePair>;
    "RequestMessage"?: HttpRequestMessage;
    "IsSuccessStatusCode"?: boolean;
}

export type HttpStatusCode = "Continue" | "SwitchingProtocols" | "Processing" | "EarlyHints" | "OK" | "Created" | "Accepted" | "NonAuthoritativeInformation" | "NoContent" | "ResetContent" | "PartialContent" | "MultiStatus" | "AlreadyReported" | "IMUsed" | "MultipleChoices" | "Ambiguous" | "MovedPermanently" | "Moved" | "Found" | "Redirect" | "SeeOther" | "RedirectMethod" | "NotModified" | "UseProxy" | "Unused" | "TemporaryRedirect" | "RedirectKeepVerb" | "PermanentRedirect" | "BadRequest" | "Unauthorized" | "PaymentRequired" | "Forbidden" | "NotFound" | "MethodNotAllowed" | "NotAcceptable" | "ProxyAuthenticationRequired" | "RequestTimeout" | "Conflict" | "Gone" | "LengthRequired" | "PreconditionFailed" | "RequestEntityTooLarge" | "RequestUriTooLong" | "UnsupportedMediaType" | "RequestedRangeNotSatisfiable" | "ExpectationFailed" | "MisdirectedRequest" | "UnprocessableEntity" | "Locked" | "FailedDependency" | "UpgradeRequired" | "PreconditionRequired" | "TooManyRequests" | "RequestHeaderFieldsTooLarge" | "UnavailableForLegalReasons" | "InternalServerError" | "NotImplemented" | "BadGateway" | "ServiceUnavailable" | "GatewayTimeout" | "HttpVersionNotSupported" | "VariantAlsoNegotiates" | "InsufficientStorage" | "LoopDetected" | "NotExtended" | "NetworkAuthenticationRequired";

export type HttpVersionPolicy = "RequestVersionOrLower" | "RequestVersionOrHigher" | "RequestVersionExact";

export interface IlluminaSampleSheetVM {
    "SampleSheet"?: string;
}

export interface InfluenzaPipelineResult {
    "WorkflowRunNumber"?: number;
    "WorkflowRunTag"?: string;
    "SequencingStatus"?: SequencingStatus;
    "Results"?: Array<BarcodeWithSubType>;
}

export interface InitializeChunkedUploadVM {
    "Created"?: string;
    "Modified"?: string;
    "Chunks"?: number;
    "ChunkSize"?: number;
    "Name"?: string;
    "Size"?: number;
    "Type"?: string;
}

export interface InstrumentVM {
    "Id": string;
    "InstrumentName": string;
    "Type": string;
    "Description": string;
    "IsActive": boolean;
    "CreatedOn": Date;
    "DeactivatedOn": Date;
}

export interface KeycloakSettingsVM {
    "Realm": string;
    "Url": string;
    "ClientId": string;
}

export interface LabAssetUploadVM {
    "ReagentCSV"?: string;
    "KitCSV"?: string;
    "InstrumentCSV"?: string;
}

export interface LaunchpadSiteVM {
    "Url": string;
    "Description": string;
    "Available": boolean;
}

export interface LimsBasespaceAnalysis {
    "Id"?: string;
    "Name"?: string;
    "BasespaceId"?: string;
    "Status"?: BaseSpaceAnalysisStatus;
    "Biosamples"?: Array<LimsBasespaceBiosample>;
}

export interface LimsBasespaceBiosample {
    "Id"?: string;
    "Name"?: string;
    "BasespaceId"?: string;
    "AnalysisId"?: string;
    "LimsliteSampleId"?: string;
}

export interface LimsInstrumentFile {
    "Id"?: string;
    "InstrumentId"?: string;
    "SiteId"?: string;
    "FileName"?: string;
    "FullPath"?: string;
    "StorageKey"?: string;
    "Uploaded"?: boolean;
    "DiscoveredOn"?: Date;
    "UploadedOn"?: Date;
    "RelativePath"?: string;
    "InstrumentSampleId"?: string;
    "ExternalFileId"?: string;
}

export interface LimsInstrumentRun {
    "Id"?: string;
    "Name"?: string;
    "SampleCount"?: number;
    "ExternalRunId"?: string;
    "RunPath"?: string;
    "Acquisitioned"?: boolean;
}

export interface LimsInstrumentSample {
    "Id"?: string;
    "SampleId"?: string;
    "SampleCount"?: number;
    "ExternalSampleName"?: string;
    "InstrumentRunId"?: string;
}

export interface LimsSample {
    "Id"?: string;
    "ExternalSampleId"?: string;
    "SampleId"?: string;
    "SpecimenSource"?: string;
    "DateHarvested"?: Date;
    "WorkflowRunStatus"?: number;
    "IsPriority"?: boolean;
}

export type LogLevel = "Trace" | "Debug" | "Information" | "Warning" | "Error" | "Critical" | "None";

export interface LookupValueVM {
    "Id": string;
    "Name": string;
}

export interface ManipulationTypeVM {
    "Id"?: string;
    "Name": string;
    "Description": string;
    "IsActive": boolean;
    "CreatedOn": Date;
    "DeactivatedOn": Date;
}

export interface MetadataTemplateFileVM {
    "Id"?: string;
    "Name"?: string;
    "MetadataTemplateId"?: string;
    "Description"?: string;
    "CsvTemplate"?: string;
    "ExternalResource"?: string;
}

export interface MetadataTemplateVM {
    "Id"?: string;
    "Name"?: string;
    "Description"?: string;
    "JsonTemplate"?: string;
    "MetadataTemplateFiles"?: Array<MetadataTemplateFileVM>;
    "Downloadable"?: boolean;
}

export interface MetadataUploadVM {
    "SampleMetadataCSV"?: string;
    "WetlabMetadataCSV"?: string;
    "DrylabMetadataCSV"?: string;
}

export interface MinKnowConnectionInformationResponse {
    "IsDev": boolean;
    "ConnectedToLaptop": boolean;
    "ConnectedToMinIT": boolean;
    "FlowCellInstalled": boolean;
    "MinITName": string;
    "FlowCellId": string;
    "FlowCellProductCode": string;
    "SpaceOnMinIT": string;
    "SpaceOnLaptop": string;
}

export interface MinitMinderJobStatusVM {
    "RunId": string;
    "Status": string;
    "Tag": string;
    "DirectoryPath": string;
    "DetectedFileCount": number;
    "QueuedFileCount": number;
    "ProcessedFileCount": number;
    "PercentageDone": string;
    "SuccessFailure": string;
    "LastUpdated": string;
    "FinishedTime": string;
}

export interface ModuleInfoVM {
    "Module": string;
    "Version": string;
    "State": string;
}

export interface NBSPipelineResult {
    "WorkflowRunNumber"?: number;
    "WorkflowRunTag"?: string;
    "SequencingStatus"?: SequencingStatus;
    "Results"?: { [key: string]: any; };
}

export interface NGSQCReportsVM {
    "BaseReportInformation"?: NgsBaseReportInformationVM;
    "AnalysisRunNotes"?: Array<NgsReportAnalysisRunNotesVM>;
    "InstrumentPrepStandardization"?: NgsReportInstrumentPrepStandardizationVM;
    "SequencingRunNotes"?: Array<NgsReportSequencingRunNotesVM>;
    "WetlabStandardization"?: NgsReportWetlabStandardizationVM;
    "PoolingCalculations"?: NgsReportWetLabPoolingCalculationsVM;
    "DilutionCalculations"?: NgsReportWetLabDilutionCalculationsVM;
    "SequencingQcChecks"?: Array<NgsReportSequenceQcChecksVM>;
    "BioinformaticsPipelineAnalysis"?: NgsReportBioinformaticsPipelineAnalysisDetailsVM;
    "InstrumentSelection"?: NgsReportInstrumentSelectionDetailsVM;
}

export interface NgsBaseReportInformationVM {
    "RunUsers"?: Array<string>;
    "RunNumber"?: string;
    "RunAdditionalInfo"?: { [key: string]: string; };
    "WorkflowId"?: string;
}

export interface NgsCommentVM {
    "Remarks": string;
    "User": string;
    "CommentTime": Date;
}

export interface NgsReportAnalysisRunNotesVM {
    "StepNumber"?: number;
    "StepName"?: string;
    "Comments": Array<NgsCommentVM>;
}

export interface NgsReportBioinformaticsPipelineAnalysisDetailsVM {
    "BioinformaticTools"?: Array<StringStringTuple>;
    "ReferenceDatasets"?: Array<StringStringTuple>;
    "PipelineRunDate"?: Date;
    "PipelineVersion"?: string;
}

export interface NgsReportInstrumentPrepStandardizationVM {
    "Steps"?: Array<NgsReportWorkflowStandardizationStepsVM>;
    "PreLoadingCount"?: number;
    "PostLoadingCount"?: number;
}

export interface NgsReportInstrumentSelectionDetailsVM {
    "InstrumentSelection"?: string;
}

export interface NgsReportLibraryPrepSampleVM {
    "SampleId"?: string;
    "Concentration"?: number;
    "TargetPoolDNAAmount"?: number;
    "VolumeFactor"?: number;
    "ScaledVolume"?: number;
}

export interface NgsReportSequenceQcChecksVM {
    "StepNumber": number;
    "StepName": string;
    "QcCheckRequirement": string;
    "WorkflowRunValue": string;
    "Passed"?: boolean;
    "QCCheckResult": QCCheckResultVM;
}

export interface NgsReportSequencingRunNotesVM {
    "SampleId"?: string;
    "Passed"?: RTPCRPass;
    "IncludedInAnalysis"?: RTPCRPass;
}

export interface NgsReportWetLabDilutionCalculationsVM {
    "LibraryConcetration"?: string;
    "LibrarySize"?: string;
    "TargetStartingVolume"?: string;
}

export interface NgsReportWetLabPoolingCalculationsVM {
    "NumberOfSamples"?: number;
    "NumberOfControls"?: number;
}

export interface NgsReportWetlabStandardizationVM {
    "SequencingAnalysisStartDate"?: Date;
    "Steps"?: Array<NgsReportWorkflowStandardizationStepsVM>;
    "Samples"?: Array<NgsReportLibraryPrepSampleVM>;
}

export interface NgsReportWorkflowStandardizationStepsVM {
    "StepNumber"?: number;
    "StepName"?: string;
    "StepInstanceId"?: string;
}

export interface NoteVM {
    "Id": string;
    "Remarks": string;
    "UserId": string;
    "CreatedOn"?: Date;
}

export interface PCRControlInfoVM {
    "InstrumentNameLabel": string;
    "InstrumentNameValue"?: string;
    "InstrumentNameIsReadonly": boolean;
    "ProgramNameLabel": string;
    "ProgramNameValue": string;
    "ProgramStepsMarkdown": string;
}

export interface ParseSampleSheetRequest {
    "SampleSheetRecords"?: Array<UploadSampleSheetRecord>;
    "RelativePath"?: string;
}

export interface PipelineRunVM {
    "Id": string;
    "Status": SequencingStatus;
    "StartTime": Date;
    "EndTime": Date;
    "Message": string;
    "PipelineId": string;
    "RunNumber": number;
    "PipelineName": string;
    "WetlabRunNumber": number;
    "WetlabRunId": string;
    "BasespaceAnalysisName": string;
}

export interface PipelineStart {
    "WorkflowRunNumber"?: number;
    "RunTag"?: string;
}

export type PlateStatus = "Empty" | "ReadyForUse" | "InUse" | "Archived" | "Discarded" | "Failed" | "Retained";

export interface PlateSummaryVM {
    "Id": string;
    "Name": string;
    "Status": PlateStatus;
    "WellMaterial": WellMaterial;
    "Barcode": string;
}

export interface PlateVM {
    "WellContents": Array<WellContentVM>;
    "WellVolume"?: number;
    "IsOutput"?: boolean;
    "Id": string;
    "Name": string;
    "Status": PlateStatus;
    "WellMaterial": WellMaterial;
    "Barcode": string;
}

export interface PollResetStatusResponse {
    "Success"?: boolean;
    "Output"?: string;
}

export type PoolStatus = "Empty" | "ReadyForUse" | "InUse" | "Archived" | "Discarded" | "Failed" | "Retained" | "SequenceComplete";

export interface PoolSummaryVM {
    "Id": string;
    "Status": PoolStatus;
    "Name": string;
}

export interface PoolVM {
    "Volume"?: number;
    "RunCount"?: number;
    "SampleCount"?: number;
    "Id": string;
    "Status": PoolStatus;
    "Name": string;
}

export interface PoolingResultVM {
    "Id": string;
    "ScaledVolume": number;
    "ShouldBePooled": boolean;
}

export interface PopulatePoolFromPlateRequestVM {
    "AssetId": string;
    "PoolId": string;
    "WorkflowRunId": string;
    "PoolingResults": Array<PoolingResultVM>;
}

export interface PostFileDataRequest {
    "LimsInstrumentSampleId"?: string;
    "InstrumentFileList"?: Array<LimsInstrumentFile>;
}

export interface PostMetadataRequest {
    "SampleId"?: string;
    "MetadataJson"?: string;
}

export interface PostPipelineRunFailureRequest {
    "RunNumber"?: number;
}

export interface ProblemDetails {
    "Type"?: string;
    "Title"?: string;
    "Status"?: number;
    "Detail"?: string;
    "Instance"?: string;
    "Extensions"?: { [key: string]: any; };
}

export interface QCCheckConfigParametersVM {
    "Id": string;
    "Display": string;
    "Value": string;
    "IsReadOnly": boolean;
}

export interface QCCheckConfigVM {
    "Parameters": Array<QCCheckConfigParametersVM>;
}

export interface QCCheckInstanceVM {
    "Id": string;
    "StepName": string;
    "QCName": string;
    "QCCategory": string;
    "QCAction": string;
    "Enabled": boolean;
    "Description": string;
    "CheckConfig"?: QCCheckConfigVM;
    "QCCheckType": string;
}

export interface QCCheckResultVM {
    "Id"?: string;
    "Date": Date;
    "QCCheckInstance": QCCheckInstanceVM;
    "MeasuredValue": string;
    "FailureActionStatus"?: number;
    "ActionNote"?: string;
    "Pass": boolean;
}

export interface QCCheckVM {
    "Id": string;
    "StepName"?: string;
    "QCName": string;
    "QCCategory": string;
    "QCAction": string;
    "Enabled": boolean;
    "Description": string;
    "CheckConfig"?: QCCheckConfigVM;
}

export interface QuantAfterEndRepairQCRequest {
    "PoolId": string;
    "UserId": string;
    "Concentration": number;
    "QCFollowed": boolean;
    "RunNumber": string;
}

export interface QubitSampleVM {
    "Id"?: string;
    "SampleId"?: string;
    "SpecimenSource"?: string;
    "SubType"?: string;
    "SampleStatus"?: number;
    "WellPosition"?: string;
    "DateHarvested"?: Date;
    "IsPriority"?: boolean;
    "WellContentId"?: string;
}

export interface QubitSamplesVM {
    "Samples"?: Array<QubitSampleVM>;
}

export type RTPCRPass = "Fail" | "Pass" | "ContinueWithFailed";

export type RackStatus = "Empty" | "ReadyForUse" | "InUse" | "Archived" | "Discarded" | "Failed" | "Retained";

export interface RackSummaryVM {
    "Id": string;
    "Name": string;
    "Status": RackStatus;
    "WellMaterial": WellMaterial;
    "Barcode": string;
}

export interface RackVM {
    "WellContents": Array<WellContentVM>;
    "WellVolume"?: number;
    "Id": string;
    "Name": string;
    "Status": RackStatus;
    "WellMaterial": WellMaterial;
    "Barcode": string;
}

export interface ReagentGroupLotVM {
    "Id": string;
    "LotNumber": string;
    "IsActive": boolean;
    "CreatedOn": Date;
    "CreatedBy": string;
    "UsedInRun": ReagentRunStatus;
    "DeactivatedOn"?: Date;
    "DeactivatedBy"?: string;
    "IsNew": boolean;
    "ReagentLots": Array<ReagentLotVM>;
    "ReagentGroupId"?: string;
    "ExpirationDate": Date;
}

export type ReagentGroupType = "Pool" | "CustomKit" | "CommercialKit" | "MinIONBarcodeKit" | "IlluminaNexteraBarcodeKit" | "IlluminaDualAdapterBarcodeKit";

export interface ReagentGroupVM {
    "Id"?: string;
    "Name": string;
    "Description"?: string;
    "IsActive": boolean;
    "CreatedOn": Date;
    "CreatedBy": string;
    "DeactivatedOn"?: Date;
    "DeactivatedBy"?: string;
    "CanUseReagentsIndependently": boolean;
    "ReagentGroupType": ReagentGroupType;
    "Lots": Array<ReagentGroupLotVM>;
    "UsedInSteps"?: Array<StringInt32Tuple>;
    "UsedInRun"?: boolean;
}

export interface ReagentLotVM {
    "Id"?: string;
    "LotNumber": string;
    "IsActive": boolean;
    "CreatedOn": Date;
    "CreatedBy": string;
    "UsedInRun": ReagentRunStatus;
    "DeactivatedOn"?: Date;
    "DeactivatedBy"?: string;
    "ExpirationDate": Date;
    "IsNew": boolean;
    "ReagentId"?: string;
    "ReagentName"?: string;
    "ReagentDescription"?: string;
    "BarcodePosition"?: string;
    "BarcodeWellPosition"?: string;
    "SequenceName"?: string;
    "Sequence"?: string;
}

export type ReagentRunStatus = "NotUsed" | "UsedInPrevious" | "UsedInActive";

export type ReagentType = "Reagent" | "BarcodeReagent" | "SeededBarcodeKitReagent";

export type ReagentUsedByType = "PerWellOfSamplePlate" | "SamplePool";

export interface ReagentVM {
    "Id"?: string;
    "Name": string;
    "Description"?: string;
    "IsActive": boolean;
    "CreatedOn": Date;
    "CreatedBy": string;
    "DeactivatedOn"?: Date;
    "DeactivatedBy"?: string;
    "Lots": Array<ReagentLotVM>;
    "InKits": number;
    "UsedInRun": boolean;
    "ReagentType": ReagentType;
    "KitName"?: string;
    "KitId"?: string;
    "UsedInSteps"?: Array<StringInt32Tuple>;
}

export interface RemoteAssociationVM {
    "Id": string;
    "Alias": string;
    "Remote": string;
    "InstanceGuid": string;
    "State": RemoteInstanceStateType;
    "Disposition": RemoteInstanceDispositionType;
    "Created": string;
    "LastAction": string;
}

export type RemoteInstanceDispositionType = "ACCEPTED" | "REJECTED" | "CANCELLED" | "REMOVED_LOCAL" | "REMOVED_REMOTE" | "EXPIRED" | "TIMED_OUT";

export type RemoteInstanceStateType = "UNASSOCIATED" | "ASSOCIATED" | "ASSOCIATE_REQUEST_PENDING" | "ASSOCIATE_REQUEST_ACTIVE" | "ASSOCIATE_REQUEST_WAIT_FOR_RESOLUTION" | "APPROVAL_WAIT" | "SEND_TO_PENDING" | "SEND_TO_ACTIVE";

export type RequirementType = "ReagentMix" | "InstructionSet" | "ReagentList" | "SimplifiedReagentList";

export interface ResetEnvironmentResponse {
    "PollToken"?: string;
}

export interface RtPCRResultVM {
    "SampleId": string;
    "PassFailContinue"?: number;
    "ThresholdCycles"?: Array<ThresholdCycleVM>;
}

export interface RunAcquisitionedRequest {
    "RunPath"?: string;
}

export interface RunAndSampleForFileResponse {
    "LimsInstrumentSample"?: LimsInstrumentSample;
    "LimsInstrumentRun"?: LimsInstrumentRun;
}

export interface RunUserVM {
    "WorkflowRunId": string;
    "UserId": string;
    "IsCurrentUser": boolean;
}

export interface SampleVM {
    "Id": string;
    "SampleId": string;
    "SpecimenSource": string;
    "SampleStatus": number;
    "WellPosition"?: number;
    "DateHarvested": Date;
    "IsPriority"?: boolean;
}

export interface SampleWithMetadataVM {
    "SampleId"?: string;
    "PopulatedJSON"?: string;
}

export interface SarsCOV2PipelineWorkflowResult {
    "WorkflowRunNumber"?: number;
}

export interface SequencingDataVM {
    "SampleId": string;
    "Metadata"?: Array<SequencingResultsMetadataInstanceVM>;
    "MetadataDictionary"?: { [key: string]: string; };
    "SampleWellPosition"?: string;
}

export interface SequencingResultsMetadataInstanceVM {
    "Id"?: string;
    "Metadata"?: string;
}

export type SequencingStatus = "NotStarted" | "Started" | "Complete" | "Failure" | "PartialResults" | "ResultsReleased" | "ResultsNotReleased";

export interface ShareFilesystemRequestDto {
    "InstanceId"?: string;
}

export interface SimplifiedMinderRunStatusVM {
    "RunNumber": string;
    "Status": string;
    "ContainerId"?: string;
    "StartTime"?: Date;
    "FinishTime"?: Date;
    "TimeElapsed"?: string;
    "SequencingStatus": SequencingStatus;
}

export interface SimulatorStartVM {
    "Iterations"?: number;
    "FilesPerIteration"?: number;
    "PositionPause"?: number;
    "RunId"?: string;
    "CopyMode"?: string;
    "Wait"?: boolean;
}

export interface SimulatorStatusVM {
    "RunIteration": string;
    "Position": string;
    "IsRunning": boolean;
    "MaxSimulatorPositions": number;
}

export interface SiteInfoVM {
    "InstanceGuid": string;
    "Remote": string;
    "CertificateSubject"?: string;
    "CertificateSerial"?: string;
    "CertificateValidFrom"?: string;
    "CertificateValidTo"?: string;
}

export interface StepCompleteResponse {
    "StepInstanceId"?: string;
}

export type StepInputOutputType = "Plate" | "Samples" | "Pool" | "PipelineData" | "Rack";

export interface StepInstanceControlsCountVM {
    "StepInstanceId"?: string;
    "Name"?: string;
    "Count"?: number;
}

export interface StepInstanceVM {
    "StepId": string;
    "StepTypeId": string;
    "WorkflowOrder": number;
    "Name": string;
    "InputType": StepInputOutputType;
    "OutputType": StepInputOutputType;
    "InputDescription": string;
    "OutputDescription": string;
    "InputName": string;
    "OutputName": string;
    "AllowPause": boolean;
    "StepInstanceId": string;
    "Status": StepStatus;
    "Actions": Array<ActionVM>;
    "InputAssets"?: Array<AssetVM>;
    "OutputAssets"?: Array<AssetVM>;
    "CustomFields": { [key: string]: string; };
    "Notes"?: Array<NoteVM>;
    "IsRestartPoint": boolean;
    "HelpDocuments"?: Array<HelpDocumentVM>;
    "QCCheckInstances"?: Array<QCCheckInstanceVM>;
    "StepInstructions"?: string;
}

export interface StepRequirementGroupVM {
    "StepName": string;
    "Requirements": Array<StepRequirementVM>;
}

export interface StepRequirementVM {
    "Id": string;
    "StepId": string;
    "StepName": string;
    "RequirementName": string;
    "RequirementType": RequirementType;
    "ComponentSetId"?: string;
    "ComponentSetName"?: string;
    "WorkflowId": string;
}

export type StepStatus = "InProgress" | "Completed" | "Paused" | "Failed" | "TechnicalFailure" | "NotStarted";

export interface StringInt32Tuple {
    "Item1"?: string;
    "Item2"?: number;
}

export interface StringStringIEnumerableKeyValuePair {
    "Key"?: string;
    "Value"?: Array<string>;
}

export interface StringStringTuple {
    "Item1"?: string;
    "Item2"?: string;
}

export interface TemperatureProgramStepCycleVM {
    "Id": string;
    "CycleNumber": number;
    "Description": string;
    "Temperature": number;
    "Duration": number;
    "DurationUnits": TimeUnit;
}

export interface TemperatureProgramStepVM {
    "Id": string;
    "StepNumber": number;
    "NumCycles": number;
    "StepCycles": Array<TemperatureProgramStepCycleVM>;
}

export interface TemperatureProgramVM {
    "Id": string;
    "Name": string;
    "Type": string;
    "Description": string;
    "IsActive": boolean;
    "CreatedOn": Date;
    "DeactivatedOn": Date;
    "Steps": Array<TemperatureProgramStepVM>;
}

export interface TestOrderVM {
    "Id"?: string;
    "SampleCuid"?: string;
    "SampleSource"?: string;
    "SampleDate"?: Date;
}

export interface TestVM {
    "Id": string;
    "Name": string;
    "Type": ClassType;
}

export interface ThresholdCycleVM {
    "Ordinal": number;
    "Value"?: number;
}

export type TimeUnit = "Minute" | "Second";

export interface UpdateConcentrationRequestVM {
    "Id": string;
    "Concentration"?: number;
    "SampleVolume"?: number;
    "AbsorbanceRatio260Over280"?: number;
    "AbsorbanceRatio260Over230"?: number;
}

export interface UpdatePlateStatusRequestVM {
    "Ids": Array<string>;
    "UpdateStatus"?: PlateStatus;
    "RunNumber": number;
    "WorkflowRunId": string;
}

export interface UpdatePoolStatusRequestVM {
    "Ids": Array<string>;
    "UpdateStatus"?: PoolStatus;
    "RunNumber": number;
}

export interface UpdateSamplePriority {
    "Id": string;
    "NewPriority": boolean;
}

export interface UpdateSamplePriorityRequestVM {
    "Samples": Array<UpdateSamplePriority>;
}

export interface UpdateWetlabRunAssetsStatusRequestVM {
    "Ids": Array<string>;
    "AssetType": AssetType;
    "UpdatePlateStatus"?: PlateStatus;
    "UpdateRackStatus"?: RackStatus;
    "RunNumber": number;
    "WetlabRunId": string;
}

export interface UploadFileResponse {
    "Success": boolean;
    "ResultText": string;
}

export interface UploadMetadataResponse {
    "Success": boolean;
    "ResultText": string;
}

export interface UploadSampleSheetRecord {
    "SampleId"?: string;
    "SampleName"?: string;
}

export interface UserPreferencesVM {
    "UserId"?: string;
    "OptOutWelcome"?: boolean;
    "GiveFeedback"?: boolean;
}

export interface WellAddRequestVM {
    "Id": string;
    "Name": string;
    "Positions": Array<number>;
    "WellContent"?: ContentType;
}

export interface WellContentVM {
    "Id": string;
    "Sample"?: SampleVM;
    "Control"?: ControlVM;
    "WellPosition": number;
    "ContentType"?: ContentType;
    "Concentration"?: number;
    "RtpcrPass"?: RTPCRPass;
    "RunCount": number;
    "AbsorbanceRatio260Over280"?: number;
    "AbsorbanceRatio260Over230"?: number;
}

export type WellMaterial = "Virus" | "DNA" | "RNA" | "Sample" | "NotApplicable";

export interface WetlabRunAssetsVM {
    "Plates"?: Array<PlateVM>;
    "Racks"?: Array<RackVM>;
    "Pools"?: Array<PoolVM>;
}

export interface WetlabRunBarcodeReagentMapVM {
    "ReagentLotId": string;
    "ReagentGroupLotId": string;
    "WellPosition": number;
}

export interface WetlabRunBarcodeVM {
    "Id": string;
    "WetlabRunId": string;
    "ReagentGroupLotId"?: string;
    "ReagentLotId": string;
    "WellPosition": number;
    "ReagentName"?: string;
    "ReagentGroupName"?: string;
    "ReagentGroupLotNumber"?: string;
    "ReagentLotNumber"?: string;
    "Barcode"?: string;
    "Sequence"?: string;
}

export interface WetlabRunBasespaceAnalysesVM {
    "WetlabRunId": string;
    "Name": string;
    "BasespaceAnalyses"?: Array<BasespaceAnalysisVM>;
}

export interface WetlabRunInstrumentInfoVM {
    "WorkflowRunId": string;
    "WorkflowName": string;
    "CreatedDate": Date;
    "RunNumber": number;
    "Thermocyclers": Array<string>;
    "AbsorbanceInstruments": Array<string>;
}

export interface WetlabRunReagentMapVM {
    "LotId"?: string;
    "IsGroup": boolean;
    "ReagentId": string;
    "IsNew": boolean;
    "LotNumber": string;
    "KitId"?: string;
}

export interface WetlabRunVM {
    "Id": string;
    "WorkflowId": string;
    "WorkflowName": string;
    "CreatedDate": Date;
    "FinishedOn"?: Date;
    "WorkflowStatus": number;
    "RunNumber": number;
    "RunState": WorkflowRunState;
    "NumOfSamples": number;
    "SpecimenSourceType": string;
    "Steps": Array<StepInstanceVM>;
    "Samples": Array<SampleVM>;
    "Controls": Array<ControlVM>;
    "CurrentOrLastStepName"?: string;
    "CurrentOrLastStepInstanceId"?: string;
    "SequencingStatus"?: SequencingStatus;
    "AssetColCount": number;
    "AssetRowCount": number;
    "AssetPositionByRow": boolean;
    "IsLocked": boolean;
    "CurrentRunUser"?: string;
    "GriddingCount"?: number;
    "GriddingAssetId"?: string;
    "PoolingCount"?: number;
    "PoolingAssetId"?: string;
    "WorkflowMaxSampleRun": number;
    "UniqueRunUsers"?: Array<string>;
    "SequencingStart"?: Date;
    "UseSafeStoppingPoints": boolean;
    "AdditionalInfo"?: { [key: string]: string; };
    "RunMetadata"?: string;
    "CanRelease"?: boolean;
}

export interface WorkflowMetricVM {
    "Name": string;
    "TotalRuns": number;
    "FailedRuns": number;
    "SamplesRun": number;
    "AverageRunTime": string;
    "MaxRunTime": string;
    "MinRunTime": string;
    "RunTimeStdDev": string;
}

export interface WorkflowPTCAndNTCCheck {
    "PTCMinumum": number;
    "NTCMinimum": number;
}

export interface WorkflowQCSummaryVM {
    "WorkflowName": string;
    "WorkflowId": string;
    "NumberOfQC": number;
    "NumberOfQCEnabled": number;
    "NumberOfQCDisabled": number;
}

export interface WorkflowReagentsVM {
    "Reagents": Array<ReagentVM>;
    "ReagentGroups": Array<ReagentGroupVM>;
}

export type WorkflowRunState = "InProgress" | "Completed" | "Paused" | "Failed" | "TechnicalFailure" | "CompletedWithPipelineError";

export interface WorkflowStepWithInitiationPointVM {
    "Id": string;
    "Name": string;
    "WorkflowOrder": number;
    "IsInitiationPoint": boolean;
}

export interface WorkflowVM {
    "Id"?: string;
    "Name"?: string;
    "WorkflowId"?: string;
    "SampleSource"?: string;
    "MatchingSampleCount"?: number;
    "Test"?: TestVM;
    "SampleRunMax": number;
    "AllComponentSetAssociationsMet": boolean;
    "DeactivatedReagents": Array<string>;
    "UseSafeStoppingPoints": boolean;
    "VersionInfo": string;
}



/**
 * AssetManagementApi - fetch parameter creator
 */
export const AssetManagementApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1AssetManagementAddControlMaterialPost(params: {  "body"?: ControlMaterialVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AddControlMaterial`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param typeName 
     */
    apiV1AssetManagementAddControlTypePost(params: {  "typeName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AddControlType`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "typeName": params["typeName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddInstrumentPost(params: {  "body"?: InstrumentVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AddInstrument`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddManipulationTypePost(params: {  "body"?: ManipulationTypeVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AddManipulationType`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddReagentGroupPost(params: {  "body"?: ReagentGroupVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AddReagentGroup`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddReagentPost(params: {  "body"?: ReagentVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AddReagent`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddTemperatureProgramPost(params: {  "body"?: TemperatureProgramVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AddTemperatureProgram`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param typeName 
     */
    apiV1AssetManagementAddTemperatureProgramTypePost(params: {  "typeName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AddTemperatureProgramType`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "typeName": params["typeName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowRunId 
     * @param body 
     */
    apiV1AssetManagementAssociateBarcodeKitToRunPost(params: {  "workflowRunId"?: string; "body"?: Array<WetlabRunBarcodeReagentMapVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/AssociateBarcodeKitToRun`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1AssetManagementControlMaterialsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/ControlMaterials`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1AssetManagementControlTypesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/ControlTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1AssetManagementInstrumentsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/Instruments`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1AssetManagementManipulationTypesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/ManipulationTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param type 
     * @param activeOnly 
     */
    apiV1AssetManagementReagentGroupsGet(params: {  "type"?: string; "activeOnly"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/ReagentGroups`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "type": params["type"],
            "activeOnly": params["activeOnly"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param type 
     */
    apiV1AssetManagementReagentsGet(params: {  "type"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/Reagents`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "type": params["type"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1AssetManagementStandaloneReagentsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/StandaloneReagents`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1AssetManagementTemperatureProgramTypesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/TemperatureProgramTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1AssetManagementTemperatureProgramsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/TemperaturePrograms`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateControlMaterialPost(params: {  "body"?: ControlMaterialVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/UpdateControlMaterial`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateInstrumentPost(params: {  "body"?: InstrumentVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/UpdateInstrument`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateManipulationTypePost(params: {  "body"?: ManipulationTypeVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/UpdateManipulationType`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateReagentGroupPost(params: {  "body"?: ReagentGroupVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/UpdateReagentGroup`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateReagentPost(params: {  "body"?: ReagentVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/UpdateReagent`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateTemperatureProgramPost(params: {  "body"?: TemperatureProgramVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/UpdateTemperatureProgram`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowId 
     */
    apiV1AssetManagementWorkflowReagentsGet(params: {  "workflowId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/WorkflowReagents`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowId": params["workflowId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowRunId 
     * @param body 
     */
    apiV1AssetManagementWorkflowReagentsPost(params: {  "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/WorkflowReagents`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowRunId 
     */
    apiV1AssetManagementWorkflowRunBarcodesGet(params: {  "workflowRunId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssetManagement/WorkflowRunBarcodes`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AssetManagementApi - functional programming interface
 */
export const AssetManagementApiFp = {
    /** 
     * @param body 
     */
    apiV1AssetManagementAddControlMaterialPost(params: { "body"?: ControlMaterialVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAddControlMaterialPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param typeName 
     */
    apiV1AssetManagementAddControlTypePost(params: { "typeName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAddControlTypePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddInstrumentPost(params: { "body"?: InstrumentVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAddInstrumentPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddManipulationTypePost(params: { "body"?: ManipulationTypeVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAddManipulationTypePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddReagentGroupPost(params: { "body"?: ReagentGroupVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAddReagentGroupPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddReagentPost(params: { "body"?: ReagentVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAddReagentPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementAddTemperatureProgramPost(params: { "body"?: TemperatureProgramVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAddTemperatureProgramPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param typeName 
     */
    apiV1AssetManagementAddTemperatureProgramTypePost(params: { "typeName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAddTemperatureProgramTypePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowRunId 
     * @param body 
     */
    apiV1AssetManagementAssociateBarcodeKitToRunPost(params: { "workflowRunId"?: string; "body"?: Array<WetlabRunBarcodeReagentMapVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementAssociateBarcodeKitToRunPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1AssetManagementControlMaterialsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ControlMaterialVM>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementControlMaterialsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1AssetManagementControlTypesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementControlTypesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1AssetManagementInstrumentsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<InstrumentVM>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementInstrumentsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1AssetManagementManipulationTypesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ManipulationTypeVM>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementManipulationTypesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param type 
     * @param activeOnly 
     */
    apiV1AssetManagementReagentGroupsGet(params: { "type"?: string; "activeOnly"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReagentGroupVM>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementReagentGroupsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param type 
     */
    apiV1AssetManagementReagentsGet(params: { "type"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReagentVM>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementReagentsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1AssetManagementStandaloneReagentsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReagentVM>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementStandaloneReagentsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1AssetManagementTemperatureProgramTypesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementTemperatureProgramTypesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1AssetManagementTemperatureProgramsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TemperatureProgramVM>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementTemperatureProgramsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateControlMaterialPost(params: { "body"?: ControlMaterialVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementUpdateControlMaterialPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateInstrumentPost(params: { "body"?: InstrumentVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementUpdateInstrumentPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateManipulationTypePost(params: { "body"?: ManipulationTypeVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementUpdateManipulationTypePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateReagentGroupPost(params: { "body"?: ReagentGroupVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementUpdateReagentGroupPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateReagentPost(params: { "body"?: ReagentVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementUpdateReagentPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateTemperatureProgramPost(params: { "body"?: TemperatureProgramVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementUpdateTemperatureProgramPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowId 
     */
    apiV1AssetManagementWorkflowReagentsGet(params: { "workflowId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WorkflowReagentsVM> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementWorkflowReagentsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowRunId 
     * @param body 
     */
    apiV1AssetManagementWorkflowReagentsPost(params: { "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementWorkflowReagentsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowRunId 
     */
    apiV1AssetManagementWorkflowRunBarcodesGet(params: { "workflowRunId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WetlabRunBarcodeVM>> {
        const fetchArgs = AssetManagementApiFetchParamCreator.apiV1AssetManagementWorkflowRunBarcodesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AssetManagementApi - object-oriented interface
 */
export class AssetManagementApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1AssetManagementAddControlMaterialPost(params: {  "body"?: ControlMaterialVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAddControlMaterialPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param typeName 
     */
    apiV1AssetManagementAddControlTypePost(params: {  "typeName"?: string; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAddControlTypePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementAddInstrumentPost(params: {  "body"?: InstrumentVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAddInstrumentPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementAddManipulationTypePost(params: {  "body"?: ManipulationTypeVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAddManipulationTypePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementAddReagentGroupPost(params: {  "body"?: ReagentGroupVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAddReagentGroupPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementAddReagentPost(params: {  "body"?: ReagentVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAddReagentPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementAddTemperatureProgramPost(params: {  "body"?: TemperatureProgramVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAddTemperatureProgramPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param typeName 
     */
    apiV1AssetManagementAddTemperatureProgramTypePost(params: {  "typeName"?: string; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAddTemperatureProgramTypePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowRunId 
     * @param body 
     */
    apiV1AssetManagementAssociateBarcodeKitToRunPost(params: {  "workflowRunId"?: string; "body"?: Array<WetlabRunBarcodeReagentMapVM>; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementAssociateBarcodeKitToRunPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1AssetManagementControlMaterialsGet(options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementControlMaterialsGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1AssetManagementControlTypesGet(options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementControlTypesGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1AssetManagementInstrumentsGet(options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementInstrumentsGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1AssetManagementManipulationTypesGet(options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementManipulationTypesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param type 
     * @param activeOnly 
     */
    apiV1AssetManagementReagentGroupsGet(params: {  "type"?: string; "activeOnly"?: boolean; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementReagentGroupsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param type 
     */
    apiV1AssetManagementReagentsGet(params: {  "type"?: string; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementReagentsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1AssetManagementStandaloneReagentsGet(options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementStandaloneReagentsGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1AssetManagementTemperatureProgramTypesGet(options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementTemperatureProgramTypesGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1AssetManagementTemperatureProgramsGet(options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementTemperatureProgramsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateControlMaterialPost(params: {  "body"?: ControlMaterialVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementUpdateControlMaterialPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateInstrumentPost(params: {  "body"?: InstrumentVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementUpdateInstrumentPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateManipulationTypePost(params: {  "body"?: ManipulationTypeVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementUpdateManipulationTypePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateReagentGroupPost(params: {  "body"?: ReagentGroupVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementUpdateReagentGroupPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateReagentPost(params: {  "body"?: ReagentVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementUpdateReagentPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1AssetManagementUpdateTemperatureProgramPost(params: {  "body"?: TemperatureProgramVM; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementUpdateTemperatureProgramPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowId 
     */
    apiV1AssetManagementWorkflowReagentsGet(params: {  "workflowId"?: string; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementWorkflowReagentsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowRunId 
     * @param body 
     */
    apiV1AssetManagementWorkflowReagentsPost(params: {  "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementWorkflowReagentsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowRunId 
     */
    apiV1AssetManagementWorkflowRunBarcodesGet(params: {  "workflowRunId"?: string; }, options?: any) {
        return AssetManagementApiFp.apiV1AssetManagementWorkflowRunBarcodesGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AssetManagementApi - factory interface
 */
export const AssetManagementApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1AssetManagementAddControlMaterialPost(params: {  "body"?: ControlMaterialVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAddControlMaterialPost(params, options)(fetch, basePath);
        },
        /** 
         * @param typeName 
         */
        apiV1AssetManagementAddControlTypePost(params: {  "typeName"?: string; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAddControlTypePost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementAddInstrumentPost(params: {  "body"?: InstrumentVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAddInstrumentPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementAddManipulationTypePost(params: {  "body"?: ManipulationTypeVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAddManipulationTypePost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementAddReagentGroupPost(params: {  "body"?: ReagentGroupVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAddReagentGroupPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementAddReagentPost(params: {  "body"?: ReagentVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAddReagentPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementAddTemperatureProgramPost(params: {  "body"?: TemperatureProgramVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAddTemperatureProgramPost(params, options)(fetch, basePath);
        },
        /** 
         * @param typeName 
         */
        apiV1AssetManagementAddTemperatureProgramTypePost(params: {  "typeName"?: string; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAddTemperatureProgramTypePost(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowRunId 
         * @param body 
         */
        apiV1AssetManagementAssociateBarcodeKitToRunPost(params: {  "workflowRunId"?: string; "body"?: Array<WetlabRunBarcodeReagentMapVM>; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementAssociateBarcodeKitToRunPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1AssetManagementControlMaterialsGet(options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementControlMaterialsGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1AssetManagementControlTypesGet(options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementControlTypesGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1AssetManagementInstrumentsGet(options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementInstrumentsGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1AssetManagementManipulationTypesGet(options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementManipulationTypesGet(options)(fetch, basePath);
        },
        /** 
         * @param type 
         * @param activeOnly 
         */
        apiV1AssetManagementReagentGroupsGet(params: {  "type"?: string; "activeOnly"?: boolean; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementReagentGroupsGet(params, options)(fetch, basePath);
        },
        /** 
         * @param type 
         */
        apiV1AssetManagementReagentsGet(params: {  "type"?: string; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementReagentsGet(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1AssetManagementStandaloneReagentsGet(options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementStandaloneReagentsGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1AssetManagementTemperatureProgramTypesGet(options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementTemperatureProgramTypesGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1AssetManagementTemperatureProgramsGet(options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementTemperatureProgramsGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementUpdateControlMaterialPost(params: {  "body"?: ControlMaterialVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementUpdateControlMaterialPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementUpdateInstrumentPost(params: {  "body"?: InstrumentVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementUpdateInstrumentPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementUpdateManipulationTypePost(params: {  "body"?: ManipulationTypeVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementUpdateManipulationTypePost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementUpdateReagentGroupPost(params: {  "body"?: ReagentGroupVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementUpdateReagentGroupPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementUpdateReagentPost(params: {  "body"?: ReagentVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementUpdateReagentPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1AssetManagementUpdateTemperatureProgramPost(params: {  "body"?: TemperatureProgramVM; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementUpdateTemperatureProgramPost(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowId 
         */
        apiV1AssetManagementWorkflowReagentsGet(params: {  "workflowId"?: string; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementWorkflowReagentsGet(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowRunId 
         * @param body 
         */
        apiV1AssetManagementWorkflowReagentsPost(params: {  "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementWorkflowReagentsPost(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowRunId 
         */
        apiV1AssetManagementWorkflowRunBarcodesGet(params: {  "workflowRunId"?: string; }, options?: any) {
            return AssetManagementApiFp.apiV1AssetManagementWorkflowRunBarcodesGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * AuditApi - fetch parameter creator
 */
export const AuditApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1AuditPost(params: {  "body"?: AuditItem; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Audit`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AuditApi - functional programming interface
 */
export const AuditApiFp = {
    /** 
     * @param body 
     */
    apiV1AuditPost(params: { "body"?: AuditItem;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AuditApiFetchParamCreator.apiV1AuditPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AuditApi - object-oriented interface
 */
export class AuditApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1AuditPost(params: {  "body"?: AuditItem; }, options?: any) {
        return AuditApiFp.apiV1AuditPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AuditApi - factory interface
 */
export const AuditApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1AuditPost(params: {  "body"?: AuditItem; }, options?: any) {
            return AuditApiFp.apiV1AuditPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * AuditManagementApi - fetch parameter creator
 */
export const AuditManagementApiFetchParamCreator = {
    /** 
     * returns if auditing is enabled for each application
     */
    apiV1AuditManagementAuditingEnabledGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AuditManagement/AuditingEnabled`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update the audit management categories
     * @param body list of categories to update
     */
    apiV1AuditManagementUpdateAuditingEnabledPost(params: {  "body"?: Array<AuditManagementCategoryVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AuditManagement/UpdateAuditingEnabled`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AuditManagementApi - functional programming interface
 */
export const AuditManagementApiFp = {
    /** 
     * returns if auditing is enabled for each application
     */
    apiV1AuditManagementAuditingEnabledGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AuditManagementCategoryVM>> {
        const fetchArgs = AuditManagementApiFetchParamCreator.apiV1AuditManagementAuditingEnabledGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update the audit management categories
     * @param body list of categories to update
     */
    apiV1AuditManagementUpdateAuditingEnabledPost(params: { "body"?: Array<AuditManagementCategoryVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = AuditManagementApiFetchParamCreator.apiV1AuditManagementUpdateAuditingEnabledPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AuditManagementApi - object-oriented interface
 */
export class AuditManagementApi extends BaseAPI {
    /** 
     * returns if auditing is enabled for each application
     */
    apiV1AuditManagementAuditingEnabledGet(options?: any) {
        return AuditManagementApiFp.apiV1AuditManagementAuditingEnabledGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Update the audit management categories
     * @param body list of categories to update
     */
    apiV1AuditManagementUpdateAuditingEnabledPost(params: {  "body"?: Array<AuditManagementCategoryVM>; }, options?: any) {
        return AuditManagementApiFp.apiV1AuditManagementUpdateAuditingEnabledPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AuditManagementApi - factory interface
 */
export const AuditManagementApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * returns if auditing is enabled for each application
         */
        apiV1AuditManagementAuditingEnabledGet(options?: any) {
            return AuditManagementApiFp.apiV1AuditManagementAuditingEnabledGet(options)(fetch, basePath);
        },
        /** 
         * Update the audit management categories
         * @param body list of categories to update
         */
        apiV1AuditManagementUpdateAuditingEnabledPost(params: {  "body"?: Array<AuditManagementCategoryVM>; }, options?: any) {
            return AuditManagementApiFp.apiV1AuditManagementUpdateAuditingEnabledPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * AuditRecordApi - fetch parameter creator
 */
export const AuditRecordApiFetchParamCreator = {
    /** 
     * Get a list of options to populate in dropdowns
     */
    apiV1AuditRecordDropdownOptionsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AuditRecord/DropdownOptions`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordExportRecordsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any): FetchArgs {
        // verify required parameter "auditActions" is set
        if (params["auditActions"] == null) {
            throw new Error("Missing required parameter auditActions when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "auditModules" is set
        if (params["auditModules"] == null) {
            throw new Error("Missing required parameter auditModules when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "fromDate" is set
        if (params["fromDate"] == null) {
            throw new Error("Missing required parameter fromDate when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "toDate" is set
        if (params["toDate"] == null) {
            throw new Error("Missing required parameter toDate when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "userIds" is set
        if (params["userIds"] == null) {
            throw new Error("Missing required parameter userIds when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "instruments" is set
        if (params["instruments"] == null) {
            throw new Error("Missing required parameter instruments when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "reagents" is set
        if (params["reagents"] == null) {
            throw new Error("Missing required parameter reagents when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "lotNumbers" is set
        if (params["lotNumbers"] == null) {
            throw new Error("Missing required parameter lotNumbers when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "runIds" is set
        if (params["runIds"] == null) {
            throw new Error("Missing required parameter runIds when calling apiV1AuditRecordExportRecordsGet");
        }
        // verify required parameter "sampleIds" is set
        if (params["sampleIds"] == null) {
            throw new Error("Missing required parameter sampleIds when calling apiV1AuditRecordExportRecordsGet");
        }
        const baseUrl = `/api/v1/AuditRecord/ExportRecords`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "AuditActions": params["auditActions"],
            "AuditModules": params["auditModules"],
            "FromDate": params["fromDate"],
            "ToDate": params["toDate"],
            "UserIds": params["userIds"],
            "Instruments": params["instruments"],
            "Reagents": params["reagents"],
            "LotNumbers": params["lotNumbers"],
            "RunIds": params["runIds"],
            "SampleIds": params["sampleIds"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredActionsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any): FetchArgs {
        // verify required parameter "auditActions" is set
        if (params["auditActions"] == null) {
            throw new Error("Missing required parameter auditActions when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "auditModules" is set
        if (params["auditModules"] == null) {
            throw new Error("Missing required parameter auditModules when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "fromDate" is set
        if (params["fromDate"] == null) {
            throw new Error("Missing required parameter fromDate when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "toDate" is set
        if (params["toDate"] == null) {
            throw new Error("Missing required parameter toDate when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "userIds" is set
        if (params["userIds"] == null) {
            throw new Error("Missing required parameter userIds when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "instruments" is set
        if (params["instruments"] == null) {
            throw new Error("Missing required parameter instruments when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "reagents" is set
        if (params["reagents"] == null) {
            throw new Error("Missing required parameter reagents when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "lotNumbers" is set
        if (params["lotNumbers"] == null) {
            throw new Error("Missing required parameter lotNumbers when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "runIds" is set
        if (params["runIds"] == null) {
            throw new Error("Missing required parameter runIds when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        // verify required parameter "sampleIds" is set
        if (params["sampleIds"] == null) {
            throw new Error("Missing required parameter sampleIds when calling apiV1AuditRecordGetFilteredActionsGet");
        }
        const baseUrl = `/api/v1/AuditRecord/GetFilteredActions`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "AuditActions": params["auditActions"],
            "AuditModules": params["auditModules"],
            "FromDate": params["fromDate"],
            "ToDate": params["toDate"],
            "UserIds": params["userIds"],
            "Instruments": params["instruments"],
            "Reagents": params["reagents"],
            "LotNumbers": params["lotNumbers"],
            "RunIds": params["runIds"],
            "SampleIds": params["sampleIds"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredModulesGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any): FetchArgs {
        // verify required parameter "auditActions" is set
        if (params["auditActions"] == null) {
            throw new Error("Missing required parameter auditActions when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "auditModules" is set
        if (params["auditModules"] == null) {
            throw new Error("Missing required parameter auditModules when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "fromDate" is set
        if (params["fromDate"] == null) {
            throw new Error("Missing required parameter fromDate when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "toDate" is set
        if (params["toDate"] == null) {
            throw new Error("Missing required parameter toDate when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "userIds" is set
        if (params["userIds"] == null) {
            throw new Error("Missing required parameter userIds when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "instruments" is set
        if (params["instruments"] == null) {
            throw new Error("Missing required parameter instruments when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "reagents" is set
        if (params["reagents"] == null) {
            throw new Error("Missing required parameter reagents when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "lotNumbers" is set
        if (params["lotNumbers"] == null) {
            throw new Error("Missing required parameter lotNumbers when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "runIds" is set
        if (params["runIds"] == null) {
            throw new Error("Missing required parameter runIds when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        // verify required parameter "sampleIds" is set
        if (params["sampleIds"] == null) {
            throw new Error("Missing required parameter sampleIds when calling apiV1AuditRecordGetFilteredModulesGet");
        }
        const baseUrl = `/api/v1/AuditRecord/GetFilteredModules`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "AuditActions": params["auditActions"],
            "AuditModules": params["auditModules"],
            "FromDate": params["fromDate"],
            "ToDate": params["toDate"],
            "UserIds": params["userIds"],
            "Instruments": params["instruments"],
            "Reagents": params["reagents"],
            "LotNumbers": params["lotNumbers"],
            "RunIds": params["runIds"],
            "SampleIds": params["sampleIds"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredSamplesGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any): FetchArgs {
        // verify required parameter "auditActions" is set
        if (params["auditActions"] == null) {
            throw new Error("Missing required parameter auditActions when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "auditModules" is set
        if (params["auditModules"] == null) {
            throw new Error("Missing required parameter auditModules when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "fromDate" is set
        if (params["fromDate"] == null) {
            throw new Error("Missing required parameter fromDate when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "toDate" is set
        if (params["toDate"] == null) {
            throw new Error("Missing required parameter toDate when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "userIds" is set
        if (params["userIds"] == null) {
            throw new Error("Missing required parameter userIds when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "instruments" is set
        if (params["instruments"] == null) {
            throw new Error("Missing required parameter instruments when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "reagents" is set
        if (params["reagents"] == null) {
            throw new Error("Missing required parameter reagents when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "lotNumbers" is set
        if (params["lotNumbers"] == null) {
            throw new Error("Missing required parameter lotNumbers when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "runIds" is set
        if (params["runIds"] == null) {
            throw new Error("Missing required parameter runIds when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        // verify required parameter "sampleIds" is set
        if (params["sampleIds"] == null) {
            throw new Error("Missing required parameter sampleIds when calling apiV1AuditRecordGetFilteredSamplesGet");
        }
        const baseUrl = `/api/v1/AuditRecord/GetFilteredSamples`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "AuditActions": params["auditActions"],
            "AuditModules": params["auditModules"],
            "FromDate": params["fromDate"],
            "ToDate": params["toDate"],
            "UserIds": params["userIds"],
            "Instruments": params["instruments"],
            "Reagents": params["reagents"],
            "LotNumbers": params["lotNumbers"],
            "RunIds": params["runIds"],
            "SampleIds": params["sampleIds"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * returns if auditing is enabled for each application
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordQueryAuditRecordsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any): FetchArgs {
        // verify required parameter "auditActions" is set
        if (params["auditActions"] == null) {
            throw new Error("Missing required parameter auditActions when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "auditModules" is set
        if (params["auditModules"] == null) {
            throw new Error("Missing required parameter auditModules when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "fromDate" is set
        if (params["fromDate"] == null) {
            throw new Error("Missing required parameter fromDate when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "toDate" is set
        if (params["toDate"] == null) {
            throw new Error("Missing required parameter toDate when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "userIds" is set
        if (params["userIds"] == null) {
            throw new Error("Missing required parameter userIds when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "instruments" is set
        if (params["instruments"] == null) {
            throw new Error("Missing required parameter instruments when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "reagents" is set
        if (params["reagents"] == null) {
            throw new Error("Missing required parameter reagents when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "lotNumbers" is set
        if (params["lotNumbers"] == null) {
            throw new Error("Missing required parameter lotNumbers when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "runIds" is set
        if (params["runIds"] == null) {
            throw new Error("Missing required parameter runIds when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        // verify required parameter "sampleIds" is set
        if (params["sampleIds"] == null) {
            throw new Error("Missing required parameter sampleIds when calling apiV1AuditRecordQueryAuditRecordsGet");
        }
        const baseUrl = `/api/v1/AuditRecord/QueryAuditRecords`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "AuditActions": params["auditActions"],
            "AuditModules": params["auditModules"],
            "FromDate": params["fromDate"],
            "ToDate": params["toDate"],
            "UserIds": params["userIds"],
            "Instruments": params["instruments"],
            "Reagents": params["reagents"],
            "LotNumbers": params["lotNumbers"],
            "RunIds": params["runIds"],
            "SampleIds": params["sampleIds"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AuditRecordApi - functional programming interface
 */
export const AuditRecordApiFp = {
    /** 
     * Get a list of options to populate in dropdowns
     */
    apiV1AuditRecordDropdownOptionsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DropdownOptionsVM> {
        const fetchArgs = AuditRecordApiFetchParamCreator.apiV1AuditRecordDropdownOptionsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordExportRecordsGet(params: { "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExportRecordsResponse> {
        const fetchArgs = AuditRecordApiFetchParamCreator.apiV1AuditRecordExportRecordsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredActionsGet(params: { "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = AuditRecordApiFetchParamCreator.apiV1AuditRecordGetFilteredActionsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredModulesGet(params: { "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = AuditRecordApiFetchParamCreator.apiV1AuditRecordGetFilteredModulesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredSamplesGet(params: { "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = AuditRecordApiFetchParamCreator.apiV1AuditRecordGetFilteredSamplesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * returns if auditing is enabled for each application
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordQueryAuditRecordsGet(params: { "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuditQuerySummaryVM> {
        const fetchArgs = AuditRecordApiFetchParamCreator.apiV1AuditRecordQueryAuditRecordsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AuditRecordApi - object-oriented interface
 */
export class AuditRecordApi extends BaseAPI {
    /** 
     * Get a list of options to populate in dropdowns
     */
    apiV1AuditRecordDropdownOptionsGet(options?: any) {
        return AuditRecordApiFp.apiV1AuditRecordDropdownOptionsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordExportRecordsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
        return AuditRecordApiFp.apiV1AuditRecordExportRecordsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredActionsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
        return AuditRecordApiFp.apiV1AuditRecordGetFilteredActionsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredModulesGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
        return AuditRecordApiFp.apiV1AuditRecordGetFilteredModulesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordGetFilteredSamplesGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
        return AuditRecordApiFp.apiV1AuditRecordGetFilteredSamplesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * returns if auditing is enabled for each application
     * @param auditActions 
     * @param auditModules 
     * @param fromDate 
     * @param toDate 
     * @param userIds 
     * @param instruments 
     * @param reagents 
     * @param lotNumbers 
     * @param runIds 
     * @param sampleIds 
     */
    apiV1AuditRecordQueryAuditRecordsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
        return AuditRecordApiFp.apiV1AuditRecordQueryAuditRecordsGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AuditRecordApi - factory interface
 */
export const AuditRecordApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get a list of options to populate in dropdowns
         */
        apiV1AuditRecordDropdownOptionsGet(options?: any) {
            return AuditRecordApiFp.apiV1AuditRecordDropdownOptionsGet(options)(fetch, basePath);
        },
        /** 
         * @param auditActions 
         * @param auditModules 
         * @param fromDate 
         * @param toDate 
         * @param userIds 
         * @param instruments 
         * @param reagents 
         * @param lotNumbers 
         * @param runIds 
         * @param sampleIds 
         */
        apiV1AuditRecordExportRecordsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
            return AuditRecordApiFp.apiV1AuditRecordExportRecordsGet(params, options)(fetch, basePath);
        },
        /** 
         * @param auditActions 
         * @param auditModules 
         * @param fromDate 
         * @param toDate 
         * @param userIds 
         * @param instruments 
         * @param reagents 
         * @param lotNumbers 
         * @param runIds 
         * @param sampleIds 
         */
        apiV1AuditRecordGetFilteredActionsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
            return AuditRecordApiFp.apiV1AuditRecordGetFilteredActionsGet(params, options)(fetch, basePath);
        },
        /** 
         * @param auditActions 
         * @param auditModules 
         * @param fromDate 
         * @param toDate 
         * @param userIds 
         * @param instruments 
         * @param reagents 
         * @param lotNumbers 
         * @param runIds 
         * @param sampleIds 
         */
        apiV1AuditRecordGetFilteredModulesGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
            return AuditRecordApiFp.apiV1AuditRecordGetFilteredModulesGet(params, options)(fetch, basePath);
        },
        /** 
         * @param auditActions 
         * @param auditModules 
         * @param fromDate 
         * @param toDate 
         * @param userIds 
         * @param instruments 
         * @param reagents 
         * @param lotNumbers 
         * @param runIds 
         * @param sampleIds 
         */
        apiV1AuditRecordGetFilteredSamplesGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
            return AuditRecordApiFp.apiV1AuditRecordGetFilteredSamplesGet(params, options)(fetch, basePath);
        },
        /** 
         * returns if auditing is enabled for each application
         * @param auditActions 
         * @param auditModules 
         * @param fromDate 
         * @param toDate 
         * @param userIds 
         * @param instruments 
         * @param reagents 
         * @param lotNumbers 
         * @param runIds 
         * @param sampleIds 
         */
        apiV1AuditRecordQueryAuditRecordsGet(params: {  "auditActions": Array<string>; "auditModules": Array<string>; "fromDate": string; "toDate": string; "userIds": string; "instruments": Array<string>; "reagents": Array<string>; "lotNumbers": string; "runIds": string; "sampleIds": string; }, options?: any) {
            return AuditRecordApiFp.apiV1AuditRecordQueryAuditRecordsGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * BlockchainApi - fetch parameter creator
 */
export const BlockchainApiFetchParamCreator = {
    /** 
     * @param s3Key 
     */
    apiV1BlockchainDownloadInternalS3FileGet(params: {  "s3Key"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Blockchain/downloadInternalS3File`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "s3Key": params["s3Key"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param receiver 
     */
    apiV1BlockchainGetOrganizationExchangeDetailsGet(params: {  "receiver"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Blockchain/GetOrganizationExchangeDetails`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "receiver": params["receiver"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * BlockchainApi - functional programming interface
 */
export const BlockchainApiFp = {
    /** 
     * @param s3Key 
     */
    apiV1BlockchainDownloadInternalS3FileGet(params: { "s3Key"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = BlockchainApiFetchParamCreator.apiV1BlockchainDownloadInternalS3FileGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param receiver 
     */
    apiV1BlockchainGetOrganizationExchangeDetailsGet(params: { "receiver"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExchangeDetailsVM>> {
        const fetchArgs = BlockchainApiFetchParamCreator.apiV1BlockchainGetOrganizationExchangeDetailsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * BlockchainApi - object-oriented interface
 */
export class BlockchainApi extends BaseAPI {
    /** 
     * @param s3Key 
     */
    apiV1BlockchainDownloadInternalS3FileGet(params: {  "s3Key"?: string; }, options?: any) {
        return BlockchainApiFp.apiV1BlockchainDownloadInternalS3FileGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param receiver 
     */
    apiV1BlockchainGetOrganizationExchangeDetailsGet(params: {  "receiver"?: boolean; }, options?: any) {
        return BlockchainApiFp.apiV1BlockchainGetOrganizationExchangeDetailsGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * BlockchainApi - factory interface
 */
export const BlockchainApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param s3Key 
         */
        apiV1BlockchainDownloadInternalS3FileGet(params: {  "s3Key"?: string; }, options?: any) {
            return BlockchainApiFp.apiV1BlockchainDownloadInternalS3FileGet(params, options)(fetch, basePath);
        },
        /** 
         * @param receiver 
         */
        apiV1BlockchainGetOrganizationExchangeDetailsGet(params: {  "receiver"?: boolean; }, options?: any) {
            return BlockchainApiFp.apiV1BlockchainGetOrganizationExchangeDetailsGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * CSVApi - fetch parameter creator
 */
export const CSVApiFetchParamCreator = {
    /** 
     * Returns CSV template
     */
    apiV1CSVMetadataTemplatesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/MetadataTemplates`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Parse a csv of barcode reagents for a plate.
     * @param body 
     */
    apiV1CSVParseCSVFlurometerOutputPost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/ParseCSVFlurometerOutput`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns CSV template
     */
    apiV1CSVSampleMetadataGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/SampleMetadata`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates results metadata.
     * @param body 
     */
    apiV1CSVUpdateResultsMetadataPost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/UpdateResultsMetadata`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Parse a csv of barcode reagents for a plate.
     * @param barcodeKitType 
     * @param body 
     */
    apiV1CSVUploadBarcodesPost(params: {  "barcodeKitType"?: string; "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/UploadBarcodes`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "barcodeKitType": params["barcodeKitType"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Uploads metadata to the system and the necessary linkage records to link the metadata to their corresponding records, returning errors/success
     * @param body 
     */
    apiV1CSVUploadFullMetadataPost(params: {  "body"?: MetadataUploadVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/UploadFullMetadata`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates results metadata.
     * @param body 
     */
    apiV1CSVUploadLabAssetsPost(params: {  "body"?: LabAssetUploadVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/UploadLabAssets`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Uploads a csv of metadata entries
     * @param templateId 
     * @param body 
     */
    apiV1CSVUploadMetadataPost(params: {  "templateId"?: string; "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/UploadMetadata`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "templateId": params["templateId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Uploads a csv of samples.
     * @param body 
     */
    apiV1CSVUploadSamplesPost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CSV/UploadSamples`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CSVApi - functional programming interface
 */
export const CSVApiFp = {
    /** 
     * Returns CSV template
     */
    apiV1CSVMetadataTemplatesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MetadataTemplateVM>> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVMetadataTemplatesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Parse a csv of barcode reagents for a plate.
     * @param body 
     */
    apiV1CSVParseCSVFlurometerOutputPost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CSVQubitFlurometerVM>> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVParseCSVFlurometerOutputPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns CSV template
     */
    apiV1CSVSampleMetadataGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SampleWithMetadataVM>> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVSampleMetadataGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates results metadata.
     * @param body 
     */
    apiV1CSVUpdateResultsMetadataPost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadFileResponse> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVUpdateResultsMetadataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Parse a csv of barcode reagents for a plate.
     * @param barcodeKitType 
     * @param body 
     */
    apiV1CSVUploadBarcodesPost(params: { "barcodeKitType"?: string; "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CSVBarcodeVM>> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVUploadBarcodesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Uploads metadata to the system and the necessary linkage records to link the metadata to their corresponding records, returning errors/success
     * @param body 
     */
    apiV1CSVUploadFullMetadataPost(params: { "body"?: MetadataUploadVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadMetadataResponse> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVUploadFullMetadataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates results metadata.
     * @param body 
     */
    apiV1CSVUploadLabAssetsPost(params: { "body"?: LabAssetUploadVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadFileResponse> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVUploadLabAssetsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Uploads a csv of metadata entries
     * @param templateId 
     * @param body 
     */
    apiV1CSVUploadMetadataPost(params: { "templateId"?: string; "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadFileResponse> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVUploadMetadataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Uploads a csv of samples.
     * @param body 
     */
    apiV1CSVUploadSamplesPost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadFileResponse> {
        const fetchArgs = CSVApiFetchParamCreator.apiV1CSVUploadSamplesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CSVApi - object-oriented interface
 */
export class CSVApi extends BaseAPI {
    /** 
     * Returns CSV template
     */
    apiV1CSVMetadataTemplatesGet(options?: any) {
        return CSVApiFp.apiV1CSVMetadataTemplatesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Parse a csv of barcode reagents for a plate.
     * @param body 
     */
    apiV1CSVParseCSVFlurometerOutputPost(params: {  "body"?: string; }, options?: any) {
        return CSVApiFp.apiV1CSVParseCSVFlurometerOutputPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns CSV template
     */
    apiV1CSVSampleMetadataGet(options?: any) {
        return CSVApiFp.apiV1CSVSampleMetadataGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Updates results metadata.
     * @param body 
     */
    apiV1CSVUpdateResultsMetadataPost(params: {  "body"?: string; }, options?: any) {
        return CSVApiFp.apiV1CSVUpdateResultsMetadataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Parse a csv of barcode reagents for a plate.
     * @param barcodeKitType 
     * @param body 
     */
    apiV1CSVUploadBarcodesPost(params: {  "barcodeKitType"?: string; "body"?: string; }, options?: any) {
        return CSVApiFp.apiV1CSVUploadBarcodesPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Uploads metadata to the system and the necessary linkage records to link the metadata to their corresponding records, returning errors/success
     * @param body 
     */
    apiV1CSVUploadFullMetadataPost(params: {  "body"?: MetadataUploadVM; }, options?: any) {
        return CSVApiFp.apiV1CSVUploadFullMetadataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates results metadata.
     * @param body 
     */
    apiV1CSVUploadLabAssetsPost(params: {  "body"?: LabAssetUploadVM; }, options?: any) {
        return CSVApiFp.apiV1CSVUploadLabAssetsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Uploads a csv of metadata entries
     * @param templateId 
     * @param body 
     */
    apiV1CSVUploadMetadataPost(params: {  "templateId"?: string; "body"?: string; }, options?: any) {
        return CSVApiFp.apiV1CSVUploadMetadataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Uploads a csv of samples.
     * @param body 
     */
    apiV1CSVUploadSamplesPost(params: {  "body"?: string; }, options?: any) {
        return CSVApiFp.apiV1CSVUploadSamplesPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CSVApi - factory interface
 */
export const CSVApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Returns CSV template
         */
        apiV1CSVMetadataTemplatesGet(options?: any) {
            return CSVApiFp.apiV1CSVMetadataTemplatesGet(options)(fetch, basePath);
        },
        /** 
         * Parse a csv of barcode reagents for a plate.
         * @param body 
         */
        apiV1CSVParseCSVFlurometerOutputPost(params: {  "body"?: string; }, options?: any) {
            return CSVApiFp.apiV1CSVParseCSVFlurometerOutputPost(params, options)(fetch, basePath);
        },
        /** 
         * Returns CSV template
         */
        apiV1CSVSampleMetadataGet(options?: any) {
            return CSVApiFp.apiV1CSVSampleMetadataGet(options)(fetch, basePath);
        },
        /** 
         * Updates results metadata.
         * @param body 
         */
        apiV1CSVUpdateResultsMetadataPost(params: {  "body"?: string; }, options?: any) {
            return CSVApiFp.apiV1CSVUpdateResultsMetadataPost(params, options)(fetch, basePath);
        },
        /** 
         * Parse a csv of barcode reagents for a plate.
         * @param barcodeKitType 
         * @param body 
         */
        apiV1CSVUploadBarcodesPost(params: {  "barcodeKitType"?: string; "body"?: string; }, options?: any) {
            return CSVApiFp.apiV1CSVUploadBarcodesPost(params, options)(fetch, basePath);
        },
        /** 
         * Uploads metadata to the system and the necessary linkage records to link the metadata to their corresponding records, returning errors/success
         * @param body 
         */
        apiV1CSVUploadFullMetadataPost(params: {  "body"?: MetadataUploadVM; }, options?: any) {
            return CSVApiFp.apiV1CSVUploadFullMetadataPost(params, options)(fetch, basePath);
        },
        /** 
         * Updates results metadata.
         * @param body 
         */
        apiV1CSVUploadLabAssetsPost(params: {  "body"?: LabAssetUploadVM; }, options?: any) {
            return CSVApiFp.apiV1CSVUploadLabAssetsPost(params, options)(fetch, basePath);
        },
        /** 
         * Uploads a csv of metadata entries
         * @param templateId 
         * @param body 
         */
        apiV1CSVUploadMetadataPost(params: {  "templateId"?: string; "body"?: string; }, options?: any) {
            return CSVApiFp.apiV1CSVUploadMetadataPost(params, options)(fetch, basePath);
        },
        /** 
         * Uploads a csv of samples.
         * @param body 
         */
        apiV1CSVUploadSamplesPost(params: {  "body"?: string; }, options?: any) {
            return CSVApiFp.apiV1CSVUploadSamplesPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * CalculationsApi - fetch parameter creator
 */
export const CalculationsApiFetchParamCreator = {
    /** 
     * API entry point to calculation framework. Calculations will be registered currently from this assembly.  In the future this will be configurable which assembly to register from.  The CalculationRequestVM will be passed to the each registered CanPerformCalculation method.  The first calculator to return true will perform the calculation.
     * @param body The required parameters for performing calculations              Calculation Type: Determines which calculation will be run, based on the results of CanPerformCalculation              Data Dictionary: What the calculations are going to be performed upon              Args Dictionary: Any additional required arguments
     */
    apiV1CalculationsPerformCalculationPost(params: {  "body"?: CalculationRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Calculations/PerformCalculation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CalculationsApi - functional programming interface
 */
export const CalculationsApiFp = {
    /** 
     * API entry point to calculation framework. Calculations will be registered currently from this assembly.  In the future this will be configurable which assembly to register from.  The CalculationRequestVM will be passed to the each registered CanPerformCalculation method.  The first calculator to return true will perform the calculation.
     * @param body The required parameters for performing calculations              Calculation Type: Determines which calculation will be run, based on the results of CanPerformCalculation              Data Dictionary: What the calculations are going to be performed upon              Args Dictionary: Any additional required arguments
     */
    apiV1CalculationsPerformCalculationPost(params: { "body"?: CalculationRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CalculationResponseVM> {
        const fetchArgs = CalculationsApiFetchParamCreator.apiV1CalculationsPerformCalculationPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CalculationsApi - object-oriented interface
 */
export class CalculationsApi extends BaseAPI {
    /** 
     * API entry point to calculation framework. Calculations will be registered currently from this assembly.  In the future this will be configurable which assembly to register from.  The CalculationRequestVM will be passed to the each registered CanPerformCalculation method.  The first calculator to return true will perform the calculation.
     * @param body The required parameters for performing calculations              Calculation Type: Determines which calculation will be run, based on the results of CanPerformCalculation              Data Dictionary: What the calculations are going to be performed upon              Args Dictionary: Any additional required arguments
     */
    apiV1CalculationsPerformCalculationPost(params: {  "body"?: CalculationRequestVM; }, options?: any) {
        return CalculationsApiFp.apiV1CalculationsPerformCalculationPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CalculationsApi - factory interface
 */
export const CalculationsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * API entry point to calculation framework. Calculations will be registered currently from this assembly.  In the future this will be configurable which assembly to register from.  The CalculationRequestVM will be passed to the each registered CanPerformCalculation method.  The first calculator to return true will perform the calculation.
         * @param body The required parameters for performing calculations              Calculation Type: Determines which calculation will be run, based on the results of CanPerformCalculation              Data Dictionary: What the calculations are going to be performed upon              Args Dictionary: Any additional required arguments
         */
        apiV1CalculationsPerformCalculationPost(params: {  "body"?: CalculationRequestVM; }, options?: any) {
            return CalculationsApiFp.apiV1CalculationsPerformCalculationPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * DashboardApi - fetch parameter creator
 */
export const DashboardApiFetchParamCreator = {
    /** 
     */
    apiV1DashboardProductivitybenchmarkGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Dashboard/productivitybenchmark`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get Test Order Status information
     */
    apiV1DashboardStatusboardGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Dashboard/statusboard`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1DashboardTodaysworkloadGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Dashboard/todaysworkload`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1DashboardYearToDateInfoGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Dashboard/yearToDateInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DashboardApi - functional programming interface
 */
export const DashboardApiFp = {
    /** 
     */
    apiV1DashboardProductivitybenchmarkGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GetProductivityBenchmarkResponseDay>> {
        const fetchArgs = DashboardApiFetchParamCreator.apiV1DashboardProductivitybenchmarkGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get Test Order Status information
     */
    apiV1DashboardStatusboardGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetTestOrderStatusBoardResponse> {
        const fetchArgs = DashboardApiFetchParamCreator.apiV1DashboardStatusboardGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1DashboardTodaysworkloadGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetTodaysWorkloadResponse> {
        const fetchArgs = DashboardApiFetchParamCreator.apiV1DashboardTodaysworkloadGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1DashboardYearToDateInfoGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetYearToDateInfoResponse> {
        const fetchArgs = DashboardApiFetchParamCreator.apiV1DashboardYearToDateInfoGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DashboardApi - object-oriented interface
 */
export class DashboardApi extends BaseAPI {
    /** 
     */
    apiV1DashboardProductivitybenchmarkGet(options?: any) {
        return DashboardApiFp.apiV1DashboardProductivitybenchmarkGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get Test Order Status information
     */
    apiV1DashboardStatusboardGet(options?: any) {
        return DashboardApiFp.apiV1DashboardStatusboardGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1DashboardTodaysworkloadGet(options?: any) {
        return DashboardApiFp.apiV1DashboardTodaysworkloadGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1DashboardYearToDateInfoGet(options?: any) {
        return DashboardApiFp.apiV1DashboardYearToDateInfoGet(options)(this.fetch, this.basePath);
    }
};

/**
 * DashboardApi - factory interface
 */
export const DashboardApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiV1DashboardProductivitybenchmarkGet(options?: any) {
            return DashboardApiFp.apiV1DashboardProductivitybenchmarkGet(options)(fetch, basePath);
        },
        /** 
         * Get Test Order Status information
         */
        apiV1DashboardStatusboardGet(options?: any) {
            return DashboardApiFp.apiV1DashboardStatusboardGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1DashboardTodaysworkloadGet(options?: any) {
            return DashboardApiFp.apiV1DashboardTodaysworkloadGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1DashboardYearToDateInfoGet(options?: any) {
            return DashboardApiFp.apiV1DashboardYearToDateInfoGet(options)(fetch, basePath);
        },
    };
};


/**
 * DocumentsApi - fetch parameter creator
 */
export const DocumentsApiFetchParamCreator = {
    /** 
     * Takes a csv string and posts it back as a csv file
     * @param body 
     */
    apiV1DocumentsCsvMetadataTemplatePost(params: {  "body"?: Array<MetadataTemplateFileVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Documents/csv_metadata_template`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns a given CSV template
     * @param body 
     */
    apiV1DocumentsCsvTemplatePost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Documents/csv_template`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns a document with a given filename
     * @param fileName 
     */
    apiV1DocumentsDocumentGet(params: {  "fileName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Documents/document`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "fileName": params["fileName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Takes a json string and posts it back as a json file
     * @param body 
     */
    apiV1DocumentsJsonMetadataPost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Documents/json_metadata`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns lab asset template
     */
    apiV1DocumentsLabassetTemplateGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Documents/labasset_template`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns qubit quantification template
     * @param body 
     */
    apiV1DocumentsQubitQuantificationCsvTemplatePost(params: {  "body"?: QubitSamplesVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Documents/qubit_quantification_csv_template`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DocumentsApi - functional programming interface
 */
export const DocumentsApiFp = {
    /** 
     * Takes a csv string and posts it back as a csv file
     * @param body 
     */
    apiV1DocumentsCsvMetadataTemplatePost(params: { "body"?: Array<MetadataTemplateFileVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentsApiFetchParamCreator.apiV1DocumentsCsvMetadataTemplatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns a given CSV template
     * @param body 
     */
    apiV1DocumentsCsvTemplatePost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentsApiFetchParamCreator.apiV1DocumentsCsvTemplatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns a document with a given filename
     * @param fileName 
     */
    apiV1DocumentsDocumentGet(params: { "fileName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentsApiFetchParamCreator.apiV1DocumentsDocumentGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Takes a json string and posts it back as a json file
     * @param body 
     */
    apiV1DocumentsJsonMetadataPost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentsApiFetchParamCreator.apiV1DocumentsJsonMetadataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns lab asset template
     */
    apiV1DocumentsLabassetTemplateGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentsApiFetchParamCreator.apiV1DocumentsLabassetTemplateGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns qubit quantification template
     * @param body 
     */
    apiV1DocumentsQubitQuantificationCsvTemplatePost(params: { "body"?: QubitSamplesVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentsApiFetchParamCreator.apiV1DocumentsQubitQuantificationCsvTemplatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DocumentsApi - object-oriented interface
 */
export class DocumentsApi extends BaseAPI {
    /** 
     * Takes a csv string and posts it back as a csv file
     * @param body 
     */
    apiV1DocumentsCsvMetadataTemplatePost(params: {  "body"?: Array<MetadataTemplateFileVM>; }, options?: any) {
        return DocumentsApiFp.apiV1DocumentsCsvMetadataTemplatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns a given CSV template
     * @param body 
     */
    apiV1DocumentsCsvTemplatePost(params: {  "body"?: string; }, options?: any) {
        return DocumentsApiFp.apiV1DocumentsCsvTemplatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns a document with a given filename
     * @param fileName 
     */
    apiV1DocumentsDocumentGet(params: {  "fileName"?: string; }, options?: any) {
        return DocumentsApiFp.apiV1DocumentsDocumentGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Takes a json string and posts it back as a json file
     * @param body 
     */
    apiV1DocumentsJsonMetadataPost(params: {  "body"?: string; }, options?: any) {
        return DocumentsApiFp.apiV1DocumentsJsonMetadataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns lab asset template
     */
    apiV1DocumentsLabassetTemplateGet(options?: any) {
        return DocumentsApiFp.apiV1DocumentsLabassetTemplateGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Returns qubit quantification template
     * @param body 
     */
    apiV1DocumentsQubitQuantificationCsvTemplatePost(params: {  "body"?: QubitSamplesVM; }, options?: any) {
        return DocumentsApiFp.apiV1DocumentsQubitQuantificationCsvTemplatePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DocumentsApi - factory interface
 */
export const DocumentsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Takes a csv string and posts it back as a csv file
         * @param body 
         */
        apiV1DocumentsCsvMetadataTemplatePost(params: {  "body"?: Array<MetadataTemplateFileVM>; }, options?: any) {
            return DocumentsApiFp.apiV1DocumentsCsvMetadataTemplatePost(params, options)(fetch, basePath);
        },
        /** 
         * Returns a given CSV template
         * @param body 
         */
        apiV1DocumentsCsvTemplatePost(params: {  "body"?: string; }, options?: any) {
            return DocumentsApiFp.apiV1DocumentsCsvTemplatePost(params, options)(fetch, basePath);
        },
        /** 
         * Returns a document with a given filename
         * @param fileName 
         */
        apiV1DocumentsDocumentGet(params: {  "fileName"?: string; }, options?: any) {
            return DocumentsApiFp.apiV1DocumentsDocumentGet(params, options)(fetch, basePath);
        },
        /** 
         * Takes a json string and posts it back as a json file
         * @param body 
         */
        apiV1DocumentsJsonMetadataPost(params: {  "body"?: string; }, options?: any) {
            return DocumentsApiFp.apiV1DocumentsJsonMetadataPost(params, options)(fetch, basePath);
        },
        /** 
         * Returns lab asset template
         */
        apiV1DocumentsLabassetTemplateGet(options?: any) {
            return DocumentsApiFp.apiV1DocumentsLabassetTemplateGet(options)(fetch, basePath);
        },
        /** 
         * Returns qubit quantification template
         * @param body 
         */
        apiV1DocumentsQubitQuantificationCsvTemplatePost(params: {  "body"?: QubitSamplesVM; }, options?: any) {
            return DocumentsApiFp.apiV1DocumentsQubitQuantificationCsvTemplatePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * FileShareApi - fetch parameter creator
 */
export const FileShareApiFetchParamCreator = {
    /** 
     * @param guid 
     */
    apiV1FileShareAbortUploadGuidPut(params: {  "guid": string; }, options?: any): FetchArgs {
        // verify required parameter "guid" is set
        if (params["guid"] == null) {
            throw new Error("Missing required parameter guid when calling apiV1FileShareAbortUploadGuidPut");
        }
        const baseUrl = `/api/v1/FileShare/AbortUpload/{guid}`
            .replace(`{${"guid"}}`, `${ params["guid"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a file or directory at a given path
     * @param path 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param lastModified 
     * @param type 
     */
    apiV1FileShareCreateFileOrDirectoryPathPut(params: {  "path": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; "lastModified"?: string; "type"?: string; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareCreateFileOrDirectoryPathPut");
        }
        const baseUrl = `/api/v1/FileShare/CreateFileOrDirectory/{path}`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/x-www-form-urlencoded" };
        fetchOptions.body = querystring.stringify({
            "ContentType": params["contentType"],
            "ContentDisposition": params["contentDisposition"],
            "Headers": params["headers"],
            "Length": params["length"],
            "Name": params["name"],
            "FileName": params["fileName"],
            "LastModified": params["lastModified"],
            "Type": params["type"],
        });
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a file or directory at a given path
     * @param path 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param lastModified 
     * @param type 
     */
    apiV1FileShareCreateFileOrDirectoryPut(params: {  "path": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; "lastModified"?: string; "type"?: string; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareCreateFileOrDirectoryPut");
        }
        const baseUrl = `/api/v1/FileShare/CreateFileOrDirectory`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/x-www-form-urlencoded" };
        fetchOptions.body = querystring.stringify({
            "ContentType": params["contentType"],
            "ContentDisposition": params["contentDisposition"],
            "Headers": params["headers"],
            "Length": params["length"],
            "Name": params["name"],
            "FileName": params["fileName"],
            "LastModified": params["lastModified"],
            "Type": params["type"],
        });
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Deletes a file or directory at a given path
     * @param path 
     */
    apiV1FileShareDeleteFileOrDirectoryPathDelete(params: {  "path": string; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareDeleteFileOrDirectoryPathDelete");
        }
        const baseUrl = `/api/v1/FileShare/DeleteFileOrDirectory/{path}`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Loads a file stream for a file at a given path
     * @param path 
     */
    apiV1FileShareDownloadFilePathGet(params: {  "path": string; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareDownloadFilePathGet");
        }
        const baseUrl = `/api/v1/FileShare/DownloadFile/{path}`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns list of directories at a given path
     * @param path 
     */
    apiV1FileShareGetDirectoriesPathGet(params: {  "path": string; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareGetDirectoriesPathGet");
        }
        const baseUrl = `/api/v1/FileShare/GetDirectories/{path}`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns list of files at a given path
     * @param path 
     */
    apiV1FileShareGetFilesPathGet(params: {  "path": string; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareGetFilesPathGet");
        }
        const baseUrl = `/api/v1/FileShare/GetFiles/{path}`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns list of directories at root
     */
    apiV1FileShareGetRootDirectoriesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/FileShare/GetRootDirectories`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns all directories and files at the root of the filesystem
     */
    apiV1FileShareGetRootFilesAndDirectoriesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/FileShare/GetRootFilesAndDirectories`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns list of files at a given path
     */
    apiV1FileShareGetRootFilesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/FileShare/GetRootFiles`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareInitializeChunkedUploadPathPost(params: {  "path": string; "body"?: InitializeChunkedUploadVM; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareInitializeChunkedUploadPathPost");
        }
        const baseUrl = `/api/v1/FileShare/InitializeChunkedUpload/{path}`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareSharePathDelete(params: {  "path": string; "body"?: ShareFilesystemRequestDto; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareSharePathDelete");
        }
        const baseUrl = `/api/v1/FileShare/Share/{path}`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareSharePathPut(params: {  "path": string; "body"?: ShareFilesystemRequestDto; }, options?: any): FetchArgs {
        // verify required parameter "path" is set
        if (params["path"] == null) {
            throw new Error("Missing required parameter path when calling apiV1FileShareSharePathPut");
        }
        const baseUrl = `/api/v1/FileShare/Share/{path}`
            .replace(`{${"path"}}`, `${ params["path"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param guid 
     * @param chunk 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     */
    apiV1FileShareUploadChunkGuidChunkPut(params: {  "guid": string; "chunk": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; }, options?: any): FetchArgs {
        // verify required parameter "guid" is set
        if (params["guid"] == null) {
            throw new Error("Missing required parameter guid when calling apiV1FileShareUploadChunkGuidChunkPut");
        }
        // verify required parameter "chunk" is set
        if (params["chunk"] == null) {
            throw new Error("Missing required parameter chunk when calling apiV1FileShareUploadChunkGuidChunkPut");
        }
        const baseUrl = `/api/v1/FileShare/UploadChunk/{guid}/{chunk}`
            .replace(`{${"guid"}}`, `${ params["guid"] }`)
            .replace(`{${"chunk"}}`, `${ params["chunk"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/x-www-form-urlencoded" };
        fetchOptions.body = querystring.stringify({
            "ContentType": params["contentType"],
            "ContentDisposition": params["contentDisposition"],
            "Headers": params["headers"],
            "Length": params["length"],
            "Name": params["name"],
            "FileName": params["fileName"],
        });
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * FileShareApi - functional programming interface
 */
export const FileShareApiFp = {
    /** 
     * @param guid 
     */
    apiV1FileShareAbortUploadGuidPut(params: { "guid": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareAbortUploadGuidPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a file or directory at a given path
     * @param path 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param lastModified 
     * @param type 
     */
    apiV1FileShareCreateFileOrDirectoryPathPut(params: { "path": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; "lastModified"?: string; "type"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareCreateFileOrDirectoryPathPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a file or directory at a given path
     * @param path 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param lastModified 
     * @param type 
     */
    apiV1FileShareCreateFileOrDirectoryPut(params: { "path": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; "lastModified"?: string; "type"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareCreateFileOrDirectoryPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Deletes a file or directory at a given path
     * @param path 
     */
    apiV1FileShareDeleteFileOrDirectoryPathDelete(params: { "path": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareDeleteFileOrDirectoryPathDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Loads a file stream for a file at a given path
     * @param path 
     */
    apiV1FileShareDownloadFilePathGet(params: { "path": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareDownloadFilePathGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns list of directories at a given path
     * @param path 
     */
    apiV1FileShareGetDirectoriesPathGet(params: { "path": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DirectoryListingVM>> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareGetDirectoriesPathGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns list of files at a given path
     * @param path 
     */
    apiV1FileShareGetFilesPathGet(params: { "path": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DirectoryListingVM>> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareGetFilesPathGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns list of directories at root
     */
    apiV1FileShareGetRootDirectoriesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DirectoryListingVM>> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareGetRootDirectoriesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns all directories and files at the root of the filesystem
     */
    apiV1FileShareGetRootFilesAndDirectoriesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FilesAndDirectoriesVM> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareGetRootFilesAndDirectoriesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns list of files at a given path
     */
    apiV1FileShareGetRootFilesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DirectoryListingVM>> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareGetRootFilesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareInitializeChunkedUploadPathPost(params: { "path": string; "body"?: InitializeChunkedUploadVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ChunkedUploadVM> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareInitializeChunkedUploadPathPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareSharePathDelete(params: { "path": string; "body"?: ShareFilesystemRequestDto;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareSharePathDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareSharePathPut(params: { "path": string; "body"?: ShareFilesystemRequestDto;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareSharePathPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param guid 
     * @param chunk 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     */
    apiV1FileShareUploadChunkGuidChunkPut(params: { "guid": string; "chunk": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = FileShareApiFetchParamCreator.apiV1FileShareUploadChunkGuidChunkPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * FileShareApi - object-oriented interface
 */
export class FileShareApi extends BaseAPI {
    /** 
     * @param guid 
     */
    apiV1FileShareAbortUploadGuidPut(params: {  "guid": string; }, options?: any) {
        return FileShareApiFp.apiV1FileShareAbortUploadGuidPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a file or directory at a given path
     * @param path 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param lastModified 
     * @param type 
     */
    apiV1FileShareCreateFileOrDirectoryPathPut(params: {  "path": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; "lastModified"?: string; "type"?: string; }, options?: any) {
        return FileShareApiFp.apiV1FileShareCreateFileOrDirectoryPathPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a file or directory at a given path
     * @param path 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     * @param lastModified 
     * @param type 
     */
    apiV1FileShareCreateFileOrDirectoryPut(params: {  "path": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; "lastModified"?: string; "type"?: string; }, options?: any) {
        return FileShareApiFp.apiV1FileShareCreateFileOrDirectoryPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Deletes a file or directory at a given path
     * @param path 
     */
    apiV1FileShareDeleteFileOrDirectoryPathDelete(params: {  "path": string; }, options?: any) {
        return FileShareApiFp.apiV1FileShareDeleteFileOrDirectoryPathDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Loads a file stream for a file at a given path
     * @param path 
     */
    apiV1FileShareDownloadFilePathGet(params: {  "path": string; }, options?: any) {
        return FileShareApiFp.apiV1FileShareDownloadFilePathGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns list of directories at a given path
     * @param path 
     */
    apiV1FileShareGetDirectoriesPathGet(params: {  "path": string; }, options?: any) {
        return FileShareApiFp.apiV1FileShareGetDirectoriesPathGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns list of files at a given path
     * @param path 
     */
    apiV1FileShareGetFilesPathGet(params: {  "path": string; }, options?: any) {
        return FileShareApiFp.apiV1FileShareGetFilesPathGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns list of directories at root
     */
    apiV1FileShareGetRootDirectoriesGet(options?: any) {
        return FileShareApiFp.apiV1FileShareGetRootDirectoriesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Returns all directories and files at the root of the filesystem
     */
    apiV1FileShareGetRootFilesAndDirectoriesGet(options?: any) {
        return FileShareApiFp.apiV1FileShareGetRootFilesAndDirectoriesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Returns list of files at a given path
     */
    apiV1FileShareGetRootFilesGet(options?: any) {
        return FileShareApiFp.apiV1FileShareGetRootFilesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareInitializeChunkedUploadPathPost(params: {  "path": string; "body"?: InitializeChunkedUploadVM; }, options?: any) {
        return FileShareApiFp.apiV1FileShareInitializeChunkedUploadPathPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareSharePathDelete(params: {  "path": string; "body"?: ShareFilesystemRequestDto; }, options?: any) {
        return FileShareApiFp.apiV1FileShareSharePathDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param path 
     * @param body 
     */
    apiV1FileShareSharePathPut(params: {  "path": string; "body"?: ShareFilesystemRequestDto; }, options?: any) {
        return FileShareApiFp.apiV1FileShareSharePathPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param guid 
     * @param chunk 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     */
    apiV1FileShareUploadChunkGuidChunkPut(params: {  "guid": string; "chunk": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; }, options?: any) {
        return FileShareApiFp.apiV1FileShareUploadChunkGuidChunkPut(params, options)(this.fetch, this.basePath);
    }
};

/**
 * FileShareApi - factory interface
 */
export const FileShareApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param guid 
         */
        apiV1FileShareAbortUploadGuidPut(params: {  "guid": string; }, options?: any) {
            return FileShareApiFp.apiV1FileShareAbortUploadGuidPut(params, options)(fetch, basePath);
        },
        /** 
         * Creates a file or directory at a given path
         * @param path 
         * @param contentType 
         * @param contentDisposition 
         * @param headers 
         * @param length 
         * @param name 
         * @param fileName 
         * @param lastModified 
         * @param type 
         */
        apiV1FileShareCreateFileOrDirectoryPathPut(params: {  "path": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; "lastModified"?: string; "type"?: string; }, options?: any) {
            return FileShareApiFp.apiV1FileShareCreateFileOrDirectoryPathPut(params, options)(fetch, basePath);
        },
        /** 
         * Creates a file or directory at a given path
         * @param path 
         * @param contentType 
         * @param contentDisposition 
         * @param headers 
         * @param length 
         * @param name 
         * @param fileName 
         * @param lastModified 
         * @param type 
         */
        apiV1FileShareCreateFileOrDirectoryPut(params: {  "path": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; "lastModified"?: string; "type"?: string; }, options?: any) {
            return FileShareApiFp.apiV1FileShareCreateFileOrDirectoryPut(params, options)(fetch, basePath);
        },
        /** 
         * Deletes a file or directory at a given path
         * @param path 
         */
        apiV1FileShareDeleteFileOrDirectoryPathDelete(params: {  "path": string; }, options?: any) {
            return FileShareApiFp.apiV1FileShareDeleteFileOrDirectoryPathDelete(params, options)(fetch, basePath);
        },
        /** 
         * Loads a file stream for a file at a given path
         * @param path 
         */
        apiV1FileShareDownloadFilePathGet(params: {  "path": string; }, options?: any) {
            return FileShareApiFp.apiV1FileShareDownloadFilePathGet(params, options)(fetch, basePath);
        },
        /** 
         * Returns list of directories at a given path
         * @param path 
         */
        apiV1FileShareGetDirectoriesPathGet(params: {  "path": string; }, options?: any) {
            return FileShareApiFp.apiV1FileShareGetDirectoriesPathGet(params, options)(fetch, basePath);
        },
        /** 
         * Returns list of files at a given path
         * @param path 
         */
        apiV1FileShareGetFilesPathGet(params: {  "path": string; }, options?: any) {
            return FileShareApiFp.apiV1FileShareGetFilesPathGet(params, options)(fetch, basePath);
        },
        /** 
         * Returns list of directories at root
         */
        apiV1FileShareGetRootDirectoriesGet(options?: any) {
            return FileShareApiFp.apiV1FileShareGetRootDirectoriesGet(options)(fetch, basePath);
        },
        /** 
         * Returns all directories and files at the root of the filesystem
         */
        apiV1FileShareGetRootFilesAndDirectoriesGet(options?: any) {
            return FileShareApiFp.apiV1FileShareGetRootFilesAndDirectoriesGet(options)(fetch, basePath);
        },
        /** 
         * Returns list of files at a given path
         */
        apiV1FileShareGetRootFilesGet(options?: any) {
            return FileShareApiFp.apiV1FileShareGetRootFilesGet(options)(fetch, basePath);
        },
        /** 
         * @param path 
         * @param body 
         */
        apiV1FileShareInitializeChunkedUploadPathPost(params: {  "path": string; "body"?: InitializeChunkedUploadVM; }, options?: any) {
            return FileShareApiFp.apiV1FileShareInitializeChunkedUploadPathPost(params, options)(fetch, basePath);
        },
        /** 
         * @param path 
         * @param body 
         */
        apiV1FileShareSharePathDelete(params: {  "path": string; "body"?: ShareFilesystemRequestDto; }, options?: any) {
            return FileShareApiFp.apiV1FileShareSharePathDelete(params, options)(fetch, basePath);
        },
        /** 
         * @param path 
         * @param body 
         */
        apiV1FileShareSharePathPut(params: {  "path": string; "body"?: ShareFilesystemRequestDto; }, options?: any) {
            return FileShareApiFp.apiV1FileShareSharePathPut(params, options)(fetch, basePath);
        },
        /** 
         * @param guid 
         * @param chunk 
         * @param contentType 
         * @param contentDisposition 
         * @param headers 
         * @param length 
         * @param name 
         * @param fileName 
         */
        apiV1FileShareUploadChunkGuidChunkPut(params: {  "guid": string; "chunk": string; "contentType"?: string; "contentDisposition"?: string; "headers"?: string; "length"?: number; "name"?: string; "fileName"?: string; }, options?: any) {
            return FileShareApiFp.apiV1FileShareUploadChunkGuidChunkPut(params, options)(fetch, basePath);
        },
    };
};


/**
 * HealthApi - fetch parameter creator
 */
export const HealthApiFetchParamCreator = {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthPortalGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/health/portal`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HealthApi - functional programming interface
 */
export const HealthApiFp = {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthPortalGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HealthCheckResponse> {
        const fetchArgs = HealthApiFetchParamCreator.apiV1HealthPortalGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HealthApi - object-oriented interface
 */
export class HealthApi extends BaseAPI {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthPortalGet(options?: any) {
        return HealthApiFp.apiV1HealthPortalGet(options)(this.fetch, this.basePath);
    }
};

/**
 * HealthApi - factory interface
 */
export const HealthApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Create an empty response to allow external actors to verify if application is online
         */
        apiV1HealthPortalGet(options?: any) {
            return HealthApiFp.apiV1HealthPortalGet(options)(fetch, basePath);
        },
    };
};


/**
 * MetadataApi - fetch parameter creator
 */
export const MetadataApiFetchParamCreator = {
    /** 
     * @param run 
     */
    apiV1MetadataGeneratereportPost(params: {  "run"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Metadata/generatereport`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "run": params["run"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1MetadataPostresultsSarscov2Post(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Metadata/postresults/sarscov2`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1MetadataReleaserunPost(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Metadata/releaserun`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1MetadataSamplesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Metadata/samples`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * MetadataApi - functional programming interface
 */
export const MetadataApiFp = {
    /** 
     * @param run 
     */
    apiV1MetadataGeneratereportPost(params: { "run"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = MetadataApiFetchParamCreator.apiV1MetadataGeneratereportPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1MetadataPostresultsSarscov2Post(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MetadataApiFetchParamCreator.apiV1MetadataPostresultsSarscov2Post(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1MetadataReleaserunPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MetadataApiFetchParamCreator.apiV1MetadataReleaserunPost(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1MetadataSamplesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LimsSample>> {
        const fetchArgs = MetadataApiFetchParamCreator.apiV1MetadataSamplesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * MetadataApi - object-oriented interface
 */
export class MetadataApi extends BaseAPI {
    /** 
     * @param run 
     */
    apiV1MetadataGeneratereportPost(params: {  "run"?: string; }, options?: any) {
        return MetadataApiFp.apiV1MetadataGeneratereportPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1MetadataPostresultsSarscov2Post(options?: any) {
        return MetadataApiFp.apiV1MetadataPostresultsSarscov2Post(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1MetadataReleaserunPost(options?: any) {
        return MetadataApiFp.apiV1MetadataReleaserunPost(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1MetadataSamplesGet(options?: any) {
        return MetadataApiFp.apiV1MetadataSamplesGet(options)(this.fetch, this.basePath);
    }
};

/**
 * MetadataApi - factory interface
 */
export const MetadataApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param run 
         */
        apiV1MetadataGeneratereportPost(params: {  "run"?: string; }, options?: any) {
            return MetadataApiFp.apiV1MetadataGeneratereportPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1MetadataPostresultsSarscov2Post(options?: any) {
            return MetadataApiFp.apiV1MetadataPostresultsSarscov2Post(options)(fetch, basePath);
        },
        /** 
         */
        apiV1MetadataReleaserunPost(options?: any) {
            return MetadataApiFp.apiV1MetadataReleaserunPost(options)(fetch, basePath);
        },
        /** 
         */
        apiV1MetadataSamplesGet(options?: any) {
            return MetadataApiFp.apiV1MetadataSamplesGet(options)(fetch, basePath);
        },
    };
};


/**
 * MinKnowApi - fetch parameter creator
 */
export const MinKnowApiFetchParamCreator = {
    /** 
     * Gets the connection status
     */
    apiV1MinKnowConnectionInformationGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinKnow/ConnectionInformation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * MinKnowApi - functional programming interface
 */
export const MinKnowApiFp = {
    /** 
     * Gets the connection status
     */
    apiV1MinKnowConnectionInformationGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MinKnowConnectionInformationResponse> {
        const fetchArgs = MinKnowApiFetchParamCreator.apiV1MinKnowConnectionInformationGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * MinKnowApi - object-oriented interface
 */
export class MinKnowApi extends BaseAPI {
    /** 
     * Gets the connection status
     */
    apiV1MinKnowConnectionInformationGet(options?: any) {
        return MinKnowApiFp.apiV1MinKnowConnectionInformationGet(options)(this.fetch, this.basePath);
    }
};

/**
 * MinKnowApi - factory interface
 */
export const MinKnowApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets the connection status
         */
        apiV1MinKnowConnectionInformationGet(options?: any) {
            return MinKnowApiFp.apiV1MinKnowConnectionInformationGet(options)(fetch, basePath);
        },
    };
};


/**
 * MinitMinderApi - fetch parameter creator
 */
export const MinitMinderApiFetchParamCreator = {
    /** 
     * Get directories from minder
     */
    apiV1MinitMinderDirectoryGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/Directory`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Starts a job by copying files from selected directory
     * @param runId 
     * @param directory 
     */
    apiV1MinitMinderDirectoryRunIdPost(params: {  "runId": string; "directory"?: string; }, options?: any): FetchArgs {
        // verify required parameter "runId" is set
        if (params["runId"] == null) {
            throw new Error("Missing required parameter runId when calling apiV1MinitMinderDirectoryRunIdPost");
        }
        const baseUrl = `/api/v1/MinitMinder/Directory/{runId}`
            .replace(`{${"runId"}}`, `${ params["runId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "directory": params["directory"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets fileshare status for minder
     */
    apiV1MinitMinderFileShareGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/FileShare`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Starts a job by copying files from selected directory
     * @param runId 
     * @param directory 
     */
    apiV1MinitMinderFileShareRunIdPost(params: {  "runId": string; "directory"?: string; }, options?: any): FetchArgs {
        // verify required parameter "runId" is set
        if (params["runId"] == null) {
            throw new Error("Missing required parameter runId when calling apiV1MinitMinderFileShareRunIdPost");
        }
        const baseUrl = `/api/v1/MinitMinder/FileShare/{runId}`
            .replace(`{${"runId"}}`, `${ params["runId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "directory": params["directory"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Finishes a job
     * @param runTag 
     */
    apiV1MinitMinderFinishJobPost(params: {  "runTag"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/FinishJob`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "runTag": params["runTag"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get the Minder Job statuses
     */
    apiV1MinitMinderJobStatusGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/JobStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get module names and versions
     */
    apiV1MinitMinderModulesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/Modules`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get Simplified Minder Job
     * @param runNumber 
     */
    apiV1MinitMinderSimplifiedMinderRunGet(params: {  "runNumber"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/SimplifiedMinderRun`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "runNumber": params["runNumber"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all Simplified Minder Jobs
     */
    apiV1MinitMinderSimplifiedMinderRunsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/SimplifiedMinderRuns`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get the Simulator Status
     */
    apiV1MinitMinderSimulatorStatusGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/SimulatorStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Starts a job
     * @param runTag 
     */
    apiV1MinitMinderStartJobPut(params: {  "runTag"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/StartJob`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "runTag": params["runTag"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param towerToken 
     * @param s3Buckets 
     * @param runNumber 
     */
    apiV1MinitMinderStartRunPost(params: {  "towerToken"?: string; "s3Buckets"?: string; "runNumber"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/StartRun`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "towerToken": params["towerToken"],
            "s3Buckets": params["s3Buckets"],
            "runNumber": params["runNumber"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Start a simulator run
     * @param body 
     */
    apiV1MinitMinderStartSimulatorPut(params: {  "body"?: SimulatorStartVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/StartSimulator`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Stops a job
     * @param runTag 
     */
    apiV1MinitMinderStopJobPost(params: {  "runTag"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/StopJob`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "runTag": params["runTag"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Stops the simulator
     */
    apiV1MinitMinderStopSimulatorPost(options?: any): FetchArgs {
        const baseUrl = `/api/v1/MinitMinder/StopSimulator`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * MinitMinderApi - functional programming interface
 */
export const MinitMinderApiFp = {
    /** 
     * Get directories from minder
     */
    apiV1MinitMinderDirectoryGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderDirectoryGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Starts a job by copying files from selected directory
     * @param runId 
     * @param directory 
     */
    apiV1MinitMinderDirectoryRunIdPost(params: { "runId": string; "directory"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderDirectoryRunIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets fileshare status for minder
     */
    apiV1MinitMinderFileShareGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderFileShareGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Starts a job by copying files from selected directory
     * @param runId 
     * @param directory 
     */
    apiV1MinitMinderFileShareRunIdPost(params: { "runId": string; "directory"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderFileShareRunIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Finishes a job
     * @param runTag 
     */
    apiV1MinitMinderFinishJobPost(params: { "runTag"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderFinishJobPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get the Minder Job statuses
     */
    apiV1MinitMinderJobStatusGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MinitMinderJobStatusVM>> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderJobStatusGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get module names and versions
     */
    apiV1MinitMinderModulesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ModuleInfoVM>> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderModulesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get Simplified Minder Job
     * @param runNumber 
     */
    apiV1MinitMinderSimplifiedMinderRunGet(params: { "runNumber"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SimplifiedMinderRunStatusVM> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderSimplifiedMinderRunGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all Simplified Minder Jobs
     */
    apiV1MinitMinderSimplifiedMinderRunsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SimplifiedMinderRunStatusVM>> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderSimplifiedMinderRunsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get the Simulator Status
     */
    apiV1MinitMinderSimulatorStatusGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SimulatorStatusVM> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderSimulatorStatusGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Starts a job
     * @param runTag 
     */
    apiV1MinitMinderStartJobPut(params: { "runTag"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderStartJobPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param towerToken 
     * @param s3Buckets 
     * @param runNumber 
     */
    apiV1MinitMinderStartRunPost(params: { "towerToken"?: string; "s3Buckets"?: string; "runNumber"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderStartRunPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Start a simulator run
     * @param body 
     */
    apiV1MinitMinderStartSimulatorPut(params: { "body"?: SimulatorStartVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderStartSimulatorPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Stops a job
     * @param runTag 
     */
    apiV1MinitMinderStopJobPost(params: { "runTag"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderStopJobPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Stops the simulator
     */
    apiV1MinitMinderStopSimulatorPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = MinitMinderApiFetchParamCreator.apiV1MinitMinderStopSimulatorPost(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * MinitMinderApi - object-oriented interface
 */
export class MinitMinderApi extends BaseAPI {
    /** 
     * Get directories from minder
     */
    apiV1MinitMinderDirectoryGet(options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderDirectoryGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Starts a job by copying files from selected directory
     * @param runId 
     * @param directory 
     */
    apiV1MinitMinderDirectoryRunIdPost(params: {  "runId": string; "directory"?: string; }, options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderDirectoryRunIdPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets fileshare status for minder
     */
    apiV1MinitMinderFileShareGet(options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderFileShareGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Starts a job by copying files from selected directory
     * @param runId 
     * @param directory 
     */
    apiV1MinitMinderFileShareRunIdPost(params: {  "runId": string; "directory"?: string; }, options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderFileShareRunIdPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Finishes a job
     * @param runTag 
     */
    apiV1MinitMinderFinishJobPost(params: {  "runTag"?: string; }, options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderFinishJobPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get the Minder Job statuses
     */
    apiV1MinitMinderJobStatusGet(options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderJobStatusGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get module names and versions
     */
    apiV1MinitMinderModulesGet(options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderModulesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get Simplified Minder Job
     * @param runNumber 
     */
    apiV1MinitMinderSimplifiedMinderRunGet(params: {  "runNumber"?: string; }, options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderSimplifiedMinderRunGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all Simplified Minder Jobs
     */
    apiV1MinitMinderSimplifiedMinderRunsGet(options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderSimplifiedMinderRunsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get the Simulator Status
     */
    apiV1MinitMinderSimulatorStatusGet(options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderSimulatorStatusGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Starts a job
     * @param runTag 
     */
    apiV1MinitMinderStartJobPut(params: {  "runTag"?: string; }, options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderStartJobPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param towerToken 
     * @param s3Buckets 
     * @param runNumber 
     */
    apiV1MinitMinderStartRunPost(params: {  "towerToken"?: string; "s3Buckets"?: string; "runNumber"?: string; }, options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderStartRunPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Start a simulator run
     * @param body 
     */
    apiV1MinitMinderStartSimulatorPut(params: {  "body"?: SimulatorStartVM; }, options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderStartSimulatorPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Stops a job
     * @param runTag 
     */
    apiV1MinitMinderStopJobPost(params: {  "runTag"?: string; }, options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderStopJobPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Stops the simulator
     */
    apiV1MinitMinderStopSimulatorPost(options?: any) {
        return MinitMinderApiFp.apiV1MinitMinderStopSimulatorPost(options)(this.fetch, this.basePath);
    }
};

/**
 * MinitMinderApi - factory interface
 */
export const MinitMinderApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get directories from minder
         */
        apiV1MinitMinderDirectoryGet(options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderDirectoryGet(options)(fetch, basePath);
        },
        /** 
         * Starts a job by copying files from selected directory
         * @param runId 
         * @param directory 
         */
        apiV1MinitMinderDirectoryRunIdPost(params: {  "runId": string; "directory"?: string; }, options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderDirectoryRunIdPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets fileshare status for minder
         */
        apiV1MinitMinderFileShareGet(options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderFileShareGet(options)(fetch, basePath);
        },
        /** 
         * Starts a job by copying files from selected directory
         * @param runId 
         * @param directory 
         */
        apiV1MinitMinderFileShareRunIdPost(params: {  "runId": string; "directory"?: string; }, options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderFileShareRunIdPost(params, options)(fetch, basePath);
        },
        /** 
         * Finishes a job
         * @param runTag 
         */
        apiV1MinitMinderFinishJobPost(params: {  "runTag"?: string; }, options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderFinishJobPost(params, options)(fetch, basePath);
        },
        /** 
         * Get the Minder Job statuses
         */
        apiV1MinitMinderJobStatusGet(options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderJobStatusGet(options)(fetch, basePath);
        },
        /** 
         * Get module names and versions
         */
        apiV1MinitMinderModulesGet(options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderModulesGet(options)(fetch, basePath);
        },
        /** 
         * Get Simplified Minder Job
         * @param runNumber 
         */
        apiV1MinitMinderSimplifiedMinderRunGet(params: {  "runNumber"?: string; }, options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderSimplifiedMinderRunGet(params, options)(fetch, basePath);
        },
        /** 
         * Get all Simplified Minder Jobs
         */
        apiV1MinitMinderSimplifiedMinderRunsGet(options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderSimplifiedMinderRunsGet(options)(fetch, basePath);
        },
        /** 
         * Get the Simulator Status
         */
        apiV1MinitMinderSimulatorStatusGet(options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderSimulatorStatusGet(options)(fetch, basePath);
        },
        /** 
         * Starts a job
         * @param runTag 
         */
        apiV1MinitMinderStartJobPut(params: {  "runTag"?: string; }, options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderStartJobPut(params, options)(fetch, basePath);
        },
        /** 
         * @param towerToken 
         * @param s3Buckets 
         * @param runNumber 
         */
        apiV1MinitMinderStartRunPost(params: {  "towerToken"?: string; "s3Buckets"?: string; "runNumber"?: string; }, options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderStartRunPost(params, options)(fetch, basePath);
        },
        /** 
         * Start a simulator run
         * @param body 
         */
        apiV1MinitMinderStartSimulatorPut(params: {  "body"?: SimulatorStartVM; }, options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderStartSimulatorPut(params, options)(fetch, basePath);
        },
        /** 
         * Stops a job
         * @param runTag 
         */
        apiV1MinitMinderStopJobPost(params: {  "runTag"?: string; }, options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderStopJobPost(params, options)(fetch, basePath);
        },
        /** 
         * Stops the simulator
         */
        apiV1MinitMinderStopSimulatorPost(options?: any) {
            return MinitMinderApiFp.apiV1MinitMinderStopSimulatorPost(options)(fetch, basePath);
        },
    };
};


/**
 * NGSQCReportsApi - fetch parameter creator
 */
export const NGSQCReportsApiFetchParamCreator = {
    /** 
     * @param workflowRunId 
     * @param workflowId 
     */
    apiV1NGSQCReportsWorkflowRunIdGet(params: {  "workflowRunId": string; "workflowId"?: string; }, options?: any): FetchArgs {
        // verify required parameter "workflowRunId" is set
        if (params["workflowRunId"] == null) {
            throw new Error("Missing required parameter workflowRunId when calling apiV1NGSQCReportsWorkflowRunIdGet");
        }
        const baseUrl = `/api/v1/NGSQCReports/{workflowRunId}`
            .replace(`{${"workflowRunId"}}`, `${ params["workflowRunId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowId": params["workflowId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * NGSQCReportsApi - functional programming interface
 */
export const NGSQCReportsApiFp = {
    /** 
     * @param workflowRunId 
     * @param workflowId 
     */
    apiV1NGSQCReportsWorkflowRunIdGet(params: { "workflowRunId": string; "workflowId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NGSQCReportsVM> {
        const fetchArgs = NGSQCReportsApiFetchParamCreator.apiV1NGSQCReportsWorkflowRunIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * NGSQCReportsApi - object-oriented interface
 */
export class NGSQCReportsApi extends BaseAPI {
    /** 
     * @param workflowRunId 
     * @param workflowId 
     */
    apiV1NGSQCReportsWorkflowRunIdGet(params: {  "workflowRunId": string; "workflowId"?: string; }, options?: any) {
        return NGSQCReportsApiFp.apiV1NGSQCReportsWorkflowRunIdGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * NGSQCReportsApi - factory interface
 */
export const NGSQCReportsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param workflowRunId 
         * @param workflowId 
         */
        apiV1NGSQCReportsWorkflowRunIdGet(params: {  "workflowRunId": string; "workflowId"?: string; }, options?: any) {
            return NGSQCReportsApiFp.apiV1NGSQCReportsWorkflowRunIdGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * PCRApi - fetch parameter creator
 */
export const PCRApiFetchParamCreator = {
    /** 
     * Method for saving PCR info for a step instance
     * @param stepInstanceId The step instance Id
     * @param body Control info to save
     */
    apiV1PCRUpdatePCRForStepStepInstanceIdPut(params: {  "stepInstanceId": string; "body"?: PCRControlInfoVM; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1PCRUpdatePCRForStepStepInstanceIdPut");
        }
        const baseUrl = `/api/v1/PCR/updatePCRForStep/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PCRApi - functional programming interface
 */
export const PCRApiFp = {
    /** 
     * Method for saving PCR info for a step instance
     * @param stepInstanceId The step instance Id
     * @param body Control info to save
     */
    apiV1PCRUpdatePCRForStepStepInstanceIdPut(params: { "stepInstanceId": string; "body"?: PCRControlInfoVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PCRApiFetchParamCreator.apiV1PCRUpdatePCRForStepStepInstanceIdPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PCRApi - object-oriented interface
 */
export class PCRApi extends BaseAPI {
    /** 
     * Method for saving PCR info for a step instance
     * @param stepInstanceId The step instance Id
     * @param body Control info to save
     */
    apiV1PCRUpdatePCRForStepStepInstanceIdPut(params: {  "stepInstanceId": string; "body"?: PCRControlInfoVM; }, options?: any) {
        return PCRApiFp.apiV1PCRUpdatePCRForStepStepInstanceIdPut(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PCRApi - factory interface
 */
export const PCRApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Method for saving PCR info for a step instance
         * @param stepInstanceId The step instance Id
         * @param body Control info to save
         */
        apiV1PCRUpdatePCRForStepStepInstanceIdPut(params: {  "stepInstanceId": string; "body"?: PCRControlInfoVM; }, options?: any) {
            return PCRApiFp.apiV1PCRUpdatePCRForStepStepInstanceIdPut(params, options)(fetch, basePath);
        },
    };
};


/**
 * PipelineResultsApi - fetch parameter creator
 */
export const PipelineResultsApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1PipelineResultsFailPipelineRunPost(params: {  "body"?: PostPipelineRunFailureRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/FailPipelineRun`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedAnthraxPost(params: {  "body"?: AnthraxPipelineResult; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/pipelinefinished/anthrax`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedInfluenzaPost(params: {  "body"?: InfluenzaPipelineResult; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/pipelinefinished/influenza`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedNbsPost(params: {  "body"?: NBSPipelineResult; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/pipelinefinished/nbs`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedAnthraxPost(params: {  "body"?: PipelineStart; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/pipelinestarted/anthrax`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedInfluenzaPost(params: {  "body"?: PipelineStart; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/pipelinestarted/influenza`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedNbsPost(params: {  "body"?: PipelineStart; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/pipelinestarted/nbs`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedSarscov2Post(params: {  "body"?: PipelineStart; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/pipelinestarted/sarscov2`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelineworkflowupdateSarscov2Post(params: {  "body"?: SarsCOV2PipelineWorkflowResult; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineResults/pipelineworkflowupdate/sarscov2`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PipelineResultsApi - functional programming interface
 */
export const PipelineResultsApiFp = {
    /** 
     * @param body 
     */
    apiV1PipelineResultsFailPipelineRunPost(params: { "body"?: PostPipelineRunFailureRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsFailPipelineRunPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedAnthraxPost(params: { "body"?: AnthraxPipelineResult;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsPipelinefinishedAnthraxPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedInfluenzaPost(params: { "body"?: InfluenzaPipelineResult;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsPipelinefinishedInfluenzaPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedNbsPost(params: { "body"?: NBSPipelineResult;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsPipelinefinishedNbsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedAnthraxPost(params: { "body"?: PipelineStart;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsPipelinestartedAnthraxPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedInfluenzaPost(params: { "body"?: PipelineStart;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsPipelinestartedInfluenzaPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedNbsPost(params: { "body"?: PipelineStart;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsPipelinestartedNbsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedSarscov2Post(params: { "body"?: PipelineStart;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsPipelinestartedSarscov2Post(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelineworkflowupdateSarscov2Post(params: { "body"?: SarsCOV2PipelineWorkflowResult;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PipelineResultsApiFetchParamCreator.apiV1PipelineResultsPipelineworkflowupdateSarscov2Post(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PipelineResultsApi - object-oriented interface
 */
export class PipelineResultsApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1PipelineResultsFailPipelineRunPost(params: {  "body"?: PostPipelineRunFailureRequest; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsFailPipelineRunPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedAnthraxPost(params: {  "body"?: AnthraxPipelineResult; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsPipelinefinishedAnthraxPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedInfluenzaPost(params: {  "body"?: InfluenzaPipelineResult; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsPipelinefinishedInfluenzaPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinefinishedNbsPost(params: {  "body"?: NBSPipelineResult; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsPipelinefinishedNbsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedAnthraxPost(params: {  "body"?: PipelineStart; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsPipelinestartedAnthraxPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedInfluenzaPost(params: {  "body"?: PipelineStart; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsPipelinestartedInfluenzaPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedNbsPost(params: {  "body"?: PipelineStart; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsPipelinestartedNbsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelinestartedSarscov2Post(params: {  "body"?: PipelineStart; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsPipelinestartedSarscov2Post(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1PipelineResultsPipelineworkflowupdateSarscov2Post(params: {  "body"?: SarsCOV2PipelineWorkflowResult; }, options?: any) {
        return PipelineResultsApiFp.apiV1PipelineResultsPipelineworkflowupdateSarscov2Post(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PipelineResultsApi - factory interface
 */
export const PipelineResultsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1PipelineResultsFailPipelineRunPost(params: {  "body"?: PostPipelineRunFailureRequest; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsFailPipelineRunPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1PipelineResultsPipelinefinishedAnthraxPost(params: {  "body"?: AnthraxPipelineResult; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsPipelinefinishedAnthraxPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1PipelineResultsPipelinefinishedInfluenzaPost(params: {  "body"?: InfluenzaPipelineResult; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsPipelinefinishedInfluenzaPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1PipelineResultsPipelinefinishedNbsPost(params: {  "body"?: NBSPipelineResult; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsPipelinefinishedNbsPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1PipelineResultsPipelinestartedAnthraxPost(params: {  "body"?: PipelineStart; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsPipelinestartedAnthraxPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1PipelineResultsPipelinestartedInfluenzaPost(params: {  "body"?: PipelineStart; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsPipelinestartedInfluenzaPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1PipelineResultsPipelinestartedNbsPost(params: {  "body"?: PipelineStart; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsPipelinestartedNbsPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1PipelineResultsPipelinestartedSarscov2Post(params: {  "body"?: PipelineStart; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsPipelinestartedSarscov2Post(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1PipelineResultsPipelineworkflowupdateSarscov2Post(params: {  "body"?: SarsCOV2PipelineWorkflowResult; }, options?: any) {
            return PipelineResultsApiFp.apiV1PipelineResultsPipelineworkflowupdateSarscov2Post(params, options)(fetch, basePath);
        },
    };
};


/**
 * PipelineRunApi - fetch parameter creator
 */
export const PipelineRunApiFetchParamCreator = {
    /** 
     * Get all Pipeline Runs
     * @param analysisId 
     */
    apiV1PipelineRunInitiateBasespacePipelineRunPost(params: {  "analysisId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineRun/InitiateBasespacePipelineRun`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "analysisId": params["analysisId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a single pipeline run by number
     * @param runNumber 
     */
    apiV1PipelineRunPipelineRunGet(params: {  "runNumber"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineRun/PipelineRun`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "runNumber": params["runNumber"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all Pipeline Runs
     */
    apiV1PipelineRunPipelineRunsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineRun/PipelineRuns`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all Pipeline Runs
     */
    apiV1PipelineRunWetlabRunBasespaceAnalysesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/PipelineRun/WetlabRunBasespaceAnalyses`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PipelineRunApi - functional programming interface
 */
export const PipelineRunApiFp = {
    /** 
     * Get all Pipeline Runs
     * @param analysisId 
     */
    apiV1PipelineRunInitiateBasespacePipelineRunPost(params: { "analysisId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = PipelineRunApiFetchParamCreator.apiV1PipelineRunInitiateBasespacePipelineRunPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a single pipeline run by number
     * @param runNumber 
     */
    apiV1PipelineRunPipelineRunGet(params: { "runNumber"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PipelineRunVM> {
        const fetchArgs = PipelineRunApiFetchParamCreator.apiV1PipelineRunPipelineRunGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all Pipeline Runs
     */
    apiV1PipelineRunPipelineRunsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PipelineRunVM>> {
        const fetchArgs = PipelineRunApiFetchParamCreator.apiV1PipelineRunPipelineRunsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all Pipeline Runs
     */
    apiV1PipelineRunWetlabRunBasespaceAnalysesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WetlabRunBasespaceAnalysesVM>> {
        const fetchArgs = PipelineRunApiFetchParamCreator.apiV1PipelineRunWetlabRunBasespaceAnalysesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PipelineRunApi - object-oriented interface
 */
export class PipelineRunApi extends BaseAPI {
    /** 
     * Get all Pipeline Runs
     * @param analysisId 
     */
    apiV1PipelineRunInitiateBasespacePipelineRunPost(params: {  "analysisId"?: string; }, options?: any) {
        return PipelineRunApiFp.apiV1PipelineRunInitiateBasespacePipelineRunPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a single pipeline run by number
     * @param runNumber 
     */
    apiV1PipelineRunPipelineRunGet(params: {  "runNumber"?: number; }, options?: any) {
        return PipelineRunApiFp.apiV1PipelineRunPipelineRunGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all Pipeline Runs
     */
    apiV1PipelineRunPipelineRunsGet(options?: any) {
        return PipelineRunApiFp.apiV1PipelineRunPipelineRunsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get all Pipeline Runs
     */
    apiV1PipelineRunWetlabRunBasespaceAnalysesGet(options?: any) {
        return PipelineRunApiFp.apiV1PipelineRunWetlabRunBasespaceAnalysesGet(options)(this.fetch, this.basePath);
    }
};

/**
 * PipelineRunApi - factory interface
 */
export const PipelineRunApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get all Pipeline Runs
         * @param analysisId 
         */
        apiV1PipelineRunInitiateBasespacePipelineRunPost(params: {  "analysisId"?: string; }, options?: any) {
            return PipelineRunApiFp.apiV1PipelineRunInitiateBasespacePipelineRunPost(params, options)(fetch, basePath);
        },
        /** 
         * Get a single pipeline run by number
         * @param runNumber 
         */
        apiV1PipelineRunPipelineRunGet(params: {  "runNumber"?: number; }, options?: any) {
            return PipelineRunApiFp.apiV1PipelineRunPipelineRunGet(params, options)(fetch, basePath);
        },
        /** 
         * Get all Pipeline Runs
         */
        apiV1PipelineRunPipelineRunsGet(options?: any) {
            return PipelineRunApiFp.apiV1PipelineRunPipelineRunsGet(options)(fetch, basePath);
        },
        /** 
         * Get all Pipeline Runs
         */
        apiV1PipelineRunWetlabRunBasespaceAnalysesGet(options?: any) {
            return PipelineRunApiFp.apiV1PipelineRunWetlabRunBasespaceAnalysesGet(options)(fetch, basePath);
        },
    };
};


/**
 * PlateApi - fetch parameter creator
 */
export const PlateApiFetchParamCreator = {
    /** 
     * Returns the plate&#39;s RTPCR results
     * @param plateId Plate Id
     */
    apiV1PlateGetPlateRtPCRResultsPlateIdGet(params: {  "plateId": string; }, options?: any): FetchArgs {
        // verify required parameter "plateId" is set
        if (params["plateId"] == null) {
            throw new Error("Missing required parameter plateId when calling apiV1PlateGetPlateRtPCRResultsPlateIdGet");
        }
        const baseUrl = `/api/v1/Plate/GetPlateRtPCRResults/{plateId}`
            .replace(`{${"plateId"}}`, `${ params["plateId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get plates for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1PlateGetPlatesForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1PlateGetPlatesForStepStepInstanceIdGet");
        }
        const baseUrl = `/api/v1/Plate/GetPlatesForStep/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a Plate, including the samples
     * @param id 
     */
    apiV1PlateIdGet(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1PlateIdGet");
        }
        const baseUrl = `/api/v1/Plate/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Add controls to plate
     * @param plateId Plate Id
     * @param workflowRunId Workflow Run Id
     * @param body Controls to add
     */
    apiV1PlatePlateIdAddControlsPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any): FetchArgs {
        // verify required parameter "plateId" is set
        if (params["plateId"] == null) {
            throw new Error("Missing required parameter plateId when calling apiV1PlatePlateIdAddControlsPatch");
        }
        const baseUrl = `/api/v1/Plate/{plateId}/AddControls`
            .replace(`{${"plateId"}}`, `${ params["plateId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param plateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1PlatePlateIdPlateSamplesPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any): FetchArgs {
        // verify required parameter "plateId" is set
        if (params["plateId"] == null) {
            throw new Error("Missing required parameter plateId when calling apiV1PlatePlateIdPlateSamplesPatch");
        }
        const baseUrl = `/api/v1/Plate/{plateId}/PlateSamples`
            .replace(`{${"plateId"}}`, `${ params["plateId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param plateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1PlatePlateIdSaveControlsPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any): FetchArgs {
        // verify required parameter "plateId" is set
        if (params["plateId"] == null) {
            throw new Error("Missing required parameter plateId when calling apiV1PlatePlateIdSaveControlsPatch");
        }
        const baseUrl = `/api/v1/Plate/{plateId}/SaveControls`
            .replace(`{${"plateId"}}`, `${ params["plateId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param plateId 
     * @param status 
     */
    apiV1PlatePlateIdUpdateCDCStatusStatusPatch(params: {  "plateId": string; "status": boolean; }, options?: any): FetchArgs {
        // verify required parameter "plateId" is set
        if (params["plateId"] == null) {
            throw new Error("Missing required parameter plateId when calling apiV1PlatePlateIdUpdateCDCStatusStatusPatch");
        }
        // verify required parameter "status" is set
        if (params["status"] == null) {
            throw new Error("Missing required parameter status when calling apiV1PlatePlateIdUpdateCDCStatusStatusPatch");
        }
        const baseUrl = `/api/v1/Plate/{plateId}/UpdateCDCStatus/{status}`
            .replace(`{${"plateId"}}`, `${ params["plateId"] }`)
            .replace(`{${"status"}}`, `${ params["status"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update plate well volume
     * @param plateId plate to update
     * @param volume new volume
     */
    apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch(params: {  "plateId": string; "volume": number; }, options?: any): FetchArgs {
        // verify required parameter "plateId" is set
        if (params["plateId"] == null) {
            throw new Error("Missing required parameter plateId when calling apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch");
        }
        // verify required parameter "volume" is set
        if (params["volume"] == null) {
            throw new Error("Missing required parameter volume when calling apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch");
        }
        const baseUrl = `/api/v1/Plate/{plateId}/UpdatePlateWellVolume/{volume}`
            .replace(`{${"plateId"}}`, `${ params["plateId"] }`)
            .replace(`{${"volume"}}`, `${ params["volume"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update the plate status to the status given
     * @param body list of plates to update, with the status
     */
    apiV1PlateUpdatePlateStatusPost(params: {  "body"?: UpdatePlateStatusRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Plate/UpdatePlateStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PlateApi - functional programming interface
 */
export const PlateApiFp = {
    /** 
     * Returns the plate&#39;s RTPCR results
     * @param plateId Plate Id
     */
    apiV1PlateGetPlateRtPCRResultsPlateIdGet(params: { "plateId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RtPCRResultVM>> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlateGetPlateRtPCRResultsPlateIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get plates for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1PlateGetPlatesForStepStepInstanceIdGet(params: { "stepInstanceId": string; "workflowRunId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PlateVM>> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlateGetPlatesForStepStepInstanceIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a Plate, including the samples
     * @param id 
     */
    apiV1PlateIdGet(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PlateVM> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlateIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Add controls to plate
     * @param plateId Plate Id
     * @param workflowRunId Workflow Run Id
     * @param body Controls to add
     */
    apiV1PlatePlateIdAddControlsPatch(params: { "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlatePlateIdAddControlsPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param plateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1PlatePlateIdPlateSamplesPatch(params: { "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlatePlateIdPlateSamplesPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param plateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1PlatePlateIdSaveControlsPatch(params: { "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlatePlateIdSaveControlsPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param plateId 
     * @param status 
     */
    apiV1PlatePlateIdUpdateCDCStatusStatusPatch(params: { "plateId": string; "status": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlatePlateIdUpdateCDCStatusStatusPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update plate well volume
     * @param plateId plate to update
     * @param volume new volume
     */
    apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch(params: { "plateId": string; "volume": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update the plate status to the status given
     * @param body list of plates to update, with the status
     */
    apiV1PlateUpdatePlateStatusPost(params: { "body"?: UpdatePlateStatusRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = PlateApiFetchParamCreator.apiV1PlateUpdatePlateStatusPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PlateApi - object-oriented interface
 */
export class PlateApi extends BaseAPI {
    /** 
     * Returns the plate&#39;s RTPCR results
     * @param plateId Plate Id
     */
    apiV1PlateGetPlateRtPCRResultsPlateIdGet(params: {  "plateId": string; }, options?: any) {
        return PlateApiFp.apiV1PlateGetPlateRtPCRResultsPlateIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get plates for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1PlateGetPlatesForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any) {
        return PlateApiFp.apiV1PlateGetPlatesForStepStepInstanceIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a Plate, including the samples
     * @param id 
     */
    apiV1PlateIdGet(params: {  "id": string; }, options?: any) {
        return PlateApiFp.apiV1PlateIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Add controls to plate
     * @param plateId Plate Id
     * @param workflowRunId Workflow Run Id
     * @param body Controls to add
     */
    apiV1PlatePlateIdAddControlsPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any) {
        return PlateApiFp.apiV1PlatePlateIdAddControlsPatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param plateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1PlatePlateIdPlateSamplesPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any) {
        return PlateApiFp.apiV1PlatePlateIdPlateSamplesPatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param plateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1PlatePlateIdSaveControlsPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any) {
        return PlateApiFp.apiV1PlatePlateIdSaveControlsPatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param plateId 
     * @param status 
     */
    apiV1PlatePlateIdUpdateCDCStatusStatusPatch(params: {  "plateId": string; "status": boolean; }, options?: any) {
        return PlateApiFp.apiV1PlatePlateIdUpdateCDCStatusStatusPatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update plate well volume
     * @param plateId plate to update
     * @param volume new volume
     */
    apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch(params: {  "plateId": string; "volume": number; }, options?: any) {
        return PlateApiFp.apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update the plate status to the status given
     * @param body list of plates to update, with the status
     */
    apiV1PlateUpdatePlateStatusPost(params: {  "body"?: UpdatePlateStatusRequestVM; }, options?: any) {
        return PlateApiFp.apiV1PlateUpdatePlateStatusPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PlateApi - factory interface
 */
export const PlateApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Returns the plate&#39;s RTPCR results
         * @param plateId Plate Id
         */
        apiV1PlateGetPlateRtPCRResultsPlateIdGet(params: {  "plateId": string; }, options?: any) {
            return PlateApiFp.apiV1PlateGetPlateRtPCRResultsPlateIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Get plates for a step
         * @param stepInstanceId 
         * @param workflowRunId 
         */
        apiV1PlateGetPlatesForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any) {
            return PlateApiFp.apiV1PlateGetPlatesForStepStepInstanceIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a Plate, including the samples
         * @param id 
         */
        apiV1PlateIdGet(params: {  "id": string; }, options?: any) {
            return PlateApiFp.apiV1PlateIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Add controls to plate
         * @param plateId Plate Id
         * @param workflowRunId Workflow Run Id
         * @param body Controls to add
         */
        apiV1PlatePlateIdAddControlsPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any) {
            return PlateApiFp.apiV1PlatePlateIdAddControlsPatch(params, options)(fetch, basePath);
        },
        /** 
         * @param plateId 
         * @param workflowRunId 
         * @param body 
         */
        apiV1PlatePlateIdPlateSamplesPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any) {
            return PlateApiFp.apiV1PlatePlateIdPlateSamplesPatch(params, options)(fetch, basePath);
        },
        /** 
         * @param plateId 
         * @param workflowRunId 
         * @param body 
         */
        apiV1PlatePlateIdSaveControlsPatch(params: {  "plateId": string; "workflowRunId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any) {
            return PlateApiFp.apiV1PlatePlateIdSaveControlsPatch(params, options)(fetch, basePath);
        },
        /** 
         * @param plateId 
         * @param status 
         */
        apiV1PlatePlateIdUpdateCDCStatusStatusPatch(params: {  "plateId": string; "status": boolean; }, options?: any) {
            return PlateApiFp.apiV1PlatePlateIdUpdateCDCStatusStatusPatch(params, options)(fetch, basePath);
        },
        /** 
         * Update plate well volume
         * @param plateId plate to update
         * @param volume new volume
         */
        apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch(params: {  "plateId": string; "volume": number; }, options?: any) {
            return PlateApiFp.apiV1PlatePlateIdUpdatePlateWellVolumeVolumePatch(params, options)(fetch, basePath);
        },
        /** 
         * Update the plate status to the status given
         * @param body list of plates to update, with the status
         */
        apiV1PlateUpdatePlateStatusPost(params: {  "body"?: UpdatePlateStatusRequestVM; }, options?: any) {
            return PlateApiFp.apiV1PlateUpdatePlateStatusPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * PoolApi - fetch parameter creator
 */
export const PoolApiFetchParamCreator = {
    /** 
     * Update the pool status to the status given
     * @param body list of pools to update, with the status
     */
    apiV1PoolChangePoolStatusPatch(params: {  "body"?: UpdatePoolStatusRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Pool/ChangePoolStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of input pools for a step instance
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1PoolGetInputPoolsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1PoolGetInputPoolsForStepStepInstanceIdGet");
        }
        const baseUrl = `/api/v1/Pool/GetInputPoolsForStep/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a Pool, including the samples
     * @param id 
     */
    apiV1PoolPoolContentsIdGet(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1PoolPoolContentsIdGet");
        }
        const baseUrl = `/api/v1/Pool/poolContents/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update pool volume
     * @param poolId Pool to update
     * @param volume new volume
     */
    apiV1PoolPoolIdUpdatePoolVolumeVolumePatch(params: {  "poolId": string; "volume": number; }, options?: any): FetchArgs {
        // verify required parameter "poolId" is set
        if (params["poolId"] == null) {
            throw new Error("Missing required parameter poolId when calling apiV1PoolPoolIdUpdatePoolVolumeVolumePatch");
        }
        // verify required parameter "volume" is set
        if (params["volume"] == null) {
            throw new Error("Missing required parameter volume when calling apiV1PoolPoolIdUpdatePoolVolumeVolumePatch");
        }
        const baseUrl = `/api/v1/Pool/{poolId}/UpdatePoolVolume/{volume}`
            .replace(`{${"poolId"}}`, `${ params["poolId"] }`)
            .replace(`{${"volume"}}`, `${ params["volume"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Populates pool from the plate volumes
     * @param body 
     */
    apiV1PoolPopulatePoolFromPlatePost(params: {  "body"?: PopulatePoolFromPlateRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Pool/PopulatePoolFromPlate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PoolApi - functional programming interface
 */
export const PoolApiFp = {
    /** 
     * Update the pool status to the status given
     * @param body list of pools to update, with the status
     */
    apiV1PoolChangePoolStatusPatch(params: { "body"?: UpdatePoolStatusRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = PoolApiFetchParamCreator.apiV1PoolChangePoolStatusPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of input pools for a step instance
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1PoolGetInputPoolsForStepStepInstanceIdGet(params: { "stepInstanceId": string; "workflowRunId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PoolVM>> {
        const fetchArgs = PoolApiFetchParamCreator.apiV1PoolGetInputPoolsForStepStepInstanceIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a Pool, including the samples
     * @param id 
     */
    apiV1PoolPoolContentsIdGet(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WellContentVM>> {
        const fetchArgs = PoolApiFetchParamCreator.apiV1PoolPoolContentsIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update pool volume
     * @param poolId Pool to update
     * @param volume new volume
     */
    apiV1PoolPoolIdUpdatePoolVolumeVolumePatch(params: { "poolId": string; "volume": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PoolApiFetchParamCreator.apiV1PoolPoolIdUpdatePoolVolumeVolumePatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Populates pool from the plate volumes
     * @param body 
     */
    apiV1PoolPopulatePoolFromPlatePost(params: { "body"?: PopulatePoolFromPlateRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PoolApiFetchParamCreator.apiV1PoolPopulatePoolFromPlatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PoolApi - object-oriented interface
 */
export class PoolApi extends BaseAPI {
    /** 
     * Update the pool status to the status given
     * @param body list of pools to update, with the status
     */
    apiV1PoolChangePoolStatusPatch(params: {  "body"?: UpdatePoolStatusRequestVM; }, options?: any) {
        return PoolApiFp.apiV1PoolChangePoolStatusPatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of input pools for a step instance
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1PoolGetInputPoolsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any) {
        return PoolApiFp.apiV1PoolGetInputPoolsForStepStepInstanceIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a Pool, including the samples
     * @param id 
     */
    apiV1PoolPoolContentsIdGet(params: {  "id": string; }, options?: any) {
        return PoolApiFp.apiV1PoolPoolContentsIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update pool volume
     * @param poolId Pool to update
     * @param volume new volume
     */
    apiV1PoolPoolIdUpdatePoolVolumeVolumePatch(params: {  "poolId": string; "volume": number; }, options?: any) {
        return PoolApiFp.apiV1PoolPoolIdUpdatePoolVolumeVolumePatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Populates pool from the plate volumes
     * @param body 
     */
    apiV1PoolPopulatePoolFromPlatePost(params: {  "body"?: PopulatePoolFromPlateRequestVM; }, options?: any) {
        return PoolApiFp.apiV1PoolPopulatePoolFromPlatePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PoolApi - factory interface
 */
export const PoolApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Update the pool status to the status given
         * @param body list of pools to update, with the status
         */
        apiV1PoolChangePoolStatusPatch(params: {  "body"?: UpdatePoolStatusRequestVM; }, options?: any) {
            return PoolApiFp.apiV1PoolChangePoolStatusPatch(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of input pools for a step instance
         * @param stepInstanceId 
         * @param workflowRunId 
         */
        apiV1PoolGetInputPoolsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any) {
            return PoolApiFp.apiV1PoolGetInputPoolsForStepStepInstanceIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a Pool, including the samples
         * @param id 
         */
        apiV1PoolPoolContentsIdGet(params: {  "id": string; }, options?: any) {
            return PoolApiFp.apiV1PoolPoolContentsIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Update pool volume
         * @param poolId Pool to update
         * @param volume new volume
         */
        apiV1PoolPoolIdUpdatePoolVolumeVolumePatch(params: {  "poolId": string; "volume": number; }, options?: any) {
            return PoolApiFp.apiV1PoolPoolIdUpdatePoolVolumeVolumePatch(params, options)(fetch, basePath);
        },
        /** 
         * Populates pool from the plate volumes
         * @param body 
         */
        apiV1PoolPopulatePoolFromPlatePost(params: {  "body"?: PopulatePoolFromPlateRequestVM; }, options?: any) {
            return PoolApiFp.apiV1PoolPopulatePoolFromPlatePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * ProcessApi - fetch parameter creator
 */
export const ProcessApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1ProcessAddrunandfiledataPost(params: {  "body"?: PostFileDataRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/addrunandfiledata`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1ProcessAllfilesforuploadGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/allfilesforupload`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessBatchLogPost(params: {  "body"?: Array<BatchLogEntry>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/BatchLog`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessClaritymetadataPost(params: {  "body"?: PostMetadataRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/claritymetadata`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1ProcessFilestodownloadGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/filestodownload`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1ProcessGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1ProcessGetReadyForProcessingBasespaceAnalysesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/getReadyForProcessingBasespaceAnalyses`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessGetS3UploadInfoPost(params: {  "body"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/GetS3UploadInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessInstrumentRunAcquisitionedPost(params: {  "body"?: RunAcquisitionedRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/InstrumentRunAcquisitioned`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessNewBasespaceAnalysesPost(params: {  "body"?: Array<LimsBasespaceAnalysis>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/newBasespaceAnalyses`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessPostfilefullpathsPatch(params: {  "body"?: LimsInstrumentFile; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/postfilefullpaths`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessProcessFileRelevancePost(params: {  "body"?: Array<FileRelevanceRequest>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/ProcessFileRelevance`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessProcessSampleSheetPost(params: {  "body"?: ParseSampleSheetRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/ProcessSampleSheet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param instrumentFileId 
     */
    apiV1ProcessRunandsampleforfileInstrumentFileIdGet(params: {  "instrumentFileId": string; }, options?: any): FetchArgs {
        // verify required parameter "instrumentFileId" is set
        if (params["instrumentFileId"] == null) {
            throw new Error("Missing required parameter instrumentFileId when calling apiV1ProcessRunandsampleforfileInstrumentFileIdGet");
        }
        const baseUrl = `/api/v1/Process/runandsampleforfile/{instrumentFileId}`
            .replace(`{${"instrumentFileId"}}`, `${ params["instrumentFileId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessUpdateS3PushedStatusPost(params: {  "body"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/UpdateS3PushedStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessUpdateuploadstatusesPatch(params: {  "body"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Process/updateuploadstatuses`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ProcessApi - functional programming interface
 */
export const ProcessApiFp = {
    /** 
     * @param body 
     */
    apiV1ProcessAddrunandfiledataPost(params: { "body"?: PostFileDataRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessAddrunandfiledataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1ProcessAllfilesforuploadGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LimsInstrumentFile>> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessAllfilesforuploadGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessBatchLogPost(params: { "body"?: Array<BatchLogEntry>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessBatchLogPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessClaritymetadataPost(params: { "body"?: PostMetadataRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessClaritymetadataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1ProcessFilestodownloadGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LimsInstrumentFile>> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessFilestodownloadGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1ProcessGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1ProcessGetReadyForProcessingBasespaceAnalysesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LimsBasespaceAnalysis>> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessGetReadyForProcessingBasespaceAnalysesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessGetS3UploadInfoPost(params: { "body"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FileOperationResponse>> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessGetS3UploadInfoPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessInstrumentRunAcquisitionedPost(params: { "body"?: RunAcquisitionedRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessInstrumentRunAcquisitionedPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessNewBasespaceAnalysesPost(params: { "body"?: Array<LimsBasespaceAnalysis>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessNewBasespaceAnalysesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessPostfilefullpathsPatch(params: { "body"?: LimsInstrumentFile;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessPostfilefullpathsPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessProcessFileRelevancePost(params: { "body"?: Array<FileRelevanceRequest>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FileRelevanceResponse>> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessProcessFileRelevancePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessProcessSampleSheetPost(params: { "body"?: ParseSampleSheetRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessProcessSampleSheetPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param instrumentFileId 
     */
    apiV1ProcessRunandsampleforfileInstrumentFileIdGet(params: { "instrumentFileId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RunAndSampleForFileResponse> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessRunandsampleforfileInstrumentFileIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessUpdateS3PushedStatusPost(params: { "body"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FileOperationResponse>> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessUpdateS3PushedStatusPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ProcessUpdateuploadstatusesPatch(params: { "body"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = ProcessApiFetchParamCreator.apiV1ProcessUpdateuploadstatusesPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ProcessApi - object-oriented interface
 */
export class ProcessApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1ProcessAddrunandfiledataPost(params: {  "body"?: PostFileDataRequest; }, options?: any) {
        return ProcessApiFp.apiV1ProcessAddrunandfiledataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1ProcessAllfilesforuploadGet(options?: any) {
        return ProcessApiFp.apiV1ProcessAllfilesforuploadGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessBatchLogPost(params: {  "body"?: Array<BatchLogEntry>; }, options?: any) {
        return ProcessApiFp.apiV1ProcessBatchLogPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessClaritymetadataPost(params: {  "body"?: PostMetadataRequest; }, options?: any) {
        return ProcessApiFp.apiV1ProcessClaritymetadataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1ProcessFilestodownloadGet(options?: any) {
        return ProcessApiFp.apiV1ProcessFilestodownloadGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1ProcessGet(options?: any) {
        return ProcessApiFp.apiV1ProcessGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1ProcessGetReadyForProcessingBasespaceAnalysesGet(options?: any) {
        return ProcessApiFp.apiV1ProcessGetReadyForProcessingBasespaceAnalysesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessGetS3UploadInfoPost(params: {  "body"?: Array<string>; }, options?: any) {
        return ProcessApiFp.apiV1ProcessGetS3UploadInfoPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessInstrumentRunAcquisitionedPost(params: {  "body"?: RunAcquisitionedRequest; }, options?: any) {
        return ProcessApiFp.apiV1ProcessInstrumentRunAcquisitionedPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessNewBasespaceAnalysesPost(params: {  "body"?: Array<LimsBasespaceAnalysis>; }, options?: any) {
        return ProcessApiFp.apiV1ProcessNewBasespaceAnalysesPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessPostfilefullpathsPatch(params: {  "body"?: LimsInstrumentFile; }, options?: any) {
        return ProcessApiFp.apiV1ProcessPostfilefullpathsPatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessProcessFileRelevancePost(params: {  "body"?: Array<FileRelevanceRequest>; }, options?: any) {
        return ProcessApiFp.apiV1ProcessProcessFileRelevancePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessProcessSampleSheetPost(params: {  "body"?: ParseSampleSheetRequest; }, options?: any) {
        return ProcessApiFp.apiV1ProcessProcessSampleSheetPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param instrumentFileId 
     */
    apiV1ProcessRunandsampleforfileInstrumentFileIdGet(params: {  "instrumentFileId": string; }, options?: any) {
        return ProcessApiFp.apiV1ProcessRunandsampleforfileInstrumentFileIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessUpdateS3PushedStatusPost(params: {  "body"?: Array<string>; }, options?: any) {
        return ProcessApiFp.apiV1ProcessUpdateS3PushedStatusPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ProcessUpdateuploadstatusesPatch(params: {  "body"?: Array<string>; }, options?: any) {
        return ProcessApiFp.apiV1ProcessUpdateuploadstatusesPatch(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ProcessApi - factory interface
 */
export const ProcessApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1ProcessAddrunandfiledataPost(params: {  "body"?: PostFileDataRequest; }, options?: any) {
            return ProcessApiFp.apiV1ProcessAddrunandfiledataPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1ProcessAllfilesforuploadGet(options?: any) {
            return ProcessApiFp.apiV1ProcessAllfilesforuploadGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessBatchLogPost(params: {  "body"?: Array<BatchLogEntry>; }, options?: any) {
            return ProcessApiFp.apiV1ProcessBatchLogPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessClaritymetadataPost(params: {  "body"?: PostMetadataRequest; }, options?: any) {
            return ProcessApiFp.apiV1ProcessClaritymetadataPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1ProcessFilestodownloadGet(options?: any) {
            return ProcessApiFp.apiV1ProcessFilestodownloadGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1ProcessGet(options?: any) {
            return ProcessApiFp.apiV1ProcessGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1ProcessGetReadyForProcessingBasespaceAnalysesGet(options?: any) {
            return ProcessApiFp.apiV1ProcessGetReadyForProcessingBasespaceAnalysesGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessGetS3UploadInfoPost(params: {  "body"?: Array<string>; }, options?: any) {
            return ProcessApiFp.apiV1ProcessGetS3UploadInfoPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessInstrumentRunAcquisitionedPost(params: {  "body"?: RunAcquisitionedRequest; }, options?: any) {
            return ProcessApiFp.apiV1ProcessInstrumentRunAcquisitionedPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessNewBasespaceAnalysesPost(params: {  "body"?: Array<LimsBasespaceAnalysis>; }, options?: any) {
            return ProcessApiFp.apiV1ProcessNewBasespaceAnalysesPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessPostfilefullpathsPatch(params: {  "body"?: LimsInstrumentFile; }, options?: any) {
            return ProcessApiFp.apiV1ProcessPostfilefullpathsPatch(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessProcessFileRelevancePost(params: {  "body"?: Array<FileRelevanceRequest>; }, options?: any) {
            return ProcessApiFp.apiV1ProcessProcessFileRelevancePost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessProcessSampleSheetPost(params: {  "body"?: ParseSampleSheetRequest; }, options?: any) {
            return ProcessApiFp.apiV1ProcessProcessSampleSheetPost(params, options)(fetch, basePath);
        },
        /** 
         * @param instrumentFileId 
         */
        apiV1ProcessRunandsampleforfileInstrumentFileIdGet(params: {  "instrumentFileId": string; }, options?: any) {
            return ProcessApiFp.apiV1ProcessRunandsampleforfileInstrumentFileIdGet(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessUpdateS3PushedStatusPost(params: {  "body"?: Array<string>; }, options?: any) {
            return ProcessApiFp.apiV1ProcessUpdateS3PushedStatusPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ProcessUpdateuploadstatusesPatch(params: {  "body"?: Array<string>; }, options?: any) {
            return ProcessApiFp.apiV1ProcessUpdateuploadstatusesPatch(params, options)(fetch, basePath);
        },
    };
};


/**
 * QCApi - fetch parameter creator
 */
export const QCApiFetchParamCreator = {
    /** 
     * @param workflowId 
     * @param stepFilter 
     */
    apiV1QCGetQCChecksGet(params: {  "workflowId"?: string; "stepFilter"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/QC/GetQCChecks`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowId": params["workflowId"],
            "stepFilter": params["stepFilter"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of QC summaries.
     * @param nameFilter 
     */
    apiV1QCGetQCSummariesGet(params: {  "nameFilter"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/QC/GetQCSummaries`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "nameFilter": params["nameFilter"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowRunId 
     * @param stepId 
     */
    apiV1QCQCCheckResultsGet(params: {  "workflowRunId"?: string; "stepId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/QC/QCCheckResults`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
            "stepId": params["stepId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Populates pool from the plate volumes
     * @param body 
     */
    apiV1QCQuantAfterEndRepairQCPost(params: {  "body"?: QuantAfterEndRepairQCRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/QC/QuantAfterEndRepairQC`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1QCSaveQCCheckPatch(params: {  "body"?: QCCheckVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/QC/SaveQCCheck`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1QCWriteQCCheckResultsPost(params: {  "body"?: Array<QCCheckResultVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/QC/WriteQCCheckResults`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * QCApi - functional programming interface
 */
export const QCApiFp = {
    /** 
     * @param workflowId 
     * @param stepFilter 
     */
    apiV1QCGetQCChecksGet(params: { "workflowId"?: string; "stepFilter"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QCCheckVM>> {
        const fetchArgs = QCApiFetchParamCreator.apiV1QCGetQCChecksGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of QC summaries.
     * @param nameFilter 
     */
    apiV1QCGetQCSummariesGet(params: { "nameFilter"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkflowQCSummaryVM>> {
        const fetchArgs = QCApiFetchParamCreator.apiV1QCGetQCSummariesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowRunId 
     * @param stepId 
     */
    apiV1QCQCCheckResultsGet(params: { "workflowRunId"?: string; "stepId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QCCheckResultVM>> {
        const fetchArgs = QCApiFetchParamCreator.apiV1QCQCCheckResultsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Populates pool from the plate volumes
     * @param body 
     */
    apiV1QCQuantAfterEndRepairQCPost(params: { "body"?: QuantAfterEndRepairQCRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QCApiFetchParamCreator.apiV1QCQuantAfterEndRepairQCPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1QCSaveQCCheckPatch(params: { "body"?: QCCheckVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = QCApiFetchParamCreator.apiV1QCSaveQCCheckPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1QCWriteQCCheckResultsPost(params: { "body"?: Array<QCCheckResultVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QCApiFetchParamCreator.apiV1QCWriteQCCheckResultsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * QCApi - object-oriented interface
 */
export class QCApi extends BaseAPI {
    /** 
     * @param workflowId 
     * @param stepFilter 
     */
    apiV1QCGetQCChecksGet(params: {  "workflowId"?: string; "stepFilter"?: string; }, options?: any) {
        return QCApiFp.apiV1QCGetQCChecksGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of QC summaries.
     * @param nameFilter 
     */
    apiV1QCGetQCSummariesGet(params: {  "nameFilter"?: string; }, options?: any) {
        return QCApiFp.apiV1QCGetQCSummariesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowRunId 
     * @param stepId 
     */
    apiV1QCQCCheckResultsGet(params: {  "workflowRunId"?: string; "stepId"?: string; }, options?: any) {
        return QCApiFp.apiV1QCQCCheckResultsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Populates pool from the plate volumes
     * @param body 
     */
    apiV1QCQuantAfterEndRepairQCPost(params: {  "body"?: QuantAfterEndRepairQCRequest; }, options?: any) {
        return QCApiFp.apiV1QCQuantAfterEndRepairQCPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1QCSaveQCCheckPatch(params: {  "body"?: QCCheckVM; }, options?: any) {
        return QCApiFp.apiV1QCSaveQCCheckPatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1QCWriteQCCheckResultsPost(params: {  "body"?: Array<QCCheckResultVM>; }, options?: any) {
        return QCApiFp.apiV1QCWriteQCCheckResultsPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * QCApi - factory interface
 */
export const QCApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param workflowId 
         * @param stepFilter 
         */
        apiV1QCGetQCChecksGet(params: {  "workflowId"?: string; "stepFilter"?: string; }, options?: any) {
            return QCApiFp.apiV1QCGetQCChecksGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of QC summaries.
         * @param nameFilter 
         */
        apiV1QCGetQCSummariesGet(params: {  "nameFilter"?: string; }, options?: any) {
            return QCApiFp.apiV1QCGetQCSummariesGet(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowRunId 
         * @param stepId 
         */
        apiV1QCQCCheckResultsGet(params: {  "workflowRunId"?: string; "stepId"?: string; }, options?: any) {
            return QCApiFp.apiV1QCQCCheckResultsGet(params, options)(fetch, basePath);
        },
        /** 
         * Populates pool from the plate volumes
         * @param body 
         */
        apiV1QCQuantAfterEndRepairQCPost(params: {  "body"?: QuantAfterEndRepairQCRequest; }, options?: any) {
            return QCApiFp.apiV1QCQuantAfterEndRepairQCPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1QCSaveQCCheckPatch(params: {  "body"?: QCCheckVM; }, options?: any) {
            return QCApiFp.apiV1QCSaveQCCheckPatch(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1QCWriteQCCheckResultsPost(params: {  "body"?: Array<QCCheckResultVM>; }, options?: any) {
            return QCApiFp.apiV1QCWriteQCCheckResultsPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * RackApi - fetch parameter creator
 */
export const RackApiFetchParamCreator = {
    /** 
     * @param id 
     */
    apiV1RackAssetIdGet(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1RackAssetIdGet");
        }
        const baseUrl = `/api/v1/Rack/asset/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get assets for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1RackGetAssetsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1RackGetAssetsForStepStepInstanceIdGet");
        }
        const baseUrl = `/api/v1/Rack/GetAssetsForStep/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a Plate, Pool or Rack, including the samples
     * @param id 
     */
    apiV1RackIdGet(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1RackIdGet");
        }
        const baseUrl = `/api/v1/Rack/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RackApi - functional programming interface
 */
export const RackApiFp = {
    /** 
     * @param id 
     */
    apiV1RackAssetIdGet(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AssetVM> {
        const fetchArgs = RackApiFetchParamCreator.apiV1RackAssetIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get assets for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1RackGetAssetsForStepStepInstanceIdGet(params: { "stepInstanceId": string; "workflowRunId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RackVM>> {
        const fetchArgs = RackApiFetchParamCreator.apiV1RackGetAssetsForStepStepInstanceIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a Plate, Pool or Rack, including the samples
     * @param id 
     */
    apiV1RackIdGet(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RackVM> {
        const fetchArgs = RackApiFetchParamCreator.apiV1RackIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RackApi - object-oriented interface
 */
export class RackApi extends BaseAPI {
    /** 
     * @param id 
     */
    apiV1RackAssetIdGet(params: {  "id": string; }, options?: any) {
        return RackApiFp.apiV1RackAssetIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get assets for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1RackGetAssetsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any) {
        return RackApiFp.apiV1RackGetAssetsForStepStepInstanceIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a Plate, Pool or Rack, including the samples
     * @param id 
     */
    apiV1RackIdGet(params: {  "id": string; }, options?: any) {
        return RackApiFp.apiV1RackIdGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * RackApi - factory interface
 */
export const RackApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param id 
         */
        apiV1RackAssetIdGet(params: {  "id": string; }, options?: any) {
            return RackApiFp.apiV1RackAssetIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Get assets for a step
         * @param stepInstanceId 
         * @param workflowRunId 
         */
        apiV1RackGetAssetsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any) {
            return RackApiFp.apiV1RackGetAssetsForStepStepInstanceIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a Plate, Pool or Rack, including the samples
         * @param id 
         */
        apiV1RackIdGet(params: {  "id": string; }, options?: any) {
            return RackApiFp.apiV1RackIdGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * RemoteAssociationApi - fetch parameter creator
 */
export const RemoteAssociationApiFetchParamCreator = {
    /** 
     * Accept the instance association request
     * @param id 
     * @param alias 
     */
    apiV1RemoteAssociationAcceptAssociationIdPost(params: {  "id": string; "alias"?: string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1RemoteAssociationAcceptAssociationIdPost");
        }
        const baseUrl = `/api/v1/RemoteAssociation/AcceptAssociation/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "alias": params["alias"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Cancel an association request
     * @param id 
     */
    apiV1RemoteAssociationAssociateWithInstanceIdPost(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1RemoteAssociationAssociateWithInstanceIdPost");
        }
        const baseUrl = `/api/v1/RemoteAssociation/AssociateWithInstance/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Cancel an association request
     * @param id 
     */
    apiV1RemoteAssociationCancelAssociationRequestIdPost(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1RemoteAssociationCancelAssociationRequestIdPost");
        }
        const baseUrl = `/api/v1/RemoteAssociation/CancelAssociationRequest/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create an instance association from supplied information
     * @param body 
     */
    apiV1RemoteAssociationCreateAssociationPost(params: {  "body"?: AssociationAddVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/RemoteAssociation/CreateAssociation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Edit the Alias and/or Remote URL of an instance
     * @param id 
     * @param body 
     */
    apiV1RemoteAssociationEditAssociationIdPost(params: {  "id": string; "body"?: AssociationAddVM; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1RemoteAssociationEditAssociationIdPost");
        }
        const baseUrl = `/api/v1/RemoteAssociation/EditAssociation/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns the local site information
     */
    apiV1RemoteAssociationGetMySiteInfoGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/RemoteAssociation/GetMySiteInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns list of remote associations
     */
    apiV1RemoteAssociationGetRemoteAssociationsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/RemoteAssociation/GetRemoteAssociations`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Reject the instance association request
     * @param id 
     */
    apiV1RemoteAssociationRejectAssociationIdPost(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1RemoteAssociationRejectAssociationIdPost");
        }
        const baseUrl = `/api/v1/RemoteAssociation/RejectAssociation/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Remove an existing association
     * @param id 
     */
    apiV1RemoteAssociationUnassociateAssociationIdPost(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1RemoteAssociationUnassociateAssociationIdPost");
        }
        const baseUrl = `/api/v1/RemoteAssociation/UnassociateAssociation/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RemoteAssociationApi - functional programming interface
 */
export const RemoteAssociationApiFp = {
    /** 
     * Accept the instance association request
     * @param id 
     * @param alias 
     */
    apiV1RemoteAssociationAcceptAssociationIdPost(params: { "id": string; "alias"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationAcceptAssociationIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Cancel an association request
     * @param id 
     */
    apiV1RemoteAssociationAssociateWithInstanceIdPost(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationAssociateWithInstanceIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Cancel an association request
     * @param id 
     */
    apiV1RemoteAssociationCancelAssociationRequestIdPost(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationCancelAssociationRequestIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create an instance association from supplied information
     * @param body 
     */
    apiV1RemoteAssociationCreateAssociationPost(params: { "body"?: AssociationAddVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationCreateAssociationPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Edit the Alias and/or Remote URL of an instance
     * @param id 
     * @param body 
     */
    apiV1RemoteAssociationEditAssociationIdPost(params: { "id": string; "body"?: AssociationAddVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationEditAssociationIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns the local site information
     */
    apiV1RemoteAssociationGetMySiteInfoGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteInfoVM> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationGetMySiteInfoGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns list of remote associations
     */
    apiV1RemoteAssociationGetRemoteAssociationsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RemoteAssociationVM>> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationGetRemoteAssociationsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Reject the instance association request
     * @param id 
     */
    apiV1RemoteAssociationRejectAssociationIdPost(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationRejectAssociationIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Remove an existing association
     * @param id 
     */
    apiV1RemoteAssociationUnassociateAssociationIdPost(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = RemoteAssociationApiFetchParamCreator.apiV1RemoteAssociationUnassociateAssociationIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RemoteAssociationApi - object-oriented interface
 */
export class RemoteAssociationApi extends BaseAPI {
    /** 
     * Accept the instance association request
     * @param id 
     * @param alias 
     */
    apiV1RemoteAssociationAcceptAssociationIdPost(params: {  "id": string; "alias"?: string; }, options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationAcceptAssociationIdPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Cancel an association request
     * @param id 
     */
    apiV1RemoteAssociationAssociateWithInstanceIdPost(params: {  "id": string; }, options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationAssociateWithInstanceIdPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Cancel an association request
     * @param id 
     */
    apiV1RemoteAssociationCancelAssociationRequestIdPost(params: {  "id": string; }, options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationCancelAssociationRequestIdPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create an instance association from supplied information
     * @param body 
     */
    apiV1RemoteAssociationCreateAssociationPost(params: {  "body"?: AssociationAddVM; }, options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationCreateAssociationPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Edit the Alias and/or Remote URL of an instance
     * @param id 
     * @param body 
     */
    apiV1RemoteAssociationEditAssociationIdPost(params: {  "id": string; "body"?: AssociationAddVM; }, options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationEditAssociationIdPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns the local site information
     */
    apiV1RemoteAssociationGetMySiteInfoGet(options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationGetMySiteInfoGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Returns list of remote associations
     */
    apiV1RemoteAssociationGetRemoteAssociationsGet(options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationGetRemoteAssociationsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Reject the instance association request
     * @param id 
     */
    apiV1RemoteAssociationRejectAssociationIdPost(params: {  "id": string; }, options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationRejectAssociationIdPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Remove an existing association
     * @param id 
     */
    apiV1RemoteAssociationUnassociateAssociationIdPost(params: {  "id": string; }, options?: any) {
        return RemoteAssociationApiFp.apiV1RemoteAssociationUnassociateAssociationIdPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * RemoteAssociationApi - factory interface
 */
export const RemoteAssociationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Accept the instance association request
         * @param id 
         * @param alias 
         */
        apiV1RemoteAssociationAcceptAssociationIdPost(params: {  "id": string; "alias"?: string; }, options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationAcceptAssociationIdPost(params, options)(fetch, basePath);
        },
        /** 
         * Cancel an association request
         * @param id 
         */
        apiV1RemoteAssociationAssociateWithInstanceIdPost(params: {  "id": string; }, options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationAssociateWithInstanceIdPost(params, options)(fetch, basePath);
        },
        /** 
         * Cancel an association request
         * @param id 
         */
        apiV1RemoteAssociationCancelAssociationRequestIdPost(params: {  "id": string; }, options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationCancelAssociationRequestIdPost(params, options)(fetch, basePath);
        },
        /** 
         * Create an instance association from supplied information
         * @param body 
         */
        apiV1RemoteAssociationCreateAssociationPost(params: {  "body"?: AssociationAddVM; }, options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationCreateAssociationPost(params, options)(fetch, basePath);
        },
        /** 
         * Edit the Alias and/or Remote URL of an instance
         * @param id 
         * @param body 
         */
        apiV1RemoteAssociationEditAssociationIdPost(params: {  "id": string; "body"?: AssociationAddVM; }, options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationEditAssociationIdPost(params, options)(fetch, basePath);
        },
        /** 
         * Returns the local site information
         */
        apiV1RemoteAssociationGetMySiteInfoGet(options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationGetMySiteInfoGet(options)(fetch, basePath);
        },
        /** 
         * Returns list of remote associations
         */
        apiV1RemoteAssociationGetRemoteAssociationsGet(options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationGetRemoteAssociationsGet(options)(fetch, basePath);
        },
        /** 
         * Reject the instance association request
         * @param id 
         */
        apiV1RemoteAssociationRejectAssociationIdPost(params: {  "id": string; }, options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationRejectAssociationIdPost(params, options)(fetch, basePath);
        },
        /** 
         * Remove an existing association
         * @param id 
         */
        apiV1RemoteAssociationUnassociateAssociationIdPost(params: {  "id": string; }, options?: any) {
            return RemoteAssociationApiFp.apiV1RemoteAssociationUnassociateAssociationIdPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * SampleApi - fetch parameter creator
 */
export const SampleApiFetchParamCreator = {
    /** 
     * @param sampleId 
     * @param fastaFileName 
     */
    apiV1SampleAddFastaFileLocationToSampleMetadataPost(params: {  "sampleId"?: string; "fastaFileName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Sample/AddFastaFileLocationToSampleMetadata`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "sampleId": params["sampleId"],
            "fastaFileName": params["fastaFileName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return archived containers (paged)
     * @param page 
     * @param pageSize 
     * @param sampleFilter 
     */
    apiV1SampleGetArchivedContainersPageGet(params: {  "page": number; "pageSize"?: number; "sampleFilter"?: string; }, options?: any): FetchArgs {
        // verify required parameter "page" is set
        if (params["page"] == null) {
            throw new Error("Missing required parameter page when calling apiV1SampleGetArchivedContainersPageGet");
        }
        const baseUrl = `/api/v1/Sample/GetArchivedContainers/{page}`
            .replace(`{${"page"}}`, `${ params["page"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "pageSize": params["pageSize"],
            "sampleFilter": params["sampleFilter"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return archived samples (paged)
     * @param page 
     * @param pageSize 
     * @param containerFilter 
     */
    apiV1SampleGetArchivedSamplesBySamplePageGet(params: {  "page": number; "pageSize"?: number; "containerFilter"?: string; }, options?: any): FetchArgs {
        // verify required parameter "page" is set
        if (params["page"] == null) {
            throw new Error("Missing required parameter page when calling apiV1SampleGetArchivedSamplesBySamplePageGet");
        }
        const baseUrl = `/api/v1/Sample/GetArchivedSamplesBySample/{page}`
            .replace(`{${"page"}}`, `${ params["page"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "pageSize": params["pageSize"],
            "containerFilter": params["containerFilter"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SampleApi - functional programming interface
 */
export const SampleApiFp = {
    /** 
     * @param sampleId 
     * @param fastaFileName 
     */
    apiV1SampleAddFastaFileLocationToSampleMetadataPost(params: { "sampleId"?: string; "fastaFileName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = SampleApiFetchParamCreator.apiV1SampleAddFastaFileLocationToSampleMetadataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return archived containers (paged)
     * @param page 
     * @param pageSize 
     * @param sampleFilter 
     */
    apiV1SampleGetArchivedContainersPageGet(params: { "page": number; "pageSize"?: number; "sampleFilter"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetArchivedContainerResponse> {
        const fetchArgs = SampleApiFetchParamCreator.apiV1SampleGetArchivedContainersPageGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return archived samples (paged)
     * @param page 
     * @param pageSize 
     * @param containerFilter 
     */
    apiV1SampleGetArchivedSamplesBySamplePageGet(params: { "page": number; "pageSize"?: number; "containerFilter"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetArchivedAssetContentsBySampleResponse> {
        const fetchArgs = SampleApiFetchParamCreator.apiV1SampleGetArchivedSamplesBySamplePageGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SampleApi - object-oriented interface
 */
export class SampleApi extends BaseAPI {
    /** 
     * @param sampleId 
     * @param fastaFileName 
     */
    apiV1SampleAddFastaFileLocationToSampleMetadataPost(params: {  "sampleId"?: string; "fastaFileName"?: string; }, options?: any) {
        return SampleApiFp.apiV1SampleAddFastaFileLocationToSampleMetadataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Return archived containers (paged)
     * @param page 
     * @param pageSize 
     * @param sampleFilter 
     */
    apiV1SampleGetArchivedContainersPageGet(params: {  "page": number; "pageSize"?: number; "sampleFilter"?: string; }, options?: any) {
        return SampleApiFp.apiV1SampleGetArchivedContainersPageGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Return archived samples (paged)
     * @param page 
     * @param pageSize 
     * @param containerFilter 
     */
    apiV1SampleGetArchivedSamplesBySamplePageGet(params: {  "page": number; "pageSize"?: number; "containerFilter"?: string; }, options?: any) {
        return SampleApiFp.apiV1SampleGetArchivedSamplesBySamplePageGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SampleApi - factory interface
 */
export const SampleApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param sampleId 
         * @param fastaFileName 
         */
        apiV1SampleAddFastaFileLocationToSampleMetadataPost(params: {  "sampleId"?: string; "fastaFileName"?: string; }, options?: any) {
            return SampleApiFp.apiV1SampleAddFastaFileLocationToSampleMetadataPost(params, options)(fetch, basePath);
        },
        /** 
         * Return archived containers (paged)
         * @param page 
         * @param pageSize 
         * @param sampleFilter 
         */
        apiV1SampleGetArchivedContainersPageGet(params: {  "page": number; "pageSize"?: number; "sampleFilter"?: string; }, options?: any) {
            return SampleApiFp.apiV1SampleGetArchivedContainersPageGet(params, options)(fetch, basePath);
        },
        /** 
         * Return archived samples (paged)
         * @param page 
         * @param pageSize 
         * @param containerFilter 
         */
        apiV1SampleGetArchivedSamplesBySamplePageGet(params: {  "page": number; "pageSize"?: number; "containerFilter"?: string; }, options?: any) {
            return SampleApiFp.apiV1SampleGetArchivedSamplesBySamplePageGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * SettingsApi - fetch parameter creator
 */
export const SettingsApiFetchParamCreator = {
    /** 
     * Gets available hard drive space
     */
    apiV1SettingsAvailableSpaceGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/AvailableSpace`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return true if Demo environment
     */
    apiV1SettingsDemoExperienceGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/DemoExperience`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return remaining time on demo
     */
    apiV1SettingsDemoInformationGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/DemoInformation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns the Google Analytics Tracking ID stored in an environment variable
     */
    apiV1SettingsGATrackingIdGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/GATrackingId`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1SettingsKeycloakGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/Keycloak`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get lims implementation names
     */
    apiV1SettingsLIMSNamesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/LIMSNames`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get if there is a lims implementation set
     */
    apiV1SettingsLIMSSetGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/LIMSSet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the launchpad sites
     */
    apiV1SettingsLaunchpadSitesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/LaunchpadSites`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1SettingsLimsImplementationSetGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/LimsImplementationSet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1SettingsLimsSyncUpGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/LimsSyncUp`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the MIA site
     */
    apiV1SettingsMIASiteGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/MIASite`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the Nextstrain site(Auspice) availability and url.
     */
    apiV1SettingsNextstrainSiteGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/NextstrainSite`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the current platform version
     */
    apiV1SettingsPlatformVersionGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/PlatformVersion`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Polls for status of resetting the demo environment
     * @param pollToken 
     */
    apiV1SettingsPollResetStatusPost(params: {  "pollToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/PollResetStatus`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "pollToken": params["pollToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Resets the demo environment
     */
    apiV1SettingsResetDemoEnvironmentPost(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/ResetDemoEnvironment`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Sets the lims implementation value
     * @param limsImplementation 
     */
    apiV1SettingsSetLimsImplementationPost(params: {  "limsImplementation"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/SetLimsImplementation`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "limsImplementation": params["limsImplementation"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get the system settings
     */
    apiV1SettingsSystemSettingsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/SystemSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get the system settings
     * @param body 
     */
    apiV1SettingsSystemSettingsPost(params: {  "body"?: any; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/SystemSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return User Preferences
     */
    apiV1SettingsUserPreferencesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/UserPreferences`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return User Preferences
     * @param body 
     */
    apiV1SettingsUserPreferencesPost(params: {  "body"?: UserPreferencesVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/UserPreferences`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the version info
     */
    apiV1SettingsVersionInfoGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Settings/VersionInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SettingsApi - functional programming interface
 */
export const SettingsApiFp = {
    /** 
     * Gets available hard drive space
     */
    apiV1SettingsAvailableSpaceGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsAvailableSpaceGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return true if Demo environment
     */
    apiV1SettingsDemoExperienceGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsDemoExperienceGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return remaining time on demo
     */
    apiV1SettingsDemoInformationGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DemoInformationResponse> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsDemoInformationGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns the Google Analytics Tracking ID stored in an environment variable
     */
    apiV1SettingsGATrackingIdGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetGATrackingIdResponse> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsGATrackingIdGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1SettingsKeycloakGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KeycloakSettingsVM> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsKeycloakGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get lims implementation names
     */
    apiV1SettingsLIMSNamesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsLIMSNamesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get if there is a lims implementation set
     */
    apiV1SettingsLIMSSetGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsLIMSSetGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the launchpad sites
     */
    apiV1SettingsLaunchpadSitesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LaunchpadSiteVM>> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsLaunchpadSitesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1SettingsLimsImplementationSetGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsLimsImplementationSetGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1SettingsLimsSyncUpGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsLimsSyncUpGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the MIA site
     */
    apiV1SettingsMIASiteGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LaunchpadSiteVM> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsMIASiteGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the Nextstrain site(Auspice) availability and url.
     */
    apiV1SettingsNextstrainSiteGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LaunchpadSiteVM> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsNextstrainSiteGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the current platform version
     */
    apiV1SettingsPlatformVersionGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetPlatformVersionResponse> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsPlatformVersionGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Polls for status of resetting the demo environment
     * @param pollToken 
     */
    apiV1SettingsPollResetStatusPost(params: { "pollToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PollResetStatusResponse> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsPollResetStatusPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Resets the demo environment
     */
    apiV1SettingsResetDemoEnvironmentPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResetEnvironmentResponse> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsResetDemoEnvironmentPost(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Sets the lims implementation value
     * @param limsImplementation 
     */
    apiV1SettingsSetLimsImplementationPost(params: { "limsImplementation"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsSetLimsImplementationPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get the system settings
     */
    apiV1SettingsSystemSettingsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: string; }> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsSystemSettingsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get the system settings
     * @param body 
     */
    apiV1SettingsSystemSettingsPost(params: { "body"?: any;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsSystemSettingsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return User Preferences
     */
    apiV1SettingsUserPreferencesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserPreferencesVM> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsUserPreferencesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return User Preferences
     * @param body 
     */
    apiV1SettingsUserPreferencesPost(params: { "body"?: UserPreferencesVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsUserPreferencesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the version info
     */
    apiV1SettingsVersionInfoGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetVersionInfoResponse> {
        const fetchArgs = SettingsApiFetchParamCreator.apiV1SettingsVersionInfoGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SettingsApi - object-oriented interface
 */
export class SettingsApi extends BaseAPI {
    /** 
     * Gets available hard drive space
     */
    apiV1SettingsAvailableSpaceGet(options?: any) {
        return SettingsApiFp.apiV1SettingsAvailableSpaceGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Return true if Demo environment
     */
    apiV1SettingsDemoExperienceGet(options?: any) {
        return SettingsApiFp.apiV1SettingsDemoExperienceGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Return remaining time on demo
     */
    apiV1SettingsDemoInformationGet(options?: any) {
        return SettingsApiFp.apiV1SettingsDemoInformationGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Returns the Google Analytics Tracking ID stored in an environment variable
     */
    apiV1SettingsGATrackingIdGet(options?: any) {
        return SettingsApiFp.apiV1SettingsGATrackingIdGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1SettingsKeycloakGet(options?: any) {
        return SettingsApiFp.apiV1SettingsKeycloakGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get lims implementation names
     */
    apiV1SettingsLIMSNamesGet(options?: any) {
        return SettingsApiFp.apiV1SettingsLIMSNamesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get if there is a lims implementation set
     */
    apiV1SettingsLIMSSetGet(options?: any) {
        return SettingsApiFp.apiV1SettingsLIMSSetGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the launchpad sites
     */
    apiV1SettingsLaunchpadSitesGet(options?: any) {
        return SettingsApiFp.apiV1SettingsLaunchpadSitesGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1SettingsLimsImplementationSetGet(options?: any) {
        return SettingsApiFp.apiV1SettingsLimsImplementationSetGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1SettingsLimsSyncUpGet(options?: any) {
        return SettingsApiFp.apiV1SettingsLimsSyncUpGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the MIA site
     */
    apiV1SettingsMIASiteGet(options?: any) {
        return SettingsApiFp.apiV1SettingsMIASiteGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the Nextstrain site(Auspice) availability and url.
     */
    apiV1SettingsNextstrainSiteGet(options?: any) {
        return SettingsApiFp.apiV1SettingsNextstrainSiteGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the current platform version
     */
    apiV1SettingsPlatformVersionGet(options?: any) {
        return SettingsApiFp.apiV1SettingsPlatformVersionGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Polls for status of resetting the demo environment
     * @param pollToken 
     */
    apiV1SettingsPollResetStatusPost(params: {  "pollToken"?: string; }, options?: any) {
        return SettingsApiFp.apiV1SettingsPollResetStatusPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Resets the demo environment
     */
    apiV1SettingsResetDemoEnvironmentPost(options?: any) {
        return SettingsApiFp.apiV1SettingsResetDemoEnvironmentPost(options)(this.fetch, this.basePath);
    }
    /** 
     * Sets the lims implementation value
     * @param limsImplementation 
     */
    apiV1SettingsSetLimsImplementationPost(params: {  "limsImplementation"?: string; }, options?: any) {
        return SettingsApiFp.apiV1SettingsSetLimsImplementationPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get the system settings
     */
    apiV1SettingsSystemSettingsGet(options?: any) {
        return SettingsApiFp.apiV1SettingsSystemSettingsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get the system settings
     * @param body 
     */
    apiV1SettingsSystemSettingsPost(params: {  "body"?: any; }, options?: any) {
        return SettingsApiFp.apiV1SettingsSystemSettingsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Return User Preferences
     */
    apiV1SettingsUserPreferencesGet(options?: any) {
        return SettingsApiFp.apiV1SettingsUserPreferencesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Return User Preferences
     * @param body 
     */
    apiV1SettingsUserPreferencesPost(params: {  "body"?: UserPreferencesVM; }, options?: any) {
        return SettingsApiFp.apiV1SettingsUserPreferencesPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the version info
     */
    apiV1SettingsVersionInfoGet(options?: any) {
        return SettingsApiFp.apiV1SettingsVersionInfoGet(options)(this.fetch, this.basePath);
    }
};

/**
 * SettingsApi - factory interface
 */
export const SettingsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets available hard drive space
         */
        apiV1SettingsAvailableSpaceGet(options?: any) {
            return SettingsApiFp.apiV1SettingsAvailableSpaceGet(options)(fetch, basePath);
        },
        /** 
         * Return true if Demo environment
         */
        apiV1SettingsDemoExperienceGet(options?: any) {
            return SettingsApiFp.apiV1SettingsDemoExperienceGet(options)(fetch, basePath);
        },
        /** 
         * Return remaining time on demo
         */
        apiV1SettingsDemoInformationGet(options?: any) {
            return SettingsApiFp.apiV1SettingsDemoInformationGet(options)(fetch, basePath);
        },
        /** 
         * Returns the Google Analytics Tracking ID stored in an environment variable
         */
        apiV1SettingsGATrackingIdGet(options?: any) {
            return SettingsApiFp.apiV1SettingsGATrackingIdGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1SettingsKeycloakGet(options?: any) {
            return SettingsApiFp.apiV1SettingsKeycloakGet(options)(fetch, basePath);
        },
        /** 
         * Get lims implementation names
         */
        apiV1SettingsLIMSNamesGet(options?: any) {
            return SettingsApiFp.apiV1SettingsLIMSNamesGet(options)(fetch, basePath);
        },
        /** 
         * Get if there is a lims implementation set
         */
        apiV1SettingsLIMSSetGet(options?: any) {
            return SettingsApiFp.apiV1SettingsLIMSSetGet(options)(fetch, basePath);
        },
        /** 
         * Gets the launchpad sites
         */
        apiV1SettingsLaunchpadSitesGet(options?: any) {
            return SettingsApiFp.apiV1SettingsLaunchpadSitesGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1SettingsLimsImplementationSetGet(options?: any) {
            return SettingsApiFp.apiV1SettingsLimsImplementationSetGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1SettingsLimsSyncUpGet(options?: any) {
            return SettingsApiFp.apiV1SettingsLimsSyncUpGet(options)(fetch, basePath);
        },
        /** 
         * Gets the MIA site
         */
        apiV1SettingsMIASiteGet(options?: any) {
            return SettingsApiFp.apiV1SettingsMIASiteGet(options)(fetch, basePath);
        },
        /** 
         * Gets the Nextstrain site(Auspice) availability and url.
         */
        apiV1SettingsNextstrainSiteGet(options?: any) {
            return SettingsApiFp.apiV1SettingsNextstrainSiteGet(options)(fetch, basePath);
        },
        /** 
         * Gets the current platform version
         */
        apiV1SettingsPlatformVersionGet(options?: any) {
            return SettingsApiFp.apiV1SettingsPlatformVersionGet(options)(fetch, basePath);
        },
        /** 
         * Polls for status of resetting the demo environment
         * @param pollToken 
         */
        apiV1SettingsPollResetStatusPost(params: {  "pollToken"?: string; }, options?: any) {
            return SettingsApiFp.apiV1SettingsPollResetStatusPost(params, options)(fetch, basePath);
        },
        /** 
         * Resets the demo environment
         */
        apiV1SettingsResetDemoEnvironmentPost(options?: any) {
            return SettingsApiFp.apiV1SettingsResetDemoEnvironmentPost(options)(fetch, basePath);
        },
        /** 
         * Sets the lims implementation value
         * @param limsImplementation 
         */
        apiV1SettingsSetLimsImplementationPost(params: {  "limsImplementation"?: string; }, options?: any) {
            return SettingsApiFp.apiV1SettingsSetLimsImplementationPost(params, options)(fetch, basePath);
        },
        /** 
         * Get the system settings
         */
        apiV1SettingsSystemSettingsGet(options?: any) {
            return SettingsApiFp.apiV1SettingsSystemSettingsGet(options)(fetch, basePath);
        },
        /** 
         * Get the system settings
         * @param body 
         */
        apiV1SettingsSystemSettingsPost(params: {  "body"?: any; }, options?: any) {
            return SettingsApiFp.apiV1SettingsSystemSettingsPost(params, options)(fetch, basePath);
        },
        /** 
         * Return User Preferences
         */
        apiV1SettingsUserPreferencesGet(options?: any) {
            return SettingsApiFp.apiV1SettingsUserPreferencesGet(options)(fetch, basePath);
        },
        /** 
         * Return User Preferences
         * @param body 
         */
        apiV1SettingsUserPreferencesPost(params: {  "body"?: UserPreferencesVM; }, options?: any) {
            return SettingsApiFp.apiV1SettingsUserPreferencesPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets the version info
         */
        apiV1SettingsVersionInfoGet(options?: any) {
            return SettingsApiFp.apiV1SettingsVersionInfoGet(options)(fetch, basePath);
        },
    };
};


/**
 * StepApi - fetch parameter creator
 */
export const StepApiFetchParamCreator = {
    /** 
     * Update Custom Fields on a step instance. Takes a stepInstanceId and a list of custom fields and applies changes to the step instance.
     * @param stepInstanceId 
     * @param body 
     */
    apiV1StepStepInstanceIdCustomFieldsPatch(params: {  "stepInstanceId": string; "body"?: Array<CustomFieldVM>; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1StepStepInstanceIdCustomFieldsPatch");
        }
        const baseUrl = `/api/v1/Step/{stepInstanceId}/CustomFields`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * StepApi - functional programming interface
 */
export const StepApiFp = {
    /** 
     * Update Custom Fields on a step instance. Takes a stepInstanceId and a list of custom fields and applies changes to the step instance.
     * @param stepInstanceId 
     * @param body 
     */
    apiV1StepStepInstanceIdCustomFieldsPatch(params: { "stepInstanceId": string; "body"?: Array<CustomFieldVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StepApiFetchParamCreator.apiV1StepStepInstanceIdCustomFieldsPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * StepApi - object-oriented interface
 */
export class StepApi extends BaseAPI {
    /** 
     * Update Custom Fields on a step instance. Takes a stepInstanceId and a list of custom fields and applies changes to the step instance.
     * @param stepInstanceId 
     * @param body 
     */
    apiV1StepStepInstanceIdCustomFieldsPatch(params: {  "stepInstanceId": string; "body"?: Array<CustomFieldVM>; }, options?: any) {
        return StepApiFp.apiV1StepStepInstanceIdCustomFieldsPatch(params, options)(this.fetch, this.basePath);
    }
};

/**
 * StepApi - factory interface
 */
export const StepApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Update Custom Fields on a step instance. Takes a stepInstanceId and a list of custom fields and applies changes to the step instance.
         * @param stepInstanceId 
         * @param body 
         */
        apiV1StepStepInstanceIdCustomFieldsPatch(params: {  "stepInstanceId": string; "body"?: Array<CustomFieldVM>; }, options?: any) {
            return StepApiFp.apiV1StepStepInstanceIdCustomFieldsPatch(params, options)(fetch, basePath);
        },
    };
};


/**
 * TestOrderApi - fetch parameter creator
 */
export const TestOrderApiFetchParamCreator = {
    /** 
     * Gets a list of Test Orders
     */
    apiV1TestOrderGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/TestOrder`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TestOrderApi - functional programming interface
 */
export const TestOrderApiFp = {
    /** 
     * Gets a list of Test Orders
     */
    apiV1TestOrderGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TestOrderVM>> {
        const fetchArgs = TestOrderApiFetchParamCreator.apiV1TestOrderGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TestOrderApi - object-oriented interface
 */
export class TestOrderApi extends BaseAPI {
    /** 
     * Gets a list of Test Orders
     */
    apiV1TestOrderGet(options?: any) {
        return TestOrderApiFp.apiV1TestOrderGet(options)(this.fetch, this.basePath);
    }
};

/**
 * TestOrderApi - factory interface
 */
export const TestOrderApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of Test Orders
         */
        apiV1TestOrderGet(options?: any) {
            return TestOrderApiFp.apiV1TestOrderGet(options)(fetch, basePath);
        },
    };
};


/**
 * WellContentApi - fetch parameter creator
 */
export const WellContentApiFetchParamCreator = {
    /** 
     * Save concentration data for wells in quantification steps
     * @param body list of concentration save requests
     */
    apiV1WellContentUpdateConcentrationDataPost(params: {  "body"?: Array<UpdateConcentrationRequestVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WellContent/UpdateConcentrationData`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save concentration and passFail data
     * @param assetToSaveResultsToId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WellContentUpdateRtPCRStatePost(params: {  "assetToSaveResultsToId"?: string; "workflowRunId"?: string; "body"?: Array<RtPCRResultVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WellContent/UpdateRtPCRState`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "assetToSaveResultsToId": params["assetToSaveResultsToId"],
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * WellContentApi - functional programming interface
 */
export const WellContentApiFp = {
    /** 
     * Save concentration data for wells in quantification steps
     * @param body list of concentration save requests
     */
    apiV1WellContentUpdateConcentrationDataPost(params: { "body"?: Array<UpdateConcentrationRequestVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = WellContentApiFetchParamCreator.apiV1WellContentUpdateConcentrationDataPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save concentration and passFail data
     * @param assetToSaveResultsToId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WellContentUpdateRtPCRStatePost(params: { "assetToSaveResultsToId"?: string; "workflowRunId"?: string; "body"?: Array<RtPCRResultVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = WellContentApiFetchParamCreator.apiV1WellContentUpdateRtPCRStatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * WellContentApi - object-oriented interface
 */
export class WellContentApi extends BaseAPI {
    /** 
     * Save concentration data for wells in quantification steps
     * @param body list of concentration save requests
     */
    apiV1WellContentUpdateConcentrationDataPost(params: {  "body"?: Array<UpdateConcentrationRequestVM>; }, options?: any) {
        return WellContentApiFp.apiV1WellContentUpdateConcentrationDataPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save concentration and passFail data
     * @param assetToSaveResultsToId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WellContentUpdateRtPCRStatePost(params: {  "assetToSaveResultsToId"?: string; "workflowRunId"?: string; "body"?: Array<RtPCRResultVM>; }, options?: any) {
        return WellContentApiFp.apiV1WellContentUpdateRtPCRStatePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * WellContentApi - factory interface
 */
export const WellContentApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Save concentration data for wells in quantification steps
         * @param body list of concentration save requests
         */
        apiV1WellContentUpdateConcentrationDataPost(params: {  "body"?: Array<UpdateConcentrationRequestVM>; }, options?: any) {
            return WellContentApiFp.apiV1WellContentUpdateConcentrationDataPost(params, options)(fetch, basePath);
        },
        /** 
         * Save concentration and passFail data
         * @param assetToSaveResultsToId 
         * @param workflowRunId 
         * @param body 
         */
        apiV1WellContentUpdateRtPCRStatePost(params: {  "assetToSaveResultsToId"?: string; "workflowRunId"?: string; "body"?: Array<RtPCRResultVM>; }, options?: any) {
            return WellContentApiFp.apiV1WellContentUpdateRtPCRStatePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * WorkflowApi - fetch parameter creator
 */
export const WorkflowApiFetchParamCreator = {
    /** 
     * Generate workflow run report
     * @param runNumber 
     */
    apiV1WorkflowGeneratePdfReportRunNumberPost(params: {  "runNumber": string; }, options?: any): FetchArgs {
        // verify required parameter "runNumber" is set
        if (params["runNumber"] == null) {
            throw new Error("Missing required parameter runNumber when calling apiV1WorkflowGeneratePdfReportRunNumberPost");
        }
        const baseUrl = `/api/v1/Workflow/GeneratePdfReport/{runNumber}`
            .replace(`{${"runNumber"}}`, `${ params["runNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1WorkflowGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Workflow`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param testId 
     */
    apiV1WorkflowGetSamplesTestIdGet(params: {  "testId": string; }, options?: any): FetchArgs {
        // verify required parameter "testId" is set
        if (params["testId"] == null) {
            throw new Error("Missing required parameter testId when calling apiV1WorkflowGetSamplesTestIdGet");
        }
        const baseUrl = `/api/v1/Workflow/GetSamples/{TestId}`
            .replace(`{${"TestId"}}`, `${ params["testId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of initiation points for a workflow
     * @param workflowId 
     * @param wellMaterial 
     */
    apiV1WorkflowGetWorkflowInitiationPointsGet(params: {  "workflowId"?: string; "wellMaterial"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Workflow/GetWorkflowInitiationPoints`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowId": params["workflowId"],
            "wellMaterial": params["wellMaterial"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get the workflow metrics for the Performance page
     */
    apiV1WorkflowGetWorkflowMetricsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Workflow/GetWorkflowMetrics`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1WorkflowUpdateSamplePriorityPost(params: {  "body"?: UpdateSamplePriorityRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/Workflow/UpdateSamplePriority`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of initiation points for a workflow
     * @param workflowId 
     */
    apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet(params: {  "workflowId": string; }, options?: any): FetchArgs {
        // verify required parameter "workflowId" is set
        if (params["workflowId"] == null) {
            throw new Error("Missing required parameter workflowId when calling apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet");
        }
        const baseUrl = `/api/v1/Workflow/WorkflowPTCAndNTCCheck/{workflowId}`
            .replace(`{${"workflowId"}}`, `${ params["workflowId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * WorkflowApi - functional programming interface
 */
export const WorkflowApiFp = {
    /** 
     * Generate workflow run report
     * @param runNumber 
     */
    apiV1WorkflowGeneratePdfReportRunNumberPost(params: { "runNumber": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowApiFetchParamCreator.apiV1WorkflowGeneratePdfReportRunNumberPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1WorkflowGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkflowVM>> {
        const fetchArgs = WorkflowApiFetchParamCreator.apiV1WorkflowGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param testId 
     */
    apiV1WorkflowGetSamplesTestIdGet(params: { "testId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SampleVM>> {
        const fetchArgs = WorkflowApiFetchParamCreator.apiV1WorkflowGetSamplesTestIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of initiation points for a workflow
     * @param workflowId 
     * @param wellMaterial 
     */
    apiV1WorkflowGetWorkflowInitiationPointsGet(params: { "workflowId"?: string; "wellMaterial"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkflowStepWithInitiationPointVM>> {
        const fetchArgs = WorkflowApiFetchParamCreator.apiV1WorkflowGetWorkflowInitiationPointsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get the workflow metrics for the Performance page
     */
    apiV1WorkflowGetWorkflowMetricsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkflowMetricVM>> {
        const fetchArgs = WorkflowApiFetchParamCreator.apiV1WorkflowGetWorkflowMetricsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1WorkflowUpdateSamplePriorityPost(params: { "body"?: UpdateSamplePriorityRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowApiFetchParamCreator.apiV1WorkflowUpdateSamplePriorityPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of initiation points for a workflow
     * @param workflowId 
     */
    apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet(params: { "workflowId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WorkflowPTCAndNTCCheck> {
        const fetchArgs = WorkflowApiFetchParamCreator.apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * WorkflowApi - object-oriented interface
 */
export class WorkflowApi extends BaseAPI {
    /** 
     * Generate workflow run report
     * @param runNumber 
     */
    apiV1WorkflowGeneratePdfReportRunNumberPost(params: {  "runNumber": string; }, options?: any) {
        return WorkflowApiFp.apiV1WorkflowGeneratePdfReportRunNumberPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1WorkflowGet(options?: any) {
        return WorkflowApiFp.apiV1WorkflowGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param testId 
     */
    apiV1WorkflowGetSamplesTestIdGet(params: {  "testId": string; }, options?: any) {
        return WorkflowApiFp.apiV1WorkflowGetSamplesTestIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of initiation points for a workflow
     * @param workflowId 
     * @param wellMaterial 
     */
    apiV1WorkflowGetWorkflowInitiationPointsGet(params: {  "workflowId"?: string; "wellMaterial"?: string; }, options?: any) {
        return WorkflowApiFp.apiV1WorkflowGetWorkflowInitiationPointsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get the workflow metrics for the Performance page
     */
    apiV1WorkflowGetWorkflowMetricsGet(options?: any) {
        return WorkflowApiFp.apiV1WorkflowGetWorkflowMetricsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1WorkflowUpdateSamplePriorityPost(params: {  "body"?: UpdateSamplePriorityRequestVM; }, options?: any) {
        return WorkflowApiFp.apiV1WorkflowUpdateSamplePriorityPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of initiation points for a workflow
     * @param workflowId 
     */
    apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet(params: {  "workflowId": string; }, options?: any) {
        return WorkflowApiFp.apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * WorkflowApi - factory interface
 */
export const WorkflowApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Generate workflow run report
         * @param runNumber 
         */
        apiV1WorkflowGeneratePdfReportRunNumberPost(params: {  "runNumber": string; }, options?: any) {
            return WorkflowApiFp.apiV1WorkflowGeneratePdfReportRunNumberPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1WorkflowGet(options?: any) {
            return WorkflowApiFp.apiV1WorkflowGet(options)(fetch, basePath);
        },
        /** 
         * @param testId 
         */
        apiV1WorkflowGetSamplesTestIdGet(params: {  "testId": string; }, options?: any) {
            return WorkflowApiFp.apiV1WorkflowGetSamplesTestIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of initiation points for a workflow
         * @param workflowId 
         * @param wellMaterial 
         */
        apiV1WorkflowGetWorkflowInitiationPointsGet(params: {  "workflowId"?: string; "wellMaterial"?: string; }, options?: any) {
            return WorkflowApiFp.apiV1WorkflowGetWorkflowInitiationPointsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get the workflow metrics for the Performance page
         */
        apiV1WorkflowGetWorkflowMetricsGet(options?: any) {
            return WorkflowApiFp.apiV1WorkflowGetWorkflowMetricsGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1WorkflowUpdateSamplePriorityPost(params: {  "body"?: UpdateSamplePriorityRequestVM; }, options?: any) {
            return WorkflowApiFp.apiV1WorkflowUpdateSamplePriorityPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of initiation points for a workflow
         * @param workflowId 
         */
        apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet(params: {  "workflowId": string; }, options?: any) {
            return WorkflowApiFp.apiV1WorkflowWorkflowPTCAndNTCCheckWorkflowIdGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * WorkflowConfigApi - fetch parameter creator
 */
export const WorkflowConfigApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1WorkflowConfigAddComponentSetPost(params: {  "body"?: ComponentSetVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowConfig/AddComponentSet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param type 
     */
    apiV1WorkflowConfigComponentSetGet(params: {  "type"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowConfig/ComponentSet`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "type": params["type"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1WorkflowConfigStepRequirementsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowConfig/StepRequirements`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1WorkflowConfigUpdateComponentSetPost(params: {  "body"?: ComponentSetVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowConfig/UpdateComponentSet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1WorkflowConfigUpdateStepRequirementPost(params: {  "body"?: StepRequirementVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowConfig/UpdateStepRequirement`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * WorkflowConfigApi - functional programming interface
 */
export const WorkflowConfigApiFp = {
    /** 
     * @param body 
     */
    apiV1WorkflowConfigAddComponentSetPost(params: { "body"?: ComponentSetVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowConfigApiFetchParamCreator.apiV1WorkflowConfigAddComponentSetPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param type 
     */
    apiV1WorkflowConfigComponentSetGet(params: { "type"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ComponentSetVM>> {
        const fetchArgs = WorkflowConfigApiFetchParamCreator.apiV1WorkflowConfigComponentSetGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1WorkflowConfigStepRequirementsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StepRequirementGroupVM>> {
        const fetchArgs = WorkflowConfigApiFetchParamCreator.apiV1WorkflowConfigStepRequirementsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1WorkflowConfigUpdateComponentSetPost(params: { "body"?: ComponentSetVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowConfigApiFetchParamCreator.apiV1WorkflowConfigUpdateComponentSetPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1WorkflowConfigUpdateStepRequirementPost(params: { "body"?: StepRequirementVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowConfigApiFetchParamCreator.apiV1WorkflowConfigUpdateStepRequirementPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * WorkflowConfigApi - object-oriented interface
 */
export class WorkflowConfigApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1WorkflowConfigAddComponentSetPost(params: {  "body"?: ComponentSetVM; }, options?: any) {
        return WorkflowConfigApiFp.apiV1WorkflowConfigAddComponentSetPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param type 
     */
    apiV1WorkflowConfigComponentSetGet(params: {  "type"?: string; }, options?: any) {
        return WorkflowConfigApiFp.apiV1WorkflowConfigComponentSetGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1WorkflowConfigStepRequirementsGet(options?: any) {
        return WorkflowConfigApiFp.apiV1WorkflowConfigStepRequirementsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1WorkflowConfigUpdateComponentSetPost(params: {  "body"?: ComponentSetVM; }, options?: any) {
        return WorkflowConfigApiFp.apiV1WorkflowConfigUpdateComponentSetPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1WorkflowConfigUpdateStepRequirementPost(params: {  "body"?: StepRequirementVM; }, options?: any) {
        return WorkflowConfigApiFp.apiV1WorkflowConfigUpdateStepRequirementPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * WorkflowConfigApi - factory interface
 */
export const WorkflowConfigApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1WorkflowConfigAddComponentSetPost(params: {  "body"?: ComponentSetVM; }, options?: any) {
            return WorkflowConfigApiFp.apiV1WorkflowConfigAddComponentSetPost(params, options)(fetch, basePath);
        },
        /** 
         * @param type 
         */
        apiV1WorkflowConfigComponentSetGet(params: {  "type"?: string; }, options?: any) {
            return WorkflowConfigApiFp.apiV1WorkflowConfigComponentSetGet(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1WorkflowConfigStepRequirementsGet(options?: any) {
            return WorkflowConfigApiFp.apiV1WorkflowConfigStepRequirementsGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1WorkflowConfigUpdateComponentSetPost(params: {  "body"?: ComponentSetVM; }, options?: any) {
            return WorkflowConfigApiFp.apiV1WorkflowConfigUpdateComponentSetPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1WorkflowConfigUpdateStepRequirementPost(params: {  "body"?: StepRequirementVM; }, options?: any) {
            return WorkflowConfigApiFp.apiV1WorkflowConfigUpdateStepRequirementPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * WorkflowRunApi - fetch parameter creator
 */
export const WorkflowRunApiFetchParamCreator = {
    /** 
     * Add a new instrument
     * @param instrument 
     */
    apiV1WorkflowRunAddInstrumentPost(params: {  "instrument"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/AddInstrument`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "instrument": params["instrument"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Standardized method for completing a step instance. This method only handles the step completion process that is standard to all steps, any handling that is specific to a given step will   require additional API calls.
     * @param body 
     */
    apiV1WorkflowRunCompleteStepPost(params: {  "body"?: CompleteStepRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/completeStep`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Completes the workflow
     * @param errored did the pipeline error?
     * @param body 
     */
    apiV1WorkflowRunCompleteWorkflowPost(params: {  "errored"?: boolean; "body"?: CompleteStepRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/completeWorkflow`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "errored": params["errored"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create a note record
     * @param stepInstanceId step instance to associate note with
     * @param userId user who created the note
     * @param remarks the note
     */
    apiV1WorkflowRunCreateNotePost(params: {  "stepInstanceId"?: string; "userId"?: string; "remarks"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/CreateNote`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "stepInstanceId": params["stepInstanceId"],
            "userId": params["userId"],
            "remarks": params["remarks"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a workflow run from an archived plate
     * @param workflowId 
     * @param stepId 
     * @param archivedPlateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WorkflowRunCreateWorkflowFromArchivedPlatePost(params: {  "workflowId"?: string; "stepId"?: string; "archivedPlateId"?: string; "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/CreateWorkflowFromArchivedPlate`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowId": params["workflowId"],
            "stepId": params["stepId"],
            "archivedPlateId": params["archivedPlateId"],
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a new WorkflowRun
     * @param workflowId 
     * @param plateMaxLoad 
     */
    apiV1WorkflowRunCreateWorkflowMaxLoadPost(params: {  "workflowId"?: string; "plateMaxLoad"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/CreateWorkflowMaxLoad`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowId": params["workflowId"],
            "plateMaxLoad": params["plateMaxLoad"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowRunId 
     */
    apiV1WorkflowRunDiscardRetainedPlatesPost(params: {  "workflowRunId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/discardRetainedPlates`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowRunId 
     * @param runHash 
     */
    apiV1WorkflowRunExportBugSeqCsvExtendedPost(params: {  "workflowRunId"?: string; "runHash"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/ExportBugSeqCsv/extended`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
            "runHash": params["runHash"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowRunId 
     * @param runHash 
     */
    apiV1WorkflowRunExportBugSeqCsvPost(params: {  "workflowRunId"?: string; "runHash"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/ExportBugSeqCsv`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
            "runHash": params["runHash"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Fails the run
     * @param stepInstanceId 
     * @param userId 
     * @param remarks 
     * @param workflowRunId 
     * @param plateId 
     */
    apiV1WorkflowRunFailRunPost(params: {  "stepInstanceId"?: string; "userId"?: string; "remarks"?: string; "workflowRunId"?: string; "plateId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/FailRun`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "stepInstanceId": params["stepInstanceId"],
            "userId": params["userId"],
            "remarks": params["remarks"],
            "WorkflowRunId": params["workflowRunId"],
            "plateId": params["plateId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Generate Illumina Sample Sheet
     * @param workflowRunId 
     * @param stepInstanceId 
     * @param isCOV2 
     */
    apiV1WorkflowRunGenerateIlluminaSampleSheetGet(params: {  "workflowRunId"?: string; "stepInstanceId"?: string; "isCOV2"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/GenerateIlluminaSampleSheet`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
            "stepInstanceId": params["stepInstanceId"],
            "isCOV2": params["isCOV2"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the Concentration Controls for Quantification
     */
    apiV1WorkflowRunGetConcentrationControlKitsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/GetConcentrationControlKits`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets custom field data for a single step
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetCustomFieldData/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the reagents
     */
    apiV1WorkflowRunGetExtractionReagentsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/GetExtractionReagents`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the workflow run users
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetRunUsersGet(params: {  "workflowRunId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/GetRunUsers`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the Sequencing data for a workflow run
     */
    apiV1WorkflowRunGetSequencingDataGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/GetSequencingData`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the Sequencing data for a workflow run
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any): FetchArgs {
        // verify required parameter "workflowRunId" is set
        if (params["workflowRunId"] == null) {
            throw new Error("Missing required parameter workflowRunId when calling apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetSequencingData/{workflowRunId}`
            .replace(`{${"workflowRunId"}}`, `${ params["workflowRunId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return ComponentSet Data
     * @param stepInstanceId 
     * @param workflowRunId 
     * @param type 
     */
    apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; "type"?: string; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetStepInstanceComponentSetData/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
            "type": params["type"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return controls count of step instance
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetStepInstanceControlsByControlType/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Return PCR Data
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetStepInstancePCRData/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any): FetchArgs {
        // verify required parameter "workflowRunId" is set
        if (params["workflowRunId"] == null) {
            throw new Error("Missing required parameter workflowRunId when calling apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetStepInstances/{WorkflowRunId}`
            .replace(`{${"WorkflowRunId"}}`, `${ params["workflowRunId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of Workflow Run Instrument Info
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any): FetchArgs {
        // verify required parameter "page" is set
        if (params["page"] == null) {
            throw new Error("Missing required parameter page when calling apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetWorkflowRunInstrumentInfo/{page}`
            .replace(`{${"page"}}`, `${ params["page"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "pageSize": params["pageSize"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of Workflow Runs
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunsPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any): FetchArgs {
        // verify required parameter "page" is set
        if (params["page"] == null) {
            throw new Error("Missing required parameter page when calling apiV1WorkflowRunGetWorkflowRunsPageGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetWorkflowRuns/{page}`
            .replace(`{${"page"}}`, `${ params["page"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "pageSize": params["pageSize"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a list of Workflow Runs
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any): FetchArgs {
        // verify required parameter "page" is set
        if (params["page"] == null) {
            throw new Error("Missing required parameter page when calling apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/GetWorkflowRunsWithMetadata/{page}`
            .replace(`{${"page"}}`, `${ params["page"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "pageSize": params["pageSize"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a specific workflow Run.
     * @param id The id of the Run to return
     */
    apiV1WorkflowRunIdGet(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1WorkflowRunIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of instrument options
     */
    apiV1WorkflowRunInstrumentOptionsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/InstrumentOptions`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Release a Workflow Run
     * @param workflowRunId 
     * @param releaseRun 
     */
    apiV1WorkflowRunReleaseRunPost(params: {  "workflowRunId"?: string; "releaseRun"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/ReleaseRun`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
            "releaseRun": params["releaseRun"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Reopens a run from a specific step.
     * @param stepId 
     * @param assetId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WorkflowRunReopenRunFromStepPost(params: {  "stepId"?: string; "assetId"?: string; "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/ReopenRunFromStep`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "stepId": params["stepId"],
            "assetId": params["assetId"],
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the Workflow Run Metadata json
     * @param workflowRunId 
     */
    apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any): FetchArgs {
        // verify required parameter "workflowRunId" is set
        if (params["workflowRunId"] == null) {
            throw new Error("Missing required parameter workflowRunId when calling apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/RunMetadataData/{workflowRunId}`
            .replace(`{${"workflowRunId"}}`, `${ params["workflowRunId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save a a list of ComponentSets to a step.
     * @param workflowRunId 
     * @param addUsedLots 
     * @param body 
     */
    apiV1WorkflowRunSaveComponentSetsPut(params: {  "workflowRunId"?: string; "addUsedLots"?: boolean; "body"?: Array<ComponentSetVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/SaveComponentSets`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
            "addUsedLots": params["addUsedLots"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a specific workflow run&#39;s sequencing status.
     * @param id The id of the Run to check
     */
    apiV1WorkflowRunSequencingStatusIdGet(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1WorkflowRunSequencingStatusIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRun/sequencingStatus/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Sets the passed in user as the current run user
     * @param body 
     */
    apiV1WorkflowRunSetCurrentRunUserPost(params: {  "body"?: RunUserVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/SetCurrentRunUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param workflowRunId 
     */
    apiV1WorkflowRunUnlockRunPost(params: {  "workflowRunId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/UnlockRun`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates workflow run sequencing status
     * @param workflowRunId 
     * @param newStatus 
     */
    apiV1WorkflowRunUpdateWorkflowRunSequencingStatusPost(params: {  "workflowRunId"?: string; "newStatus"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRun/UpdateWorkflowRunSequencingStatus`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
            "newStatus": params["newStatus"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * WorkflowRunApi - functional programming interface
 */
export const WorkflowRunApiFp = {
    /** 
     * Add a new instrument
     * @param instrument 
     */
    apiV1WorkflowRunAddInstrumentPost(params: { "instrument"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunAddInstrumentPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Standardized method for completing a step instance. This method only handles the step completion process that is standard to all steps, any handling that is specific to a given step will   require additional API calls.
     * @param body 
     */
    apiV1WorkflowRunCompleteStepPost(params: { "body"?: CompleteStepRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StepCompleteResponse> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunCompleteStepPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Completes the workflow
     * @param errored did the pipeline error?
     * @param body 
     */
    apiV1WorkflowRunCompleteWorkflowPost(params: { "errored"?: boolean; "body"?: CompleteStepRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunCompleteWorkflowPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create a note record
     * @param stepInstanceId step instance to associate note with
     * @param userId user who created the note
     * @param remarks the note
     */
    apiV1WorkflowRunCreateNotePost(params: { "stepInstanceId"?: string; "userId"?: string; "remarks"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunCreateNotePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a workflow run from an archived plate
     * @param workflowId 
     * @param stepId 
     * @param archivedPlateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WorkflowRunCreateWorkflowFromArchivedPlatePost(params: { "workflowId"?: string; "stepId"?: string; "archivedPlateId"?: string; "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WetlabRunVM> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunCreateWorkflowFromArchivedPlatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a new WorkflowRun
     * @param workflowId 
     * @param plateMaxLoad 
     */
    apiV1WorkflowRunCreateWorkflowMaxLoadPost(params: { "workflowId"?: string; "plateMaxLoad"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WetlabRunVM> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunCreateWorkflowMaxLoadPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowRunId 
     */
    apiV1WorkflowRunDiscardRetainedPlatesPost(params: { "workflowRunId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunDiscardRetainedPlatesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowRunId 
     * @param runHash 
     */
    apiV1WorkflowRunExportBugSeqCsvExtendedPost(params: { "workflowRunId"?: string; "runHash"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunExportBugSeqCsvExtendedPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowRunId 
     * @param runHash 
     */
    apiV1WorkflowRunExportBugSeqCsvPost(params: { "workflowRunId"?: string; "runHash"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunExportBugSeqCsvPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Fails the run
     * @param stepInstanceId 
     * @param userId 
     * @param remarks 
     * @param workflowRunId 
     * @param plateId 
     */
    apiV1WorkflowRunFailRunPost(params: { "stepInstanceId"?: string; "userId"?: string; "remarks"?: string; "workflowRunId"?: string; "plateId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunFailRunPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Generate Illumina Sample Sheet
     * @param workflowRunId 
     * @param stepInstanceId 
     * @param isCOV2 
     */
    apiV1WorkflowRunGenerateIlluminaSampleSheetGet(params: { "workflowRunId"?: string; "stepInstanceId"?: string; "isCOV2"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IlluminaSampleSheetVM> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGenerateIlluminaSampleSheetGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the Concentration Controls for Quantification
     */
    apiV1WorkflowRunGetConcentrationControlKitsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LookupValueVM>> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetConcentrationControlKitsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets custom field data for a single step
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet(params: { "stepInstanceId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: string; }> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the reagents
     */
    apiV1WorkflowRunGetExtractionReagentsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetExtractionReagentsResponse> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetExtractionReagentsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the workflow run users
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetRunUsersGet(params: { "workflowRunId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RunUserVM>> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetRunUsersGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the Sequencing data for a workflow run
     */
    apiV1WorkflowRunGetSequencingDataGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: Array<SequencingDataVM>; }> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetSequencingDataGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the Sequencing data for a workflow run
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet(params: { "workflowRunId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SequencingDataVM>> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return ComponentSet Data
     * @param stepInstanceId 
     * @param workflowRunId 
     * @param type 
     */
    apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet(params: { "stepInstanceId": string; "workflowRunId"?: string; "type"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ComponentSetVM>> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return controls count of step instance
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet(params: { "stepInstanceId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StepInstanceControlsCountVM>> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Return PCR Data
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet(params: { "stepInstanceId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PCRControlInfoVM> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet(params: { "workflowRunId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StepInstanceVM>> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of Workflow Run Instrument Info
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet(params: { "page": number; "pageSize"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetWetlabRunInstrumentInfoResponse> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of Workflow Runs
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunsPageGet(params: { "page": number; "pageSize"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetWetlabRunsResponse> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetWorkflowRunsPageGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a list of Workflow Runs
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet(params: { "page": number; "pageSize"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetWetlabRunsResponse> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a specific workflow Run.
     * @param id The id of the Run to return
     */
    apiV1WorkflowRunIdGet(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WetlabRunVM> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of instrument options
     */
    apiV1WorkflowRunInstrumentOptionsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunInstrumentOptionsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Release a Workflow Run
     * @param workflowRunId 
     * @param releaseRun 
     */
    apiV1WorkflowRunReleaseRunPost(params: { "workflowRunId"?: string; "releaseRun"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunReleaseRunPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Reopens a run from a specific step.
     * @param stepId 
     * @param assetId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WorkflowRunReopenRunFromStepPost(params: { "stepId"?: string; "assetId"?: string; "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WetlabRunVM> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunReopenRunFromStepPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the Workflow Run Metadata json
     * @param workflowRunId 
     */
    apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet(params: { "workflowRunId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save a a list of ComponentSets to a step.
     * @param workflowRunId 
     * @param addUsedLots 
     * @param body 
     */
    apiV1WorkflowRunSaveComponentSetsPut(params: { "workflowRunId"?: string; "addUsedLots"?: boolean; "body"?: Array<ComponentSetVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunSaveComponentSetsPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a specific workflow run&#39;s sequencing status.
     * @param id The id of the Run to check
     */
    apiV1WorkflowRunSequencingStatusIdGet(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetSequencingStatusResponse> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunSequencingStatusIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Sets the passed in user as the current run user
     * @param body 
     */
    apiV1WorkflowRunSetCurrentRunUserPost(params: { "body"?: RunUserVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunSetCurrentRunUserPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param workflowRunId 
     */
    apiV1WorkflowRunUnlockRunPost(params: { "workflowRunId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunUnlockRunPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates workflow run sequencing status
     * @param workflowRunId 
     * @param newStatus 
     */
    apiV1WorkflowRunUpdateWorkflowRunSequencingStatusPost(params: { "workflowRunId"?: string; "newStatus"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunApiFetchParamCreator.apiV1WorkflowRunUpdateWorkflowRunSequencingStatusPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * WorkflowRunApi - object-oriented interface
 */
export class WorkflowRunApi extends BaseAPI {
    /** 
     * Add a new instrument
     * @param instrument 
     */
    apiV1WorkflowRunAddInstrumentPost(params: {  "instrument"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunAddInstrumentPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Standardized method for completing a step instance. This method only handles the step completion process that is standard to all steps, any handling that is specific to a given step will   require additional API calls.
     * @param body 
     */
    apiV1WorkflowRunCompleteStepPost(params: {  "body"?: CompleteStepRequestVM; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunCompleteStepPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Completes the workflow
     * @param errored did the pipeline error?
     * @param body 
     */
    apiV1WorkflowRunCompleteWorkflowPost(params: {  "errored"?: boolean; "body"?: CompleteStepRequestVM; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunCompleteWorkflowPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create a note record
     * @param stepInstanceId step instance to associate note with
     * @param userId user who created the note
     * @param remarks the note
     */
    apiV1WorkflowRunCreateNotePost(params: {  "stepInstanceId"?: string; "userId"?: string; "remarks"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunCreateNotePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a workflow run from an archived plate
     * @param workflowId 
     * @param stepId 
     * @param archivedPlateId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WorkflowRunCreateWorkflowFromArchivedPlatePost(params: {  "workflowId"?: string; "stepId"?: string; "archivedPlateId"?: string; "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunCreateWorkflowFromArchivedPlatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a new WorkflowRun
     * @param workflowId 
     * @param plateMaxLoad 
     */
    apiV1WorkflowRunCreateWorkflowMaxLoadPost(params: {  "workflowId"?: string; "plateMaxLoad"?: number; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunCreateWorkflowMaxLoadPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowRunId 
     */
    apiV1WorkflowRunDiscardRetainedPlatesPost(params: {  "workflowRunId"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunDiscardRetainedPlatesPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowRunId 
     * @param runHash 
     */
    apiV1WorkflowRunExportBugSeqCsvExtendedPost(params: {  "workflowRunId"?: string; "runHash"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunExportBugSeqCsvExtendedPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowRunId 
     * @param runHash 
     */
    apiV1WorkflowRunExportBugSeqCsvPost(params: {  "workflowRunId"?: string; "runHash"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunExportBugSeqCsvPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Fails the run
     * @param stepInstanceId 
     * @param userId 
     * @param remarks 
     * @param workflowRunId 
     * @param plateId 
     */
    apiV1WorkflowRunFailRunPost(params: {  "stepInstanceId"?: string; "userId"?: string; "remarks"?: string; "workflowRunId"?: string; "plateId"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunFailRunPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Generate Illumina Sample Sheet
     * @param workflowRunId 
     * @param stepInstanceId 
     * @param isCOV2 
     */
    apiV1WorkflowRunGenerateIlluminaSampleSheetGet(params: {  "workflowRunId"?: string; "stepInstanceId"?: string; "isCOV2"?: boolean; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGenerateIlluminaSampleSheetGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the Concentration Controls for Quantification
     */
    apiV1WorkflowRunGetConcentrationControlKitsGet(options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetConcentrationControlKitsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets custom field data for a single step
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the reagents
     */
    apiV1WorkflowRunGetExtractionReagentsGet(options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetExtractionReagentsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the workflow run users
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetRunUsersGet(params: {  "workflowRunId"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetRunUsersGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the Sequencing data for a workflow run
     */
    apiV1WorkflowRunGetSequencingDataGet(options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetSequencingDataGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the Sequencing data for a workflow run
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Return ComponentSet Data
     * @param stepInstanceId 
     * @param workflowRunId 
     * @param type 
     */
    apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; "type"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Return controls count of step instance
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Return PCR Data
     * @param stepInstanceId 
     */
    apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param workflowRunId 
     */
    apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of Workflow Run Instrument Info
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of Workflow Runs
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunsPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetWorkflowRunsPageGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a list of Workflow Runs
     * @param page 
     * @param pageSize 
     */
    apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a specific workflow Run.
     * @param id The id of the Run to return
     */
    apiV1WorkflowRunIdGet(params: {  "id": string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of instrument options
     */
    apiV1WorkflowRunInstrumentOptionsGet(options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunInstrumentOptionsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Release a Workflow Run
     * @param workflowRunId 
     * @param releaseRun 
     */
    apiV1WorkflowRunReleaseRunPost(params: {  "workflowRunId"?: string; "releaseRun"?: boolean; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunReleaseRunPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Reopens a run from a specific step.
     * @param stepId 
     * @param assetId 
     * @param workflowRunId 
     * @param body 
     */
    apiV1WorkflowRunReopenRunFromStepPost(params: {  "stepId"?: string; "assetId"?: string; "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunReopenRunFromStepPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the Workflow Run Metadata json
     * @param workflowRunId 
     */
    apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save a a list of ComponentSets to a step.
     * @param workflowRunId 
     * @param addUsedLots 
     * @param body 
     */
    apiV1WorkflowRunSaveComponentSetsPut(params: {  "workflowRunId"?: string; "addUsedLots"?: boolean; "body"?: Array<ComponentSetVM>; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunSaveComponentSetsPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a specific workflow run&#39;s sequencing status.
     * @param id The id of the Run to check
     */
    apiV1WorkflowRunSequencingStatusIdGet(params: {  "id": string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunSequencingStatusIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Sets the passed in user as the current run user
     * @param body 
     */
    apiV1WorkflowRunSetCurrentRunUserPost(params: {  "body"?: RunUserVM; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunSetCurrentRunUserPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param workflowRunId 
     */
    apiV1WorkflowRunUnlockRunPost(params: {  "workflowRunId"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunUnlockRunPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates workflow run sequencing status
     * @param workflowRunId 
     * @param newStatus 
     */
    apiV1WorkflowRunUpdateWorkflowRunSequencingStatusPost(params: {  "workflowRunId"?: string; "newStatus"?: string; }, options?: any) {
        return WorkflowRunApiFp.apiV1WorkflowRunUpdateWorkflowRunSequencingStatusPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * WorkflowRunApi - factory interface
 */
export const WorkflowRunApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Add a new instrument
         * @param instrument 
         */
        apiV1WorkflowRunAddInstrumentPost(params: {  "instrument"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunAddInstrumentPost(params, options)(fetch, basePath);
        },
        /** 
         * Standardized method for completing a step instance. This method only handles the step completion process that is standard to all steps, any handling that is specific to a given step will   require additional API calls.
         * @param body 
         */
        apiV1WorkflowRunCompleteStepPost(params: {  "body"?: CompleteStepRequestVM; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunCompleteStepPost(params, options)(fetch, basePath);
        },
        /** 
         * Completes the workflow
         * @param errored did the pipeline error?
         * @param body 
         */
        apiV1WorkflowRunCompleteWorkflowPost(params: {  "errored"?: boolean; "body"?: CompleteStepRequestVM; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunCompleteWorkflowPost(params, options)(fetch, basePath);
        },
        /** 
         * Create a note record
         * @param stepInstanceId step instance to associate note with
         * @param userId user who created the note
         * @param remarks the note
         */
        apiV1WorkflowRunCreateNotePost(params: {  "stepInstanceId"?: string; "userId"?: string; "remarks"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunCreateNotePost(params, options)(fetch, basePath);
        },
        /** 
         * Creates a workflow run from an archived plate
         * @param workflowId 
         * @param stepId 
         * @param archivedPlateId 
         * @param workflowRunId 
         * @param body 
         */
        apiV1WorkflowRunCreateWorkflowFromArchivedPlatePost(params: {  "workflowId"?: string; "stepId"?: string; "archivedPlateId"?: string; "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunCreateWorkflowFromArchivedPlatePost(params, options)(fetch, basePath);
        },
        /** 
         * Creates a new WorkflowRun
         * @param workflowId 
         * @param plateMaxLoad 
         */
        apiV1WorkflowRunCreateWorkflowMaxLoadPost(params: {  "workflowId"?: string; "plateMaxLoad"?: number; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunCreateWorkflowMaxLoadPost(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowRunId 
         */
        apiV1WorkflowRunDiscardRetainedPlatesPost(params: {  "workflowRunId"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunDiscardRetainedPlatesPost(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowRunId 
         * @param runHash 
         */
        apiV1WorkflowRunExportBugSeqCsvExtendedPost(params: {  "workflowRunId"?: string; "runHash"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunExportBugSeqCsvExtendedPost(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowRunId 
         * @param runHash 
         */
        apiV1WorkflowRunExportBugSeqCsvPost(params: {  "workflowRunId"?: string; "runHash"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunExportBugSeqCsvPost(params, options)(fetch, basePath);
        },
        /** 
         * Fails the run
         * @param stepInstanceId 
         * @param userId 
         * @param remarks 
         * @param workflowRunId 
         * @param plateId 
         */
        apiV1WorkflowRunFailRunPost(params: {  "stepInstanceId"?: string; "userId"?: string; "remarks"?: string; "workflowRunId"?: string; "plateId"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunFailRunPost(params, options)(fetch, basePath);
        },
        /** 
         * Generate Illumina Sample Sheet
         * @param workflowRunId 
         * @param stepInstanceId 
         * @param isCOV2 
         */
        apiV1WorkflowRunGenerateIlluminaSampleSheetGet(params: {  "workflowRunId"?: string; "stepInstanceId"?: string; "isCOV2"?: boolean; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGenerateIlluminaSampleSheetGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets the Concentration Controls for Quantification
         */
        apiV1WorkflowRunGetConcentrationControlKitsGet(options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetConcentrationControlKitsGet(options)(fetch, basePath);
        },
        /** 
         * Gets custom field data for a single step
         * @param stepInstanceId 
         */
        apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetCustomFieldDataStepInstanceIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets the reagents
         */
        apiV1WorkflowRunGetExtractionReagentsGet(options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetExtractionReagentsGet(options)(fetch, basePath);
        },
        /** 
         * Gets the workflow run users
         * @param workflowRunId 
         */
        apiV1WorkflowRunGetRunUsersGet(params: {  "workflowRunId"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetRunUsersGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets the Sequencing data for a workflow run
         */
        apiV1WorkflowRunGetSequencingDataGet(options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetSequencingDataGet(options)(fetch, basePath);
        },
        /** 
         * Gets the Sequencing data for a workflow run
         * @param workflowRunId 
         */
        apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetSequencingDataWorkflowRunIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Return ComponentSet Data
         * @param stepInstanceId 
         * @param workflowRunId 
         * @param type 
         */
        apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; "type"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetStepInstanceComponentSetDataStepInstanceIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Return controls count of step instance
         * @param stepInstanceId 
         */
        apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetStepInstanceControlsByControlTypeStepInstanceIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Return PCR Data
         * @param stepInstanceId 
         */
        apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet(params: {  "stepInstanceId": string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetStepInstancePCRDataStepInstanceIdGet(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param workflowRunId 
         */
        apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetStepInstancesWorkflowRunIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of Workflow Run Instrument Info
         * @param page 
         * @param pageSize 
         */
        apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetWorkflowRunInstrumentInfoPageGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of Workflow Runs
         * @param page 
         * @param pageSize 
         */
        apiV1WorkflowRunGetWorkflowRunsPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetWorkflowRunsPageGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a list of Workflow Runs
         * @param page 
         * @param pageSize 
         */
        apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet(params: {  "page": number; "pageSize"?: number; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunGetWorkflowRunsWithMetadataPageGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets a specific workflow Run.
         * @param id The id of the Run to return
         */
        apiV1WorkflowRunIdGet(params: {  "id": string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Get a list of instrument options
         */
        apiV1WorkflowRunInstrumentOptionsGet(options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunInstrumentOptionsGet(options)(fetch, basePath);
        },
        /** 
         * Release a Workflow Run
         * @param workflowRunId 
         * @param releaseRun 
         */
        apiV1WorkflowRunReleaseRunPost(params: {  "workflowRunId"?: string; "releaseRun"?: boolean; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunReleaseRunPost(params, options)(fetch, basePath);
        },
        /** 
         * Reopens a run from a specific step.
         * @param stepId 
         * @param assetId 
         * @param workflowRunId 
         * @param body 
         */
        apiV1WorkflowRunReopenRunFromStepPost(params: {  "stepId"?: string; "assetId"?: string; "workflowRunId"?: string; "body"?: Array<WetlabRunReagentMapVM>; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunReopenRunFromStepPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets the Workflow Run Metadata json
         * @param workflowRunId 
         */
        apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet(params: {  "workflowRunId": string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunRunMetadataDataWorkflowRunIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Save a a list of ComponentSets to a step.
         * @param workflowRunId 
         * @param addUsedLots 
         * @param body 
         */
        apiV1WorkflowRunSaveComponentSetsPut(params: {  "workflowRunId"?: string; "addUsedLots"?: boolean; "body"?: Array<ComponentSetVM>; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunSaveComponentSetsPut(params, options)(fetch, basePath);
        },
        /** 
         * Gets a specific workflow run&#39;s sequencing status.
         * @param id The id of the Run to check
         */
        apiV1WorkflowRunSequencingStatusIdGet(params: {  "id": string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunSequencingStatusIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Sets the passed in user as the current run user
         * @param body 
         */
        apiV1WorkflowRunSetCurrentRunUserPost(params: {  "body"?: RunUserVM; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunSetCurrentRunUserPost(params, options)(fetch, basePath);
        },
        /** 
         * @param workflowRunId 
         */
        apiV1WorkflowRunUnlockRunPost(params: {  "workflowRunId"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunUnlockRunPost(params, options)(fetch, basePath);
        },
        /** 
         * Updates workflow run sequencing status
         * @param workflowRunId 
         * @param newStatus 
         */
        apiV1WorkflowRunUpdateWorkflowRunSequencingStatusPost(params: {  "workflowRunId"?: string; "newStatus"?: string; }, options?: any) {
            return WorkflowRunApiFp.apiV1WorkflowRunUpdateWorkflowRunSequencingStatusPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * WorkflowRunAssetApi - fetch parameter creator
 */
export const WorkflowRunAssetApiFetchParamCreator = {
    /** 
     * Get assets for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any): FetchArgs {
        // verify required parameter "stepInstanceId" is set
        if (params["stepInstanceId"] == null) {
            throw new Error("Missing required parameter stepInstanceId when calling apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet");
        }
        const baseUrl = `/api/v1/WorkflowRunAsset/GetWorkflowRunAssetsForStep/{stepInstanceId}`
            .replace(`{${"stepInstanceId"}}`, `${ params["stepInstanceId"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workflowRunId": params["workflowRunId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save controls to an asset based on asset type
     * @param assetId asset id of the plate, rack or pool
     * @param workflowRunId workflow run id
     * @param assetTypeId asset type id
     * @param body list of controls to be added to the plate, rack or pool
     */
    apiV1WorkflowRunAssetSaveControlsPatch(params: {  "assetId"?: string; "workflowRunId"?: string; "assetTypeId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRunAsset/SaveControls`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "assetId": params["assetId"],
            "workflowRunId": params["workflowRunId"],
            "assetTypeId": params["assetTypeId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PATCH" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update the assets statuses to the status given
     * @param body list of assets to update, with the status
     */
    apiV1WorkflowRunAssetUpdateWorkflowRunAssetsStatusPost(params: {  "body"?: UpdateWetlabRunAssetsStatusRequestVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/WorkflowRunAsset/UpdateWorkflowRunAssetsStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * WorkflowRunAssetApi - functional programming interface
 */
export const WorkflowRunAssetApiFp = {
    /** 
     * Get assets for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet(params: { "stepInstanceId": string; "workflowRunId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WetlabRunAssetsVM> {
        const fetchArgs = WorkflowRunAssetApiFetchParamCreator.apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save controls to an asset based on asset type
     * @param assetId asset id of the plate, rack or pool
     * @param workflowRunId workflow run id
     * @param assetTypeId asset type id
     * @param body list of controls to be added to the plate, rack or pool
     */
    apiV1WorkflowRunAssetSaveControlsPatch(params: { "assetId"?: string; "workflowRunId"?: string; "assetTypeId"?: string; "body"?: Array<WellAddRequestVM>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = WorkflowRunAssetApiFetchParamCreator.apiV1WorkflowRunAssetSaveControlsPatch(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update the assets statuses to the status given
     * @param body list of assets to update, with the status
     */
    apiV1WorkflowRunAssetUpdateWorkflowRunAssetsStatusPost(params: { "body"?: UpdateWetlabRunAssetsStatusRequestVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HttpResponseMessage> {
        const fetchArgs = WorkflowRunAssetApiFetchParamCreator.apiV1WorkflowRunAssetUpdateWorkflowRunAssetsStatusPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * WorkflowRunAssetApi - object-oriented interface
 */
export class WorkflowRunAssetApi extends BaseAPI {
    /** 
     * Get assets for a step
     * @param stepInstanceId 
     * @param workflowRunId 
     */
    apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any) {
        return WorkflowRunAssetApiFp.apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save controls to an asset based on asset type
     * @param assetId asset id of the plate, rack or pool
     * @param workflowRunId workflow run id
     * @param assetTypeId asset type id
     * @param body list of controls to be added to the plate, rack or pool
     */
    apiV1WorkflowRunAssetSaveControlsPatch(params: {  "assetId"?: string; "workflowRunId"?: string; "assetTypeId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any) {
        return WorkflowRunAssetApiFp.apiV1WorkflowRunAssetSaveControlsPatch(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update the assets statuses to the status given
     * @param body list of assets to update, with the status
     */
    apiV1WorkflowRunAssetUpdateWorkflowRunAssetsStatusPost(params: {  "body"?: UpdateWetlabRunAssetsStatusRequestVM; }, options?: any) {
        return WorkflowRunAssetApiFp.apiV1WorkflowRunAssetUpdateWorkflowRunAssetsStatusPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * WorkflowRunAssetApi - factory interface
 */
export const WorkflowRunAssetApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get assets for a step
         * @param stepInstanceId 
         * @param workflowRunId 
         */
        apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet(params: {  "stepInstanceId": string; "workflowRunId"?: string; }, options?: any) {
            return WorkflowRunAssetApiFp.apiV1WorkflowRunAssetGetWorkflowRunAssetsForStepStepInstanceIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Save controls to an asset based on asset type
         * @param assetId asset id of the plate, rack or pool
         * @param workflowRunId workflow run id
         * @param assetTypeId asset type id
         * @param body list of controls to be added to the plate, rack or pool
         */
        apiV1WorkflowRunAssetSaveControlsPatch(params: {  "assetId"?: string; "workflowRunId"?: string; "assetTypeId"?: string; "body"?: Array<WellAddRequestVM>; }, options?: any) {
            return WorkflowRunAssetApiFp.apiV1WorkflowRunAssetSaveControlsPatch(params, options)(fetch, basePath);
        },
        /** 
         * Update the assets statuses to the status given
         * @param body list of assets to update, with the status
         */
        apiV1WorkflowRunAssetUpdateWorkflowRunAssetsStatusPost(params: {  "body"?: UpdateWetlabRunAssetsStatusRequestVM; }, options?: any) {
            return WorkflowRunAssetApiFp.apiV1WorkflowRunAssetUpdateWorkflowRunAssetsStatusPost(params, options)(fetch, basePath);
        },
    };
};
