import {
    React,
    _,
} from "$Imports/Imports";

import {
    ActionVM, ActionType
} from "$Generated/api";

import {
    Button
} from "$Imports/MaterialUIComponents";


const styles: {
    button: string;
} = require("./StepActionButtons.scss");

export interface IStepActionButtonsProps {
    actions: ActionVM[];
    actionHandler: (actionType: ActionType, button: Element) => void;
    hideArchiveRetain?: boolean;
    showGridControl?: boolean;
}

export class StepActionButtons extends React.PureComponent<IStepActionButtonsProps> {
    render() {
        var comment = _.find(this.props.actions, action => action.ActionType === "AddComment");
        var buttons = _.filter(this.props.actions, action => !action.IsPerformedOnStepCompletion && action.ActionType !== "AddComment")
            .filter(a => !(this.props.hideArchiveRetain && (a.ActionType === "ArchivePlate" || a.ActionType === "ArchivePool" || a.ActionType === "RetainPlate" || a.ActionType === "RetainPool")))
            .map((action =>
                (<Button key={action.Name} variant="contained" size="small" color="primary" className={styles.button} onClick={(event) => { this.props.actionHandler(action.ActionType, event.currentTarget) }}>
                    {action.Name}
                </Button>)))

        return (
            <div>
                {comment !== undefined && 
                <Button key={comment.Name} variant="contained" size="small" color="primary" className={styles.button} onClick={(event) => { this.props.actionHandler("AddComment", event.currentTarget) }}>
                    {comment.Name}
                </Button>
                }
                {this.props.showGridControl && 
                <Button key="gridcontrol" variant="contained" size="small" color="primary" className={styles.button} onClick={(event) => { this.props.actionHandler("AddControl", event.currentTarget) }}>
                    Grid Control
                </Button>
                }
                {buttons}
            </div>
        );

    }
}