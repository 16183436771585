import {
    React,
    _,
    bind
} from "$Imports/Imports";

import { GetWorkflowType, IWorkflowScreenProps } from "../../../WorkflowStep/WorkflowStep";
import {
    Button, Card, IconButton, EditIcon, DeleteIcon, ListItem, Dialog
} from "$Imports/MaterialUIComponents";

import {
    PlateVM, WellAddRequestVM, ControlVM, WellContentVM
} from "$Generated/api";

import { StandardPlate, DataLoadingDisplay } from "$Imports/CommonComponents";

import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";

import { WorkflowRunFreezerService } from "$State/WorkflowRun/WorkflowRunFreezerService";

const styles: {
    tableHeader: string;
    table: string;
    card: string;
    controlInputLabel: string;
    clearButton: string;
    controlsContainer: string;
    controlsActions: string;
    scaledPlateDiv: string;
} = require("./GridActionsControl.scss");

export interface IGridActionsControlProps {
    open: boolean;
    save: (plateId: string, workFlowRunId: string, controls: WellAddRequestVM[]) => void;
    close: () => void;
    actionString: string;
    workflowRunService: WorkflowRunFreezerService;
}

export interface IGridActionsControlState {
    controls: WellContentVM[],
    outputPlate?: PlateVM,
    controlType?: ControlVM | undefined
}

export class GridActionsControl extends React.PureComponent<IGridActionsControlProps, IGridActionsControlState> {
    componentDidMount() {
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;
        if (currentStep) {
            const outputPlate = _.find(plates.data, plate => (plate.IsOutput === true && plate.Name === currentStep.OutputName));
            this.setState({
                outputPlate: outputPlate
            })
        }

    }

    constructor(props: IGridActionsControlProps) {
        super(props);

        this.state = {
            controls: []
        };
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        if (currentWorkflowRun && currentStep) {
            let selectedCount = 0;
            const inputs = _.filter(currentWorkflowRun.Controls, c => c.Name === "PTC" || c.Name === "NTC").map(c => {
                const currentlySelected = _.filter(this.state.outputPlate?.WellContents, (w) => {
                    if (w.Control && (w.Control.ControlId === c.Id)) {
                        return true;
                    }
                    return false;
                });
                let positionString = "";

                _.forEach(currentlySelected, (w) => {
                    positionString += ConvertPositionToString(w.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow) + ", ";
                    selectedCount++;
                });

                return (<tr key={c.Id} style={this.state.controlType === c ? { backgroundColor: "#ffa" } : {}}>
                    <td>
                        <div>
                            {c.Name + ": " + currentlySelected.length}
                        </div>
                    </td>
                    <td>
                        <div style={{ width: "150px" }}>
                            {positionString}
                        </div>
                    </td>
                    <td>
                        <IconButton
                            className={styles.controlInputLabel}
                            disabled={(currentStep && currentWorkflowRun && currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress")}
                            onClick={() => { this.handleControlClick(c) }}
                        >
                            <EditIcon />
                        </IconButton>
                    </td>
                </tr>);
            });

            if (this.state.outputPlate) {
                return <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth="md"
                >
                    <Card className={styles.card}>
                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                            <h2>
                                Control Positions
                            </h2>
                            <div className={styles.scaledPlateDiv}>
                                <StandardPlate
                                    wellContents={this.state.outputPlate.WellContents}
                                    wellCount={96}
                                    columnCount={12}
                                    positionByRow={false}
                                    wellClick={this.handleWellClick}
                                />
                            </div>

                            <h2>
                                Select Controls
                            </h2>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Control</th>
                                        <th>Position</th>
                                        <th>Click to Select</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inputs}
                                    <tr>
                                        <td className={styles.clearButton} colSpan={3}>
                                            <IconButton
                                                onClick={() => { this.clearControls() }}
                                            >
                                                <DeleteIcon />
                                                Clear Controls
                                            </IconButton>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={styles.controlsActions}>
                                <Button key={"save"} variant="contained" size="small" color="primary" onClick={() => { this.saveControls() }}>Save</Button>
                                <Button key={"save"} variant="contained" size="small" color="default" onClick={() => { this.handleClose() }}>Cancel</Button>
                            </div>
                        </div>
                    </Card>
                </Dialog>
            } else {
                return <DataLoadingDisplay />
            }

        }
        return <Dialog open={this.props.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="lg">
            <DataLoadingDisplay />
        </Dialog>

    }

    @bind
    private handleClose() {
        this.clearControls();
        this.props.close();
    }

    @bind
    private handleControlClick(controlToSet: ControlVM) {
        this.setState({ controlType: controlToSet });
    }

    @bind
    private handleWellClick(position: number) {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;

        if (currentStep && currentWorkflowRun && (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress")) {
            return;
        }

        let plateToUpdate = _.cloneDeep(this.state.outputPlate);
        let controls = _.cloneDeep(this.state.controls);
        if (plateToUpdate && plateToUpdate.WellContents) {
            const wellExists = _.find(plateToUpdate.WellContents, (w) => { return ((w.WellPosition === position)) });
            if (!wellExists) {
                const controlType = this.state.controlType;
                if (controlType) {
                    plateToUpdate.WellContents.push({
                        Id: controlType.Id,
                        Control: controlType,
                        WellPosition: position,
                        ContentType: "Control",
                        RtpcrPass: "Pass", //Set so legend is correct,
                        RunCount: 1
                    });

                    controls.push({
                        Id: controlType.Id,
                        Control: controlType,
                        WellPosition: position,
                        ContentType: "Control",
                        RtpcrPass: "Pass", //Set so legend is correct,
                        RunCount: 1
                    })
                }
            }
            this.setState({
                outputPlate: plateToUpdate,
                controls: controls
            });
        }
    }

    @bind
    private clearControls() {
        const currentStep = this.props.workflowRunService.currentStep;
        const plates = this.props.workflowRunService.getState().plates;
        if (currentStep) {
            const outputPlate = _.find(plates.data, plate => (plate.IsOutput === true && plate.Name === currentStep.InputName));
            this.setState({
                outputPlate: outputPlate,
                controls: []
            })
        }
    }

    @bind
    private async saveControls() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (this.state.outputPlate && this.state.controls && currentWorkflowRun) {
            let controlsToAdd: WellAddRequestVM[] = [];
            let controlPositions: { [ControlId: string]: WellAddRequestVM } = {};
            _.each(this.state.controls, (control) => {
                if (controlPositions[control.Id]) {
                    controlPositions[control.Id].Positions.push(control.WellPosition);
                } else if (control.Control) {
                    controlPositions[control.Id] = {
                        Id: control.Id,
                        Name: control.Control?.Name,
                        Positions: [control.WellPosition],
                        WellContent: control.ContentType
                    };
                }
            });

            _.each(controlPositions, (control) => {
                controlsToAdd.push(control);
            });

            await this.props.save(this.state.outputPlate.Id, currentWorkflowRun.Id, controlsToAdd);
            if (GetWorkflowType() === "Anthrax") {
                await this.props.workflowRunService.fetchRacksForStep(true);
            }
            else {
                await this.props.workflowRunService.fetchPlatesForStep(true);
            }
        }

        this.props.close();
    }
}