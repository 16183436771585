export const GriddingSampleCount = "28eb246a-a591-400e-b336-f134d5d57307";
export const GriddingGriddedMandatory = "75f51cc5-1b13-414e-9079-ee51eebb27a0";
export const GriddingNTCControlCount = "5bf9e2db-f813-4016-9639-c416459b8e8c";
export const GriddingPTCControlCount = "71505f77-9c8c-43ea-8b4d-4917dd5031bc";
export const RNAExtractionTemperatureMandatoryEntryCheck = "9c0643af-d98c-4de1-a9cb-501d1d031167";
export const RNAExtractionTemperatureInRangeCheck = "1bd77973-3a92-4021-9574-1ea73d677b7c";
export const RealTimePCRResultsNTCThresholdCheck = "8864d721-e38b-432a-a60b-96192d910387";
export const RealTimePCRResultsPTCThresholdCheck = "4c61962c-f7d6-4c8d-a2a7-18a59c1c088b";
export const RealTimePCRResultsNTCThresholdPassCheck = "8b1904ca-444c-4e32-a9f1-43a936d8fae0";
export const RealTimePCRResultsPTCThresholdPassCheck = "42e3218e-bf79-44c0-a2bc-99f14bb83b4f";
export const CleanAfterReverseTranscriptaseTemperatureMandatoryEntryCheck = "b023490c-e087-435a-bd87-83a3ce538803";
export const CleanAfterReverseTranscriptaseTemperatureInRangeCheck = "849506e1-83ab-4329-97f8-78352e2a4a7f";
export const CleanAfterBarcodingTemperatureMandatoryEntryCheck = "160f2688-8f72-4862-a041-7c4c0b80524d";
export const CleanAfterBarcodingTemperatureInRangeCheck = "9eb3ec21-15bd-4246-8181-e1ece4e47160";
export const QuantificationBeforePoolingConcentrationEntryMandatoryCheck = "b6d5e9b7-6cc1-461a-8287-9ee4a5cdbcd3";
export const QuantificationBeforePoolingNTCConcentrationCheck = "a205b8eb-0846-4974-86f3-5dfa1c412751";
export const QuantificationBeforePoolingPTCConcentrationCheck = "2f1c0e40-e1bc-46f9-8dcf-ff6b57b4892c";
export const PoolingPoolTargetMandatoryValueCheck = "39c72913-fdca-4af3-8ff4-b24a54ba41ac";
export const PoolingVolumeFactorMandatoryValueCheck = "863d5e82-ea58-442d-a772-6cfa995520ba";
export const CleanAfterEndRepairTemperatureMandatoryEntryCheck = "bc332db5-f284-4c95-8411-fef3af2862a4";
export const CleanAfterEndRepairTemperatureInRangeCheck = "699d940d-c998-454e-8a9d-c03a2411452a";
export const QuantificationAfterEndRepairConcentrationMandatoryEntryCheck = "e19a9f29-f5b9-4dcc-a9b3-22980da530bf";
export const QuantificationAfterEndRepairConcentrationInRangeCheck = "4564e85a-9fae-4107-9703-44e7a993df40";
export const CleanAfterLigationTemperatureMandatoryEntryCheck = "447f4d0f-2ca8-4f08-922c-8098271e7f10";
export const CleanAfterLigationTemperatureInRangeCheck = "fdf8ff6f-97b0-4695-9f05-816bc91b11cf";
export const QuantificationAfterLigationConcentrationMandatoryEntryCheck = "17ca03dd-062f-4a04-879e-6dcc627a9fca";
export const QuantificationAfterLigationConcentrationInRangeCheck = "4ff82329-5c99-4331-9654-b3bce6cf5916";
export const PreLoadingPoresCheck = "b6c62f11-8159-462e-8438-e71dcaa823d0";
export const PostLoadingPoresCheck = "2a80df14-ef94-4df4-8b10-5b92649800b2";
export const PreLoadingPoresMandatoryCheck = "4007f09c-5042-4b13-8d3d-7498b4a2a821";
export const PostLoadingPoresMandatoryCheck = "1e01fe5e-4615-44fa-b93b-cc8da6cbc680";
export const AmpliconQuantificationPTCConcentrationCheck = "2be8b3ae-f522-43cc-a1c9-205ddd6b80cd";
export const AmpliconQuantificationNTCConcentrationCheck = "720ce449-0a36-4e83-a923-b1f6916b079a";
export const AmpliconQuantificationSampleConcentrationCheck = "ed41a4ef-fa61-41ed-8ea1-5b9e7f2f96aa";
export const SpectrophotometerInstrumentEmptyCheck = "1bb40cab-2c81-4516-8fea-d1e7aa2a857c";
export const Spectrophotometer280RatioEmptyCheck = "7a1c25bd-1007-4fa4-8b18-48ee4cbb6d03";
export const Spectrophotometer230RatioEmptyCheck = "234c61d1-ae4a-4057-8e87-f7bf44bdeedb";
export const Spectrophotometer280OutOfRangeCheck = "212f749f-d874-4b71-9ab2-e890c99d0d8b";
export const Spectrophotometer230OutOfRangeCheck = "1e13429e-0216-460c-9511-61427ea9b3d7";
export const DilutionNTCConcentrationCheck = "e922afde-7105-40bd-92d4-d0fee8610a35";
export const DilutionSampleMinConcentrationCheck = "05e5ff0d-04c7-42a8-8c1d-b6758bc1deef";
export const FluorometricQuantificationConcentrationEmpty = "8e9f8836-a3f5-44ec-8565-86300e125697";
export const FluorometricQuantificationLotNumberEmpty = "635D45CE-3062-44A2-B16F-DAD4512E7219";
export const BioanalyzerQualificationAverageLibrarySizeMinCheck = "e9c4464f-c3dd-4982-925c-5feda10e6316";
export const BioanalyzerQualificationAverageLibrarySizeMaxCheck = "645aa6d9-a263-4a2c-ab4f-4d95a5995936";
export const EnrichedLibrariesConcentrationCheck = "d5b459bf-4df3-4731-aa7e-b9f09bc00e18";
export const EnrichedLibrariesAverageLibrarySizeMinCheck = "01786a68-e477-4052-bc51-90dafed68905";
export const EnrichedLibrariesAverageLibrarySizeMaxCheck = "83cf3f01-e8e6-40f1-8258-f91eed27302c";
export const InitialDilutionTargetVolumeMinCheck = "20e96a56-da19-43d7-a054-012aeb20323c";


export const SampleMinConcentration = "SampleMinConcentration";
export const PTCMinConcentration = "PTCMinConcentration";
export const NTCMaxConcentration = "NTCMaxConcentration";
export const DilutionMinConcentration = "DilutionMinConcentration";