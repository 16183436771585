import { SequencingDataVM } from "$Generated/api";

import {
  React, bind
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents";

import { DataTable } from "../DataTable/DataTable";
import { IDataTableColumn } from "../DataTable/IDataTableColumn";
import { NavigationService } from "$State/NavigationFreezerService";

var urljoin = require('url-join');

const genomicStyles: {
  dialog: string;
  tableHeader: string;
  qcDataNavCol: string;
} = require("./GenomicsData.scss");

export type IGenomicsDataProps = {
  sequencingData:SequencingDataVM[],
  wetlabRunId: string,
  pipelineRunNumber: number
};

interface IGenomicsDataState {
}

export class GenomicsData extends React.Component<IGenomicsDataProps, IGenomicsDataState> {

  // Initialize the state.
  state: IGenomicsDataState = {
  };

  private readonly sarsCOV2Columns: Array<IDataTableColumn<SequencingDataVM>> = [
    {
        columnName: "sampleId",
        columnFieldData: (d) => d.SampleId || (d.MetadataDictionary ? d.MetadataDictionary["ERROR"] : ""),
        headerProps: {
            className: genomicStyles.tableHeader,
        },
        headerValue: "Sample Id",
        sortMethod: (d) => (d.SampleId) ?? ""
    },
    {
        columnName: "pangolinLineage",
        columnFieldData: (d) => d.MetadataDictionary ? d.MetadataDictionary["pangolin_lineage"] : "",
        headerProps: {
            className: genomicStyles.tableHeader,
        },
        headerValue: "Pangolin Lineage",
        sortMethod: (d) => d.MetadataDictionary ? d.MetadataDictionary["pangolin_lineage"] : "" ?? ""
    },
    {
        columnName: "pangolinStatus",
        columnFieldData: (d) => d.MetadataDictionary ? d.MetadataDictionary["pangolin_status"] : "",
        headerProps: {
            className: genomicStyles.tableHeader,
        },
        headerValue: "Pangolin Status",
        sortMethod: (d) => d.MetadataDictionary ? d.MetadataDictionary["pangolin_status"] : "" ?? ""
    },
    {
        columnName: "nextcladeClade",
        columnFieldData: (d) => d.MetadataDictionary ? d.MetadataDictionary["nextclade_clade"] : "",
        headerProps: {
            className: genomicStyles.tableHeader,
        },
        headerValue: "Nextclade Clade",
        sortMethod: (d) => d.MetadataDictionary ? d.MetadataDictionary["nextclade_clade"] : "" ?? ""
    },
    {
        columnName: "NavToQcMetrics",
        columnFieldData: (d) =>
            <Button variant="contained"
                color="primary"
                onClick={() => this.navigateToQcMetricsPage(d.SampleId)}>
                View Quality Control Metrics
            </Button>,
        cellProps: {
            className: genomicStyles.qcDataNavCol
        },
        headerProps: {
            className: genomicStyles.tableHeader
        },
        headerValue: "Sample-Level Metrics"
    },
  ];

  render() {
    return (<DataTable
    data={this.props.sequencingData}
    columns={this.sarsCOV2Columns}
    stickyHeader={true}
    defaultSortColumnName="wellPosition"
    defaultSortDirection="asc" />)
  }

  @bind
  private async navigateToQcMetricsPage(sampleId: string) {
      const navigateUrl = urljoin('/qcmetrics', 'statistics', this.props.wetlabRunId, 'pipelinerunnumber', this.props.pipelineRunNumber.toString(), 'sample', sampleId);
      NavigationService.navigateTo(navigateUrl);
  }
}