import {
    React,
    bind,
    _,
} from "$Imports/Imports"

import { IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, CollapsibleSection, DataLoadingDisplay, BioanalyzerInstrument, SimpleQuantificationInstrument, EnrichedLibrariesAverageLibrarySizeMinCheck, EnrichedLibrariesAverageLibrarySizeMaxCheck, EnrichedLibrariesConcentrationCheck, QCUIComponent } from "$Imports/CommonComponents";
import { QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";
import { Snackbar } from "@material-ui/core";

import { GetWorkflowType } from "../../WorkflowStep/WorkflowStep";


const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

interface IEnrichedLibrariesCheckState {
    dataLoaded: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    qcOpen: boolean;
    messageSnackbarOpen: boolean;
    message: string;
    expectedConcentration: number;
}

export class EnrichedLibrariesCheck extends React.Component<IWorkflowScreenProps, IEnrichedLibrariesCheckState> {

    constructor(props: IWorkflowScreenProps) {
        super(props);

        this.state = {
            dataLoaded: false,
            failedQcs: [],
            qcResults: [],
            qcOpen: false,
            message: "",
            messageSnackbarOpen: false,
            expectedConcentration: 3
        };
    }

    async componentDidMount() {
        await this.props.workflowRunService.fetchSimplifiedReagentListForStep(true);
        await this.props.workflowRunService.fetchInstrumentOptions();
        this.setState({ dataLoaded: true });

        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                if (qc.QCCheckType === EnrichedLibrariesConcentrationCheck) {
                    let concentrationMinumum = _.find(qc.CheckConfig?.Parameters, p => p.Id === "Concentration");
                    if (concentrationMinumum) {
                        this.setState({ expectedConcentration: Number.parseInt(concentrationMinumum.Value) });
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {


        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;

        if (currentWorkflowRun && currentStep && masterMixInfoState.data && lotOptions && instrumentOptions) {
            let customFields = currentStep.toJS().CustomFields;
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return (
                <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={commonStyles.mainDiv}>
                            <div>
                                <h2>Reagent Information</h2>
                                <MasterMixControl
                                    disabled={disabled}
                                    index={0}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={0}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { }}
                                    hideHeaders
                                />
                            </div>
                            <div>
                                <SimpleQuantificationInstrument
                                    instrumentOptions={instrumentOptions}
                                    disabled={disabled}
                                    displayNotes={"Expected >= " + this.state.expectedConcentration.toString() + "ng/uL yield"}
                                    instrumentName={customFields["QuantificationInstrument"]}
                                    updateInstrumentName={(name: string) => { this.props.workflowRunService.updateCustomField("QuantificationInstrument", name); }}
                                    concentration={parseFloat(customFields["Concentration"]) || 0}
                                    updateConcentration={(newConc: number) => { this.props.workflowRunService.updateCustomField("Concentration", newConc.toString()); }}
                                    concentrationUnit={customFields["ConcentrationUnit"] || "ng/uL"}
                                    updateConcentrationUnit={(newUnit: string) => { this.props.workflowRunService.updateCustomField("ConcentrationUnit", newUnit); }}

                                />
                                {GetWorkflowType() !== "SarsCov2" && <BioanalyzerInstrument
                                    instrumentOptions={instrumentOptions}
                                    disabled={disabled}
                                    displayNotes={"Expected mean fragment size to be 350 bp with a range from 200 - 1000 bp"}
                                    instrumentName={customFields["Bioanalyzer"]}
                                    librarySize={parseFloat(customFields["LibrarySize"]) || 0}
                                    updateInstrumentName={(name: string) => { this.props.workflowRunService.updateCustomField("Bioanalyzer", name); }}
                                    updateLibrarySize={(newSize: number) => { this.props.workflowRunService.updateCustomField("LibrarySize", newSize.toFixed()); }}
                                />}
                            </div>
                        </div>
                    </CollapsibleSection>
                    {currentStep.QCCheckInstances &&
                        <QCUIComponent
                            open={this.state.qcOpen}
                            failedQCs={this.state.failedQcs}
                            close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                            results={this.state.qcResults}
                            step={currentStep}
                            workflowRunId={currentWorkflowRun.Id}
                            workflowName={currentWorkflowRun.WorkflowName}
                            completeStep={this.completeMoveToNextStep}
                        />
                    }
                    <div className={commonStyles.footerDiv}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={this.state.messageSnackbarOpen}
                        message={this.state.message}
                        autoHideDuration={5000}
                        onClose={this.snackbarClose}
                    />
                </div>)
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private async moveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        let failedQCs: QCCheckInstanceVM[] = [];
        let qcResults: QCCheckResultVM[] = [];
        // Run all QCs.
        if (currentStep) {
            let customFields = currentStep.toJS().CustomFields;
            let librarySize: number = Number.parseInt(customFields["LibrarySize"]);
            let concentration: number = Number.parseFloat(customFields["Concentration"]);
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case EnrichedLibrariesAverageLibrarySizeMinCheck:
                        let librarySizeMin = _.find(qc.CheckConfig?.Parameters, p => p.Id === "AvgLibrarySize");
                        let librarySizeMinPass = true;
                        if (librarySizeMin && librarySize < Number.parseInt(librarySizeMin.Value)) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            librarySizeMinPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? librarySizeMinPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: librarySize.toString(),
                                Date: new Date(Date.now()),
                                Pass: librarySizeMinPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case EnrichedLibrariesAverageLibrarySizeMaxCheck:
                        let librarySizeMax = _.find(qc.CheckConfig?.Parameters, p => p.Id === "AvgLibrarySize");
                        let librarySizeMaxPass = true;
                        if (librarySizeMax && librarySize > Number.parseInt(librarySizeMax.Value)) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            librarySizeMaxPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? librarySizeMaxPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: librarySize.toString(),
                                Date: new Date(Date.now()),
                                Pass: librarySizeMaxPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case EnrichedLibrariesConcentrationCheck:
                        let concentrationMinumum = _.find(qc.CheckConfig?.Parameters, p => p.Id === "Concentration");
                        let concentrationPass = true;
                        if (concentrationMinumum && concentration < Number.parseInt(concentrationMinumum.Value)) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            concentrationPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? concentrationPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: concentration.toString(),
                                Date: new Date(Date.now()),
                                Pass: concentrationPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    default:
                        break;
                }
            });
            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else if (failedQCs.length === 0) {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                this.completeMoveToNextStep();
            }
        }
    }

    @bind
    private async completeMoveToNextStep() {

        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;

        const currentStep = this.props.workflowRunService.currentStep;

        if (currentStep && masterMixState.data) {
            const customFields = currentStep.CustomFields;

            if (currentStep.OutputAssets && customFields["QuantificationInstrument"] != null && customFields["QuantificationInstrument"] != "") {
                if (customFields["Bioanalyzer"]) //Optional step, only add instrument if it exists
                {
                    await this.props.workflowRunService.addInstrument(customFields["Bioanalyzer"]);
                }
                await Promise.all([
                    this.props.workflowRunService.saveMasterMix(true),
                    this.props.workflowRunService.updateCustomFields(),
                    this.props.workflowRunService.addInstrument(customFields["QuantificationInstrument"]),
                ]);
                await this.props.workflowRunService.completeStep(); //Needs to be outside due to a race condition
            }
            else {
                this.setState({ messageSnackbarOpen: true, message: "Quantification Instrument is Required" });
            }
        }
    }


}