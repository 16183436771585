import {
    NgsReportSequencingRunNotesVM,
} from "$Generated/api";
import {
    React,
    _,
  } from "$Imports/Imports";
  
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const commonStyles: {
    table: string;
} = require("../../../css/common.scss");

const wetLabStyles: {
    analysisTableHeader : string;
    textEmphasis : string;
} = require("./WetLabReport.scss");


interface IWetLabSequencingRunNotesProps {
    dataSequencingRunNotes: NgsReportSequencingRunNotesVM[] | undefined;
    startDate : Date | undefined;
    workflowName : string;
};

export class WetLabSequencingRunNotes extends React.Component<IWetLabSequencingRunNotesProps> {

    async componentDidMount(){

    }
  
    render() {

        // Table headers for Sequencing Run Notes (LL-2797)
        var colHeadersSequencingRunNotes = ['Sample ID', 'Passed/Failed', 'Included in Analysis?'];
        if (this.props.workflowName == "Newborn Screening")
        {
            colHeadersSequencingRunNotes = ['Sample ID', 'Included in Analysis?'];
        }
        

        return (
            
            <div>
                
                <h2>Sequencing Run Notes</h2>
                <section className={wetLabStyles.textEmphasis}>Review samples included or excluded in the analysis during the regridding step.</section>

                <h3>Sequencing Analysis Start Date: {this.props.startDate}</h3>
                <TableContainer component={Paper} >
                <Table className={commonStyles.table}>
                    <TableHead>
                        <TableRow key='header'>
                            {colHeadersSequencingRunNotes.map(h => (
                                <TableCell key={h} style={{fontWeight: 'bold'}}>{h}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {this.props.dataSequencingRunNotes?.map( d => (
                            <TableRow key={d.SampleId}>
                                <TableCell>{d.SampleId}</TableCell>
                                {(this.props.workflowName !== "Newborn Screening") && <TableCell>{d.Passed ? d.Passed?.toString() : "Passed"}</TableCell>}
                                <TableCell>{(d.IncludedInAnalysis === "Fail") ? "No" : "Yes"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>

            </div>

        )
      
    }



}
