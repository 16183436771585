import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";

import
    * as s
    from "underscore.string";

import { SharingService, ISharingServiceInjectedProps } from "$State/SharingFreezerService";

import { Button, Card, Dialog, DialogActions, IconButton, RefreshIcon } from "$Imports/MaterialUIComponents";
import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import { RemoteAssociationVM, AssociationAddVM } from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";


const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    associationButton: string;
    dialog: string;
    infoLabel: string;
    headerButtons: string;
} = require("./RemoteConnectivity.scss");


interface IRemoteConnectivityPageBaseProps {
}

interface IRemoteConnectivityState {
    siteInfoOpen: boolean,
    createAssociationOpen: boolean,
    editAssociationOpen: boolean,
    verifyRequestOpen: boolean,
    associationToAdd?: AssociationAddVM,
    associationToEdit?: AssociationAddVM,
    associationToEditId?: string,
    selectedAssociation?: RemoteAssociationVM,
    removeAssociationOpen: boolean,
    cancelRequestOpen: boolean,
    isNewAssociation: boolean,
    acceptAssociationAliasName: string,
}

type IRemoteConnectivityPageProps = IRemoteConnectivityPageBaseProps & ISharingServiceInjectedProps;

export class _RemoteConnectivityPage extends React.Component<IRemoteConnectivityPageProps, IRemoteConnectivityState> {

    state: IRemoteConnectivityState = {
        siteInfoOpen: false,
        createAssociationOpen: false,
        editAssociationOpen: false,
        verifyRequestOpen: false,
        removeAssociationOpen: false,
        cancelRequestOpen: false,
        isNewAssociation: false,
        acceptAssociationAliasName: ""
    }

    private readonly columns: Array<IDataTableColumn<RemoteAssociationVM>> = [
        {
            columnName: "site-alias",
            columnFieldData: "Alias",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Site",
        },
        {
            columnName: "association-status",
            columnFieldData: (d) => this.GetFormattedInstanceState(d.State),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Association Status",
        },
        {
            columnName: "Established-on",
            columnFieldData: (d) => moment(d.Created).format("L LT"),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Established On",
        },
        {
            columnName: "last-action-on",
            columnFieldData: (d) => !s.isBlank(d.LastAction) ? moment(d.LastAction).format("L LT") : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Last Action On",
        },
        {
            columnName: "action",
            columnFieldData: (d) => {
                let buttonText = "";
                let onClickOp = () => { /* no-op */ };
                //any of these states do not show a button
                if (d.State == "SEND_TO_ACTIVE" ||
                    d.State == "ASSOCIATE_REQUEST_ACTIVE"
                ) {
                    return <></>;
                }

                //otherwise determine the right text to show on the button
                switch (d.State) {
                    case "ASSOCIATED":
                        buttonText = "Remove Connection";
                        onClickOp = () => {
                            this.setState({ selectedAssociation: d, removeAssociationOpen: true });
                        };
                        break;
                    case "ASSOCIATE_REQUEST_PENDING":
                    case "ASSOCIATE_REQUEST_WAIT_FOR_RESOLUTION":
                    case "SEND_TO_PENDING":
                        buttonText = "Cancel Request";
                        onClickOp = () => {
                            this.setState({ selectedAssociation: d, cancelRequestOpen: true });
                        };
                        break;
                    case "APPROVAL_WAIT":
                        buttonText = "Verify Request";
                        onClickOp = () => { this.setState({ selectedAssociation: d, verifyRequestOpen: true }); };
                        break;
                    case "UNASSOCIATED":
                        buttonText = "Send Request";
                        onClickOp = () => {
                            this.showAssociateToInstance(d);
                        };
                        break;
                }

                return <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                            onClick={onClickOp}
                        >
                            {buttonText}
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
        {
            columnName: "edit",
            columnFieldData: (d) => {
                let onClickOp = () => {
                    this.setState({ associationToEdit: {
                        Alias: d.Alias,
                        Remote: d.Remote
                    }, associationToEditId: d.Id, editAssociationOpen: true})
                }
                return <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || d.State != "UNASSOCIATED"}
                            onClick={onClickOp}
                        >
                            Edit
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ]

    async componentDidMount() {
        await this.props.SharingService.fetchRemoteAssociations();
        await this.props.SharingService.fetchMySiteInfo();
    }


    render() {

        let {
            remoteAssociationsResult,
            mySiteInfoResult
        } = this.props.SharingService.getState();

        let data = remoteAssociationsResult.data;
        let mySiteInfo = mySiteInfoResult.data;

        return <ApplicationSecurityContext.Consumer>
            {value => (
                <div
                    className={styles.mainContainer}
                >
                    <Card
                        className={styles.cardStyle}
                    >
                        <div className={styles.content}>
                            <h2>Remote Connections</h2>
                        </div>
                        <div className={styles.content}>
                            <h2>My Site Information</h2>
                            <Button variant="contained" color="primary" onClick={() => { this.showSiteInfo(); }}>
                                Site Info
                            </Button>
                        </div>
                        <div className={styles.content}>
                            <div style={{ display: "flex", flexDirection: "row" }} >
                                <h2>My Connections</h2>
                                <IconButton className={styles.headerButtons} onClick={(event) => { this.refreshAssociations(); }}>
                                    <RefreshIcon />
                                </IconButton>
                            </div>
                            <DataTable
                                data={data || []}
                                columns={this.columns}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                className={styles.associationButton}
                                onClick={() => { this.showCreateAssociation(); }} >
                                Establish Association
                            </Button>
                        </div>
                        <Dialog
                            className={styles.dialog}
                            open={this.state.siteInfoOpen}
                            onClose={() => { this.setState({ siteInfoOpen: false }) }}
                            PaperProps={{ style: { width: "500px", height: "200px" } }}
                        >

                            <div className={styles.content}>
                                <h2>My Site Info</h2>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={styles.infoLabel}>
                                                Guid:
                                            </td>
                                            <td>
                                                {mySiteInfo?.InstanceGuid}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.infoLabel}>
                                                DNS/IP Address:
                                            </td>
                                            <td>
                                                {mySiteInfo?.Remote}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Dialog>

                        <Dialog
                            className={styles.dialog}
                            open={this.state.createAssociationOpen || this.state.editAssociationOpen}
                            onClose={() => { this.closeCreateOrEditAssociation(); }}
                            PaperProps={{ style: { width: "500px", height: "430px" } }}
                        >

                            <div className={styles.content}>
                                <h2>{this.state.isNewAssociation ? "Send Association Request" : "Restablish Association"}</h2>
                                <div>
                                    <h2>My Site:</h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    Site Guid:
                                                </td>
                                                <td>
                                                    {mySiteInfo?.InstanceGuid}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    DNS/IP Address:
                                                </td>
                                                <td>
                                                    {mySiteInfo?.Remote}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {this.state.createAssociationOpen && 
                                    <div>
                                        <h2>Remote Site:</h2>
                                        <div style={{ paddingTop: "10px" }}>
                                            <AdvanceTextField
                                                InputLabelProps={{ shrink: true }}
                                                label={"Alias"}
                                                value={this.state.associationToAdd?.Alias}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.isNewAssociation}
                                                onDebouncedChange={(value) => { this.setAddAssociationAlias(value) }}
                                            />
                                        </div>
                                        <div style={{ paddingTop: "10px" }}>
                                            <AdvanceTextField
                                                InputLabelProps={{ shrink: true }}
                                                label={"Remote"}
                                                value={this.state.associationToAdd?.Remote}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.isNewAssociation}
                                                onDebouncedChange={(value) => { this.setAddAssociationRemote(value) }}
                                            />
                                        </div>
                                    </div>
                                }
                                {this.state.editAssociationOpen && 
                                    <div>
                                        <h2>Remote Site:</h2>
                                        <div style={{ paddingTop: "10px" }}>
                                            <AdvanceTextField
                                                InputLabelProps={{ shrink: true }}
                                                label={"Alias"}
                                                value={this.state.associationToEdit?.Alias}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                onDebouncedChange={(value) => { this.setEditAssociationAlias(value) }}
                                            />
                                        </div>
                                        <div style={{ paddingTop: "10px" }}>
                                            <AdvanceTextField
                                                InputLabelProps={{ shrink: true }}
                                                label={"Remote"}
                                                value={this.state.associationToEdit?.Remote}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                onDebouncedChange={(value) => { this.setEditAssociationRemote(value) }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <DialogActions>
                                {this.state.createAssociationOpen &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.sendAssociationRequest() }}
                                        disabled={
                                            value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                            !(this.state.associationToAdd && this.state.associationToAdd.Alias && this.state.associationToAdd.Remote)
                                        }
                                    >
                                        Send
                                    </Button>
                                }
                                {this.state.editAssociationOpen &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.editAssociation() }}
                                        disabled={
                                            value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                            !(this.state.associationToEdit && this.state.associationToEdit.Alias && this.state.associationToEdit.Remote)
                                        }
                                    >
                                        Save
                                    </Button>
                                }
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.closeCreateOrEditAssociation}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            className={styles.dialog}
                            open={this.state.verifyRequestOpen}
                            onClose={() => { this.closeVerifyRequest() }}
                            PaperProps={{ style: { width: "500px", height: "275px" } }}
                        >

                            <div className={styles.content}>
                                <h2>Verify Connection Request</h2>
                                <div>
                                    <h2>Requesting Site:</h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    Alias:
                                                </td>
                                                <td>
                                                    <AdvanceTextField
                                                        InputLabelProps={{ shrink: true }}
                                                        value={this.state.acceptAssociationAliasName}
                                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                        onDebouncedChange={(value) => { this.setAcceptAssociationAlias(value) }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    DNS/IP Address:
                                                </td>
                                                <td>
                                                    {this.state.selectedAssociation?.Remote}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { this.approveAssociationRequest() }}
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !(this.state.acceptAssociationAliasName)}
                                >
                                    Approve
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onClick={this.rejectAssociationRequest}
                                >
                                    Reject
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            className={styles.dialog}
                            open={this.state.removeAssociationOpen}
                            onClose={() => { this.closeRemoveAssociation() }}
                            PaperProps={{ style: { width: "500px", height: "275px" } }}
                        >

                            <div className={styles.content}>
                                <h2>Revoke Connection</h2>
                                <div>
                                    <h2>Site:</h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    Guid:
                                                </td>
                                                <td>
                                                    {this.state.selectedAssociation?.InstanceGuid}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    Alias:
                                                </td>
                                                <td>
                                                    {this.state.selectedAssociation?.Alias}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    DNS/IP Address:
                                                </td>
                                                <td>
                                                    {this.state.selectedAssociation?.Remote}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onClick={() => { this.unassociateAssociation() }}
                                >
                                    Revoke
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.closeRemoveAssociation}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            className={styles.dialog}
                            open={this.state.cancelRequestOpen}
                            onClose={() => { this.closeCancelRequest() }}
                            PaperProps={{ style: { width: "500px", height: "275px" } }}
                        >

                            <div className={styles.content}>
                                <h2>Cancel Request</h2>
                                <div>
                                    <h2>Site:</h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    Guid:
                                                </td>
                                                <td>
                                                    {this.state.selectedAssociation?.InstanceGuid}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    Alias:
                                                </td>
                                                <td>
                                                    {this.state.selectedAssociation?.Alias}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.infoLabel}>
                                                    DNS/IP Address:
                                                </td>
                                                <td>
                                                    {this.state.selectedAssociation?.Remote}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    onClick={() => { this.cancelRequest() }}
                                >
                                    Cancel Request
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.closeCancelRequest}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Card>
                </div>
            )}
        </ApplicationSecurityContext.Consumer>;
    }

    @bind
    private async showSiteInfo() {
        this.setState({ siteInfoOpen: true });
    }


    @bind
    private async showCreateAssociation() {
        this.setState({
            createAssociationOpen: true,
            isNewAssociation: true,
            associationToAdd: {
                Alias: "",
                Remote: ""
            }
        });
    }

    @bind
    private async showAssociateToInstance(selectedAssociation: RemoteAssociationVM) {
        this.setState({
            selectedAssociation: selectedAssociation,
            createAssociationOpen: true,
            isNewAssociation: false,
            associationToAdd: {
                Alias: selectedAssociation.Alias,
                Remote: selectedAssociation.Remote
            }
        });
    }

    @bind
    private async closeCreateOrEditAssociation() {
        this.setState({ createAssociationOpen: false, editAssociationOpen: false });
    }

    @bind
    private setAddAssociationAlias(value: string) {
        let oldVal = this.state.associationToAdd;
        if (oldVal) {
            this.setState({
                associationToAdd:
                {
                    Alias: value,
                    Remote: oldVal.Remote,

                }
            })
        }
    }

    @bind
    private setAddAssociationRemote(value: string) {
        let oldVal = this.state.associationToAdd;
        if (oldVal) {
            this.setState({
                associationToAdd:
                {
                    Alias: oldVal.Alias,
                    Remote: value
                }
            })
        }
    }

    @bind
    private setEditAssociationAlias(value: string) {
        let oldVal = this.state.associationToEdit;
        if (oldVal) {
            this.setState({
                associationToEdit:
                {
                    Alias: value,
                    Remote: oldVal.Remote,

                }
            })
        }
    }

    @bind
    private setEditAssociationRemote(value: string) {
        let oldVal = this.state.associationToEdit;
        if (oldVal) {
            this.setState({
                associationToEdit:
                {
                    Alias: oldVal.Alias,
                    Remote: value
                }
            })
        }
    }

    @bind
    private setAcceptAssociationAlias(value: string) {
        this.setState({ acceptAssociationAliasName: value })
    }

    @bind
    private async sendAssociationRequest() {
        if (this.state.associationToAdd) {
            //create a new association, or re-associate to the selected association
            if (this.state.isNewAssociation) {
                await this.props.SharingService.createNewAssociation(this.state.associationToAdd);
            }
            else if (!this.state.isNewAssociation && this.state.selectedAssociation) {
                await this.props.SharingService.associateToInstance(this.state.selectedAssociation.Id);
            }

            this.refreshAssociations();
            this.closeCreateOrEditAssociation();
        }
    }

    @bind
    private async editAssociation() {
        if (this.state.associationToEdit) {
            //Edit the alias and/or remote url of an unassociated instance
            await this.props.SharingService.editAssociation(this.state.associationToEditId!, this.state.associationToEdit);

            this.refreshAssociations();
            this.closeCreateOrEditAssociation();
        }
    }



    @bind
    private async refreshAssociations() {
        await this.props.SharingService.fetchRemoteAssociations(true);
    }


    @bind
    private async approveAssociationRequest() {
        if (this.state.selectedAssociation) {
            await this.props.SharingService.acceptAssociationRequest(this.state.selectedAssociation.Id, this.state.acceptAssociationAliasName);
            this.refreshAssociations();
            this.closeVerifyRequest();
        }
    }

    @bind
    private async rejectAssociationRequest() {
        if (this.state.selectedAssociation) {
            await this.props.SharingService.rejectAssociationRequest(this.state.selectedAssociation.Id);
            this.refreshAssociations();
            this.closeVerifyRequest();
        }
    }

    @bind
    private async unassociateAssociation() {
        if (this.state.selectedAssociation) {
            await this.props.SharingService.unassociateAssociationInstance(this.state.selectedAssociation.Id);
            this.refreshAssociations();
            this.closeRemoveAssociation();
        }
    }

    @bind
    private async cancelRequest() {
        if (this.state.selectedAssociation) {
            await this.props.SharingService.cancelAssociationRequest(this.state.selectedAssociation.Id);
            this.refreshAssociations();
            this.closeCancelRequest();
        }
    }

    @bind
    private async closeVerifyRequest() {
        this.setState({ verifyRequestOpen: false });
    }


    @bind
    private async closeRemoveAssociation() {
        this.setState({ removeAssociationOpen: false });
    }

    @bind
    private async closeCancelRequest() {
        this.setState({ cancelRequestOpen: false });
    }

    @bind
    private GetFormattedInstanceState(state: string) {
        var formattedState = state;
        switch (state) {
            case "UNASSOCIATED":
                formattedState = "Unassociated";
                break;
            case "ASSOCIATED":
                formattedState = "Associated"
                break;
            case "ASSOCIATE_REQUEST_PENDING":
                formattedState = "Request Pending";
                break;
            case "ASSOCIATE_REQUEST_ACTIVE":
                formattedState = "Request Active";
                break;
            case "ASSOCIATE_REQUEST_WAIT_FOR_RESOLUTION":
                formattedState = "Request Wait For Resolution";
                break;
            case "APPROVAL_WAIT":
                formattedState = "Approval Wait "
                break;
            case "SEND_TO_PENDING":
                formattedState = "Send To Pending";
                break;
            case "SEND_TO_ACTIVE":
                formattedState = "Send To Active";
                break;
        }
        return formattedState;
    }
}



export const RemoteConnectivityPage = SharingService.inject(_RemoteConnectivityPage);