import {
    React,
    _,
    bind,
    C3
} from "$Imports/Imports";
import { Card, Link } from "$Imports/MaterialUIComponents";


interface IUserGuideLinksBaseProps {
    width: number;
}

const styles: {
    cardStyle: string;
    headerStyle: string;
    urlMargin: string;
} = require("./WidgetCommon.scss");

type IUserGuideLinksProps = IUserGuideLinksBaseProps;

export class UserGuideLinks extends React.Component<IUserGuideLinksProps> {

    async componentDidMount() {
    }

    render() {

        return <Card
            className={styles.cardStyle}
            style={{ width: this.props.width + "%" }}
        >
            <h3 className={styles.headerStyle}>
                User Guide Links
            </h3>
            <div className={styles.urlMargin}>
                <a
                    href="https://jmichaelconsulting1.sharepoint.com/:b:/r/sites/JMCEmployees/Shared%20Documents/General/02.External/LIMS%20Lite/SARS_COV2%20Pilot%20Materials/Pilot%20Collaboration/User%20Guide/LIMS%20Lite%20SARS-CoV-2%20User%20Guide%20Ver%201.0%20-%20Lab%20Modules.pdf"
                    target="_blank"
                >
                    <b>LIMS Lite Laboratory Modules</b>
                </a>
                <div>
                    Use the LIMS Lite Platform modules for inventory, configuration, and more
                </div>
            </div>
            <div className={styles.urlMargin}>
                <a
                    href="https://jmichaelconsulting1.sharepoint.com/:b:/r/sites/JMCEmployees/Shared%20Documents/General/02.External/LIMS%20Lite/SARS_COV2%20Pilot%20Materials/Pilot%20Collaboration/User%20Guide/LIMS%20Lite%20SARS-CoV-2%20User%20Guide%20Ver%201.0%20-%20SARS-CoC-2%20Configuration.pdf"
                    target="_blank"
                >
                    <b>SARS-CoV-2 Configuration</b>
                </a>
                <div>
                    Use the SARS-CoV-2 Workflow Engine and review generated reports
                </div>
            </div>
            <div className={styles.urlMargin}>
                <a
                    href="https://jmichaelconsulting1.sharepoint.com/:b:/r/sites/JMCEmployees/Shared%20Documents/General/02.External/LIMS%20Lite/SARS_COV2%20Pilot%20Materials/Pilot%20Collaboration/User%20Guide/LIMS%20Lite%20SARS-CoV-2%20User%20Guide%20Ver%201.0%20-%20Partner%20Collaboration%20Engine.pdf"
                    target="_blank"
                >
                    <b>LIMS Lite Partner Collaboration Engine</b>
                </a>
                <div>
                    Exchange data with partners
                </div>
            </div>
        </Card>;
    }
}