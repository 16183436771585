import {
    React,
    _,
    bind
} from "$Imports/Imports";

import { SettingsService, ISettingsServiceInjectedProps } from "$State/SettingsFreezerService";

interface IMainLayoutBaseProps {
    applicationBar: JSX.Element;
    applicationFooter: JSX.Element;
    sideNavigation: JSX.Element;
    showSideNav: boolean;
}

const styles: {
    applicationContainer: string;
    applicationBar: string;
    subContainer: string;
    sideNavigation: string;
    mainContent: string;
    applicationFooter: string;
} = require("./MainLayout.scss");

const PollInterval = 30; //poll at 30s

interface IMainLayoutState {
    baobabUp: boolean | null;
}

import {
    ThemeConsumer, ThemeContext
} from "$Providers/index";
import { ApplicationFooter } from "./ApplicationFooter";

type IMainLayoutProps = IMainLayoutBaseProps & ISettingsServiceInjectedProps;

class _MainLayout extends React.PureComponent<IMainLayoutProps, IMainLayoutState> {
    state: IMainLayoutState = {
        baobabUp: false
    }

    async componentDidMount() {
        await this.props.settingsService.fetchDemoEnvironmentSet();
        await this.props.settingsService.fetchDemoEnvironmentInformation();
        await this.props.settingsService.fetchUserPreferences();
        await this.props.settingsService.limsImplementationSet();
        let limsImplementationSet = this.props.settingsService.getState().limsImplementationSetResults.data;
    }

    componentWillUnmount() {
        //Finish polling
        if (this.timerID) {
            clearInterval(this.timerID);
        }
    }

    private timerID: any;  // Specifing the type causes compilation issues.  Changing to Any for now.

    render() {
        let limsImplementationSet = this.props.settingsService.getState().limsImplementationSetResults.data;
        return (
            <ThemeConsumer>
                {(context) => {
                    const theme = context.themeConfig;

                    return (
                        <>
                            {!_.isNull(limsImplementationSet) && (
                                <div
                                    className={styles.applicationContainer}
                                >
                                    <div
                                        className={styles.applicationBar}
                                    >
                                        {this.props.applicationBar}
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: theme.sideNavigationColor
                                        }}
                                        className={styles.sideNavigation}
                                    >
                                        {this.props.showSideNav ? this.props.sideNavigation : <></>}
                                    </div>
                                    <div
                                        className={styles.subContainer}
                                        style={this.props.showSideNav ? {} : {paddingLeft:"0px"}}
                                    >

                                        <div
                                            className={styles.mainContent}
                                        >
                                            {this.props.children}
                                        </div>

                                    </div>
                                    <div
                                        className={styles.applicationFooter}
                                    >
                                        {this.props.applicationFooter}
                                    </div>
                                </div>
                            )
                            }
                        </>
                    );
                }
                }
            </ThemeConsumer >
        );
    }
}
export const MainLayout = SettingsService.inject(_MainLayout);