import { ReagentVM } from "$Generated/api";
import { AdvanceTextField, DataLoadingDisplay, DataTable, ProcessAdminRole } from "$Imports/CommonComponents";
import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";
import { Dialog, TextField, MenuItem, Checkbox, Button, DialogActions, Chip, Link } from "@material-ui/core";

import {
    HorizontalSplitIcon,
    BlockIcon,
    KeyboardDatePicker
} from "$Imports/MaterialUIComponents";
import { IUserContext } from "$Utilities/Security/IUserContext";
import { LotNumberChangeLog } from "./LotNumberChangeLog";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
} = require("../LabAssets.scss");

interface IEditReagentBaseProps {
    handleClose: () => void,
    open: boolean,
    newReagent: boolean,
    initialReagent: ReagentVM | undefined,
    handleSave: (reagent: ReagentVM) => void
    user: IUserContext | null;
}

interface IEditReagentState {
    reagentToEdit?: ReagentVM;
    lotNumberEntry: string;
    lotEntryOpen: boolean;
    lastId: number;
    lotNumberLogOpen: boolean;
    expirationEntry: Date;
}

type IEditReagentProps = IEditReagentBaseProps;



export class EditReagent extends React.Component<IEditReagentProps, IEditReagentState> {

    state: IEditReagentState = {
        lotNumberEntry: "",
        lotEntryOpen: false,
        lastId: 0,
        lotNumberLogOpen: false,
        expirationEntry: new Date(Date.now())
    }

    componentDidUpdate(prevProps: IEditReagentBaseProps) {
        //if the object is set, or changes from what it was last time.
        if (this.props.initialReagent && (prevProps.initialReagent === undefined || (prevProps.initialReagent && this.props.initialReagent.Id !== prevProps.initialReagent.Id))) {
            let reagent = _.cloneDeep(this.props.initialReagent);
            if (this.props.newReagent) {
                reagent.CreatedBy = this.props.user ? this.props.user.preferred_username : "";
            }
            this.setState({ reagentToEdit: reagent });
        }
    }

    render() {
        if (this.state.reagentToEdit) {
            let noneActive = _.find(this.state.reagentToEdit.Lots, l => l.IsActive) === undefined;
            let usedInActiveRun = _.find(this.state.reagentToEdit.Lots, l => l.UsedInRun == "UsedInActive") !== undefined;
            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div>
                        {this.state.reagentToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.props.open}
                                onClose={() => { this.props.handleClose() }}
                                PaperProps={{ style: { width: "1200px" } }}
                            >
                                <div className={styles.content} style={{ marginBottom: "50px" }}>
                                    <h2>Reagent</h2>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentToEdit?.IsActive || usedInActiveRun}
                                            InputLabelProps={{ shrink: true }}
                                            label={"Name"}
                                            value={this.state.reagentToEdit?.Name}
                                            onDebouncedChange={(value) => { this.setName(value) }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentToEdit?.IsActive || usedInActiveRun}
                                            InputLabelProps={{ shrink: true }}
                                            label={"Description"}
                                            value={this.state.reagentToEdit?.Description}
                                            onDebouncedChange={(value) => { this.setDescription(value) }}
                                        />
                                    </div>
                                    <h3 style={noneActive ? { color: "red" } : { color: "" }}>
                                        Active Lot/Ref Numbers {noneActive ? " - At least one must be active!" : ""}
                                    </h3>
                                    {_.filter(this.state.reagentToEdit.Lots, l => l.IsActive).map(l => <Chip
                                        label={l.LotNumber}
                                        key={l.Id}
                                        onDelete={l.UsedInRun === "UsedInPrevious" ? () => { this.disableLotNumber(l.Id || ""); } : l.UsedInRun === "NotUsed" ? () => { this.deleteLotNumber(l.Id || ""); } : undefined}
                                        style={{ margin: "5px" }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || l.UsedInRun === "UsedInActive"}
                                        deleteIcon={l.UsedInRun === "UsedInPrevious" ? <BlockIcon /> : undefined}
                                    />)}
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || !this.state.reagentToEdit?.IsActive}
                                            onClick={() => {
                                                this.setState({ lotEntryOpen: true, lotNumberEntry: "" });
                                            }}
                                        >
                                            Add New Lot Number
                                        </Button>
                                        <div style={{ marginTop: "auto", marginLeft: "10px" }} onClick={async () => {
                                            this.setState({ lotNumberLogOpen: true });
                                        }}>
                                            <Link>
                                                <u>View Lot Number Change Log</u>
                                            </Link>
                                        </div>
                                    </div>
                                    {
                                        !this.props.newReagent &&
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ paddingTop: "10px" }}>
                                                    Active:
                                                </div>
                                                <Checkbox checked={this.state.reagentToEdit?.IsActive}
                                                    disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.reagentToEdit?.UsedInRun}
                                                    onChange={(event) => {
                                                        this.setActive(event.target.checked);
                                                    }}
                                                />
                                            </div>
                                            {!this.state.reagentToEdit?.IsActive && this.state.reagentToEdit?.DeactivatedOn &&
                                                <div>
                                                    Deactivated On {moment(this.state.reagentToEdit?.DeactivatedOn).format("L LT")}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || noneActive || this.state.reagentToEdit.Name === ""}
                                        onClick={() => {
                                            if (this.state.reagentToEdit) {
                                                this.props.handleSave(this.state.reagentToEdit);
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.props.handleClose() }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                        {this.state.reagentToEdit &&
                            <Dialog
                                className={styles.dialog}
                                open={this.state.lotEntryOpen}
                                onClose={() => { this.setState({ lotEntryOpen: false, lotNumberEntry: "" }) }}
                                PaperProps={{ style: { width: "400px", height: "300px" } }}
                            >
                                <div className={styles.content}>
                                    <h3>Reagent Name: {this.state.reagentToEdit?.Name}</h3>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <AdvanceTextField
                                            label={"Lot/Reference Number"}
                                            autoFocus={true}
                                            value={this.state.lotNumberEntry}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onDebouncedChange={(value) => { this.setState({ lotNumberEntry: value }) }}
                                        />
                                        <HorizontalSplitIcon />
                                    </div>
                                    {_.find(this.state.reagentToEdit.Lots, l => l.LotNumber === this.state.lotNumberEntry) !== undefined && <h3 style={{ color: "red" }}>
                                        Duplicate Lot Number Entered
                                    </h3>}
                                    <KeyboardDatePicker
                                        label="Expiration Date"
                                        value={this.state.expirationEntry}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        onChange={this.onDateChanged}
                                        format="MM/DD/YY" />
                                    <DialogActions style={{ position: "absolute", bottom: "0px", right: "0px" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.lotNumberEntry === "" || _.find(this.state.reagentToEdit.Lots, l => l.LotNumber === this.state.lotNumberEntry) !== undefined}
                                            onClick={() => {
                                                this.addLotNumber();
                                            }}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { this.setState({ lotEntryOpen: false, lotNumberEntry: "" }) }}
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        }
                        {this.state.reagentToEdit &&
                            <LotNumberChangeLog
                                lotNumbers={this.state.reagentToEdit.Lots}
                                name={this.state.reagentToEdit.Name}
                                open={this.state.lotNumberLogOpen}
                                handleClose={() => { this.setState({ lotNumberLogOpen: false }); }}
                            />
                        }
                    </div>
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <></>;
    }

    @bind
    private setName(value: string) {
        let oldVal = this.state.reagentToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Name = value;
            this.setState({ reagentToEdit: newVal });
        }
    }

    @bind
    private setDescription(value: string) {
        let oldVal = this.state.reagentToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.Description = value;
            this.setState({ reagentToEdit: newVal });
        }
    }

    @bind
    private setActive(value: boolean) {
        let oldVal = this.state.reagentToEdit;
        if (oldVal) {
            let newVal = _.cloneDeep(oldVal);
            newVal.DeactivatedOn = value ? oldVal.DeactivatedOn : new Date(Date.now());
            newVal.IsActive = value;
            newVal.DeactivatedBy = value ? oldVal.DeactivatedBy : this.props.user ? this.props.user.preferred_username : "";
            this.setState({ reagentToEdit: newVal });
        }
    }

    @bind
    private addLotNumber() {
        let oldVal = this.state.reagentToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);
            lots.push(
                {
                    CreatedBy: this.props.user ? this.props.user.preferred_username : "",
                    CreatedOn: new Date(Date.now()),
                    Id: (this.state.lastId + 1).toString(),
                    IsActive: true,
                    LotNumber: this.state.lotNumberEntry,
                    UsedInRun: "NotUsed",
                    IsNew: true,
                    ReagentId: oldVal.Id || "",
                    ReagentName: oldVal.Name,
                    ReagentDescription: oldVal.Description || "",
                    ExpirationDate: this.state.expirationEntry
                }
            )
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({
                reagentToEdit: newVal,
                lotEntryOpen: false,
                lotNumberEntry: "",
                lastId: this.state.lastId + 1
            });
        }
    }

    @bind
    private deleteLotNumber(id: string) {
        let oldVal = this.state.reagentToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots).filter(l => l.Id !== id);
            let newVal = _.cloneDeep(oldVal);
            newVal.Lots = lots;
            this.setState({ reagentToEdit: newVal });
        }
    }

    @bind
    private disableLotNumber(id: string) {
        let oldVal = this.state.reagentToEdit;
        if (oldVal) {
            let lots = _.cloneDeep(oldVal.Lots);
            let lotToDisable = _.find(lots, l => l.Id === id);
            if (lotToDisable) {

                lotToDisable.IsActive = false;
                lotToDisable.DeactivatedOn = new Date(Date.now());
                lotToDisable.DeactivatedBy = this.props.user ? this.props.user.preferred_username : "";

                let newVal = _.cloneDeep(oldVal);
                newVal.Lots = lots;
                this.setState({
                    reagentToEdit: newVal,
                    lotEntryOpen: false,
                    lotNumberEntry: "",
                    lastId: this.state.lastId + 1
                });
            }
        }
    }

    @bind
    private onDateChanged(date: MaterialUiPickersDate) {
        if (date) {
            this.setState({ expirationEntry: date.toDate() })
        }
    }
}