
import {
    React,
    _,
    bind
  } from "$Imports/Imports";
  
import {Document, Page, pdfjs} from 'react-pdf';

//In order for webpack to serve this file, it needs to be referenced in the project. Since it doesn't export any modules or members
//it cannot be imported, so it needs to be pulled in via require().
require('pdfjs-dist/build/pdf.worker.min.js');

import {
    PDFViewerService,
    IPDFViewerServiceInjectedProps
} from "../../../state/PDFViewerFreezerService";

import {
    Button
} from "$Imports/MaterialUIComponents";

const urlJoin = require('url-join');

const styles: {
    nav: string,
    prevButton: string,
    nextButton: string,
} = require("./PDFViewerControl.scss");

interface IPDFViewerControlProps {
    document: string;
}

interface IPDFViewerControlState {
    pageNum: number
    numberOfPages: number | null
}

class _PDFViewerControl extends React.Component<IPDFViewerControlProps & IPDFViewerServiceInjectedProps, IPDFViewerControlState> {

    state: IPDFViewerControlState = {
        pageNum: 1,
        numberOfPages: null
    }

    componentDidMount() {
        let workerSrc = urlJoin(window.location.origin, 'pdf.worker.min.js');
        pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
        this.loadDocument();
    }

    @bind
    async loadDocument() {
        await this.props.PDFViewerService.getDocument(this.props.document);
    }

    @bind
    onDocumentLoad(pdf: pdfjs.PDFDocumentProxy) {
        this.setState({numberOfPages: pdf.numPages});
    }

    @bind
    goToPrevPage() {
        let {pageNum} = this.state;

        if(pageNum > 1) {
            this.setState({pageNum: this.state.pageNum - 1});
        }
    }

    @bind
    goToNextPage() {
        let {pageNum, numberOfPages} = this.state;

        if(numberOfPages && pageNum < numberOfPages) {
            this.setState({pageNum: this.state.pageNum + 1});
        }
    }


    render() {
        let fileResult = this.props.PDFViewerService.getState().documentResult;
        let {pageNum, numberOfPages} = this.state;
        return (
            <>
            {fileResult.hasFetched && fileResult.data &&
        
                <div>
                    <div className={styles.nav}>
                        <Button className={styles.prevButton} disabled={pageNum === 1} onClick={this.goToPrevPage} variant="contained" color="primary">Previous</Button>
                        <Button className={styles.nextButton} disabled={(!numberOfPages) || (pageNum === numberOfPages)} onClick={this.goToNextPage} variant="contained" color="primary">Next</Button>
                    </div>

                    <Document                   
                        file={fileResult.data}
                        onLoadSuccess={this.onDocumentLoad}
                    >
                        <Page pageNumber={this.state.pageNum} width={800}></Page>    
                    </Document>
                </div> 
            }
            </>
        )
    }
}

export const PDFViewerControl = PDFViewerService.inject(_PDFViewerControl);