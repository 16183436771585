import {
    React,
    bind
} from "$Imports/Imports";

import {
    AppBar,
    Toolbar,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Popover,
} from "$Imports/MaterialUIComponents";

import {
    LLIcon
} from "$Imports/CommonIcons";


import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { style } from "@material-ui/system";

import {
    SettingsService,
    ISettingsServiceInjectedProps
} from "$State/SettingsFreezerService";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { IUserContext } from "$Utilities/Security/IUserContext";
import { ComputerIcon, ContactSupportIcon, Home, MenuBookIcon } from "$Imports/MaterialUIIcons";
import { NavigationService } from "$State/NavigationFreezerService";

interface IApplicationFooterBaseProps {
}

interface IApplicationFooterState {
    softwareInfoOpen: boolean;
    element: Element | null
}

type IApplicationFooterProps = IApplicationFooterBaseProps & ISettingsServiceInjectedProps;

const styles: {
    applicationBarContainer: string;
    applicationBarItems: string;
    applicationBarTitle: string;
    logo: string;
} = require("./MainLayout.scss");

export class _ApplicationFooter extends React.Component<IApplicationFooterProps, IApplicationFooterState> {


    state: IApplicationFooterState = {
        softwareInfoOpen: false,
        element: null
    };

    private _security: ApplicationSecuritySettings = new ApplicationSecuritySettings();

    async componentDidMount() {
        await this.props.settingsService.fetchSettings();
        await this.props.settingsService.fetchPlatformVersion();
    }

    render() {
        const versionInfo = this.props.settingsService.getState().settingsFetchResults.data;
        const platformVersion = this.props.settingsService.getState().platformVersion.data;
        const siteName = "CDC AMD Division"; //TODO: Replace with configuration

        return (
            <AppBar
                position="sticky"
            >
                <Toolbar>
                    <div className={styles.applicationBarContainer} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Button
                                style={{ marginTop: "auto", marginBottom: "auto", marginRight: "5px", color: "white", borderColor: "white", width: "250px", height: "40px" }}
                                variant="outlined"
                                startIcon={<ContactSupportIcon />}
                                onClick={() => {
                                    window.open("https://app.smartsheet.com/b/form/4b7cac351bdf4264a21be6ef892c9fcd", "_blank");
                                }}
                            >
                                Submit Help Desk Ticket
                            </Button>
                            <Button
                                style={{ marginTop: "auto", marginBottom: "auto", marginRight: "5px", color: "white", borderColor: "white", width: "150px", height: "40px" }}
                                variant="outlined"
                                startIcon={<MenuBookIcon />}
                                onClick={() => {
                                    window.open("https://jmichaelconsulting1.sharepoint.com/:b:/r/sites/JMCEmployees/Shared%20Documents/General/02.External/LIMS%20Lite/SARS_COV2%20Pilot%20Materials/Pilot%20Collaboration/User%20Guide/LIMS%20Lite%20SARS-CoV-2%20User%20Guide%20Ver%201.0.pdf", "_blank");
                                }}
                            >
                                User Guide
                            </Button>
                        </div>
                        <div>
                            <IconButton onClick={this.handleClick}>
                                <ComputerIcon style={{ color: "white" }} fontSize={"large"} />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
                <Popover
                    open={this.state.softwareInfoOpen}
                    onClose={this.handleClose}
                    anchorEl={this.state.element}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <div style = {{justifyContent:"center", textAlign:"center", padding:"5px"}}>
                        <b style={{margin:"auto"}}>Software Information</b>
                        <div style={{margin:"auto"}}>App Version Info: {versionInfo ? versionInfo.VersionInfo : ""}</div>
                        <div style={{margin:"auto"}}>Platform Version Info: {platformVersion ? platformVersion.PlatformVersion : ""}</div>
                    </div>
                </Popover>
            </AppBar>
        );
    }

    @bind
    private handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ element: event.currentTarget, softwareInfoOpen:true });
    }

    @bind
    private handleClose() {
        this.setState({ softwareInfoOpen: false, element: null });
    }
}

export const ApplicationFooter = SettingsService.inject(_ApplicationFooter);
