import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import { GetWorkflowType, IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, DataTable, IDataTableColumn, AdvanceTextField, StandardPlate, CollapsibleSection } from "$Imports/CommonComponents";

import { Dialog, Card, Snackbar, Button } from "$Imports/MaterialUIComponents";

import { CardActions, Chip, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { PlateVM, RackVM, ReagentGroupLotVM, ReagentGroupVM, ReagentLotVM, WellContentVM } from "$Generated/api";

import { ConvertPositionToString, ConvertStringToPosition } from "$Components/common/StandardPlate/StandardPlate";
import { BarcodingPrimerPrepMinIONDialog } from "./BarcodingPrimerPrepMinIONDialog";
import { BarcodingPrimerPrepIlluminaDialog } from "./BarcodingPrimerPrepIlluminaDialog";

const styles: {
    footerDiv: string;
    mainDiv: string;
    tableHeader: string;
} = require("./CommonStepStyles.scss");

export interface IBarcodingPrimerPrepState {
    messageSnackbarOpen: boolean;
    message: string;
    startingPosition: number;
    dialogOpen: boolean;
}

export class BarcodingPrimerPrepScreen extends React.Component<IWorkflowScreenProps, IBarcodingPrimerPrepState> {

    private groupColumns(): Array<IDataTableColumn<ReagentGroupVM>> {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentStep && currentWorkflowRun) {
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");

            return [
                {
                    columnName: "kit-name",
                    columnFieldData: "Name",
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Barcode Kit",
                },
                {
                    columnName: "lot-num",
                    columnFieldData: (d) => <div>
                        {_.filter(d.Lots, l => l.UsedInRun === "NotUsed").map(l => <Chip
                            label={l.LotNumber}
                            key={l.Id}
                            style={{ minWidth: "50px", margin: "5px", backgroundColor: this.props.workflowRunService.barcodeReagentGroupId === l.Id ? "green" : "" }}
                            onClick={() => {
                                this.props.workflowRunService.barcodeReagentGroupId = l.Id;
                                this.setState({
                                    dialogOpen: true
                                });
                            }}
                            disabled={disabled}
                        />)}
                    </div>,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Lot/Ref Number",
                },
                {
                    columnName: "view-mapping",
                    columnFieldData: (d) => {
                        if (this.props.workflowRunService.barcodeReagentMap && _.find(d.Lots, l => this.props.workflowRunService.barcodeReagentGroupId === l.Id)) {
                            return <Button
                                style={{ height: "40px" }}
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => { this.setState({ dialogOpen: true }) }}
                            >
                                View Mapping
                            </Button>;
                        }
                        return <></>;
                    },
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "",
                }
            ];
        }
        return [];

    }

    state: IBarcodingPrimerPrepState =
        {
            messageSnackbarOpen: false,
            message: "",
            startingPosition: -1,
            dialogOpen: false,
        };

    async componentDidMount() {
        if (GetWorkflowType() === "NBS") {
            await this.props.workflowRunService.fetchBarcodeKits(true, "IlluminaNexteraBarcodeKit", true);
        }
        else if (GetWorkflowType() === "SarsCov2") {
            await this.props.workflowRunService.fetchBarcodeKits(true, "IlluminaDualAdapterBarcodeKit", true);
        }
        else {
            await this.props.workflowRunService.fetchBarcodeKits(true, "MinIONBarcodeKit", true);
        }
        await this.props.workflowRunService.fetchBarcodeReagents(true);
        await this.props.workflowRunService.fetchWorkflowRunBarcodes(true);

        if (GetWorkflowType() === "Anthrax") {
            await this.props.workflowRunService.fetchRacksForStep(true);
        }
        else {
            await this.props.workflowRunService.fetchPlatesForStep(true);
        }
        

        const workflowRunBarcodeData = this.props.workflowRunService.getState().fetchWorkflowRunBarcodes.data || [];

        this.props.workflowRunService.barcodeReagentMap = _.map(workflowRunBarcodeData, barcode => {
            return {
                ReagentLotId: barcode.ReagentLotId,
                ReagentGroupLotId: barcode.ReagentGroupLotId || "",
                WellPosition: barcode.WellPosition
            };
        });

        this.props.workflowRunService.barcodeReagentGroupId = workflowRunBarcodeData[0].ReagentGroupLotId || "";
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const racks = this.props.workflowRunService.getState().racks;
        const plates = this.props.workflowRunService.getState().plates;
        const barcodeKitData = this.props.workflowRunService.getState().fetchBarcodeKits.data;
        const selectedbarcodeKitData = _.find(this.props.workflowRunService.getState().fetchBarcodeKits.data, b => _.find(b.Lots, l => l.Id === this.props.workflowRunService.barcodeReagentGroupId) !== undefined);
        let barcodeWells: ReagentLotVM[] = [];
        if (selectedbarcodeKitData) {
            barcodeWells = _.orderBy(_.find(selectedbarcodeKitData.Lots, l => l.Id === this.props.workflowRunService.barcodeReagentGroupId)?.ReagentLots || [],
                r => ConvertStringToPosition(r.BarcodeWellPosition || "", currentWorkflowRun?.AssetColCount || 0, currentWorkflowRun?.AssetRowCount || 0, currentWorkflowRun?.AssetPositionByRow || false));
        }

        if (currentStep && (plates.data || racks.data)) {
            if (currentWorkflowRun && currentStep && barcodeKitData) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={styles.mainDiv}>
                                <div style={{ width: "1200px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <h2>Components</h2>
                                        <div style={{ fontSize: "12px", margin: "auto" }}>Go to the Assets tab to edit components and add/edit lot numbers</div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {this.props.workflowRunService.useBarcodeKit && <DataTable
                                            columns={this.groupColumns()}
                                            data={barcodeKitData}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div className={styles.footerDiv}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={this.state.message}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                        {(GetWorkflowType() === "NBS") ?
                            <BarcodingPrimerPrepIlluminaDialog
                                close={() => { this.setState({ dialogOpen: false }) }}
                                disabled={disabled}
                                open={this.state.dialogOpen}
                                workflowRunService={this.props.workflowRunService}
                            />
                            :
                            <BarcodingPrimerPrepMinIONDialog
                                close={() => { this.setState({ dialogOpen: false }) }}
                                disabled={disabled}
                                open={this.state.dialogOpen}
                                workflowRunService={this.props.workflowRunService}
                                showTableToggle={(GetWorkflowType() !== "SarsCov2")}
                            />
                        }
                    </div>);
            }
        }
        return <></>
    }


    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private async moveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentStep && currentWorkflowRun) {
            if (this.props.workflowRunService.useBarcodeKit) {
                if (this.props.workflowRunService.barcodeReagentMap.length > 0) {
                    if (currentStep.InputAssets) {
                        if (GetWorkflowType() === "Anthrax") {
                            const racks = this.props.workflowRunService.getState().racks;
                            if (racks.data) {
                                const inputRack = _.find(racks.data, rack => (rack.Name === currentStep.InputName));
                                if (inputRack) {
                                    await this.props.workflowRunService.associateBarcodeKitToRun()

                                    //Continue to next step
                                    await Promise.all([
                                        this.props.workflowRunService.completeStep(),
                                    ]);
                                }
                            }
                        }
                        else {
                            const plates = this.props.workflowRunService.getState().plates;
                            if (plates.data) {
                                const inputPlate = _.find(plates.data, plate => (plate.Name === currentStep.InputName));
                                if (inputPlate) {
                                    await this.props.workflowRunService.associateBarcodeKitToRun()

                                    //To be continued
                                    await Promise.all([
                                        this.props.workflowRunService.completeStep(),
                                    ]);
                                }
                            }
                        }
                        
                    }
                }
                else {
                    this.setState({ messageSnackbarOpen: true, message: "Kit must be selected" });
                }
            }
        }
    }
}