import {
    React,
    bind
} from "$Imports/Imports";

import {
    Card,
    Button,
    InputLabel,
    FormControl
} from "$Imports/MaterialUIComponents";

import {
    AdvanceTextField, DataTable, IDataTableColumn, ProcessAdminRole
} from "$Imports/CommonComponents";

import {
    SettingsService,
    ISettingsServiceInjectedProps
} from "$State/SettingsFreezerService"
import { Dialog, FormControlLabel, FormLabel, Radio, RadioGroup, StepIconClasskey } from "@material-ui/core";
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import { FileService, IFileServiceInjectedProps } from "$State/FileFreezerService";
import { LabAssetUploadVM } from "$Generated/api";
import { CSVService, ICSVServiceInjectedProps } from "$State/CSVFreezerService";
import _ = require("lodash");
import { AuthService } from "$Utilities/Security/AuthFreezerService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    cardHeader: string;
    flexRow: string;
    formControl: string;
    content: string;
    savedText: string;
    radioLabel: string;
    componentDiv: string;
    instructionDiv: string;
    assetDiv: string;
    tableHeader: string;
    indentedList: string;
    reagentsSelectionButton: string;
    kitsSelectionButton: string;
    instrumentSelectionButton: string;
    selectorContainer: string;
    uploadContainer: string;
    url: string;
    sectionContainer: string;
} = require("./SystemSettingsPage.scss");

interface ISystemSettingsPageBaseProps {
}

interface ISystemSettingsPageState {
    systemSettings: { [key: string]: string };
    recentlySaved: boolean;
    saveFailed: boolean;
    reagentsFileName: string;
    kitsFileName: string;
    instrumentsFileName: string;
    componentsUploaded: boolean;
    reagentsData?: string;
    kitsData?: string;
    instrumentsData?: string;
    displayResults: boolean;
    resultsData: string;
    fileError: boolean;
    keycloakAuthUrl: string;
}

type ISystemSettingsPageProps = ISystemSettingsPageBaseProps & ISettingsServiceInjectedProps & IFileServiceInjectedProps & ICSVServiceInjectedProps;

export class _SystemSettingsPage extends React.PureComponent<ISystemSettingsPageProps, ISystemSettingsPageState> {

    private readonly outputColumns: Array<IDataTableColumn<{ text: string }>> = [
        {
            columnName: "text",
            columnFieldData: "text",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ];

    async componentDidMount() {
        let keycloakConfig = await AuthService.getKeycloakSettings();

        this.setState({
            recentlySaved: false,
            reagentsFileName: "<selected file name>",
            kitsFileName: "<selected file name>",
            instrumentsFileName: "<selected file name>",
            componentsUploaded: false,
            displayResults: false,
            resultsData: "",
            fileError: false,
            keycloakAuthUrl: keycloakConfig?.url || ""
        });
        await this.props.settingsService.getSystemSettings(true).then(() => {
            const state = this.props.settingsService.getState();

            const {
                systemSettings
            } = state;
            this.setState({ systemSettings: systemSettings.data || {} })
        })
        this.props.FileService.getLabAssetTemplateZip();
    }

    render() {
        let disabled = false;

        //Store the selected file in React state
        const saveReagentsFile = () => {
            const file = document.getElementById('reagentButton') as HTMLInputElement;
            if (file.files != null) {
                this.setState({ reagentsFileName: file.files[0].name });
                const reader = new FileReader();
                reader.onload = () => { this.setState({ reagentsData: reader.result as string }); };
                reader.readAsText(file.files[0]);
            }
        }
        const saveKitsFile = () => {
            const file = document.getElementById('kitButton') as HTMLInputElement;
            if (file.files != null) {
                this.setState({ kitsFileName: file.files[0].name })
                const reader = new FileReader();
                reader.onload = () => { this.setState({ kitsData: reader.result as string }); };
                reader.readAsText(file.files[0]);
            }
        }
        const saveInstrumentsFile = () => {
            const file = document.getElementById('instrumentButton') as HTMLInputElement;
            if (file.files != null) {
                this.setState({ instrumentsFileName: file.files[0].name })
                const reader = new FileReader();
                reader.onload = () => { this.setState({ instrumentsData: reader.result as string }); };
                reader.readAsText(file.files[0]);
            }
        }
        //Trigger the file input
        const reagentButton = () => {
            document.getElementById('reagentButton')?.click();
        }
        const kitButton = () => {
            document.getElementById('kitButton')?.click();
        }
        const instrumentButton = () => {
            document.getElementById('instrumentButton')?.click();
        }

        return (
            <div
                className={styles.mainContainer}
            >
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Card
                            className={styles.cardStyle}
                        >

                            {this.state?.systemSettings != null ?
                                <div className={styles.content}>
                                    <div className={styles.sectionContainer}>
                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                            <h2 className={styles.cardHeader}>Site Settings</h2>
                                        </div>
                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                            <h3><i>Administrative users can edit system settings below.</i></h3>
                                        </div>
                                        <br />
                                        <div className={styles.flexRow} style={{ margin: "auto", justifyContent: "space-evenly" }}>
                                            <div className={styles.flexRow}>
                                                <h3>Site Name: </h3>
                                                <FormControl className={styles.formControl}>
                                                    <AdvanceTextField
                                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                        value={this.state.systemSettings['site_name'] || ""}
                                                        label="Enter Name"
                                                        variant="outlined"
                                                        onDebouncedChange={(value) => {
                                                            var currentSettings = { ...(this.state.systemSettings) };
                                                            currentSettings['site_name'] = value;
                                                            this.setState({ systemSettings: currentSettings });
                                                        }} />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div style={{ margin: "auto", textAlign: "center", padding: "5px 0px" }}>
                                            <h3><i>Record the LIMS Lite Application Computing Environment</i></h3>
                                        </div>
                                        <div className={styles.flexRow} style={{ margin: "auto", justifyContent: "center" }}>
                                            <div className={styles.flexRow}>
                                                <h3>Cloud or Server Environment: </h3>
                                                <FormControl className={styles.formControl}>
                                                    <AdvanceTextField
                                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                        value={this.state.systemSettings['environment'] || ""}
                                                        label="Environment i.e. Amazon Web Services"
                                                        variant="outlined"
                                                        onDebouncedChange={(value) => {
                                                            var currentSettings = { ...(this.state.systemSettings) };
                                                            currentSettings['environment'] = value;
                                                            this.setState({ systemSettings: currentSettings });
                                                        }} />
                                                </FormControl>
                                            </div>
                                            <div style={{ padding: "0px 10px" }}>
                                                <h3><i>Or</i></h3>
                                            </div>
                                            <div className={styles.flexRow}>
                                                <h3>Hardware ID: </h3>
                                                <FormControl className={styles.formControl}>
                                                    <AdvanceTextField
                                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                        value={this.state.systemSettings['hardware_id'] || ""}
                                                        label="Enter ID"
                                                        variant="outlined"
                                                        onDebouncedChange={(value) => {
                                                            var currentSettings = { ...(this.state.systemSettings) };
                                                            currentSettings['hardware_id'] = value;
                                                            this.setState({ systemSettings: currentSettings });
                                                        }} />
                                                </FormControl>
                                            </div>
                                        </div>
                                        {(value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) !== -1) && <div className={styles.flexRow}>
                                            <h3>User Administration and Configuration: </h3>
                                            <div className={styles.url}>
                                                <a
                                                    href={this.state.keycloakAuthUrl}
                                                    target="_blank"
                                                >
                                                    <b>Launch Management Console</b>
                                                </a>
                                            </div>
                                        </div>}

                                        <div>
                                            <div><b><i>Sample Management Templates: Date Format for Uploaded Files</i></b></div>
                                            <div><i>The application can accept two different date formats for uploaded data. Please select the format this site will be using.</i></div>
                                            <div className={styles.flexRow}>
                                                <b className={styles.radioLabel}>Select a date format for the file:</b>
                                                <RadioGroup row value={this.state.systemSettings["sample_management_date_format_setting"]} onChange={this.handleDateRadioChange}  >
                                                    <FormControlLabel disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} value={"MMDDYYYY"} control={<Radio />} label="mm/dd/yyyy" />
                                                    <FormControlLabel disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} value={"DDMMYYYY"} control={<Radio />} label="dd/mm/yyyy" />
                                                </RadioGroup>
                                            </div>
                                        </div>
                                        <div className={styles.flexRow} style={{ margin: "auto", justifyContent: "center" }}>
                                            <div className={styles.flexRow} style={{ maxHeight: "40px" }}>
                                                <Button disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} variant="contained" color="primary" onClick={() => this.undoChanges()}>Undo Changes</Button>
                                            </div>
                                            <div className={styles.flexRow} style={{ padding: "0px 10px", maxHeight: "40px" }}>
                                                <Button disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} variant="contained" color="primary" onClick={() => this.saveSettings(this.state.systemSettings)}>Save</Button>
                                            </div>
                                            {this.state?.recentlySaved ? <div className={styles.flexRow}>
                                                <h3 className={styles.savedText}><b>Saved!</b></h3>
                                            </div> : <></>}
                                            {this.state?.saveFailed ? <div className={styles.flexRow}>
                                                <h3><b>Save Failed</b></h3>
                                            </div> : <></>}
                                        </div>
                                        {(value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || (this.state.systemSettings["components_uploaded"] === "true")) ? disabled = true : disabled = this.state.componentsUploaded}
                                    </div>
                                    <div className={styles.sectionContainer}>
                                        <div className={styles.instructionDiv}>
                                            <h3>Initial Component Lots and Instrument Upload</h3>
                                        </div>
                                        <div className={styles.instructionDiv}>
                                            <div>
                                                <i>LIMS Lite records lot numbers and instruments associated with each workflow run for quality control and auditing purposes.</i>
                                            </div>
                                            <div>
                                                <i>To complete the initial set up for the laboratory site, please add lot numbers for reagents and commercial kits and any instruments the lab would be using according to the following instructions:</i>
                                            </div>
                                            <ul>
                                                <li><i>Download the zip file containing templates to upload into the system.</i></li>
                                                <li><i>Unzip the downloaded file and fill out each template.</i></li>
                                                <li className={styles.indentedList}><i>For Reagents and Commercial Kits, the templates will have any reagents or commecial kits expected by the installed configurations pre-populated. Add a lot number and expiration date for each of the expected reagents or kits.</i></li>
                                                <li className={styles.indentedList}><i>For Instruments, please fill out a list of all the instruments the laboratory team would be using on a regular basis. Ensure each instrument has a unique name.</i></li>
                                                <li><i>Select each file using the below 'Choose File' buttons. Confirm the files you would like to use are selected.</i></li>
                                                <li><i>Click 'Upload Completed Files.'</i></li>
                                            </ul>
                                        </div>
                                        <div className={styles.assetDiv}>
                                            <div>
                                                <Button size={"large"} disabled={disabled} variant="contained" color="primary" onClick={() => this.downloadTemplates()}>Download Templates</Button>
                                            </div>
                                            <div className={styles.uploadContainer}>
                                                <Button size={"large"} disabled={disabled} variant="contained" color="primary"
                                                    onClick={() => {
                                                        if (this.state.reagentsData && this.state.instrumentsData && this.state.kitsData) {
                                                            this.uploadFiles(this.state.reagentsData, this.state.kitsData, this.state.instrumentsData);
                                                            var currentSettings = { ...(this.state.systemSettings) };
                                                            currentSettings["components_uploaded"] = "true";
                                                            this.setState({ systemSettings: currentSettings }, () => { this.saveSettings(this.state.systemSettings); });

                                                        }
                                                    }}>
                                                    Upload Completed Files</Button>
                                            </div>
                                        </div>
                                        <div className={styles.selectorContainer}>
                                            <div>
                                                <input id="reagentButton" type="file" hidden onChange={saveReagentsFile} />
                                                <h3>Reagents
                                                    <Button className={styles.reagentsSelectionButton} disabled={disabled} variant="contained" color="primary" onClick={reagentButton}>Choose File</Button>
                                                    <i>{this.state.reagentsFileName}</i></h3>
                                            </div>
                                            <div>
                                                <input id="kitButton" type="file" hidden onChange={saveKitsFile} />
                                                <h3>Commercial Kits
                                                    <Button className={styles.kitsSelectionButton} disabled={disabled} variant="contained" color="primary" onClick={kitButton}>Choose File</Button>
                                                    <i>{this.state.kitsFileName}</i></h3>
                                            </div>
                                            <div>
                                                <input id="instrumentButton" type="file" hidden onChange={saveInstrumentsFile} />
                                                <h3>Instruments
                                                    <Button className={styles.instrumentSelectionButton} disabled={disabled} variant="contained" color="primary" onClick={instrumentButton}>Choose File</Button>
                                                    <i>{this.state.instrumentsFileName}</i></h3>
                                            </div>
                                        </div>

                                        <Dialog open={this.state.displayResults} onClose={this.handleClose} PaperProps={{ style: { width: "1000px", maxWidth: "1000px" } }}>
                                            <DataTable
                                                data={_.map(this.state.resultsData.split("\n"), line => {
                                                    return { text: line }
                                                })}
                                                columns={this.outputColumns}
                                                isError={this.state.fileError}
                                            />
                                        </Dialog>

                                    </div>
                                </div> : <></>}
                        </Card>)}
                </ApplicationSecurityContext.Consumer>
            </div>
        );
    }

    @bind
    private handleDateRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        var currentSettings = { ...(this.state.systemSettings) };
        currentSettings['sample_management_date_format_setting'] = (event.target as HTMLInputElement).value;
        this.setState({ systemSettings: currentSettings });
    }

    @bind
    private async saveSettings(systemSettings: { [key: string]: string }) {
        this.props.settingsService.setSystemSettings(systemSettings).then(() => {
            this.setState({ recentlySaved: true, saveFailed: false });
        }).catch(() => {
            this.setState({ recentlySaved: false, saveFailed: true });
        }).finally(() => {
            setTimeout(() => {
                this.setState({ recentlySaved: false, saveFailed: false });
            }, 10 * 1000);
        });

        await this.props.settingsService.getSystemSettings(true).then(() => {
            const state = this.props.settingsService.getState();

            const {
                systemSettings
            } = state;
            this.setState({ systemSettings: systemSettings.data || {} })
        })
    }

    @bind
    private async undoChanges() {
        var settings = this.props.settingsService.getState().systemSettings.data || {};
        this.setState({ systemSettings: settings });
    }

    @bind
    private async downloadTemplates() {
        var saveDocument = document.createElement('a');
        saveDocument.href = this.props.FileService.getState().assetZipState.data || "";
        saveDocument.target = '_blank';
        saveDocument.download = 'template.zip';
        saveDocument.click();
    }

    @bind
    private async uploadFiles(reagents: string, kits: string, instruments: string) {
        if (reagents && kits && instruments) {
            let data: LabAssetUploadVM = {
                ReagentCSV: reagents,
                KitCSV: kits,
                InstrumentCSV: instruments
            };
            await this.props.CSVService.uploadLabAssets(data);

            const uploadResults = this.props.CSVService.getState().uploadLabAssets.data;

            if (uploadResults && !uploadResults.Success) {
                this.setState({ displayResults: true, fileError: true, resultsData: uploadResults.ResultText });
            }
            else if (uploadResults) {
                this.setState({ displayResults: true, resultsData: uploadResults.ResultText });
                this.setState({ componentsUploaded: true });
            }
        }
    }

    @bind
    private handleClose() {
        this.setState({ fileError: false, displayResults: false, resultsData: "", });
    }
}

export const SystemSettingsPage = CSVService.inject(FileService.inject(SettingsService.inject(
    _SystemSettingsPage
)));