import {
    NgsReportWetLabPoolingCalculationsVM,
} from "$Generated/api";

import {
    React,
    _,
} from "$Imports/Imports";
  
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const commonStyles: {
    table: string;
} = require("../../../css/common.scss");


const wetLabStyles: {
    analysisTableHeader : string;
    textEmphasis: string;
} = require("./WetLabReport.scss");

interface IWetLabPoolingCalculationsProps {
    dataPoolingCalculations: NgsReportWetLabPoolingCalculationsVM | undefined;
    workflowName: string;
};

export class WetLabPoolingCalculations extends React.Component<IWetLabPoolingCalculationsProps> {

    async componentDidMount(){

    }

    render() {

        
        // Table headers for Analysis Run Notes (LL-2798)
        var colHeadersPoolingCalculations = ['# of samples', '# of controls','Total Samples & Controls', 'Volume from Each Sample or Control (uL)', 'Total Sample & Control Volume (uL)', (this.props.workflowName === "NBS") ? 'Volume of RSB to Use (uL)' : 'Volume of IPB to Use (uL)'];
        var colSecondRow = ['count of samples from this run',
                            'count of PTCs and NTCs from this run',
                            '# of sample + # of controls',
                            (this.props.workflowName === "NBS") ? 'for Total Samples & Controls = 1, use 14;\nfor TotalSamples & Controls > 1, use 2.3' : 'per Illumina COVIDSeq Assay (96 samples) protocol',
                            'Total Samples & Controls * Volume from Each Sample or Control',
                            (this.props.workflowName === "NBS") ? '30 - Total Sample & Control Volume' : '0.9 * Total Sample & Control Volume'];

        

    
        var nSamples = this.props.dataPoolingCalculations?.NumberOfSamples ? this.props.dataPoolingCalculations.NumberOfSamples : 0;
        var nControls = this.props.dataPoolingCalculations?.NumberOfControls ? this.props.dataPoolingCalculations.NumberOfControls : 0;
        var nSum = nSamples + nControls;
        var nVolSampleControl = (this.props.workflowName === "NBS") ? ((nSum == 1) ? 1.4 : 2.3) : 5;
        
        return (
        
            <div>
                    {(this.props.workflowName !== "COV2") ? <h2>Pooling Calculations</h2> : <h2>Library Pooling and Clean Up Calculations</h2>}
                    <section className={wetLabStyles.textEmphasis}>Relevant notes or instructions that document decisions made or key items to understand the analysis.</section>
                    
                    <TableContainer component={Paper} >
                    <Table className={commonStyles.table}>
                        <TableHead>
                            <TableRow>
                                {colHeadersPoolingCalculations.map((h, nInd) => (
                                    <TableCell key={nInd} style={{fontWeight: 'bold'}}>{h}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                {colSecondRow.map((c, nInd) => (
                                    <TableCell key={nInd} className={wetLabStyles.textEmphasis}>{c}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {nSamples}
                                </TableCell>
                                <TableCell>
                                    {nControls}
                                </TableCell>
                                <TableCell>
                                    {nSum}
                                </TableCell>
                                <TableCell>
                                    {nVolSampleControl}
                                </TableCell>
                                <TableCell>
                                    {(nSum * nVolSampleControl).toFixed(1).toString()}
                                </TableCell>
                                <TableCell>
                                    {(this.props.workflowName === "NBS") ? (30 - nSum * nVolSampleControl).toFixed(1) : (0.9 * nSum * nVolSampleControl).toFixed(1)}
                                </TableCell>
                            </TableRow>
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                    
                </div>

        )
        

        
      
    }


}
