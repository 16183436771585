import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";

import {
    Checkbox,
} from "$Imports/MaterialUIComponents";
import { 
    QCCheckResultVM
} from "$Generated/api";
import { IDataTableColumn, DataTable, AdvanceTextField } from "$Imports/CommonComponents";

export interface IShowQCControlProps {
    stepName: string;
    resultDetails?: QCCheckResultVM;
}

export interface IShowQCControlState {
    
}

const styles: {
    card: string;
    header: string;
    buttons: string;
    tableHeader: string;
    content: string;
} = require("../Actions/ShowQCControl.scss");

export class QCCheckResultsDialog extends React.PureComponent<IShowQCControlProps, IShowQCControlState> {

    render() {
        // This code was copied out of ShowQCControl.tsx and should probably replace the code there.
        return (
                
            <div>
                <div style={{ height: "600px", overflow: "auto" }}>
                    
                        <div className={styles.content}>
                            <h1>
                                QC Check - {this.props.resultDetails?.QCCheckInstance.QCName}
                            </h1>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ paddingRight: "20px" }}>
                                            Description:
                                    </td>
                                        <td>
                                            {this.props.resultDetails?.QCCheckInstance.Description}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ paddingRight: "20px" }}>
                                            Step:
                                    </td>
                                        <td>
                                            {this.props.resultDetails?.QCCheckInstance.StepName}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                {
                                    this.props.resultDetails?.QCCheckInstance.CheckConfig &&
                                    this.props.resultDetails?.QCCheckInstance.CheckConfig.Parameters &&
                                    <div>
                                        <h2 style={{ paddingBottom: "20px" }}>
                                            QC Check Detail
                                        </h2>
                                        {_.map(this.props.resultDetails?.QCCheckInstance.CheckConfig.Parameters, p =>
                                            <div key={p.Id}>
                                                <AdvanceTextField
                                                    disabled={true}
                                                    InputLabelProps={{ shrink: true }}
                                                    label={p.Display}
                                                    value={p.Value}
                                                />
                                            </div>)}
                                    </div>
                                }
                                {
                                    this.props.resultDetails &&
                                    <div>
                                        <h2>
                                            QC Check Result
                                        </h2>
                                        <div style={{color: this.props.resultDetails.Pass ? "blue" : "red"}}>
                                            {this.props.resultDetails.Pass ? "Passed" : "Failed"} at {moment(this.props.resultDetails.Date).format("MM-DD-YY, h:mm a")}
                                        </div>
                                        <div>
                                            <AdvanceTextField
                                                disabled={true}
                                                InputLabelProps={{ shrink: true }}
                                                label={"Measurement"}
                                                value={this.props.resultDetails.MeasuredValue}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                <div style={{ paddingRight: "20px" }}>
                                    <h2>
                                        QC Check Failure Action
                                </h2>
                                    <div>
                                        {this.props.resultDetails?.QCCheckInstance.QCAction}
                                    </div>
                                </div>
                                <div>
                                    <h2>
                                        QC Check Action Status
                                </h2>
                                    <div>
                                    {this.props.resultDetails?.FailureActionStatus === 0 ? "Executed" : this.props.resultDetails?.FailureActionStatus === 1 ? "Skipped" : this.props.resultDetails?.FailureActionStatus === 2 ? "Modified" : ""}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <h2>
                                    QC Enabled
                            </h2>
                                <Checkbox
                                    checked={this.props.resultDetails?.QCCheckInstance.Enabled}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        
                </div>
            </div>
            
            
        );

    }

   
}