export function isNullOrUndefined(value: any): boolean {
  return (value === null || value === undefined);
}

export function nextAlpha(value: string): string {
  let returnVal = "";
  value = value.toUpperCase();

  // Checks if all chars are equal to Z
  if (same(value, "Z")) {
    returnVal = "";
    let i = value.length;
    while (i--) {
      returnVal += "A";
    }
    // All Chars are Z so set to A and add one
    return (returnVal + "A");
  } else {
    let prefix = "";
    let lastPrefix = "";

    if (value.length > 1) {
      prefix = value.substring(0, value.length - 1);
      lastPrefix = String.fromCharCode(prefix.slice(-1).charCodeAt(0));
    }

    const lastCharCode = value.slice(-1).charCodeAt(0);
    const nextLet = nextLetter(lastCharCode);

    if (nextLet === "A") { // Hit end cycle, increment letter before
      return prefix.slice(0, -1) + nextLetter(lastPrefix.slice(-1).charCodeAt(0)) + nextLet;
    } else {
      return prefix + nextLet;
    }
  }

  function nextLetter(letter: number): string {
    if (letter < 90) {
      return String.fromCharCode(letter + 1);
    } else {
      return "A";
    }
  }

  function same(str: string, test: string): boolean {
    let i = str.length;

    while (i--) {
      if (str.charAt(i) !== test) {
        return false;
      }
    }
    return true;
  }
  
}