import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _
} from "$Imports/Imports";

import {
    GetArchivedAssetContentsBySampleResponse, SampleApiFactory, PlateVM, WellContentVM, PlateApiFactory, PoolApiFactory, GetArchivedContainerResponse
} from "$Generated/api";
import { apiExceptionHandler } from "./ErrorFreezerService";

interface ISamplesState {
    archivedAssetContentsResults: IAjaxState<GetArchivedAssetContentsBySampleResponse>[];
    archivedContainersResults: IAjaxState<GetArchivedContainerResponse>[];
    currentPage: number;
    totalPageCount: number;
    totalCount: number;
    plateDataResults: IAjaxState<PlateVM>;
    poolDataResults: IAjaxState<WellContentVM[]>;
    updateCDCStatusResults: IAjaxState<boolean>;
}

const InjectedPropName = "samplesService";
const pageSize = 8;

class SampleFreezerService extends FreezerService<ISamplesState, typeof InjectedPropName> {
    constructor() {
        super({
            archivedAssetContentsResults: [managedAjaxUtil.createInitialState()],
            archivedContainersResults: [managedAjaxUtil.createInitialState()],
            currentPage: 0,
            totalPageCount: 0,
            totalCount: 0,
            plateDataResults: managedAjaxUtil.createInitialState(),
            poolDataResults: managedAjaxUtil.createInitialState(),
            updateCDCStatusResults: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async fetchArchivedAssetContentsBySample(containerName: string, forceUpdate: boolean = false) {
        const { archivedAssetContentsResults, currentPage, totalPageCount } = this.freezer.get();

        if (archivedAssetContentsResults[currentPage].hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            getAjaxState: (options) => {
                return this.freezer.get().archivedAssetContentsResults[this.freezer.get().currentPage].toJS();
            },
            setAjaxState: (options, newStatus) => {
                const archivedAssetContentsFetchResults = this.freezer.get().archivedAssetContentsResults.toJS();
                archivedAssetContentsFetchResults[this.freezer.get().currentPage] = newStatus;
                this.freezer.get().set({ archivedAssetContentsResults: archivedAssetContentsFetchResults });
            },
            onExecute: (apiOptions, param, options) => {
                const factory = SampleApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SampleGetArchivedSamplesBySamplePageGet(param);
            },
            onOk: (data: GetArchivedAssetContentsBySampleResponse) => {
                const archivedAssetContentsFetchResults = this.freezer.get().archivedAssetContentsResults.toJS();
                archivedAssetContentsFetchResults[this.freezer.get().currentPage].data = data;
                this.freezer.get().set({ archivedAssetContentsResults: archivedAssetContentsFetchResults });
            },
            onError: apiExceptionHandler,
            params:
            {
                page: currentPage,
                pageSize: pageSize,
                containerFilter: containerName
            }
        });
        let pageList = this.freezer.get().archivedAssetContentsResults.toJS();
        let initialResults = pageList[0];

        if (initialResults.hasFetched && initialResults.data && initialResults.data.TotalPageCount && totalPageCount === 0) {
            for (var x = 0; x < initialResults.data.TotalPageCount; x++) {
                pageList.push(managedAjaxUtil.createInitialState());
            }
            this.freezer.get().set({ archivedAssetContentsResults: pageList, totalPageCount: initialResults.data.TotalPageCount, totalCount: initialResults.data.TotalSampleCount });
        }

    }

    public async fetchArchivedContainers(sampleName: string, forceUpdate: boolean = false) {
        const { archivedContainersResults, currentPage, totalPageCount } = this.freezer.get();

        if (archivedContainersResults[currentPage].hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            getAjaxState: (options) => {
                return this.freezer.get().archivedContainersResults[this.freezer.get().currentPage].toJS();
            },
            setAjaxState: (options, newStatus) => {
                const archivedAssetContentsFetchResults = this.freezer.get().archivedContainersResults.toJS();
                archivedAssetContentsFetchResults[this.freezer.get().currentPage] = newStatus;
                this.freezer.get().set({ archivedContainersResults: archivedAssetContentsFetchResults });
            },
            onExecute: (apiOptions, param, options) => {
                const factory = SampleApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1SampleGetArchivedContainersPageGet(param);
            },
            onOk: (data: GetArchivedContainerResponse) => {
                const archivedAssetContentsFetchResults = this.freezer.get().archivedContainersResults.toJS();
                archivedAssetContentsFetchResults[this.freezer.get().currentPage].data = data;
                this.freezer.get().set({ archivedContainersResults: archivedAssetContentsFetchResults });
            },
            onError: apiExceptionHandler,
            params:
            {
                page: currentPage,
                pageSize: pageSize,
                sampleFilter: sampleName
            }
        });
        let pageList = this.freezer.get().archivedContainersResults.toJS();
        let initialResults = pageList[0];

        if (initialResults.hasFetched && initialResults.data && initialResults.data.TotalPageCount && totalPageCount === 0) {
            for (var x = 0; x < initialResults.data.TotalPageCount; x++) {
                pageList.push(managedAjaxUtil.createInitialState());
            }
            this.freezer.get().set({ archivedContainersResults: pageList, totalPageCount: initialResults.data.TotalPageCount, totalCount: initialResults.data.TotalSampleCount });
        }

    }

    public get totalCount(): number {
        return this.freezer.get().totalCount;
    }

    public get pageSize(): number {
        return pageSize;
    }

    public resetResults() {
        this.freezer.get().set({ archivedAssetContentsResults: [managedAjaxUtil.createInitialState()], archivedContainersResults: [managedAjaxUtil.createInitialState()], currentPage: 0, totalCount: 0, totalPageCount: 0 });
    }

    public async setPageNumber(page: number, viewBySample: boolean, containerNameSearch: string) {
        if (page >= 0) {
            this.freezer.get().set({ currentPage: page });
            if (viewBySample) {
                let state = this.getState().archivedAssetContentsResults[page];
                if (!state.hasFetched) {
                    await this.fetchArchivedAssetContentsBySample(containerNameSearch);
                }
            }
            else {
                let state = this.getState().archivedContainersResults[page];
                if (!state.hasFetched) {
                    await this.fetchArchivedContainers(containerNameSearch);
                }
            }
        }
    }

    public async getPlate(plateId: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'plateDataResults',
            onExecute: (apiOptions, param, options) => {
                let factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PlateIdGet({ id: plateId });
            },
            onError: apiExceptionHandler
        });
    }

    public async getPoolContents(poolId: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'poolDataResults',
            onExecute: (apiOptions, param, options) => {
                let factory = PoolApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PoolPoolContentsIdGet({ id: poolId });
            },
            onError: apiExceptionHandler
        });
    }

    public async updateCDCStatus(plateId: string, newStatus: boolean)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: 'updateCDCStatusResults',
            onExecute: (apiOptions, param, options) => {
                let factory = PlateApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1PlatePlateIdUpdateCDCStatusStatusPatch({plateId: plateId, status: newStatus});
            },
            onError: apiExceptionHandler
        })
    }



}

export const SampleService = new SampleFreezerService();
export type ISampleServiceInjectedProps = ReturnType<SampleFreezerService["getPropsForInjection"]>;
