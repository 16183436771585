import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import {
    IWorkflowScreenProps
} from "../WorkflowStep";

import {
    StepActionsControl,
    StepChangeControl,
    AdvanceTextField,
    QCUIComponent,
    QuantificationAfterEndRepairConcentrationInRangeCheck,
    QuantificationAfterEndRepairConcentrationMandatoryEntryCheck,
    QuantificationAfterLigationConcentrationMandatoryEntryCheck,
    QuantificationAfterLigationConcentrationInRangeCheck,
    CollapsibleSection,
    DataLoadingDisplay
} from "$Imports/CommonComponents";

import {
    ErrorIcon,
    Snackbar,
    TextField
} from "$Imports/MaterialUIComponents";

import * as s from "underscore.string";
import { ChangeEvent } from "react";
import { Autocomplete } from "@material-ui/lab";
import { QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";

const styles: {
    inputDiv: string;
    errorIcon: string;
} = require("./PoolQuantification.scss");

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");

export interface IPoolQuantificationState {
    messageSnackbarOpen: boolean;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    concNotFilled: boolean;
    dataLoaded: boolean;
}

export class PoolQuantificationScreen extends React.Component<IWorkflowScreenProps, IPoolQuantificationState> {

    state: IPoolQuantificationState = {
        messageSnackbarOpen: false,
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        concNotFilled: false,
        dataLoaded: false
    };

    componentDidMount() {
        this.props.workflowRunService.fetchStepInstanceCustomFields();
        this.props.workflowRunService.fetchInstrumentOptions();
        this.setState({dataLoaded: true});
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {

        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const instrumentOptions = this.props.workflowRunService.getState().fetchInstrumentOptions.data;

        if (currentStep && currentWorkflowRun && instrumentOptions) {
            const customFields = currentStep.CustomFields;
            if (customFields) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.collapsibleDiv}>
                                <h2>Absorbance Measurement</h2>
                                UV Absorbance Instrument: <Autocomplete
                                    freeSolo
                                    disabled={disabled}
                                    id="instrument-name"
                                    className={styles.inputDiv}
                                    options={instrumentOptions}
                                    onChange={(e, val) => this.onTextChanged("UVAbsorbanceInstrument", val || "")}
                                    value={(customFields["UVAbsorbanceInstrument"] ? customFields["UVAbsorbanceInstrument"] : "")}
                                    renderInput={(params) =>
                                    (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                            autoFocus={true}
                                            disabled={disabled}
                                            label=""
                                            value={(customFields["UVAbsorbanceInstrument"] ? customFields["UVAbsorbanceInstrument"] : "")}
                                            onChange={(e) => { this.onTextChanged("UVAbsorbanceInstrument", e.target.value) }} />
                                    )}
                                />
                                <div className={styles.inputDiv}>
                                    Quantification volume: {(customFields["QuantificationVolume"] ? customFields["QuantificationVolume"] : "")} ul
                                </div>
                                <div className={styles.inputDiv}>
                                    Concentration(ng/ul): <TextField disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={(customFields["Concentration"] ? customFields["Concentration"] : "")} onChange={this.updateConcentrationField} inputProps={{ min: 0 }} />
                                    {this.state.concNotFilled && <ErrorIcon className={styles.errorIcon} />}
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                    completeStep={this.completeMoveToNextStep}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={"Absorbance information is required"}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private onTextChanged(key: string, newValue: string) {
        this.props.workflowRunService.updateCustomField(key, newValue);
    }

    @bind
    updateConcentrationField(e: ChangeEvent<HTMLTextAreaElement>) {
        let newValue = e.target.value;

        if (s.isBlank(newValue)) {
            this.onTextChanged("Concentration", "");
        }
        else {
            let numericValue = parseFloat(newValue);
            if (numericValue) {
                this.onTextChanged("Concentration", numericValue.toString());
            }
        }
    }

    @bind
    private async moveToNextStepBegin() {

        let {
            currentStep
        } = this.props.workflowRunService;

        if (currentStep) {

            let failedQCs: QCCheckInstanceVM[] = [];
            let qcResults: QCCheckResultVM[] = [];
            const customFields = currentStep.CustomFields;

            //Run all QCs
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                switch (qc.QCCheckType) {
                    case QuantificationAfterEndRepairConcentrationMandatoryEntryCheck:
                    case QuantificationAfterLigationConcentrationMandatoryEntryCheck:
                        let concMandPass = true;
                        if (s.isBlank(customFields["Concentration"])) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            concMandPass = false;
                            this.setState({ concNotFilled: true });
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? concMandPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: s.isBlank(customFields["Concentration"]) ? "None" : customFields["Concentration"],
                                Date: new Date(Date.now()),
                                Pass: concMandPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;
                    case QuantificationAfterEndRepairConcentrationInRangeCheck:
                    case QuantificationAfterLigationConcentrationInRangeCheck:
                        let concRangePass = true;
                        let conc = Number.parseInt(customFields["Concentration"]);
                        if (!isNaN(conc)) {
                            let minConcParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MinConcentration")?.Value || "0")
                            let maxConcParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "MaxConcentration")?.Value || "0")
                            if (conc < minConcParam || conc > maxConcParam) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                                concRangePass = false;
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? concRangePass ? undefined : 0 : 2, //passed/executed/skipped
                                    MeasuredValue: customFields["Concentration"],
                                    Date: new Date(Date.now()),
                                    Pass: concRangePass,
                                    QCCheckInstance: qc,
                                }
                            );
                        }
                        break;
                }
            })
            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                await this.completeMoveToNextStep();
            }
        }
    }

    @bind
    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            const customFields = currentStep.CustomFields;

            let fieldWithMissingData = _.find(customFields, c => s.isBlank(c));
            if (fieldWithMissingData === undefined) {

                await Promise.all([
                    this.props.workflowRunService.updateCustomFields(),
                    this.props.workflowRunService.addInstrument(customFields["UVAbsorbanceInstrument"]),
                    this.props.workflowRunService.completeStep()
                ]);
            }
            else {
                this.setState({ messageSnackbarOpen: true });
            }
        }
    }
}