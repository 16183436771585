import {
  Freezer,
  FreezerService, 
  IAjaxState, 
  managedAjaxUtil,
  moment
} from "$Imports/Imports";

import Keycloak from "keycloak-js";

import {
  ApplicationSecuritySettings
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  KeycloakSettingsVM,
  SettingsApiFactory
} from "$Generated/api";

import { getLogger } from "@yahara/logging";

interface IAuthenticationState {
  keycloakConfig: IAjaxState<KeycloakSettingsVM>;
  applicationJwt: string | null;
  userJwt: string | null;
}

const logger = getLogger("AuthFreezerService");

/** Constants */
const tokenRefeshMinValiditySeconds: number = 600;//10 min - Must be less than token timeout configured in keycloak
const InjectedPropName = "authService";

class AuthFreezerService extends FreezerService<IAuthenticationState, typeof InjectedPropName>{
  public constructor() {
    super({
      applicationJwt: null,
      userJwt: null,
      keycloakConfig: managedAjaxUtil.createInitialState()
    }, InjectedPropName);
  }

  private _keycloakClient: Keycloak | undefined;
  private _isAuthenticating: boolean = false;

  /**
   * Returns true if it's in the process of the AuthFreezerService is currently authenticating or refreshing keycloak token
   */
  public get isAuthenticating(){
    return this._isAuthenticating;
  }

  /**
   * Sets the isAuthenticating field to that of the supplied param
   * 
   * @param value boolean to set isAuthenticating property to 
   */
  public set isAuthenticating(value: boolean) {
    this._isAuthenticating = value;
  }

  /**
   * Gets an instance of the keycloak-js adapter
   */
  public get keyCloakClient() {
    return this._keycloakClient;
  }

  /**
   * 
   * @returns Promise<Keycloak.KeycloakConfig | undefined>
   */
  public async getKeycloakSettings() : Promise<Keycloak.KeycloakConfig | undefined> {
    let keycloak = this.getState().keycloakConfig;
    if(!keycloak.hasFetched || keycloak.error) {
        await this.fetchConfig();
        keycloak = this.getState().keycloakConfig;
    }

    while(keycloak.isFetching){
        await this.wait(400);
    }

    if(keycloak.hasFetched && keycloak.data) {
        let securityConfig: Keycloak.KeycloakConfig = {
            realm: keycloak.data.Realm,
            clientId: keycloak.data.ClientId,
            url: keycloak.data.Url
        }
        return securityConfig;
    }
}

  /**
   * Method that can be awaited for a period of time
   * @param milisec millli seconds for which to wait for timeout
   * @returns Promise<void> 
   */
  private wait(milisec: number): Promise<unknown> {
    return new Promise(resolve => {
      setTimeout(() => resolve('') , milisec);
    });
  }

  /**
   * Method that will wait for a currently running authentication process to complete before returning
   */
  public async waitForAuthentication() : Promise<void>{
    while (this.isAuthenticating) {
      await this.wait(500);
    }
  }

  /**
   * Method that evaluates the keycloak-js adapter to determine if we're currently authenticated
   */
  public get isAuthenticated(): boolean {
    return this._keycloakClient?.authenticated ?? false;
  }

  /**
   * Takes user to login page
   * @returns Promise<void>
   */
  public async login(): Promise<void>{
    if(!this._keycloakClient){
      await this.fetchConfig();

      const keycloakConfig = this.freezer.get().keycloakConfig.data?.toJS();
  
      if (keycloakConfig === null || keycloakConfig === undefined) {
        return;
      }

      this._keycloakClient = new Keycloak({
        clientId: keycloakConfig?.ClientId || "",
        realm:  keycloakConfig?.Realm || "",
        url:  keycloakConfig?.Url || ""
      });
    }

    this._keycloakClient.login();
  }

  /**
   * Authenticates the user against the keycloak server. 
   * Calls the 'init' method on the keycloak adapter with the 'login-required' option.
   * @returns Promise<void>
   */
  public async authenticate() {

    this.isAuthenticating = true;
    await this.fetchConfig();

    const keycloakConfig = this.freezer.get().keycloakConfig.data?.toJS();

    if (keycloakConfig === null || keycloakConfig === undefined) {
      this.isAuthenticating = false;
      return;
    }

    if(!this._keycloakClient){
      this._keycloakClient = new Keycloak({
        clientId: keycloakConfig?.ClientId || "",
        realm:  keycloakConfig?.Realm || "",
        url:  keycloakConfig?.Url || ""
      });
    }

    const response = await this._keycloakClient.init({
      onLoad: 'login-required'
    });

    if (response) {
      this.saveTokens(this._keycloakClient, false);
    }

    this.isAuthenticating = false;
  }

  /**
   * Will refresh the keycloak token if within 30 seconds of expiring
   * @returns Promise<void>
   */
  public async refreshAuthentication() {
    this.isAuthenticating = true;

    await this.fetchConfig();

    const keycloakConfig = this.freezer.get().keycloakConfig.data?.toJS();

    if (keycloakConfig === null || keycloakConfig === undefined) {
      this.isAuthenticating = false;
      return;
    }

    if(!this._keycloakClient){
      this._keycloakClient = new Keycloak({
        clientId: keycloakConfig?.ClientId || "",
        realm:  keycloakConfig?.Realm || "",
        url:  keycloakConfig?.Url || ""
      });
    }

    await this._keycloakClient.updateToken(tokenRefeshMinValiditySeconds)
      .then((refreshed:any) => {
        if(refreshed)
        {
          this.saveTokens(this._keycloakClient as Keycloak, true);
        }
      })
      .catch((reason:any) => {
        logger.error('Failed to refresh keycloak token, will force login')
        logger.error(reason);
        this._keycloakClient?.login();
        
      });

    this.isAuthenticating = false;
  }

  /**
   * Will log the user out of keycloak and clears the JWT in browser storage.
   * Redirects to `window.location.origin` upon successful logout of keycloak.
   * @returns Promise<void>
   */
  async logout(): Promise<void> {

    ApplicationSecuritySettings.clearSessionInfo();

    //If keycloak client instance has not been created, then create one
    if(!this._keycloakClient){

      //Initiate api call to obtain config
      await this.fetchConfig();
    
      //Pull our config data from freezer
      const keycloakConfig = this.freezer.get().keycloakConfig.data?.toJS();
  
      //validate we have config
      if (keycloakConfig === null || keycloakConfig === undefined) {
        return;
      }

      //Instantiate the keycloak client
      this._keycloakClient = new Keycloak({
        clientId: keycloakConfig?.ClientId || "",
        realm:  keycloakConfig?.Realm || "",
        url:  keycloakConfig?.Url || ""
      });
    }

    //logout via the keycloak client
    this._keycloakClient.logout({
      redirectUri: window.location.origin
    });
  }

  public async CreateKeycloakInstance() {
    if(!this._keycloakClient){
      //Initiate api call to obtain config
      await this.fetchConfig();
  
      //Pull our config data from freezer
      const keycloakConfig = this.freezer.get().keycloakConfig.data?.toJS();

      //validate we have config
      if (keycloakConfig === null || keycloakConfig === undefined) {
        logger.info('Unable to create keycloak adapter instance. Keycloak config unavailable');
        return;
      }

      logger.info('Creating new keycloak client');
      this._keycloakClient = new Keycloak({
        clientId: keycloakConfig?.ClientId || "",
        realm:  keycloakConfig?.Realm || "",
        url:  keycloakConfig?.Url || ""
      });
    }
  }

  /**
   * Method to trigger fetch of leycloak config.
   * @param forceUpdate(bool) if false and config already present will return existing config , if true will fetch from api
   * @returns Promise<void>
   */
  public async fetchConfig(forceUpdate: boolean = false) : Promise<void> {

    if (this.freezer.get().keycloakConfig.hasFetched && !forceUpdate){
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "keycloakConfig",

      onExecute: (apiOptions) => {
        var factory = SettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1SettingsKeycloakGet();
      }
    });
  }

  /**
   * Method to store tokens received from keycloak client.
   * Stores both Application and User JWT
   * @param keycloakClient instance of keycloak client
   */
  private saveTokens(keycloakClient: Keycloak, isRefresh: boolean): void {
    const securitySettings = new ApplicationSecuritySettings();

    if (keycloakClient.token && keycloakClient.idToken) {
      logger.info("Saving new keycloak tokens to freezer state and local storage")
      this.freezer.get().set({
        applicationJwt: keycloakClient.token ?? null,
        userJwt: keycloakClient.idToken ?? null
      });

      securitySettings.userJWT = keycloakClient.idToken;
      securitySettings.applicationJWT = keycloakClient.token
    }
  }
}

export const AuthService = new AuthFreezerService();
export type IAuthenicationServiceInjectedProps = ReturnType<AuthFreezerService["getPropsForInjection"]>;