import {
  React,
  _
} from "$Imports/Imports";

import {
   QCMetricsSideNav
} from "$Components/common/QCVisualization/QCMetricsSideNav"

import { 
  qcMetricsNavigation,
  getQCMetricsBasePath,
  getWetlabRunId,
  getStepInstanceId,
  getPipelineRunNumber,
  getSampleId
} from "$Utilities/qcMetricsNavigation";

const styles: {
  main:string;
} = require("./QCMetricsLayoutPage.scss");

/**
 * Component that provides the layout for the QC Stats/Visualization screens.
 * Houses the side menu and manages the display of the currently selected screen
 */
export class QCMetricsLayoutPage extends React.Component {

  render() {

    var navItems = qcMetricsNavigation();
    const basePath = getQCMetricsBasePath();
    const selectedNavItem = _.find(navItems, ni => ni.url === basePath);
    const props:any = {runId:getWetlabRunId(), stepInstanceId:getStepInstanceId(), pipelineRunNumber: getPipelineRunNumber(), sampleId: getSampleId()};

    return (
      <div className={styles.main}>
        <QCMetricsSideNav {...props}/>
        {selectedNavItem && React.createElement(selectedNavItem.component!, props)}
      </div>
      )
  }
}