import {
    React, IAjaxState,
} from "../../../imports/Imports";

import {
    SampleVM, WellContentVM
} from "../../../generated/api";

import {
    DataTable,
    IDataTableColumn
} from "../../../imports/CommonComponents";

const styles: {
    tableHeader: string;
    table: string;
    controlDiv: string;
} = require("./SampleInfoTable.scss");

export interface ISampleInfoTableProps {
    data: WellContentVM[],
    columns: IDataTableColumn<WellContentVM>[],
    defaultSortColumnName?: string,
    header?: string,
    description?: string,
    footer?: JSX.Element,
    overrideStyles?: React.CSSProperties
}

export class SampleInfoTable extends React.PureComponent<ISampleInfoTableProps> {

    render() {
        const { data } = this.props;
        return (
            data &&
            <div style={this.props.overrideStyles} className={styles.controlDiv}>
                {(this.props.header && <h2 className={styles.tableHeader}>
                    {this.props.header}
                </h2>)}
                {(this.props.description && <div>
                    {this.props.description}
                </div>)}
                <div style={this.props.overrideStyles} className={styles.table}>

                    <DataTable
                        data={data}
                        columns={this.props.columns}
                        stickyHeader={true}
                        defaultSortColumnName={this.props.defaultSortColumnName}
                        
                    />
                </div>
                {this.props.footer ? this.props.footer : <></>}
            </div>
        );
    }
}
