import {
    React,
    bind,
    Freezer,
    _
} from "$Imports/Imports";

import {
    PCRControlInfoVM, QCCheckInstanceVM, QCCheckResultVM, StepInstanceVM
} from "$Generated/api";

import { QCService, IQCServiceInjectedProps } from "$State/QCFreezerService";

import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";

import {
    WorkflowRunService,
    IWorkflowRunServiceInjectedProps
} from "$State/WorkflowRun/WorkflowRunFreezerService"

import { Button, Card, Dialog } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Imports/CommonComponents";
import { ShowQCControl } from "../Actions/ShowQCControl";

import {
    WorkflowScreens
} from "../../../WorkflowStep/WorkflowStep";

import { KeyCodeEnter } from '$Imports/CommonComponents';

const styles: {
    dialog: string;
    card: string;
    button: string;
    continueCard: string;
    continueHeader: string;
    failCard: string;
    failHeader: string;
    failButtons: string;
} = require("./QCUIComponent.scss");

export interface IQCUIComponentBaseProps {
    open: boolean;
    failedQCs: QCCheckInstanceVM[];
    close: () => void;
    results: QCCheckResultVM[];
    step: Freezer.Types.IFrozenObject<StepInstanceVM>;
    workflowRunId: string;
    workflowName: string;
    completeStep?: () => void;
    failReplicateAction?: () => void;
    failSampleAction?: () => void;
    continueAfterFailSampleAction?: () => void;
    modifyFunction?: () => void;
}

export interface IQCUIComponentState {
    showQcsOpen: boolean;
    continueOpen: boolean;
    continueComment: string;
    modifyComment: string;
    modifyOpen: boolean;
    failOpen: boolean;
    failComment: string;
    continueFinishFunc: () => void;
    continueModifyFunc: () => void;
    isModifying: boolean;
}

type IQCUIComponentProps = IQCUIComponentBaseProps & IQCServiceInjectedProps & IWorkflowRunServiceInjectedProps;

class _QCUIComponent extends React.PureComponent<IQCUIComponentProps, IQCUIComponentState> {

    private _security: ApplicationSecuritySettings = new ApplicationSecuritySettings();

    state: IQCUIComponentState = {
        showQcsOpen: false,
        continueOpen: false,
        continueComment: "",
        modifyComment: "",
        modifyOpen: false,
        failOpen: false,
        failComment: "",
        continueFinishFunc: () => { },
        continueModifyFunc: () => { },
        isModifying: false
    }

    async componentDidUpdate(prevProps: IQCUIComponentProps) {
        if (!prevProps.open && this.props.open) {
            if (this.props.failedQCs.length === 0 || _.every(this.props.failedQCs, q => !q.Enabled)) {
                //Everything passed or ignored, trigger save results, then close
                this.props.QCService.writeQCResults(this.props.results);
                this.props.close();
            }
        }
    }

    render() {

        const restrictQCs = _.filter(this.props.failedQCs, q => q.QCAction === "Restrict Run Progress" && q.Enabled);
        const warningQCs = _.filter(this.props.failedQCs, q => q.QCAction === "Warning" && q.Enabled);
        const failRunQCs = _.filter(this.props.failedQCs, q => q.QCAction === "Fail Run" && q.Enabled);
        const failReplicateQCs = _.filter(this.props.failedQCs, q => q.QCAction === "Fail Replicate" && q.Enabled);
        const failSampleQCs = _.filter(this.props.failedQCs, q => q.QCAction === "Fail Sample" && q.Enabled);
        const step = this.props.step.toJS();
        const results = this.props.QCService.getState().getQCResults.data;

        return <div style={{ marginTop: "auto", whiteSpace: "nowrap" }}>
            {
                (step.QCCheckInstances && step.QCCheckInstances.length > 0) &&
                (<div>
                    <Button key={"showQcs"} variant="contained" size="small" color="primary" className={styles.button}
                        onClick={async (event) => {
                            const step = this.props.step.toJS();
                            if (step.Status !== "InProgress") {
                                await this.props.QCService.getQCResults(this.props.workflowRunId, step.StepId)
                            }
                            this.setState({ showQcsOpen: true });
                        }}
                    >       {step.Status === "InProgress" ? "Show QCs" : "QC Results"}
                    </Button>
                    <ShowQCControl
                        open={this.state.showQcsOpen}
                        close={() => { this.setState({ showQcsOpen: false }) }}
                        qcs={step.QCCheckInstances}
                        showResults={step.Status !== "InProgress"}
                        results={results || []}
                        stepName={step.Name}
                        workflowName={this.props.workflowName}
                    />
                </div>)
            }
            <Dialog
                open={this.props.open && !this.state.continueOpen}
                PaperProps={{ className: styles.dialog }}
                onExited={() => {
                    if(this.props.modifyFunction && this.state.isModifying)
                    {
                        this.props.modifyFunction();
                        this.setState({isModifying: false});
                    }
                }}
            >
                <Card className={styles.card}>
                    <div>
                        <div style={{ height: "600px", overflow: "auto" }}>
                            {
                                //Restrict Run Progress / Mandatory is first
                                _.map(restrictQCs, qc =>
                                    <div key={qc.QCName} style={{ paddingBottom: "25px" }}>
                                        <h2>
                                            QC Check - {qc.QCName} has failed
                                    </h2>

                                    </div>)

                            }
                            {restrictQCs.length > 0 &&
                                <div style={{ float: "right" }}>
                                    <Button key={"backToStep"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.handleModifyClose() }}
                                    >
                                        Go back to {step.Name}
                                    </Button>
                                </div>
                            }
                            {
                                //Anything that fails runs is next
                                _.map(failRunQCs, qc =>
                                    <div key={qc.QCName} style={{ paddingBottom: "25px" }}>
                                        <h2>
                                            QC Check - {qc.QCName} has failed
                                         </h2>
                                    </div>)
                            }
                            {failRunQCs.length > 0 && restrictQCs.length === 0 &&
                                <div style={{ float: "right" }}>
                                    <Button key={"modify"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.setState({ modifyOpen: true, continueModifyFunc: () => { this.handleModifyClose() } }) }}
                                    >
                                        Modify
                                    </Button>
                                    <Button key={"fail"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.setState({ failOpen: true }) }}
                                    >
                                        Fail Run
                                    </Button>
                                </div>
                            }
                            {
                                //Fail Replicate
                                _.map(failReplicateQCs, qc =>
                                    <div key={qc.QCName} style={{ paddingBottom: "25px" }}>
                                        <h2>
                                            QC Check - {qc.QCName} has failed
                                         </h2>
                                    </div>)
                            }
                            {failReplicateQCs.length > 0 && restrictQCs.length === 0 && failRunQCs.length === 0 &&
                                <div style={{ float: "right" }}>
                                    <Button key={"modify"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.setState({ modifyOpen: true, continueModifyFunc: () => { this.handleModifyClose() } }) }}
                                    >
                                        Modify
                                    </Button>
                                    <Button key={"failSamples"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.failReplicates(true) }}
                                    >
                                        Fail Samples
                                    </Button>
                                    <Button key={"continueWithFailed"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.setState({ continueOpen: true, continueFinishFunc: () => { this.failReplicates(false) } }) }}
                                    >
                                        Continue With Samples
                                    </Button>
                                </div>
                            }
                            {
                                //Fail Sample
                                _.map(failSampleQCs, qc =>
                                    <div key={qc.QCName} style={{ paddingBottom: "25px" }}>
                                        <h2>
                                            QC Check - {qc.QCName} has failed
                                         </h2>
                                    </div>)
                            }
                            {failSampleQCs.length > 0 && failReplicateQCs.length === 0 && restrictQCs.length === 0 && failRunQCs.length === 0 &&
                                <div style={{ float: "right" }}>
                                    <Button key={"modify"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.setState({ modifyOpen: true, continueModifyFunc: () => { this.handleModifyClose() } }) }}
                                    >
                                        Modify
                                    </Button>
                                    <Button key={"failSamples"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.failSamples(false) }}
                                    >
                                        Fail Sample
                                    </Button>
                                    <Button key={"continueWithFailed"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.setState({ continueOpen: true, continueFinishFunc: () => { this.failSamples(true) } }) }}
                                    >
                                        Continue With Failed Sample
                                    </Button>
                                </div>
                            }
                            {
                                //Warnings last
                                _.map(warningQCs, qc =>
                                    <div key={qc.QCName} style={{ paddingBottom: "25px" }}>
                                        <h2>
                                            QC Check - {qc.QCName} has failed
                                         </h2>
                                    </div>)
                            }
                            {warningQCs.length > 0 && restrictQCs.length === 0 && failRunQCs.length === 0 && failReplicateQCs.length === 0 && failSampleQCs.length === 0 &&
                                <div style={{ float: "right" }}>
                                    <Button key={"modify"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.setState({ modifyOpen: true, continueModifyFunc: this.handleModifyClose }) }}
                                    >
                                        Modify
                                    </Button>
                                    <Button key={"continue"} variant="contained" size="small" color="primary" className={styles.button}
                                        onClick={(event) => { this.setState({ continueOpen: true, continueFinishFunc: this.handleContinueClose }) }}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </Card>
            </Dialog>
            <Dialog
                open={this.state.continueOpen}
            >
                <Card className={styles.continueCard}>
                    <h2 className={styles.continueHeader}>
                        Enter a comment to explain continuing
                    </h2>
                    <AdvanceTextField onDebouncedChange={this.onContinueCommentChanged} onKeyDown={(e) => { 
                        if(e.keyCode === KeyCodeEnter) {
                            this.state.continueFinishFunc();
                        }
                     }}/>
                    <div style={{ float: "right" }}>
                        <Button onClick={this.state.continueFinishFunc}>Continue</Button>
                    </div>
                </Card>
            </Dialog>
            <Dialog
                open={this.state.modifyOpen}
            >
                <Card className={styles.continueCard}>
                    <h2 className={styles.continueHeader}>
                        Please enter a comment to explain the modifications
                    </h2>
                    <AdvanceTextField onDebouncedChange={this.onModifyCommentChanged} onKeyDown={(e) => { 
                        if(e.keyCode === KeyCodeEnter) {
                            this.state.continueModifyFunc();
                        }
                     }} />
                    <div style={{ float: "right" }}>
                        <Button onClick={this.state.continueModifyFunc}>Modify</Button>
                    </div>
                </Card>
            </Dialog>
            <Dialog
                open={this.state.failOpen}

            >
                <Card className={styles.failCard}>
                    <h2 className={styles.failHeader}>
                        Enter a comment to confirm and fail the run
                                </h2>
                    <AdvanceTextField onDebouncedChange={this.onFailCommentChanged} />
                    <div className={styles.failButtons}>
                        <Button disabled={this.state.failComment === ""} onClick={this.failRunFinalize}>Confirm</Button>
                    </div>
                </Card>
            </Dialog>
        </div>;
    }

    @bind
    private onContinueCommentChanged(newValue: string) {
        this.setState({ continueComment: newValue });
    }

    @bind
    private onModifyCommentChanged(newValue: string) {
        this.setState({ modifyComment: newValue });
    }

    @bind
    private handleModifyClose() {

        var modifiedResults: QCCheckResultVM[] = _.map(this.props.results, r => {
            let newResult: QCCheckResultVM =
            {
                FailureActionStatus: 1, //Modify
                Date: r.Date,
                MeasuredValue: r.MeasuredValue,
                Pass: r.Pass,
                QCCheckInstance: r.QCCheckInstance,
                Id: r.Id,
                ActionNote: this.state.modifyComment
            };
            return newResult;
        });
        this.props.QCService.writeQCResults(modifiedResults);
        this.setState({ modifyOpen: false, modifyComment: "", isModifying: true });
        this.props.close();
    }

    @bind
    private handleContinueClose() {
        //needs callback to finish step
        var modifiedResults: QCCheckResultVM[] = _.map(this.props.results, r => {
            if (r.QCCheckInstance.QCAction === "Warning" && !r.Pass) {
                let newResult: QCCheckResultVM =
                {
                    FailureActionStatus: r.FailureActionStatus,
                    Date: r.Date,
                    MeasuredValue: r.MeasuredValue,
                    Pass: r.Pass,
                    QCCheckInstance: r.QCCheckInstance,
                    ActionNote: this.state.continueComment,
                    Id: r.Id
                };
                return newResult;
            }
            else {
                return r;
            }
        });


        this.props.QCService.writeQCResults(modifiedResults);
        this.setState({ continueOpen: false, continueComment: "" });
        this.props.close();
        if (this.props.completeStep) {
            this.props.completeStep();
        }
    }

    @bind
    private async failRunFinalize() {
        //Go to the freezer
        const step = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (step) {
            let screen = WorkflowScreens[step.StepTypeId];
            if (screen.saveActions) {
                for (let action of screen.saveActions) {
                    action(this.props.workflowRunService);
                }
            }
        }


        if (step && currentWorkflowRun && step.OutputAssets) {
            const outputPlate = _.find(step.OutputAssets, plate => (plate.Name === step.OutputName));
            if (outputPlate && this.state.failComment) {
                await this.props.workflowRunService.failRun(step.StepInstanceId, (this._security.userContext ? this._security.userContext.preferred_username : ""), this.state.failComment, currentWorkflowRun.Id, outputPlate.Id)
            }
        }

        //Save QCs
        var modifiedResults: QCCheckResultVM[] = _.map(this.props.results, r => {
            if (!r.Pass) {
                let newResult: QCCheckResultVM =
                {
                    FailureActionStatus: r.FailureActionStatus,
                    Date: r.Date,
                    MeasuredValue: r.MeasuredValue,
                    Pass: r.Pass,
                    QCCheckInstance: r.QCCheckInstance,
                    ActionNote: this.state.failComment,
                    Id: r.Id
                };
                return newResult;
            }
            else {
                return r;
            }
        });
        this.props.QCService.writeQCResults(modifiedResults);

        this.setState({ failOpen: false, failComment: "" });
        this.props.workflowRunService.fetchWorkflowRun(true); //reload data
    }

    @bind
    private onFailCommentChanged(newValue: string) {
        this.setState({ failComment: newValue });
    }

    @bind
    private failReplicates(keepSampleValues: boolean) {
        //write QC results (add comment)
        var modifiedResults: QCCheckResultVM[] = _.map(this.props.results, r => {
            if (!r.Pass) {
                let newResult: QCCheckResultVM =
                {
                    FailureActionStatus: r.FailureActionStatus,
                    Date: r.Date,
                    MeasuredValue: r.MeasuredValue,
                    Pass: r.Pass,
                    QCCheckInstance: r.QCCheckInstance,
                    ActionNote: this.state.continueComment,
                    Id: r.Id
                };
                return newResult;
            }
            else {
                return r;
            }
        });
        this.props.QCService.writeQCResults(modifiedResults);

        //update samples if changing
        if (!keepSampleValues && this.props.failReplicateAction) {
            this.props.failReplicateAction();
        }

        //complete step
        if (this.props.completeStep) {
            this.props.completeStep();
        }
    }

    @bind
    private failSamples(continueWithFailed: boolean) {
        //write QC results (add comment)
        var modifiedResults: QCCheckResultVM[] = _.map(this.props.results, r => {
            if (!r.Pass) {
                let newResult: QCCheckResultVM =
                {
                    FailureActionStatus: r.FailureActionStatus,
                    Date: r.Date,
                    MeasuredValue: r.MeasuredValue,
                    Pass: r.Pass,
                    QCCheckInstance: r.QCCheckInstance,
                    ActionNote: this.state.continueComment,
                    Id: r.Id
                };
                return newResult;
            }
            else {
                return r;
            }
        });
        this.props.QCService.writeQCResults(modifiedResults);

        this.props.close();

        if (continueWithFailed && this.props.continueAfterFailSampleAction) {
            this.setState({ continueOpen: false, continueComment: "" });
            this.props.continueAfterFailSampleAction();
        }
        else if (this.props.failSampleAction) {
            this.props.failSampleAction();
        }
    }


}

export const QCUIComponent = WorkflowRunService.inject(QCService.inject(_QCUIComponent));