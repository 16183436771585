import {
    React,
    bind,
    _,
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link, Table, TableRow, TableCell } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    DataLoadingDisplay,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import {
    WorkflowConfigService,
    IWorkflowConfigServiceInjectedProps
} from "$State/WorkflowConfigFreezerService";
import { ComponentSetVM } from "$Generated/api";

import { EditComponentSet } from "./EditComponentSet";
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import { IWorkflowsServiceInjectedProps, WorkflowsService } from "$State/WorkflowsFreezerService";

import {
    WorkflowConfigHelper,
} from "../WorkflowConfig/WorkflowConfigHelper";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
} = require("./WorkflowComponentManagement.scss");

interface IReagentMixPageBaseProps {
}

interface IReagentMixPageState {
    editDialogOpen: boolean,
    newComponentSet: boolean,
    reagentMixToEdit?: ComponentSetVM,
    isCopy: boolean,
    filteredWorkflows: string,
}

type IReagentMixPageProps = IReagentMixPageBaseProps & IWorkflowConfigServiceInjectedProps & IWorkflowsServiceInjectedProps;

export class _ReagentMixPage extends React.Component<IReagentMixPageProps, IReagentMixPageState> {

    private readonly columns: Array<IDataTableColumn<ComponentSetVM>> = [
        {
            columnName: "name",
            columnFieldData: "DisplayName",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.setState({ reagentMixToEdit: d, editDialogOpen: true });
                                }}
                            >
                                {(d.AssociatedRunNumber !== 0 || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1) ? "View" : "Edit"}
                            </Button>
                            <Button
                                style={{ marginLeft: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({ reagentMixToEdit: d, editDialogOpen: true, newComponentSet: true, isCopy: true });
                                }}
                            >
                                Copy
                            </Button>
                        </div>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    state: IReagentMixPageState = {
        editDialogOpen: false,
        newComponentSet: false,
        isCopy: false,
        filteredWorkflows: '',
    }

    async componentDidMount() {
        await this.props.workflowConfigService.fetchReagentMixes(true);
        await this.props.workflowsService.fetchWorkflows(true);
    }

    render() {

        let {
            reagentMixFetchResults,
        } = this.props.workflowConfigService.getState();

        if (reagentMixFetchResults.data) {

            let reagentMixData : ComponentSetVM[] = [...reagentMixFetchResults.data];

            //Filter according to the selected workflow.
            if (this.state.filteredWorkflows !=='')
            {
                reagentMixData = [];
                _.forEach(reagentMixFetchResults.data, x => {
                    if (x.AssociatedWorkflows && x.AssociatedWorkflows.some( y => y === this.state.filteredWorkflows)){
                        reagentMixData.push(x);
                    }
                })
            }

            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div
                        className={styles.mainContainer}
                    >
                        <Card
                            className={styles.cardStyle}
                        >
                            <h2>Reagent Mixes</h2>
                            <Button
                                style={{ float: "right", marginRight: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({
                                        editDialogOpen: true,
                                        newComponentSet: true,
                                        reagentMixToEdit: {
                                            AssociatedRunNumber: 0,
                                            AssociatedWorkflows: [],
                                            ComponentSetItems: [],
                                            ComponentSetFooters: [],
                                            ComponentSetType: "ReagentMix",
                                            AdditionalAmountFactorType: "NumberOfSamples",
                                            DisplayHeader: "",
                                            DisplayName: "NAME",
                                            Id: "",
                                            ReagentUsedByType: "PerWellOfSamplePlate",
                                            AdditionalAmountFactor: 0,
                                            CreatedOn: new Date(Date.now()),
                                            IsActive: true,
                                            CreatedBy: "",
                                            DeactivatedOn: new Date(Date.now()),
                                        }
                                    })
                                }}
                            >
                                Add
                            </Button>
                            <WorkflowConfigHelper
                                onChange={e => this.setState({filteredWorkflows: e.target.value})}
                                filteredWorkflows={this.state.filteredWorkflows}>
                            </WorkflowConfigHelper>
                            
                            <div style={{fontStyle: 'italic'}}>Note: Edit button will disappear once an instruction set/component 
                            has been used in a workflow run. Users will only be able to view Reagent Mixes after being used in a run.
                            </div>    
                                
                            
                            {reagentMixFetchResults.data &&
                                <DataTable
                                    data={reagentMixData}
                                    columns={this.columns}
                                />
                            }
                            <div>
                                <EditComponentSet
                                    handleClose={this.handleClose}
                                    handleSave={this.handleReagentMixUpdate}
                                    open={this.state.editDialogOpen}
                                    newComponentSet={this.state.newComponentSet}
                                    initialComponentSet={this.state.reagentMixToEdit}
                                    user={value.applicationContext.userContext}
                                    existingNames={_.map(reagentMixFetchResults.data, r => r.DisplayName || "")}
                                    isCopy={this.state.isCopy}
                                />
                            </div>
                        </Card>
                    </div >
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private handleClose() {
        this.setState({ editDialogOpen: false, newComponentSet: false, reagentMixToEdit: undefined, isCopy: false });
    }

    @bind
    private async handleReagentMixUpdate(reagentMix: ComponentSetVM) {
        if (this.state.newComponentSet) {
            //add
            await this.props.workflowConfigService.addComponentSet(reagentMix);
        }
        else {
            //update
            await this.props.workflowConfigService.updateComponentSet(reagentMix);
        }

        await this.props.workflowConfigService.fetchReagentMixes(true);
        this.handleClose();
    }
    
}

export const ReagentMixPage = WorkflowsService.inject(WorkflowConfigService.inject(_ReagentMixPage));