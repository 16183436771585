import {
    FreezerService, IAjaxState, managedAjaxUtil,
} from "$Imports/Imports";

import {
    CalculationsApiFactory,
    CalculationRequestVM,
    CalculationResponseVM,
} from "$Generated/api";

import { apiExceptionHandler } from "$State/ErrorFreezerService";

const InjectedPropName = "calculationService";

interface ICalculationState {
    performCalculationResponse: IAjaxState<CalculationResponseVM>;
}

class CalculationFreezerService extends FreezerService<ICalculationState, typeof InjectedPropName>{
    constructor() {
        super({
            performCalculationResponse: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async performCalculation(calculationType: string, dataDictionary: { [key: string]: any }, argsDictionary: { [key: string]: any; }) {
        let postData : CalculationRequestVM = {
            CalculationType: calculationType,
            ArgsDictionary: argsDictionary,
            DataDictionary: dataDictionary
        };
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "performCalculationResponse",
            onExecute(apiOptions, params, options) {
                let factory = CalculationsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1CalculationsPerformCalculationPost(params);
            },
            params: {
                body: postData
            },
            onError: apiExceptionHandler
        });
    }
}

export const CalculationService = new CalculationFreezerService();