import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports"

import {
    IWorkflowScreenProps
} from "../WorkflowStep";

import { ConvertPositionToString } from "$Components/common/StandardPlate/StandardPlate";

import {
    StepActionsControl,
    StepChangeControl,
    CollapsibleSection,
    DataTable,
    IDataTableColumn,
    DataLoadingDisplay
} from "$Imports/CommonComponents";

import {
    PopulatePoolFromPlateRequestVM, PoolingResultVM
} from "$Generated/api";

import {
    Snackbar,
    TextField,
    Checkbox
} from "$Imports/MaterialUIComponents";

import {
    CalculationService
} from "$State/CalculationFreezerService";

const styles: {
    contentContainer: string;
    noMt: string;
    italicizedText: string;
    concentrationVolumeInputs: string;
    concentrationVolumeTable: string;
    finalDilutionContainer: string;
    finalDilutionTable: string;
    tableHeader: string;
    finalDilutionHighlighted: string;
    samplesGridContainer: string;
} = require("./Pooling.scss");

const commonStyles: {
    footerDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");

export interface IIlluminaPoolingState {
    avgLibrarySize: number;
    useBioanalyzer: boolean;
    completePlatePoolConcentration: number;
    targetConcentration: number;
    targetStartingVolume: number;
    poolVolumeToUse: number;
    rsbVolumeToUse: number;
    totalSamplesVolume: number;
    averageSampleConcentration: number;
    samplesCount: number;
    illuminaPoolingData: IIlluminaPoolingData[];
}

interface IIlluminaPoolingData {
    sampleId: string;
    wellPosition: string;
    currentSampleVolume: number;
    quantifiedConcentration: number;
    totalSample: number;
    wellId: string;
}

const DEFAULT_AVG_LIBRARY_SIZE = 600;
const DEFAULT_DABP = 650;
const DEFAULT_TARGET_CONCENTRATION = 4;
const DEFAULT_TARGET_STARTING_VOLUME = 1000;

export class IlluminaPoolingScreen extends React.Component<IWorkflowScreenProps, IIlluminaPoolingState> {

    state: IIlluminaPoolingState = {
        avgLibrarySize: DEFAULT_AVG_LIBRARY_SIZE,
        useBioanalyzer: false,
        completePlatePoolConcentration: 0,
        targetConcentration: DEFAULT_TARGET_CONCENTRATION,
        targetStartingVolume: DEFAULT_TARGET_STARTING_VOLUME,
        poolVolumeToUse: 0,
        rsbVolumeToUse: 0,
        totalSamplesVolume: 0,
        averageSampleConcentration: 0,
        samplesCount: 0,
        illuminaPoolingData: []
    };

    async componentDidMount() {
        await this.props.workflowRunService.fetchPlatesForStep(true); //we're not going to test for the anthrax workflow here because that uses MinION
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
		//This function sets state, so we don't need to have a loaded variable here
        this.calculateIlluminaPoolingData(DEFAULT_AVG_LIBRARY_SIZE);
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {

        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const plates = this.props.workflowRunService.getState().plates;

        if (currentStep && currentWorkflowRun) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            if (inputPlate) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.collapsibleDiv} style={{ display:"flex" }}>
                                <div className={styles.contentContainer}>
                                    <div>
                                        <h2 className={styles.noMt}>Initial Dilution</h2>
                                        <p className={styles.italicizedText}>This calculation supports the MiSeq (v3 reagents) sequencing system.</p>
                                        <div className={styles.concentrationVolumeInputs}>
                                            Avg. Library Size (bp): 
                                            <TextField type="number" inputProps={{ min: 0 }} value={this.state.avgLibrarySize} 
                                                disabled={!this.state.useBioanalyzer}
                                                onChange={(event) => {
                                                    this.setState({ avgLibrarySize: parseFloat(event.target.value) });
                                                    if (parseFloat(event.target.value) > 0)
                                                        this.calculateIlluminaPoolingData(parseFloat(event.target.value));
                                                }}/>
                                            <Checkbox value={this.state.useBioanalyzer} 
                                                onChange={(event) => {
                                                    this.setState({ useBioanalyzer: event.target.checked })
                                                    if(!event.target.checked) {
                                                        this.calculateIlluminaPoolingData(DEFAULT_AVG_LIBRARY_SIZE);
                                                    }
                                                }} />
                                            <span className={styles.italicizedText}>Use bioanalyzer results</span>
                                        </div>
                                        <table className={styles.concentrationVolumeTable}>
                                            <tr>
                                                <td>Complete Plate Pool Concen. (nM):</td>
                                                <td>{this.state.completePlatePoolConcentration.toFixed(1)}</td>
                                                <td style={{ fontWeight: "bold" }}>Pool Volume to Use (&#181;l):</td>
                                                <td style={{ fontWeight: "bold" }}>{this.state.poolVolumeToUse.toFixed(1)}</td>
                                            </tr>
                                            <tr>
                                                <td>Target Concen. (nM):</td>
                                                <td>{this.state.targetConcentration}</td>
                                                <td style={{ fontWeight: "bold" }}>RSB Volume to Use (&#181;l):</td>
                                                <td style={{ fontWeight: "bold" }}>{this.state.rsbVolumeToUse.toFixed(1)}</td>
                                            </tr>
                                            <tr>
                                                <td>Target Starting Volume (&#181;l):</td>
                                                <td>{this.state.targetStartingVolume}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div>
                                        <h2 className={styles.noMt}>Final Dilution</h2>
                                        <p className={styles.italicizedText}>This is the final step in the serial dilution</p>
                                        <p className={styles.italicizedText}>The highlighted column works for MiSeq (v3 reagents) sequencing.</p>
                                        <div className={styles.finalDilutionContainer}>
                                            <table className={styles.finalDilutionTable}>
                                                <tr>
                                                    <th>Final Library Conc. (pM)</th>
                                                    <td>8</td>
                                                    <td>10</td>
                                                    <td className={styles.finalDilutionHighlighted}>12</td>
                                                    <td>14</td>
                                                    <td>15</td>
                                                    <td>16</td>
                                                    <td>18</td>
                                                    <td>20</td>
                                                </tr>
                                                <tr>
                                                    <th>Pooled Library (&#181;l)</th>
                                                    <td>400</td>
                                                    <td>500</td>
                                                    <td className={styles.finalDilutionHighlighted}>600</td>
                                                    <td>700</td>
                                                    <td>750</td>
                                                    <td>800</td>
                                                    <td>900</td>
                                                    <td>1000</td>
                                                </tr>
                                                <tr>
                                                    <th>HT1 (&#181;l)</th>
                                                    <td>600</td>
                                                    <td>500</td>
                                                    <td className={styles.finalDilutionHighlighted}>400</td>
                                                    <td>300</td>
                                                    <td>250</td>
                                                    <td>200</td>
                                                    <td>100</td>
                                                    <td>0</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentContainer}>
                                    <span>Samples count: {this.state.samplesCount}</span>
                                    <div className={styles.samplesGridContainer}>
                                        <DataTable
                                            data={this.state.illuminaPoolingData}
                                            columns={this.samplePoolingColumns()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled || !(this.state.avgLibrarySize > 0)} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                    </div>)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private async moveToNextStepBegin() {

        let {
            currentStep
        } = this.props.workflowRunService;

        if (currentStep) {
            await this.completeMoveToNextStep();
        }
    }

    @bind
    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentStep && currentStep.OutputAssets) {
            let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName)
            let inputAsset = _.find(currentStep.toJS().InputAssets, a => a.Name === currentStep.InputName)
            const illuminaPoolingData = this.state.illuminaPoolingData;

            let poolingResults: PoolingResultVM[] = _.map(illuminaPoolingData, (val) => {
                return {
                    Id: val.wellId,
                    ScaledVolume: val.currentSampleVolume,
                    ShouldBePooled: (val.currentSampleVolume > 0)
                };
            });

            if(inputAsset && inputAsset.PlateSummary && outputAsset && outputAsset.PoolSummary && currentWorkflowRun) {
                let request: PopulatePoolFromPlateRequestVM =
                {
                    AssetId: inputAsset.PlateSummary.Id,
                    PoolId: outputAsset.PoolSummary.Id,
                    PoolingResults: poolingResults,
                    WorkflowRunId: currentWorkflowRun.Id
                };

                await Promise.all([
                    this.props.workflowRunService.updateCustomFields(),
                    this.props.workflowRunService.populatePoolFromPlate(request)
                ]);
                this.props.workflowRunService.completeStep();
            }
            
            
        }
    }

    private async calculateIlluminaPoolingData(averageLibrarySize: number) {
        const plates = this.props.workflowRunService.getState().plates;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const results: IIlluminaPoolingData[] = [];
        const currentStep = this.props.workflowRunService.currentStep;
        this.setState({ avgLibrarySize: averageLibrarySize });

        if (plates.data && currentStep && currentWorkflowRun) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName });
            if (inputPlate) {
                const currentSampleVolumeToUse = 28;
                const customFields = {
                    CurrentSampleVolumeToUse: currentSampleVolumeToUse,
                    AverageLibrarySize: averageLibrarySize
                };

                const poolingDataDictionary: { [index: string]: number } = {};

                _.forEach(inputPlate.WellContents, well => {
                    poolingDataDictionary[well.Id] = well.Concentration || 0;
                });

                await CalculationService.performCalculation("illuminapooling", poolingDataDictionary, customFields);
                let data = CalculationService.getState().performCalculationResponse.data?.CalculationResponse as { [key: string]: any };
                let calculatedPoolingData = data["poolingData"];

                _.forEach(inputPlate.WellContents, well => {
                    if (well.Concentration !== undefined) {
                        let calculatedData = calculatedPoolingData[well.Id];
                        results.push(
                            {
                                sampleId: well.Sample?.SampleId || well.Control?.SampleId || "",
                                wellPosition: ConvertPositionToString(well.WellPosition, currentWorkflowRun.AssetColCount, currentWorkflowRun.AssetRowCount, currentWorkflowRun.AssetPositionByRow),
                                quantifiedConcentration: well.Concentration,
                                currentSampleVolume: currentSampleVolumeToUse,
                                totalSample: calculatedData ? calculatedData.TotalSampleVolume : 0,
                                wellId: well.Id
                            }
                        );
                    }
                });

                this.setState({ 
                    samplesCount: results.length,
                    illuminaPoolingData: results, 
                    totalSamplesVolume: data["TotalSamplesVolume"], 
                    averageSampleConcentration: data["AverageConcentration"],
                    completePlatePoolConcentration: data["CompletePlatePoolConcentration"],
                    poolVolumeToUse: data["PoolVolumeToUse"],
                    rsbVolumeToUse: data["RsbVolumeToUse"]
                });

                this.props.workflowRunService.updateCustomField('UseBioanalyzerResults', this.state.useBioanalyzer ? 'Yes' : 'No');
                this.props.workflowRunService.updateCustomField('AvgLibrarySize', '' + averageLibrarySize);
                this.props.workflowRunService.updateCustomField('PoolVolumeToUse', data["PoolVolumeToUse"]);
                this.props.workflowRunService.updateCustomField('RSBVolumeToUse', data["RsbVolumeToUse"]);
            }
        }
    }

    private samplePoolingColumns(): Array<IDataTableColumn<IIlluminaPoolingData>> {
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentWorkflowRun) {
            return [
                {
                    columnName: "sample-id",
                    columnFieldData: (d) => d.sampleId,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Sample ID",
                    sortMethod: (d) => d.sampleId
                },
                {
                    columnName: "sample-well-position",
                    columnFieldData: (d) => d.wellPosition,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Well Position",
                    sortMethod: (d) => d.wellPosition
                },
                {
                    columnName: "current-sample-volume",
                    columnFieldData: (d) => d.currentSampleVolume,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Current Sample Volumn (\xB5/L)",
                    sortMethod: (d) => d.currentSampleVolume
                },
                {
                    columnName: "quantified-concentration",
                    columnFieldData: (d) => d.quantifiedConcentration,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Quantified Concentration (ng/\xB5L)",
                    sortMethod: (d) => d.quantifiedConcentration
                },
                {
                    columnName: "total-sample-weight",
                    columnFieldData: (d) => d.totalSample,
                    headerProps: {
                        className: styles.tableHeader,
                    },
                    headerValue: "Total DNA in Sample (ng)",
                    sortMethod: (d) => d.totalSample
                }
            ];
        }
        return [];
    }
}