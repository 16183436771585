import {
    NgsReportWetlabStandardizationVM,
} from "$Generated/api";
import {
    React,
    _,
    bind
} from "$Imports/Imports";
  
  
import { NavigationService } from "$State/NavigationFreezerService";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
var urljoin = require('url-join');

const commonStyles: {
    table: string;
} = require("../../../css/common.scss");


const wetLabStyles: {
    analysisTableHeader : string;
    pageLink : string;
    textEmphasis : string;
} = require("./WetLabReport.scss");


interface IWetLabStandardizationOfWorkflowProps {
    workflowRunId : string;
    dataWetlabStandardization: NgsReportWetlabStandardizationVM | undefined;
};

interface IWetLabStandardizationOfWorkflowState {
    stepRNAExtractionId : string;
}

export class WetLabStandardizationOfWorkflow extends React.Component<IWetLabStandardizationOfWorkflowProps, IWetLabStandardizationOfWorkflowState> {

    state : IWetLabStandardizationOfWorkflowState = {
        stepRNAExtractionId : '',
    }
    
    async componentDidMount(){
        
    }
    
    render() {

        // Get the guids for the step instances.
        if (this.props.dataWetlabStandardization){
            const oTmp = this.props.dataWetlabStandardization;
            oTmp.Steps?.map( s => {
                switch(s.StepNumber){
                    case 2:
                        if (this.state.stepRNAExtractionId === '') this.setState({stepRNAExtractionId: (s.StepInstanceId ? s.StepInstanceId : '')});
                        break;
                }
                
            });
        }

        // Table headers for (LL-2800)
        var colHeadersStandardizationWetLabWorkflow = ['Sample ID', 'Concentration ng/uL', 'Target Pool DNA Amount\n(ng)', 'Volume Factor', 'Scaled Volume'];

        return (
            
            <div>
                
                <h2>Standardization of Wet-Lab Workflow</h2>
                
                <section className={wetLabStyles.textEmphasis}>Detection of proteinase K in components:</section>
                <section>Instructions are provided in <a onClick={e => this.clickLink(e, this.state.stepRNAExtractionId)} className={wetLabStyles.pageLink}>Step #2: RNA Extraction</a> to ensure that the QIAmp Mini Kit does not have proteinase K.</section>

                <h2>Library Preparation Calculations</h2>

                <TableContainer component={Paper} >
                <Table className={commonStyles.table}>
                    <TableHead>
                        <TableRow>
                            {colHeadersStandardizationWetLabWorkflow.map(h => (
                                <TableCell key={h} style={{fontWeight: 'bold', textAlign: 'center'}}>{h}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                        {this.props.dataWetlabStandardization && this.props.dataWetlabStandardization.Samples?.map( s => (
                        <TableRow key={s.SampleId}>
                            <TableCell style={{textAlign: 'center'}}>{s.SampleId}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{s.Concentration}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{s.TargetPoolDNAAmount}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{s.VolumeFactor}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{s.ScaledVolume}</TableCell>
                        </TableRow>
                        ))}
                        
                    <TableBody>
                        
                    </TableBody>
                </Table>
                </TableContainer>

            </div>

            

        )
      
    }

    // Click event for links to workflow run steps.
    clickLink = (event : React.MouseEvent<HTMLAnchorElement>, stepGuid : string) => {
        event.preventDefault();
        this.navigateToWorkflowStep(stepGuid);
    }
    
    @bind
    private navigateToWorkflowStep(stepGuid : string) : void {
        const navigateToUrl = urljoin('/WorkflowRun', this.props.workflowRunId, 'step', stepGuid);
        NavigationService.navigateTo(navigateToUrl);
    }

}
