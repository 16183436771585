import {
    React,
    bind,
    _,
} from "$Imports/Imports"

import { IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, CollapsibleSection, DataLoadingDisplay, BioanalyzerInstrument, SimpleQuantificationInstrument, PCRControl, SafeStoppingPointComponent } from "$Imports/CommonComponents";

import {
    CalculationService
} from "$State/CalculationFreezerService";

import {
    WellContentVM, PopulatePoolFromPlateRequestVM, PoolingResultVM, QCCheckInstanceVM, QCCheckResultVM
} from "$Generated/api";

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

const styles: {
    poolingTable: string;
    poolingTableRow: string;
    poolingTableData: string;
} = require("./NbsPooling.scss");

interface IPoolWellData {
    volume: number;
}

export interface INbsPoolingState {
    wellDataDictionary: { [index: string]: number };
    sampleCount: number;
    controlCount: number;
    volumePerWell: number;
    totalVolume: number;
    RSBVolume: number;
    messageSnackbarOpen: boolean;
    message: string;
}

export class NbsPoolingScreen extends React.Component<IWorkflowScreenProps, INbsPoolingState> {

    state: INbsPoolingState = {
        wellDataDictionary: {},
        sampleCount: 0,
        controlCount: 0,
        volumePerWell: 0,
        totalVolume: 0,
        RSBVolume: 0,
        messageSnackbarOpen: false,
        message: ""
    };

    async componentDidMount() {
        await this.props.workflowRunService.fetchSimplifiedReagentListForStep(true);
        await this.props.workflowRunService.fetchPlatesForStep(true);
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
        this.calculateNbsPoolData();
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {


        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;

        if (currentWorkflowRun && currentStep && masterMixInfoState.data && lotOptions) {
            let customFields = currentStep.toJS().CustomFields;
            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return (
                <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={commonStyles.mainDiv}>
                            <div style={{ float: "left", margin: "15px" }}>
                                <h2>Pooling Instructions</h2>
                                <table className={styles.poolingTable}>
                                    <tr className={styles.poolingTableRow}>
                                        <th className={styles.poolingTableRow}># of Samples</th>
                                        <th className={styles.poolingTableRow}># of Controls</th>
                                        <th className={styles.poolingTableRow}>Volume from Each Sample or Control (uL)</th>
                                        <th className={styles.poolingTableRow}>Total Sample and Control Volume (uL)</th>
                                        <th className={styles.poolingTableRow}>Volume of RSB to Use (uL)</th>
                                    </tr>
                                    <tr className={styles.poolingTableRow}>
                                        <td className={styles.poolingTableRow}>{this.state.sampleCount}</td>
                                        <td className={styles.poolingTableRow}>{this.state.controlCount}</td>
                                        <td className={styles.poolingTableRow}>{this.state.volumePerWell}</td>
                                        <td className={styles.poolingTableRow}>{this.state.totalVolume}</td>
                                        <td className={styles.poolingTableRow}>{this.state.RSBVolume}</td>
                                    </tr>
                                </table>
                                <div style={{ float: "left", margin: "15px" }}> <SafeStoppingPointComponent /> </div>
                            </div>
                            <div style={{ float: "right", margin: "15px" }}>
                                <h2>Reagent Information</h2>
                                <MasterMixControl
                                    disabled={disabled}
                                    index={0}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={0}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { }}
                                    hideHeaders
                                />
                            </div>
                        </div>
                    </CollapsibleSection>
                    <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                    </div>
                </div> 
            )    
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private async moveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (currentStep && currentWorkflowRun) {
            let outputAsset = _.find(currentStep.toJS().OutputAssets, a => a.Name === currentStep.OutputName)
            let inputAsset = _.find(currentStep.toJS().InputAssets, a => a.Name === currentStep.InputName)

            if (inputAsset && inputAsset.PlateSummary && outputAsset && outputAsset.PoolSummary) { 
                let poolingResults: PoolingResultVM[] = _.map(this.state.wellDataDictionary, (val, id) => {
                    return {
                        Id: id,
                        ScaledVolume: this.state.volumePerWell,
                        ShouldBePooled: (this.state.volumePerWell > 0)
                    };
                });

                let request: PopulatePoolFromPlateRequestVM =
                {
                    AssetId: inputAsset.PlateSummary.Id,
                    PoolId: outputAsset.PoolSummary.Id,
                    PoolingResults: poolingResults,
                    WorkflowRunId: currentWorkflowRun.Id
                };
                    
                await Promise.all([
                    this.props.workflowRunService.updateCustomFields(),
                    this.props.workflowRunService.populatePoolFromPlate(request)
                ]);
                await this.props.workflowRunService.completeStep();
            }

        }
    }

    @bind
    private async calculateNbsPoolData() {
        const plates = this.props.workflowRunService.getState().plates;
        const currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (plates.data && currentStep && currentWorkflowRun) {
            let inputPlate = _.find(plates.data, (p) => { return p.Name === currentStep.InputName});
            if (inputPlate) {
                const customFields = {
                    SampleName: "",
                    WellVolume: "",
                };

                const dataDictionary: { [index: string]: number } = {};
                const wellDictionary: { [index: string]: number } = {};

                _.forEach(inputPlate.WellContents, well => {
                    if (well.Control) {
                        dataDictionary[well.Control.SampleId] = well.Concentration || 0;
                    }
                    else if (well.Sample) {
                        dataDictionary[well.Sample.SampleId] = well.Concentration || 0;
                    }
                    wellDictionary[well.Id] = 0;
                });

                await CalculationService.performCalculation("nbsPooling", dataDictionary, customFields);
                let data = CalculationService.getState().performCalculationResponse.data?.CalculationResponse as { [key: string]: any };

                this.setState({
                    sampleCount: data["sampleCount"],
                    controlCount: data["controlCount"],
                    volumePerWell: data["sampleVolumeToUse"],
                    totalVolume: data["totalVolume"],
                    RSBVolume: data["RSB"],
                    wellDataDictionary: wellDictionary,
                });
            }
        }
    }
}