import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";

import { FileSharingService, IFileSharingServiceInjectedProps, FileSharingAPI } from "$State/FileShareManagementService";

import { SharingService, ISharingServiceInjectedProps } from "$State/SharingFreezerService";

import { Button, Card, Dialog, DialogActions, IconButton, RefreshIcon, TreeView, TreeItem, DataGrid, GridColDef, GridValueFormatterParams, Checkbox, FormControlLabel } from "$Imports/MaterialUIComponents";

import { ExpandMoreIcon, ChevronRightIcon } from "$Imports/MaterialUIIcons";

import {
    AdvanceTextField, DataTable, IDataTableColumn, ProcessAdminRole,
} from "../../imports/CommonComponents";

import { GridCellParams, GridSelectionModelChangeParams } from "@material-ui/data-grid";
import { lastIndexOf } from "lodash";
import { ThreeSixty } from "@material-ui/icons";
import { RemoteAssociationVM, FileShareWith } from "$Generated/api";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

import { InitializeChunkedUploadVM, FileShareApiFactory } from "$Generated/api";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    dialog: string;
    columnButton: string;
    rowButton: string;
    threeColumnTable: string;
    threeColumnTableColumn: string;
    modalContent: string;
    tableHeader: string;
    errorText: string;
} = require("./FileShareManagement.scss");


interface IFileShareManagementPageBaseProps {
    defaultOpenDirectory?: string;
}

interface IFileShareManagementState {
    fileData: IFileData[],
    directories: IDirectoryTree,
    sharedWithDialog: boolean,
    sharedWithInfo: FileShareWith[],
    selectedFilesOrDirectory: string[],
    currentlySelected: string,
    deleteDialog: boolean,
    createFileOrDirectoryDialog: boolean,
    creating: string,
    expanded: string[],
    uploadFile?: File,
    creatingDirectoryName?: string,
    associationData?: RemoteAssociationVM[] | null,
    showDuplicateFileError: boolean,
    showDuplicateDirectoryError: boolean,
    showSharedFileDeleteError: boolean,
    showPopulatedDirectoryDeleteError: boolean,
    showUploadFailureError: boolean,
    userRoles: string[]
}

interface IDirectoryTree {
    Id: string,
    Name: string,
    Subdirectories?: IDirectoryTree[];
}

interface IFileData {
    id: string,
    fileName: string,
    type: string,
    createdOn: Date,
    fileSharedTo: number,
    fileSharedToHidden: FileShareWith[],
    fileReceivedFrom?: string
}

type IFileShareManagementPageProps = IFileShareManagementPageBaseProps & IFileSharingServiceInjectedProps & ISharingServiceInjectedProps;

export class _FileShareManagmentPage extends React.Component<IFileShareManagementPageProps, IFileShareManagementState> {

    constructor(props: IFileShareManagementPageProps) {
        super(props);
    }

    state: IFileShareManagementState = {
        fileData: [],
        directories: {
            Id: 'root',
            Name: 'My Directories',
            Subdirectories: [
            ]
        },
        sharedWithDialog: false,
        sharedWithInfo: [],
        selectedFilesOrDirectory: [],
        currentlySelected: "Directory",
        deleteDialog: false,
        createFileOrDirectoryDialog: false,
        creating: "",
        expanded: [],
        uploadFile: undefined,
        creatingDirectoryName: undefined,
        associationData: [],
        showDuplicateFileError: false,
        showDuplicateDirectoryError: false,
        showSharedFileDeleteError: false,
        showPopulatedDirectoryDeleteError: false,
        showUploadFailureError: false,
        userRoles: []
    }

    // Column definitions for the file data datagrid
    private readonly columns: GridColDef[] = [
        {
            field: "fileName",
            headerName: "File Name",
            width: 150,
            flex: 2
        },
        {
            field: "type",
            headerName: "Type",
            width: 100,
            flex: 1
        },
        {
            field: "fileCreatedOn",
            headerName: "Created On",
            valueFormatter: (params: GridValueFormatterParams) => moment(params.row.createdOn as Date).format("L LT"),
            width: 200,
            flex: 1
        },
        {
            field: "downloadLink",
            headerName: "Download Link",
            width: 200,
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            variant="contained"
                            color="primary"
                            className={styles.rowButton}
                            onClick={() => {
                                this.downloadFile(params.id.toString());
                            }}
                            disabled={this.state.userRoles == null || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} >
                            Download
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>
            )
        },
        {
            field: "userRolesHidden",
            hide: true
        }
    ]

    // Populate and display the modal containing file sharing information
    showShareWithModal = (fileShares: FileShareWith[]) => {
        this.setState({ sharedWithInfo: fileShares, sharedWithDialog: true });
    }

    async componentDidMount() {
        await this.props.SharingService.fetchRemoteAssociations();
        let {
            remoteAssociationsResult,
        } = this.props.SharingService.getState();
        this.setState({ associationData: _.filter(remoteAssociationsResult.data, remoteAssociation => remoteAssociation.State == "ASSOCIATED") });
        // load root files and directories
        await this.loadRootFilesAndDirectories();
        if(this.props.defaultOpenDirectory)
        {
            //Load run files
            await this.loadFiles({}, "run/"+this.props.defaultOpenDirectory);
        }
    }


    render() {
        // Display directory as treeitems and runs again against all the directory's subdirectories
        const renderTree = (nodes: IDirectoryTree) => {
            return (
                <TreeItem key={nodes.Id} nodeId={nodes.Id} label={nodes.Name} >
                    {Array.isArray(nodes.Subdirectories) ? nodes.Subdirectories.map((node) => renderTree(node)) : null}
                    <TreeItem key={nodes.Id + "?empty"} nodeId={nodes.Id + "?empty"} label=""></TreeItem>
                </TreeItem>
            )
        }

        //Determine and display the correct file path where newly added file or directory will live
        const showFilePathInModal = () => {
            let filePath: string = "";
            if (this.state.selectedFilesOrDirectory.length == 0 || this.state.selectedFilesOrDirectory[0] == "root" ||
                (this.state.currentlySelected == "File" && this.state.selectedFilesOrDirectory[0].lastIndexOf("/") == -1)) {
                filePath = "/";
            }
            else if (this.state.currentlySelected == "File") {
                filePath = "/" + this.state.selectedFilesOrDirectory[0].substring(0, this.state.selectedFilesOrDirectory[0].lastIndexOf("/")) + "/";
            }
            else {
                filePath = "/" + this.state.selectedFilesOrDirectory[0] + "/"
            }
            return (
                <div style={{ paddingTop: "10px" }}>
                    At file path: {filePath}
                </div>
            )
        }

        //Trigger the file input
        const fileUploadButton = () => {
            document.getElementById('fileButton')?.click();
        }

        //Stores selected files and marks the currently selected object as a file
        const selectionChange = (param: GridSelectionModelChangeParams) => {
            this.setState({
                selectedFilesOrDirectory: param.selectionModel as string[],
                currentlySelected: "File"
            });
        }

        //Store the selected file in React state
        const saveUploadFile = () => {
            const file = document.getElementById('fileButton') as HTMLInputElement;
            if (file.files != null) {
                this.setState({ uploadFile: file.files[0] })
            }
        }

        return <ApplicationSecurityContext.Consumer>
            {value => (
                <div className={styles.mainContainer}>
                    <Card className={styles.cardStyle}>
                        <div className={styles.content}>
                            <div style={{ paddingRight: "20px", maxWidth: "400px" }}>
                                <h2>My Directories</h2>
                                {/* directory tree view */}
                                <TreeView
                                    style={{ paddingBottom: "20px", height: "300px", maxHeight: "300px", overflowY: "auto" }}
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    onNodeSelect={this.loadFiles}
                                    onNodeToggle={this.loadDirectories}
                                    expanded={this.state.expanded}
                                >
                                    {
                                        renderTree(this.state.directories)
                                    }
                                </TreeView>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={styles.columnButton}
                                        onClick={() => { this.setState({ createFileOrDirectoryDialog: true, creating: "File" }) }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} >
                                        Upload File
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={styles.columnButton}
                                        onClick={() => { this.setState({ createFileOrDirectoryDialog: true, creating: "Directory" }) }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} >
                                        Create Folder
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={styles.columnButton}
                                        onClick={this.refreshGrid} >
                                        Refresh Directory
                                    </Button>
                                </div>
                            </div>
                            {/* Shared with information dialog */}
                            <Dialog
                                className={styles.dialog}
                                open={this.state.sharedWithDialog}
                                onClose={() => { this.setState({ sharedWithDialog: false }) }}
                                PaperProps={{ style: { width: "500px", height: "200px" } }}
                            >
                                <div>
                                    <table className={styles.threeColumnTable}>
                                        <tbody>
                                            <tr>
                                                <th className={styles.threeColumnTableColumn}>
                                                    <strong>Shared With</strong>
                                                </th>
                                                <th className={styles.threeColumnTableColumn}>
                                                    <strong>Shared On</strong>
                                                </th>
                                                <th className={styles.threeColumnTableColumn}>
                                                    <strong>Shared Status</strong>
                                                </th>
                                            </tr>
                                            {
                                                this.state.sharedWithInfo.map((sharedInfo) => {
                                                    return <tr>
                                                        <td className={styles.threeColumnTableColumn}>{(this.state.associationData?.find(association => association.Id == sharedInfo.InstanceId)?.Alias)}</td>
                                                        <td className={styles.threeColumnTableColumn}>{(sharedInfo.CreatedOn == undefined ? "" : moment(new Date(sharedInfo.CreatedOn)).format("L LT"))}</td>
                                                        <td className={styles.threeColumnTableColumn}>{(sharedInfo.State)}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Dialog>
                            {/* delete file or directory dialog */}
                            <Dialog
                                className={styles.dialog}
                                open={this.state.deleteDialog}
                                onClose={() => { this.setState({ deleteDialog: false, showPopulatedDirectoryDeleteError: false, showSharedFileDeleteError: false }) }}
                            >
                                <div className={styles.modalContent}>
                                    <div>
                                        <h2>Are you sure you want to delete the following?</h2>
                                    </div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <ul>
                                            {
                                                this.state.selectedFilesOrDirectory.map((fileOrDirectoryPath) => {
                                                    return <li>{(fileOrDirectoryPath)}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.modalContent}>
                                    {this.state.showPopulatedDirectoryDeleteError && <div className={styles.errorText}>This folder cannot be deleted because it is not empty</div>}
                                    {this.state.showSharedFileDeleteError && <div className={styles.errorText}>Shared files cannot be deleted</div>}
                                </div>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.deleteFilesOrDirectories();
                                        }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.setState({ deleteDialog: false, showPopulatedDirectoryDeleteError: false, showSharedFileDeleteError: false }) }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* create file or directory dialog */}
                            <Dialog
                                className={styles.dialog}
                                open={this.state.createFileOrDirectoryDialog}
                                onClose={() => { this.setState({ createFileOrDirectoryDialog: false, showDuplicateFileError: false, showDuplicateDirectoryError: false, showUploadFailureError: false }) }}
                            >
                                {/* Display File Or Directory adding modal based on which action was selected */}
                                <div className={styles.modalContent}>
                                    {this.state.creating == "File" ?
                                        <div>
                                            <div>
                                                <h2>Choose a file to upload</h2>
                                            </div>
                                            <input id="fileButton" type="file" hidden onChange={saveUploadFile} />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={styles.columnButton}
                                                onClick={fileUploadButton}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} >
                                                Choose File
                                            </Button>
                                            <div>
                                                {this.state.uploadFile ? this.state.uploadFile.name : ""}
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div>
                                                <h2>Create a Directory</h2>
                                            </div>
                                            <AdvanceTextField
                                                InputLabelProps={{ shrink: true }}
                                                label={"Directory Name"}
                                                value={this.state.creatingDirectoryName}
                                                onDebouncedChange={(value) => { this.setState({ creatingDirectoryName: value }) }}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} />
                                        </div>
                                    }
                                    {
                                        showFilePathInModal()
                                    }
                                </div>
                                <div className={styles.modalContent}>
                                    {this.state.showDuplicateFileError && <div className={styles.errorText}>A file with that name already exists in this directory</div>}
                                    {this.state.showDuplicateDirectoryError && <div className={styles.errorText}>A subdirectory with that name already exists in this directory</div>}
                                    {this.state.showUploadFailureError && <div className={styles.errorText}>An error occurred when uploading</div>}
                                </div>
                                <DialogActions>
                                    {this.state.creating == "File" ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.uploadFile();
                                            }}
                                            disabled={this.state.uploadFile == undefined || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        > Upload File
                                        </Button>
                                        : <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.createDirectory();
                                            }}
                                            disabled={this.state.creatingDirectoryName == undefined || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                        >Create Directory
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.setState({ createFileOrDirectoryDialog: false, creating: "", showDuplicateFileError: false, showDuplicateDirectoryError: false, showUploadFailureError: false }) }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
     
                            <div style={{ width: "100%" }}>
                                <h2>Details</h2>
                                <div style={{ height: "500px" }}>
                                    {/* file metadata grid */}
                                    <DataGrid
                                        rows={this.state.fileData}
                                        columns={this.columns}
                                        checkboxSelection
                                        onSelectionModelChange={selectionChange}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.selectedFilesOrDirectory[0] == "root" || this.state.selectedFilesOrDirectory.length == 0}
                                        className={styles.rowButton}
                                        onClick={() => { }} >
                                        Move
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.currentlySelected != "File" || this.state.selectedFilesOrDirectory.length == 0}
                                        className={styles.rowButton}
                                        onClick={() => { }} >
                                        Copy
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={styles.rowButton}
                                        onClick={() => { this.setState({ deleteDialog: true }) }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.selectedFilesOrDirectory[0] == "root" || this.state.selectedFilesOrDirectory.length == 0}>
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </ApplicationSecurityContext.Consumer>;
    }

    //Loads files and directories in the root directory
    @bind
    private async loadRootFilesAndDirectories() {
        await this.props.FileSharingService.fetchRootDirectoryListing(true);
        let {
            directoryListingResult
        } = this.props.FileSharingService.getState();

        let fileData: IFileData[] = [];
        directoryListingResult.data?.Files.forEach(file => {
            fileData.push({ id: file.Name, fileName: file.Name, type: file.ContentType, createdOn: new Date(file.CreatedOn), fileSharedTo: file.SharedWith.length, fileSharedToHidden: file.SharedWith, fileReceivedFrom: this.state.associationData?.find(association => association.Id == file.SharedFrom)?.Alias })
        });

        let initialTree: IDirectoryTree = this.state.directories;
        directoryListingResult.data?.Directories.forEach(directory => {
            if (initialTree.Subdirectories) {
                initialTree.Subdirectories.push({ Id: directory.Name, Name: directory.Name })
            }
        });

        this.setState({
            fileData: fileData, directories: initialTree, expanded: ["root"]
        })
    }

    // load files at a given path
    @bind
    private async loadFiles(event: object, value: string) {
        let fileData: IFileData[] = [];
        let filePath: string;
        if (value == undefined || value == "root" || value == "") {
            await this.props.FileSharingService.fetchRootFiles();
            filePath = "";
        }
        else {
            await this.props.FileSharingService.fetchFiles(value);
            filePath = value + "/"
        }

        let {
            currentFiles
        } = this.props.FileSharingService.getState();

        currentFiles.data?.forEach(file => {
            fileData.push({ id: filePath + file.Name, fileName: file.Name, type: file.ContentType, createdOn: new Date(file.CreatedOn), fileSharedTo: file.SharedWith.length, fileSharedToHidden: file.SharedWith, fileReceivedFrom: this.state.associationData?.find(association => association.Id == file.SharedFrom)?.Alias })
        });

        let currentExpanded: string[] = this.state.expanded;
        if (currentExpanded.includes(value)) {
            if (value == "root") {
                currentExpanded = [];
            }
            else {
                currentExpanded.splice(currentExpanded.indexOf(value), 1);
                for (let i = 0; i < currentExpanded.length; i++) {
                    if (currentExpanded[i].indexOf(value) == 0) {
                        currentExpanded.splice(currentExpanded.indexOf(currentExpanded[i]), 1);
                        i--;
                    }
                }
            }
        }
        else {
            currentExpanded.push(value);
        }

        if (value == "") {
            this.setState({
                fileData: fileData, selectedFilesOrDirectory: [], currentlySelected: "Directory", expanded: currentExpanded
            })
        } else {
            this.setState({
                fileData: fileData, selectedFilesOrDirectory: [value], currentlySelected: "Directory", expanded: currentExpanded
            })
        }
    }

    // load directories at a given path
    @bind
    private async loadDirectories(event: object, nodeIds: Array<string>) {
        if (nodeIds[0] != "root") {
            let resetTree: IDirectoryTree = _.cloneDeep(this.state.directories)
            resetTree = await this.loadIntoExpandedDirectory(resetTree, nodeIds);
            this.setState({ directories: resetTree });
        }
    }

    //Gets the selected file and posts it and some metadata to the
    //Fileshare controller as form data
    @bind
    private async uploadFile() {
        if (this.state.uploadFile != undefined) {
            let fileCopy: boolean = false;
            this.state.fileData.forEach((file) => {
                if (file.fileName == this.state.uploadFile?.name) {
                    fileCopy = true;
                }
            })
            if (fileCopy) {
                this.setState({ showDuplicateFileError: true })
            }
            else {
                this.setState({ showDuplicateFileError: false, showDuplicateDirectoryError: false })
                const data = new FormData();
                data.append('Upload', this.state.uploadFile);
                data.append('Type', "FILE");
                data.append('LastModified', new Date(this.state.uploadFile.lastModified).toISOString());
                let filePath: string = "";
                if (this.state.selectedFilesOrDirectory.length == 0 || this.state.selectedFilesOrDirectory[0] == "root") {
                    filePath = ""
                }
                else if (this.state.currentlySelected == "File") {
                    filePath = this.state.selectedFilesOrDirectory[0];
                    filePath = filePath.substring(0, filePath.lastIndexOf("/") + 1);
                }
                else if (this.state.currentlySelected == "Directory") {
                    filePath = this.state.selectedFilesOrDirectory[0];
                }

                // Use chunked upload for > 10 MB
                let chunkSize = 1024*1024*10;
                if (this.state.uploadFile.size > chunkSize) {
                    let numChunks = Math.ceil(this.state.uploadFile.size / chunkSize);

                    const headers: HeadersInit = new Headers();
                    headers.set('Content-Type', 'application/json');

                    await FileSharingService.initializeChunkedUpload(filePath, numChunks, chunkSize, this.state.uploadFile.name, this.state.uploadFile.size, this.state.uploadFile.type);
                    var result = FileSharingService.getState().initializeUploadResult.data;
                    if (result?.guid == "" || FileSharingService.getState().initializeUploadResult.error != null) {
                        this.setState({showUploadFailureError: true});
                        return;
                    }
                    var guid = result?.guid.replace(/\\/g, '').replace(/\"/g, '');

                    for (var chunk = 0; chunk < numChunks; chunk++) {
                        const chunk_data = new FormData();
                        chunk_data.append("file", this.state.uploadFile.slice(chunk * chunkSize, (chunk + 1) * chunkSize));
                        await fetch(FileSharingAPI + 'UploadChunk/' + guid + "/" + (chunk + 1), { method: "PUT", body: chunk_data }).then(
                            function (res) {
                                var success = true;
                            }
                        )
                    }
                } else {
                    await fetch(FileSharingAPI + 'CreateFileOrDirectory/' + filePath + "/" + this.state.uploadFile.name, { method: "PUT", body: data }).then(
                        function (res) {
                            var success = true;
                        }
                    )
                }
                this.setState({ createFileOrDirectoryDialog: false, uploadFile: undefined, creating: "" })
                await this.loadFiles(new Object(), filePath.substring(0, filePath.lastIndexOf("/")))
            }
        }
    }

    //Posts back the path for the newly created directory to the
    //Fileshare controller as form data
    @bind
    private async createDirectory() {
        if (this.state.creatingDirectoryName != undefined) {
            const data = new FormData();
            data.append('Type', "Directory");
            let filePath: string = "";
            let currentNode: string = "";
            if (this.state.selectedFilesOrDirectory.length == 0 || this.state.selectedFilesOrDirectory[0] == "root"
                || (this.state.currentlySelected == "File" && filePath.lastIndexOf("/") == -1)) {
                filePath = this.state.creatingDirectoryName;
                currentNode = "root";
            }
            else if (this.state.currentlySelected == "File") {
                filePath = this.state.selectedFilesOrDirectory[0];
                filePath = filePath.substring(0, filePath.lastIndexOf("/"));
                currentNode = filePath.substring(filePath.lastIndexOf("/") + 1);
                filePath = filePath + "/" + this.state.creatingDirectoryName;

            }
            else if (this.state.currentlySelected == "Directory") {
                currentNode = this.state.selectedFilesOrDirectory[0];
                filePath = this.state.selectedFilesOrDirectory[0] + "/" + this.state.creatingDirectoryName;
            }

            if (this.hasChild(filePath, this.state.directories)) {
                this.setState({ showDuplicateDirectoryError: true });
            }
            else {
                this.setState({ showDuplicateFileError: false, showDuplicateDirectoryError: false })
                await fetch(FileSharingAPI + 'CreateFileOrDirectory/' + filePath, { method: "PUT", body: data }).then(
                    function (res) {
                        var success = true;
                    }
                )

                if (this.state.selectedFilesOrDirectory.length == 0 || this.state.selectedFilesOrDirectory[0] == "root"
                    || (this.state.currentlySelected == "File" && filePath.lastIndexOf("/") == -1)) {
                    this.setState({
                        createFileOrDirectoryDialog: false, creatingDirectoryName: undefined, creating: "", directories: {
                            Id: 'root',
                            Name: 'My Directories',
                            Subdirectories: []
                        }
                    });
                    this.loadRootFilesAndDirectories();
                }
                else {
                    await this.loadDirectories(new Object(), [currentNode]);

                    let currentExpanded: string[] = this.state.expanded;
                    for (let i = 0; i < currentExpanded.length; i++) {
                        if (currentExpanded[i].indexOf(filePath.substring(0, filePath.lastIndexOf("/")) + "/") == 0) {
                            currentExpanded.splice(currentExpanded.indexOf(currentExpanded[i]), 1);
                        }
                    }
                    this.setState({
                        createFileOrDirectoryDialog: false, creatingDirectoryName: undefined, creating: "", expanded: currentExpanded
                    });
                }
            }
        }
    }

    //Delete currently selected files or directory
    @bind
    private async deleteFilesOrDirectories() {
        var totalShares = 0;
        if (this.state.currentlySelected == "File") {
            for (let i = 0; i < this.state.selectedFilesOrDirectory.length; i++) {
                var fileSelected = _.find(this.state.fileData, fd => fd.id === this.state.selectedFilesOrDirectory[i]);
                if (fileSelected) {
                    totalShares += fileSelected.fileSharedTo;
                }
            }
        }

        if (this.state.currentlySelected == "Directory" &&
            (this.hasChild(this.state.selectedFilesOrDirectory[0] + "/*", this.state.directories) ||
                this.state.fileData.length > 0)) {
            this.setState({ showPopulatedDirectoryDeleteError: true });
        }
        else if (this.state.currentlySelected == "File" &&
            totalShares > 0) {
            this.setState({ showSharedFileDeleteError: true });
        }
        else {
            let filePath: string = this.state.selectedFilesOrDirectory[0];
            for (let i = 0; i < this.state.selectedFilesOrDirectory.length; i++) {
                if (this.state.selectedFilesOrDirectory[i] != "root") {
                    await this.props.FileSharingService.deleteFileOrDirectory(this.state.selectedFilesOrDirectory[i]);
                }
            }
            if (this.state.currentlySelected == "File") {
                await this.loadFiles(new Object(), filePath.substring(0, filePath.lastIndexOf("/")))
            }
            else if (this.state.currentlySelected == "Directory") {
                if (this.state.selectedFilesOrDirectory[0].indexOf("/") == -1) {
                    this.setState({
                        directories: {
                            Id: 'root',
                            Name: 'My Directories',
                            Subdirectories: []
                        }
                    });
                    this.loadRootFilesAndDirectories();
                }
                else {
                    await this.loadDirectories(new Object(), [filePath.substring(0, filePath.lastIndexOf("/"))]);
                }
                let currentExpanded: string[] = this.state.expanded;
                for (let i = 0; i < currentExpanded.length; i++) {
                    if (currentExpanded[i].indexOf(filePath.substring(0, filePath.lastIndexOf("/")) + "/") == 0) {
                        currentExpanded.splice(currentExpanded.indexOf(currentExpanded[i]), 1);
                        i--;
                    }
                    this.setState({ expanded: currentExpanded })
                }
            }

            this.setState({ deleteDialog: false, showPopulatedDirectoryDeleteError: false })
        }
    }

    // download a file at a given path
    @bind
    private async downloadFile(filePath: string) {
        var fileName = filePath;
        if (fileName.lastIndexOf("/") != -1) {
            fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
        }
        await fetch(FileSharingAPI + 'DownloadFile/' + filePath, { method: "GET" }).then(
            response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                var fileDownload = document.createElement("a");
                fileDownload.href = url;
                fileDownload.download = fileName;
                fileDownload.click();
                URL.revokeObjectURL(url);
            })
    }

    //HELPER FUNCTIONS

    //Check if a specific subdirectory or any subdirectories exist on a node
    @bind
    public hasChild(path: string, tree: IDirectoryTree): boolean {
        if (path.indexOf("/") != -1 && tree.Subdirectories != undefined) {
            var nextDir = tree.Subdirectories.find(node => node.Name == path.substring(0, path.indexOf("/")));
            if (nextDir?.Subdirectories != undefined && nextDir?.Subdirectories.length > 0) {
                return this.hasChild(path.substring(path.indexOf("/") + 1), nextDir);
            }
            else {
                return false;
            }
        }
        else if (path == "*") {
            return (tree.Subdirectories != undefined && tree.Subdirectories.length > 0);
        }
        else {
            return (tree.Subdirectories != undefined && (tree.Subdirectories.find(node => node.Name == path) != undefined));
        }
    }

    @bind
    private async refreshGrid() {
        if (this.state.currentlySelected == "File") {
            let filePath: string = this.state.selectedFilesOrDirectory[0];
            await this.loadFiles(new Object(), filePath.substring(0, filePath.lastIndexOf("/")))
        }
        else if (this.state.currentlySelected == "Directory") {
            let filePath: string = this.state.selectedFilesOrDirectory[0];
            await this.loadFiles(new Object(), filePath)
        }
    }

    // determine where in the directory tree the newly loaded directories belong and store the directories there
    @bind
    private async loadIntoExpandedDirectory(tree: IDirectoryTree, toggledNode: Array<string>) {
        // If the current node matches the expanded node and its subdirectories haven't been loaded
        // Load directories from the given file path into the current node's subdirectories
        if (tree.Id == toggledNode[0]) {
            await this.props.FileSharingService.fetchDirectories(toggledNode[0]);
            let {
                currentDirectories
            } = this.props.FileSharingService.getState();

            let directoryData: IDirectoryTree[] = [];

            currentDirectories.data?.forEach(directory => {
                directoryData.push({ Id: toggledNode[0] + "/" + directory.Name, Name: directory.Name })
            });

            tree.Subdirectories = directoryData;
        } //If the current node has subdirectories, run on each subdirectory
        else if (tree.Subdirectories != null) {
            for (let i = 0; i < tree.Subdirectories.length; i++) {
                tree.Subdirectories[i] = await this.loadIntoExpandedDirectory(tree.Subdirectories[i], toggledNode);
            }
        }
        return tree;
    }
}


export const FileShareManagementPage = SharingService.inject(FileSharingService.inject(_FileShareManagmentPage));