import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    DataLoadingDisplay,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import {
    AssetService,
    IAssetServiceInjectedProps
} from "$State/AssetFreezerService";
import { ControlMaterialVM, TemperatureProgramStepCycleVM, TemperatureProgramStepVM, TemperatureProgramVM } from "$Generated/api";
import { numberFormat } from "underscore.string";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { ChangeEvent } from "react";

import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
} = require("./LabAssets.scss");

interface ITemperatureProgramsPageBaseProps {
}

interface ITemperatureProgramsPageState {
    editDialogOpen: boolean,
    newTempProgram: boolean,
    programToEdit?: TemperatureProgramVM,
    addTypeOpen: boolean,
    typeInputName: string,
    stepEditOpen: boolean,
    stepToEdit?: TemperatureProgramStepVM,
    cycleDescription: string,
    cycleTemperature: number,
    cycleTime: number,
    cycleMinSec: boolean,
}

type ITemperatureProgramsPageProps = ITemperatureProgramsPageBaseProps & IAssetServiceInjectedProps;

export class _TemperatureProgramsPage extends React.Component<ITemperatureProgramsPageProps, ITemperatureProgramsPageState> {

    private readonly columns: Array<IDataTableColumn<TemperatureProgramVM>> = [
        {
            columnName: "name",
            columnFieldData: "Name",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
        },
        {
            columnName: "type",
            columnFieldData: "Type",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Type",
        },
        {
            columnName: "active",
            columnFieldData: (d) => d.IsActive ? "Active" : "Inactive",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Active",
        },
        {
            columnName: "deactivated-on",
            columnFieldData: (d) => !d.IsActive ? moment(d.DeactivatedOn).format("L LT") : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Deactivated On",
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({ programToEdit: d, editDialogOpen: true });
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                style={{ marginLeft: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({ programToEdit: d, editDialogOpen: true, newTempProgram: true });
                                    this.setName(d.Name + " - Copy");
                                }}
                            >
                                Copy
                            </Button>
                        </div>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ]

    private readonly stepColumns: Array<IDataTableColumn<TemperatureProgramStepVM>> = [
        {
            columnName: "stepNum",
            columnFieldData: (d) => <div>Step {d.StepNumber}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Step",
        },
        {
            columnName: "description",
            columnFieldData: (d) => <div>
                {_.map(d.StepCycles, c => <div>{c.Description}</div>)}
            </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Description",
        },
        {
            columnName: "temperature",
            columnFieldData: (d) => <div>
                {_.map(d.StepCycles, c => <div>{c.Temperature + " C"}</div>)}
            </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Temperature",
        },
        {
            columnName: "time",
            columnFieldData: (d) => <div>
                {_.map(d.StepCycles, c => <div>{c.Duration + " " + c.DurationUnits + "s"}</div>)}
            </div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Time",
        },
        {
            columnName: "numCycles",
            columnFieldData: "NumCycles",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Number of Cycles",
        },
        {
            columnName: "edit-step",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({ stepToEdit: d, stepEditOpen: true });
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                style={{ marginLeft: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => { this.deleteStep(d); }}
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    private readonly cycleColumns: Array<IDataTableColumn<TemperatureProgramStepCycleVM>> = [
        {
            columnName: "description",
            columnFieldData: "Description",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Description",
        },
        {
            columnName: "temperature",
            columnFieldData: (d) => <div>{d.Temperature !== 0 ? d.Temperature + " C" : ""}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Temperature",
        },
        {
            columnName: "time",
            columnFieldData: (d) => <div>{d.Duration !== 0 ? d.Duration + " " + d.DurationUnits + "s" : ""}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Time",
        },
        {
            columnName: "edit-cycle",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <div>
                            <Button
                                style={{ marginLeft: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => { this.deleteCycle(d); }}
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ]

    state: ITemperatureProgramsPageState = {
        editDialogOpen: false,
        newTempProgram: false,
        addTypeOpen: false,
        typeInputName: "",
        stepEditOpen: false,
        cycleDescription: "",
        cycleMinSec: true,
        cycleTemperature: 0,
        cycleTime: 0,
    }

    async componentDidMount() {
        await this.props.assetService.fetchTemperaturePrograms(true);
        await this.props.assetService.fetchTemperatureProgramTypes(true);
    }

    render() {

        let {
            temperatureProgramFetchResults,
            temperatureProgramTypesFetchResults
        } = this.props.assetService.getState();
        let stepCycles = this.state.stepToEdit?.StepCycles
        if (temperatureProgramFetchResults.data && temperatureProgramTypesFetchResults.data) {
            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div
                        className={styles.mainContainer}
                    >
                        <Card
                            className={styles.cardStyle}
                        >
                            <h2>Temperature Programs</h2>
                            <Button
                                style={{ float: "right", marginRight: "20px" }}
                                variant="contained"
                                color="primary"
                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                onClick={() => {
                                    this.setState({
                                        editDialogOpen: true,
                                        newTempProgram: true,
                                        programToEdit: {
                                            Type: "",
                                            Description: "",
                                            Id: "",
                                            IsActive: true,
                                            Name: "",
                                            CreatedOn: new Date(Date.now()),
                                            DeactivatedOn: new Date(Date.now()),
                                            Steps: []
                                        }
                                    })
                                }}
                            >
                                Add
                            </Button>
                            {temperatureProgramFetchResults.data &&
                                <DataTable
                                    data={temperatureProgramFetchResults.data}
                                    columns={this.columns}
                                />
                            }
                            <Dialog
                                className={styles.dialog}
                                open={this.state.editDialogOpen}
                                onClose={this.handleClose}
                                PaperProps={{ style: { maxWidth: "900px" } }}
                            >
                                <div className={styles.content}>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            InputLabelProps={{ shrink: true }}
                                            label={"Name"}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            value={this.state.programToEdit?.Name}
                                            onDebouncedChange={(value) => { this.setName(value) }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: "10px", display: "flex", flexDirection: "row" }}>
                                        <TextField
                                            select
                                            InputLabelProps={{ shrink: true }}
                                            label="Type"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            id="control-type"
                                            value={this.state.programToEdit?.Type}
                                            onChange={(event) => { this.setType(event.target.value as string) }}
                                        >
                                            {_.map(temperatureProgramTypesFetchResults.data, t => {
                                                return (<MenuItem key={t} value={t}>{t}</MenuItem>)
                                            })}
                                        </TextField>
                                        <div style={{ marginTop: "auto", marginLeft: "10px" }} onClick={async () => {
                                            this.setState({ addTypeOpen: true });
                                        }}>
                                            <Link>
                                                <u>Add New Type</u>
                                            </Link>
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <AdvanceTextField
                                            InputLabelProps={{ shrink: true }}
                                            label={"Description"}
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            value={this.state.programToEdit?.Description}
                                            onDebouncedChange={(value) => { this.setDescription(value) }}
                                        />
                                    </div>
                                    {
                                        !this.state.newTempProgram &&
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ paddingTop: "10px" }}>
                                                    Active:
                                                </div>
                                                <Checkbox disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} checked={this.state.programToEdit?.IsActive}
                                                    onChange={(event) => {
                                                        this.setActive(event.target.checked);
                                                    }}
                                                />
                                            </div>
                                            {!this.state.programToEdit?.IsActive && this.state.programToEdit?.DeactivatedOn &&
                                                <div>
                                                    Deactivated On {moment(this.state.programToEdit?.DeactivatedOn).format("L LT")}
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <h3>Temperature Step Details</h3>
                                            <Button
                                                style={{
                                                    height: "35px",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginLeft: "20px"
                                                }}
                                                variant="contained"
                                                color="primary"
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                onClick={() => {
                                                    let lastStepNum = _.sortBy(this.state.programToEdit?.Steps, s => s.StepNumber).pop()?.StepNumber || 0;
                                                    this.setState({
                                                        stepEditOpen: true,
                                                        stepToEdit:
                                                        {
                                                            Id: "",
                                                            NumCycles: 0,
                                                            StepNumber: lastStepNum + 1,
                                                            StepCycles: []
                                                        },
                                                    });
                                                }}
                                            >
                                                Add Step
                                            </Button>
                                        </div>
                                        <DataTable
                                            data={_.orderBy(this.state.programToEdit?.Steps || [], step => step.StepNumber)}
                                            columns={this.stepColumns}
                                        />
                                    </div>
                                </div>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.state.newTempProgram ? this.addTemperatureProgram() : this.updateTemperatureProgram() }}
                                        disabled={
                                            value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ||
                                            (this.state.newTempProgram &&
                                                !(this.state.programToEdit && this.state.programToEdit.Type && this.state.programToEdit.Description && this.state.programToEdit.Name))
                                        }
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                className={styles.dialog}
                                open={this.state.addTypeOpen}
                                onClose={this.handleClose}
                            >
                                <Card>
                                    <h2>
                                        Enter type name
                                    </h2>
                                    <AdvanceTextField disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} onDebouncedChange={this.onTypeInputChange} />
                                    {_.find(temperatureProgramTypesFetchResults.data, t => t === this.state.typeInputName) !== undefined &&
                                        <div>
                                            Duplicate Type Exists
                                        </div>}
                                </Card>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.addNewType() }}
                                        disabled={_.find(temperatureProgramTypesFetchResults.data, t => t === this.state.typeInputName) !== undefined || value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleAddTypeClose}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                className={styles.dialog}
                                open={this.state.stepEditOpen}
                                onClose={this.handleEditStepClose}
                            >
                                <Card>
                                    <div className={styles.content}>
                                        <h2>
                                            Step: {this.state.stepToEdit ? this.state.stepToEdit.StepNumber : 0}
                                        </h2>
                                        <div style={{ paddingTop: "10px" }}>
                                            <AdvanceTextField
                                                InputLabelProps={{ shrink: true }}
                                                label={"Number of Cycles"}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                                value={this.state.stepToEdit?.NumCycles}
                                                onDebouncedChange={(value) => { this.setNumCycles(value) }}
                                            />
                                        </div>

                                        <h3>Temperature Cycle</h3>
                                        <DataTable
                                            data={stepCycles || []}
                                            columns={this.cycleColumns}
                                        />
                                        <div style={{ marginTop: "100px", display: "flex", flexDirection: "row" }}>
                                            <div>
                                                {"Description"} <TextField disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} style={{ paddingLeft: 10 }} value={this.state.cycleDescription || ""} onChange={this.updateCycleDescription} />
                                            </div>
                                            <div>
                                                {"Temperature"} <TextField disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} style={{ paddingLeft: 10 }} type="number" value={this.state.cycleTemperature || ""} onChange={this.updateCycleTemp} inputProps={{ min: 1 }} />
                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <div>
                                                    {"Time"} <TextField disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} style={{ paddingLeft: 10 }} type="number" value={this.state.cycleTime || ""} onChange={this.updateCycleTime} inputProps={{ min: 1 }} />
                                                </div>
                                                <RadioGroup row value={this.state.cycleMinSec} onChange={this.handleRadioChange} >
                                                    <FormControlLabel disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} value={true} control={<Radio />} label="Min" />
                                                    <FormControlLabel disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1} value={false} control={<Radio />} label="Sec" />
                                                </RadioGroup>
                                            </div>
                                            <Button
                                                style={{ marginLeft: "20px" }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => { this.addCycle() }}
                                                disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.cycleDescription === "" || this.state.cycleTemperature === 0 || this.state.cycleTime === 0}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { this.saveStep() }}
                                        disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 || this.state.stepToEdit?.NumCycles === 0}
                                    >
                                        Save Step
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleEditStepClose}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Card>
                    </div >
                )}
            </ApplicationSecurityContext.Consumer>;
        }

        return <DataLoadingDisplay />;
    }

    @bind
    private handleClose() {
        this.setState({ editDialogOpen: false, addTypeOpen: false, newTempProgram: false, typeInputName: "" });
    }

    @bind
    private handleAddTypeClose() {
        this.setState({ addTypeOpen: false, typeInputName: "" });
    }

    @bind
    private handleEditStepClose() {
        this.setState({ stepEditOpen: false, stepToEdit: undefined });
    }

    @bind
    private onTypeInputChange(newValue: string) {
        this.setState({ typeInputName: newValue });
    }

    @bind
    private setName(value: string) {
        let oldVal = this.state.programToEdit;
        if (oldVal) {
            this.setState({
                programToEdit:
                {
                    Type: oldVal.Type,
                    Description: oldVal.Description,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: oldVal.DeactivatedOn,
                    IsActive: oldVal.IsActive,
                    Id: oldVal.Id,
                    Name: value,
                    Steps: oldVal.Steps
                }
            })
        }
    }

    @bind
    private setDescription(value: string) {
        let oldVal = this.state.programToEdit;
        if (oldVal) {
            this.setState({
                programToEdit:
                {
                    Type: oldVal.Type,
                    Description: value,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: oldVal.DeactivatedOn,
                    IsActive: oldVal.IsActive,
                    Id: oldVal.Id,
                    Name: oldVal.Name,
                    Steps: oldVal.Steps
                }
            })
        }
    }

    @bind
    private setActive(value: boolean) {
        let oldVal = this.state.programToEdit;
        if (oldVal) {
            this.setState({
                programToEdit:
                {
                    Type: oldVal.Type,
                    Description: oldVal.Description,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: value ? oldVal.DeactivatedOn : new Date(Date.now()),
                    IsActive: value,
                    Id: oldVal.Id,
                    Name: oldVal.Name,
                    Steps: oldVal.Steps
                }
            })
        }
    }

    @bind
    private setType(value: string) {
        let oldVal = this.state.programToEdit;
        if (oldVal) {
            this.setState({
                programToEdit:
                {
                    Type: value,
                    Description: oldVal.Description,
                    CreatedOn: oldVal.CreatedOn,
                    DeactivatedOn: oldVal.DeactivatedOn,
                    IsActive: oldVal.IsActive,
                    Id: oldVal.Id,
                    Name: oldVal.Name,
                    Steps: oldVal.Steps
                }
            })
        }
    }

    @bind
    private async addNewType() {
        await this.props.assetService.addTemperatureProgramType(this.state.typeInputName);
        await this.props.assetService.fetchTemperatureProgramTypes(true);
        this.setState({ addTypeOpen: false, typeInputName: "" })
    }

    @bind
    private async updateTemperatureProgram() {
        if (this.state.programToEdit) {
            await this.props.assetService.updateTemperatureProgram(this.state.programToEdit);
            await this.props.assetService.fetchTemperaturePrograms(true);
            this.handleClose();
        }
    }

    @bind
    private async addTemperatureProgram() {
        if (this.state.programToEdit) {
            await this.props.assetService.addTemperatureProgram(this.state.programToEdit);
            await this.props.assetService.fetchTemperaturePrograms(true);
            this.handleClose();
        }
    }

    @bind
    private setNumCycles(value: string) {
        let oldVal = this.state.stepToEdit;
        if (oldVal) {
            this.setState({
                stepToEdit:
                {
                    Id: oldVal.Id,
                    NumCycles: Number.parseInt(value),
                    StepCycles: oldVal.StepCycles,
                    StepNumber: oldVal.StepNumber
                }
            })
        }
    }

    @bind
    private updateCycleDescription(e: ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ cycleDescription: e.target.value });
    }

    @bind
    private updateCycleTime(e: ChangeEvent<HTMLTextAreaElement>) {
        let numericValue = parseFloat(e.target.value);
        if (numericValue) {
            this.setState({ cycleTime: numericValue });
        }
    }

    @bind
    private updateCycleTemp(e: ChangeEvent<HTMLTextAreaElement>) {
        let numericValue = parseFloat(e.target.value);
        if (numericValue) {
            this.setState({ cycleTemperature: numericValue });
        }
    }

    @bind
    private handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ cycleMinSec: (event.target as HTMLInputElement).value.toLowerCase() === "true" });
    }

    @bind
    private addCycle() {
        let newCycle: TemperatureProgramStepCycleVM = {
            Description: this.state.cycleDescription,
            Duration: this.state.cycleTime,
            DurationUnits: this.state.cycleMinSec ? "Minute" : "Second",
            Id: "",
            Temperature: this.state.cycleTemperature,
            CycleNumber: this.state.stepToEdit?.StepCycles.length || 1
        }

        let step = _.cloneDeep(this.state.stepToEdit);
        if (step) {
            step.StepCycles.push(newCycle);
            this.setState({ stepToEdit: step, cycleDescription: "", cycleTime: 0, cycleMinSec: true, cycleTemperature: 0 });
        }
    }

    @bind
    private saveStep() {
        let programToEdit = _.cloneDeep(this.state.programToEdit);
        let step = _.cloneDeep(this.state.stepToEdit);
        if (programToEdit && step) {
            if (step.StepNumber > programToEdit.Steps.length) {
                programToEdit.Steps.push(step);
            }
            //else find + replace
            else {
                programToEdit.Steps[step.StepNumber - 1] = step;
            }

            this.setState({ programToEdit: programToEdit, stepEditOpen: false });
        }
    }

    @bind
    private deleteStep(step: TemperatureProgramStepVM) {
        let programToEdit = _.cloneDeep(this.state.programToEdit);
        if (programToEdit) {
            var steps = _.orderBy(programToEdit.Steps, step => step.StepNumber)
            steps.splice(step.StepNumber - 1, 1);
            for (let s = 0; s < steps.length; s++) {
                steps[s].StepNumber = s + 1;
            }
            programToEdit.Steps = steps;
            this.setState({ programToEdit: programToEdit });
        }
    }

    @bind
    private deleteCycle(cycle: TemperatureProgramStepCycleVM) {
        let stepToEdit = _.cloneDeep(this.state.stepToEdit);
        if (stepToEdit) {
            var cycles = _.orderBy(stepToEdit.StepCycles, cycle => cycle.CycleNumber);
            cycles.splice(cycle.CycleNumber - 1, 1);
            for (let s = 0; s < cycles.length; s++) {
                cycles[s].CycleNumber = s + 1;
            }
            stepToEdit.StepCycles = cycles;
            this.setState({ stepToEdit: stepToEdit });
        }
    }
}

export const TemperatureProgramsPage = AssetService.inject(_TemperatureProgramsPage);