import {
  React, bind,
} from "$Imports/Imports";

import {
  ISecurityContext
} from "$Utilities/Security/ISecurityContext";

import {
  ApplicationSecuritySettings
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  AuthService
} from "$Utilities/Security/AuthFreezerService"

import {
 AuthTokenExpiringModal
} from "$Utilities/Security/AuthTokenExpiringModal";

import { getLogger } from "@yahara/logging";

import Keycloak from "keycloak-js"

export interface IAuthenticationContext {
  securityContext: ISecurityContext,
  applicationContext: ApplicationSecuritySettings,
  logout: () => void
}

/**
 * An valid instance of the security context with default(empty) initializations.
 */
const defaultSecurityClaims: ISecurityContext = {
  realm_access: { roles: [] },
  exp: null,
  iat:null
};

/**
 * An authorization context initialized with default values.
 */
const defaultContext: IAuthenticationContext = {
  securityContext: defaultSecurityClaims,
  applicationContext: new ApplicationSecuritySettings(),
  logout: () => { }
}

/** Constants */
const tokenCheckIntervalMs: number = 5000;

const ApplicationSecurityContext = React.createContext(defaultContext);

const ApplicationSecurityConsumer = ApplicationSecurityContext.Consumer;

interface IApplicationSecurityProviderProps {
}

interface IApplicationSecurityProviderState {
  authenticationContext: IAuthenticationContext;
  securityProviderReady: boolean;
  showInactivityWarning: boolean;
}

const logger = getLogger("AuthenticationProvider");

class ApplicationSecurityProvider extends React.Component<IApplicationSecurityProviderProps, IApplicationSecurityProviderState> {

  constructor(props: IApplicationSecurityProviderProps) {
      super(props);
      let security = new ApplicationSecuritySettings();

      let context: IAuthenticationContext = {
          securityContext: security.securityContext ?? defaultSecurityClaims,
          applicationContext: security,
          logout: this.logout
      }

      this.state = {
          authenticationContext: context,
          securityProviderReady: false,
          showInactivityWarning: false
      };
  }

  /**
   * Interval that fires and checks if the keycloak token has expired.
   * If it has expired it will trigger the modal that gives the user a chance to remain logged in.
   */
  tokenExpirationCheckInterval = setInterval(() => {
    if(AuthService.keyCloakClient?.isTokenExpired()){
      logger.warn("Keycloak token expired");
      if(!AuthService.isAuthenticating){
        this.setState({showInactivityWarning: true});
      }
      
    }
  } , tokenCheckIntervalMs);

  async componentDidMount() {

    await AuthService.authenticate()
    .then(() => {
      const security = new ApplicationSecuritySettings();

      const context: IAuthenticationContext = {
        securityContext: security.securityContext ?? defaultSecurityClaims,
        applicationContext: security,
        logout: this.logout
      }
      this.setState({
        authenticationContext: context,
        securityProviderReady: true 
      });

      })
    .catch((reason:any) => {
      logger.error("Error authenticating user on mount");
      logger.error(reason);
      AuthService.logout();
    });
  }

  /**
   * Will log user out of their keycloak session and redirect to login page
   */
  @bind
  async logout() {
    await AuthService.logout();
  }

  /**
   * 
   * @returns string that has debug information about the keycloak token(primarily used for testing)
   * Not wired up to anything
   */
  private getTokenExpiration():string{
    const kcClient = (AuthService.keyCloakClient as Keycloak);
    if(kcClient?.tokenParsed){
      const currentTime = Math.floor(new Date().getTime()/1000);
      const remainingtime = (kcClient?.tokenParsed.exp as number) - currentTime;
      return "Remaining seconds on token:" + remainingtime + kcClient?.isTokenExpired();
    }
    return "No token"
    
  }

  /**
   * Will refresh an expired token(or within 30 seconds of expiring) with keycloak server
   */
  @bind
  private async refreshToken() : Promise<void> {

    await AuthService.refreshAuthentication()
    .then(() => {
      const security = new ApplicationSecuritySettings();
      const context: IAuthenticationContext = {
        securityContext: security.securityContext ?? defaultSecurityClaims,
        applicationContext: security,
        logout: this.logout
      }

      this.setState({
        showInactivityWarning: false,
        authenticationContext: context
      });

    })
    .catch((reason:any) => {
      logger.error("Error refreshing token");
      logger.error(reason);
      AuthService.logout();
    });
  }

  render() {
      return (
          <>
              {this.state.securityProviderReady && (
                  <ApplicationSecurityContext.Provider
                      value={this.state.authenticationContext}
                  >
                    <div>

                        {this.state.showInactivityWarning && <AuthTokenExpiringModal refreshToken={this.refreshToken} logout={this.logout}/>}
                        
                        {this.props.children}
                    </div>
                </ApplicationSecurityContext.Provider>
              )}
          </>
      );

  }
}

export {
  ApplicationSecurityProvider,
  ApplicationSecurityConsumer,
  ApplicationSecurityContext
};
