import {
    React,
    bind,
    _,
    moment,
    ReactGA
} from "$Imports/Imports";

import { Card, Checkbox, Button, Dialog, DialogActions, TextField, MenuItem, Link, Tabs, Tab } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    DataLoadingDisplay,
    ProcessAdminRole,
} from "../../imports/CommonComponents";

import {
    AssetService,
    IAssetServiceInjectedProps
} from "$State/AssetFreezerService";
import { ControlMaterialVM, ReagentGroupLotVM, ReagentGroupVM, ReagentLotVM, ReagentVM } from "$Generated/api";

import {
    EditBarcodeKit,
    EditCommercialKit,
    EditPoolAndCustomKit,
    EditReagent
} from "./ComponentDialogs"
import { ApplicationSecurityContext } from "$Providers/AuthenticationProvider";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
    dialog: string;
    table: string;
} = require("./LabAssets.scss");

interface IComponentsPageBaseProps {
}

interface IComponentsPageState {
    newReagent: boolean,
    editReagentOpen: boolean,
    reagentToEdit?: ReagentVM,
    newReagentGroup: boolean,
    editPoolCustomKitOpen: boolean,
    reagentGroupToEdit?: ReagentGroupVM,
    tabOpen: number,
    editCommercialKitOpen: boolean,
    editBarcodeKitOpen: boolean
}

type IComponentsPageProps = IComponentsPageBaseProps & IAssetServiceInjectedProps;

export class _ComponentsPage extends React.Component<IComponentsPageProps, IComponentsPageState> {

    private readonly poolReagentColumns: Array<IDataTableColumn<ReagentVM | ReagentGroupVM>> = [
        {
            columnName: "reagent-name",
            columnFieldData: (d) => <div>{d.Name}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
            sortMethod: (d) => d.Name ?? ""
        },
        {
            columnName: "reagent-description",
            columnFieldData: (d) => <div>{d.Description}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Description",
        },
        {
            columnName: "is-pool",
            columnFieldData: (d) => <div>{this.isReagent(d) ? <></> : <CheckCircleIcon style={{ color: "green" }} />}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Is Pool",
        },
        {
            columnName: "lot-number",
            columnFieldData: (d) => {
                let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                data = _.union(data, d.Lots);
                return <div>{_.map(data.filter((lot, i, arr) => arr.findIndex(t => t.LotNumber === lot.LotNumber) === i), (l) => {
                    let temp = l as ReagentLotVM | ReagentGroupLotVM;
                    if (temp.LotNumber !== undefined) {
                        return <div key={temp.LotNumber}>{temp.LotNumber}</div>;
                    }
                    return <></>;
                })}</div>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Lot/Ref Number",
        },
        {
            columnName: "control-material-deactivated-on",
            columnFieldData: (d) => !d.IsActive ? moment(d.DeactivatedOn).format("L LT") : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Deactivated On",
            sortMethod: (d) => d.DeactivatedOn ?? ""
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            style={{ float: "right" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (this.isReagent(d)) {
                                    this.setState({ editReagentOpen: true, reagentToEdit: d });
                                }
                                else {
                                    this.setState({ editPoolCustomKitOpen: true, reagentGroupToEdit: d });
                                }
                            }}
                        >
                            {value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ? "View" : "View/Edit"}
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    private readonly barcodeKitReagentColumns: Array<IDataTableColumn<ReagentVM | ReagentGroupVM>> = [
        {
            columnName: "reagent-name",
            columnFieldData: (d) => <div>{d.Name}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
            sortMethod: (d) => d.Name ?? ""
        },
        {
            columnName: "is-plate",
            columnFieldData: (d) => <div>{this.isReagent(d) ? <></> : <CheckCircleIcon style={{ color: "green" }} />}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Is Plate",
        },
        {
            columnName: "lot-number",
            columnFieldData: (d) => {
                let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                data = _.union(data, d.Lots);
                return <div>{_.map(data.filter((lot, i, arr) => arr.findIndex(t => t.LotNumber === lot.LotNumber) === i), (l) => {
                    let temp = l as ReagentLotVM | ReagentGroupLotVM;
                    if (temp.LotNumber !== undefined) {
                        return <div key={temp.LotNumber}>{temp.LotNumber}</div>;
                    }
                    return <></>;
                })}</div>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Lot/Ref Number",
        },
        {
            columnName: "kit-type",
            columnFieldData: (d) => {
                if (!this.isReagent(d)) {
                    let kitType = "";
                    switch (d.ReagentGroupType) {
                        case "MinIONBarcodeKit":
                            kitType = "MinION";
                            break;
                        case "IlluminaDualAdapterBarcodeKit":
                            kitType = "Illumina Dual Adapter";
                            break;
                        default:
                        case "IlluminaNexteraBarcodeKit":
                            kitType = "Illumina Nextera";
                            break;
                    }

                    return <div>{kitType}</div>;
                }
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Kit Type",
        },
        {
            columnName: "control-material-deactivated-on",
            columnFieldData: (d) => !d.IsActive ? moment(d.DeactivatedOn).format("L LT") : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Deactivated On",
            sortMethod: (d) => d.DeactivatedOn ?? ""
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            style={{ float: "right" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (this.isReagent(d)) {
                                    this.setState({ editReagentOpen: true, reagentToEdit: d });
                                }
                                else {
                                    this.setState({ editBarcodeKitOpen: true, reagentGroupToEdit: d });
                                }
                            }}
                        >
                            {value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ? "View" : "View/Edit"}
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    private readonly kitColumns: Array<IDataTableColumn<ReagentGroupVM>> = [
        {
            columnName: "reagent-name",
            columnFieldData: (d) => <div>{d.Name}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Name",
            sortMethod: (d) => d.Name ?? ""
        },
        {
            columnName: "is-commercial",
            columnFieldData: (d) => <div>{d.ReagentGroupType === "CommercialKit" ? <CheckCircleIcon style={{ color: "green" }} /> : <></>}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Is Commercial",
        },
        {
            columnName: "kit-description",
            columnFieldData: (d) => <div>{d.Description}</div>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Description",
        },
        {
            columnName: "lot-number",
            columnFieldData: (d) => {
                let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                data = _.union(data, d.Lots);
                return <div>{_.map(data.filter((lot, i, arr) => arr.findIndex(t => t.LotNumber === lot.LotNumber) === i), (l) => {
                    let temp = l as ReagentLotVM | ReagentGroupLotVM;
                    if (temp.LotNumber !== undefined) {
                        return <div key={temp.LotNumber}>{temp.LotNumber}</div>;
                    }
                    return <></>;
                })}</div>;
            },
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Lot/Ref Number",
        },
        {
            columnName: "control-material-deactivated-on",
            columnFieldData: (d) => !d.IsActive ? moment(d.DeactivatedOn).format("L LT") : "",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Deactivated On",
            sortMethod: (d) => d.DeactivatedOn ?? ""
        },
        {
            columnName: "edit-link",
            columnFieldData: (d) =>
                <ApplicationSecurityContext.Consumer>
                    {value => (
                        <Button
                            style={{ float: "right" }}
                            variant="contained"
                            color="primary"
                            onClick={() => { this.setState({ editCommercialKitOpen: d.ReagentGroupType === "CommercialKit", editPoolCustomKitOpen: d.ReagentGroupType === "CustomKit", reagentGroupToEdit: d }); }}
                        >
                            {value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1 ? "View" : "View/Edit"}
                        </Button>
                    )}
                </ApplicationSecurityContext.Consumer>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },

    ]

    state: IComponentsPageState = {
        editReagentOpen: false,
        newReagent: false,
        editPoolCustomKitOpen: false,
        newReagentGroup: false,
        tabOpen: 0,
        editCommercialKitOpen: false,
        editBarcodeKitOpen: false
    }

    async componentDidMount() {
        await this.props.assetService.fetchStandaloneReagents(true);
        await this.props.assetService.fetchPools(false, true);
        await this.props.assetService.fetchCustomKits(false, true);
        await this.props.assetService.fetchCommercialKits(false, true);
        await this.props.assetService.fetchMinIONBarcodeKits(false, true);
        await this.props.assetService.fetchIlluminaBarcodeKits(false, true);
        await this.props.assetService.fetchIlluminaDualAdapterBarcodeKits(false, true);
    }

    render() {
        let {
            standaloneReagentFetchResults,
            poolFetchResults,
            customKitFetchResults,
            commercialKitFetchResults,
            minIONBarcodeKitFetchResults,
            illuminaBarcodeKitFetchResults,
            illuminaDualAdapterBarcodeKitFetchResults
        } = this.props.assetService.getState();
        if (standaloneReagentFetchResults.data && poolFetchResults.data && customKitFetchResults.data && commercialKitFetchResults.data && illuminaBarcodeKitFetchResults.data && minIONBarcodeKitFetchResults.data && illuminaDualAdapterBarcodeKitFetchResults.data) {
            let poolReagentData: (ReagentVM | ReagentGroupVM)[] = [];
            poolReagentData = _.union(poolReagentData, standaloneReagentFetchResults.data, poolFetchResults.data);

            let barcodeKitReagentData: (ReagentVM | ReagentGroupVM)[] = [];
            barcodeKitReagentData = _.union(barcodeKitReagentData, illuminaBarcodeKitFetchResults.data, minIONBarcodeKitFetchResults.data, illuminaDualAdapterBarcodeKitFetchResults.data);

            let kitData = _.union(customKitFetchResults.data, commercialKitFetchResults.data);

            return <ApplicationSecurityContext.Consumer>
                {value => (
                    <div className={styles.mainContainer}>
                        <Card className={styles.cardStyle}>
                            <h2>Component Inventory</h2>

                            <Tabs value={this.state.tabOpen} onChange={(e, val) => this.setState({ tabOpen: val })}>
                                <Tab label="Pools and Reagents" />
                                <Tab label="Kits" />
                                <Tab label="Barcode Kits/Plates" />
                            </Tabs>
                            <div style={{ marginLeft: "50px", marginBottom: "20px" }} hidden={this.state.tabOpen !== 0}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h2>Pools and Reagents</h2>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Button
                                            style={{ marginTop: "auto", marginBottom: "auto", marginRight: "5px" }}
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => { this.openNewReagent() }}
                                        >
                                            Add Reagent
                                        </Button>
                                        <Button
                                            style={{ marginTop: "auto", marginBottom: "auto" }}
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => { this.openNewPool() }}
                                        >
                                            Add Pool
                                        </Button>
                                    </div>
                                </div>
                                <div className={styles.table}>
                                    <DataTable
                                        data={poolReagentData}
                                        columns={this.poolReagentColumns}
                                        stickyHeader={true}
                                    />
                                </div>
                            </div>
                            <div style={{ marginLeft: "50px", marginBottom: "20px" }} hidden={this.state.tabOpen !== 1}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h2>Kits</h2>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Button
                                            style={{ marginTop: "auto", marginBottom: "auto", marginRight: "5px" }}
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => { this.openNewCustomKit() }}
                                        >
                                            Add Custom Kit
                                        </Button>
                                        <Button
                                            style={{ marginTop: "auto", marginBottom: "auto" }}
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => { this.openNewCommercialKit() }}
                                        >
                                            Add Commercial Kit
                                        </Button>
                                    </div>
                                </div>
                                <div className={styles.table}>
                                    <DataTable
                                        data={kitData}
                                        columns={this.kitColumns}
                                        stickyHeader={true}
                                    />
                                </div>
                            </div>
                            <div style={{ marginLeft: "50px", marginBottom: "20px" }} hidden={this.state.tabOpen !== 2}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h2>Barcode Kits/Plates</h2>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Button
                                            style={{ marginTop: "auto", marginBottom: "auto" }}
                                            variant="contained"
                                            color="primary"
                                            disabled={value.securityContext.realm_access.roles.indexOf(ProcessAdminRole) == -1}
                                            onClick={() => { this.openNewBarcodeKit() }}
                                        >
                                            Add Barcode Kit
                                        </Button>
                                    </div>
                                </div>
                                <div className={styles.table}>
                                    <DataTable
                                        data={barcodeKitReagentData}
                                        columns={this.barcodeKitReagentColumns}
                                        stickyHeader={true}
                                    />
                                </div>
                            </div>
                        </Card>
                        <div>
                            <EditReagent
                                open={this.state.editReagentOpen}
                                newReagent={this.state.newReagent}
                                handleClose={this.handleClose}
                                initialReagent={this.state.reagentToEdit}
                                handleSave={this.handleReagentUpdate}
                                user={value.applicationContext.userContext}
                            />
                            <EditPoolAndCustomKit
                                open={this.state.editPoolCustomKitOpen}
                                newReagentGroup={this.state.newReagentGroup}
                                handleClose={this.handleClose}
                                initialReagentGroup={this.state.reagentGroupToEdit}
                                handleSave={this.handleReagentGroupUpdate}
                                user={value.applicationContext.userContext}
                            />
                            <EditCommercialKit
                                open={this.state.editCommercialKitOpen}
                                newReagentGroup={this.state.newReagentGroup}
                                handleClose={this.handleClose}
                                initialReagentGroup={this.state.reagentGroupToEdit}
                                handleSave={this.handleReagentGroupUpdate}
                                user={value.applicationContext.userContext}
                            />
                            <EditBarcodeKit
                                open={this.state.editBarcodeKitOpen}
                                newReagentGroup={this.state.newReagentGroup}
                                handleClose={this.handleClose}
                                initialReagentGroup={this.state.reagentGroupToEdit}
                                handleSave={this.handleReagentGroupUpdate}
                                user={value.applicationContext.userContext}
                            />
                        </div>
                    </div>
                )}
            </ApplicationSecurityContext.Consumer>;
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private closeModals() {
        this.setState({
            editReagentOpen: false,
            editPoolCustomKitOpen: false,
            editCommercialKitOpen: false,
            editBarcodeKitOpen: false
        });
    }

    private clearData() {
        this.setState({
            newReagent: false,
            reagentToEdit: undefined,
            newReagentGroup: false,
            reagentGroupToEdit: undefined
        });
    }

    @bind
    private handleClose() {
        this.closeModals();
        this.clearData();
    }

    //#region Reagents and Barcode Reagents
    @bind
    private async handleReagentUpdate(reagent: ReagentVM) {
        this.closeModals();
        if (this.state.newReagent) {
            await this.props.assetService.addReagent(reagent);
        }
        else {
            await this.props.assetService.updateReagent(reagent)
        }

        //reload the necessary data
        if (reagent.ReagentType == "Reagent") {
            await this.props.assetService.fetchStandaloneReagents(true);
        }
        else {
            await this.props.assetService.fetchBarcodeReagents(true);
        }
        this.clearData();
    }

    @bind
    private openNewReagent() {
        //open blank reagent
        this.setState({
            newReagent: true, editReagentOpen: true, reagentToEdit:
            {
                Description: "",
                CreatedOn: new Date(Date.now()),
                IsActive: true,
                Id: "",
                Name: "",
                CreatedBy: "",
                InKits: 0,
                Lots: [],
                DeactivatedOn: new Date(Date.now()),
                UsedInRun: false,
                ReagentType: "Reagent"
            }
        });
    }

    //#endregion

    //#region Reagent Groups
    @bind
    private async handleReagentGroupUpdate(group: ReagentGroupVM) {
        this.closeModals();
        if (this.state.newReagentGroup) {
            await this.props.assetService.addReagentGroup(group);
        }
        else {
            await this.props.assetService.updateReagentGroup(group)
        }

        //reload correct data afterwards
        if (group.ReagentGroupType === "Pool") {
            await this.props.assetService.fetchPools(false, true);
        }
        else if (group.ReagentGroupType === "CommercialKit") {
            await this.props.assetService.fetchCommercialKits(false, true);
        }
        else if (group.ReagentGroupType === "CustomKit") {
            await this.props.assetService.fetchCustomKits(false, true);
        }
        else {
            await this.props.assetService.fetchMinIONBarcodeKits(false, true);
            await this.props.assetService.fetchIlluminaBarcodeKits(false, true);
            await this.props.assetService.fetchIlluminaDualAdapterBarcodeKits(false, true);
        }
        this.clearData();
    }

    @bind
    private openNewPool() {
        //create blank pool
        this.setState({
            newReagentGroup: true, editPoolCustomKitOpen: true, reagentGroupToEdit:
            {
                Description: "",
                CreatedOn: new Date(Date.now()),
                IsActive: true,
                Id: "",
                Name: "",
                CreatedBy: "",
                CanUseReagentsIndependently: false,
                Lots: [],
                DeactivatedOn: new Date(Date.now()),
                ReagentGroupType: "Pool"
            }
        });
    }

    @bind
    private openNewCustomKit() {
        //create blank custom kit
        this.setState({
            newReagentGroup: true, editPoolCustomKitOpen: true, reagentGroupToEdit:
            {
                Description: "",
                CreatedOn: new Date(Date.now()),
                IsActive: true,
                Id: "",
                Name: "",
                CreatedBy: "",
                CanUseReagentsIndependently: false,
                Lots: [],
                DeactivatedOn: new Date(Date.now()),
                ReagentGroupType: "CustomKit"
            }
        });
    }

    @bind
    private openNewCommercialKit() {
        //Create blank commercial kit
        this.setState({
            newReagentGroup: true, editCommercialKitOpen: true, reagentGroupToEdit:
            {
                Description: "",
                CreatedOn: new Date(Date.now()),
                IsActive: true,
                Id: "",
                Name: "",
                CreatedBy: "",
                CanUseReagentsIndependently: false,
                Lots: [],
                DeactivatedOn: new Date(Date.now()),
                ReagentGroupType: "CommercialKit"
            }
        });
    }

    @bind
    private openNewBarcodeKit() {
        //Create blank barcode kit
        this.setState({
            newReagentGroup: true, editBarcodeKitOpen: true, reagentGroupToEdit:
            {
                Description: "",
                CreatedOn: new Date(Date.now()),
                IsActive: true,
                Id: "new",
                Name: "",
                CreatedBy: "",
                CanUseReagentsIndependently: false,
                Lots: [],
                DeactivatedOn: new Date(Date.now()),
                ReagentGroupType: "MinIONBarcodeKit",
            }
        });
    }
    //#endregion

    private isReagent(r: ReagentVM | ReagentGroupVM): r is ReagentVM {
        return (r as ReagentVM).InKits !== undefined;
    }
}

export const ComponentsPage = AssetService.inject(_ComponentsPage);