import {
    React,
    bind,
    _,
    ReactGA
} from "$Imports/Imports";

import { Card, FormControlLabel, Checkbox, Button, Snackbar } from "$Imports/MaterialUIComponents";

import {
    DataTable,
    IDataTableColumn,
    AdvanceTextField,
    DataTablePager
} from "../../imports/CommonComponents";

import { NavigationService } from "$State/NavigationFreezerService";

const styles: {
    mainContainer: string;
    cardStyle: string;
    content: string;
    tableHeader: string;
} = require("./WorkflowComponentManagement.scss");

interface IWorkflowComponentManagementPageProps {
}

export class WorkflowComponentManagementPage extends React.Component<IWorkflowComponentManagementPageProps> {

    private readonly columns: Array<IDataTableColumn<{ usage: string, url: string }>> = [
        {
            columnName: "usage-name",
            columnFieldData: "usage",
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Component Usage",
        },
        {
            columnName: "view-link",
            columnFieldData: (d) => <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    NavigationService.navigateTo("/" + d.url);
                }}
            >
                View
            </Button>,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "",
        },
    ]

    render() {

        var data: { usage: string, url: string }[] = [
            { usage: "Reagent Mixes", url: "ReagentMixes" },
            { usage: "Reagent Lists", url: "ReagentLists" },
            { usage: "Instruction Sets", url: "InstructionSets" },
            { usage: "Workflow Step Component Association", url: "WorkflowStepComponentAssociation" },
        ];
        return <div
            className={styles.mainContainer}
        >
            <Card
                className={styles.cardStyle}
            >
                <div className={styles.content}>
                    <h2>Lab Assets</h2>
                    <DataTable
                        data={data}
                        columns={this.columns}
                    />
                </div>
            </Card>
        </div>;
    }
}