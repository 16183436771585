import { 
  QCVisualization 
} from "$Components/common/QCVisualization/QCVisualization";

import { 
  QCStatistics 
} from "$Components/common/QCVisualization/QCStatistics";

import { 
  INavigationItem 
} from "./navigation";

import {
  ISecurityContext
} from "$Utilities/Security/ISecurityContext";

interface IQcNavigationItem extends INavigationItem{
  component?: React.ComponentClass<any,any> | React.FunctionComponent<any>
}

/**
 * Method to return list of navigation items to be used for the Data visualization and statistics pages
 * @returns INavigationItem[]
 */
export const qcMetricsNavigation = () => {
  var array: IQcNavigationItem[] = [{
    url: "/qcmetrics/statistics",
    label: "Statistics",
    externalLink: false,
    component: QCStatistics,
    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
        return true;
    },
    enabled: true,
    pageType:"Drylab",
    isVisibleInNavMenu: true
  },
  {
    url: "/qcmetrics/visualization",
    label: "Visualization",
    externalLink: false,
    component: QCVisualization,
    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext | null) => {
        return true;
    },
    enabled: true,
    pageType:"Drylab",
    isVisibleInNavMenu: false
  }];
  return array
}

/**
 * Gets the run id from the url
 * @returns string representing the runId of a workflow
 */
export const getWetlabRunId = () : string => {
  const urlParts = window.location.pathname.split('/');
  return urlParts[3];
}

/**
 * Gets the sample id from the url
 * @returns string representing the sampleId
 */
export const getSampleId = () : string => {
  const urlParts = window.location.pathname.split('/');
  return urlParts[7];
}

/**
 * Gets the step insatnce id from the url
 * @returns string representing the step instance id of a workflow
 */
export const getStepInstanceId = () : string=> {
  const urlParts = window.location.pathname.split('/');
  if(urlParts[4] == 'step')
    return urlParts[5];
  
  return '';
}

/**
 * Gets the qc metrics base path from the url
 * @returns string representing the base path for the qc metrics screens(ie qcmetrics/visualization or qcmetrics/statistics)
 */
export const getQCMetricsBasePath = () : string => {
  const urlParts = window.location.pathname.split('/');
  return `/${urlParts[1]}/${urlParts[2]}`;
}

/**
 * Gets the run number of the pipeline run from url
 * @returns string representing the pipeline run number
 */
export const getPipelineRunNumber = () : string=> {
  const urlParts = window.location.pathname.split('/');
  if(urlParts[4] == 'pipelinerunnumber')
    return urlParts[5];

  return '';
}
