import { AssociationAddVM, BlockchainApiFactory, ExchangeDetailsVM, RemoteAssociationApiFactory, RemoteAssociationVM, SiteInfoVM } from "$Generated/api";
import {
    FreezerService, IAjaxState, managedAjaxUtil
} from "$Imports/Imports";

const InjectedPropName = "SharingService";

export interface ISharingFreezerState {
    remoteAssociationsResult : IAjaxState<RemoteAssociationVM[]>;
    mySiteInfoResult : IAjaxState<SiteInfoVM>;
    createNewAssociationResult: IAjaxState<boolean>;
    editAssociationResult: IAjaxState<boolean>;
    acceptAssociationRequestResult: IAjaxState<boolean>;
    rejectAssociationRequestResult: IAjaxState<boolean>;
    unassociateAssociationInstanceResult: IAjaxState<boolean>;
    cancelAssociationRequestResult: IAjaxState<boolean>;
    associateToInstanceResult: IAjaxState<boolean>;
    exchangeDetailsResult: IAjaxState<ExchangeDetailsVM[]>;
    fileResult: IAjaxState<string>;
}


class SharingFreezerService extends FreezerService<ISharingFreezerState, typeof InjectedPropName> {
    constructor() {
        super({
            remoteAssociationsResult: managedAjaxUtil.createInitialState(),
            mySiteInfoResult: managedAjaxUtil.createInitialState(),
            createNewAssociationResult: managedAjaxUtil.createInitialState(),
            editAssociationResult: managedAjaxUtil.createInitialState(),
            acceptAssociationRequestResult: managedAjaxUtil.createInitialState(),
            rejectAssociationRequestResult: managedAjaxUtil.createInitialState(),
            unassociateAssociationInstanceResult: managedAjaxUtil.createInitialState(),
            cancelAssociationRequestResult: managedAjaxUtil.createInitialState(),
            associateToInstanceResult: managedAjaxUtil.createInitialState(),
            exchangeDetailsResult: managedAjaxUtil.createInitialState(),
            fileResult: managedAjaxUtil.createInitialState()
        }, InjectedPropName);
    }

    public async fetchRemoteAssociations(forceUpdate?: boolean) {
        const { remoteAssociationsResult } = this.freezer.get();

        if (remoteAssociationsResult.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "remoteAssociationsResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationGetRemoteAssociationsGet();
            },
        });
    } 

    public async fetchMySiteInfo(forceUpdate?: boolean) {
        const { mySiteInfoResult } = this.freezer.get();

        if (mySiteInfoResult.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "mySiteInfoResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationGetMySiteInfoGet();
            },
        });
    }

    public async createNewAssociation(toAdd: AssociationAddVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "createNewAssociationResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationCreateAssociationPost({body: toAdd});
            },
        });
    }

    public async editAssociation(id: string, toEdit: AssociationAddVM)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "editAssociationResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationEditAssociationIdPost({id: id, body: toEdit})
            },
        });
    }

    public async acceptAssociationRequest(id: string, alias:string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "acceptAssociationRequestResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationAcceptAssociationIdPost({id:id, alias:alias});
            },
        });
    }

    public async rejectAssociationRequest(id: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "rejectAssociationRequestResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationRejectAssociationIdPost({id});
            },
        });
    }

    public async unassociateAssociationInstance(id: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "unassociateAssociationInstanceResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationUnassociateAssociationIdPost({id});
            },
        });
    }

    public async cancelAssociationRequest(id: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "cancelAssociationRequestResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationCancelAssociationRequestIdPost({id});
            },
        });
    }

    public async associateToInstance(id: string)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "associateToInstanceResult",
            onExecute: (apiOptions, param, options) => {
                const factory = RemoteAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1RemoteAssociationAssociateWithInstanceIdPost({id});
            },
        });
    }

    public async getExchangeDetails(receiver: boolean)
    {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "exchangeDetailsResult",
            onExecute: (apiOptions, param, options) => {
                const factory = BlockchainApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1BlockchainGetOrganizationExchangeDetailsGet({receiver})
            },
        });
    }

    public async getS3File(s3Key: string) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "fileResult",
            onExecute: async (apiOptions, param, options) => {
                let factory = BlockchainApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                let httpResponse = await factory.apiV1BlockchainDownloadInternalS3FileGet({s3Key});
                let response = new Response(httpResponse.body);
                let blob = await response.blob();
                let blobUrl = URL.createObjectURL(blob);
                return blobUrl;
            },
        });
    }
}



export const SharingService = new SharingFreezerService();
export type ISharingServiceInjectedProps = ReturnType<SharingFreezerService["getPropsForInjection"]>;
