import {
    React,
    bind,
    IAjaxState
} from "$Imports/Imports";

import * as _ from "lodash";

import {
    Card,
} from "$Imports/MaterialUIComponents";

import {
    StatusElement
} from "$Imports/CommonComponents";

import {
    WetlabRunVM, StepInstanceVM
} from "$Generated/api";


const styles: {
    cardStyle: string;
    header: string;
    indicator: string;
    elementRow: string;
    bar: string;
    width_sm: string;
    width_md: string;
    width_lg: string;
} = require("./StatusHeader.scss");


export interface IStatusHeaderProps {
    goToStep?: (stepId: string) => void;
    workflowRun: IAjaxState<WetlabRunVM>;
    steps: IAjaxState<Array<StepInstanceVM>>;
}


export class StatusHeader extends React.PureComponent<IStatusHeaderProps> {


    @bind
    handleStepChange(stepId: string) {
        this.props.goToStep && this.props.goToStep(stepId);
    }

    render() {
        let {
            hasFetched,
            data
        } = this.props.workflowRun;

        let stepData = this.props.steps.data;


        //Lay the elements out based on how many there are, for aesthetic purposes.
        let elementPositioning = styles.width_lg;

        if (stepData && stepData.length <= 5) {
            elementPositioning = styles.width_sm;
        }
        else if (stepData && stepData.length <= 10) {
            elementPositioning = styles.width_md;
        }

        return (
            <Card className={styles.cardStyle}>
                {
                    hasFetched && data &&
                    <>
                        <h2 className={styles.header}>
                            Run {data.RunNumber} - {data.WorkflowName}
                        </h2>

                        <div className={`${styles.elementRow} ${elementPositioning}`}>
                            {
                                _.orderBy(stepData, (step) => {
                                    return step.WorkflowOrder
                                })
                                    .map((step, idx) => {
                                        return (
                                            <StatusElement
                                                key={idx}
                                                workflowStepId={step.StepId}
                                                workflowStepName={step.Name}
                                                workflowStepStatus={step.Status}
                                                handleWorkflowStepClick={this.handleStepChange}
                                            />
                                        )
                                    })
                            }
                        </div>
                        <div className={styles.bar} />
                    </>
                }
            </Card>
        );
    }
}