import { NgsReportInstrumentSelectionDetailsVM } from "$Generated/api";
import {
  React,
  _,
} from "$Imports/Imports";
import { ReportHeader } from "./ReportComponents/ReportHeader";

interface IInstrumentReportProps {
  workflowRunId: string;
  instrumentSelectionDetail: NgsReportInstrumentSelectionDetailsVM | undefined;
  // Report Header props.
  workflowRunNumber: string | undefined;
  workflowName: string;
  workflowDisplayName: string;
  users: string[] | undefined;
  systemSettings: {[key: string]: string};
};

type IInstrumentPageProps = IInstrumentReportProps;

export class _InstrumentReport extends React.Component<IInstrumentPageProps> {

  render() {
      const instrumentSelection = this.props.instrumentSelectionDetail ? this.props.instrumentSelectionDetail.InstrumentSelection : "N/A";
      return (
        <div>
          <ReportHeader
            workflowRunNumber={this.props.workflowRunNumber}
            workflowDisplayName={this.props.workflowName}
            users={this.props.users}
            systemSettings={this.props.systemSettings}
            reportName="Next Generation Sequencing Quality Control Standardization of the Instrument Process"/>
          
          <h2>Instrument Selection</h2>
          
          <div>
              The following instrument was used for sequencing: {instrumentSelection}
          </div>
        </div>
      )
  }
}

export const InstrumentReport = _InstrumentReport;