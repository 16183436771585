import { PipelineRunVM } from "$Generated/api";
import {
  React, bind, moment
} from "$Imports/Imports";

import {
  Button,
  Card,
} from "$Imports/MaterialUIComponents";

import { IPipelineServiceInjectedProps, PipelineService } from "$State/PipelineFreezerService";
import { IWorkflowRunServiceInjectedProps, WorkflowRunService } from "$State/WorkflowRun/WorkflowRunFreezerService";
import { GenomicsData } from "./GenomicsData";

import * as s from "underscore.string";

const styles: {
  mainContainer: string;
  cardStyle: string;
  headerContainer: string;
  title: string;
  run: string;
  sampleLabel: string;
  displayDiv: string;
  metricHeaderLabel: string;
  ampliconSublabel: string;
  runReleaseContainer: string;
  runReleaseNotReady: string;
  runReleaseButtonGroup: string;
  runReleaseButton: string;
  runFailButton: string;
  releaseInfo: string;
} = require("./PipelineAnalysis.scss");

interface IPipelineAnalysisBaseProps { };
interface IPipelineAnalysisState {
}

export type IPipelineAnalysisProps = IPipelineServiceInjectedProps & IPipelineAnalysisBaseProps & IWorkflowRunServiceInjectedProps;

class _PipelineAnalysis extends React.Component<IPipelineAnalysisProps, IPipelineAnalysisState> {

  // Initialize the state.
  state: IPipelineAnalysisState = {
  };

  async componentDidMount() {
  }

  componentWillUnmount() {
    this.props.workflowRunService.resetInternalData();
  }

  render() {
    const pipelineRun = this.props.PipelineService.getState().currentPipelineRun.data;
    const wetlabRunId: string = this.props.PipelineService.getState().currentPipelineRun.data?.WetlabRunId as string;
    const wetlabRunFinished = this.props.PipelineService.getState().currentPipelineRun.data?.Status !== "Started";
    const sequencingData: any = this.props.workflowRunService.freezer.get().fetchSequencingDataState.data;
    const wetlabRunMetadata: any = this.props.workflowRunService.getState().workflowRunMetadata.data as string;
    const isReleased = wetlabRunMetadata ? wetlabRunMetadata.release_flag == "True" : false;
    const wetlab_run_id_display = wetlabRunMetadata ? wetlabRunMetadata.workflow_run_id : "";

    if (pipelineRun) {
      return (
        <div className={styles.mainContainer}>
          <Card className={styles.cardStyle}>

            <div className={styles.headerContainer}>
              <h2>Pipeline Analysis</h2>
              <div>Run the pipeline on selected samples.</div>
            </div>

            {(pipelineRun.WetlabRunNumber !== 0) && <div className={styles.displayDiv}>
              <h3>Wet Lab Workflow Run ID:</h3>
              {wetlab_run_id_display !== "" ?
                <div>{wetlab_run_id_display}</div>
                :
                <div>LIMS Lite Workflow Run {pipelineRun.WetlabRunNumber}</div>
              }

            </div>}

            {!s.isBlank(pipelineRun.BasespaceAnalysisName) && <div className={styles.displayDiv}>
              <h3>BaseSpace Analysis:</h3>
              <div>{pipelineRun.BasespaceAnalysisName}</div>
            </div>}

            <div className={styles.displayDiv}>
              <i>Date and Time Pipeline Launched: {moment(pipelineRun.StartTime).format("MM-DD-YY, h:mm a")}</i>
            </div>
            <div className={styles.displayDiv}>
              <i>Date and Time Pipeline Completed: {pipelineRun.EndTime ? moment(pipelineRun.EndTime).format("MM-DD-YY, h:mm a") : ""}</i>
            </div>
            <div className={styles.displayDiv}>
              <b>Pipeline Status: {getStatusText(pipelineRun)}</b>
            </div>

            {wetlabRunId
              && <GenomicsData wetlabRunId={wetlabRunId} pipelineRunNumber={pipelineRun.RunNumber} sequencingData={sequencingData} />}

            {(pipelineRun.Status === "Complete" && wetlabRunFinished)
              ?
              <div className={styles.runReleaseContainer}>
                {!isReleased && <div className={styles.runReleaseButtonGroup}>
                  <Button disabled={false} variant="contained" className={styles.runFailButton} onClick={c => this.releaseRun(false)}>
                    Fail Run
                  </Button>
                  <Button disabled={false} variant="contained" className={styles.runReleaseButton} color="primary" onClick={c => this.releaseRun(true)}>
                    Release Run
                  </Button>
                </div>}

                {isReleased && <div className={styles.releaseInfo}>
                  Date and Time Released: {wetlabRunMetadata ? wetlabRunMetadata.release_date : 'unavailable'}
                </div>}
              </div>
              :
              <div className={styles.runReleaseNotReady}>
                *Ability to release run will become an option when pipeline and wetlab run are completed
              </div>
            }

          </Card>
        </div>
      )
    }
    return <></>;
  }

  /**
   * Will release run.
   * 
   * @param {boolean} err Determines if post release activities should run on backend when we release the run.
   */
  @bind
  private async releaseRun(err: boolean) {
    await this.props.workflowRunService.releaseRun(this.props.PipelineService.getState().currentPipelineRun.data?.WetlabRunId || "", err)
    await this.props.workflowRunService.fetchWorkflowRunMetadata(true)
  }
}

export function getStatusText(pipelineRun: PipelineRunVM) {
  switch (pipelineRun.Status) {
    case "Complete":
      return "Pipeline Finished";
    case "Failure":
      return "Pipeline Failed";
    case "NotStarted":
      return "Pipeline Not Started";
    case "Started":
      return "Pipeline Started";
    case "PartialResults":
      return "Partial Results";
    case "ResultsNotReleased":
      return "Analysis Failed";
    case "ResultsReleased":
      return "Analysis Released";
  }
  return "Unknown Status";
}

export const PipelineAnalysis = WorkflowRunService.inject(PipelineService.inject(_PipelineAnalysis));