import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil
} from "$Imports/Imports";

import {
    MinKnowConnectionInformationResponse, SettingsApiFactory, MinKnowApiFactory,
} from "$Generated/api";


interface IMinKnowState {
    connectionResults: IAjaxState<MinKnowConnectionInformationResponse>;
}

const InjectedPropName = "minKnowService";

class MinKnowFreezerService extends FreezerService<IMinKnowState, typeof InjectedPropName> {
    constructor() {
        super({
            connectionResults: managedAjaxUtil.createInitialState(),
        }, InjectedPropName);
    }

    public async fetchConnectionStatus(forceUpdate?: boolean)
    {
        const { connectionResults } = this.freezer.get();

        if (connectionResults.hasFetched && !forceUpdate) {
            return;
        }

        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "connectionResults",
            onExecute: (apiOptions, param, options) => {
                const factory = MinKnowApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1MinKnowConnectionInformationGet();
            },
        });
    }

}

export const MinKnowService = new MinKnowFreezerService();
export type IMinKnowServiceInjectedProps = ReturnType<MinKnowFreezerService["getPropsForInjection"]>;
