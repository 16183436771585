import {
  Freezer,
    React,
    _,
    bind
} from "$Imports/Imports";
import * as ReactDOM from 'react-dom';
import {
    WorkflowScreens
} from "./WorkflowStep";

import {
    WorkflowRunService,
    IWorkflowRunServiceInjectedProps
} from "$State/WorkflowRun/WorkflowRunFreezerService"

import {
    InputOutputHeader,
    AdvanceTextField,
    PDFViewerControl,
    CollapsibleSection
} from "$Imports/CommonComponents";

import {
    IconButton,
    InfoIcon,
    Divider,
    Dialog,
    Card,
    Button,
    Snackbar,
    ExitToAppIcon, Popover, List, ListItem
} from "$Imports/MaterialUIComponents";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { NavigationService } from "$State/NavigationFreezerService";
import ReactMarkdown = require("react-markdown");
import { StepInstanceVM } from "$Generated/api";
import { getLogger } from "@yahara/logging";

const styles: {
    headerDiv: string;
    failCard: string;
    failHeader: string;
    failButtons: string;
    headerButtons: string;
    handbook: string;
    card: string;
    header: string;
    list: string;
} = require("./WorkflowScreenManager.scss");

const commonStyles: {
    instructionDiv: string;
} = require("./Steps/CommonStepStyles.scss");

interface IWorkflowScreenManagerBaseProps {
    viewMode?: boolean;
}

export interface IWorkflowScreenManagerState {
    comment: string;
    failOpen: boolean;
    docChoiceOpen: boolean;
    documentOpen: boolean;
    messageSnackbarOpen: boolean;
    infoButton: Element | null;
    document: string;
}

const logger = getLogger("Application");

/*
    Component to manage the workflow screen displayed for a given step. This component determines which screen matches the current workflow step (based on the map defined
    in WorkflowStep.ts) and renders that screen component.
*/
export type IWorkflowScreenManagerProps = IWorkflowScreenManagerBaseProps & IWorkflowRunServiceInjectedProps;

class _workflowScreenManager extends React.Component<IWorkflowScreenManagerProps, IWorkflowScreenManagerState> {

    private _security: ApplicationSecuritySettings = new ApplicationSecuritySettings();

    state: IWorkflowScreenManagerState = {
        failOpen: false,
        docChoiceOpen: false,
        comment: "",
        messageSnackbarOpen: false,
        documentOpen: false,
        infoButton: null,
        document: "",
    }


    render() {
        let component = (<></>)
        //Determine the current workflow step
        let currentStep = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;

        if (currentStep) {
            let selectedStepTemplateId = currentStep.StepTypeId;
            let markdown = currentStep.StepInstructions;
            //Find the screen that corresponds to the current workflow step
            let screen = WorkflowScreens[selectedStepTemplateId];

            if (screen && currentWorkflowRun) {
                const ScreenComponent = screen.screen;
                component = (
                    <div>
                        <div className={styles.headerDiv}>
                            <InputOutputHeader 
                                step={currentStep} 
                                workflowRunAssets={this.props.workflowRunService.getState().workflowAssets} 
                                fetchAssets={this.props.workflowRunService.fetchAssetsForStep} 
                                workflowRun={currentWorkflowRun.toJS()} 
                                inputAssetType={currentStep.InputType} 
                                outputAssetType={currentStep.OutputType} />
                            {currentStep.HelpDocuments && currentStep.HelpDocuments.length > 0 && <IconButton className={styles.headerButtons} onClick={(event) => { this.setState({ docChoiceOpen: true, infoButton: event.currentTarget }) }}>
                                <InfoIcon />
                            </IconButton>
                            }
                            <Button disabled={currentStep.Status !== "InProgress"} className={styles.headerButtons} onClick={() => { this.saveScreen(); }}>Save</Button>
                        </div>
                        {currentWorkflowRun && currentWorkflowRun.RunState === "Failed" && <h3 style={{ color: "red" }}>This workflow run has been set as a failed run. Return to Runs.</h3>}
                        {currentWorkflowRun && currentWorkflowRun.RunState === "Completed" && <h3>This workflow run has been completed. Return to Runs.</h3>}
                        {currentWorkflowRun && currentWorkflowRun.RunState === "CompletedWithPipelineError" && <h3 style={{ color: "red" }}>This workflow run has completed with pipeline errors. Return to Runs.</h3>}
                        <Divider />
                        <CollapsibleSection sectionHeader={"Instructions"}>
                          <div className={commonStyles.instructionDiv}>
                              <Button size="small" color="primary" onClick={c => this.openInstructionsInNewBrowserTab(markdown, currentStep)}>
                                Open in browser tab
                              </Button>
                              <ReactMarkdown source={markdown} />
                          </div>
                        </CollapsibleSection>
                        <ScreenComponent workflowRunService={this.props.workflowRunService} failRun={this.failRunStart} saveScreen={this.saveScreen} viewMode={this.props.viewMode} stepId={selectedStepTemplateId} />
                        <Dialog
                            open={this.state.failOpen}
                            onClose={this.handleClose}
                        >
                            <Card className={styles.failCard}>
                                <h2 className={styles.failHeader}>
                                    Enter a comment to confirm and fail the run
                                </h2>
                                <AdvanceTextField onDebouncedChange={this.onFailCommentChanged} />
                                <div className={styles.failButtons}>
                                    <Button disabled={this.state.comment === ""} onClick={this.failRunFinalize}>Confirm</Button>
                                    <Button onClick={this.handleClose}>Cancel</Button>
                                </div>
                            </Card>
                        </Dialog>
                        <Popover
                            open={this.state.docChoiceOpen}
                            onClose={this.handleClose}
                            anchorEl={this.state.infoButton}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Card className={styles.card}>
                                <h2 className={styles.header}>
                                    Help Documents
                                </h2>
                                <List
                                    id="asset-select"
                                    className={styles.list}
                                >
                                    {_.map(currentStep.HelpDocuments, document => (
                                        <ListItem button key={document.Filename} onClick={() => this.setState({ docChoiceOpen: false, documentOpen: true, document: document.Filename })}>
                                            {document.DisplayName}
                                        </ListItem>
                                    ))}
                                </List>
                            </Card>
                        </Popover>
                        <Dialog
                            open={this.state.documentOpen}
                            onClose={this.handleClose}
                            maxWidth={'lg'}
                        >
                            <PDFViewerControl document={this.state.document} />
                        </Dialog>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={"Data has been saved"}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>
                )
            }
        }

        return component;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private failRunStart() {
        this.setState({ failOpen: true });
    }

    @bind
    private async failRunFinalize() {
        //Go to the freezer
        const step = this.props.workflowRunService.currentStep;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        if (step && currentWorkflowRun && step.OutputAssets) {
            const outputPlate = _.find(step.OutputAssets, plate => (plate.Name === step.OutputName));
            if (outputPlate && this.state.comment) {
                await this.props.workflowRunService.failRun(step.StepInstanceId, (this._security.userContext ? this._security.userContext.preferred_username : ""), this.state.comment, currentWorkflowRun.Id, outputPlate.Id)
            }
        }
        this.setState({ failOpen: false, comment: "" });
        this.props.workflowRunService.fetchWorkflowRun(true); //reload data
    }

    @bind
    private handleClose() {
        this.setState({ failOpen: false, comment: "", documentOpen: false, docChoiceOpen: false });
    }

    @bind
    private onFailCommentChanged(newValue: string) {
        this.setState({ comment: newValue });
    }

    @bind
    async saveScreen(displayMessage: boolean = true) {
        let step = this.props.workflowRunService.currentStep;
        if (step) {
            let screen = WorkflowScreens[step.StepTypeId];
            if (screen.saveActions) {
                for (let action of screen.saveActions) {
                    action(this.props.workflowRunService);
                }
            }
            if (displayMessage) {
                this.setState({ messageSnackbarOpen: true });
            }
        }
    }

    @bind
    private async onExit(save: boolean) {
        if (save) {
            await this.saveScreen();
        }
        this.props.workflowRunService.resetCurrentWorkflowRun();
        this.props.workflowRunService.selectedStepInstanceId = "";
        NavigationService.navigateTo("/Runs");
    }

    @bind
    private openInstructionsInNewBrowserTab(markdown:string|undefined, currentStep: Freezer.Types.IFrozenObject<StepInstanceVM> | null | undefined ){
      try {
        const w:Window|null = window.open();
        if(w){
          w.document.title = currentStep?.Name + "-instructions";
          let root = w.document.createElement("div");
          w.document.body.appendChild(root);
          ReactDOM.render(<div><h2>{currentStep?.Name}</h2><ReactMarkdown source={markdown} /></div>, root);
        }
        else{
          logger.error("Unable to open instructions in new tab in browser, window.open() failed us.");
        }
      } catch(error){
        let message = 'Error opening instruction tab'
        logger.error(message)
        if (error instanceof Error){
          logger.error(error.message);
        } 
      }
    }
}
export const WorkflowScreenManager = WorkflowRunService.inject(_workflowScreenManager);