import {
    React,
    _,
} from "$Imports/Imports";



const styles: {
    header: string;
} = require("../../NGSQCReportsLayoutPage.scss");


interface IReportHeaderProps {
    workflowRunNumber: string | undefined;
    workflowDisplayName: string;
    users: string[] | undefined;
    systemSettings: {[key: string]: string};
    reportName: string;
};

export class ReportHeader extends React.Component<IReportHeaderProps> {


    render()
    {
        return (
            
            <div className={styles.header}>
                <h2>Next Generation Sequencing Quality Control Standardization of the {this.props.reportName}</h2>
                <div>Run {this.props.workflowRunNumber} - {this.props.workflowDisplayName}</div>
                <div>Analysis Point of Contact:</div>
                <div>{this.props.users?.map((u, ind) => (
                    <section key={ind}>{u}</section>
                ))}</div>
                <div>Computing Environment: LIMS Lite, {this.props.systemSettings["site_name"] || "Site Name Missing"}, {(this.props.systemSettings["environment"] || this.props.systemSettings["hardware_id"]) || "Computing Environment Missing"}</div>
                <div>TODO: SITE SPECIFIC COMPUTING ENVIRONMENT</div>
            </div>
            
        )
    }

}