import {
    React,
    _,
    bind,
    Freezer,
} from "$Imports/Imports";

import {
    StepInstanceVM, ActionType, WellAddRequestVM
} from "$Generated/api";

import {
    StepActionButtons
} from "./StepActionButtons";

import {
    AddCommentControl
} from "./Actions/AddCommentControl";

import {
    ShowCommentsControl
} from "./Actions/ShowCommentsControl";

import {
    AssetActionsControl
} from "./Actions/AssetActionsControl";

import {
    GridActionsControl
} from "./Actions/GridActionsControl";

import {
    WorkflowRunFreezerService
} from "$State/WorkflowRun/WorkflowRunFreezerService";
import { Button, Snackbar } from "$Imports/MaterialUIComponents";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";

export interface IStepActionsControlProps {
    step: Freezer.Types.IFrozenObject<StepInstanceVM>;
    actionHandler?: (actionType: number) => void;
    workflowRunService: WorkflowRunFreezerService;
    saveScreen: (showMessage: boolean) => Promise<void>;
    hideArchiveRetain?: boolean;
    showGridControl?: boolean;
}


export interface IStepActionsControlState {
    currentAction: ActionType | null
    currentButton: Element | null;
    showCommentsOpen: boolean;
    messageSnackbarOpen: boolean;
    message: string;
    showQcsOpen: boolean;
}

const styles: {
    controlDiv: string;
    button: string;
} = require("./StepActionsControl.scss");

export class StepActionsControl extends React.PureComponent<IStepActionsControlProps, IStepActionsControlState> {

    private _security: ApplicationSecuritySettings = new ApplicationSecuritySettings();

    constructor(props: IStepActionsControlProps) {
        super(props);
        this.state = { currentAction: null, currentButton: null, showCommentsOpen: false, messageSnackbarOpen: false, message: "", showQcsOpen: false };
    }


    render() {
        const step = this.props.step.toJS();
        if (step) {

            var inputAssetsInUse = _.find(step.InputAssets, a => (a.AssetType === "Plate" && a.PlateSummary?.Status === "InUse") || (a.AssetType === "Pool" && a.PoolSummary?.Status === "InUse"));

            var actionButtons = (<StepActionButtons
                actionHandler={this.actionClickHandler}
                actions={step.Actions}
                hideArchiveRetain={this.props.hideArchiveRetain || (inputAssetsInUse == undefined)}
                showGridControl={this.props.showGridControl}
            ></StepActionButtons>);

            return (
                <div className={styles.controlDiv}>
                    {actionButtons}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {
                            (step.Notes && step.Notes.length > 0) &&
                            (<div>
                                <Button key={"showComments"} variant="contained" size="small" color="primary" className={styles.button}
                                    onClick={(event) => { this.setState({ showCommentsOpen: true }) }}
                                >       Show Comments
                                </Button>
                                <ShowCommentsControl comments={step.Notes} key="comment" open={this.state.showCommentsOpen} close={() => { this.setState({ showCommentsOpen: false }) }} />
                            </div>)

                        }
                    </div>

                    {
                        _.find(this.props.step.Actions, (action) => {
                            return action.ActionType === "AddComment" && !action.IsPerformedOnStepCompletion;
                        }) &&
                        <AddCommentControl key="comment" open={this.state.currentAction === "AddComment"} close={this.actionClose} save={this.commentSave} buttonElement={this.state.currentButton} />
                    }
                    {
                        _.find(this.props.step.Actions, (action) => {
                            return action.ActionType === "DiscardPlate" && !action.IsPerformedOnStepCompletion;
                        }) &&
                        <AssetActionsControl
                            key="discard"
                            open={this.state.currentAction === "DiscardPlate"}
                            close={this.actionClose}
                            save={this.discardPlates}
                            actionString={"Discard Plate(s)"}
                            buttonElement={this.state.currentButton}
                            assets={step.InputAssets}
                            type="Plate"
                        />

                    }
                    {

                        _.find(this.props.step.Actions, (action) => {
                            return action.ActionType === "ArchivePlate" && !action.IsPerformedOnStepCompletion;
                        }) && !this.props.hideArchiveRetain &&
                        <AssetActionsControl
                            key="Archive"
                            open={this.state.currentAction === "ArchivePlate"}
                            close={this.actionClose}
                            save={this.archivePlates}
                            actionString={"Archive Plate(s)"}
                            buttonElement={this.state.currentButton}
                            assets={step.InputAssets}
                            type="Plate"
                        />
                    }
                    {
                        _.find(this.props.step.Actions, (action) => {
                            return action.ActionType === "RetainPlate" && !action.IsPerformedOnStepCompletion;
                        }) && !this.props.hideArchiveRetain &&
                        <AssetActionsControl
                            key="retain"
                            open={this.state.currentAction === "RetainPlate"}
                            close={this.actionClose}
                            save={this.retainPlates}
                            actionString={"Retain Plate(s)"}
                            buttonElement={this.state.currentButton}
                            assets={step.InputAssets}
                            type="Plate"
                        />
                    }
                    {
                        _.find(this.props.step.Actions, (action) => {
                            return action.ActionType === "RetainPool" && !action.IsPerformedOnStepCompletion;
                        }) && !this.props.hideArchiveRetain &&
                        <AssetActionsControl
                            key="retain-pool"
                            open={this.state.currentAction === "RetainPool"}
                            close={this.actionClose}
                            save={this.retainPools}
                            actionString={"Retain Pools(s)"}
                            buttonElement={this.state.currentButton}
                            assets={step.InputAssets}
                            type="Pool"
                        />
                    }
                    {
                        this.props.showGridControl && 
                        <GridActionsControl
                            key="add-control"
                            open={this.state.currentAction === "AddControl"}
                            close={this.actionClose}
                            save={this.saveControls}
                            actionString={"Add Control"}
                            workflowRunService={this.props.workflowRunService}
                        />
                    }
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={this.state.messageSnackbarOpen}
                        message={this.state.message}
                        autoHideDuration={5000}
                        onClose={this.snackbarClose}
                    />
                </div>
            );
        }
        return <></>;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private actionClickHandler(actionType: ActionType, button: Element) {
        this.setState({ currentAction: actionType, currentButton: button });
    }

    @bind
    private actionClose() {
        this.setState({ currentAction: null, currentButton: null });
    }

    //#region Action API calls
    @bind
    private async commentSave(remarks: string) {
        const stepInstanceId = this.props.step.StepInstanceId;
        await this.props.saveScreen(false);

        await this.props.workflowRunService.addComment(stepInstanceId, (this._security.userContext ? this._security.userContext.preferred_username : ""), remarks);
        this.setState({ messageSnackbarOpen: true, message: "Comment added" });
        this.actionClose();
    }

    @bind
    private async discardPlates(ids: string[]) {
        await this.props.saveScreen(false);
        this.props.workflowRunService.updatePlateStatus(ids, "Discarded");
        this.setState({ messageSnackbarOpen: true, message: "Plate was discarded" });
        this.actionClose();
    }

    @bind
    private async retainPlates(ids: string[]) {
        await this.props.saveScreen(false);
        this.props.workflowRunService.updatePlateStatus(ids, "Retained");
        this.setState({ messageSnackbarOpen: true, message: "Plate was retained" });
        this.actionClose();
    }

    @bind
    private async retainPools(ids: string[]) {
        await this.props.saveScreen(false);
        this.props.workflowRunService.changePoolStatus(ids, "Retained");
        this.setState({ messageSnackbarOpen: true, message: "Pool was retained" });
        this.actionClose();
    }

    @bind 
    private async saveControls(plateId: string, workFlowRunId: string, controls: WellAddRequestVM[]) {
        await this.props.workflowRunService.addControlsToPlate(plateId, workFlowRunId, controls);
    }

    @bind
    private async archivePlates(ids: string[]) {
        await this.props.saveScreen(false);
        this.props.workflowRunService.updatePlateStatus(ids, "Archived");
        this.setState({ messageSnackbarOpen: true, message: "Plate was archived" });
        this.actionClose();
    }

    //#endregion
}