import {
    React,
    _,
    bind
} from "$Imports/Imports";

import {
    Button,
    Card,
    Popover
} from "$Imports/MaterialUIComponents";
import {
    AdvanceTextField
} from "$Imports/CommonComponents";

export interface IAddCommentControlProps {
    open: boolean;
    save: (remarks: string) => void;
    close: () => void;
    buttonElement: Element | null
}

const styles: {
    card: string;
    header: string;
    buttons: string;
} = require("./AddCommentControl.scss");

export interface IAddCommentControlState {
    comment: string;
}

export class AddCommentControl extends React.PureComponent<IAddCommentControlProps, IAddCommentControlState> {

    constructor(props: IAddCommentControlProps) {
        super(props);
        this.state = { comment: "" };
    }

    render() {

        return (this.props.buttonElement &&


            <Popover
                open={this.props.open}
                onClose={this.handleClose}
                anchorEl={this.props.buttonElement}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Card className={styles.card}>
                    <h2 className={styles.header}>
                        Add Comment
                    </h2>
                    <AdvanceTextField onDebouncedChange={this._onCommentChanged} multiline />
                    <div className={styles.buttons}>
                        <Button onClick={this.onSave}>Save</Button>
                        <Button onClick={this.props.close}>Close</Button>
                    </div>
                </Card>
            </Popover>
        );

    }

    @bind
    private _onCommentChanged(newValue: string) {
        this.setState({ comment: newValue });
    }

    @bind
    private handleClose() {
        this.props.close();
    }

    @bind
    private onSave() {
        this.props.save(this.state.comment);
    }
}