import {
    React,
    bind,
    moment,
    _,
    ReactGA
} from "$Imports/Imports";

import {
    MenuItem,
    ListItemIcon,
    Dialog,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Card,
    ListItemSecondaryAction,
    Button
} from "$Imports/MaterialUIComponents";

import {
    AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
    ExpandMore,
    ExpandLess
} from "$Imports/MaterialUIIcons"


import {
    IResultsServiceInjectedProps,
    ResultsService,
    FileSharingAPI
} from "$State/ResultsFreezerService"

import {
    MinitMinderService,
    IMinitMinderServiceInjectedProps,
} from "$State/MinitMinderFreezerService"

import {
    WetlabRunVM, SequencingDataVM, DirectoryListingVM, MinitMinderJobStatusVM
} from "$Generated/api";
import { NavigationService } from "$State/NavigationFreezerService";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { exception } from "react-ga";
var urljoin = require('url-join');

const styles: {
    mainContainer: string;
    cardStyle: string;
    cardHeader: string;
    mainList: string;
    fileList: string;
    downloadAll: string;
} = require("./ResultsPage.scss");

interface IResultsPageBaseProp {
    selectedRun?: string
}

type IResultsPageProp = IResultsServiceInjectedProps & IMinitMinderServiceInjectedProps & IResultsPageBaseProp;

interface IResultsPageState {
    dataLoaded: boolean
    runs: WetlabRunVM[],
    sequencingData: { [key: string]: SequencingDataVM[] },
    showResults: boolean[],
    showModule: { [key: string]: boolean },
    showFailDialog: boolean,
    directoryListings: { [key: string]: DirectoryListingVM[] },
    minderJobs: MinitMinderJobStatusVM[],
    runsWithReports: number[],
    folders: { [key: string]: string },
}

// tslint:disable-next-line: class-name
export class _ResultsPage extends React.Component<IResultsPageProp, IResultsPageState> {

    private _security = new ApplicationSecuritySettings();

    state: IResultsPageState = {
        dataLoaded: false,
        runs: [],
        sequencingData: {},
        showResults: [],
        showModule: {},
        showFailDialog: false,
        directoryListings: {},
        minderJobs: [],
        runsWithReports: [],
        folders: {}
    }

    async componentDidMount() {
        await this.props.ResultsService.fetchWorkflowRuns(true).then(() => {
            this.props.ResultsService.fetchWorkflowRunSequencingData(true);
            this.getAndParseJobs();
            this.setModuleNames(this.props.ResultsService.getState().WorkflowRuns[this.props.ResultsService.getState().currentPage].data?.Runs ?? []);
        });

        try {
            await this.props.ResultsService.fetchRunReports().then(() => {
                this.props.ResultsService.getState().runReportFiles.data?.forEach((report) => {
                    let reportArr = this.state.runsWithReports;
                    reportArr.push(parseInt(report.Name.charAt(4)));
                    this.setState({ runsWithReports: reportArr });
                })
            });
        }
        catch {
            console.log("No Run Reports Exist");
        }

        const state = this.props.ResultsService.getState();

        const {
            WorkflowRuns,
            fetchSequencingDataState,
            currentPage
        } = state;

        let runs = WorkflowRuns[currentPage].data?.Runs ?? [];
        let sequencingData = fetchSequencingDataState.data ?? {};

        let names: { [key: string]: string } = {};
        runs.forEach((run) => {
            if (Object.keys(sequencingData).includes(run.Id)) {
                names[run.Id] = Object.entries(sequencingData[run.Id][0].MetadataDictionary!)[0][1].substring(0, 10);
            }
        });

        this.setState({ runs: runs, folders: names, sequencingData: sequencingData });

        _.forEach(this.state.runs, (run, runIdx) => {
            if (this.props.selectedRun === run.Id) {
                this.toggleRunOpened(runIdx)
            }
        });
    }

    render() {
        // tslint:disable-next-line: no-console
        const state = this.props.ResultsService.getState();
        const { jobStatusResults } = this.props.MinitMinderService.getState();

        const {
            WorkflowRuns: WorkflowRunFetchResults,
            fetchSequencingDataState: SequencingData,
            currentPage,
            directoryResults: directoryListings
        } = state;


        this.state.runs = WorkflowRunFetchResults[currentPage].data?.Runs ?? [];
        this.state.sequencingData = SequencingData.data ?? {};
        jobStatusResults.data?.forEach((value) => {
            if (value.Status == "FINISHED") {
                var run = this.state.runs.find((run) => run.RunNumber == parseInt(value.RunId));
                if (run != null) {
                    var path = run.WorkflowName == "Influenza" ? "run/" : "";
                    var id = path + value.RunId + "-" + value.Tag;
                    this.state.directoryListings[id] = directoryListings[id]?.data ?? [];
                }
            }
        })

        return (
            <div
                className={styles.mainContainer}
            >
                <Dialog
                    open={this.state.showFailDialog}
                    onClose={() => this.setState({ showFailDialog: false })}
                    PaperProps={{ style: { maxWidth: "1200px" } }}
                >
                    <Card className={styles.cardStyle}>
                        <h3>Error downloading all run files</h3>
                        <div style={{ float: "right" }}>
                            <Button onClick={() => this.setState({ showFailDialog: false })}>Close</Button>
                        </div>
                    </Card>
                </Dialog>
                <Card
                    className={styles.cardStyle}
                >
                    <AjaxActionIndicator
                        state={SequencingData}
                    />
                    <div style={{ width: "100%" }}>
                        <h2 className={styles.cardHeader}>Sequencing Run Reporting and Analysis</h2>
                    </div>
                    <div style={{ width: "100%" }}>
                        {this.state.runs.length > 0 ? <List>
                            {_.map(this.state.showModule, (n, nIdx) => (
                                <>
                                    <ListItem button={true} onClick={() => this.toggleModuleOpened(nIdx)}>
                                        {n ? <ExpandLess /> : <ExpandMore />}
                                        <ListItemText primary={nIdx} />
                                    </ListItem>
                                    <Collapse in={this.state.showModule[nIdx]}>
                                        <List>
                                            {_.map(this.state.runs, ((run, nIdx) => {
                                                var minderJob = this.state.minderJobs.find((job) => parseInt(job.RunId) == run.RunNumber)
                                                var runReport = this.state.runsWithReports.includes(run.RunNumber);
                                                var path = run.WorkflowName == "Influenza" ? "run/" : "";
                                                var sequencingComplete = run.SequencingStatus == "Complete" || run.SequencingStatus == "PartialResults" || run.SequencingStatus == "ResultsNotReleased" || run.SequencingStatus == "ResultsReleased";

                                                if (minderJob == undefined && !(sequencingComplete)) {
                                                    if (!runReport || run.WorkflowName == "Influenza") { // No minder run and no run report
                                                        return <>
                                                            <ListItem className={styles.mainList} button={true} onClick={() => this.toggleRunOpened(nIdx)}>
                                                                {this.state.showResults[nIdx] ? <ExpandLess /> : <ExpandMore />}
                                                                <ListItemText primary={"Workflow Run #" + run.RunNumber} />
                                                            </ListItem>
                                                            <Collapse in={this.state.showResults[nIdx]}>
                                                                <List>
                                                                    <ListItem className={styles.fileList}>
                                                                        <ListItemText primary={"Run not complete"} />
                                                                    </ListItem>
                                                                </List>
                                                            </Collapse>
                                                        </>
                                                    } else { // No minder run, but run report exists
                                                        return <>
                                                            <ListItem className={styles.mainList} button={true} onClick={() => this.toggleRunOpened(nIdx)}>
                                                                {this.state.showResults[nIdx] ? <ExpandLess /> : <ExpandMore />}
                                                                <ListItemText primary={"Workflow Run #" + run.RunNumber} />
                                                            </ListItem>
                                                            <Collapse in={this.state.showResults[nIdx]}>
                                                                <List>
                                                                    <ListItem className={styles.fileList} button={true} onClick={() => this.downloadFile("run-reports/run_" + run.RunNumber + "_report.pdf")}>
                                                                        <ListItemText primary={"run_" + run.RunNumber + "_report.pdf"} />
                                                                    </ListItem>
                                                                </List>
                                                            </Collapse>
                                                        </>
                                                    }
                                                } else { // Minder run exists, or completed sequencing status
                                                    return <>
                                                        <ListItem className={styles.mainList} button={true} onClick={() => this.toggleRunOpened(nIdx)}>
                                                            {this.state.showResults[nIdx] ? <ExpandLess /> : <ExpandMore />}
                                                            <ListItemText primary={"Workflow Run #" + run.RunNumber} />
                                                            {run.WorkflowName === "Anthrax" && ((minderJob && minderJob.Status == "FINISHED") || (sequencingComplete)) ? <ListItemSecondaryAction>
                                                                <Button className={styles.downloadAll} onClick={() => this.downloadFile(path + minderJob!.RunId + "-" + minderJob!.Tag + "/" + "results-" + minderJob!.RunId + "-" + minderJob!.Tag + ".zip")}>Download All</Button>
                                                            </ListItemSecondaryAction> : <></>}
                                                        </ListItem>
                                                        <Collapse in={this.state.showResults[nIdx]}>
                                                            <List>
                                                                {(run.WorkflowName !== "Anthrax") ?
                                                                    <ListItem className={styles.fileList} button={true} onClick={() => this.navigateToQcReportPage(run.Id, run.WorkflowId)}>
                                                                        <ListItemText primary="View QC Report" />
                                                                    </ListItem> : <></>
                                                                }
                                                                {run.WorkflowName === "Anthrax" && (
                                                                ((minderJob != null) && (this.state.directoryListings[path + run.RunNumber + "-" + minderJob.Tag] != null)) ? <>{_.map(this.state.directoryListings[path + minderJob.RunId + "-" + minderJob.Tag], (f, fIdx) => (
                                                                    <ListItem className={styles.fileList} button={true} onClick={() => this.downloadFile(path + minderJob!.RunId + "-" + minderJob!.Tag + "/" + f.Name)}>
                                                                        <ListItemText primary={f.Name} />
                                                                    </ListItem>
                                                                ))} {runReport ?
                                                                    <ListItem className={styles.fileList} button={true} onClick={() => this.downloadFile("run-reports/run_" + run.RunNumber + "_report.pdf")}>
                                                                        <ListItemText primary={"run_" + run.RunNumber + "_report.pdf"} />
                                                                    </ListItem> : <></>} </>
                                                                    : runReport ?
                                                                        <ListItem className={styles.fileList} button={true} onClick={() => this.downloadFile("run-reports/run_" + run.RunNumber + "_report.pdf")}>
                                                                            <ListItemText primary={"run_" + run.RunNumber + "_report.pdf"} />
                                                                        </ListItem> :
                                                                        <ListItem className={styles.fileList}>
                                                                            <ListItemText primary={((minderJob && minderJob.Status != "FINISHED") || !(sequencingComplete)) ? "Run not complete" : "Run Report Does Not Exist"} />
                                                                        </ListItem>)}
                                                            </List>
                                                        </Collapse>
                                                    </>
                                                }
                                            }))}
                                        </List>
                                    </Collapse>
                                </>
                            ))}
                        </List> : <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <h3 className={styles.cardHeader}>No completed runs found</h3>
                        </div>}
                    </div>
                </Card>
            </div>
        );
    }

    private setModuleNames(runs: WetlabRunVM[]) {
        let moduleNames: string[] = [];
        let autoOpen = "";
        runs.forEach((run) => {
            if (!moduleNames.includes(run.WorkflowName)) {
                moduleNames.push(run.WorkflowName);
            }
            if (this.props.selectedRun && this.props.selectedRun === run.Id) {
                autoOpen = run.WorkflowName;
            }
        });

        let finalState: { [key: string]: boolean } = {};

        moduleNames.map((module) => {
            finalState[module] = module == autoOpen;
        });

        this.setState({ showModule: finalState });
    }

    @bind
    private async downloadFile(filePath: string = "") {
        let fileName = filePath;
        if (fileName.lastIndexOf("/") != -1) {
            fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
        }
        await fetch(FileSharingAPI + 'DownloadFile/' + filePath, { method: "GET" }).then(
            response => {
                if (response.status < 200 || response.status >= 300) {
                    this.setState({ showFailDialog: true });
                    throw exception;
                }
                return response.blob()
            })
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                let fileDownload = document.createElement("a");
                fileDownload.href = url;
                fileDownload.download = fileName;
                fileDownload.click();
                URL.revokeObjectURL(url);
            })
    }

    @bind
    private toggleModuleOpened(index: string) {
        let modules = this.state.showModule;
        modules[index] = !modules[index];
        this.setState({
            showModule: modules
        });
    }

    @bind
    private toggleRunOpened(index: number) {
        let results = this.state.showResults;
        results[index] = !results[index];
        this.setState({
            showResults: results
        });
    }

    @bind
    private async navigateToQcReportPage(workflowRunId: string, workflowId: string) {
        const navigateUrl = urljoin('/ngsreports', workflowRunId, workflowId);
        NavigationService.navigateTo(navigateUrl);
    }

    @bind
    private async getAndParseJobs() {
        await this.props.MinitMinderService.fetchJobStatuses(true).then(() => {
            const { jobStatusResults } = this.props.MinitMinderService.getState();
            this.props.ResultsService.fetchDirectoryContents("run/", jobStatusResults, true);
            this.props.ResultsService.fetchDirectoryContents("", jobStatusResults, true);
        });
        await this.props.MinitMinderService.fetchSimplifiedRuns();

        var legacyStatus = this.props.MinitMinderService.getState().jobStatusResults.data || [];
        var simplifiedStatus = this.props.MinitMinderService.getState().simplifiedRunStatusResults.data || [];

        var simplifiedAsLegacy: MinitMinderJobStatusVM[] = _.map(simplifiedStatus, s => {

            return {
                RunId: "",
                Status: "",
                Tag: "",
                DirectoryPath: "",
                "DetectedFileCount": 0,
                "QueuedFileCount": 0,
                "ProcessedFileCount": 0,
                "PercentageDone": "",
                "SuccessFailure": "",
                "LastUpdated": "",
                "FinishedTime": ""
            };
        });

        var combined = _.concat(legacyStatus, simplifiedAsLegacy);

        this.setState({ minderJobs: combined });
    }
}


export const ResultsPage = MinitMinderService.inject(ResultsService.inject(
    _ResultsPage
));