import {
    React,
    bind,
    Freezer
} from "$Imports/Imports";

import * as _ from "lodash";

import {
    Link, Paper, IconButton, ExitToAppIcon, Dialog, Card, Button, LoopIcon, DialogActions
} from "$Imports/MaterialUIComponents";

import {
    ReagentGroupLotVM,
    ReagentGroupVM,
    ReagentLotVM,
    ReagentVM,
    StepInstanceVM
} from "$Generated/api";
import { WorkflowRunService, IWorkflowRunServiceInjectedProps } from "$State/WorkflowRun/WorkflowRunFreezerService";

import { WorkflowsService, IWorkflowsServiceInjectedProps } from "$State/WorkflowsFreezerService";

const styles: {
    stepDiv: string;
    currentStepRow: string;
    exitButtons: string;
    exitCard: string;
    futureStepRow: string;
    pastStepRow: string;
    restartButton: string;
    dialog: string;
    tableHeader: string;
} = require("./WorkflowOrderSidebar.scss");

import {
    GetWorkflowType,
    WorkflowScreens
} from "../../WorkflowStep/WorkflowStep";

import { NavigationService } from "$State/NavigationFreezerService";
import { AdvanceTextField, DataTable, IDataTableColumn } from "$Imports/CommonComponents";
import { Chip } from "@material-ui/core";
import { FeedbackIcon } from "$Imports/MaterialUIIcons";
import { SettingsService } from "$State/SettingsFreezerService";

var urljoin = require('url-join');

export interface IWorkflowOrderSidebarBaseProps {
}

export interface IWorkflowOrderSidebarState {
    exitPromptOpen: boolean;
    associationFailedOpen: boolean;
    selectLotsOpen: boolean;
    selectedReagentLotsDictionary: { [index: string]: { lot: (ReagentLotVM | ReagentGroupLotVM), kitId: string } };
    stepViewStructure: { step: string, reagents: (ReagentVM | ReagentGroupVM)[] }[];
    viewByStep: boolean;
    notAllAssociatedOpen: boolean;
    selectedStepInstance?: StepInstanceVM;
    reopeningStep: boolean;
}

type IWorkflowOrderSidebarProps = IWorkflowOrderSidebarBaseProps & IWorkflowRunServiceInjectedProps & IWorkflowsServiceInjectedProps;

export class _WorkflowOrderSidebar extends React.Component<IWorkflowOrderSidebarProps, IWorkflowOrderSidebarState> {

    state: IWorkflowOrderSidebarState = {
        exitPromptOpen: false,
        selectedReagentLotsDictionary: {},
        associationFailedOpen: false,
        selectLotsOpen: false,
        stepViewStructure: [],
        viewByStep: true,
        notAllAssociatedOpen: false,
        reopeningStep: false
    }

    private reagentColumns(): Array<IDataTableColumn<ReagentVM | ReagentGroupVM>> {
        let columns: Array<IDataTableColumn<ReagentVM | ReagentGroupVM>> = [
            {
                columnName: "kit-name",
                columnFieldData: (d) => <div>{this.isReagent(d) ? d.KitName : ""}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Kit Name",
            },
            {
                columnName: "reagent-name",
                columnFieldData: (d) => <div>{d.Name}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Name",
            },
            {
                columnName: "step-names",
                columnFieldData: (d) => <div>{_.map(d.UsedInSteps, step => <div>{step}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Steps",
            },
            {
                columnName: "lot-number",
                columnFieldData: (d) => {
                    let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                    data = _.union(data, d.Lots);
                    let isGroup = !this.isReagent(d);
                    let mapId = this.isReagent(d) ? (d.Id || "") + (d.KitName || "") : d.Id || "";
                    let isKit = this.isReagent(d) ? d.KitId !== undefined : false;
                    return <div>{_.map(_.sortBy(data.filter((lot, i, arr) => (arr.findIndex(t => t.LotNumber === lot.LotNumber) === i) && lot.IsActive && new Date(lot.ExpirationDate) > new Date(Date.now())), d => d.CreatedOn).reverse(), (l) => {
                        let temp = l as ReagentLotVM | ReagentGroupLotVM;
                        if (temp.LotNumber !== undefined) {
                            let selected = _.find(this.state.selectedReagentLotsDictionary, r => r.lot.Id === l.Id) !== undefined;
                            return <Chip
                                label={temp.LotNumber}
                                key={temp.Id}
                                style={{ minWidth: "50px", margin: "5px", backgroundColor: selected ? "green" : "" }}
                                onClick={() => { selected ? this.removeWorkflowReagent(mapId, this.isReagent(d) ? d : undefined) : this.addWorkflowReagent(mapId, temp, this.isReagent(d) ? d : undefined); }}
                            />;
                        }
                        return <></>;
                    })}
                        <AdvanceTextField
                            disabled={this.state.selectedReagentLotsDictionary[mapId] !== undefined && !this.state.selectedReagentLotsDictionary[mapId].lot.IsNew}
                            value={this.state.selectedReagentLotsDictionary[mapId] !== undefined && this.state.selectedReagentLotsDictionary[mapId].lot.IsNew ? this.state.selectedReagentLotsDictionary[mapId].lot.LotNumber : ""}
                            onDebouncedChange={(value) => {
                                isGroup ?
                                    this.addWorkflowReagent(mapId, {
                                        LotNumber: value,
                                        Id: "",
                                        IsActive: true,
                                        CreatedOn: new Date(Date.now()),
                                        CreatedBy: "",
                                        UsedInRun: "NotUsed",
                                        IsNew: true,
                                        ReagentLots: [],
                                        ReagentGroupId: d.Id,
                                        ExpirationDate: new Date()
                                    }, this.isReagent(d) ? d : undefined)
                                    :
                                    this.addWorkflowReagent(mapId, {
                                        LotNumber: value,
                                        Id: "",
                                        IsActive: true,
                                        CreatedOn: new Date(Date.now()),
                                        CreatedBy: "",
                                        UsedInRun: "NotUsed",
                                        IsNew: true,
                                        ReagentId: d.Id,
                                        ReagentName: "",
                                        ReagentDescription: "",
                                        ExpirationDate: new Date()
                                    }, this.isReagent(d) ? d : undefined)
                            }}

                        />
                    </div>;
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Lot/Ref Number",
            }
        ];
        return columns;
    }

    private reagentColumnsByStep(): Array<IDataTableColumn<{ step: string, reagents: (ReagentVM | ReagentGroupVM)[] }>> {
        return [
            {
                columnName: "step",
                columnFieldData: "step",
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Step",
            },
            {
                columnName: "kit-name",
                columnFieldData: (d) => <div>{_.map(d.reagents, reagent => <div style={{ height: "32px" }}>{this.isReagent(reagent) ? reagent.KitName : ""}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Kit Name",
            },
            {
                columnName: "reagent-name",
                columnFieldData: (d) => <div>{_.map(d.reagents, reagent => <div style={{ height: "32px" }}>{reagent.Name}</div>)}</div>,
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Reagent Name",
            },

            {
                columnName: "lot-number",
                columnFieldData: (d) => {
                    return _.map(d.reagents, reagent => {
                        let data: (ReagentLotVM | ReagentGroupLotVM)[] = [];
                        data = _.union(data, reagent.Lots);
                        let isGroup = !this.isReagent(reagent);
                        let mapId = this.isReagent(reagent) ? (reagent.Id || "") + (reagent.KitName || "") : reagent.Id || "";
                        let isKit = this.isReagent(reagent) ? reagent.KitId !== undefined : false;
                        return <div>{_.map(_.sortBy(data.filter((lot, i, arr) => (arr.findIndex(t => t.LotNumber === lot.LotNumber) === i) && lot.IsActive && new Date(lot.ExpirationDate) > new Date(Date.now())), d => d.CreatedOn).reverse(), (l) => {
                            let temp = l as ReagentLotVM | ReagentGroupLotVM;
                            if (temp.LotNumber !== undefined) {
                                let selected = _.find(this.state.selectedReagentLotsDictionary, r => r.lot.Id === l.Id) !== undefined;
                                return <Chip
                                    label={temp.LotNumber}
                                    key={temp.Id}
                                    style={{ minWidth: "50px", margin: "5px", backgroundColor: selected ? "green" : "" }}
                                    onClick={() => { selected ? this.removeWorkflowReagent(mapId) : this.addWorkflowReagent(mapId, temp, this.isReagent(reagent) ? reagent : undefined); }}
                                />;
                            }
                            return <></>;
                        })}
                            <AdvanceTextField
                                disabled={this.state.selectedReagentLotsDictionary[mapId] !== undefined && !this.state.selectedReagentLotsDictionary[mapId].lot.IsNew}
                                value={this.state.selectedReagentLotsDictionary[mapId] !== undefined && this.state.selectedReagentLotsDictionary[mapId].lot.IsNew ? this.state.selectedReagentLotsDictionary[mapId].lot.LotNumber : ""}
                                onDebouncedChange={(value) => {
                                    let isKit = this.isReagent(reagent) ? reagent.KitId !== undefined : false;
                                    isGroup ?
                                        this.addWorkflowReagent(mapId, {
                                            LotNumber: value,
                                            Id: "",
                                            IsActive: true,
                                            CreatedOn: new Date(Date.now()),
                                            CreatedBy: "",
                                            UsedInRun: "NotUsed",
                                            IsNew: true,
                                            ReagentLots: [],
                                            ReagentGroupId: reagent.Id,
                                            ExpirationDate: new Date()
                                        }, this.isReagent(reagent) ? reagent : undefined)
                                        :
                                        this.addWorkflowReagent(mapId, {
                                            LotNumber: value,
                                            Id: "",
                                            IsActive: true,
                                            CreatedOn: new Date(Date.now()),
                                            CreatedBy: "",
                                            UsedInRun: "NotUsed",
                                            IsNew: true,
                                            ReagentId: reagent.Id,
                                            ReagentName: "",
                                            ReagentDescription: "",
                                            ExpirationDate: new Date()
                                        }, this.isReagent(reagent) ? reagent : undefined)
                                }}

                            />
                        </div>;
                    })
                },
                headerProps: {
                    className: styles.tableHeader,
                },
                headerValue: "Lot/Ref Number",
            }
        ];
    }

    @bind
    handleStepChange(step: StepInstanceVM) {
        this.goToStep(step.StepId);
    }

    render() {
        const isDemo = !!SettingsService.getState().demoEnvironmentResults.data;
        const giveFeedback = !!SettingsService.getState().userPreferenceResults.data?.GiveFeedback;
        let feedbackUrl = SettingsService.getState().demoEnvironmentInformation.data?.FeedbackUrl || "";
        if (GetWorkflowType() == "Anthrax") {
            feedbackUrl = "https://app.smartsheet.com/b/form/d7362dbbb49041b6881236ebda2d5971";
        }
        const state = this.props.workflowRunService.getState();
        const {
            stepInstances,
            workflowRun
        } = state;
        let orderedStepData = _.orderBy(stepInstances.data, (step) => {
            return step.WorkflowOrder
        });

        const workflowState = this.props.workflowsService.getState();
        let { fetchWorkflowReagentsResults } = workflowState;
        let reagentsData: (ReagentVM | ReagentGroupVM)[] = [];
        reagentsData = _.union(reagentsData, fetchWorkflowReagentsResults.data?.ReagentGroups, fetchWorkflowReagentsResults.data?.Reagents);

        var currentIndex = Number.MAX_SAFE_INTEGER;
        const currentStep = this.props.workflowRunService.currentStep;
        if (currentStep) {
            return (
                <div className={styles.stepDiv}>
                    {_.map(orderedStepData, (step, idx) => {
                        if (currentStep) {
                            if (step.StepInstanceId === currentStep.StepInstanceId) {
                                currentIndex = idx;
                            }
                            return (

                                <div key={idx}
                                    className={step.StepInstanceId === currentStep.StepInstanceId ? styles.currentStepRow : (idx < currentIndex ? styles.pastStepRow : styles.futureStepRow)}
                                >
                                    {step.StepInstanceId ?
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Link style={{ color: "white" }}>
                                                <div onClick={() => {
                                                    if (step.StepInstanceId) {
                                                        this.handleStepChange(step);
                                                    }
                                                }}>
                                                    {idx + 1}. {step.Name}
                                                </div>
                                            </Link>
                                            {step.IsRestartPoint && step.InputAssets && _.find(step.InputAssets, a => {
                                                if (a.AssetType == "Plate" && (a.PlateSummary?.Status === "Archived" || a.PlateSummary?.Status === "Retained")) {
                                                    return true;
                                                }
                                                if (a.AssetType == "Pool" && (a.PoolSummary?.Status === "Archived" || a.PoolSummary?.Status === "Retained")) {
                                                    return true;
                                                }
                                                return false;
                                            }) &&
                                                <IconButton className={styles.restartButton} onClick={async () => {
                                                    await this.props.workflowsService.fetchWorkflowReagents(workflowRun.data?.WorkflowId || "", true);
                                                    this.setState({ selectedStepInstance: step, selectLotsOpen: true })
                                                }}>
                                                    <LoopIcon />
                                                </IconButton>
                                            }
                                        </div>
                                        :
                                        <div>
                                            {idx + 1}. {step.Name}
                                        </div>
                                    }
                                </div>);
                        }
                        return <></>;
                    })}
                    <IconButton style={{ color: "white" }} onClick={() => {
                        if (currentStep.Status === "InProgress") {
                            this.setState({ exitPromptOpen: true });
                        }
                        else {
                            this.onExit(false);
                        }
                    }}>
                        Exit Workflow <ExitToAppIcon />
                    </IconButton>
                    {((isDemo && giveFeedback) || GetWorkflowType() === "Anthrax") && <Button
                        startIcon={<FeedbackIcon />}
                        style={{ position: "fixed", bottom: "0", width: "300px" ,left:"0px"}}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            window.open(feedbackUrl, "_blank");
                        }}
                    >
                        Give Feedback
                    </Button>}
                    <Dialog
                        open={this.state.exitPromptOpen}
                        onClose={this.handleExitClose}
                        maxWidth={'lg'}
                    >
                        <Card className={styles.exitCard}>
                            {workflowRun.data?.UseSafeStoppingPoints && !currentStep.AllowPause && <div><u><b>Warning:</b></u> This is <b>NOT</b> a safe stopping point.</div>}
                            <h2>Save before Exiting?</h2>
                            <div className={styles.exitButtons}>
                                <Button onClick={() => { this.onExit(true) }}>Yes</Button>
                                <Button onClick={() => { this.onExit(false) }}>No</Button>
                            </div>
                        </Card>
                    </Dialog>
                    <Dialog
                        PaperProps={{ style: { maxWidth: "700px" } }}
                        open={this.state.associationFailedOpen}
                        onClose={this.handleClose}
                        className={styles.dialog}
                    >
                        Some workflow steps do not have required components associated. This run cannot be started.
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { this.handleClose() }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        PaperProps={{ style: { maxWidth: "1500px" } }}
                        open={this.state.selectLotsOpen}
                        onClose={this.handleClose}
                    >
                        <Card>
                            <h2>Components</h2>
                            <h3>Go to the Assets tab to edit components and add/edit lot numbers.</h3>
                            <Link>
                                <div onClick={() => {
                                    this.setState({ viewByStep: !this.state.viewByStep })
                                }}>
                                    {this.state.viewByStep ? "View by Component" : "View by Workflow Step"}
                                </div>
                            </Link>
                            <div style={{ overflow: "auto", height: "450px" }}>
                                {this.state.viewByStep ?
                                    <DataTable
                                        columns={this.reagentColumnsByStep()}
                                        data={this.state.stepViewStructure}
                                    />
                                    :
                                    <DataTable
                                        columns={this.reagentColumns()}
                                        data={reagentsData}
                                    />
                                }
                            </div>
                        </Card>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    let selectedCount = 0;
                                    _.forEach(this.state.selectedReagentLotsDictionary, d => selectedCount++);
                                    if (selectedCount !== reagentsData.length) {
                                        this.setState({ notAllAssociatedOpen: true })
                                    }
                                    else {
                                        if (this.state.selectedStepInstance && !this.state.reopeningStep) {
                                            this.setState({reopeningStep: true});
                                            this.reopenRun(this.state.selectedStepInstance);
                                        }
                                    }
                                }}
                            >
                                Continue
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { this.handleClose() }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        PaperProps={{ style: { maxWidth: "700px" } }}
                        open={this.state.notAllAssociatedOpen}
                        onClose={() => this.setState({ notAllAssociatedOpen: false })}
                        className={styles.dialog}
                    >
                        You have not specified lot numbers for some components that this run will use.
                        <DialogActions>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (this.state.selectedStepInstance) {
                                        this.reopenRun(this.state.selectedStepInstance);
                                    }
                                }}
                            >
                                Continue
                            </Button> */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.setState({ notAllAssociatedOpen: false })}
                            >
                                Change Lot Numbers
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
        return <></>;
    }

    @bind
    goToStep(stepId: string) {
        let state = this.props.workflowRunService.getState();
        if (state.stepInstances.hasFetched && state.stepInstances.data) {
            let stepInstance = state.stepInstances.data.find((step) => {
                return step.StepId === stepId;
            });
            if (stepInstance) {
                this.props.workflowRunService.selectedStepInstanceId = stepInstance.StepInstanceId;
            }
        }
    }

    @bind
    async saveScreen() {
        let step = this.props.workflowRunService.currentStep;
        if (step) {
            let screen = WorkflowScreens[step.StepTypeId];
            if (screen.saveActions) {
                for (let action of screen.saveActions) {
                    action(this.props.workflowRunService);
                }
            }
        }
    }

    @bind
    private async onExit(save: boolean) {
        if (save) {
            await this.saveScreen();
        }
        this.props.workflowRunService.resetCurrentWorkflowRun();
        this.props.workflowRunService.selectedStepInstanceId = "";
        NavigationService.navigateTo("/Runs");
    }

    @bind
    private handleExitClose() {
        this.setState({ exitPromptOpen: false });
    }

    @bind
    private reopenRun(stepInstance: StepInstanceVM) {
        const currentRun = this.props.workflowRunService.currentWorkflowRun;
        if (stepInstance.InputAssets && currentRun) {
            var assetToUse = _.find(stepInstance.InputAssets, a => {
                if ((a.AssetType === "Plate" && a.PlateSummary && (a.PlateSummary.Status === "Archived" || a.PlateSummary.Status === "Retained")) || (a.AssetType === "Pool" && a.PoolSummary && (a.PoolSummary.Status === "Archived" || a.PoolSummary.Status === "Retained"))) {
                    return true;
                }
                return false;
            });
            if (assetToUse) {
                this.props.workflowsService.reopenWorkflowFromStep(stepInstance.StepId, assetToUse.Id, currentRun.Id, this.state.selectedReagentLotsDictionary, async (workflowInstanceId, stepInstanceId) => {
                    NavigationService.navigateTo(urljoin("/WorkflowRun", workflowInstanceId, "step", stepInstanceId));
                    this.props.workflowRunService.workflowId = workflowInstanceId;
                    this.props.workflowRunService.selectedStepInstanceId = ""; //reset
                    await this.props.workflowRunService.fetchWorkflowRun(true);
                    this.props.workflowRunService.selectedStepInstanceId = stepInstanceId;
                });
            }
        }
    }

    @bind
    private handleClose() {
        this.setState({ selectLotsOpen: false });
    }

    @bind
    private addWorkflowReagent(reagentId: string, reagentLot: ReagentLotVM | ReagentGroupLotVM, reagent?: ReagentVM) {
        let newReagents = _.cloneDeep(this.state.selectedReagentLotsDictionary);

        newReagents[reagentId] = { lot: reagentLot, kitId: reagent?.KitId || "" };


        if (reagent && reagent.KitName) {
            //kits
            //loop all reagents, if kit id = reagent.kitId, find where lot number = same and set.
            let state = this.props.workflowsService.getState();
            let { fetchWorkflowReagentsResults } = state;
            _.forEach(fetchWorkflowReagentsResults.data?.Reagents, r => {
                if (r.KitId === reagent.KitId && r.Id !== reagent.Id) {
                    var lot = _.find(r.Lots, l => l.LotNumber === reagentLot.LotNumber);
                    if (lot !== undefined) {
                        newReagents[(r.Id || "") + (r.KitName || "")] = { lot: lot, kitId: reagent?.KitId || "" };;
                    }
                    else {
                        if (reagentLot.IsNew) {
                            newReagents[(r.Id || "") + (r.KitName || "")] =
                            {
                                lot: {
                                    LotNumber: reagentLot.LotNumber,
                                    Id: "",
                                    IsActive: true,
                                    CreatedOn: new Date(Date.now()),
                                    CreatedBy: "",
                                    UsedInRun: "NotUsed",
                                    IsNew: true,
                                    ReagentId: r.Id,
                                    ReagentName: "",
                                    ReagentDescription: "",
                                    ExpirationDate: new Date()
                                },
                                kitId: reagent?.KitId || ""
                            };
                        }
                    }
                }
            });
        }

        this.setState({ selectedReagentLotsDictionary: newReagents });
    }

    @bind
    private removeWorkflowReagent(reagentId: string, reagent?: ReagentVM) {
        let newReagents = _.cloneDeep(this.state.selectedReagentLotsDictionary);
        delete newReagents[reagentId];

        //kits
        if (reagent && reagent.KitName) {
            let state = this.props.workflowsService.getState();
            let { fetchWorkflowReagentsResults } = state;
            _.forEach(fetchWorkflowReagentsResults.data?.Reagents, r => {
                if (r.KitId === reagent.KitId && r.Id !== reagent.Id) {
                    delete newReagents[(r.Id || "") + (r.KitName || "")];
                }
            });
        }

        this.setState({ selectedReagentLotsDictionary: newReagents });
    }

    private isReagent(r: ReagentVM | ReagentGroupVM): r is ReagentVM {
        return (r as ReagentVM).InKits !== undefined;
    }
}

export const WorkflowOrderSidebar = WorkflowsService.inject(WorkflowRunService.inject(_WorkflowOrderSidebar));