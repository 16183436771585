import {
    React,
    _,
    bind,
    C3
} from "$Imports/Imports";
import { Card, Link } from "$Imports/MaterialUIComponents";

import { IDashboardServiceInjectedProps, DashboardService } from "$State/DashboardFreezerService";
import { DataLoadingDisplay } from "../DataLoadingDisplay";


interface ITodaysWorkloadBaseProps {
    width: number;
}

const styles: {
    cardStyle: string;
    headerStyle: string;
} = require("./WidgetCommon.scss");

type ITodaysWorkloadProps = ITodaysWorkloadBaseProps & IDashboardServiceInjectedProps;



class _TodaysWorkload extends React.Component<ITodaysWorkloadProps> {

    async componentDidMount() {
        await this.props.dashboardService.fetchTodaysWorkload();
    }

    render() {
        var data = this.props.dashboardService.getState().todaysWorkloadFetchResults.data;

        if (data) {
            return <Card
                className={styles.cardStyle}
                style={{width: this.props.width + "%"}}
            >
                <h3 className={styles.headerStyle}>
                    Today's Workload
                </h3>
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <i>Total Test Orders</i>
                </div>
                <div style={{ background: "#688da7", width: "85%", textAlign: "center", margin: "auto", border: "1px solid black" }}>
                    <b>{data.TotalTestOrders}</b>
                </div>
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <i>Total Wet-Lab QC Failures</i>
                </div>
                <div style={{ background: "#f0beb0", width: "75%", textAlign: "center", margin: "auto", border: "1px solid black" }}>
                    <b>{data.TotalQCFailures}</b>
                </div>
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <i>Last Run ID</i>
                </div>
                <div style={{ background: "#d0cece", width: "55%", textAlign: "center", margin: "auto", border: "1px solid black" }}>
                    <b>{data.LastRunId}</b>
                </div>
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <i>Time Elapsed</i>
                </div>
                <div style={{ background: "#a3aab1", width: "40%", textAlign: "center", margin: "auto", border: "1px solid black" }}>
                    <b>{data.TimeElapsed}</b>
                </div>
            </Card>;
        }
        return <DataLoadingDisplay />;
    }
}

export const TodaysWorkload = DashboardService.inject(_TodaysWorkload);