import {
    React,
    bind,
    _,
} from "$Imports/Imports"
import
    * as s
    from "underscore.string";

import {
    IWorkflowScreenProps
} from "../WorkflowStep";
import {
    StepActionsControl,
    StepChangeControl,
    InstructionSetControl,
    QCUIComponent,
    CollapsibleSection,
    DataLoadingDisplay,
    MasterMixControl
} from "$Imports/CommonComponents";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";
import { TextField } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { RemoteAssociationVM, FileShareWith  } from "$Generated/api";
import { Button } from "$Imports/MaterialUIComponents";
import { FileSharingService, FileSharingAPI  } from "$State/FileShareManagementService";

const sampleImage = require("../../../images/SampleGel.png");
let placeholderImage = require("../../../images/placeholder.png");

const styles: {
    leftDiv: string;
    rightDiv: string;
    columnButton: string;
    downloadButton: string;
    image: string;
    textFieldDiv: string;
    sampleGelDiv: string;
    userImage: string;
} = require("./GelElectrophoresis.scss");

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
    collapsibleDiv: string;
} = require("./CommonStepStyles.scss");


interface IGelElectrophoresisState {
    messageSnackbarOpen: boolean,
    isValid: boolean,
    canMove: boolean,
    gelQuality: number,
    currentlySelected: string,
    uploadFile?: File,
    fileData: IFileData[],
    showDuplicateFileError: boolean,
    showDuplicateDirectoryError: boolean,
    showSharedFileDeleteError: boolean,
    showPopulatedDirectoryDeleteError: boolean,
    createFileOrDirectoryDialog: boolean,
    selectedFilesOrDirectory: string[],
    creating: string,
    expanded: string[],
    associationData?: RemoteAssociationVM[] | null,
    imagePath: string
    imageData?: Blob
}

interface IFileData {
    id: string,
    fileName: string,
    type: string,
    createdOn: Date,
    fileSharedTo: number,
    fileSharedToHidden: FileShareWith[],
    fileReceivedFrom?: string
}


export class GelElectrophoresisScreen extends React.Component<IWorkflowScreenProps, IGelElectrophoresisState> {


    state: IGelElectrophoresisState = {
        messageSnackbarOpen: false,
        isValid: false,
        canMove: true,
        gelQuality: 2,
        currentlySelected: "Directory",
        uploadFile: undefined,
        fileData: [],
        showDuplicateFileError: false,
        showDuplicateDirectoryError: false,
        showSharedFileDeleteError: false,
        showPopulatedDirectoryDeleteError: false,
        createFileOrDirectoryDialog: false,
        selectedFilesOrDirectory: [],
        creating: "",
        expanded: [],
        associationData: [],
        imagePath: "start",
        imageData: undefined
    };

    async componentDidMount() {
        await this.props.workflowRunService.fetchRacksForStep(true); //Gel Electrophoresis is unique to Anthrax so we aren't going to check workflow
        await this.props.workflowRunService.fetchMasterMixForStep(true);
        await this.props.workflowRunService.fetchStepInstanceCustomFields();

        const currentStep = this.props.workflowRunService.currentStep;
        let customFields = currentStep!.toJS().CustomFields;
        let imageUploaded = customFields["imagePath"];
        //check if customFields has a path from this workflow already
        //the path could be start if the workflow was stopped before Gel Electrophoresis was finished
        if (!s.isBlank(imageUploaded) && imageUploaded !== "start") {
            //fetch the image from simpleshare (this may take some time depending on how long simpleshare takes to respond)
            fetch(FileSharingAPI + 'DownloadFile/' + imageUploaded, { method: "GET"})
            .then(response => response.blob())
            .then(blob => {this.setState({imageData: blob})})

            if (this.state.imagePath === "start")
            {
                this.setState({ imagePath: imageUploaded });
            }
        }
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const racks = this.props.workflowRunService.getState().racks;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        let inputAsset;

        if (currentStep) {
            inputAsset = _.find(racks.data, rack => (rack.Name === currentStep.InputName));
        }
        //Store the selected file in React state
        const saveUploadFile = () => {
            const file = document.getElementById('fileButton') as HTMLInputElement;
            if (file.files != null) {
                this.setState({ uploadFile: file.files[0] })
            }
        }
        //Trigger the file input
        const fileUploadButton = () => {
            document.getElementById('fileButton')?.click();
        }

        if (inputAsset && masterMixInfoState.data && lotOptions && currentStep && currentWorkflowRun) {

            let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
            return (
                <div>
                    <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                        <div className={commonStyles.mainDiv}>
                            <div className={styles.leftDiv}>
                                    <MasterMixControl
                                    disabled={disabled}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={inputAsset.WellContents.length}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove: boolean) => { this.setState({ canMove: canMove }) }}
                                    index={0}/>
                                
                            </div>
                            <div className={styles.rightDiv}>
                                <h2>Sample Results</h2>
                                <div className={styles.leftDiv}>
                                    <div className={styles.leftDiv}>
                                        <Button 
                                            variant="contained" 
                                            size="small" 
                                            color="primary"
                                            disabled={disabled} 
                                            onClick={() => { this.uploadFile(); }}> 
                                            Upload Image 
                                        </Button>
                                        <input id="fileButton" type="file" hidden onChange={saveUploadFile} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            className={styles.columnButton}
                                            onClick={fileUploadButton}
                                            disabled={disabled} 
                                            style = {{ margin: "10px"}}>
                                            Choose File
                                        </Button>
                                    </div>
                                    <div></div>
                                    <div className={styles.downloadButton}>
                                        <Button 
                                            variant="contained" 
                                            size="small" 
                                            color={(this.state.imagePath === "start") ? "default" : "primary"}
                                            disabled={(this.state.imagePath === "start")}
                                            onClick={() => { this.downloadFile(this.state.imagePath); }}> 
                                            Download Last Image 
                                        </Button>
                                    </div>
                                    <div>
                                        {(this.state.imageData === undefined) 
                                        ? <img src={placeholderImage} className={styles.userImage} />
                                        : <img src={URL.createObjectURL(this.state.imageData)} className={styles.userImage} />
                                        }
                                        <div>
                                            <div className={styles.leftDiv}>
                                                <p>Enter Quality Score for Gel:</p>
                                                <p>(1 as lowest to 3 highest)</p>
                                            </div>
                                            <div className={styles.textFieldDiv}>
                                                <TextField 
                                                    disabled={disabled}
                                                    type="number" 
                                                    value={this.state.gelQuality || ""} 
                                                    onChange={e => {this.checkInput(e.target.value)}} 
                                                    size="small"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className={styles.sampleGelDiv}>
                                    <h3><u>Sample Gel</u></h3>
                                    <img src={sampleImage} className={styles.image} />
                                </div>
                            </div>
                        </div>
                    </CollapsibleSection>
                    <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                        <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen}/>
                        <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                    </div>
                    <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={"Gel Quality must be between 1 and 3 (inclusive)"}
                            autoHideDuration={2000}
                            onClose={this.snackbarClose}
                        />
                </div>
                
            );
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private checkInput(input: string) {
        let num = input;

        if (s.isBlank(num)) {
            this.props.workflowRunService.updateCustomField("GelQuality", input);
        }
        else {
            let parsedInput = parseInt(num);
            if ((parsedInput === 1) || (parsedInput === 2) || (parsedInput === 3)) {
                this.setState({ gelQuality: parsedInput });
                this.setState({ messageSnackbarOpen: false });
            }
            else {
                this.setState({ messageSnackbarOpen: true });
            }
        }
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private async moveToNextStepBegin() {
        let {
            selectedControls
        } = this.props.workflowRunService.getState();
        


        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        
        if (currentWorkflowRun && currentStep) {
            //the QCs for this step aren't implemented, when they are we would put them here
            const customFields = currentStep.CustomFields;
            //save the data to the custom fields for this workflow
            this.props.workflowRunService.updateCustomField("imagePath", this.state.imagePath);
            this.props.workflowRunService.updateCustomField("gelQuality", this.state.gelQuality.toString());

            const outputAsset = _.find(currentStep.OutputAssets, asset => (asset.Name === currentStep.OutputName));
            if (outputAsset) {
                let selectedCount = 0;
                _.forEach(selectedControls, s => selectedCount += s.Positions.length);
                
                await Promise.all([
                    this.props.workflowRunService.updateCustomFields(),
                ]);
                await this.props.workflowRunService.completeStep();
            }
        }

        

    }

    @bind
    private async uploadFile() {
        if (this.state.uploadFile != undefined) {
            let fileCopy: boolean = false;
            this.state.fileData.forEach((file) =>
            {
                if (file.fileName == this.state.uploadFile?.name) {
                    fileCopy = true;
                }
            })
            if (fileCopy) {
                this.setState({ showDuplicateFileError: true})
            }
            else {
                this.setState({ showDuplicateFileError: false, showDuplicateDirectoryError: false})
                const data = new FormData();
                data.append('Upload', this.state.uploadFile);
                data.append('Type', "FILE");
                data.append('LastModified', new Date(this.state.uploadFile.lastModified).toISOString());
                let filePath: string = "";
                if (this.state.selectedFilesOrDirectory.length == 0 || this.state.selectedFilesOrDirectory[0] == "root") {
                    filePath = ""
                }
                else if (this.state.currentlySelected == "File") {
                    filePath = this.state.selectedFilesOrDirectory[0];
                    filePath = filePath.substring(0, filePath.lastIndexOf("/") + 1);
                }
                else if (this.state.currentlySelected == "Directory") {
                    filePath = this.state.selectedFilesOrDirectory[0] + "/";

                }
                await fetch(FileSharingAPI + 'CreateFileOrDirectory/' + filePath + this.state.uploadFile.name, { method: "PUT", body: data }).then(
                    function (res) {
                        var success = true;
                    }
                )
                this.setState({imagePath: this.state.uploadFile.name})
                this.props.workflowRunService.updateCustomField("ImageName", this.state.imagePath);
                this.props.workflowRunService.updateCustomField("imagePath", this.state.imagePath);
                await Promise.all([
                    this.props.workflowRunService.updateCustomFields(),
                ]);
                this.setState({ createFileOrDirectoryDialog: false, uploadFile: undefined, creating: "" })
                await this.loadFiles(new Object(), filePath.substring(0, filePath.lastIndexOf("/")))
                //grab a copy of the uploaded image as a blob so it can be previewed later
                await fetch(FileSharingAPI + 'DownloadFile/' + this.state.imagePath, { method: "GET"})
                    .then(response => response.blob())
                    .then(blob => {this.setState({imageData: blob})})
            }
        }
    }

    // load files at a given path
    @bind
    private async loadFiles(event: object, value: string) {
        let fileData: IFileData[] = [];
        let filePath: string;
        if (value == undefined || value == "root" || value == "") {
            await FileSharingService.fetchRootFiles();
            filePath = "";
        }
        else {
            await FileSharingService.fetchFiles(value);
            filePath = value + "/"
        }

        let {
            currentFiles
        } = FileSharingService.getState();

        currentFiles.data?.forEach(file => {
            fileData.push({ id: filePath + 
                file.Name, 
                fileName: file.Name, 
                type: file.ContentType, 
                createdOn: new Date(file.CreatedOn), 
                fileSharedTo: file.SharedWith.length, 
                fileSharedToHidden: file.SharedWith, 
                fileReceivedFrom: this.state.associationData?.find(association => association.Id == file.SharedFrom)?.Alias })
        });

        let currentExpanded: string[] = this.state.expanded;
        if (currentExpanded.includes(value)) {
            if (value == "root") {
                currentExpanded = [];
            }
            else {
                currentExpanded.splice(currentExpanded.indexOf(value), 1);
                for (let i = 0; i < currentExpanded.length; i++) {
                    if (currentExpanded[i].indexOf(value) == 0) {
                        currentExpanded.splice(currentExpanded.indexOf(currentExpanded[i]), 1);
                        i--;
                    }
                }
            }
        }
        else {
            currentExpanded.push(value);
        }

        if (value == "") {
            this.setState({
                fileData: fileData, selectedFilesOrDirectory: [], currentlySelected: "Directory", expanded: currentExpanded
            })
        } else {
            this.setState({
                fileData: fileData, selectedFilesOrDirectory: [value], currentlySelected: "Directory", expanded: currentExpanded
            })
        }
    }

    // download a file at a given path
    @bind
    private async downloadFile(filePath: string) {
        var fileName = filePath;
        if (fileName.lastIndexOf("/") != -1) {
            fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
        }
        await fetch(FileSharingAPI + 'DownloadFile/' + filePath, { method: "GET" }).then(
            response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                var fileDownload = document.createElement("a");
                fileDownload.href = url;
                fileDownload.download = fileName;
                fileDownload.click();
                URL.revokeObjectURL(url);
            })
    }

}
