import {
    React,
    _,
    bind,
    moment
} from "$Imports/Imports";

import {
    Card,
    Dialog
} from "$Imports/MaterialUIComponents";
import { NoteVM } from "$Generated/api";
import { IDataTableColumn, DataTable } from "$Imports/CommonComponents";

export interface IShowCommentsControlProps {
    comments: NoteVM[];
    open: boolean;
    close: () => void;
}

const styles: {
    card: string;
    header: string;
    buttons: string;
    tableHeader: string;
} = require("./ShowCommentsControl.scss");

export class ShowCommentsControl extends React.PureComponent<IShowCommentsControlProps> {

    private readonly columns: Array<IDataTableColumn<NoteVM>> = [
        {
            columnName: "remarks",
            columnFieldData: (d) => d.Remarks,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Comments",
            sortMethod: (d) => (d.Remarks) ?? ""
        },
        {
            columnName: "user",
            columnFieldData: (d) => d.UserId,
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "User",
            sortMethod: (d) => (d.UserId) ?? ""
        },
        {
            columnName: "created-on",
            columnFieldData: (d) => (d.CreatedOn ? moment(d.CreatedOn).format("MM-DD-YY, h:mm a") : ""),
            headerProps: {
                className: styles.tableHeader,
            },
            headerValue: "Created On",
            sortMethod: (d) => (d.CreatedOn ? moment(d.CreatedOn).format("MM-DD-YY, h:mm a") : "") ?? ""
        },
    ];

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
            >
                <Card className={styles.card}>
                    <DataTable
                        data={this.props.comments}
                        columns={this.columns}
                        stickyHeader={true}
                    />
                </Card>
            </Dialog>
        );

    }

    @bind
    private handleClose() {
        this.props.close();
    }
}