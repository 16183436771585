import {
    React,
    bind,
    _,
} from "$Imports/Imports"

import { IWorkflowScreenProps } from "../WorkflowStep";
import { StepActionsControl, StepChangeControl, MasterMixControl, CollapsibleSection, DataLoadingDisplay } from "$Imports/CommonComponents";
import { Snackbar } from "$Imports/MaterialUIComponents";
import { missingData } from "$Components/common/WorkflowControls/MasterMixControl";


const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

interface ISinglePooledMasterMixScreenState {
    displayValidationWarning: boolean;
    canMove: boolean;
    dataLoaded: boolean;
}

export class SinglePooledMasterMixStep extends React.Component<IWorkflowScreenProps, ISinglePooledMasterMixScreenState> {

    constructor(props: IWorkflowScreenProps) {
        super(props);

        this.state = {
            displayValidationWarning: false,
            canMove: true,
            dataLoaded: false
        };
    }

    async componentDidMount() {
        await this.props.workflowRunService.fetchInputPoolsForStep(true);
        await this.props.workflowRunService.fetchMasterMixForStep(true);
        this.setState({dataLoaded: true});
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {


        const currentWorkflowRun = this.props.workflowRunService.currentWorkflowRun;
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixInfoState = this.props.workflowRunService.getState().masterMixInfoState;
        const lotOptions = this.props.workflowRunService.getState().fetchWorkflowReagentsResults.data;
        const inputPools = this.props.workflowRunService.getState().inputPools.data;

        if (currentWorkflowRun && currentStep && masterMixInfoState.data && inputPools && lotOptions) {
            let inputPool = _.find(inputPools, p => p.Name === currentStep.InputName);
            if (inputPool) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");
                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.mainDiv}>
                                <MasterMixControl
                                    disabled={disabled}
                                    index={0}
                                    masterMix={masterMixInfoState.data[0]}
                                    sampleCount={inputPool.SampleCount || 0}
                                    updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                    lotOptions={lotOptions}
                                    setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                    sampleHeaderOverride={"Per Sample Volume (uL)"}
                                />
                            </div>
                        </CollapsibleSection>
                        <div className={commonStyles.footerDiv}>
                            <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                            <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStep} failRun={this.props.failRun} />
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.displayValidationWarning}
                            message={"Lot numbers are required"}
                            autoHideDuration={5000}
                            onClose={this.hideValidationWarning}
                        />
                    </div>)
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private async moveToNextStep() {
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;

        const currentStep = this.props.workflowRunService.currentStep;

        if (currentStep && masterMixState.data) {

            let missingMasterMixData = missingData(masterMixState.data[0]);

            if (currentStep.OutputAssets) {
                let outputAsset = _.find(currentStep.OutputAssets, a => a.Name === currentStep.OutputName);

                if (!missingMasterMixData && outputAsset && outputAsset.PoolSummary) {
                    if (this.state.canMove) {
                        await Promise.all([
                            this.props.workflowRunService.saveMasterMix(true),
                        ]);
                        await this.props.workflowRunService.completeStep(); //Needs to be outside due to a race condition
                    }
                }
                else {
                    this.showValidationWarning();
                }
            }
        }
    }

    @bind
    hideValidationWarning() {
        this.setState({ displayValidationWarning: false });
    }

    @bind
    showValidationWarning() {
        this.setState({ displayValidationWarning: true });
    }
}