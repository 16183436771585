import {
    React,
    bind,
    _,
    moment
} from "$Imports/Imports"

import {
    GetWorkflowType,
    IWorkflowScreenProps
} from "../WorkflowStep";

import {
    StepActionsControl,
    StepChangeControl,
    InstructionSetControl,
    MasterMixControl,
    QCUIComponent,
    PreLoadingPoresMandatoryCheck,
    PreLoadingPoresCheck,
    PostLoadingPoresMandatoryCheck,
    PostLoadingPoresCheck,
    CollapsibleSection,
    DataLoadingDisplay
} from "$Imports/CommonComponents";

import { Snackbar, TextField } from "$Imports/MaterialUIComponents";
import { missingData } from "$Components/common/WorkflowControls/InstructionSetControl";

import
    * as s
    from "underscore.string";
import { ApplicationSecuritySettings } from "$Utilities/Security/ApplicationSecuritySettings";
import { MinKnowService, IMinKnowServiceInjectedProps } from "$State/MinKnowFreezerService";
import { NavigationService } from "$State/NavigationFreezerService";
import { QCCheckInstanceVM, QCCheckResultVM } from "$Generated/api";
import { ChangeEvent } from "react";

const commonStyles: {
    footerDiv: string;
    mainDiv: string;
} = require("./CommonStepStyles.scss");

const styles: {
    inputDiv: string;
} = require("./PrepFlowCell.scss");

const PreLoadingPoresCountName: string = "PreLoadingPoresCount";
const PostLoadingPoresCountName: string = "PostLoadingPoresCount";

export interface IPrepFlowCellScreenState {
    messageSnackbarOpen: boolean;
    qcOpen: boolean;
    failedQcs: QCCheckInstanceVM[];
    qcResults: QCCheckResultVM[];
    canMove: boolean;
    dataLoaded: boolean;
}

type IPrepFlowCellScreenProps = IWorkflowScreenProps & IMinKnowServiceInjectedProps;

export class _PrepFlowCellScreen extends React.Component<IPrepFlowCellScreenProps, IPrepFlowCellScreenState> {

    private _security: ApplicationSecuritySettings = new ApplicationSecuritySettings();

    state: IPrepFlowCellScreenState = {
        messageSnackbarOpen: false,
        qcOpen: false,
        failedQcs: [],
        qcResults: [],
        canMove: true,
        dataLoaded: false
    };

    async componentDidMount() {
        await this.props.workflowRunService.fetchCleaningDataForStep(true);
        await this.props.workflowRunService.fetchInputPoolsForStep(true);
        await this.props.workflowRunService.fetchMasterMixForStep(true);
        await this.props.workflowRunService.fetchStepInstanceCustomFields();
        await this.props.minKnowService.fetchConnectionStatus(); //Not necessary to force.
        this.setState({ dataLoaded: true });
    }

    componentWillUnmount() {
        this.props.workflowRunService.resetInternalData();
    }

    render() {

        let {
            currentStep,
            currentWorkflowRun,
        } = this.props.workflowRunService;
        let {
            cleaningDataState,
            masterMixInfoState,
            fetchWorkflowReagentsResults
        } = this.props.workflowRunService.getState();


        if (currentStep && currentWorkflowRun && cleaningDataState.data && masterMixInfoState.data && fetchWorkflowReagentsResults.data) {
            const customFields = currentStep.CustomFields;
            if (customFields) {
                let disabled = this.props.viewMode || (currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress");

                let dateString = moment(Date.now()).format("YYYYMMDDhmmA");
                let username = (this._security.userContext ? this._security.userContext.preferred_username : "");
                if (s.isBlank(customFields["ExperimentName"])) {
                    this.props.workflowRunService.updateCustomField("ExperimentName", currentWorkflowRun.RunNumber + "_" + dateString + "_" + username);
                }
                if (s.isBlank(customFields["SampleId"])) {
                    let sampleId = currentStep.InputName + "_" + dateString + "_" + username;
                    sampleId = sampleId.replace(".", "");
                    sampleId = sampleId.replace(" ", "");
                    this.props.workflowRunService.updateCustomField("SampleId", sampleId);
                }

                return (
                    <div>
                        <CollapsibleSection sectionHeader="Step Details" expanded={true}>
                            <div className={commonStyles.mainDiv}>
                                <div>
                                    <h2>Prep Flow Cell</h2>
                                    <div className={styles.inputDiv}>
                                        Pre-loading Pore Count: <TextField autoFocus={true} disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={(customFields[PreLoadingPoresCountName] ? customFields[PreLoadingPoresCountName] : "")} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { this.onTextChanged(PreLoadingPoresCountName, e.target.value) }} inputProps={{ min: 0 }} />
                                        Post-loading Pore Count: <TextField autoFocus={true} disabled={disabled} style={{ paddingLeft: 10 }} type="number" value={(customFields[PostLoadingPoresCountName] ? customFields[PostLoadingPoresCountName] : "")} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { this.onTextChanged(PostLoadingPoresCountName, e.target.value) }} inputProps={{ min: 0 }} />
                                    </div>
                                    <InstructionSetControl
                                        componentSetData={cleaningDataState.data[0]}
                                        updateComponentSetDataSteps={this.props.workflowRunService.updateCleaningDataSteps}
                                        disabled={currentStep.Status !== "InProgress" || currentWorkflowRun.RunState !== "InProgress"}
                                        poolOrPlate={"Pool"}
                                        lotOptions={fetchWorkflowReagentsResults.data}
                                        setCanMoveState={(canMove: boolean) => { this.setState({ canMove: canMove }) }}
                                        ignoreFocus={true}
                                    />
                                    <br />
                                    <MasterMixControl
                                        disabled={disabled}
                                        ignoreFocus={true}
                                        hideHeaders={true}
                                        index={0}
                                        masterMix={masterMixInfoState.data[0]}
                                        sampleCount={1}
                                        updateMasterMixReagents={this.props.workflowRunService.updateMasterMixReagents}
                                        lotOptions={fetchWorkflowReagentsResults.data}
                                        setCanMoveState={(canMove) => { this.setState({ canMove: canMove }) }}
                                    />
                                </div>
                                <div>
                                    <h2>
                                        Run Info Entry in MinKnow
                                    </h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    1. Enter Experiment Name:
                                                </td>
                                                <td>
                                                    <b>{customFields["ExperimentName"]}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    2. Enter SampleID:
                                                </td>
                                                <td>
                                                    <b>{customFields["SampleId"]}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    3. Select Kit.
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CollapsibleSection>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentStep.QCCheckInstances &&
                                <QCUIComponent
                                    open={this.state.qcOpen}
                                    failedQCs={this.state.failedQcs}
                                    close={() => { this.setState({ qcOpen: false, failedQcs: [] }) }}
                                    results={this.state.qcResults}
                                    step={currentStep}
                                    workflowRunId={currentWorkflowRun.Id}
                                    workflowName={currentWorkflowRun.WorkflowName}
                                    completeStep={this.completeMoveToNextStep}
                                />
                            }
                            <div className={commonStyles.footerDiv} style={{ width: "100%" }}>
                                <StepActionsControl step={currentStep} actionHandler={(actionType: number) => { }} workflowRunService={this.props.workflowRunService} saveScreen={this.props.saveScreen} />
                                <StepChangeControl disabled={disabled} nextStep={"Move to Next Step"} showPause={false} moveToNextStep={this.moveToNextStepBegin} failRun={this.props.failRun} />
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={this.state.messageSnackbarOpen}
                            message={"Lot numbers are required"}
                            autoHideDuration={5000}
                            onClose={this.snackbarClose}
                        />
                    </div>);
            }
        }
        return <DataLoadingDisplay />;
    }

    @bind
    private snackbarClose() {
        this.setState({ messageSnackbarOpen: false });
    }

    @bind
    private async moveToNextStepBegin() {
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        const cleaningDataState = this.props.workflowRunService.getState().cleaningDataState;
        const minKnowInformation = this.props.minKnowService.getState().connectionResults.data;
        if (currentStep && masterMixState.data && cleaningDataState.data && minKnowInformation) {

            let failedQCs: QCCheckInstanceVM[] = [];
            let qcResults: QCCheckResultVM[] = [];
            const customFields = currentStep.CustomFields;

            //Run all QCs
            _.forEach(currentStep.QCCheckInstances?.toJS(), qc => {
                let bPoresPass = true;
                let nPoresCount = 0;
                switch (qc.QCCheckType) {
                    case PreLoadingPoresMandatoryCheck:
                        // Check pre-loading pores mandatory requirements.
                        if (s.isBlank(customFields[PreLoadingPoresCountName])) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            bPoresPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? bPoresPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: s.isBlank(customFields[PreLoadingPoresCountName]) ? "None" : customFields[PreLoadingPoresCountName],
                                Date: new Date(Date.now()),
                                Pass: bPoresPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;

                    case PostLoadingPoresMandatoryCheck:
                        // Check post-loading pores mandatory requirements.
                        if (s.isBlank(customFields[PostLoadingPoresCountName])) {
                            if (qc.Enabled) {
                                failedQCs.push(qc);
                            }
                            bPoresPass = false;
                        }
                        qcResults.push(
                            {
                                Id: "",
                                FailureActionStatus: qc.Enabled ? bPoresPass ? undefined : 1 : 2, //passed/modify/skipped
                                MeasuredValue: s.isBlank(customFields[PostLoadingPoresCountName]) ? "None" : customFields[PostLoadingPoresCountName],
                                Date: new Date(Date.now()),
                                Pass: bPoresPass,
                                QCCheckInstance: qc,
                            }
                        );
                        break;

                    case PreLoadingPoresCheck:
                        nPoresCount = Number.parseInt(customFields[PreLoadingPoresCountName]);
                        // Check pre-loading pores count.
                        if (!isNaN(nPoresCount)) {
                            let minPoresParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "PreLoadingPoresCount")?.Value || "0");
                            if (nPoresCount < minPoresParam) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                                bPoresPass = false;
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? bPoresPass ? undefined : 0 : 2, //passed/executed/skipped
                                    MeasuredValue: customFields[PreLoadingPoresCountName],
                                    Date: new Date(Date.now()),
                                    Pass: bPoresPass,
                                    QCCheckInstance: qc,
                                }
                            );
                        }

                        break;

                    case PostLoadingPoresCheck:
                        nPoresCount = Number.parseInt(customFields[PostLoadingPoresCountName]);
                        // Check post-loading pores count.
                        if (!isNaN(nPoresCount)) {
                            let minPoresParam = Number.parseInt(_.find(qc.CheckConfig?.Parameters, p => p.Id === "PostLoadingPoresCount")?.Value || "0");
                            if (nPoresCount < minPoresParam) {
                                if (qc.Enabled) {
                                    failedQCs.push(qc);
                                }
                                bPoresPass = false;
                            }
                            qcResults.push(
                                {
                                    Id: "",
                                    FailureActionStatus: qc.Enabled ? bPoresPass ? undefined : 0 : 2, //passed/executed/skipped
                                    MeasuredValue: customFields[PostLoadingPoresCountName],
                                    Date: new Date(Date.now()),
                                    Pass: bPoresPass,
                                    QCCheckInstance: qc,
                                }
                            );
                        }
                }
            })
            if (failedQCs.length > 0 && _.find(failedQCs, q => q.Enabled)) {
                this.setState({ qcOpen: true, failedQcs: failedQCs, qcResults: qcResults });
            }
            else {
                this.setState({ qcOpen: true, qcResults: qcResults }); //use this to trigger the results saving
                await this.completeMoveToNextStep();

            }
        }
    }

    @bind
    private async completeMoveToNextStep() {
        const currentStep = this.props.workflowRunService.currentStep;
        const masterMixState = this.props.workflowRunService.getState().masterMixInfoState;
        const cleaningDataState = this.props.workflowRunService.getState().cleaningDataState;
        const minKnowInformation = this.props.minKnowService.getState().connectionResults.data;
        const timestamp = Date.now(); // ms since epoch

        if (currentStep && masterMixState.data && cleaningDataState.data && minKnowInformation) {
            let missingMasterMixData = missingData(masterMixState.data[0]);
            let missingCleaning = missingData(cleaningDataState.data[0]);

            if (!missingMasterMixData && !missingCleaning) {
                //Continue to next step
                if (this.state.canMove) {
                    await Promise.all([
                        this.props.workflowRunService.updateSequencingStatus("Started"),
                        this.props.workflowRunService.saveCleaningData(true),
                        this.props.workflowRunService.saveMasterMix(true),
                        this.props.workflowRunService.updateCustomFields(),
                        this.props.workflowRunService.autoDiscardRetainedAssets()
                    ]);
                    await this.props.workflowRunService.completeStep();
                }

            }
            else {
                this.setState({ messageSnackbarOpen: true });
            }
        }
    }

    @bind
    private onTextChanged(key: string, newValue: string) {
        this.props.workflowRunService.updateCustomField(key, newValue);
    }
}

export const PrepFlowCellScreen = MinKnowService.inject(_PrepFlowCellScreen);